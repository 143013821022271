import { useCallback, useState } from "react"
import { LayoutChangeEvent, LayoutRectangle } from "react-native"

// @ts-ignore
type IProps = [onLayout: (event: LayoutChangeEvent) => void, layout?: LayoutRectangle]

/* @deprecated use useLayout instead */
const useComponentDimensions = (): IProps => {
  const [layout, setLayout] = useState<LayoutRectangle>()

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setLayout(event.nativeEvent.layout)
  }, [])

  return [onLayout, layout]
}

export default useComponentDimensions
