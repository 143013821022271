import React, { FC } from "react"
import { StyleSheet } from "react-native"
import { ActivityIndicator, Dialog, Portal } from "react-native-paper"

import { useTheme } from "../../features/Theme"
import { Subtitle1 } from "../Texts"

export interface LoadingModalProps {
  loading: boolean
  title?: string
}

const onDismiss = () => null

const LoadingModal: FC<LoadingModalProps> = ({ loading, title }): JSX.Element => {
  const {
    colors: { overrides, primary },
    dimensions: { spacing },
  } = useTheme()

  return (
    <Portal>
      <Dialog style={styles.dialog} visible={loading} dismissable={false} onDismiss={onDismiss}>
        <ActivityIndicator
          size="small"
          style={{
            margin: spacing,
            marginLeft: spacing * 2,
          }}
          color={overrides?.activityIndicator || primary}
        />
        {title && <Subtitle1 emphasis="high">{title}</Subtitle1>}
      </Dialog>
    </Portal>
  )
}

const styles = StyleSheet.create({
  dialog: {
    flexDirection: "column-reverse",
    alignItems: "center",
    minHeight: 128,
  },
})

export default LoadingModal
