import React, { FC, useCallback, useContext, useMemo } from "react"
import { StyleSheet, View } from "react-native"

import { tabContext, TabType } from "../../features/Providers"
import useTheme from "../../features/Theme/ThemeProvider"
import { Subtitle1 } from "../Texts"
import { Touchable } from "../Touchable"

interface IProps {
  tab: TabType
  index: number
  width?: number
  height?: number
}

const Tab: FC<IProps> = ({ tab, width, index, height }) => {
  const { currentTab, setCurrentTab, tabRef } = useContext(tabContext)
  const isSelected = currentTab?.index === tab.index
  const {
    dimensions: { spacing },
    typography: { button, caption },
    colors: {
      primary,
      overrides,
      black: { highEmphasis: black },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      view: [
        styles.view,
        {
          width,
          height,
        },
      ],
      tab: [
        styles.tab,
        {
          marginVertical: spacing / 2,
        },
      ],
      indicator: [
        styles.indicator,
        {
          backgroundColor: overrides?.tabIndicator ?? black,
        },
      ],
      button: {
        fontSize: caption.fontSize,
        color: button.color,
        lineHeight: button.lineHeight,
      },
    }),
    [
      black,
      height,
      overrides?.tabIndicator,
      spacing,
      width,
      caption,
      button.color,
      button.lineHeight,
    ],
  )

  const onPress = useCallback(() => {
    setCurrentTab(tab)
    tabRef?.current?.scrollToIndex({ index, viewPosition: 0.5 })
  }, [index, setCurrentTab, tab, tabRef])

  return (
    <Touchable rippleColor={primary} style={s.view} onPress={onPress}>
      <>
        <View style={s.tab}>
          <Subtitle1 style={s.button} emphasis={isSelected ? "high" : "medium"}>
            {tab.title}
          </Subtitle1>
        </View>
        {isSelected ? <View style={s.indicator} /> : null}
      </>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  tab: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  indicator: {
    height: 2,
  },
})

export default Tab
