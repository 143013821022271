import { useNavigation } from "@react-navigation/native"
import { IRowItemProps, ISectionHeader, ParametersScreen, useTheme } from "capsule"
import _ from "lodash"
import React, { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { SectionListData, StyleSheet } from "react-native"

import SeeMoreButton from "../../components/SeeMoreButton"
import { itemField } from "../../features/hooks/useItemI18n"
import { HomeNS } from "../../features/i18n/constants"
import { programContext } from "../../features/Providers/ProgramProvider"
import { HomeScreenNavigationProp } from "./HomeScreen"

const HomeFaqView = () => {
  const { t } = useTranslation(HomeNS)
  const {
    colors: {
      surface: { background },
      black: { highEmphasis: black },
    },
    dimensions: { spacing },
    typography: { h6 },
  } = useTheme()
  const { allFaqs } = useContext(programContext)

  const navigation = useNavigation<HomeScreenNavigationProp>()
  const s = useMemo(
    () => ({
      view: {
        backgroundColor: background,
      },
      title: [h6, { color: black }],
      header: {
        paddingHorizontal: 0,
        paddingLeft: spacing,
        paddingBottom: spacing,
        paddingTop: spacing * 1.5,
        backgroundColor: background,
      },
      row: [
        styles.row,
        {
          paddingVertical: spacing / 2,
        },
      ],
    }),
    [black, background, h6, spacing],
  )

  const goToFaqTab = useCallback(() => navigation.jumpTo("Faq", { screen: "Faq_Main" }), [
    navigation,
  ])

  const onPress = useCallback(
    (id: string) =>
      navigation.jumpTo("Faq", {
        screen: "Faq_Article",
        initial: false,
        params: { id },
      }),
    [navigation],
  )

  const sections: Array<SectionListData<IRowItemProps, ISectionHeader>> = [
    {
      shadows: _.isEmpty(allFaqs) ? 0 : 1,
      headerStyle: s.header,
      title: t("inquire"),
      headerTextStyle: s.title,
      rightHeaderNode: <SeeMoreButton onPress={goToFaqTab} padding />,
      data: _.map(allFaqs, faq => ({
        rowStyle: s.row,
        onPress: () => onPress(faq.id),
        name: itemField(faq, "title", t),
        leftChild: {
          category: "MaterialIcons",
          name: faq.video ? "video-library" : "insert-drive-file",
        },
        rightChild: { category: "MaterialIcons", name: "chevron-right" },
      })),
    },
  ]

  return (
    <ParametersScreen listKey="faqList" sections={sections} showSeparator paddingStyle={s.view} />
  )
}

const styles = StyleSheet.create({
  row: {
    height: "100%",
  },
})

export default HomeFaqView
