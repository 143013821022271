import { Theme } from "./ThemeProvider"
import { ITypographyTheme } from "./types"

const typoGen = ({
  colors,
  fontMaker,
}: Omit<Theme, "typography" | "typeGen">): ITypographyTheme => {
  const defaultFont = fontMaker()
  const titleFont = fontMaker({ family: "title" })
  return {
    body1: {
      color: colors.black.highEmphasis,
      fontSize: 18,
      lineHeight: 28,
      ...defaultFont,
    },
    body2: {
      color: colors.black.highEmphasis,
      fontSize: 14,
      lineHeight: 20,
      ...defaultFont,
    },
    button: {
      color: colors.white.highEmphasis,
      fontSize: 14,
      lineHeight: 20,
      ...defaultFont,
    },
    caption: {
      color: colors.black.highEmphasis,
      fontSize: 12,
      lineHeight: 16,
      ...defaultFont,
    },
    h1: {
      color: colors.black.highEmphasis,
      fontSize: 96,
      ...titleFont,
    },
    h2: {
      color: colors.black.highEmphasis,
      fontSize: 60,
      ...titleFont,
    },
    h3: {
      color: colors.black.highEmphasis,
      fontSize: 48,
      ...titleFont,
    },
    h4: {
      color: colors.black.highEmphasis,
      fontSize: 34,
      ...titleFont,
    },
    h5: {
      color: colors.black.highEmphasis,
      fontSize: 24,
      ...defaultFont,
    },
    h6: {
      color: colors.black.highEmphasis,
      fontSize: 20,
      lineHeight: 23,
      ...defaultFont,
    },
    overline: {
      color: colors.black.highEmphasis,
      fontSize: 10,
      lineHeight: 16,
      textTransform: "uppercase",
      ...defaultFont,
    },
    subtitle1: {
      color: colors.black.highEmphasis,
      fontSize: 16,
      lineHeight: 24,
      ...defaultFont,
    },
    subtitle2: {
      color: colors.black.highEmphasis,
      fontSize: 14,
      lineHeight: 24,
      ...defaultFont,
    },
    dateNumber: {
      fontSize: 22,
    },
  }
}

export default typoGen
