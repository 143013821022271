import React, { FC, PropsWithChildren, useMemo } from "react"
import { Platform, StyleProp, StyleSheet, ViewStyle } from "react-native"
import { Edge, SafeAreaView } from "react-native-safe-area-context"

import useTheme from "../../features/Theme/ThemeProvider"
import KeyboardAvoidingView from "../KeyboardAvoidingView/KeyboardAvoidView"

interface IProps {
  edges?: Edge[]
  style?: StyleProp<ViewStyle>
}

const BottomButtonContainer: FC<PropsWithChildren<IProps>> = ({ children, edges, style }) => {
  const {
    colors: { white },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      view: [
        styles.container,
        {
          paddingTop: spacing,
          backgroundColor: white.highEmphasis,
        },
        style,
      ],
    }),
    [spacing, style, white.highEmphasis],
  )

  return (
    <KeyboardAvoidingView enabled={Platform.OS === "ios"}>
      <SafeAreaView edges={edges ?? ["bottom", "left", "right"]} style={s.view}>
        {children}
      </SafeAreaView>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
})

export default BottomButtonContainer
