import { SectionSeparator, useTheme } from "capsule"
import React, { FC, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, Platform, View } from "react-native"

import AccordionSection from "../../components/AccordionSection"
import ProtocolExercicesView from "../../components/ProtocolExercicesView"
import { PatientNS } from "../../features/i18n/constants"
import { getSelectedExos } from "../../features/models/PhaseAndSessionFunctions"
import { Patient } from "../../features/models/UserData"
import { programContext } from "../../features/Providers/ProgramProvider"

interface PatientHandlePhasesProps {
  patient: Patient
  isKine: boolean
}

const PatientHandlePhasesView: FC<PatientHandlePhasesProps> = ({ patient, isKine }) => {
  const { currentPhase } = useContext(programContext)
  const {
    colors: {
      white: { highEmphasis: white },
    },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation(PatientNS)

  const [phaseExercises, setPhaseExercises] = useState<any | null>(null)

  useEffect(() => {
    const fetchExercices = async () => {
      const phaseExos = await getSelectedExos(patient, patient.id, currentPhase)
      setPhaseExercises(phaseExos)
    }
    fetchExercices()
  }, [patient, patient.id, currentPhase])

  const s = useMemo(
    () => ({
      view: [
        {
          backgroundColor: white,
        },
        Platform.OS !== "web" ? screenStyle : null,
      ],
    }),
    [white, screenStyle],
  )

  const FlatListComponents = patient
    ? [
        <ProtocolExercicesView
          key="ProtocolExercices"
          patient={patient}
          isKine={isKine}
          patientId={patient.id}
          currentPhase={currentPhase}
          currentExos={phaseExercises}
        />,
      ]
    : []

  return (
    <AccordionSection
      title={isKine ? t("handlePhases") : t("viewPhases")}
      leftIcon="change-circle"
      expandList={true}
    >
      <View style={s.view}>
        <FlatList
          data={FlatListComponents}
          renderItem={({ item }) => <React.Fragment>{item}</React.Fragment>}
        />
        <SectionSeparator />
      </View>
    </AccordionSection>
  )
}

export default PatientHandlePhasesView
