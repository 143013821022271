import { useNavigation } from "@react-navigation/native"
import { Body2, ButtonType, functions, logger, useAlert, userContext, useTheme } from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Keyboard, Platform, StyleSheet } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { Button } from "react-native-paper"
import * as Yup from "yup"

import { OnConvertAddressParams } from "../../common/types"
import { DoctupSupportedCountries, SCROLL_HEIGHT } from "../../features/config/Constants"
import { ProfileNS } from "../../features/i18n/constants"
import { AddressType } from "../../features/models/Address"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { maxWidth } from "../../ThemeApp"
import { getFlagEmoji } from "../../utils/conversion"
import { AddressFormikConfig, AddressFormType, AddressInputScreen } from "./AddressInputScreen"

interface IProps {
  isEdit: boolean
}

const AddressScreen: FC<IProps> = ({ isEdit }) => {
  const { userDocRef, userData } = useContext(userContext)
  const navigation = useNavigation<any>()
  const { t } = useTranslation(ProfileNS)
  const [formLoading, setformLoading] = useState<boolean>(false)
  const [skipButton, setSkipButton] = useState<boolean>(false)
  const [isOnMount, setIsOnMount] = useState<boolean>(true)
  const { showDialog } = useAlert()

  const {
    colors: {
      surface: { textInput, background },
      black: { mediumEmphasis: black },
      primary,
    },
    dimensions: { spacing },
  } = useTheme()

  const countriesAndFlagsArray = useMemo(
    () =>
      _.map(DoctupSupportedCountries, country => ({
        code: country.countryCode,
        title: country.countryName,
        flag: getFlagEmoji(country.countryCode),
        callingCode: country.countryCallingCode,
      })),
    [],
  )

  const s = useMemo(
    () => ({
      inputStyle: { backgroundColor: textInput, marginBottom: spacing / 4 },
      viewStyle: [
        styles.viewStyle,
        {
          backgroundColor: background,
          paddingHorizontal: spacing,
          paddingVertical: Platform.OS === "ios" ? 0 : spacing,
        },
      ],
      content: [
        styles.content,
        {
          backgroundColor: background,
          paddingBottom: spacing * 2,
        },
      ],
      childStyle: {
        child: { marginBottom: spacing },
      },
      dialogTextColor: {
        color: black,
      },
      dialog: [
        styles.dialog,
        {
          paddingHorizontal: spacing,
        },
      ],
      dialogTitle: {
        padding: spacing,
      },
      innerContent: {
        paddingBottom: spacing * 2,
      },
      label: [styles.buttonLabel, { right: spacing / 2 }],
    }),
    [spacing, textInput, black, background],
  )

  const convertAddress = useCallback(async (val: AddressType) => {
    try {
      const convertAddressParams: OnConvertAddressParams = {
        dev: __DEV__,
        address: val,
      }
      return await functions().httpsCallable("convertAddressToCoordinates")(convertAddressParams)
    } catch (e) {
      logger("Convert address error ", e)
      return
    }
  }, [])

  const submitAddress = useCallback(
    async (address: AddressType) => {
      updateUserData(userDocRef, {
        address,
      })
      if (isEdit) {
        navigation.goBack()
      }
    },
    [userDocRef, navigation, isEdit],
  )
  const onPress = useCallback(async () => {
    if (!isEdit) {
      updateUserData(userDocRef, {
        address: {},
      })
    } else {
      navigation.goBack()
    }
  }, [userDocRef, isEdit, navigation])

  const openErrorDialog = useCallback(async () => {
    showDialog({
      type: "button",
      style: s.dialog,
      titleStyle: s.dialogTitle,
      contentContainerStyle: styles.dialogContent,
      title: t("address.errorTitle", { ProfileNS }),
      message: t("address.addressError", { ProfileNS }),
      messageStyle: s.dialogTextColor,
      positive: {
        buttonStyle: styles.dialogRightButton,
        type: ButtonType.POSITIVE,
        label: t(`${ProfileNS}:address.addressModal.negative`),
        labelType: "destructive",
        onPress: () => {
          setSkipButton(true)
        },
      },
      negative: {
        buttonStyle: styles.dialogLeftButton,
        type: ButtonType.NEGATIVE,
        textStyle: s.dialogTextColor,
        label: t("address.addressModal.later", { ProfileNS }),
        onPress: () => {
          onPress()
        },
      },
    })
  }, [onPress, s.dialog, s.dialogTextColor, s.dialogTitle, showDialog, t])

  const onSubmit = useCallback(
    async (val: AddressType) => {
      Keyboard.dismiss()
      setformLoading(false)
      setIsOnMount(false)
      const { additional_address, country, ...addressObject } = val
      const address = await convertAddress({ country, ...addressObject })
      if (address && address.data) {
        const userAddress = address.data.formattedAddress
        const newAddressObject = { ...userAddress }
        delete newAddressObject.country
        if (userAddress.street_number && userAddress.route) {
          setformLoading(true)
          showDialog({
            type: "button",
            style: s.dialog,
            titleStyle: s.dialogTitle,
            contentContainerStyle: styles.dialogContent,
            title: t(`${ProfileNS}:address.addressModal.title`),
            message: t(`${ProfileNS}:address.addressModal.message`, {
              street_number: userAddress.street_number,
              route: userAddress.route,
              locality: userAddress.locality,
              postal_code: userAddress.postal_code,
              country: userAddress.country,
            }),
            messageStyle: s.dialogTextColor,
            positive: {
              buttonStyle: styles.dialogRightButton,
              onPress: async () => {
                await submitAddress({ ...newAddressObject, additional_address, country })
              },
              type: ButtonType.POSITIVE,
              label: t(`${ProfileNS}:address.addressModal.positive`),
              labelType: "destructive",
            },
            negative: {
              buttonStyle: styles.dialogLeftButton,
              type: ButtonType.NEGATIVE,
              textStyle: s.dialogTextColor,
              label: t(`${ProfileNS}:address.addressModal.negative`),
              onPress: () => {
                setSkipButton(true)
              },
            },
          })
        } else {
          setformLoading(true)
          openErrorDialog()
        }
      } else {
        setformLoading(true)
        openErrorDialog()
      }
    },
    [
      convertAddress,
      t,
      s.dialogTextColor,
      showDialog,
      submitAddress,
      s.dialog,
      s.dialogTitle,
      openErrorDialog,
    ],
  )

  return (
    <KeyboardAwareScrollView
      style={s.content}
      enableOnAndroid
      keyboardShouldPersistTaps="handled"
      contentContainerStyle={s.innerContent}
      extraScrollHeight={
        Platform.OS === "ios" || Platform.OS === "android" ? SCROLL_HEIGHT : undefined
      }
    >
      <AddressInputScreen
        inputStyle={s.inputStyle}
        viewStyle={s.viewStyle}
        formikConfig={AddressFormikConfig({
          onSubmit,
          isOnMount,
          initialValues: {
            street_number: userData?.address?.street_number || "",
            route: userData?.address?.route || "",
            additional_address: userData?.address?.additional_address || "",
            postal_code: userData?.address?.postal_code || "",
            locality: userData?.address?.locality || "",
            country: userData?.address?.country || countriesAndFlagsArray[0].title,
          },
          validationSchema: Yup.object<AddressFormType>({
            street_number: Yup.string().required(t("required.street_number", { ProfileNS })),
            route: Yup.string().required(t("required.route", { ProfileNS })),
            additional_address: Yup.string(),
            postal_code: Yup.string().required(t("required.postal_code", { ProfileNS })),
            locality: Yup.string().required(t("required.locality", { ProfileNS })),
            country: Yup.string().required(t("required.country", { ProfileNS })),
          }),
        })}
        buttonStyle={styles.buttonStyle}
        styles={s.childStyle}
        formLoading={formLoading}
        countries={countriesAndFlagsArray}
      />
      {!isEdit && skipButton ? (
        <Button
          mode="text"
          labelStyle={s.label}
          onPress={onPress}
          contentStyle={styles.buttonContent}
          uppercase={false}
        >
          <Body2 color={primary}>{t("common:button.skip")}</Body2>
        </Button>
      ) : null}
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  buttonStyle: { margin: 0 },
  viewStyle: { marginVertical: 0 },
  dialogLeftButton: {
    marginRight: 0,
    flex: 0,
  },
  dialogRightButton: {
    marginLeft: 0,
    flex: 0,
  },
  dialog: {
    alignItems: "center",
  },
  dialogContent: {
    maxWidth,
    width: "100%",
  },
  buttonLabel: {
    margin: 0,
    alignItems: "flex-start",
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
})

export default AddressScreen
