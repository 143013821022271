import { Subtitle1, Subtitle2, useTheme } from "capsule"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"

import RadioButton from "../../components/RadioButton"
import SubmitButton from "../../components/SubmitButton"
import { NavigationNS } from "../../features/i18n/constants"
import { maxWidth } from "../../ThemeApp"
import { SurgeonPracticeFormValues } from "./Constants"

interface IProps {
  isEdit: boolean
}

const SurgeonPracticeScreen: FC<IProps> = ({ isEdit = false }) => {
  const { t } = useTranslation(NavigationNS)
  const {
    dimensions: { spacing },
    colors: {
      white: { highEmphasis: white },
      surface,
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      view: [
        styles.containerScreen,
        {
          backgroundColor: surface.background,
        },
      ],
      button: {
        margin: spacing * 2,
      },
      inputContainer: [
        {
          paddingHorizontal: spacing,
          backgroundColor: white,
        },
      ],
      label: {
        paddingTop: spacing,
        paddingHorizontal: spacing,
      },
      screenStyle: [Platform.OS === "web" ? { width: maxWidth } : null],
      subtitle: {
        padding: spacing,
      },
    }),
    [spacing, white, surface.background],
  )

  return (
    <View style={s.view}>
      <View style={s.screenStyle}>
        <Subtitle1 style={s.label}>{t("SurgeonPractice.question")}</Subtitle1>
        <Subtitle2 style={s.subtitle}>{t("SurgeonPractice.info")}</Subtitle2>
        <RadioButton
          name="surgeonPractice"
          options={[
            { label: t("SurgeonPractice.junior"), value: "junior" },
            { label: t("SurgeonPractice.intermediate"), value: "intermediate" },
            { label: t("SurgeonPractice.senior"), value: "senior" },
            { label: t("SurgeonPractice.unknown"), value: "unknown" },
          ]}
        />
        <View style={s.button}>
          <SubmitButton<SurgeonPracticeFormValues> {...{ isEdit }} />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  containerScreen: {
    flex: 1,
    alignItems: "center",
  },
})

export default SurgeonPracticeScreen
