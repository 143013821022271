import React, { ElementType, FC, useCallback, useMemo, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import {
  Dimensions,
  FlatList,
  Image,
  ImageSourcePropType,
  ImageStyle,
  ListRenderItem,
  Platform,
  StatusBar,
  StatusBarStyle,
  StyleProp,
  StyleSheet,
  TextProps,
  View,
  ViewStyle,
} from "react-native"
import { IconButton } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { useAlert } from "../../features/Providers"
import { useTheme } from "../../features/Theme"
import { Button } from "../Button"
import { CircleStepProgress } from "../CircleStepProgress"
import { Caption, H4, IBodyProps, Subtitle1 } from "../Texts"
import { Link } from "../Texts/Link"
import { VectorIcon, VectorIconProps } from "../VectorIcon"

interface BasePage {
  title?: string
  description?: string
  Background?: ElementType
}

interface ImagePage extends BasePage {
  image: ImageSourcePropType
}

interface ComponentPage extends BasePage {
  Component: ElementType
}

type Page = ImagePage | ComponentPage

const isImage = (p: Page): p is ImagePage => (p as ImagePage).image !== undefined

export type WelcomeButton = "signIn" | "signUp"

export interface WelcomeButtonStyle {
  icon?: VectorIconProps
  mode?: "text" | "outlined" | "contained"
  color?: string
  hidden?: boolean
}

interface IProps {
  pages: Page[]
  showHtmlView?: boolean
  barStyle?: StatusBarStyle
  buttonStyles?: WelcomeButtonStyle[]
  color?: Omit<IBodyProps, "children">
  onSubmit?: (button: WelcomeButton) => void
  TitleComponent?: React.ComponentType<TextProps>
  DescriptionComponent?: React.ComponentType<TextProps>
  imageStyle?: StyleProp<ImageStyle>
  containerStyle?: StyleProp<ViewStyle>
  hideStatusBar?: boolean
  Background?: ElementType
  transComponents?: React.ReactNode[] | { readonly [tagName: string]: React.ReactNode }
}

const keyExtractor = (item: Page, index: number) => `${index}`

// noinspection JSUnusedGlobalSymbols
export const WelcomeScreen: FC<IProps> = ({
  color,
  pages,
  barStyle = "dark-content",
  buttonStyles,
  showHtmlView,
  TitleComponent = H4,
  DescriptionComponent = Subtitle1,
  imageStyle,
  containerStyle,
  hideStatusBar = true,
  Background,
  transComponents,
  ...rest
}) => {
  const { notImplemented } = useAlert()
  const { onSubmit = notImplemented } = rest
  const {
    colors,
    dimensions: { spacing, marginSides },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)
  const indexRef = useRef(index)
  indexRef.current = index
  const { width: windowWidth } = Dimensions.get("window")
  const [flatListDimensions, setFlatListDimensions] = useState<[number, number]>([windowWidth, 0])

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          backgroundColor: colors.surface.background,
        },
        containerStyle,
      ],
      image: [
        styles.image,
        {
          marginTop: spacing * 5,
          marginBottom: spacing / 2,
        },
        imageStyle,
      ],
      textContainer: [
        styles.textContainer,
        {
          paddingHorizontal: spacing,
        },
      ],
      text: [
        styles.text,
        {
          marginVertical: spacing / 2,
          marginHorizontal: spacing / 2,
        },
      ],
      progress: [
        styles.progress,
        {
          marginVertical: spacing,
        },
      ],
      button: {
        marginHorizontal: marginSides,
      },
      button1: {
        marginTop: spacing / 2,
        marginBottom: spacing,
      },
      caption: { marginBottom: marginSides, marginHorizontal: marginSides },
      listButton: {
        top: (flatListDimensions?.[1] ?? 0) / 2,
        marginHorizontal: spacing * 2,
        backgroundColor: colors.black.disabledButton,
      },
    }),
    [colors, containerStyle, spacing, imageStyle, marginSides, flatListDimensions],
  )

  const onScroll = useCallback(event => {
    const slideSize = event.nativeEvent.layoutMeasurement.width
    const idx = event.nativeEvent.contentOffset.x / slideSize
    const roundIndex = Math.round(idx)

    const distance = Math.abs(roundIndex - idx)

    // Prevent one pixel triggering setIndex in the middle
    // of the transition. With this we have to scroll a bit
    // more to trigger the index change.
    const isNoMansLand = 0.4 < distance

    if (roundIndex !== indexRef.current && !isNoMansLand) {
      setIndex(roundIndex)
    }
  }, [])

  const renderItem: ListRenderItem<Page> = useCallback(
    ({ item }) => (
      <View style={{ width: flatListDimensions[0], overflow: "hidden" }}>
        {item.Background ? (
          <View style={StyleSheet.absoluteFill}>
            <item.Background width="100%" height="100%" />
          </View>
        ) : undefined}
        {isImage(item) ? (
          <Image source={item.image} style={s.image} />
        ) : (
          <item.Component style={s.image} />
        )}

        <View style={s.textContainer}>
          <TitleComponent {...color} style={s.text}>
            {item.title}
          </TitleComponent>
          <DescriptionComponent {...color} style={s.text}>
            {item.description}
          </DescriptionComponent>
        </View>
      </View>
    ),
    [
      DescriptionComponent,
      TitleComponent,
      color,
      s.image,
      s.text,
      s.textContainer,
      flatListDimensions,
    ],
  )

  const buttonIcon = useCallback(
    (ind: number) =>
      buttonStyles?.[ind]?.icon
        ? () => <VectorIcon {...(buttonStyles?.[ind]?.icon as VectorIconProps)} />
        : undefined,
    [buttonStyles],
  )
  const flatList = useRef<FlatList<Page>>(null)
  const getItemLayout = useCallback(
    (_, i) => ({ length: flatListDimensions[0], offset: flatListDimensions[0] * i, index: i }),
    [flatListDimensions],
  )
  const onLayout = useCallback(
    e => setFlatListDimensions([e.nativeEvent.layout.width, e.nativeEvent.layout.height]),
    [],
  )

  return (
    <SafeAreaView style={s.container} edges={["right", "left"]}>
      <StatusBar hidden={hideStatusBar} barStyle={barStyle} animated />
      {Background ? (
        <View style={StyleSheet.absoluteFill}>
          <Background width="100%" height="100%" />
        </View>
      ) : undefined}
      <FlatList<Page>
        ref={flatList}
        onLayout={onLayout}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        pagingEnabled
        horizontal
        showsHorizontalScrollIndicator={false}
        onScroll={onScroll}
        data={pages}
      />
      {Platform.OS === "web" ? (
        <>
          <IconButton
            style={[s.listButton, styles.absolute]}
            icon="chevron-left"
            onPress={() =>
              flatList.current?.scrollToIndex({
                animated: true,
                index: (index + pages.length - 1) % pages.length,
                viewPosition: 0.1,
              })
            }
          />
          <IconButton
            style={[s.listButton, styles.absolute, styles.right]}
            icon="chevron-right"
            onPress={() =>
              flatList.current?.scrollToIndex({
                animated: true,
                index: (index + 1) % pages.length,
                viewPosition: 0.9,
              })
            }
          />
        </>
      ) : undefined}
      <View style={s.progress}>
        <CircleStepProgress steps={pages.length} currentStep={index} />
      </View>
      <View style={screenStyle}>
        {buttonStyles?.[0]?.hidden ? undefined : (
          <Button
            style={s.button}
            icon={buttonIcon(0)}
            onPress={() => onSubmit?.("signUp")}
            mode={buttonStyles?.[0]?.mode ?? "contained"}
            labelStyle={buttonStyles?.[0]?.color ? { color: buttonStyles?.[0]?.color } : undefined}
          >
            {t("landing.signUp")}
          </Button>
        )}
        {buttonStyles?.[1]?.hidden ? undefined : (
          <Button
            style={[s.button, s.button1]}
            icon={buttonIcon(1)}
            onPress={() => onSubmit?.("signIn")}
            mode={buttonStyles?.[1]?.mode ?? "outlined"}
            labelStyle={buttonStyles?.[1]?.color ? { color: buttonStyles?.[1]?.color } : undefined}
          >
            {t("landing.signIn")}
          </Button>
        )}
        {showHtmlView ? (
          <Caption style={s.caption}>
            <Trans
              i18nKey="accountScreen.termsOfUse"
              // @ts-ignore
              components={{ a: <Link />, ...transComponents }}
            />
          </Caption>
        ) : (
          <View style={s.caption} />
        )}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    zIndex: 100,
    alignSelf: "center",
  },
  textContainer: {
    flex: 1,
    alignContent: "center",
    marginBottom: 200,
    minHeight: 200,
  },
  text: {
    textAlign: "center",
  },
  progress: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  absolute: {
    position: "absolute",
  },
  right: {
    right: 0,
  },
})
