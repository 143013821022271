import { RouteProp } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { Body2, firestore, H6, SectionSeparator, useTheme } from "capsule"
import _ from "lodash"
import React, { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, Platform, StyleSheet, View } from "react-native"

import { ItemList } from "../../common/List"
import { collections } from "../../common/types"
import { itemField } from "../../features/hooks/useItemI18n"
import { HomeNS } from "../../features/i18n/constants"
import { OperationParamList } from "../OperationScreen/OperationNavigator"
import PatientExerciseView from "../PatientScreen/PatientExerciseView"
import PhaseListView from "./PhaseListView"

interface IProps {
  navigation: StackNavigationProp<OperationParamList, "PhaseResume">
  route: RouteProp<OperationParamList, "PhaseResume">
}

export const renderSeparator = () => <SectionSeparator />

const PhaseResumeScreen: FC<IProps> = ({ route }) => {
  const { blockPhase } = route?.params
  const { t } = useTranslation()

  const {
    colors: {
      black: { highEmphasis: black },
      white: { highEmphasis: white },
      surface: { background },
    },
    dimensions: { spacing },
    screenStyle,
  } = useTheme()

  const s = useMemo(
    () => ({
      scrollview: [
        styles.scrollview,
        {
          backgroundColor: background,
        },
      ],
      view: [
        {
          backgroundColor: white,
          padding: spacing * 2,
        },
        Platform.OS !== "web" ? screenStyle : null,
      ],
      title: {
        color: black,
        paddingBottom: spacing,
      },
      description: {
        color: black,
      },
    }),
    [black, spacing, white, screenStyle, background],
  )

  const [blockTodos, setBlockTodos] = useState<ItemList[]>([])

  useEffect(() => {
    const fetchTodos = async () => {
      if (!_.isEmpty(blockPhase?.todo)) {
        const todoChunks = _.chunk(blockPhase.todo, 10)
        const todoPromises = todoChunks.map(chunk =>
          firestore()
            .collection(collections.TODO)
            .where("id", "in", chunk)
            .where("disabled", "==", false)
            .get(),
        )

        const snapshots = await Promise.all(todoPromises)
        const allTodos = snapshots.flatMap(snapshot =>
          snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id } as ItemList)),
        )
        setBlockTodos(allTodos)
      }
    }

    fetchTodos()
  }, [blockPhase?.todo])

  const filteredTodos = blockTodos?.filter(
    todo => !todo.url?.startsWith("https://doctup.page.link"),
  )

  const FlatListComponents = [
    <PatientExerciseView key="PhaseExercise" phase={blockPhase} />,
    <PhaseListView key="PhaseTodos" typeItem={t(`${HomeNS}:todo`)} listIds={filteredTodos} />,
  ]

  return (
    <View style={s.scrollview}>
      <FlatList
        ListHeaderComponent={
          <View style={s.view}>
            <H6 style={s.title}>{itemField(blockPhase, "titlePhysician", t)}</H6>
            <Body2 style={s.description}>{itemField(blockPhase, "descriptionPhysician", t)}</Body2>
          </View>
        }
        data={FlatListComponents}
        renderItem={({ item }) => <React.Fragment>{item}</React.Fragment>}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  scrollview: {
    flex: 1,
    overflow: "scroll",
  },
})

export default PhaseResumeScreen
