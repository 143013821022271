import { useField, useFormikContext } from "formik"
import React, { FC } from "react"
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native"
import { Switch as RNPSwitch } from "react-native-paper"

import useTheme from "../../features/Theme/ThemeProvider"

interface IProps {
  name: string
  label: string
  styles?: Partial<{ container: StyleProp<ViewStyle> }>
}

export const FormikSwitch: FC<IProps> = ({ name, label, styles = defaultStyles }) => {
  const [field] = useField(name)
  const { setFieldValue } = useFormikContext()
  const {
    colors: { overrides, primary, secondary },
  } = useTheme()
  return (
    <View style={styles.container}>
      <RNPSwitch
        value={field.value}
        trackColor={{
          true: overrides?.switch?.lineActive || primary,
          false: overrides?.switch?.lineInactive || secondary,
        }}
        onValueChange={() => setFieldValue(name, !field.value)}
      />
      <Text>{label}</Text>
    </View>
  )
}

const defaultStyles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
  },
})
