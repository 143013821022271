import { RouteProp } from "@react-navigation/core"
import { useNavigation } from "@react-navigation/native"
import {
  generateShadow,
  PresentationListView,
  SectionSeparator,
  Touchable,
  useTheme,
  VectorIcon,
} from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"
import { Chip } from "react-native-paper"

import FaqItem from "../../components/FaqItem"
import { ICON_SIZE } from "../../features/config/Constants"
import { FaqNS } from "../../features/i18n/constants"
import { faqContext, FaqNor } from "../../features/Providers/FaqProvider"
import { TabParamList } from "./FaqNavigator"

type FaqScreenRouteProp = RouteProp<TabParamList, "FaqTab_before" | "FaqTab_after">

type ChipType = { name: string; label: string }

interface IProps {
  route: FaqScreenRouteProp
}

const faqsKeyExtractor = item => item.id
const chipsKeyExtractor = item => item.name

const FaqScreen: FC<IProps> = ({ route }) => {
  const { t } = useTranslation(FaqNS)
  const [buttonState, setButtonState] = useState<Record<string, boolean | unknown>>({})
  const navigation = useNavigation<any>()

  const chips: ChipType[] = useMemo(
    () =>
      _.map(["tutorial", "information", "document"], i => ({
        label: t(`chips.${i}`),
        name: i,
      })),
    [t],
  )

  const {
    colors: {
      surface: { background, appUi, textInput },
      white: { highEmphasis: white },
      primary,
      black: { highEmphasis, mediumEmphasis, disabled },
      accent,
    },
    dimensions: { spacing },
    typography: { body2 },
  } = useTheme()

  const { filteredDataBySearch, error, loading, setSearchBarVisible } = useContext(faqContext)
  const filterData: FaqNor[] = useMemo(
    () => _.filter(filteredDataBySearch, o => o.when === route.params?.when),
    [filteredDataBySearch, route.params?.when],
  )

  const noChipSelected = useMemo(() => !_.some(buttonState), [buttonState])

  const filteredFaq: FaqNor[] = useMemo(
    () => (noChipSelected ? filterData : filterData?.filter(f => buttonState[f.type])),
    [buttonState, filterData, noChipSelected],
  )

  const onFaqItemPress = useCallback(
    item => () => {
      navigation.navigate("Faq_Article", { id: item?.item?.id })
      setSearchBarVisible(false)
    },
    [navigation, setSearchBarVisible],
  )

  const onFaqScroll = useCallback(() => {
    setSearchBarVisible(false)
  }, [setSearchBarVisible])

  const renderFaqItems: ListRenderItem<FaqNor> = useCallback(
    item => (
      <Touchable rippleColor={accent} onPress={onFaqItemPress(item)} style={styles.touchableRow}>
        <View>
          <FaqItem faq={item} />
        </View>
      </Touchable>
    ),
    [onFaqItemPress, accent],
  )

  const s = useMemo(
    () => ({
      view: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      section: {
        backgroundColor: appUi,
      },
      articleTitle: {
        color: highEmphasis,
      },
      articleDescription: {
        color: mediumEmphasis,
      },
      chip: [
        styles.chip,
        {
          marginHorizontal: spacing / 4,
          marginVertical: spacing / 2,
          ...body2,
        },
      ],
      articlesList: [
        styles.articlesList,
        {
          backgroundColor: white,
        },
      ],
      chipFlatList: {
        margin: spacing / 4,
      },
    }),
    [appUi, spacing, highEmphasis, mediumEmphasis, white, body2, background],
  )

  const indicatorOptions = useMemo(
    () => ({
      color: primary,
      size: ICON_SIZE,
    }),
    [primary],
  )

  const onChipPress = useCallback(
    name => () => {
      setButtonState(oldState => ({ ...oldState, [name]: !oldState[name] }))
    },
    [],
  )

  const chipIcon = useCallback(
    selected => (
      <VectorIcon
        name="check"
        category="MaterialIcons"
        size={20}
        color={selected ? highEmphasis : disabled}
      />
    ),
    [disabled, highEmphasis],
  )
  const renderChipsList: ListRenderItem<ChipType> = useCallback(
    item => {
      const selected = !!buttonState[item.item.name]
      return (
        <Chip
          icon={chipIcon}
          onPress={onChipPress(item.item.name)}
          selected={selected}
          style={[s.chip, { backgroundColor: selected ? accent : textInput }]}
        >
          {item.item.label}
        </Chip>
      )
    },
    [accent, buttonState, chipIcon, onChipPress, s.chip, textInput],
  )

  return (
    <View style={s.view}>
      <View>
        <FlatList<ChipType>
          data={chips}
          horizontal
          style={s.chipFlatList}
          renderItem={renderChipsList}
          keyExtractor={chipsKeyExtractor}
          showsHorizontalScrollIndicator={false}
        />
      </View>
      <PresentationListView
        isEmpty={_.isEmpty(filteredFaq)}
        indicator={indicatorOptions}
        loading={loading}
        error={error}
      >
        <FlatList
          data={filteredFaq}
          renderItem={renderFaqItems}
          keyExtractor={faqsKeyExtractor}
          style={[styles.shadow, s.articlesList]}
          ItemSeparatorComponent={SectionSeparator}
          onScrollBeginDrag={onFaqScroll}
        />
      </PresentationListView>
    </View>
  )
}
const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  shadow: generateShadow(1),
  articlesList: {
    flexGrow: 0,
  },
  touchableRow: {
    height: "auto",
  },
  chip: {
    alignItems: "center",
    height: 28,
  },
})

export default FaqScreen
