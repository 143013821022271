import { RouteProp } from "@react-navigation/core"
import { useNavigation } from "@react-navigation/native"
import { Body2, generateShadow, H6, IBodyProps, useStyles, useTheme, useUser } from "capsule"
import { useFormikContext } from "formik"
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, StatusBar, View } from "react-native"
import FastImage from "react-native-fast-image"
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context"

import { images } from "../../assets/images"
import OppositeRadioButton from "../../components/OppositeRadioButton"
import Rating from "../../components/Rating"
import SubmitButton from "../../components/SubmitButton"
import useSpecialtySystem from "../../features/hooks/useSpecialtySystem"
import { EvaluationNS } from "../../features/i18n/constants"
import { EvalType } from "../../features/models/Evaluation"
import { NextSessionAction } from "../../features/models/Session"
import { CongratType, RootParamList } from "../../features/Navigation/types"
import { programContext } from "../../features/Providers/ProgramProvider"
import { useSession } from "../../features/Providers/SessionProvider"
import { useItems } from "./Items"

type EveningEvalScreenRouteProp = RouteProp<RootParamList, "EveningEvaluation">

interface IProps {
  evalType: EvalType
  route: EveningEvalScreenRouteProp
}

const EvaluationScreen: FC<IProps> = ({ evalType, route }) => {
  const {
    colors: { surface },
  } = useTheme()
  const { t } = useTranslation(EvaluationNS)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<any>()
  const { handleSubmit, setFieldValue } = useFormikContext()
  const { userData } = useUser()
  const isOnco = userData?.specialty === "onco"
  const { currentPhase, currentSession } = useContext(programContext)
  const {
    endSession,
    endExercise,
    displayProgramCongratulation,
    displayMiSessionCongratulation,
  } = useSession()
  const { displayEvaluation } = useSpecialtySystem()
  const { efforts, pains } = useItems()
  const isEvening = evalType === EvalType.EVENING
  const isMorning = evalType === EvalType.MORNING
  useEffect(() => {
    if (currentPhase) {
      setFieldValue("phase", currentPhase.id)
    }
    if (currentSession?.start) {
      setFieldValue("start", currentSession.start)
    }
  }, [currentPhase, setFieldValue, currentSession?.start])

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        accent,
        surface: { background },
      },
    }) => ({
      body: {
        backgroundColor: accent,
        borderRadius: 8,
        margin: spacing,
        overflow: "hidden",
        padding: spacing,
      },
      button: {
        width: "100%",
        padding: spacing,
      },
      content: {
        alignItems: "center",
        paddingBottom: spacing * 2,
      },
      flex: {
        flex: 1,
        backgroundColor: background,
      },
      header: {
        alignItems: "center",
        paddingTop: insets.top,
      },
      image: {
        height: 130,
        width: 130,
      },
      innerView: {
        marginBottom: spacing,
        padding: spacing,
      },
      sectionTitle: {
        paddingBottom: spacing,
        paddingTop: spacing * 2,
        textAlign: "center",
      },
      title: {
        paddingBottom: spacing * 1.5,
        textAlign: "center",
      },
      view: {
        backgroundColor: background,
        ...generateShadow(1),
      },
    }),
    [insets.top],
  )

  const h6Props = useMemo<Omit<IBodyProps, "children">>(
    () => ({
      emphasis: "high",
      style: s.sectionTitle,
    }),
    [s.sectionTitle],
  )

  const onSubmit = useCallback(async () => {
    handleSubmit?.()
    if (evalType === EvalType.EXERCISE) {
      if (route?.params?.validationExIsLast) {
        endSession()
        navigation.navigate("Congratulation", { type: CongratType.program })
        return
      }
      const result = await endExercise?.(true)
      const displayProgramCongrat = displayProgramCongratulation()
      const displayEveningEval = displayEvaluation(true)
      if (result === NextSessionAction.CLOSE) {
        endSession()
        navigation.navigate(
          displayEveningEval ? "EveningEvaluation" : "Congratulation",
          displayEveningEval
            ? { endProgram: displayProgramCongrat }
            : {
                type: displayProgramCongrat ? CongratType.program : CongratType.session,
              },
        )
      } else {
        const displayMiSessionCongrat = displayMiSessionCongratulation()
        navigation.navigate(displayMiSessionCongrat ? "Congratulation" : "Exercise", {
          type: CongratType.misession,
        })
        return
      }
    }
    navigation.navigate(
      isEvening ? "Congratulation" : "Exercise",
      isEvening
        ? { type: route?.params?.endProgram ? CongratType.program : CongratType.session }
        : undefined,
    )
  }, [
    handleSubmit,
    evalType,
    navigation,
    isEvening,
    route?.params?.endProgram,
    route?.params?.validationExIsLast,
    endExercise,
    displayProgramCongratulation,
    displayEvaluation,
    endSession,
    displayMiSessionCongratulation,
  ])

  const commonProps = useMemo(
    () => ({
      left: { label: t("common:button.yes"), value: "yes" },
      right: { label: t("common:button.no"), value: "no" },
    }),
    [t],
  )

  const innerComponents = useMemo(
    () => ({
      [EvalType.EXERCISE]: (
        <>
          <H6 {...h6Props}>{t("increaseDifficulty")}</H6>
          <OppositeRadioButton name="validation" {...commonProps} split={true} />
        </>
      ),
      [EvalType.MORNING]: (
        <>
          {isOnco ? (
            <>
              <H6 {...h6Props}>{t("fatigue")}</H6>
              <Rating name="fatigue" data={pains} />
              <H6 {...h6Props}>{t("morningPain")}</H6>
              <Rating name="pain" data={pains} />
            </>
          ) : (
            <>
              <H6 {...h6Props}>{t("morningPain")}</H6>
              <Rating name="pain" data={pains} />
              <H6 {...h6Props}>{t("stiffness")}</H6>
              <OppositeRadioButton name="stiffness" {...commonProps} />
            </>
          )}
        </>
      ),
      [EvalType.EVENING]: (
        <>
          <H6 {...h6Props}>{t("difficulty")}</H6>
          <Rating name="effort" data={efforts} />
          {isOnco ? (
            <>
              <H6 {...h6Props}>{t("fatigue")}</H6>
              <Rating name="fatigue" data={pains} />
            </>
          ) : (
            <>
              <H6 {...h6Props}>{t("eveningPain")}</H6>
              <Rating name="pain" data={pains} />
            </>
          )}
        </>
      ),
    }),
    [commonProps, efforts, h6Props, isOnco, pains, t],
  )

  return (
    <SafeAreaView style={s.flex}>
      <StatusBar barStyle="dark-content" backgroundColor={surface.background} />
      <ScrollView contentContainerStyle={s.view}>
        <View style={s.innerView}>
          <View style={s.header}>
            <H6 emphasis="high" style={s.title}>
              {t(isEvening ? "evening" : isMorning ? "morning" : "exercise")}
            </H6>
            <FastImage source={images.evaluation as number} style={s.image} />
          </View>
          {evalType ? innerComponents[evalType] : null}
        </View>
        <Body2 emphasis="high" style={s.body}>
          {t("information")}
        </Body2>
      </ScrollView>
      <View style={s.button}>
        <SubmitButton {...{ onSubmit }} label={t("common:button.continue")} />
      </View>
    </SafeAreaView>
  )
}

export default EvaluationScreen
