import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, StyleSheet, ViewStyle } from "react-native"
import { Dialog, Modal, Portal } from "react-native-paper"

/** Do not shorten to avoid `cycles warning` */
import { IDialogInternal } from "../../features/Providers/AlertInterfaces"
import useTheme from "../../features/Theme/ThemeProvider"
import { Body2, H6 } from "../Texts"
import AlertDialogButtons from "./AlertDialogButtons"

interface IProps {
  isValid?: boolean
  dialog: IDialogInternal
}

const AlertDialog: FC<IProps> = ({
  dialog: {
    title,
    message,
    neutral,
    positive,
    negative,
    children,
    titleStyle,
    messageStyle,
    ...rest
  },
  isValid,
}) => {
  const {
    colors: {
      surface: { appUi },
    },
    typography: { h6, body2 },
    dimensions: { spacing },
    button,
  } = useTheme()
  const { t } = useTranslation()

  const s = useMemo(
    () => ({
      title: [h6, titleStyle],
      message: [body2, messageStyle],
      modal: [
        {
          backgroundColor: appUi,
          marginHorizontal: spacing * 2,
          borderRadius: button?.roundness,
        },
        styles.modal,
        rest.contentContainerStyle,
      ] as StyleProp<ViewStyle>,
    }),
    [
      h6,
      titleStyle,
      body2,
      messageStyle,
      rest.contentContainerStyle,
      appUi,
      spacing,
      button?.roundness,
    ],
  )

  return (
    <Portal>
      <Modal
        {...rest}
        contentContainerStyle={s.modal}
        overlayAccessibilityLabel={t("alert.dismiss")}
      >
        {title ? (
          <Dialog.Title style={s.title}>
            <H6>{title}</H6>
          </Dialog.Title>
        ) : null}
        {message ? (
          <Dialog.Content style={styles.dialogContent}>
            <Body2 style={s.message}>{message}</Body2>
          </Dialog.Content>
        ) : null}
        {children}
        <AlertDialogButtons {...{ neutral, positive, negative, isValid }} />
      </Modal>
    </Portal>
  )
}

const styles = StyleSheet.create({
  dialogContent: {
    paddingBottom: 0,
  },
  modal: {
    alignSelf: "center",
  },
})

export default AlertDialog
