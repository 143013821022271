import { Subtitle2, useTheme, VectorIcon } from "capsule"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, TouchableOpacity, View } from "react-native"

import { Phase } from "../common/Phase"
import { ICON_SIZE } from "../features/config/Constants"
import { JourneyNS } from "../features/i18n/constants"

interface ExercisesNavigationButtonsProps {
  previousPhase: Phase | undefined
  upcomingPhase: Phase | undefined
  handleSelectedPhase: (isGoBack?: boolean) => void
  isPatient: boolean
  isCurrentSession: boolean
}

const ExercisesNavigationButtons: FC<ExercisesNavigationButtonsProps> = ({
  previousPhase,
  upcomingPhase,
  handleSelectedPhase,
  isPatient,
  isCurrentSession,
}) => {
  const { t } = useTranslation(JourneyNS)
  const {
    fontMaker,
    dimensions: { spacing },
    colors: {
      white: { highEmphasis: white },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      text: [fontMaker({ weight: "Bold" }), { paddingBottom: spacing / 8 }],
    }),
    [fontMaker, spacing],
  )

  return (
    <View style={!isPatient && previousPhase && upcomingPhase ? styles.buttonsContainer : null}>
      {previousPhase && (!isPatient || (isPatient && !isCurrentSession)) ? (
        <TouchableOpacity style={styles.backButton} onPress={() => handleSelectedPhase(true)}>
          <VectorIcon
            size={ICON_SIZE}
            color={isCurrentSession ? white : undefined}
            name="chevron-left"
            category="MaterialIcons"
          />
          <Subtitle2 style={[s.text, isCurrentSession && { color: white }]}>
            {isPatient ? t("exercicesView.currentExos") : t("exercicesView.phaseBefore")}
          </Subtitle2>
        </TouchableOpacity>
      ) : null}
      {upcomingPhase && (!isPatient || (isPatient && isCurrentSession)) ? (
        <TouchableOpacity style={styles.nextButton} onPress={() => handleSelectedPhase()}>
          <Subtitle2 style={[s.text, isCurrentSession && !isPatient && { color: white }]}>
            {isPatient ? t("exercicesView.exosTocome") : t("exercicesView.phaseToCome")}
          </Subtitle2>
          <VectorIcon
            size={ICON_SIZE}
            color={isCurrentSession && !isPatient ? white : undefined}
            name="chevron-right"
            category="MaterialIcons"
          />
        </TouchableOpacity>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nextButton: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  backButton: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})

export default ExercisesNavigationButtons
