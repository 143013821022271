import i18n, { Resource } from "i18next"
import { useContext } from "react"
import { initReactI18next } from "react-i18next"
import { getLocales } from "react-native-localize"

import { AppUserData } from "../../../features/models/UserData"
import { LanguageSelector } from "../../../screens/LanguageScreen/Constants"
/** Do not shorten to avoid `cycles warning` */
import { IUserContext, userContext } from "../Providers"
import { COMMON_NAMESPACE } from "./constants"

interface IProps {
  translation: Resource
}

const I18nInit: (props: IProps) => typeof i18n = ({ translation }) => {
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const supportedLanguages = LanguageSelector.map(lang => lang.languageCode)
  const deviceLanguage = getLocales()[0].languageCode
  const fallbackLanguage = "fr"
  const effectiveLanguage = supportedLanguages.includes(deviceLanguage)
    ? deviceLanguage
    : fallbackLanguage

  // noinspection JSIgnoredPromiseFromCall
  i18n.use(initReactI18next).init({
    fallbackLng: fallbackLanguage,
    lng: userData?.lng || effectiveLanguage,
    resources: translation,
    ns: [COMMON_NAMESPACE],
    defaultNS: COMMON_NAMESPACE,
    debug: __DEV__,
    cache: {
      enabled: true,
    },
    react: {
      useSuspense: false,
    },
  })
  return i18n
}

export default I18nInit
