import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { TFunction } from "i18next"
import * as Yup from "yup"

import { ID } from "../../common/CommonUserData"
import { AppUserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { FormikParams } from "../../features/Navigation/FormikScreen"

export interface OncoFormValues {
  pathology: ID
  organ: ID
}

export const defaultOncoFormValues: OncoFormValues = {
  pathology: "",
  organ: "",
}

export const operationValidationSchema = (t: TFunction, ns: string) =>
  Yup.object({
    pathology: Yup.string().required(t("required.pathology", { ns })),
  })

export const onSubmitOperation = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
) => async (values: OncoFormValues): Promise<any> => {
  await updateUserData(userDocRef, values)
}

export const oncoFormikParams = (t: TFunction, ns: string, userData?: Partial<AppUserData>) =>
  ({
    onSubmit: onSubmitOperation,
    initialValues: userData?.pathology
      ? {
          organ: userData?.organ,
          pathology: userData?.pathology,
        }
      : defaultOncoFormValues,
    validationSchema: operationValidationSchema(t, ns),
  } as FormikParams<OncoFormValues>)
