/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DarkTheme as RNDarkTheme, DefaultTheme as NavigationTheme } from "@react-navigation/native"
import _ from "lodash"
import React, { ReactNode, useContext } from "react"
import { MD2DarkTheme, MD2LightTheme, Provider } from "react-native-paper"
import { MD2Theme } from "react-native-paper/lib/typescript/src/types"

import fontMaker from "./fontMaker"
// TODO: warning require cycle with theme and ThemeProvider
import theme from "./theme"
import {
  defaultStyles,
  defaultWeights,
  FmFunc,
  ICSTheme,
  IFontTheme,
  PartialPartial,
  PartialPartialPartial,
} from "./types"
import typoGen from "./typography"

export type Theme = Omit<ICSTheme, "typoGen"> & { fontMaker: FmFunc }
const themeContext = React.createContext<Theme>({
  ...theme,
  typography: typoGen({ ...theme, fontMaker: fontMaker(theme) }),
  fontMaker: fontMaker(theme),
})

// noinspection JSUnusedGlobalSymbols
export const makeNavigationTheme = (appTheme: Theme) => {
  const RNavigationTheme = appTheme.darkMode ? RNDarkTheme : NavigationTheme

  return {
    ...RNavigationTheme,
    colors: {
      ...RNavigationTheme.colors,
      primary: _.get(appTheme, "colors.primary", RNavigationTheme.colors.primary),
      background: _.get(appTheme, "colors.surface.appUi", RNavigationTheme.colors.background),
      text: _.get(appTheme, "colors.black.highEmphasis", RNavigationTheme.colors.text),
    },
  }
}

const makePaperTheme = (appTheme: Theme): MD2Theme => {
  const paperTheme = appTheme.darkMode ? MD2DarkTheme : MD2LightTheme

  return {
    ...paperTheme,
    ...appTheme,
    dark: appTheme.darkMode,
    colors: {
      ...paperTheme.colors,
      primary: _.get(appTheme, "colors.primary", paperTheme.colors.primary),
      accent: _.get(appTheme, "colors.secondary", paperTheme.colors.accent),
      background: _.get(appTheme, "colors.surface.appUi", paperTheme.colors.background),
      text: _.get(appTheme, "colors.black.highEmphasis", paperTheme.colors.text),
      notification: _.get(appTheme, "colors.primary", paperTheme.colors.notification),
    },
    fonts: {
      regular: {
        fontFamily: appTheme.fontMaker({ weight: "Regular" }).fontFamily!,
      },
      medium: {
        fontFamily: appTheme.fontMaker({ weight: "Medium" }).fontFamily!,
      },
      light: {
        fontFamily: appTheme.fontMaker({ weight: "Light" }).fontFamily!,
      },
      thin: {
        fontFamily: appTheme.fontMaker({ weight: "Thin" }).fontFamily!,
      },
    },
  }
}

const applyFallbacks = (csTheme: ICSTheme) => {
  csTheme.colors.white.header = _.get(
    csTheme,
    "colors.white.header",
    csTheme.colors.white.highEmphasis,
  )
  csTheme.colors.surface.header = _.get(csTheme, "colors.surface.header", csTheme.colors.primary)
  return csTheme
}

function getTheme<AppTheme extends Theme = Theme>(
  parentTheme?: PartialPartial<AppTheme>,
  csTheme?: PartialPartialPartial<AppTheme>,
) {
  return applyFallbacks(_.merge({}, theme, parentTheme as ICSTheme, csTheme as ICSTheme))
}

export function ThemeProvider<AppTheme extends Theme = Theme>({
  children,
  customTheme,
}: {
  children: ReactNode
  customTheme: PartialPartialPartial<AppTheme>
}) {
  const parentTheme = useContext(themeContext)
  const csTheme: Theme = (getTheme(parentTheme, customTheme) as unknown) as Theme
  _.forEach((csTheme.fonts as unknown) as IFontTheme[], f => {
    if (f.weights === undefined) {
      f.weights = defaultWeights
    }
    if (f.styles === undefined) {
      f.styles = defaultStyles
    }
  })
  csTheme.fontMaker = fontMaker(csTheme)
  const defaultTypo = typoGen(csTheme)
  const localTypo = ((customTheme as unknown) as ICSTheme)?.typoGen?.(
    (csTheme as unknown) as ICSTheme,
  )
  csTheme.typography = _.merge({}, defaultTypo, parentTheme.typography, localTypo)

  // console.log(JSON.stringify({ "*default": defaultTypo, "**parent": parentTheme.typography, "***local": localTypo, "****merged": csTheme.typography }, undefined, 2))

  return (
    <themeContext.Provider value={csTheme}>
      <Provider theme={makePaperTheme(csTheme)}>{children}</Provider>
    </themeContext.Provider>
  )
}

function useTheme<AppTheme extends Theme = Theme>(): AppTheme {
  return (useContext<Theme>(themeContext) as unknown) as AppTheme
}

export default useTheme
