import { NavigatorScreenParams } from "@react-navigation/core"

import { ExerciseParamList } from "../../screens/ExerciseScreen/ExerciseNavigator"

// eslint-disable-next-line no-shadow
export enum CongratType {
  session,
  misession,
  program,
  phase,
  end,
}

export type OnboardingParamList = {
  /** Sign in screen names of patient */
  Welcome: undefined
  Cgu: undefined
  ResearchData: undefined
  ActivationCode: undefined
  Phone: undefined
  SMSValidation: { phoneNumber: string }
  Specialty: undefined
  OperationNavigator: undefined
  OncoPathologyNavigator: undefined
  Profile: undefined
  Alert: undefined
  Language: undefined
  /** Sign in screen names of practitioner */
  Practitioner: undefined
  PractitionerProfile: undefined
  SurgeonPractice: undefined
  PromsNavigator: undefined
  Video: undefined
  ShareContact: undefined
  KineSpecialties: undefined
  Address: undefined
  RdvUrl: undefined
}

export type RootParamList = {
  Onboarding: undefined
  /** main screen names of patient */
  MainNavigator: undefined
  ExerciseNavigator: NavigatorScreenParams<ExerciseParamList>
  Congratulation: { type: CongratType }
  MorningEvaluation: { endProgram?: boolean }
  EveningEvaluation: { endProgram?: boolean; validationExIsLast?: boolean }
  /** edition screen names of patient */
  SMSValidationEdition: { phoneNumber: string }
  PhoneEdition: undefined
  LanguageEdition: undefined
  OperationEdition: undefined
  ProfileEdition: undefined
  AlertEdition: undefined
  ParametersEdition: undefined
  /** edition screen names of practitioner */
  AddPatient: undefined
  ShareContactEdition: undefined
  KineListData: undefined
  KineSpecialtiesEdition: undefined
  AddressEdition: undefined
  DeleteAccount: undefined
  PractitionerProfileEdition: undefined
  SurgeonPracticeEdition: undefined
  ReviewUrl: undefined
  RdvUrl: undefined
  /** edition screen onco data */
  OncoDataEdition: undefined
  OncoSurgeries: undefined
  Therapies: undefined
  OtherPathologies: undefined
  HeartDisease: undefined
  RespiratoryDisease: undefined
  /** PROMS */
  PromsNavigator: undefined
  Research: undefined
}
