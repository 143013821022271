import React, { FC } from "react"
import { Text, TextProps } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import msToTime from "../../utils/msToTime"

interface IProps {
  time: number
}

const Clock: FC<IProps & TextProps> = ({ style, time, ...props }) => {
  const theme = useTheme()

  return (
    <Text {...props} style={[theme.typography.button, style]}>
      {msToTime(time, false)}
    </Text>
  )
}

export default Clock
