const DEFAULT_DELTA = {
  LAT: 0.000185 * 2,
  LNG: 0.025947 * 2,
}

const START_REGION = {
  latitude: 48.864716,
  longitude: 2.349014,
  latitudeDelta: 15,
  longitudeDelta: 15,
}

const ZOOM = {
  max: 15,
  min: 0,
}

export { ZOOM, DEFAULT_DELTA, START_REGION }
