import { timeVals } from "./msToTime"

const millisInSec = 1000

const hmsToMillis = (hms: string) => {
  const hmsSplit = hms.split(":")

  return (
    (+hmsSplit[0] * timeVals.SecInMin * timeVals.MinInHours +
      +hmsSplit[1] * timeVals.MinInHours +
      +hmsSplit[2]) *
    millisInSec
  )
}

export default hmsToMillis
