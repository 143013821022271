import React, { useCallback, useMemo, useRef, useState } from "react"

/** Do not shorten to avoid `cycles warning` */
import {
  DialogResponse,
  IDialogInternal,
  TextInputDialogResponse,
} from "../../features/Providers/AlertInterfaces"
import { useAlert } from "../../features/Providers/AlertProvider"
import useTheme from "../../features/Theme/ThemeProvider"
import { TextInput, TextInputProps } from "../TextInput"

interface IProps {
  validator?: (...args: any) => boolean
  limit?: number
}

const ControlledTextInput = ({
  onChangeText: onChange,
  validator,
  defaultValue = "",
  ...props
}: TextInputProps & {
  validator?: (val: string) => boolean
}) => {
  const [value, setValue] = useState(defaultValue)

  const onChangeText = useCallback(
    (text: string) => {
      if (text.length > 0 && validator && !validator(text)) {
        return
      }
      setValue(text)
      onChange?.(text)
    },
    [onChange, validator],
  )

  return <TextInput {...{ ...props, onChangeText, value }} />
}

const useTextInputDialog = ({ limit, validator }: IProps = { limit: 1 }) => {
  const { showDialog, setIsValid } = useAlert()
  const text = useRef("")
  const {
    dimensions: { margin },
  } = useTheme()

  const s = useMemo(
    () => ({
      textInput: { marginHorizontal: margin * 2 },
    }),
    [margin],
  )

  const onChangeText = useCallback(
    (val: string) => {
      text.current = val
      if (limit) {
        setIsValid?.(val.length >= limit)
      }
    },
    [limit, setIsValid],
  )

  const showTextDialog = useCallback(
    async (
      dialog: Omit<IDialogInternal, "type" | "visible" | "onDismiss">,
      textInputProps?: TextInputProps,
      defaultValue?: string,
    ): Promise<TextInputDialogResponse> => {
      const res = (await showDialog({
        type: "text",
        ...dialog,
        children: (
          <ControlledTextInput
            {...{ ...textInputProps, onChangeText, validator, defaultValue }}
            style={s.textInput}
          />
        ),
      })) as DialogResponse
      return { button: res.button, text: text.current }
    },
    [onChangeText, s.textInput, showDialog, validator],
  )

  return { showTextDialog }
}

export default useTextInputDialog
