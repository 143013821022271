import React, { FC, Ref } from "react"
import {
  FlatList,
  Image,
  ImageSourcePropType,
  ListRenderItemInfo,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from "react-native"
import { RadioButton, ToggleButton } from "react-native-paper"

import { IColorTheme } from "../../features/Theme"
import useTheme from "../../features/Theme/ThemeProvider"
import { Caption } from "../Texts"
import { Touchable } from "../Touchable"
import { isVectorIcon, VectorIcon, VectorIconProps } from "../VectorIcon"

type RadioButtonProps = React.ComponentProps<typeof RadioButton>

export interface IChoice {
  label?: string
  value: string
  color?: string
  disabled?: boolean
  icon: VectorIconProps | ImageSourcePropType
  uncheckedColor?: string
}

interface IProps {
  onValueChange: (value: string) => void
  captionStyle?: StyleProp<TextStyle>
  iconStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  selectedColor?: string
  ref?: Ref<typeof ToggleButton.Group>
  value: string
  items: IChoice[]
  direction?: "row" | "column" | "row-reverse" | "column-reverse"
  rowStyle?: StyleProp<ViewStyle>
  onPress?: () => void
  numColumns?: number
}

interface IRenderItemProps
  extends Pick<IProps, "onValueChange" | "captionStyle" | "iconStyle" | "selectedColor"> {
  selected: string
  spacing: number
  colors: IColorTheme
}

const keyExtractor = (elem: IChoice) => elem.value

const renderItem = ({
  colors,
  selected,
  captionStyle,
  onValueChange,
  selectedColor,
  iconStyle,
  spacing,
}: IRenderItemProps) => ({
  item: { value, icon, label, disabled = false },
}: ListRenderItemInfo<IChoice>) => {
  const onPress = () => {
    onValueChange(value)
  }
  const { black } = colors

  const elemStyle = [
    styles.toggleButton,
    !disabled && styles.shadowBox,
    iconStyle,
    {
      marginHorizontal: spacing / 2,
      marginVertical: spacing / 4,
      backgroundColor:
        value === selected
          ? selectedColor || colors.secondary
          : disabled
          ? colors.surface.disabled
          : colors.white.highEmphasis,
    },
  ]
  const color = black[disabled ? "inactive" : "highEmphasis"]
  const textStyle = [
    captionStyle,
    {
      color,
    },
  ]
  const iconButtonColor = colors.overrides?.iconButton || (icon as VectorIconProps)?.color || color

  return (
    <Touchable style={elemStyle} onPress={onPress} disabled={disabled}>
      <>
        {isVectorIcon(icon) ? (
          <VectorIcon {...icon} color={iconButtonColor} />
        ) : (
          <Image source={icon} />
        )}
        {label ? (
          <Caption emphasis="high" style={textStyle}>
            {label}
          </Caption>
        ) : null}
      </>
    </Touchable>
  )
}

const SingleToggleGroup: FC<IProps & RadioButtonProps> = ({
  items,
  rowStyle,
  iconStyle,
  captionStyle,
  selectedColor,
  onValueChange,
  numColumns = 3,
  contentStyle,
  ...props
}) => {
  const {
    colors,
    dimensions: { spacing },
  } = useTheme()

  return (
    <ToggleButton.Row style={rowStyle} onValueChange={onValueChange} {...props}>
      <FlatList
        data={items}
        numColumns={numColumns}
        contentContainerStyle={[styles.container, contentStyle]}
        columnWrapperStyle={styles.wrapper}
        renderItem={renderItem({
          iconStyle,
          captionStyle,
          onValueChange,
          selected: props.value,
          selectedColor,
          spacing,
          colors,
        })}
        keyExtractor={keyExtractor}
      />
    </ToggleButton.Row>
  )
}

const styles = StyleSheet.create({
  toggleButton: {
    height: 72,
    width: 72,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  shadowBox: {
    shadowOffset: {
      width: 0,
      height: 2,
    },
    elevation: 2,
    shadowRadius: 3,
    shadowOpacity: 0.2,
  },
  container: {
    alignItems: "center",
  },
  wrapper: {
    justifyContent: "center",
  },
  icon: {
    margin: 0,
  },
})

export default SingleToggleGroup
