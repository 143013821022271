import React, { FC, isValidElement, ReactNode } from "react"
import { View } from "react-native"

import { VectorIcon, VectorIconProps } from "../VectorIcon"

interface IProps {
  child: ReactNode | VectorIconProps
}

const RowSideItem: FC<IProps> = ({ child }) =>
  isValidElement(child) ? (
    <View>{child}</View>
  ) : (child as VectorIconProps)?.category ? (
    <VectorIcon {...(child as VectorIconProps)} />
  ) : null
export default RowSideItem
