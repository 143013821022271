import { useNavigation } from "@react-navigation/native"
import { firestore, H6, Subtitle2, useTheme, VectorIcon } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet, Text, View } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"

import { collections } from "../common/types"
import { ICON_SIZE, ICON_SIZE_MEDIUM } from "../features/config/Constants"
import { JourneyNS } from "../features/i18n/constants"
import { EvalFormValues } from "../features/models/Evaluation"
import { Patient } from "../features/models/UserData"
import { useItems } from "../screens/EvaluationScreen/Items"
import EvalItemsList from "./EvalItemsList"
import { RadioItem } from "./Rating"

interface ProtocolEvaluationsViewrops {
  patient: Patient
}

const ProtocolEvaluationsView: FC<ProtocolEvaluationsViewrops> = ({ patient }) => {
  const {
    fontMaker,
    colors: {
      primary,
      white: { highEmphasis: white },
    },
    typography: { subtitle1 },
    dimensions: { spacing },
  } = useTheme()
  const navigation = useNavigation<any>()
  const { t } = useTranslation(JourneyNS)
  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          backgroundColor: white,
          margin: spacing,
          paddingVertical: spacing,
          paddingHorizontal: spacing / 2,
        },
      ],
      title: [
        fontMaker({ weight: "Bold" }),
        {
          fontSize: subtitle1.fontSize,
        },
      ],
      subtitle: [
        fontMaker({ weight: "Bold" }),
        styles.subtitle,
        {
          margin: spacing,
        },
      ],
      buttonText: [
        fontMaker({ weight: "Bold" }),
        {
          color: primary,
          paddingBottom: spacing / 8,
        },
      ],
    }),
    [spacing, white, fontMaker, primary, subtitle1.fontSize],
  )
  const isOnco = patient.specialty === "onco"
  const { efforts, pains } = useItems(undefined, ICON_SIZE_MEDIUM)
  const [morning] = useCollectionData<EvalFormValues>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient.id)
      .collection(collections.EVAL_MORNING)
      .orderBy("start", "desc")
      .limit(1) as unknown) as firebase.firestore.Query<EvalFormValues>,
  )
  const [evening] = useCollectionData<EvalFormValues>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient.id)
      .collection(collections.EVAL_EVENING)
      .orderBy("start", "desc")
      .limit(1) as unknown) as firebase.firestore.Query<EvalFormValues>,
  )
  const painEval = _([morning?.[0], evening?.[0]])
    // @ts-ignore
    .filter(o => o?.pain)
    .orderBy(["start"], "desc")
    .head()

  const fatigueEval = _([morning?.[0], evening?.[0]])
    // @ts-ignore
    .filter(o => o?.fatigue)
    .orderBy(["start"], "desc")
    .head()

  const effort: RadioItem | undefined = evening
    ? _.find(efforts, e => e.value === Number(evening[0]?.effort))
    : undefined
  const pain: RadioItem | undefined = painEval
    ? _.find(pains, e => e.value === Number(painEval?.pain))
    : undefined
  const fatigue: RadioItem | undefined = evening
    ? _.find(pains, e => e.value === Number(fatigueEval?.fatigue))
    : undefined
  return (
    <View style={s.container}>
      <View style={styles.buttonsContainer}>
        <H6 style={s.title}>{t("eval.title")}</H6>
        <TouchableOpacity
          style={styles.evalButton}
          onPress={() => navigation.navigate("Patient_Graph", { id: patient.id })}
        >
          <Text style={s.buttonText}>{t("eval.navigation")}</Text>
          <VectorIcon
            size={ICON_SIZE}
            name="chevron-right"
            category="MaterialIcons"
            color={primary}
          />
        </TouchableOpacity>
      </View>
      {effort || pain || (fatigue && isOnco) || (morning?.[0]?.stiffness && !isOnco) ? (
        <EvalItemsList
          effort={effort}
          pain={pain}
          fatigue={fatigue}
          morning={morning}
          isOnco={isOnco}
        />
      ) : (
        <Subtitle2 style={s.subtitle}>{t("eval.noEval")}</Subtitle2>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    overflow: "hidden",
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  evalButton: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
  },
})

export default ProtocolEvaluationsView
