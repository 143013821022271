import { useFormikContext } from "formik"

export function usePartialValidation<T>() {
  const { setErrors, setTouched, validateForm } = useFormikContext<T>()

  async function partialValidation(fields: Array<keyof T>) {
    const errors = await validateForm()

    setTouched(fields.reduce((acc, field) => ({ ...acc, [field]: true }), {}))

    setErrors(errors)
    return fields.every(field => !errors[field])
  }
  return partialValidation
}
