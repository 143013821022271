import React, { FC } from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { IconButton, RadioButton } from "react-native-paper"
import Touchable from "react-native-touchable-safe"

import { Caption } from "../Texts"

type RadioButtonProps = React.ComponentProps<typeof RadioButton>
type IconProps = Pick<React.ComponentProps<typeof IconButton>, "icon">

interface IProps {
  label: string
  onPress?: () => void
  iconStyle?: StyleProp<ViewStyle>
  selectedColor?: string
  captionStyle?: StyleProp<TextStyle>
}

const ToggleButton: FC<IProps & RadioButtonProps & IconProps> = ({
  label,
  icon,
  disabled,
  captionStyle,
}) => (
  <Touchable ios="opacity" android="native" disabled={disabled}>
    <View>
      {icon && <IconButton icon={icon} style={{ margin: 0 }} />}
      {label && (
        <Caption emphasis="high" style={captionStyle}>
          {label}
        </Caption>
      )}
    </View>
  </Touchable>
)

export default ToggleButton
