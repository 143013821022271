/* eslint-disable */
import { useCallback, useState } from "react"

const useToggle = (initialValue: boolean = false, onChange?: (value: boolean) => void) => {
  const [value, setValue] = useState(initialValue)

  const setValueAndNotify = useCallback(
    (val: boolean) => {
      setValue(val)
      if (onChange) {
        onChange(val)
      }
    },
    [onChange],
  )

  const toggle = useCallback(() => {
    setValueAndNotify(!value)
  }, [value, setValueAndNotify])

  const setTrue = useCallback(() => {
    setValueAndNotify(true)
  }, [])

  const setFalse = useCallback(() => {
    setValueAndNotify(false)
  }, [])

  return { value, toggle, setFalse, setTrue }
}

export default useToggle
