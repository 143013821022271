import { RouteProp } from "@react-navigation/core"
import { useIsFocused } from "@react-navigation/native"
import { StackActions } from "@react-navigation/routers"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  BottomButtonContainer,
  EmptyView,
  firestore,
  generateShadow,
  ISO_8601_DATE,
  IUserContext,
  logger,
  userContext,
  useTheme,
} from "capsule"
import dayjs from "dayjs"
import { useFormikContext } from "formik"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { GestureResponderEvent, StyleSheet, View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

import { Phase } from "../../common/Phase"
import { FormResult, Interstitial } from "../../common/Proms"
import { collections } from "../../common/types"
import FormQuestions from "../../components/FormQuestions"
import PromsFormHeader from "../../components/PromsFormHeader"
import PromsProgressionHeader from "../../components/PromsProgressionHeader"
import ScaleForm from "../../components/ScaleForm"
import SubmitButton from "../../components/SubmitButton"
import {
  JAUGE_SCORE,
  ONCO_SURGERY_FORM,
  RATING_SCORE,
  SURGERY_SETTINGS,
} from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import useSpecialtySystem from "../../features/hooks/useSpecialtySystem"
import {
  calculateLimitStartDate,
  handleUpdateProgram,
  navigateToQuestion,
  navigateToWelcome,
  setOptionalScreen,
} from "../../features/models/OncoFormFunctions"
import { createDailyPhase, usePhases } from "../../features/models/PhaseAndSessionFunctions"
import { AppUserData } from "../../features/models/UserData"
import { editUserData, updateUserData } from "../../features/models/UserDataFunctions"
import { programContext } from "../../features/Providers/ProgramProvider"
import { promsContext } from "../../features/Providers/PromsProvider"
import { PromsParamList } from "./PromsNavigator"

interface IProps {
  navigation: StackNavigationProp<PromsParamList, "Proms_Form">
  route: RouteProp<PromsParamList, "Proms_Form">
}

function isEvent(field: string | GestureResponderEvent): field is GestureResponderEvent {
  return (field as GestureResponderEvent).nativeEvent !== undefined
}

const PromsScreen: FC<IProps> = ({ navigation, route }) => {
  const isFocused = useIsFocused()
  const { form, getScore, setForm, loadSigninForm, setLoading } = useContext(promsContext)
  const { user, userDocRef, userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { t } = useTranslation()
  const { values, resetForm } = useFormikContext<FormResult>()
  const { cleanPhase, phases } = useContext(programContext)
  const { initDailyPhase, createLocalNotifications } = useSpecialtySystem()
  const paramId = useMemo(() => route.params?.id, [route.params?.id])
  const isEditForm = form?.isEdit
  const question = useMemo(() => form?.questions?.[paramId], [form?.questions, paramId])
  const data = useMemo(() => question?.options ?? [], [question?.options])
  const type = question?.type
  const isScale = type === "scale"
  const name = question?.name ?? ""
  const isRating = form?.thanks === RATING_SCORE
  const isJauge = form?.thanks === JAUGE_SCORE
  const isSatisfaction = form?.thanks === "satisfaction"
  const dataGroupName = form?.dataGroupName ?? ""
  const title = itemField(question, "title", t)
  const description = itemField(question, "description", t) ?? null
  const scaleValue = values[name]
  const isLast = (form?.questions?.length ?? 0) - 1 === paramId
  const signInForm = form?.signInForm
  const dateType = type === "date"
  const currentValue = useMemo(() => values[name], [name, values])
  const linkedQuestion = useMemo(() => question?.linkedQuestions ?? [], [question?.linkedQuestions])
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [operationDate, setOperationDate] = useState<Date | undefined>(undefined)
  const [previousType, setPreviousType] = useState<string | undefined>(undefined)
  const [pendingOperationData, setPendingOperationData] = useState<any>(undefined)
  const startPhase = usePhases(operationDate, "asc")
  const endPhase = usePhases(operationDate, "desc")
  const isOrthoOrImplant = useMemo(
    () => userData?.specialty === "ortho" || userData?.specialty === "implant",
    [userData?.specialty],
  )
  const handleSetIsButtonDisabled = useCallback(
    (newValue: boolean) => {
      setIsButtonDisabled(newValue)
    },
    [setIsButtonDisabled],
  )
  const initNewPhase = useCallback(
    async (newStartPhase: Phase | undefined) => {
      if (!userData || !newStartPhase) {
        return
      }
      const result = await createDailyPhase({
        user,
        phase: newStartPhase,
        userData,
        initDailyPhase,
        createLocalNotifications,
      })
      if (!result) {
        cleanPhase()
      }
    },
    [cleanPhase, createLocalNotifications, initDailyPhase, userData, user],
  )

  useEffect(() => {
    if (startPhase && operationDate) {
      if (phases && isOrthoOrImplant) {
        const newAddedDays = { ...userData?.addedDays }
        const sortedPhases = [...phases].sort((a, b) => a.startDay - b.startDay)
        let reset = false
        sortedPhases.forEach(phase => {
          if (phase.id === startPhase.id) {
            reset = true
          }
          if (reset && phase.id !== startPhase.id && newAddedDays[phase.id]) {
            newAddedDays[phase.id] = 0
          }
        })
        updateUserData(userDocRef, { addedDays: newAddedDays })
      }
      updateUserData(userDocRef, { phase: startPhase.id })
      initNewPhase(startPhase)
    }
  }, [
    startPhase,
    operationDate,
    userData?.surgeryDate,
    userDocRef,
    initNewPhase,
    phases,
    userData?.addedDays,
    isOrthoOrImplant,
  ])

  const isDisabled = useMemo(
    () =>
      type !== "alert" &&
      (linkedQuestion.length > 0 || dateType
        ? isButtonDisabled
        : type === "scale"
        ? currentValue === undefined
        : type === "qcm-checkbox"
        ? _.isEmpty(currentValue) && !values[`${name}_unknown`]
        : _.isEmpty(currentValue)),
    [currentValue, isButtonDisabled, linkedQuestion.length, type, dateType, name, values],
  )

  const getSigninForm = useCallback(
    async (id: string) => {
      await loadSigninForm?.({ id })
    },
    [loadSigninForm],
  )
  useEffect(() => {
    if (linkedQuestion && linkedQuestion.length > 0) {
      const questionsToHide = linkedQuestion.filter(
        item =>
          !_.isUndefined(currentValue) && !_.includes(item.showIf, currentValue) && item.showIf,
      )
      if (questionsToHide.length === linkedQuestion.length) {
        setIsButtonDisabled(false)
      }
    }
  }, [currentValue, linkedQuestion])

  const {
    colors: {
      surface: { background },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      bottomButtonContainer: [
        styles.bottomButtonContainer,
        {
          paddingHorizontal: spacing,
          paddingBottom: spacing,
        },
        generateShadow(1),
      ],
      mainView: [
        styles.mainView,
        {
          backgroundColor: background,
        },
      ],
      dateView: {
        padding: spacing,
      },
    }),
    [spacing, background],
  )

  const onCreateProgram = useCallback(
    async (surgeryDate, surgeryType, newSurgeryDate, updateSingleSurgery?) => {
      setOperationDate(newSurgeryDate)
      setPendingOperationData({ surgeryDate, surgeryType, newSurgeryDate, updateSingleSurgery })
    },
    [],
  )

  useEffect(() => {
    const executeAfterOperationDateUpdate = async () => {
      setPreviousType(pendingOperationData.surgeryType)
      if (!operationDate || !pendingOperationData) {
        return
      }
      const { surgeryDate, surgeryType, newSurgeryDate, updateSingleSurgery } = pendingOperationData
      try {
        if (!userData?.pathology || !startPhase || !endPhase) {
          return
        }
        const isAvailableDate = dayjs(newSurgeryDate).isSame(dayjs(surgeryDate), "day")
        const programStart = firestore.Timestamp.fromDate(
          dayjs(surgeryDate).add(startPhase.startDay, "day").toDate(),
        )
        const programEnd = firestore.Timestamp.fromDate(
          dayjs(surgeryDate).add(endPhase.endDay, "day").toDate(),
        )
        const limitStartDate = calculateLimitStartDate(userData)
        const updateSurgeryDate = dayjs(newSurgeryDate).utc().format("YYYY-MM-DD")
        const programsDocs = await userDocRef
          ?.collection(collections.PROGRAMS)
          .where("surgeryType", "==", surgeryType)
          .get()
        const existingProgram = programsDocs?.docs
        if (!existingProgram || existingProgram.length === 0) {
          if (surgeryDate >= limitStartDate) {
            const program = await userDocRef?.collection(collections.PROGRAMS).add({
              start: programStart,
              end: programEnd,
              pathology: userData.pathology,
              surgeryType,
            })
            if (newSurgeryDate && isAvailableDate) {
              const newUserProgramValue = {
                programEnd,
                programStart,
                surgeryDate: updateSurgeryDate,
              }
              const programId = program?.id || null
              updateUserData(
                userDocRef,
                programId ? { ...newUserProgramValue, program: programId } : newUserProgramValue,
              )
            }
          }
        } else {
          const docId = existingProgram[0].id
          userDocRef
            ?.collection(collections.PROGRAMS)
            .doc(docId)
            .set({ end: programEnd, start: programStart }, { merge: true })
          if (newSurgeryDate && (updateSingleSurgery || isAvailableDate)) {
            const newProgram = await userDocRef
              ?.collection(collections.PROGRAMS)
              .where("start", "==", newSurgeryDate)
              .get()
            updateUserData(userDocRef, {
              programEnd: isAvailableDate ? programEnd : newProgram?.docs[0].data()?.end,
              programStart: isAvailableDate ? programStart : newProgram?.docs[0].data()?.start,
              program: isAvailableDate ? docId : newProgram?.docs[0].id,
              surgeryDate: updateSurgeryDate,
            })
          }
        }
      } catch (e) {
        logger("error creating program", e, surgeryType)
      } finally {
        setPendingOperationData(undefined)
      }
    }
    if (pendingOperationData && previousType !== pendingOperationData.surgeryType) {
      executeAfterOperationDateUpdate()
    }
  }, [
    operationDate,
    pendingOperationData,
    startPhase,
    endPhase,
    userData,
    userDocRef,
    previousType,
  ])

  const convertDateFields = obj => {
    const updatedValues = { ...obj }
    Object.keys(updatedValues).forEach(key => {
      if (key.endsWith("date")) {
        const value = updatedValues[key]
        if (value !== null && value !== undefined) {
          updatedValues[key] = dayjs(value).utc().format(ISO_8601_DATE)
        }
      }
    })
    return updatedValues
  }

  const validateFormData = useCallback(async () => {
    const updateObj = {}
    const convertedValues = convertDateFields(values)
    updateObj[`medicalInfo.${dataGroupName}`] = convertedValues
    if (isEditForm) {
      if (form.questionId || form.questionId === 0) {
        await updateUserData(userDocRef, { medicalInfo: { [dataGroupName]: convertedValues } })
        if (form?.id === SURGERY_SETTINGS) {
          await handleUpdateProgram(
            "updateSingleSurgery",
            userDocRef,
            userData as AppUserData,
            paramId,
            values,
            onCreateProgram,
          )
        }
      } else {
        await editUserData(userDocRef, updateObj)
        if (form?.id === SURGERY_SETTINGS) {
          if (values.operation_type) {
            await handleUpdateProgram(
              "updateAllSurgeries",
              userDocRef,
              userData as AppUserData,
              paramId,
              values,
              onCreateProgram,
            )
          }
        }
      }
      setForm(undefined)
      navigation.navigate("MainNavigator", { screen: "Account" })
    } else if (form?.nextForm) {
      await updateUserData(userDocRef, { medicalInfo: { [dataGroupName]: convertedValues } })
      if (form?.id === ONCO_SURGERY_FORM) {
        if (values.operation_type) {
          await handleUpdateProgram(
            "createSurgeryData",
            userDocRef,
            userData as AppUserData,
            paramId,
            values,
            onCreateProgram,
          )
        }
      }
      setTimeout(() => {
        if (form.nextForm) {
          getSigninForm(form.nextForm)
          navigateToQuestion(0, navigation)
        }
      }, 500)
    } else {
      await updateUserData(userDocRef, { medicalInfo: { [dataGroupName]: values } })
      setLoading(true)
      setTimeout(() => {
        setForm(undefined)
        setLoading(false)
      }, 500)
    }
    resetForm()
  }, [
    setLoading,
    form?.questionId,
    form?.nextForm,
    form?.id,
    navigation,
    values,
    userDocRef,
    dataGroupName,
    getSigninForm,
    setForm,
    resetForm,
    isEditForm,
    onCreateProgram,
    paramId,
    userData,
  ])

  const onSubmit = useCallback(
    async (lastField?: string | GestureResponderEvent, lastValue?: any) => {
      const { linkedValue } = question || {}
      if (!isLast) {
        if (form?.questionId || form?.questionId === 0) {
          validateFormData()
        } else if (linkedValue) {
          const questionsLength = (form?.questions?.length ?? 0) - 1
          if (values[`${linkedValue}_unknown`]) {
            navigateToQuestion(questionsLength, navigation)
          } else {
            if (!values[linkedValue]) {
              validateFormData()
            } else {
              // @ts-ignore
              const closestSuperior = values[linkedValue].reduce((acc, curr) => {
                const test = setOptionalScreen(curr)
                if (test > paramId && test < acc) {
                  return test
                }
                return acc
              }, questionsLength)
              if (closestSuperior === questionsLength) {
                validateFormData()
              } else {
                navigateToQuestion(closestSuperior, navigation)
              }
            }
          }
        } else {
          navigateToQuestion(paramId + 1, navigation)
        }
      } else {
        if (form?.signInForm) {
          validateFormData()
        } else {
          getScore(
            isRating || isEvent(lastField ?? "")
              ? values
              : { ...values, [lastField as string]: lastValue },
          )
          isSatisfaction &&
            updateUserData(userDocRef, {
              patientSurveyCompleted: true,
            })
          navigateToWelcome(isRating ? Interstitial.RATING : Interstitial.END, navigation)
        }
      }
    },
    [
      question,
      navigation,
      form,
      isLast,
      isRating,
      paramId,
      values,
      getScore,
      validateFormData,
      isSatisfaction,
      userDocRef,
    ],
  )

  const onGoBack = useCallback(async () => {
    const popAction = StackActions.pop()
    if (isRating) {
      setForm(undefined)
    } else if (signInForm) {
      if (isEditForm && (paramId === 0 || form.questionId)) {
        setForm(undefined)
        navigation.navigate("MainNavigator", { screen: "Account" })
        resetForm()
      } else {
        navigation.goBack()
      }
    } else if (paramId > 0 && !signInForm) {
      navigation.dispatch(popAction)
    } else {
      navigation.goBack()
    }
  }, [isRating, navigation, paramId, setForm, signInForm, isEditForm, form?.questionId, resetForm])

  return form && isFocused ? (
    <SafeAreaView style={s.mainView} edges={["top"]}>
      <View style={styles.mainView}>
        <View>
          <PromsProgressionHeader
            index={paramId}
            signInForm={signInForm}
            isEditForm={isEditForm}
            onPress={onGoBack}
            {...{ isRating }}
          />
        </View>
        <PromsFormHeader {...{ title, description }} showSeparator={!isScale} />
        {isScale ? (
          <ScaleForm
            scaleType={question?.scaleType}
            {...{ title, description, name, data, onSubmit, isRating, scaleValue }}
          />
        ) : (
          <FormQuestions
            setIsButtonDisabled={handleSetIsButtonDisabled}
            linkedQuestion={linkedQuestion}
            currentValue={currentValue}
            question={question}
          />
        )}
      </View>
      {isScale && !isRating && !isJauge ? null : (
        <BottomButtonContainer style={s.bottomButtonContainer}>
          <SubmitButton
            label={isEditForm ? t("common:button.validate") : t("common:button.continue")}
            viewStyle={styles.bottomButton}
            {...{ onSubmit, isDisabled }}
          />
        </BottomButtonContainer>
      )}
    </SafeAreaView>
  ) : (
    <EmptyView />
  )
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
  },
  bottomButtonContainer: {
    justifyContent: "center",
  },
  bottomButton: {
    width: "100%",
  },
})
export default PromsScreen
