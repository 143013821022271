import { AlertButton, ButtonType, useAlert, useStyles } from "capsule"
import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { checkNotifications, openSettings, requestNotifications } from "react-native-permissions"

import { AlertNS } from "../i18n/constants"

const usePermissions = (check: boolean) => {
  const { t } = useTranslation(AlertNS)
  const { showDialog, setIsValid } = useAlert()

  const s = useStyles(
    ({
      colors: {
        primary,
        surface: { appUi },
      },
    }) => ({
      button: { backgroundColor: appUi },
      label: { color: primary },
      text: {
        textAlign: "center",
      },
    }),
    [],
  )

  const renderAlertButton = useCallback(
    (positive: boolean): AlertButton => ({
      mode: "text",
      buttonStyle: s.button,
      textStyle: positive ? s.label : undefined,
      onPress: positive ? openSettings : undefined,
      type: positive ? ButtonType.POSITIVE : ButtonType.NEGATIVE,
      label: t(`common:button.${positive ? "ok" : "noThanks"}`),
    }),
    [s, t],
  )

  useEffect(() => {
    if (!check) {
      return
    }
    ;(async () => {
      setIsValid?.(true)
      const { status } = await checkNotifications()
      if (status !== "granted") {
        const { status: requestStatus } = await requestNotifications(["alert", "badge", "sound"])
        if (requestStatus !== "granted") {
          showDialog({
            type: "button",
            titleStyle: s.text,
            messageStyle: s.text,
            title: t("dialog.title"),
            message: t("dialog.message"),
            positive: renderAlertButton(true),
            negative: renderAlertButton(false),
          })
        }
      }
    })()
  }, [check, renderAlertButton, s.text, setIsValid, showDialog, t])
}

export default usePermissions
