import { Button, Caption, MessageItem, openUrl, SectionSeparator, useTheme } from "capsule"
import dayjs from "dayjs"
import React, { FC, useCallback, useMemo } from "react"
import { FlatList, ListRenderItem, StyleSheet } from "react-native"

import { Message } from "../features/models/Message"
import { getFirestoreTimestamp } from "../features/models/Types"

const keyExtractor = item => item.id

interface IProps {
  patientMessages: Message[] | undefined
}

export const MessageList: FC<IProps> = ({ patientMessages }) => {
  const {
    colors: {
      black: { highEmphasis },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      urlLabel: [
        styles.urlLabel,
        {
          color: highEmphasis,
        },
      ],
    }),
    [highEmphasis],
  )

  const onOpenUrl = useCallback(item => () => openUrl(item.attachmentUrl), [])

  const renderItem: ListRenderItem<Message> = useCallback(
    ({ item }) => (
      <>
        <MessageItem
          title={item?.authorName}
          date={dayjs(getFirestoreTimestamp(item?.timestamp)?.toDate()).format("L")}
          message={item?.text}
          renderMessage={
            item?.attachmentUrl && item?.attachmentName ? (
              <Button
                mode="text"
                labelStyle={s.urlLabel}
                style={styles.urlContainer}
                onPress={onOpenUrl(item)}
              >
                <Caption>{item.attachmentName}</Caption>
              </Button>
            ) : null
          }
          alertLabel={item?.alert}
          alertIcon={item?.alert ? { name: "warning", category: "MaterialIcons" } : undefined}
        />
      </>
    ),
    [onOpenUrl, s.urlLabel],
  )
  return (
    <FlatList<Message>
      data={patientMessages}
      ItemSeparatorComponent={SectionSeparator}
      {...{ keyExtractor, renderItem }}
    />
  )
}

const styles = StyleSheet.create({
  urlContainer: {
    alignSelf: "flex-start",
  },
  urlLabel: {
    textDecorationLine: "underline",
    marginHorizontal: 0,
  },
})
