import { H6, useTheme } from "capsule"
import React, { FC, useMemo } from "react"

interface IProps {
  title: string
}

const HomeSectionHeader: FC<IProps> = ({ title }) => {
  const {
    dimensions: { spacing },
    colors: {
      black: { highEmphasis: black },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      title: {
        color: black,
        paddingTop: spacing * 2,
        paddingBottom: spacing,
        paddingHorizontal: spacing,
      },
    }),
    [black, spacing],
  )

  return <H6 style={s.title}>{title}</H6>
}

export default HomeSectionHeader
