import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import AlertDialog from "../../components/AlertDialog/AlertDialog"
import { Snackbar } from "../../components/Snackbar"
/** Do not shorten to avoid `cycles warning` */
import { logger } from "../../utils/logger"
import { PartialPartialPartial, Theme, ThemeProvider } from "../Theme"
import {
  ButtonType,
  DialogResponse,
  Duration,
  IDialogInternal,
  ISnack,
  ISnackInternal,
  TextInputDialogResponse,
} from "./AlertInterfaces"

interface IAlertContext {
  showSnack: (snack: ISnack) => Promise<boolean> | void
  showDialog: (
    dialog: Omit<IDialogInternal, "onDismiss" | "visible">,
  ) => Promise<DialogResponse> | void
  notImplemented: () => void
  setIsValid?: Dispatch<SetStateAction<boolean>>
  onDismiss: () => void
}

const logError = () => logger("Add an AlertProvider at the root of your app")

const defaultValues: IAlertContext = {
  showSnack: logError,
  showDialog: logError,
  notImplemented: logError,
  setIsValid: logError,
  onDismiss: logError,
}

// @ts-ignore
export const alertContext = createContext<IAlertContext>(defaultValues)

interface IProps {
  children: ReactNode
  resetIsValid?: boolean
  bottomHeight?: number
  hasBottomNav?: boolean
  customDialogTheme?: PartialPartialPartial<Theme>
}

export type ShowDialog = Omit<IDialogInternal, "onDismiss" | "visible">

export const AlertProvider: FC<IProps> = ({
  children,
  hasBottomNav = false,
  bottomHeight,
  resetIsValid,
  customDialogTheme,
}) => {
  const [snackList, setSnackList] = useState<ISnackInternal[]>([])
  const [currentSnack, setCurrentSnack] = useState<ISnackInternal | undefined>(undefined)
  const [currentDialog, setCurrentDialog] = useState<IDialogInternal | undefined>(undefined)
  const [isValid, setIsValid] = useState<boolean>(!resetIsValid)
  const { t } = useTranslation()

  useEffect(() => {
    if (snackList.length >= 1) {
      setCurrentSnack({ ...snackList[0], visible: true })
    }
    // in this case we really want to depend on snackList, that's what triggers showing the snack
  }, [snackList])

  const onInternalDismiss = useCallback(() => {
    setCurrentSnack(s => (s ? { ...s, visible: false } : s))
    setTimeout(() => setSnackList(oldSnackList => _.slice(oldSnackList, 1)), 500)
  }, [setCurrentSnack])

  const onInternalDialogDismiss = useCallback(() => {
    setCurrentDialog(undefined)
    setIsValid(!resetIsValid)
  }, [resetIsValid])

  const showSnack = useCallback(
    (snack: ISnack | string) => {
      const realSnack: ISnack = _.isObject(snack) ? snack : { message: snack }
      return new Promise<boolean>(resolve => {
        const newSnack: ISnackInternal = {
          duration: Duration.SHORT,
          ...realSnack,
          visible: true,
          action: realSnack.action
            ? {
                label: realSnack.action.label,
                onPress: () => {
                  realSnack.action?.onPress?.()
                  onInternalDismiss()
                  resolve(true)
                },
              }
            : undefined,
          onDismiss: () => {
            onInternalDismiss()
            resolve(true)
          },
        }
        setSnackList(oldSnackList => [...oldSnackList, newSnack])
      })
    },
    [setSnackList, onInternalDismiss],
  )

  const showDialog = useCallback(
    (dialog: ShowDialog) =>
      new Promise<DialogResponse | TextInputDialogResponse>(resolve => {
        const newDialog: IDialogInternal = {
          ...dialog,
          resolve,
          visible: true,
          positive: {
            ...dialog.positive,
            type: ButtonType.POSITIVE ?? dialog.positive.type,
            onPress: async () => {
              await dialog.positive.onPress?.()
              onInternalDialogDismiss()
              resolve({ button: ButtonType.POSITIVE })
            },
          },
          negative: dialog.negative
            ? {
                ...dialog.negative,
                type: ButtonType.NEGATIVE ?? dialog.negative.type,
                onPress: () => {
                  dialog.negative?.onPress?.()
                  onInternalDialogDismiss()
                  resolve({ button: ButtonType.NEGATIVE })
                },
              }
            : undefined,
          neutral: dialog.neutral
            ? {
                ...dialog.neutral,
                type: ButtonType.NEUTRAL ?? dialog.neutral.type,
                onPress: () => {
                  dialog.neutral?.onPress?.()
                  onInternalDialogDismiss()
                  resolve({ button: ButtonType.NEUTRAL })
                },
              }
            : undefined,
          onDismiss: () => {
            onInternalDialogDismiss()
            resolve({ button: undefined })
          },
        }
        setCurrentDialog(newDialog)
      }),
    [onInternalDialogDismiss],
  )

  // logger("AlertProvider render")

  const notImplemented = useCallback(() => showSnack(t("alert.notImplemented")), [showSnack, t])

  const alertDialog = useMemo(
    () => (currentDialog ? <AlertDialog dialog={currentDialog} isValid={isValid} /> : null),
    [currentDialog, isValid],
  )

  return (
    <alertContext.Provider
      value={{
        showSnack,
        showDialog,
        notImplemented,
        setIsValid,
        onDismiss: onInternalDialogDismiss,
      }}
    >
      <>
        <View style={{ height: "100%" }}>{children}</View>
        {customDialogTheme ? (
          <ThemeProvider customTheme={customDialogTheme}>{alertDialog}</ThemeProvider>
        ) : (
          alertDialog
        )}
        {currentSnack?.visible ? (
          <View style={styles.view} pointerEvents="none">
            <Snackbar
              snack={currentSnack}
              hasBottomNav={hasBottomNav}
              bottomHeight={bottomHeight}
            />
          </View>
        ) : null}
      </>
    </alertContext.Provider>
  )
}

export const useAlert = () => useContext(alertContext)

const styles = StyleSheet.create({
  view: { position: "absolute", bottom: 0, left: 0, right: 0 },
})

export default AlertProvider
