import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs/lib/typescript/src/types"
import { RouteProp } from "@react-navigation/core"
import { useNavigation } from "@react-navigation/native"
import {
  alertContext,
  auth,
  firebase,
  FirebaseAuthTypes,
  logger,
  SMSInputScreen,
  userContext,
  useTheme,
} from "capsule"
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet } from "react-native"

import { INPUT_HEIGHT } from "../../features/config/Constants"
import { SMSValidationNS } from "../../features/i18n/constants"
import { OnboardingParamList, RootParamList } from "../../features/Navigation/types"
import crashlytics from "../../services/Analytics/crashlytics"
import { IMainParamList } from "../MainScreen/MainNavigator"

interface IProps {
  isEdit: boolean
  route:
    | RouteProp<RootParamList, "SMSValidationEdition">
    | RouteProp<OnboardingParamList, "SMSValidation">
}

const length = 6

const SmsValidationScreen: FC<IProps> = ({
  route: {
    params: { phoneNumber },
  },
  isEdit = false,
}) => {
  const {
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation(SMSValidationNS)
  const navigation = useNavigation<BottomTabNavigationProp<IMainParamList, "Account">>()
  const { user } = useContext(userContext)
  const { showSnack } = useContext(alertContext)
  const [smsCode, setSmsCode] = useState("")
  const [verifId, setVerifId] = useState("")
  const [resend, setResend] = useState(0)
  // If null, no SMS has been sent - only onBoarding
  const [confirm, setConfirm] = useState<FirebaseAuthTypes.ConfirmationResult | undefined>()
  const recaptchaRef = useRef(null)
  const [editable, setEditable] = useState(false)
  useEffect(() => {
    if (Platform.OS === "web" && recaptchaRef.current) {
      // @ts-ignore
      window.recaptchaVerifier = auth().RecaptchaVerifier(recaptchaRef.current, {
        size: "invisible",
        callback(response) {
          logger(response)
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaRef.current])

  const { inputStyle } = useMemo(
    () => ({
      inputStyle: [
        styles.input,
        {
          marginTop: spacing,
        },
      ],
    }),
    [spacing],
  )

  // Launch when code is submitted from textInput by user
  const onValidate = useCallback(
    async (code: string) => {
      if (code.length !== length) {
        return
      }
      if (isEdit) {
        setSmsCode(code)
        return
      }
      try {
        if (confirm) {
          await confirm.confirm(code)
        }
      } catch (e) {
        crashlytics.reportError(10, `confirmation code error : ${e.message}`)
        logger("Confirmation code error: ", e)
        showSnack({ message: t("codeError") })
      }
    },
    [confirm, isEdit, showSnack, t],
  )

  /** Launch at the start if edit mode, trigger sms validation */
  useEffect(() => {
    if (isEdit && !verifId) {
      logger("Verify phone number", { phoneNumber, resend })
      firebase
        .auth()
        .verifyPhoneNumber(phoneNumber)
        .on("state_changed", ({ error, code, verificationId, state }) => {
          setVerifId(verificationId)
          switch (state) {
            case firebase.auth.PhoneAuthState.CODE_SENT:
              showSnack({ message: t("sent") })
              setEditable(true)
              break
            case firebase.auth.PhoneAuthState.AUTO_VERIFIED: // Android only
              setSmsCode(code ?? "")
              break
            case firebase.auth.PhoneAuthState.ERROR:
              setEditable(true)
              crashlytics.reportError(10, `verify phone number error : ${error?.message}`)
              logger("verify phone number error", error?.message)
              showSnack({ message: t(error?.code ?? "unknown") })
              break
          }
        })
    }
  }, [isEdit, resend, phoneNumber, showSnack, t, smsCode, verifId])

  /** UseEffect to launch phone update when smsCode is received in editMode */
  useEffect(() => {
    if (!isEdit || !smsCode) {
      return
    }
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verifId, smsCode)
    user
      ?.updatePhoneNumber(phoneCredential)
      ?.then(() => {
        navigation.navigate("Account")
      })
      ?.catch(e => {
        crashlytics.reportError(10, `update phone number error : ${e.message}`)
        logger("Update phone number error", e.message)
        showSnack({ message: t(e.code) })
      })
    setSmsCode("")
  }, [isEdit, navigation, showSnack, smsCode, t, user, verifId])

  /** If SMS code is detected, auto signIn. Only Onboarding flow */
  useEffect(() => {
    if (!confirm && !isEdit) {
      // @ts-ignore
      if (Platform.OS === "web" && !window?.recaptchaVerifier) {
        return
      }
      ;(async () => {
        try {
          logger("Start confirmation", { phoneNumber, resend, confirm })
          // noinspection TypeScriptValidateJSTypes
          const confirmation =
            Platform.OS === "web"
              ? await auth().signInWithPhoneNumber(
                  phoneNumber,
                  // @ts-ignore
                  window?.recaptchaVerifier,
                )
              : await auth().signInWithPhoneNumber(phoneNumber, true)
          if (confirmation !== confirm) {
            setConfirm(confirmation)
            setEditable(true)
          }
        } catch (e) {
          setEditable(true)
          crashlytics.reportError(
            crashlytics.errorCodes.smsLogin,
            `Authentication error : ${e.message}`,
          )
          logger("Authentication system error: ", e)
          showSnack({ message: t(e.code, t("networkError")) })
        }
      })()
    }
  }, [confirm, isEdit, phoneNumber, resend, showSnack, t])

  const onResend = useCallback(() => setResend(r => r + 1), [setResend])

  return (
    <SMSInputScreen
      phone={phoneNumber}
      {...{ onResend, onValidate, inputStyle, length, editable }}
      extraComponent={
        Platform.OS === "web" ? <div id="recaptcha-container" ref={recaptchaRef} /> : undefined
      }
    />
  )
}

const styles = StyleSheet.create({
  input: {
    height: INPUT_HEIGHT,
  },
})

export default SmsValidationScreen
