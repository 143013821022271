import { i18n } from "capsule"
import React, { FC } from "react"
import { I18nextProvider } from "react-i18next"

import translation from "../../../features/i18n/i18n"

interface IProps {
  children: React.ReactNode
}

const Provider: FC<IProps> = ({ children }) => (
  <I18nextProvider i18n={i18n({ translation })}>{children}</I18nextProvider>
)

export default Provider
