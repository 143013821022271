import { Subtitle2 } from "capsule"
import { FormikConfig, FormikValues } from "formik"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, View, ViewStyle } from "react-native"
import * as Yup from "yup"

import useTheme from "../../features/Theme/ThemeProvider"
import { logger } from "../../utils/logger"
import { FormikTextInput, InputContainerStyle } from "../FormBis"
import { TextInput } from "../TextInput"
import { InputScreen } from "./InputScreen"

export interface NamesForm {
  firstName: string
  lastName: string
  email: string
  otherEmail?: string
}

const defaultNames: NamesForm = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  otherEmail: "otherEmail",
}

interface IProps<Values> {
  inputStyle?: StyleProp<ViewStyle>
  viewStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  formikConfig?: FormikConfig<Values>
  names?: {
    firstName: string
    lastName: string
    email: string
    otherEmail?: string
  }
  styles?: InputContainerStyle
  isEmail: string
  isLastName: string
}

export const NamesFormikConfig = <Values extends FormikValues>(options?: {
  onSubmit?: (args: Values) => void
  initialValues?: NamesForm
  validationSchema?: any
}) =>
  ({
    validateOnMount: true,
    onSubmit: options?.onSubmit || logger,
    initialValues: options?.initialValues || {
      firstName: "",
      lastName: "",
      email: "",
      otherEmail: "",
    },
    validationSchema:
      options?.validationSchema ||
      Yup.object<NamesForm>({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        email: Yup.string().required(),
        otherEmail: Yup.string(),
      }),
  } as FormikConfig<NamesForm>)

export const NamesScreen = <Values extends FormikValues>({
  formikConfig,
  viewStyle,
  styles,
  buttonStyle,
  names = defaultNames,
  inputStyle,
  isEmail,
  isLastName,
}: IProps<Values>) => {
  const {
    colors: {
      black: { transparent },
    },
    dimensions: { margin },
  } = useTheme()
  const { t } = useTranslation()
  const style = { backgroundColor: transparent }
  const s = useMemo(
    () => ({
      textView: {
        marginBottom: margin * 2,
        marginHorizontal: margin / 4,
      },
    }),
    [margin],
  )

  const InputElem = !!formikConfig ? FormikTextInput : TextInput
  const showText = isLastName !== undefined && !isEmail ? true : undefined

  const formikFirstNameProps = {
    name: names.firstName,
  }
  const formikLastNameProps = {
    name: names.lastName,
  }
  const formikEmailProps = {
    name: names.email,
  }
  const formikOtherEmailProps = {
    name: names.otherEmail,
  }

  return (
    <InputScreen<Values>
      Inputs={
        <View>
          {!isEmail ? <Subtitle2 style={s.textView}>{t("addMail")}</Subtitle2> : null}
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikFirstNameProps, styles }}
            label={t("fields.label.firstName")}
            style={inputStyle}
          />
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikLastNameProps, styles }}
            label={t("fields.label.lastName")}
            style={inputStyle}
          />
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikEmailProps, styles }}
            label={t("fields.label.email")}
            style={inputStyle}
          />
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikOtherEmailProps, styles }}
            label={t("fields.label.otherEmail")}
            style={inputStyle}
          />
        </View>
      }
      buttonLabel={t("button.save")}
      {...{ viewStyle, formikConfig }}
      buttonStyle={{ style: buttonStyle }}
      isTextStyle={showText}
    />
  )
}
