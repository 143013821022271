import { Button, firestore, Subtitle1, userContext, useStyles } from "capsule"
import firebase from "firebase"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { ActivityIndicator, ScrollView, View } from "react-native"
import Video from "react-native-video"

import { Faq } from "../../common/Faq"
import { collections } from "../../common/types"
import { TECHNICAL_FAQ } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { VideoNS } from "../../features/i18n/constants"
import { updateUserData } from "../../features/models/UserDataFunctions"

export const VideoPresentationScreen: FC = () => {
  const { t } = useTranslation(VideoNS)
  const { userDocRef } = useContext(userContext)

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        surface: { appUi },
      },
    }) => ({
      container: {
        flex: 1,
        justifyContent: "space-between",
        backgroundColor: appUi,
      },
      subtitle: {
        padding: spacing,
      },
      buttonContainer: {
        padding: spacing * 2,
      },
      videoContainer: {
        width: "100%",
        height: 250,
      },
      loadingContainer: {
        position: "absolute",
        width: "100%",
        height: 250,
        justifyContent: "center",
        alignItems: "center",
      },
    }),
    [],
  )

  const [isPlaying, setIsPlaying] = useState<boolean | null>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onPlayPress = useCallback(() => {
    setIsPlaying(prevIsPlaying => !prevIsPlaying)
  }, [])

  const onExitButtonPress = useCallback(() => {
    updateUserData(userDocRef, { videoWatched: true })
    setIsPlaying(false)
  }, [userDocRef])

  const onLoadStart = () => setIsLoading(true)
  const onLoad = () => setIsLoading(false)

  const [videoUri] = useDocumentData<Faq>(
    TECHNICAL_FAQ
      ? ((firestore()
          .collection(collections.FAQ)
          .doc(TECHNICAL_FAQ) as unknown) as firebase.firestore.DocumentReference<Faq>)
      : null,
  )

  const videoUrl = useMemo(() => (videoUri ? itemField(videoUri, "video", t) : null), [videoUri, t])

  return (
    <View style={s.container}>
      <ScrollView>
        {videoUrl && (
          <View>
            <Video
              source={{ uri: videoUrl }}
              controls
              style={s.videoContainer}
              paused={!isPlaying}
              onTouchStart={onPlayPress}
              onLoadStart={onLoadStart}
              onLoad={onLoad}
            />
            {isLoading && (
              <View style={s.loadingContainer}>
                <ActivityIndicator size="large" />
              </View>
            )}
            <Subtitle1 emphasis="high" style={s.subtitle}>
              {t("encourageText")}
            </Subtitle1>
          </View>
        )}
        <View style={s.buttonContainer}>
          <Button onPress={onExitButtonPress}>{t("button")}</Button>
        </View>
      </ScrollView>
    </View>
  )
}

export default VideoPresentationScreen
