import { IUserContext, userContext, useTheme } from "capsule"
import React, { FC, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"

import CodeView from "../../components/CodeView"
import { ActivationCodeNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { useRole } from "../../features/Providers/RoleProvider"

interface IProps {
  /** true if this is inside the practitioner app, to add a new patient */
  addPatient?: boolean
}

const ActivationCodeScreen: FC<IProps> = ({ addPatient = false }) => {
  const {
    colors: {
      surface: { background },
    },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation(ActivationCodeNS)
  const { role } = useRole()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const s = useMemo(
    () => ({
      view: [
        styles.view,
        {
          backgroundColor: background,
          paddingHorizontal: spacing * 2,
        },
      ],
      viewContent: {
        marginTop: spacing * 2,
      },
    }),
    [background, spacing],
  )
  return (
    <KeyboardAwareScrollView
      style={s.view}
      enableOnAndroid
      keyboardShouldPersistTaps="handled"
      contentContainerStyle={s.viewContent}
    >
      <CodeView
        isScreen
        name="activation_code"
        mode={addPatient ? "add_patient" : "onboarding"}
        caption={t([`logout.${userData?.role ?? role}`, "logout.patient"])}
        subtitle={t([`label.${addPatient ? "addPatient" : role}`, "label.patient"])}
        addInfo={addPatient && role !== "patient" ? t("label.addPatientInfo") : undefined}
      />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  viewContent: {
    alignItems: "center",
  },
})

export default ActivationCodeScreen
