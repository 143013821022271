import { firestore } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"

import { Exercise, Phase } from "../../common/Phase"
import { collections } from "../../common/types"
import AccordionSection from "../../components/AccordionSection"
import ExercicesList from "../../components/ExercisesList"
import { ExercisesListNS } from "../../features/i18n/constants"
import { getExosFromPhase } from "../../features/models/ExerciseFunctions"
import { Patient } from "../../features/models/UserData"

interface IProps {
  patient?: Patient
  phase?: Phase
}

const PatientExerciseView: FC<IProps> = ({ patient, phase }) => {
  const { t } = useTranslation()
  const [exercises, setExercises] = useState<any | null>(null)
  const phaseId = patient?.phase || phase?.id

  const [getExosFromExercisesByPhaseId] = useCollectionData<Exercise>(
    phaseId
      ? ((firestore()
          .collection(collections.EXERCISES)
          .where("disabled", "==", false)
          .where("intensity", "==", 2)
          .where("phase", "==", phaseId)
          .orderBy("order", "asc") as unknown) as firebase.firestore.Query<Exercise>)
      : null,
  )

  const [hasExercises, setHasExercises] = useState<boolean | null>(null)

  useEffect(() => {
    const checkExercises = async () => {
      if (phaseId) {
        const phaseDoc = await firestore().collection(collections.PHASES).doc(phaseId).get()
        const phaseData = phaseDoc.data()
        setHasExercises(!!phaseData?.exercises)
      }
    }

    if (phase?.exercises !== undefined) {
      setHasExercises(!!phase.exercises)
    } else {
      checkExercises()
    }
  }, [phaseId, phase])

  useEffect(() => {
    const fetchExercices = async () => {
      if (hasExercises && phaseId) {
        const exos = await getExosFromPhase(phaseId)
        setExercises(exos)
      } else {
        setExercises(getExosFromExercisesByPhaseId)
      }
    }

    if (hasExercises !== null) {
      fetchExercices()
    }
  }, [phaseId, getExosFromExercisesByPhaseId, hasExercises])

  const containerProps = useMemo(
    () => ({
      leftIcon: "list",
      title: t(`${ExercisesListNS}:sectionTitle`),
    }),
    [t],
  )

  return (
    <>
      {!_.isEmpty(exercises) ? (
        <AccordionSection {...containerProps} expandList={true}>
          <ExercicesList exercises={exercises} />
        </AccordionSection>
      ) : null}
    </>
  )
}

export default PatientExerciseView
