import { FormikConfig, FormikValues } from "formik"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { TextInput as NativeInput } from "react-native"
import * as Yup from "yup"

import useTheme from "../../features/Theme/ThemeProvider"
import { logger } from "../../utils/logger"
import { FormikTextInput } from "../FormBis"
import { IPaperTextInputProps, TextInput } from "../TextInput"
import { InputScreen } from "./InputScreen"

export interface SingleTextInputForm {
  value: string
}

interface IProps<Values> {
  name?: string
  label: string
  buttonLabel?: string
  inputProps?: IPaperTextInputProps
  formikConfig?: FormikConfig<Values>
}

export const SingleTextInputFormikConfig = <Values extends FormikValues>(
  onSubmit?: (args: Values) => Promise<void> | void,
) =>
  ({
    initialValues: { value: "" },
    validateOnMount: true,
    validationSchema: Yup.object({
      value: Yup.string().required(),
    }),
    onSubmit: onSubmit || logger,
  } as FormikConfig<SingleTextInputForm>)

export const SingleTextInputScreen = <Values extends FormikValues>({
  name,
  label,
  inputProps,
  buttonLabel,
  formikConfig,
}: IProps<Values>) => {
  const {
    colors: {
      black: { transparent },
    },
  } = useTheme()
  const { t } = useTranslation()
  const style = { backgroundColor: transparent }
  const forwardRef = useRef<NativeInput>(null)
  const InputElem = !!formikConfig ? FormikTextInput : TextInput
  const inputName = name ?? "basic"

  useEffect(() => {
    forwardRef?.current?.focus()
  }, [])

  return (
    <InputScreen<Values>
      formikConfig={formikConfig}
      buttonLabel={buttonLabel ?? t("button.save")}
      Inputs={
        <InputElem
          autoFocus
          label={label}
          // @ts-ignore
          name={inputName}
          changeUnderline={false}
          {...{ style, forwardRef, ...inputProps }}
        />
      }
    />
  )
}
