import { useCallback, useEffect, useState } from "react"
import { AppState, AppStateStatus } from "react-native"

interface AppStateHookSettings {
  onChange?: (status: AppStateStatus) => void
}

interface AppStateHookResult {
  appState: AppStateStatus
}

export const useAppState = (settings?: AppStateHookSettings): AppStateHookResult => {
  const { onChange } = settings || {}
  const [appState, setAppState] = useState(AppState.currentState)
  const handler = useCallback(
    (nextAppState: AppStateStatus) => {
      setAppState(nextAppState)
      onChange?.(nextAppState)
    },
    [onChange],
  )
  useEffect(() => {
    const subscription = AppState.addEventListener("change", handler)
    return () => {
      subscription && subscription.remove()
    }
  }, [handler])

  return { appState }
}
