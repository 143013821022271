import React, { FC } from "react"
import { Trans } from "react-i18next"
import { StyleProp, TextStyle, TouchableOpacity } from "react-native"

import { IBodyProps } from "../Texts"

interface IProps {
  highlightedTextStyle: StyleProp<TextStyle>
  i18nKey: string
  onPress?: () => void
  TextComp: FC<IBodyProps>
  params?: Record<string, string>
  textStyle?: StyleProp<TextStyle>
}

export const StylizedTranslatedText: FC<IProps> = ({
  onPress,
  i18nKey,
  TextComp,
  params: values,
  highlightedTextStyle,
  textStyle: style,
}) => {
  const textComp = (
    <TextComp {...{ style }}>
      <Trans {...{ i18nKey, values }}>
        standardSequence
        <TextComp style={highlightedTextStyle}>highlightedSequence</TextComp>
      </Trans>
    </TextComp>
  )
  return onPress ? <TouchableOpacity onPress={onPress}>{textComp}</TouchableOpacity> : textComp
}
