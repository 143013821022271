import { defaultWeights, IFontsTheme } from "./types"

const fonts: IFontsTheme = {
  default: {
    weights: defaultWeights,
    defaultWeight: "Regular",
    family: undefined,
  },
}

export default fonts
