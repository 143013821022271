import { firestore, SectionSeparator, TextInput, useTheme } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import {
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInputEndEditingEventData,
  View,
} from "react-native"

import { TeamInfo } from "../../common/CommonUserData"
import { checkValidity } from "../../common/dataFunctions"
import { QCU } from "../../common/Instruction"
import { collections } from "../../common/types"
import AccordionSection from "../../components/AccordionSection"
import { INPUT_HEIGHT } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { PatientNS } from "../../features/i18n/constants"
import { updatePatientData } from "../../features/models/UserDataFunctions"
import PatientPractitionersView from "./PatientPractitionersView"
import PatientQcuView from "./PatientQcuView"
import PatientReportView from "./PatientReportView"
import { PatientProps } from "./PatientScreen"
import PostOpInstructionsView from "./PostOpInstructionsView"

export interface IChildrenProps extends PatientProps {
  isSurgeon?: boolean
}

const PatientInformationsView: FC<PatientProps> = ({ patient, isSurgeon }) => {
  const {
    colors: {
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
    screenStyle,
  } = useTheme()
  // Contexts
  const { t } = useTranslation(PatientNS)
  const [inputValues, setInputValues] = useState<Record<string, string>>({})
  const [questions, error] = useCollectionData<QCU>(
    (firestore().collection(collections.QCU) as unknown) as firebase.firestore.Query<QCU>,
  )
  const filteredQuestions = _.filter(questions, i =>
    checkValidity(patient, i.specialty, i.organ, i.pathology, i.partner),
  )

  const s = useMemo(
    () => ({
      view: [
        {
          backgroundColor: white,
        },
        Platform.OS !== "web" ? screenStyle : null,
      ],
      content: {
        marginVertical: spacing / 2,
      },
      input: [
        styles.input,
        {
          marginHorizontal: spacing,
          marginVertical: spacing / 2,
        },
      ],
    }),
    [white, screenStyle, spacing],
  )

  const onChangeInputValues = useCallback((text: string, id: string) => {
    setInputValues(oldValues => ({ ...oldValues, [id]: text }))
  }, [])

  const onEndEditing = useCallback(
    (e: NativeSyntheticEvent<TextInputEndEditingEventData>, id: string) => {
      updatePatientData(patient.id, { qcuAnswers: { [id]: e.nativeEvent.text } })
    },
    [patient.id],
  )

  return (
    <AccordionSection title={t("information")} leftIcon="info-outline" expandList={true}>
      <View style={s.view}>
        <PatientPractitionersView
          surgeonId={patient.surgeon as string}
          surgeryDate={patient.surgeryDate}
          medicalTeamInfo={patient.medicalTeamInfo as TeamInfo}
        />
        {isSurgeon ? (
          <>
            <SectionSeparator />
            <PatientReportView {...{ patient, isSurgeon }} />
          </>
        ) : null}
        <SectionSeparator />
        <>
          <PostOpInstructionsView {...{ patient, isSurgeon }} />
          {error ? null : (
            <View style={s.content}>
              {_.map(filteredQuestions, qcu =>
                qcu.type === "qcu" ? (
                  <PatientQcuView key={qcu.id} {...{ patient, isSurgeon, qcu }} />
                ) : (
                  <TextInput
                    key={qcu.id}
                    style={s.input}
                    blurOnSubmit
                    value={inputValues?.[qcu.id]}
                    onEndEditing={e => onEndEditing(e, qcu.id)}
                    label={itemField<QCU>(qcu, "title", t)}
                    onChangeText={text => onChangeInputValues(text, qcu.id)}
                  />
                ),
              )}
            </View>
          )}
        </>
      </View>
    </AccordionSection>
  )
}

const styles = StyleSheet.create({
  input: {
    height: INPUT_HEIGHT,
  },
})

export default PatientInformationsView
