import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"

interface LatLng {
  latitude: number
  longitude: number
}

export interface IMapElement {
  coord: LatLng
}

interface IProps<T extends IMapElement> {
  children: ReactNode
  elements: T[]
}

export interface IMapContext<T extends IMapElement> {
  viewElements: T[]
  boundaries?: { northEast: LatLng; southWest: LatLng }
  setBoundaries: Dispatch<SetStateAction<{ northEast: LatLng; southWest: LatLng } | undefined>>
  getSnapPosition: (path: string, id: string) => number
}

export const defaultValues: IMapContext<IMapElement> = ({
  viewElements: [],
  setBoundaries: _.noop,
} as unknown) as IMapContext<IMapElement>

export const mapContext = createContext<IMapContext<IMapElement>>(defaultValues)

const MapProvider: <T extends IMapElement>({ children, elements }: IProps<T>) => JSX.Element = <
  T extends IMapElement
>({
  children,
  elements,
}: IProps<T>) => {
  const [viewElements, setViewElements] = useState<T[]>([])
  const [boundaries, setBoundaries] = useState<
    | {
        northEast: LatLng
        southWest: LatLng
      }
    | undefined
  >(undefined)

  useEffect(() => {
    setViewElements(
      boundaries
        ? elements.filter(
            (el: T) =>
              el.coord.latitude >= boundaries.southWest.latitude &&
              el.coord.latitude <= boundaries.northEast.latitude &&
              el.coord.longitude >= boundaries.southWest.longitude &&
              el.coord.longitude <= boundaries.northEast.longitude,
          )
        : [],
    )
  }, [elements, boundaries])

  const getSnapPosition = useCallback(
    (path: string, id: string) => _.findIndex(viewElements as T[], [path, id]),
    [viewElements],
  )

  const defaultMapContext: IMapContext<T> = {
    viewElements,
    boundaries,
    setBoundaries,
    getSnapPosition,
  }

  return <mapContext.Provider value={defaultMapContext}>{children}</mapContext.Provider>
}

export default MapProvider
