import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs"
import { firestore, IUserContext, logger, userContext, useTheme } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _, { isEqual } from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { FlatList, StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { ID } from "../../common/CommonUserData"
import { collections } from "../../common/types"
import { PATIENT_SURVEY_FORM, RATING_FORM } from "../../features/config/Constants"
import currentDateMs from "../../features/hooks/useDateMock"
import useHomeActions from "../../features/hooks/useHomeActions"
import useSpecialtySystem from "../../features/hooks/useSpecialtySystem"
import {
  calculateLimitStartDate,
  findCurrentProgram,
} from "../../features/models/OncoFormFunctions"
import { createDailyPhase, usePhases } from "../../features/models/PhaseAndSessionFunctions"
import { PatientPromsResult } from "../../features/models/Proms"
import { AppUserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { programContext } from "../../features/Providers/ProgramProvider"
import { IMainParamList } from "../MainScreen/MainNavigator"
import HomeFaqView from "./HomeFaqView"
import HomeHeader from "./HomeHeader"
import HomeNoProgramView from "./HomeNoProgramView"
import HomePromsByIdView from "./HomePromsByIdView"
import HomeSessionView from "./HomeSessionView"
import HomeTodoView from "./HomeTodoView"

export type HomeScreenNavigationProp = BottomTabNavigationProp<IMainParamList, "Home">

const HomeScreen: FC = () => {
  const {
    colors: {
      primary,
      surface: { background },
    },
    dimensions: { spacing },
  } = useTheme()
  const { user, userData, userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  // PHASES
  const { currentPhase, cleanPhase } = useContext(programContext)
  const { initDailyPhase, createLocalNotifications } = useSpecialtySystem()
  const isProgramEnded = dayjs(currentDateMs()).isAfter(userData?.programEnd as Date, "day")
  const [isExistingProg, setIsExistingProg] = useState<boolean | undefined>(undefined)
  const { initialLoading } = useHomeActions()
  const prevUserDataRef = useRef(
    userData
      ? {
          alertDays: userData.alertDays,
          alertEndTime: userData.alertEndTime,
          alertStartTime: userData.alertStartTime,
          program: userData.program,
          surgeryDate: userData.surgeryDate,
          timezone: userData.timezone,
        }
      : {},
  )
  // @ts-ignore
  const startPhase = usePhases(userData?.surgeryDate, "asc")

  const usePromsByFormId = (formId: ID) =>
    useCollectionData<PatientPromsResult & { docId: string }>(
      userDocRef
        ? ((userDocRef
            .collection(collections.PROMS)
            .where("id", "==", formId) as unknown) as firebase.firestore.Query<PatientPromsResult>)
        : null,
      { idField: "docId" },
    )

  const [ratingProms] = usePromsByFormId(RATING_FORM)
  const [patientSurveyProms] = usePromsByFormId(PATIENT_SURVEY_FORM)

  const initNewPhase = useCallback(async () => {
    if (!userData || !startPhase) {
      return
    }
    const result = await createDailyPhase({
      user,
      phase: startPhase,
      userData,
      initDailyPhase,
      createLocalNotifications,
    })
    if (!result) {
      cleanPhase()
    }
  }, [cleanPhase, createLocalNotifications, initDailyPhase, userData, user, startPhase])

  useEffect(() => {
    const isUpdateNeeded = () => {
      const fieldsToCompare = [
        "program",
        "alertEndTime",
        "alertStartTime",
        "alertDays",
        "surgeryDate",
        "timezone",
      ]
      const newUserSubset = {}
      const prevUserSubset = {}
      fieldsToCompare.forEach(field => {
        newUserSubset[field] = userData?.[field]
        prevUserSubset[field] = prevUserDataRef.current?.[field]
        if (field === "program" && newUserSubset[field] !== prevUserSubset[field]) {
          userDocRef?.set(
            {
              doneTasks: firestore.FieldValue.delete(),
            },
            { merge: true },
          )
        }
      })
      if (startPhase && userData?.phase !== startPhase?.id) {
        updateUserData(userDocRef, {
          phase: startPhase.id,
        })
      }
      return !isEqual(newUserSubset, prevUserSubset)
    }
    if (isUpdateNeeded()) {
      initNewPhase()
    }
    prevUserDataRef.current = userData
      ? {
          program: userData?.program,
          alertEndTime: userData?.alertEndTime,
          alertStartTime: userData?.alertStartTime,
          alertDays: userData?.alertDays,
          surgeryDate: userData?.surgeryDate,
          timezone: userData?.timezone,
        }
      : {}
  }, [userData, initNewPhase, userDocRef, startPhase])

  useEffect(() => {
    const getUserPrograms = async () => {
      try {
        if (!startPhase || !userData) {
          return
        }
        if (userData && userData.program && !userData.phase) {
          updateUserData(userDocRef, {
            phase: startPhase.id,
          })
        }
        const programDocs = (await userDocRef?.collection(collections.PROGRAMS).get())?.docs
        if (programDocs && programDocs.length > 0) {
          setIsExistingProg(true)
          if (isProgramEnded) {
            const programsDates = programDocs.map(prog => ({
              type: prog.data().surgeryType,
              date: dayjs(prog.data().start.seconds * 1000).toDate(),
            }))
            const limitStartDate = calculateLimitStartDate(userData)
            const validProgDates = programsDates.filter(
              validProg => validProg.date >= limitStartDate,
            )
            const newProgramDate = await findCurrentProgram(validProgDates, userData)
            if (newProgramDate) {
              const newProgram = await userDocRef
                ?.collection(collections.PROGRAMS)
                .where("start", "==", newProgramDate)
                .get()
              const newProgData = newProgram?.docs[0].data()
              const newProgId = newProgram?.docs[0].id
              const updateSurgeryDate = dayjs(newProgramDate).format("YYYY-MM-DD")
              updateUserData(userDocRef, {
                phase: startPhase.id,
                programEnd: newProgData?.end,
                programStart: newProgData?.start,
                program: newProgId,
                surgeryDate: updateSurgeryDate,
              })
              initNewPhase()
            }
          }
        } else {
          setIsExistingProg(undefined)
        }
      } catch (error) {
        logger("Error when retrieving programs :", error)
      }
    }
    if (userData?.specialty === "onco") {
      getUserPrograms()
    }
  }, [userDocRef, isProgramEnded, startPhase, userData, initNewPhase])

  const s = useMemo(
    () => ({
      statusBar: [
        styles.view,
        {
          backgroundColor: primary,
        },
      ],
      view: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      indicator: {
        marginTop: spacing * 2,
      },
    }),
    [background, primary, spacing],
  )
  const FlatListComponents = [
    <HomeSessionView key="HomeSession" />,
    userData?.displayTodos !== false ? <HomeTodoView key="HomeTodo" /> : null,
    <HomeFaqView key="HomeFaq" />,
  ]

  const showSatisfaction = currentPhase?.showSatisfaction && _.isEmpty(ratingProms)
  const showPatientSurvey =
    currentPhase?.showPatientSurvey &&
    (_.isEmpty(patientSurveyProms) ||
      !patientSurveyProms?.some(prom => prom.triggerDay === currentPhase.startDay))

  return (
    <SafeAreaView style={s.statusBar} edges={["top"]}>
      <View style={s.view}>
        <HomeHeader />
        {initialLoading ? (
          <ActivityIndicator style={s.indicator} size="large" />
        ) : (
          <>
            {isProgramEnded || (!isExistingProg && userData?.specialty === "onco") ? (
              <HomeNoProgramView isProgramEnded={isProgramEnded} />
            ) : (
              <FlatList
                data={FlatListComponents}
                renderItem={({ item }) => <>{item}</>}
                ListHeaderComponent={
                  <>
                    {showSatisfaction && <HomePromsByIdView formId={RATING_FORM} />}
                    {showPatientSurvey && <HomePromsByIdView formId={PATIENT_SURVEY_FORM} />}
                  </>
                }
              />
            )}
          </>
        )}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
})

export default HomeScreen
