import { H5, Subtitle1, useTabLand, useTheme } from "capsule"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"

import { collections } from "../../common/types"
import useItemI18n from "../../features/hooks/useItemI18n"
import { PatientNS, PatientsListNS } from "../../features/i18n/constants"
import { Patient } from "../../features/models/UserData"
import { daySinceSurgery } from "../../utils/conversion"

interface IProps {
  patient: Patient
}

const PatientHeader: FC<IProps> = ({
  patient: { firstName, lastName, surgeryDate, pathology },
}) => {
  const isTabLand = useTabLand()
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
      black: { highEmphasis: black },
    },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const nbDays = daySinceSurgery(surgeryDate)
  const name = useItemI18n(collections.PATHOLOGIES, pathology as string, "name")
  const subtitle =
    name && nbDays
      ? t(`${PatientNS}:subtitle_${nbDays > 0 ? "positive" : "negative"}`, { nbDays })
      : ""
  const s = useMemo(
    () => ({
      header: [
        styles.container,
        {
          padding: spacing,
          backgroundColor: isTabLand && Platform.OS === "web" ? white : primary,
        },
      ],
      text: {
        color: isTabLand && Platform.OS === "web" ? black : white,
      },
      subtitle: {
        paddingTop: spacing,
        paddingBottom: spacing / 2,
        color: isTabLand && Platform.OS === "web" ? black : white,
      },
    }),
    [black, isTabLand, primary, spacing, white],
  )
  return (
    <View style={s.header}>
      <H5 style={s.text}>
        {t("name", {
          firstName,
          lastName,
          ns: PatientsListNS,
          interpolation: { escapeValue: false },
        })}
      </H5>
      <Subtitle1 style={s.subtitle}>
        {subtitle} {name}
      </Subtitle1>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
})

export default PatientHeader
