import { useNavigation, useRoute } from "@react-navigation/native"
import { ParamListBase } from "@react-navigation/routers"
import { createStackNavigator, StackNavigationOptions } from "@react-navigation/stack"
import { HeaderBackButton, TabProvider, useAlert, useTheme, useUser } from "capsule"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { ID } from "../../common/CommonUserData"
import { Exercise } from "../../common/Phase"
import { ICON_SIZE } from "../../features/config/Constants"
import { ExerciseNS } from "../../features/i18n/constants"
import { EvalType, ExerciseEvalFormValues } from "../../features/models/Evaluation"
import { FormikParams, FormikScreen } from "../../features/Navigation/FormikScreen"
import { hideHeader } from "../../features/Navigation/NavigationService"
import { useSession } from "../../features/Providers/SessionProvider"
import VideoProvider from "../../features/Providers/VideoProvider"
import { evaluationFormikParams } from "../EvaluationScreen/Constants"
import EvaluationScreen from "../EvaluationScreen/EvaluationScreen"
import ExercisesListScreen from "../ExercisesListScreen/ExercisesListScreen"
import ExerciseScreen from "./ExerciseScreen"

export interface ExerciseParamList extends ParamListBase {
  ExercisesList: undefined
  Exercise: {
    selectedExercise?: Exercise
    patientId?: ID
    endProgram?: boolean
  }
  ExerciseEvaluation: undefined
}

const exerciseNavigator = createStackNavigator<ExerciseParamList>()
type RouteParams = {
  params: {
    watchOnly?: boolean
  }
}

const ExerciseNavigator = () => {
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { t } = useTranslation(ExerciseNS)
  const { showDialog } = useAlert()
  const { sessionId, currentEx, cancelOncoSession } = useSession()
  const exerciseIdRef = useRef<string | undefined>(currentEx?.id)

  const { userData } = useUser()
  const navigation = useNavigation()
  const route = useRoute()
  const params = route.params as RouteParams
  const isWatchOnly = useMemo(
    () => params && params.params && params.params.watchOnly !== undefined,
    [params],
  )

  const patientOptions = useMemo<StackNavigationOptions>(
    () => ({
      headerStyle: [
        styles.header,
        {
          backgroundColor: primary,
        },
      ],
      // @ts-ignore
      headerTitle: "",
      headerTintColor: white,
      headerBackTitleVisible: false,
    }),
    [primary, white],
  )

  const cancelSession = useCallback(
    async (onPress?: () => void) => {
      showDialog({
        type: "button",
        title: t("oncoAlert.title"),
        message: t("oncoAlert.message"),
        positive: {
          label: t("common:button.stop"),
          onPress: () => {
            cancelOncoSession?.()
            onPress?.()
          },
        },
        negative: {
          label: t("common:button.stopSession"),
          textStyle: { color: primary },
        },
      })
    },
    [cancelOncoSession, primary, showDialog, t],
  )

  useEffect(() => {
    exerciseIdRef.current = currentEx?.id
  }, [currentEx])

  return (
    <exerciseNavigator.Navigator initialRouteName="ExercisesList">
      <exerciseNavigator.Screen
        {...{ options: patientOptions }}
        name="ExercisesList"
        component={ExercisesListScreen}
      />

      <exerciseNavigator.Screen
        name="Exercise"
        options={{
          ...patientOptions,
          headerTitle: !isWatchOnly
            ? t(`${ExerciseNS}:sessionOfTheDay`)
            : t(`${ExerciseNS}:exercise`),
          headerLeft: props => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                userData?.role === "patient" && !isWatchOnly
                  ? cancelSession(props.onPress)
                  : navigation.goBack()
              }}
              vectorIcon={{
                name: "close",
                category: "MaterialIcons",
                size: ICON_SIZE,
                color: white,
              }}
              labelVisible={false}
            />
          ),
        }}
      >
        {props => (
          <TabProvider initialTab={{ index: "instructions", title: "" }}>
            <VideoProvider exDuration={currentEx?.duration}>
              <ExerciseScreen {...props} specialty={userData?.specialty} />
            </VideoProvider>
          </TabProvider>
        )}
      </exerciseNavigator.Screen>
      <exerciseNavigator.Screen name="ExerciseEvaluation" options={hideHeader}>
        {props => (
          <FormikScreen<ExerciseEvalFormValues>
            {...props}
            screenParams={{
              evalType: EvalType.EXERCISE,
              sessionId,
              exerciseId: exerciseIdRef.current,
            }}
            component={EvaluationScreen}
            formikParams={
              evaluationFormikParams(EvalType.EXERCISE) as FormikParams<ExerciseEvalFormValues>
            }
          />
        )}
      </exerciseNavigator.Screen>
    </exerciseNavigator.Navigator>
  )
}

const styles = StyleSheet.create({
  header: {
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 0,
  },
})

export default ExerciseNavigator
