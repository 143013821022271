import { RouteProp } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  firestore,
  IRowItemProps,
  ISectionHeader,
  IUserContext,
  ParametersScreen,
  PresentationListView,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import { useFormikContext } from "formik"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, Platform, SectionListData, StyleSheet } from "react-native"
import FastImage from "react-native-fast-image"

import { Pathology } from "../../common/Pathology"
import { collections } from "../../common/types"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import { itemName } from "../../features/hooks/useItemI18n"
import { AppUserData } from "../../features/models/UserData"
import { renderIcon } from "../../utils/renderIcon"
import { OperationFormValues } from "../OperationScreen/Constants"
import { OperationParamList } from "../OperationScreen/OperationNavigator"
import PathologyRow from "./PathologyRow"

interface IProps {
  navigation: StackNavigationProp<OperationParamList, "Pathology" | "Protocol">
  route: RouteProp<OperationParamList, "Pathology">
}

const keyExtractor = item => item.id

const PathologyScreen: FC<IProps> = ({ route, navigation }) => {
  const { t } = useTranslation()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const {
    colors: {
      black: { highEmphasis: black },
      white: { highEmphasis: white },
      primary,
    },
    dimensions: { spacing },
    typography: { subtitle1, subtitle2 },
  } = useTheme()
  const s = useMemo(
    () => ({
      image: [
        styles.image,
        {
          margin: spacing,
          marginRight: spacing * 2,
        },
      ],
      contentContainer: {
        backgroundColor: white,
      },
      text: {
        color: black,
        flex: 1,
      },
      indicator: { color: primary, size: ICON_SIZE },
      row: [
        styles.row,
        {
          backgroundColor: white,
        },
      ],
      parametersStyle: {
        fontSize: subtitle2.fontSize,
      },
    }),
    [black, primary, spacing, white, subtitle2.fontSize],
  )
  const [pathologies, loading, error] = useCollectionData<Pathology>(
    !_.isEmpty(route?.params?.organId)
      ? ((firestore()
          .collection(collections.PATHOLOGIES)
          .where(collections.PATHOLOGIES_ORGAN, "==", route?.params?.organId)
          .where("disabled", "==", false) as unknown) as firebase.firestore.Query<Pathology>)
      : null,
  )

  // Radio button for Patient view
  const { setFieldValue } = useFormikContext<OperationFormValues>()

  const onPress = useCallback(() => {
    setFieldValue("organ", route?.params?.organId)
    setFieldValue("specialty", route?.params?.specialty)
    navigation.navigate(route?.params?.specialty === "onco" ? "OncoPathology" : "Operation")
  }, [navigation, route?.params?.organId, route?.params?.specialty, setFieldValue])

  const pathologiesRows: ListRenderItem<Pathology> = useCallback(
    ({ item }) => <PathologyRow item={item} onPress={onPress} userLanguage={userData?.lng} />,
    [onPress, userData?.lng],
  )

  // SectionList for pratician view
  const pathologiesData = _(pathologies)
    .map(
      pathology =>
        ({
          rightChild: renderIcon,
          leftChild: (
            <FastImage
              style={s.image}
              source={{
                uri: pathology.image,
                ...(Platform.OS !== "web" && {
                  priority: FastImage.priority.high,
                  cache: FastImage.cacheControl.immutable,
                }),
              }}
              {...(Platform.OS !== "web" && { resizeMode: FastImage.resizeMode.cover })}
            />
          ),
          name: itemName(pathology, t),
          rowStyle: s.row,
          textStyle: subtitle1,
          route: "Protocol",
          params: { pathology: pathology.id, organId: route?.params?.organId },
        } as IRowItemProps<OperationParamList>),
    )
    .value()

  const pathologySectionData: Array<
    SectionListData<IRowItemProps<OperationParamList>, ISectionHeader>
  > = [
    {
      shadows: 1,
      data: pathologiesData,
      title: "",
    },
  ]

  return (
    <PresentationListView
      isEmpty={_.isEmpty(pathologies)}
      indicator={s.indicator}
      info={{ title: t("list.empty.comingSoon") }}
      loading={loading}
      error={error}
    >
      {userData?.role === "patient" ? (
        <FlatList
          data={pathologies}
          renderItem={pathologiesRows}
          keyExtractor={keyExtractor}
          contentContainerStyle={s.contentContainer}
          scrollIndicatorInsets={{ right: 1 }}
        />
      ) : (
        <ParametersScreen
          listKey="pathologyList"
          sections={pathologySectionData}
          textStyle={s.parametersStyle}
          maxFontSizeMultiplier={1}
        />
      )}
    </PresentationListView>
  )
}

const styles = StyleSheet.create({
  image: {
    height: INPUT_HEIGHT,
    width: INPUT_HEIGHT,
  },
  row: {
    height: 80,
  },
})

export default PathologyScreen
