import {
  Button,
  IRowItemProps,
  ISectionHeader,
  ParametersScreen,
  SectionSeparator,
  useTabLand,
  useTheme,
} from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, SectionListData, StyleSheet } from "react-native"

import { INPUT_HEIGHT } from "../features/config/Constants"
import { promsContext } from "../features/Providers/PromsProvider"
import { leftChild, rightChild } from "../screens/SettingsScreen/Constants"

interface IProps {
  listItems: any
  listKey: string
  settingsForm?: string
  questionId?: number
}

const SettingsList: FC<IProps> = ({ listItems, listKey, settingsForm, questionId }) => {
  const {
    colors: { black, primary },
    typography: { subtitle1 },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const isTabLand = useTabLand()
  const { loadSigninForm } = useContext(promsContext)
  const getSigninForm = useCallback(
    async (id: string, formId?: number) => {
      await loadSigninForm?.({ id, questionId: formId ?? undefined, isEdit: true })
    },
    [loadSigninForm],
  )

  const s = useMemo(
    () => ({
      buttonLabel: {
        color: primary,
      },
      button: [
        styles.button,
        {
          marginTop: spacing,
        },
      ],
      padding: {
        paddingVertical: spacing,
      },
    }),
    [primary, spacing],
  )

  const footerComponent = useMemo(
    () =>
      settingsForm ? (
        <Button
          style={s.button}
          labelStyle={s.buttonLabel}
          mode="outlined"
          onPress={() => getSigninForm(settingsForm, questionId ?? undefined)}
        >
          {t(`common:button.${listKey}`)}
        </Button>
      ) : null,
    [s.button, s.buttonLabel, t, getSigninForm, listKey, settingsForm, questionId],
  )

  const common = useCallback(
    item => ({
      textStyle: subtitle1,
      color: black.highEmphasis,
      name: item?.name,
      rowStyle: styles.row,
      rightChild: item?.rightChild ?? rightChild,
      leftChild: item?.leftChild ?? {
        ...leftChild,
        name: item?.iconName,
        color: black.highEmphasis,
      },
    }),
    [black.highEmphasis, subtitle1],
  )

  const webSeparator = useMemo(
    () => ({
      data: [
        {
          name: "webSeparator",
          render: () => (isTabLand && Platform.OS === "web" ? <SectionSeparator /> : null),
        },
      ],
    }),
    [isTabLand],
  )

  const oncoItems: Array<SectionListData<IRowItemProps, ISectionHeader>> = useMemo(
    () => [
      {
        data: _.map(listItems, item => ({
          ...common(item),
          route: item?.route ? item?.route : null,
          onPress: item?.form ? () => getSigninForm(item?.form, item?.formId) : undefined,
        })),
        shadows: 1,
      },
      webSeparator,
    ],
    [common, webSeparator, listItems, getSigninForm],
  )

  return (
    <ParametersScreen
      listKey={listKey}
      paddingStyle={s.padding}
      showSeparator
      sections={oncoItems}
      color={black.mediumEmphasis}
      {...{ footerComponent }}
    />
  )
}

const styles = StyleSheet.create({
  row: {
    height: INPUT_HEIGHT,
  },
  button: {
    width: "100%",
  },
})

export default SettingsList
