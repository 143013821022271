import _ from "lodash"
import { Platform, TextStyle } from "react-native"

import { FmOptions, ICSTheme } from "./types"

/* Generate styles for a font with given weight and style.
   The purpose of this utility is to overcome differences in font names
   and capabilities between iOS and Android.
   iOS natively picks the correct font in a family based on the requested weight
   and style, whereas Android requires specifying the font file name and more
   or less ignores the weight and style.
 */
const fontMaker = ({ fonts }: Pick<ICSTheme, "fonts">) => (options: FmOptions = {}): TextStyle => {
  const family = options.family || "default"

  // eslint-disable-next-line prefer-const
  let { weight, style } = {
    weight: fonts[family]?.defaultWeight ?? "Regular",
    ...options,
  }

  const fontFamily = fonts[family] ?? fonts.default

  const { weights, styles } = fontFamily

  if (Platform.OS === "android") {
    const styleName = styles && style && styles[style] ? style : ""
    const weightName =
      weights && weights[weight] && (styleName === "" || weight !== "Regular") ? weight : ""

    const suffix = weightName + _.capitalize(styleName)

    if (!fontFamily.family) {
      return {}
    }

    return {
      fontFamily: fontFamily.family + (suffix.length ? `-${suffix}` : ""),
    }
  }
  const weightValue = (weights && (weights[weight] || weights.Regular)) || "400"
  style = (style && styles && styles[style]) || "normal"

  return {
    fontFamily: Platform.OS === "ios" ? fontFamily.iosFamily : fontFamily.family,
    fontWeight: weightValue,
    fontStyle: style,
  }
}

export default fontMaker
