import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator, StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { Edge } from "react-native-safe-area-context"

import useTheme from "../../features/Theme/ThemeProvider"
import EmptyView from "../EmptyView/EmptyView"

export interface Indicator {
  size: "small" | "large" | number
  color: string
}

export interface Message {
  title?: string
  subtitle?: string
}

interface IProps {
  loading?: boolean
  isEmpty: boolean
  children: React.ReactNode
  info?: Message
  childInfo?: React.ReactNode
  error?: Error | undefined
  childError?: React.ReactNode
  indicator?: Indicator
  style?: StyleProp<ViewStyle>
  edges?: Edge[] // ios exclusive to control safe edges
}
// TODO: do not forget to update Dilo for the repercussion
const PresentationListView: FC<IProps> = ({
  loading,
  isEmpty,
  children,
  error,
  childError,
  childInfo,
  indicator,
  style,
  edges,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    colors: { primary },
  } = useTheme()
  const { title, subtitle } = props.info ?? {
    title: t("list.empty.title"),
    subtitle: t("list.empty.subtitle"),
  }
  if (error) {
    return childError ? (
      <View style={[styles.children, style]}>{childError}</View>
    ) : (
      <EmptyView title={error.name} subtitle={error.message} />
    )
  } else {
    return loading ? (
      <View style={[styles.indicator, style]}>
        <ActivityIndicator
          style={[styles.indicator, style]}
          size={indicator?.size ?? "small"}
          color={indicator?.color ?? primary}
        />
      </View>
    ) : isEmpty ? (
      childInfo ? (
        <View style={[styles.children, style]}>{childInfo}</View>
      ) : (
        <EmptyView {...{ title, subtitle, style, edges }} />
      )
    ) : (
      <View style={[styles.children, style]}>{children}</View>
    )
  }
}

const styles = StyleSheet.create({
  children: {
    flex: 1,
  },
  indicator: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
  },
})

export default PresentationListView
