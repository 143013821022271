import _ from "lodash"
import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  PropsWithChildren,
  ReactNode,
} from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"

import { IKeyboardProps, KeyboardSafeAreaScrollView } from "../KeyboardSafeAreaScrollView"

export type InputContainerStyle = Partial<{
  container: StyleProp<ViewStyle>
  label: StyleProp<TextStyle>
  error: StyleProp<TextStyle>
  child: StyleProp<ViewStyle>
}>

interface IProps {
  scrollable?: boolean
  styles: InputContainerStyle
  keyboardProps?: IKeyboardProps
}

const getChildrenWithStyles = (children: ReactNode, styles: InputContainerStyle) => {
  if (isValidElement(children) && _.get(children?.type, "name") === "InputFocusProvider") {
    const { children: formChildren } = children.props
    return cloneElement(children, {
      children: Children.map(formChildren as ReactNode, child =>
        isValidElement(child) ? cloneElement(child, { styles }) : child,
      ),
    })
  }
  return Children.map(children, child =>
    isValidElement(child) ? cloneElement(child, { styles }) : child,
  )
}

// Useful to pass styles (error, label, child) for each component inside the form
/** Update InputContainer to detect if a form is inside, useful if we want to control the focus
 * If the form contains inline input or deeper input children, its style control is not suitable.
 * It is preferable to use Form and create the root View/ScrollView inside the screen. */
export const InputContainer: FC<PropsWithChildren<IProps>> = ({
  styles,
  children,
  keyboardProps,
  scrollable,
}) =>
  scrollable ? (
    <KeyboardSafeAreaScrollView {...keyboardProps} viewStyle={styles.container}>
      {getChildrenWithStyles(children, styles)}
    </KeyboardSafeAreaScrollView>
  ) : (
    <View style={styles.container}>{getChildrenWithStyles(children, styles)}</View>
  )

export default InputContainer
