import { Phase } from "../../common/Phase"
import { SessionDetails } from "./Session"
import { AppUserData } from "./UserData"

// eslint-disable-next-line no-shadow
export enum DayNum {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

/** Apart from isEdit, the rest are non nullable params.
 * Sessions and notifications cannot exist without them.
 **/
export interface LocalNotification {
  // user
  userData: Pick<AppUserData, "surgeryDate" | "alertEndTime" | "alertStartTime" | "alertDays">
  // phase
  phase: Pick<Phase, "startDay" | "endDay">
  // App status
  isEdit?: boolean
  sessionDetails: SessionDetails[] // to create new session when a new phase is selected
}
