import {
  createMaterialTopTabNavigator,
  MaterialTopTabNavigationOptions,
} from "@react-navigation/material-top-tabs"
import { EmptyView, firestore, IUserContext, userContext, useTabLand, useTheme } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { useContext, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { Organ } from "../../common/Pathology"
import { collections } from "../../common/types"
import { itemField } from "../../features/hooks/useItemI18n"
import { HomeNS, PatientsListNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import PatientsListScreen from "./PatientsListScreen"

const patientsListTabNavigator = createMaterialTopTabNavigator()

const PatientsListNavigator = () => {
  const {
    typography: { button },
    colors: { white, primary, black },
    dimensions: { spacing },
  } = useTheme()
  const isTabLand = useTabLand()

  const { userData } = useContext<IUserContext<AppUserData>>(userContext)

  const [organs, loading, error] = useCollectionData<Organ>(
    ((userData?.specialty
      ? firestore()
          .collection(collections.ORGANS)
          .where("specialty", "==", userData?.specialty)
          .where("disabled", "==", false)
      : firestore()
          .collection(collections.ORGANS)
          .where("disabled", "==", false)) as unknown) as firebase.firestore.Query<AppUserData>,
  )

  const { t } = useTranslation()
  const screenOptions = useMemo<MaterialTopTabNavigationOptions>(
    () => ({
      tabBarScrollEnabled: true,
      tabBarActiveTintColor:
        isTabLand && Platform.OS === "web" ? black.highEmphasis : white.highEmphasis,
      tabBarStyle: {
        backgroundColor: isTabLand && Platform.OS === "web" ? white.highEmphasis : primary,
      },
      tabBarInactiveTintColor:
        isTabLand && Platform.OS === "web" ? black.mediumEmphasis : white.mediumEmphasis,
      tabBarIndicatorStyle: {
        height: 2,
        backgroundColor:
          isTabLand && Platform.OS === "web" ? black.highEmphasis : white.highEmphasis,
      },
      tabBarLabelStyle: { ..._.omit(button, "color"), textTransform: "none" },
      tabBarItemStyle: { width: 158 },
    }),
    [
      black.highEmphasis,
      black.mediumEmphasis,
      button,
      isTabLand,
      primary,
      white.highEmphasis,
      white.mediumEmphasis,
    ],
  )

  const s = useMemo(
    () => ({
      indicator: {
        paddingTop: spacing,
      },
    }),
    [spacing],
  )

  return loading ? (
    <ActivityIndicator color={primary} style={s.indicator} />
  ) : error ? (
    <EmptyView title={t(`${HomeNS}:empty`)} />
  ) : (
    <patientsListTabNavigator.Navigator {...{ screenOptions }}>
      <>
        <patientsListTabNavigator.Screen
          name="all"
          component={PatientsListScreen}
          options={{
            title: t(`${PatientsListNS}:all`),
          }}
        />
        {_.map(organs, organ => {
          const title = itemField(organ, "name", t)
          return (
            <patientsListTabNavigator.Screen
              key={organ.id}
              name={organ.id}
              options={{
                title,
              }}
            >
              {() => <PatientsListScreen key={organ.id} {...{ organ }} />}
            </patientsListTabNavigator.Screen>
          )
        })}
      </>
    </patientsListTabNavigator.Navigator>
  )
}

export default PatientsListNavigator
