import { Body2, Button, ButtonType, Subtitle1, useAlert, userContext, useTheme } from "capsule"
import React, { useCallback, useContext, useMemo } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

import { DeleteAccountNS, NavigationNS } from "../../features/i18n/constants"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { maxWidth } from "../../ThemeApp"

const DeleteAccountScreen = () => {
  const { userDocRef, logout } = useContext(userContext)
  const { t } = useTranslation()
  const { showDialog } = useAlert()

  const {
    colors: {
      black,
      surface: { background },
    },
    dimensions: { spacing },
    fontMaker,
  } = useTheme()

  const s = useMemo(
    () => ({
      safe: [
        styles.safe,
        {
          paddingLeft: spacing * 2,
          paddingRight: spacing * 2,
          backgroundColor: background,
        },
      ],
      paragraph: {
        paddingBottom: spacing,
      },
      bold: fontMaker({ weight: "Bold" }),
      dialogTextColor: {
        color: black.mediumEmphasis,
      },
      screenStyle: [
        Platform.OS === "web" ? { width: maxWidth } : null,
        { paddingTop: spacing, backgroundColor: background },
      ],
    }),
    [spacing, fontMaker, black.mediumEmphasis, background],
  )

  const deleteAccount = useCallback(() => {
    updateUserData(userDocRef, { disabled: true })
    // noinspection JSIgnoredPromiseFromCall
    logout()
  }, [logout, userDocRef])

  const onPress = useCallback(() => {
    showDialog({
      type: "button",
      title: t(`${NavigationNS}:DeleteAccount:definitiveDeletionTitle`),
      message: t(`${NavigationNS}:DeleteAccount:definitiveDeletionParagraph`),
      messageStyle: s.dialogTextColor,
      positive: {
        buttonStyle: styles.dialogRightButton,
        onPress: deleteAccount,
        type: ButtonType.POSITIVE,
        label: t(`${NavigationNS}:DeleteAccount:title`),
        labelType: "destructive",
      },
      negative: {
        buttonStyle: styles.dialogLeftButton,
        label: t(`${NavigationNS}:DeleteAccount:cancel`),
        type: ButtonType.NEGATIVE,
        textStyle: s.dialogTextColor,
      },
    })
  }, [deleteAccount, t, showDialog, s.dialogTextColor])

  return (
    <SafeAreaView style={s.safe}>
      <View style={s.screenStyle}>
        <View>
          <Subtitle1 style={s.paragraph}>{t(`${DeleteAccountNS}:title`)}</Subtitle1>
          <Body2 style={s.paragraph}>
            <Trans
              i18nKey={`${DeleteAccountNS}:warningParagraph`}
              // @ts-ignore
              components={{ p: <Body2 style={s.padding} />, b: <Body2 style={s.bold} /> }}
            />
          </Body2>
          <Body2 style={s.paragraph}>
            <Trans
              i18nKey={`${DeleteAccountNS}:validateDeletion`}
              // @ts-ignore
              components={{ p: <Body2 style={s.padding} />, b: <Body2 style={s.bold} /> }}
            />
          </Body2>
        </View>
        <View style={styles.container}>
          <View>
            <Button onPress={onPress}>{t("common:button.delete")}</Button>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  safe: {
    alignItems: "center",
    flex: 1,
  },
  container: {
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    paddingTop: 20,
  },
  dialogLeftButton: {
    marginRight: 0,
    flex: 0,
  },
  dialogRightButton: {
    marginLeft: 0,
    flex: 0,
  },
})

export default DeleteAccountScreen
