import {
  firestore,
  IUserContext,
  ProgressionList,
  ProgressItem,
  userContext,
  useTheme,
} from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { AppUserData } from "src/features/models/UserData"

import { Phase } from "../../common/Phase"
import { collections } from "../../common/types"
import AccordionSection from "../../components/AccordionSection"
import currentDateMs from "../../features/hooks/useDateMock"
import { PatientNS, PatientsListNS } from "../../features/i18n/constants"
import PatientEvaluationView from "./PatientEvaluationView"
import PatientHandlePhasesView from "./PatientHandlePhasesView"
import { PatientProps } from "./PatientScreen"
import PatientStatisticView from "./PatientStatisticView"

const PatientProgressionView: FC<PatientProps> = ({ patient }) => {
  const {
    colors: {
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { t } = useTranslation()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const [phases, setPhases] = useState<Phase[]>([])
  useEffect(() => {
    const query = firestore()
      .collection(collections.PHASES)
      .where("pathology", "==", patient.pathology)
      .orderBy("startDay", "asc")
    const fetchData = async () => {
      const snapshot = await query.get()
      const allDocuments: Phase[] = snapshot.docs.map(doc => {
        const phase = doc.data()
        return {
          ...phase,
          startDay: phase.startDay,
        } as Phase
      })
      setPhases(allDocuments)
    }
    fetchData()
  }, [patient.pathology])
  const isPatient = userData?.role === "patient"
  const isKine = userData?.role === "kine"
  const calculatePercent = useCallback(
    (phase: Phase) => {
      const totalDays = phase.endDay - phase.startDay
      const current = dayjs(currentDateMs()).diff(
        dayjs(patient.surgeryDate).add(phase.startDay, "d"),
        "days",
      )
      return (current / totalDays) * 100
    },
    [patient.surgeryDate],
  )

  const data: ProgressItem[] = useMemo(() => {
    let index = 0
    const filteredPhases = _.filter(phases, phase => !phase.isDisabled)
    const currentPhasePosition = _.findIndex(filteredPhases, p => p.id === patient.phase)
    return filteredPhases
      ? _.map(filteredPhases, phase => {
          index = index + 1
          const hideSeparator = index === filteredPhases.length
          const percent =
            phase.id === patient.phase
              ? calculatePercent(phase)
              : currentPhasePosition >= index
              ? 100
              : 0
          return {
            percent,
            hideSeparator,
            key: phase.id,
            label: t(`daySinceSurgery_${phase.startDay > 0 ? "positive" : "negative"}`, {
              ns: PatientsListNS,
              nbDays: phase.startDay,
            }),
          }
        })
      : []
  }, [calculatePercent, patient.phase, phases, t])

  const phaseIndex = data.findIndex(phase => phase.percent < 100)
  const currentPhaseIndex = isPatient ? phaseIndex + 1 : phaseIndex
  const { style } = useMemo(
    () => ({
      style: [
        {
          backgroundColor: white,
        },
      ],
    }),
    [white],
  )

  return (
    <View>
      {!_.isEmpty(data) && !isPatient ? (
        <AccordionSection
          leftIcon="trending-up"
          title={isPatient ? t(`${PatientNS}:patientProgression`) : t(`${PatientNS}:progression`)}
          expandList={true}
        >
          <View {...{ style }}>
            <ProgressionList {...{ isPatient, data, currentPhaseIndex }} />
          </View>
        </AccordionSection>
      ) : null}
      <PatientHandlePhasesView {...{ patient, isKine }} />
      <PatientEvaluationView {...{ patient }} />
      {patient.activityTracking.length > 0 ? <PatientStatisticView {...{ patient }} /> : null}
    </View>
  )
}

export default PatientProgressionView
