import NetInfo from "@react-native-community/netinfo"
import { useFocusEffect, useIsFocused, useNavigation } from "@react-navigation/native"
import {
  ButtonType,
  DialogResponse,
  H6,
  useAlert,
  useStyles,
  useTheme,
  VectorIconProps,
  VideoPlayer,
} from "capsule"
import dayjs from "dayjs"
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { BackHandler, View } from "react-native"
import { IconButton } from "react-native-paper"
import { OnVideoErrorData } from "react-native-video"

import { ID, SpecialtyName } from "../../common/CommonUserData"
import CountdownOverlay from "../../components/CountdownOverlay"
import EmptyVideo from "../../components/EmptyVideo"
import { ICON_SIZE_MEDIUM } from "../../features/config/Constants"
import { ExerciseNS } from "../../features/i18n/constants"
import { useProgram } from "../../features/Providers/ProgramProvider"
import { sessionContext } from "../../features/Providers/SessionProvider"
import { useVideo } from "../../features/Providers/VideoProvider"

interface IProps {
  id: ID
  uri?: string
  reps?: number
  series?: number
  readOnly?: boolean
  specialty?: SpecialtyName
  isFullScreenPrincipal?: boolean
  setIsFullScreenPrincipal?: Dispatch<SetStateAction<boolean>>
}

const ExerciseVideoView: FC<IProps> = ({
  uri,
  id,
  reps,
  series,
  readOnly,
  specialty,
  isFullScreenPrincipal,
  setIsFullScreenPrincipal,
}) => {
  // global contexts
  const {
    colors: { primary: localPrimary, white: localWhite },
  } = useTheme()

  const navigation = useNavigation<any>()
  const isFocused = useIsFocused()
  const { t } = useTranslation(ExerciseNS)
  const { showDialog, setIsValid } = useAlert()

  // context to control tabs and phase screen
  const { prefTabVideo, setPrefTabVideo } = useProgram()

  // context to control the exercise screen and its video
  const {
    videoRef,
    videoState,
    dispatch,
    actions: { reset, pause, resume, start },
    timeLeft,
    duration,
    videoError,
    setVideoError,
  } = useVideo()
  const { startExercise, cancelSession } = useContext(sessionContext)
  const isOnco = useMemo(() => specialty === "onco", [specialty])
  const isOrthoOrImplant = useMemo(() => specialty === "ortho" || specialty === "implant", [
    specialty,
  ])
  const [showReplayButton, setShowReplayButton] = useState(false)
  const [showCountDown, setShowCountDown] = useState(true)
  const [controlledPause, setControlledPause] = useState(duration ? false : true)
  const s = useStyles(
    ({ dimensions: { spacing }, colors: { primary, white } }) => ({
      buttonNegative: { color: primary, right: spacing / 4 },
      buttonPositive: { right: spacing / 4 },
      control: {
        paddingTop: spacing,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
      replay: {
        alignSelf: "center",
        height: "100%",
        justifyContent: "center",
        position: "absolute",
        color: white.mediumEmphasis,
      },
      time: {
        borderRadius: 24,
        position: "absolute",
        right: spacing / 2,
        bottom: spacing / 2,
        backgroundColor: white.highEmphasis,
        paddingVertical: spacing / 4,
        paddingHorizontal: spacing / 2,
      },
    }),
    [],
  )

  const icon = useMemo<VectorIconProps>(
    () => ({
      name: "play-circle-fill",
      category: "MaterialIcons",
      size: ICON_SIZE_MEDIUM,
      color: localWhite.highEmphasis,
    }),
    [localWhite],
  )

  useEffect(() => {
    NetInfo.addEventListener(state => {
      if (state.isConnected) {
        setVideoError(undefined)
      }
    })
  }, [setVideoError])

  useEffect(() => {
    if (prefTabVideo) {
      // avoid bad behaviour with react-countdown-hook
      setControlledPause(true)
      setTimeout(() => pause(), 0)
      dispatch({ type: "paused" })
    }
  }, [dispatch, pause, prefTabVideo])

  useEffect(() => {
    if (isOrthoOrImplant && timeLeft === 0 && !videoState.firstPress) {
      if (duration) {
        dispatch({ type: "paused" })
      }
      setShowReplayButton(true)
      dispatch({ type: "ready" })
    }
  }, [timeLeft, dispatch, reset, isOrthoOrImplant, videoState.firstPress, duration])

  const onVideoPress = useCallback(() => {
    if (videoState.firstPress && prefTabVideo) {
      setPrefTabVideo(false)
      start(duration)
      dispatch({ type: "start" })
      return
    }
    if (videoState.paused) {
      if (duration) {
        setControlledPause(false)
      }
      resume()
      setPrefTabVideo(false)
    } else {
      if (duration) {
        setControlledPause(true)
      }
      pause()
    }
    dispatch({ type: "play" })
  }, [
    videoState.firstPress,
    videoState.paused,
    prefTabVideo,
    dispatch,
    setPrefTabVideo,
    start,
    duration,
    resume,
    pause,
  ])

  const restartExercise = useCallback(() => {
    if (duration) {
      setShowCountDown(true)
      setControlledPause(true)
    }
    setShowReplayButton(false)
    dispatch({ type: "restart" })
    reset()
    // a trick: https://github.com/react-native-video/react-native-video/issues/1249#issuecomment-424636539
    setTimeout(() => videoRef?.current?.seek(0), 0)
  }, [dispatch, reset, videoRef, duration])

  const onCountdownFinish = useCallback(() => {
    setShowReplayButton(false)
    if (videoState.restart === 0 && !readOnly) {
      startExercise()
    }
    if (!prefTabVideo) {
      start(duration)
      dispatch({ type: "start" })
    } else {
      dispatch({ type: "ready" })
    }
    if (duration) {
      setShowCountDown(false)
      setControlledPause(false)
    }
  }, [videoState.restart, readOnly, prefTabVideo, startExercise, start, duration, dispatch])
  // TODO: fix it when internationalization will be added
  const renderDuration = useCallback(
    () => (
      <>
        {specialty ? (
          <View style={s.time}>
            <H6 color={localPrimary}>
              {isOrthoOrImplant
                ? dayjs(timeLeft).format("mm:ss")
                : `${series} x ${reps} répétitions`}
            </H6>
          </View>
        ) : null}
      </>
    ),
    [localPrimary, reps, series, s.time, specialty, isOrthoOrImplant, timeLeft],
  )

  const onError = useCallback(
    (error: OnVideoErrorData) => {
      setVideoError(error)
    },
    [setVideoError],
  )

  const onEnd = useCallback(() => {
    if (readOnly) {
      navigation.goBack()
    } else if (isOnco) {
      setShowReplayButton(true)
      return
    } else if (duration && timeLeft > 0) {
      setShowReplayButton(false)
      setPrefTabVideo(false)
      dispatch({ type: "restart" })
      setTimeout(() => videoRef?.current?.seek(0), 0)
      setTimeout(() => dispatch({ type: "start" }), 0)
    }
  }, [isOnco, navigation, readOnly, timeLeft, dispatch, duration, setPrefTabVideo, videoRef])

  /** Android BackHandler */
  const goBack = useCallback(async () => {
    if (readOnly) {
      navigation.goBack()
      return
    }
    setIsValid?.(true)
    const result: void | DialogResponse = await showDialog({
      type: "button",
      message: t("alert.message"),
      title: t("alert.title"),
      positive: {
        type: ButtonType.POSITIVE,
        textStyle: s.buttonPositive,
        buttonStyle: {
          borderWidth: 0,
          flex: undefined,
        },
        label: t("abandon"),
      },
      negative: {
        type: ButtonType.NEGATIVE,
        textStyle: s.buttonNegative,
        buttonStyle: {
          flex: undefined,
          borderWidth: 0,
        },
        label: t("common:button.cancel"),
      },
    })
    if ((result as DialogResponse).button === ButtonType.POSITIVE) {
      await cancelSession()
      navigation.navigate("Home")
    }
    return false
  }, [
    cancelSession,
    navigation,
    s.buttonPositive,
    s.buttonNegative,
    setIsValid,
    showDialog,
    t,
    readOnly,
  ])

  useFocusEffect(
    useCallback(() => {
      // @ts-ignore
      BackHandler.addEventListener("hardwareBackPress", goBack)

      // @ts-ignore
      return () => BackHandler.removeEventListener("hardwareBackPress", goBack)
    }, [goBack]),
  )

  return (
    <>
      <View>
        {(videoState.restart === 0 || (showCountDown && controlledPause)) && (
          <CountdownOverlay
            isPlaying={isFocused}
            key={`countdown-${id}`}
            onFinish={onCountdownFinish}
            restart={videoState.restart}
          />
        )}
        {!uri || videoError ? (
          <EmptyVideo />
        ) : (
          <VideoPlayer
            hasExtraHeader={false}
            enableFullScreen={true}
            isFullScreenPrincipal={isFullScreenPrincipal}
            setIsFullScreenPrincipal={setIsFullScreenPrincipal}
            key={`video-${id}`}
            forwardRef={videoRef}
            showProgress={true}
            {...{ onVideoPress, renderDuration, uri, icon }}
            alternativeProgress={duration ? ((duration - timeLeft) / duration) * 100 : undefined}
            controlledPause={controlledPause}
            control={{
              onEnd,
              onError,
              paused: videoState.paused,
            }}
          />
        )}
        {showReplayButton ? (
          <View style={s.replay}>
            <IconButton
              icon="replay"
              size={96}
              rippleColor={localPrimary}
              onPress={restartExercise}
            />
          </View>
        ) : null}
      </View>
    </>
  )
}

export default ExerciseVideoView
