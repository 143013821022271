import { RowItem, useTheme, VectorIcon } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"

import { images } from "../../assets/images"
import { PractitionerInfo, TeamInfo } from "../../common/CommonUserData"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import { PatientNS, PatientsListNS } from "../../features/i18n/constants"

interface IProps {
  surgeonId?: string
  surgeryDate?: Date | string
  medicalTeamInfo?: TeamInfo
}

const PatientPractitionersView: FC<IProps> = ({ surgeonId, surgeryDate, medicalTeamInfo }) => {
  const {
    dimensions: { spacing },
    colors: {
      black: { mediumEmphasis },
      transparent,
    },
  } = useTheme()
  const { t } = useTranslation()
  const surgeon = medicalTeamInfo?.[surgeonId ?? ""]
  const gps: PractitionerInfo[] = _.filter(medicalTeamInfo, mti => mti.role === "gp")
  const kines: PractitionerInfo[] = _.filter(medicalTeamInfo, mti => mti.role === "kine")
  const s = useMemo(
    () => ({
      expandBlock: [
        {
          paddingVertical: spacing,
        },
      ],
    }),
    [spacing],
  )

  const rowProps = useMemo(
    () => ({
      rowStyle: [styles.row, { backgroundColor: transparent }],
      labelStyle: styles.label,
    }),
    [transparent],
  )

  const renderLeftChild = useCallback(
    (name?: string) =>
      name ? (
        <FastImage
          style={styles.image}
          tintColor={mediumEmphasis}
          source={images?.[name] as number}
        />
      ) : (
        <VectorIcon
          size={ICON_SIZE}
          name="calendar-today"
          color={mediumEmphasis}
          category="MaterialIcons"
        />
      ),
    [mediumEmphasis],
  )

  const getPractitionerList = useCallback(
    (label: string, image: string, data: PractitionerInfo[]) =>
      _.map(data, practitioner => (
        <RowItem
          key={practitioner.firstName}
          {...rowProps}
          {...{ label }}
          leftChild={renderLeftChild(image)}
          name={t("name", {
            firstName: practitioner.firstName,
            lastName: practitioner.lastName,
            ns: PatientsListNS,
            interpolation: { escapeValue: false },
          })}
        />
      )),
    [renderLeftChild, rowProps, t],
  )

  return (
    <View style={s.expandBlock}>
      {surgeon ? (
        <RowItem
          {...rowProps}
          label={t(`${PatientNS}:surgeon`)}
          leftChild={renderLeftChild("custom_surgery")}
          name={t("name", {
            firstName: surgeon.firstName,
            lastName: surgeon.lastName,
            ns: PatientsListNS,
            interpolation: { escapeValue: false },
          })}
        />
      ) : null}
      {surgeryDate ? (
        <RowItem
          {...rowProps}
          name={dayjs(surgeryDate).format("L")}
          label={t(`${PatientNS}:surgeryDate`)}
          leftChild={renderLeftChild()}
        />
      ) : null}
      {kines ? getPractitionerList(t(`${PatientNS}:kine`), "kine_patient", kines) : null}
      {gps ? getPractitionerList(t(`${PatientNS}:gp`), "custom_surgery", gps) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    height: INPUT_HEIGHT,
  },
  label: {
    paddingBottom: 0,
  },
  image: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
})

export default PatientPractitionersView
