import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  Button,
  generateShadow,
  IUserContext,
  TextInput,
  userContext,
  useTheme,
} from "capsule"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { Text } from "react-native-paper"

import { PractitionerHomeNS } from "../features/i18n/constants"
import { AppUserData } from "../features/models/UserData"
import { updateUserData } from "../features/models/UserDataFunctions"
import { maxWidth } from "../ThemeApp"

interface IProps {
  isEdit?: boolean
}

const RdvUrlView: FC<IProps> = ({ isEdit }) => {
  const {
    colors: { accent, primary, error, surface },
    dimensions: { spacing },
  } = useTheme()
  const navigation = useNavigation()
  const { userData, userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const rdvLink = userData?.rdvLink
  const [value, setValue] = useState<string>("")
  const [urlError, setUrlError] = useState<string | null>(null)
  const [disabled, setDisabled] = useState<boolean>(false)
  const { t } = useTranslation(PractitionerHomeNS)
  const s = useMemo(
    () => ({
      view: [
        styles.view,
        {
          padding: spacing,
          backgroundColor: surface.background,
        },
        generateShadow(1),
      ],
      screenStyle: [Platform.OS === "web" && isEdit ? { width: maxWidth } : null],
      body: [
        styles.body,
        {
          padding: spacing,
          marginVertical: spacing * 2,
          backgroundColor: accent,
        },
      ],
      button: { marginTop: spacing * 4, marginBottom: spacing, marginHorizontal: spacing / 2 },
      label: [styles.buttonLabel, { right: spacing / 2 }],
      infoStyle: {
        marginVertical: spacing / 2,
      },
      errorStyle: {
        color: error,
      },
    }),
    [spacing, accent, error, isEdit, surface.background],
  )
  const buttonProps = useMemo(
    () => ({
      style: s.button,
    }),
    [s],
  )

  useEffect(() => {
    if (userData?.rdvLink) {
      setValue(userData.rdvLink)
    }
  }, [userData?.rdvLink])

  const isValidUrl = (url: string) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i",
    )
    return pattern.test(url)
  }

  const onChangeText = (text: string) => {
    setValue(text)
    setDisabled(false)
    setUrlError(null)
  }

  useEffect(() => {
    if (rdvLink) {
      onChangeText(rdvLink)
    }
  }, [rdvLink])

  const onPress = useCallback(() => {
    if (!isValidUrl(value) && value !== "") {
      setUrlError(t("rdvUrl.error"))
      setDisabled(true)
    } else {
      setUrlError(null)
      updateUserData(userDocRef, { rdvLink: value.toLowerCase() })
      if (isEdit) {
        navigation.goBack()
      }
    }
  }, [value, t, userDocRef, navigation, isEdit])

  return (
    <View style={s.view}>
      <View style={s.screenStyle}>
        <Body2 style={s.body}>{t("subtitle.rdvUrl")}</Body2>
        <TextInput
          blurOnSubmit
          style={styles.textInput}
          label={t("rdvUrl.label")}
          autoComplete="off"
          autoCorrect={false}
          autoCapitalize="none"
          {...{ onChangeText, value }}
        />
        <Text style={s.infoStyle}>{t("rdvUrl.info")}</Text>
        {error && <Text style={s.errorStyle}>{urlError}</Text>}
        <Button {...buttonProps} disabled={disabled} onPress={onPress}>
          {t("common:button.validate")}
        </Button>
        {!isEdit ? (
          <Button
            mode="text"
            labelStyle={s.label}
            onPress={onPress}
            contentStyle={styles.buttonContent}
            disabled={disabled}
          >
            <Body2 color={primary}>{t("common:button.skip")}</Body2>
          </Button>
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    alignItems: "center",
    height: "100%",
  },
  textInput: {
    maxHeight: 69,
  },
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
})

export default RdvUrlView
