import React, { FC, useCallback, useMemo } from "react"
import { FlatList, ListRenderItemInfo, Platform } from "react-native"

import { ICON_SIZE } from "../../../../src/features/config/Constants"
import useTheme from "../../features/Theme/ThemeProvider"
import ProgressionItem, { ProgressItem, SeparatorPosition } from "./ProgressionItem"

interface IProps {
  isPatient: boolean
  size?: number
  width?: number
  height?: number
  data: ProgressItem[]
  currentPhaseIndex?: number
  horizontal?: boolean
  separator?: SeparatorPosition
}

export interface SelectedItem {
  key: string
  titlePhase: string
  descriptionPhase: string
}

const keyExtractor = (item: ProgressItem) => item.key

const ProgressionList: FC<IProps> = ({
  isPatient,
  data,
  currentPhaseIndex,
  horizontal = true, // vertical list is not implemented yet. Future evolution
  size = ICON_SIZE,
  height = 2,
  separator,
  width = 56,
}) => {
  const {
    dimensions: { spacing },
  } = useTheme()
  const { style, contentContainerStyle } = useMemo(
    () => ({
      style: { paddingVertical: spacing },
      contentContainerStyle: { paddingHorizontal: spacing },
    }),
    [spacing],
  )

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<ProgressItem>) => (
      <ProgressionItem borderWidth={height} {...{ size, item, separator, width, isPatient }} />
    ),
    [height, separator, size, width, isPatient],
  )

  const getItemLayoutForWeb = useCallback(
    (_, i) => ({
      length: 0,
      offset: 0 * i,
      index: i,
    }),
    [],
  )

  const getItemLayout = useCallback(
    (_, i) => ({
      length: width,
      offset: width * i,
      index: i,
    }),
    [width],
  )
  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      initialScrollIndex={
        Platform.OS === "web"
          ? 0
          : currentPhaseIndex !== -1
          ? currentPhaseIndex === data.length
            ? data.length - 1
            : currentPhaseIndex
          : data.length - 1
      }
      getItemLayout={Platform.OS === "web" ? getItemLayoutForWeb : getItemLayout}
      {...{ renderItem, keyExtractor, data, horizontal, style, contentContainerStyle }}
    />
  )
}

export default ProgressionList
