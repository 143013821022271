import { useNavigation } from "@react-navigation/native"
import { Button, ButtonProps, generateShadow, useTheme } from "capsule"
import { useFormikContext } from "formik"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, ViewStyle } from "react-native"

import { EvalFormValues, ExerciseEvalFormValues } from "../features/models/Evaluation"
import { AlertFormValues } from "../screens/AlertScreen/Constants"
import { OncoFormValues } from "../screens/OncoPathologyScreen/Constants"
import { OperationFormValues } from "../screens/OperationScreen/Constants"
import { ProfileFormValues } from "../screens/ProfileScreen/Constants"
import { SurgeonPracticeFormValues } from "../screens/SurgeonPracticeScreen/Constants"

type Form =
  | ProfileFormValues
  | OperationFormValues
  | AlertFormValues
  | SurgeonPracticeFormValues
  | EvalFormValues
  | ExerciseEvalFormValues
  | OncoFormValues

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IProps<FormType> {
  label?: string
  isEdit?: boolean
  onSubmit?: () => void
  viewStyle?: StyleProp<ViewStyle>
  isDisabled?: boolean
  syncLoading?: boolean
}

function SubmitButton<FormType extends Form>({
  label,
  viewStyle,
  onSubmit,
  isDisabled,
  isEdit = false,
  syncLoading,
}: IProps<FormType>) {
  const {
    dimensions: { spacing },
    colors: {
      white,
      black: { disabled },
    },
  } = useTheme()
  const { t } = useTranslation()
  const { isValid, isSubmitting, validateOnMount, handleSubmit } = useFormikContext<FormType>()
  const navigation = useNavigation()
  const isReady = (isValid || validateOnMount) && !isSubmitting

  const s = useMemo(
    () => ({
      button: [
        {
          marginHorizontal: spacing,
        },
        !isReady || isDisabled ? undefined : generateShadow(4),
        viewStyle,
      ],
      label: {
        color: isReady ? white.highEmphasis : disabled,
      },
    }),

    [spacing, isReady, isDisabled, viewStyle, white.highEmphasis, disabled],
  )
  const touchableProps = useMemo<Partial<ButtonProps>>(
    () => ({
      // @ts-ignore
      style: s.button,
      labelStyle: s.label,
      loading: syncLoading ?? isSubmitting,
      disabled: (!isValid && !validateOnMount) || isSubmitting || isDisabled,
    }),
    [s.button, s.label, syncLoading, isSubmitting, isValid, validateOnMount, isDisabled],
  )

  const onPress = useCallback(() => {
    handleSubmit?.()
    if (isEdit) {
      navigation.goBack()
    }
  }, [handleSubmit, isEdit, navigation])

  return (
    <Button onPress={onSubmit ?? onPress} {...touchableProps}>
      {label ?? t("common:button.save")}
    </Button>
  )
}

export default SubmitButton
