import dayjs from "dayjs"
import localData from "dayjs/plugin/localeData"

export const ISO_8601_TIME = "HH:mm"
export const ISO_8601_DATE = "YYYY-MM-DD"

dayjs.extend(localData)

export const dateFormatHours = () =>
  dayjs().localeData().longDateFormat("LT").replace(/h/g, "HH").replace("A", "").replace(" ", "")

export const dateFormatIsoTime = () =>
  dayjs
    .localeData()
    .longDateFormat("LTS")
    .replace(/[H|h]?[H|h]./g, "")
    .replace("A", "")
