import {
  Caption,
  H4,
  Subtitle1,
  ThemeProvider,
  useTheme,
  WelcomeButton,
  WelcomeScreen,
} from "capsule"
import _ from "lodash"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import { welcomeImages } from "../../assets/images"
import { NavigationNS } from "../i18n/constants"
import { useRole } from "../Providers/RoleProvider"
import { OnboardingRouteNames } from "./RootNavigator"

const MyWelcomeScreen = ({ navigation }) => {
  const { setRole } = useRole()

  const {
    colors: {
      surface: { background },
      black: { highEmphasis },
      primary,
      white: { highEmphasis: white },
    },
    dimensions: { spacing, marginSides },
  } = useTheme()

  const s = useMemo(
    () => ({
      image: {
        marginTop: marginSides,
        marginBottom: spacing / 2,
      },
    }),
    [marginSides, spacing],
  )

  const { t } = useTranslation()

  const onSubmitWelcome = useCallback(
    async (button: WelcomeButton, navAction: (key: OnboardingRouteNames) => void) => {
      if (button === "signUp") {
        setRole?.("patient")
      } else {
        setRole?.("practitioner")
      }
      navAction("Cgu")
    },
    [setRole],
  )

  return (
    <ThemeProvider
      customTheme={{
        colors: { surface: { background } },
      }}
    >
      <WelcomeScreen
        buttonStyles={
          Platform.OS !== "web"
            ? [
                {
                  color: white,
                },
                {
                  color: primary,
                },
              ]
            : [
                {
                  hidden: true,
                },
                {
                  color: white,
                  mode: "contained",
                },
              ]
        }
        pages={_.map(_.range(0, 4), i => ({
          // @ts-ignore false positive
          image: welcomeImages[i],
          ...t(`${NavigationNS}:Welcome:welcome.${i}`, { returnObjects: true }),
        }))}
        onSubmit={(button: WelcomeButton) => onSubmitWelcome(button, navigation.navigate)}
        TitleComponent={H4}
        // @ts-ignore
        transComponents={{ p: <Caption /> }}
        DescriptionComponent={Subtitle1}
        imageStyle={s.image}
        hideStatusBar={false}
        showHtmlView
        color={{ color: highEmphasis }}
      />
    </ThemeProvider>
  )
}

export default MyWelcomeScreen
