import { ParamListBase } from "@react-navigation/core"
import { getPathFromState, LinkingOptions, NavigationContainer } from "@react-navigation/native"
import { NavigationState } from "@react-navigation/routers"
import { AlertProvider, logger } from "capsule"
import React, { useCallback, useEffect, useRef } from "react"

import { BOTTOM_SIZE } from "./features/config/Constants"
import { ignoreWarnings } from "./features/config/LogBox"
// import { useDateMock } from "./features/hooks/useDateMock"
import { navigationRef } from "./features/Navigation/NavigationService"
import RootNavigator, { screens } from "./features/Navigation/RootNavigator"
import PromsProvider from "./features/Providers/PromsProvider"
import RootProvider from "./features/Providers/RootProvider"
import ScreenTracking from "./services/Analytics/analytics"
// import shake from "./services/Analytics/shake"
import ThemeApp from "./ThemeApp"
import LoadFonts from "./utils/loadFonts"

const linking: LinkingOptions<ParamListBase> = {
  prefixes: [
    "https://app.doctup.fr",
    "http://localhost:3000",
    "https://doctup-a2cca.web.app",
    "https://doctup-a2cca.firebaseapp.com",
    "https://doctup-pre-prod.web.app",
    "https://doctup-pre-prod.firebaseapp.com",
  ],
  config: { screens },
  getPathFromState: (state, options) => (state == null ? "/" : getPathFromState(state, options)),
}

ignoreWarnings()
// shake()

// Use the following when testing with a local instance of firebase and firestore
if (__DEV__) {
  /* functions().useFunctionsEmulator("http://localhost:5001")
  firestore().settings({
    host: "localhost:8080",
    ssl: false,
    persistence: false,
    cacheSizeBytes: firestore.CACHE_SIZE_UNLIMITED,
  })*/
}

const App = () => {
  const first = useRef(true)
  const routeNameRef = useRef<string | Partial<NavigationState> | undefined>()

  // useDateMock()

  const screenTracking = useCallback((navigationState: NavigationState | undefined) => {
    const getActiveRoute = (innerState: NavigationState | undefined) => {
      const route = innerState?.routes[innerState?.index || 0]
      if (route?.state) {
        return getActiveRoute(route.state as NavigationState)
      }
      return route
    }

    const activeRoute = getActiveRoute(navigationState)
    const currentRouteName = activeRoute?.name
    const routeParams = activeRoute?.params

    // Logique existante pour le suivi des écrans
    const previousRouteName = routeNameRef.current
    if (first.current || previousRouteName !== currentRouteName) {
      first.current = false
      ScreenTracking.logScreenTracking(currentRouteName)
    }
    // Suivi spécifique pour les articles de Faq
    if (currentRouteName === "Faq_Article" && routeParams?.id) {
      ScreenTracking.logScreenTracking(`${currentRouteName}_${routeParams.id}`)
    }

    // Sauvegarder le nom de la route actuelle pour une comparaison ultérieure
    routeNameRef.current = currentRouteName
  }, [])

  useEffect(() => {
    // @ts-ignore
    const state = navigationRef?.current?.getRootState()
    // Save the initial route name
    routeNameRef.current = ScreenTracking.getActiveRouteName(state)
  }, [])

  logger("reload App")

  // @ts-ignore
  return (
    <NavigationContainer
      // @ts-ignore
      ref={navigationRef}
      onStateChange={screenTracking}
      linking={linking}
    >
      <RootProvider>
        <LoadFonts
          fonts={{
            Montserrat: [
              { file: "Montserrat-Regular", weight: 400 },
              { file: "Montserrat-Medium", weight: 600 },
              { file: "Montserrat-Bold", weight: 700 },
            ],
          }}
        />
        <ThemeApp>
          <AlertProvider hasBottomNav bottomHeight={BOTTOM_SIZE}>
            <PromsProvider>
              <RootNavigator />
            </PromsProvider>
          </AlertProvider>
        </ThemeApp>
      </RootProvider>
    </NavigationContainer>
  )
}

export default App
