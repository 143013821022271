import { H5, IUserContext, Subtitle1, userContext, useTabLand, useTheme } from "capsule"
import React, { FC, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"

import { HomeNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"

const HomeHeader: FC = () => {
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
      black: { highEmphasis: black },
    },
    dimensions: { spacing },
  } = useTheme()
  const isTabLand = useTabLand()
  const { t } = useTranslation(HomeNS)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const isPatient = userData?.role === "patient"
  const isSurgeon = userData?.role === "surgeon"
  const s = useMemo(
    () => ({
      header: [
        styles.container,
        {
          padding: spacing * 2,
          backgroundColor: isTabLand && Platform.OS === "web" ? white : primary,
        },
      ],
      text: [
        styles.text,
        {
          color: isTabLand && Platform.OS === "web" ? black : white,
        },
      ],
      subtitle: {
        marginTop: spacing / 2,
      },
    }),
    [black, isTabLand, primary, spacing, white],
  )

  return (
    <View style={s.header}>
      <H5 style={s.text}>
        {isSurgeon
          ? t("titleSurgeon", {
              interpolation: { escapeValue: false },
              lastName: userData?.lastName,
            })
          : t("title", {
              interpolation: { escapeValue: false },
              firstName: userData?.firstName ?? "",
              lastName: isPatient ? "" : userData?.lastName ?? "",
            })}
      </H5>
      {isPatient ? <Subtitle1 style={[s.text, s.subtitle]}>{t("subtitle")}</Subtitle1> : null}
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
})

export default HomeHeader
