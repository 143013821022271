import "firebase/firestore"

import { useRoute } from "@react-navigation/native"
import {
  EmptyView,
  firestore,
  IUserContext,
  Subtitle1,
  Subtitle2,
  userContext,
  useTheme,
  VectorIcon,
} from "capsule"
import dayjs from "dayjs"
import firebase from "firebase/app"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import {
  InteractionManager,
  ScrollView,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { ID } from "../../common/CommonUserData"
import { collections } from "../../common/types"
import { JourneyNS } from "../../features/i18n/constants"
import { useProtocolPhases } from "../../features/models/PhaseAndSessionFunctions"
import { getFirestoreDate } from "../../features/models/Types"
import { AppUserData, Patient } from "../../features/models/UserData"
import { programContext } from "../../features/Providers/ProgramProvider"
import { daySinceSurgery } from "../../utils/conversion"

type RouteParams = {
  id: ID
}

export interface ProgressItem {
  titlePhase: string
  descriptionPhase: string
}

type InteractionHandle = {
  cancel: () => void
}

const PatientProtocolTimeline = () => {
  const { t } = useTranslation(JourneyNS)
  const route = useRoute()
  const params = route.params as RouteParams
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { phases } = useContext(programContext)
  const [patient, loading] = useDocumentData<Patient>(
    params?.id
      ? ((firestore()
          .collection(collections.LOGIN)
          .doc(params?.id) as unknown) as firebase.firestore.DocumentReference<Patient>)
      : undefined,
    { idField: "id" },
  )
  const currentDate = dayjs().startOf("day")
  const isProgramEnded =
    patient?.programEnd && currentDate.isAfter(getFirestoreDate(patient.programEnd))
  const isPatient = userData?.role === "patient"
  const protocolPhases = useProtocolPhases(isPatient)
  const [expanded, setExpanded] = useState<Record<number, boolean>>({})
  const [heights, setHeights] = useState<number[]>([])
  const [textOverflow, setTextOverflow] = useState<Record<number, boolean>>({})
  const [measured, setMeasured] = useState<Record<number, boolean>>({})
  const initialScrollDoneRef = useRef(false)
  const scrollViewRef = useRef<ScrollView>(null)

  const {
    fontMaker,
    typography: { h6 },
    colors: {
      surface: { background },
      accent,
      primary,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          paddingVertical: spacing,
          paddingLeft: spacing / 2,
          paddingRight: spacing,
          backgroundColor: background,
        },
      ],
      stepContainer: [styles.stepContainer, { marginVertical: spacing * 1.5 }],
      iconAndLineContainer: [styles.iconAndLineContainer, { marginRight: spacing / 2 }],
      verticalLine: [styles.verticalLine, { backgroundColor: primary }],
      textContainer: [styles.textContainer, { padding: spacing, backgroundColor: accent }],
      title: [fontMaker({ weight: "Bold" }), { marginBottom: spacing / 4 }],
      description: { lineHeight: spacing * 1.3 },
      moreText: { color: primary, marginTop: spacing / 2 },
      indicator: { paddingTop: spacing },
      empty: [h6, { paddingHorizontal: spacing }],
      emptyContainer: { backgroundColor: background },
    }),
    [spacing, accent, primary, fontMaker, h6, background],
  )

  const patientDaySinceSurgery = daySinceSurgery(patient?.surgeryDate)

  const currentPatientPhaseIndex = useMemo(() => {
    if (!phases || patientDaySinceSurgery === undefined || patientDaySinceSurgery === null) {
      return -1
    }

    const blockPhases = phases
      .filter(phase => phase.showProtocoleTab && phase.blockDates !== undefined)
      .sort((a, b) => {
        if (a.blockDates && b.blockDates) {
          return a.blockDates.startDay - b.blockDates.startDay
        }
        return 0 // ou une autre valeur par défaut si nécessaire
      })

    let representativeIndex = -1
    let lastSignificantPhaseIndex = -1

    for (let i = 0; i < blockPhases.length; i++) {
      const phase = blockPhases[i]

      if (!phase.blockDates) {
        continue
      } // Sauter si blockDates est undefined

      // Ignorer les phases passées
      if (phase.blockDates.startDay > phase.blockDates.endDay) {
        continue
      }

      // Gérer les phases actives et phases désactivées ou inversées
      if (
        (phase.blockDates.startDay <= phase.blockDates.endDay &&
          patientDaySinceSurgery >= phase.blockDates.startDay &&
          patientDaySinceSurgery <= phase.blockDates.endDay) ||
        (phase.blockDates.startDay > phase.blockDates.endDay &&
          patientDaySinceSurgery <= phase.blockDates.endDay)
      ) {
        return i
      }
      representativeIndex = i
      if (
        i > 0 &&
        blockPhases[i - 1].blockDates &&
        blockPhases[i - 1].blockDates.endDay < patientDaySinceSurgery &&
        phase.blockDates &&
        patientDaySinceSurgery < phase.blockDates.startDay
      ) {
        return lastSignificantPhaseIndex
      }

      lastSignificantPhaseIndex = i
    }

    // Vérifier si une phase précédente contient showProtocoleTab à true
    const anyPreviousShowProtocoleTab = phases.some(
      phase =>
        phase.showProtocoleTab &&
        phase.blockDates &&
        blockPhases[0] &&
        blockPhases[0].blockDates &&
        phase.blockDates.startDay < blockPhases[0].blockDates.startDay,
    )
    if (!anyPreviousShowProtocoleTab) {
      return -1
    }

    return representativeIndex
  }, [phases, patientDaySinceSurgery])

  const toggleExpand = (index: number) => setExpanded(prev => ({ ...prev, [index]: !prev[index] }))

  const handleTextLayout = (index: number, event: any) => {
    const { lines } = event.nativeEvent
    if (lines.length > 4) {
      setTextOverflow(prev => ({ ...prev, [index]: true }))
    }
    setMeasured(prev => ({ ...prev, [index]: true }))
  }

  const handleLayout = (index: number, event: any) => {
    const { height } = event.nativeEvent.layout
    setHeights(prevHeights => {
      const newHeights = [...prevHeights]
      newHeights[index] = height
      return newHeights
    })
  }

  useEffect(() => {
    let interactionHandle: InteractionHandle | undefined
    if (patient && phases && currentPatientPhaseIndex !== -1 && !initialScrollDoneRef.current) {
      interactionHandle = InteractionManager.runAfterInteractions(() => {
        const yOffset = heights
          .slice(0, currentPatientPhaseIndex)
          .reduce((sum, height) => sum + height, 0)
        scrollViewRef.current?.scrollTo({
          y: yOffset - heights[currentPatientPhaseIndex] / 2,
          animated: true,
        })
        initialScrollDoneRef.current = true
      })
    }
    return () => {
      if (interactionHandle) {
        interactionHandle.cancel()
      }
    }
  }, [patient, phases, currentPatientPhaseIndex, heights])

  return (
    <>
      <StatusBar barStyle="dark-content" backgroundColor={primary} />
      {loading ? (
        <ActivityIndicator style={s.indicator} size="large" color={primary} />
      ) : patient ? (
        <ScrollView ref={scrollViewRef} style={s.container}>
          {protocolPhases.map((step, index) => (
            <View
              key={index}
              style={s.stepContainer}
              onLayout={event => handleLayout(index, event)}
            >
              <View style={s.iconAndLineContainer}>
                <View style={styles.iconContainer}>
                  <VectorIcon
                    category="MaterialCommunityIcons"
                    name={
                      index <= currentPatientPhaseIndex || isProgramEnded
                        ? "circle"
                        : "circle-outline"
                    }
                    size={36}
                    color={primary}
                  />
                  {index < currentPatientPhaseIndex || isProgramEnded ? (
                    <VectorIcon
                      category="MaterialCommunityIcons"
                      name="check"
                      size={20}
                      color={white}
                      style={styles.checkIcon}
                    />
                  ) : null}
                  {index === currentPatientPhaseIndex && !isProgramEnded ? (
                    <VectorIcon
                      category="MaterialCommunityIcons"
                      name="timer-sand"
                      size={20}
                      color={white}
                      style={styles.checkIcon}
                    />
                  ) : null}
                </View>
                {index !== protocolPhases.length - 1 ? (
                  <View
                    style={[s.verticalLine, { height: heights[index] ? heights[index] - 5 : 0 }]}
                  />
                ) : null}
              </View>
              <View style={s.textContainer}>
                <Subtitle1 style={s.title}>
                  {index < 1
                    ? t("protocolInfo.programStart")
                    : `${t("protocolInfo.step")} ${index + 1} ${
                        index === currentPatientPhaseIndex ? t("protocolInfo.inProgress") : ""
                      }`}
                </Subtitle1>
                {!measured[index] ? (
                  <Subtitle2
                    style={s.description}
                    onTextLayout={event => handleTextLayout(index, event)}
                  >
                    {step.descriptionPhase}
                  </Subtitle2>
                ) : (
                  <Subtitle2 style={s.description} numberOfLines={expanded[index] ? undefined : 4}>
                    {step.descriptionPhase}
                  </Subtitle2>
                )}
                {textOverflow[index] ? (
                  <TouchableOpacity
                    style={styles.moreContainer}
                    onPress={() => toggleExpand(index)}
                  >
                    <Subtitle2 style={s.moreText}>
                      {expanded[index] ? t("protocolInfo.seeLess") : t("protocolInfo.seeMore")}
                    </Subtitle2>
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>
          ))}
        </ScrollView>
      ) : (
        <EmptyView
          title={t("protocolInfo.noProtocols")}
          titleStyle={s.empty}
          viewStyle={s.emptyContainer}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  stepContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  iconAndLineContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
  iconContainer: {
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  checkIcon: {
    position: "absolute",
  },
  verticalLine: {
    width: 4,
    position: "absolute",
    top: 45,
    left: 15,
    zIndex: -1,
  },
  textContainer: {
    flex: 1,
    borderRadius: 5,
  },
  moreContainer: {
    alignItems: "flex-end",
  },
})

export default PatientProtocolTimeline
