import React, { FC } from "react"

import useTheme from "../../features/Theme/ThemeProvider"
import { IBodyProps, Text } from "./common"

export const H5: FC<IBodyProps> = props => {
  const theme = useTheme()

  // noinspection RequiredAttributes
  return <Text {...props} type={theme.typography.h5} theme={theme} />
}
