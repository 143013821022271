import { FormikRadioButton, generateShadow, useStyles, useTheme } from "capsule"
import React, { FC } from "react"
import { StyleProp, View, ViewStyle } from "react-native"

interface IProps {
  name: string
  split?: boolean
  viewStyle?: StyleProp<ViewStyle>
  left: { label: string; value: string }
  right: { label: string; value: string }
}

const OppositeRadioButton: FC<IProps> = ({ viewStyle, name, left, right, split }) => {
  const {
    colors: { white },
  } = useTheme()
  const s = useStyles(
    ({ dimensions: { spacing } }) => ({
      radio: {
        alignItems: "center",
        flex: 1,
        height: split ? 53 : 40,
        padding: spacing / 2,
      },
      radioLeft: {
        ...(split
          ? { margin: spacing / 4, ...generateShadow(4) }
          : {
              borderBottomStartRadius: 4,
              borderRadius: 0,
              borderTopStartRadius: 4,
              marginRight: 1,
            }),
        justifyContent: "center",
      },
      radioRight: {
        ...(split
          ? { margin: spacing / 4, ...generateShadow(4) }
          : { borderBottomEndRadius: 4, borderRadius: 0, borderTopEndRadius: 4 }),
        justifyContent: "center",
      },
      view: {
        flexDirection: "row",
      },
    }),
    [split],
  )

  return (
    <View style={[s.view, viewStyle]}>
      <FormikRadioButton
        {...{ name }}
        backgroundColor={split ? white.highEmphasis : undefined}
        textOnly={true}
        label={left.label}
        value={left.value}
        touchableStyle={[s.radio, s.radioLeft]}
      />
      <FormikRadioButton
        {...{ name }}
        backgroundColor={split ? white.highEmphasis : undefined}
        textOnly={true}
        label={right.label}
        value={right.value}
        touchableStyle={[s.radio, s.radioRight]}
      />
    </View>
  )
}

export default OppositeRadioButton
