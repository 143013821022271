import { useRoute } from "@react-navigation/native"
import { firestore, useTheme } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import React, { useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { ScrollView, StatusBar, StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { ID } from "../../common/CommonUserData"
import { collections } from "../../common/types"
import { PatientNS } from "../../features/i18n/constants"
import { EvalFormValues } from "../../features/models/Evaluation"
import { getDatesFrom } from "../../utils/conversion"
import { useItems } from "../EvaluationScreen/Items"
import PatientStatistics from "../PatientScreen/PatientStatistics"

type RouteParams = {
  id: ID
}

const PatientGraphScreen = () => {
  const { t } = useTranslation(PatientNS)
  const route = useRoute()
  const params = route.params as RouteParams
  const {
    dimensions: { spacing },
    colors: {
      primary,
      surface: { background },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      viewContainer: [
        styles.viewContainer,
        {
          backgroundColor: background,
        },
      ],
      chartsContainer: [
        styles.chartsContainer,
        {
          backgroundColor: background,
          margin: spacing,
        },
      ],
      indicator: { paddingTop: spacing },
    }),
    [spacing, background],
  )

  const { efforts, pains } = useItems()

  const currentDate = new Date()
  const sevenDaysAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7,
  )

  const [morning, loadingMorning] = useCollectionData<EvalFormValues>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(params.id)
      .collection(collections.EVAL_MORNING)
      .where("start", ">=", sevenDaysAgo)
      .orderBy("start", "asc") as unknown) as firebase.firestore.Query<EvalFormValues>,
  )
  const [evening, loadingEvening] = useCollectionData<EvalFormValues>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(params.id)
      .collection(collections.EVAL_EVENING)
      .where("start", ">=", sevenDaysAgo)
      .orderBy("start", "asc") as unknown) as firebase.firestore.Query<EvalFormValues>,
  )

  const painData = useMemo(() => {
    const weekArray = getDatesFrom(7)
    return _.map(weekArray, el => {
      let pain: string | undefined
      pain = evening?.find(eve => dayjs(el).isSame(eve.start?.toDate(), "day"))?.pain
      if (pain === undefined) {
        pain = morning?.find(mo => dayjs(el).isSame(mo.start?.toDate(), "day"))?.pain
      }
      return {
        day: el,
        statData: pain ? parseInt(pain) : 0,
      }
    })
  }, [evening, morning])

  const effortData = useMemo(() => {
    const weekArray = getDatesFrom(7)
    return _.map(weekArray, el => {
      const effort: string | undefined = evening?.find(eve =>
        dayjs(el).isSame(eve.start?.toDate(), "day"),
      )?.effort
      return {
        day: el,
        statData: effort ? parseInt(effort) : 0,
      }
    })
  }, [evening])

  return (
    <ScrollView style={s.viewContainer}>
      <StatusBar barStyle="dark-content" backgroundColor={primary} />
      {loadingEvening || loadingMorning ? (
        <ActivityIndicator style={s.indicator} size="large" color={primary} />
      ) : (
        <View style={s.chartsContainer}>
          <PatientStatistics
            chartTitle={t("painChartTitle")}
            icons={pains}
            datesRange={painData}
            {...{ loadingEvening, loadingMorning }}
          />
          <PatientStatistics
            chartTitle={t("effortChartTitle")}
            icons={efforts}
            datesRange={effortData}
            loadingEvening={loadingEvening}
          />
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
    overflow: "scroll",
  },
  chartsContainer: {
    justifyContent: "center",
    alignSelf: "center",
  },
})

export default PatientGraphScreen
