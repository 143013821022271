import { useFormikContext } from "formik"
import React, { FC } from "react"

import { Button } from "../Button"

interface IProps {
  title: string
}

export const SubmitButton: FC<IProps> = ({ title }) => {
  const { isSubmitting, handleSubmit } = useFormikContext()

  return (
    <Button onPress={handleSubmit} disabled={isSubmitting}>
      {title}
    </Button>
  )
}
