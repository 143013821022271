import _ from "lodash"
import React, { FC, Ref } from "react"
import { Image, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import { IChoice } from "../SingleToggleGroup"
import { Caption } from "../Texts"
import { ToggleRow } from "../ToggleRow"
import { Touchable } from "../Touchable"
import { isVectorIcon, VectorIcon } from "../VectorIcon"

interface IProps {
  ref: Ref<View>
  items: IChoice[]
  stateChoices: { [key: string]: boolean } | undefined
  onStatusChange: (value: string, status?: boolean) => void
  selectedColor?: string
  buttonStyle?: StyleProp<ViewStyle>
  rowStyle?: StyleProp<ViewStyle>
  captionStyle?: StyleProp<TextStyle>
  iconStyle?: StyleProp<ViewStyle>
}

const MultipleToggleGroup: FC<IProps> = ({
  items,
  rowStyle,
  iconStyle,
  buttonStyle,
  captionStyle,
  stateChoices,
  onStatusChange,
  selectedColor,
  ref,
}) => {
  const {
    dimensions: { spacing },
    colors: { overrides, primary, surface, white, secondary },
  } = useTheme()
  const onPress = (value: string) => () => {
    onStatusChange(value, stateChoices && stateChoices[value])
  }

  const commonStyle = [
    iconStyle,
    styles.toggleButton,
    {
      marginVertical: spacing / 4,
    },
  ]

  return (
    <ToggleRow ref={ref} direction="row" rowStyle={rowStyle}>
      {_.map(items, choice => {
        const elemStyle = [
          commonStyle,
          styles.shadowBox,
          {
            backgroundColor:
              stateChoices && stateChoices[choice.value]
                ? selectedColor || secondary
                : choice.disabled
                ? surface.disabled
                : white.highEmphasis,
          },
        ]

        return (
          <Touchable
            onPress={onPress(choice.value)}
            key={choice.value}
            style={buttonStyle}
            disabled={choice.disabled}
          >
            <View pointerEvents="none" style={elemStyle}>
              {isVectorIcon(choice.icon) ? (
                <VectorIcon {...choice.icon} color={overrides?.iconButton || primary} />
              ) : (
                <Image source={choice.icon} />
              )}
              <Caption emphasis="high" style={captionStyle}>
                {choice.label}
              </Caption>
            </View>
          </Touchable>
        )
      })}
    </ToggleRow>
  )
}

const styles = StyleSheet.create({
  toggleButton: {
    height: 72,
    width: 72,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  shadowBox: {
    shadowOffset: {
      width: 0,
      height: 2,
    },
    elevation: 2,
    shadowRadius: 3,
    shadowOpacity: 0.2,
  },
  buttonStyle: {
    margin: 0,
  },
})

export default MultipleToggleGroup
