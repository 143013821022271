import React, { FC, ReactNode, Ref } from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"

interface IProps {
  ref: Ref<View>
  direction?: "row" | "column" | "row-reverse" | "column-reverse"
  children: ReactNode
  rowStyle?: StyleProp<ViewStyle>
}

const ToggleRow: FC<IProps> = ({ children, direction, rowStyle, ref }) => {
  const style = [
    styles.toggleRow,
    {
      flexDirection: direction,
    },
    rowStyle,
  ]

  return <View {...{ ref, style, children }} />
}

const styles = StyleSheet.create({
  toggleRow: {
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
})

export default ToggleRow
