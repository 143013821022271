import React, { FC, PropsWithChildren, ReactNode } from "react"

interface IProps {
  condition: boolean
  wrapper: ({ children }: any) => ReactNode
}

const ConditionalWrapper: FC<PropsWithChildren<IProps>> = ({ children, condition, wrapper }) => (
  <>{condition ? wrapper(children) : children}</>
)

export default ConditionalWrapper
