import { TwoPaneScreen, userContext } from "capsule"
import _ from "lodash"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"

import SettingsList from "../../components/SettingsList"
import { SettingsNS } from "../../features/i18n/constants"

const KineDataScreen = () => {
  const { t } = useTranslation()
  const { userData } = useContext(userContext)

  const oncoItems = useMemo(
    () =>
      _.filter([
        {
          name: t(`${SettingsNS}:shareContact`),
          iconName: "lock",
          route: "ShareContactEdition",
        },
        userData?.address
          ? {
              name: t(`${SettingsNS}:address`),
              iconName: "flag",
              route: "AddressEdition",
            }
          : null,
        userData?.kineSpecialties
          ? {
              name: t(`${SettingsNS}:kineSpecialties`),
              iconName: "accessibility",
              route: "KineSpecialtiesEdition",
            }
          : null,
        userData?.rdvLink || userData.rdvLink === ""
          ? {
              name: t(`${SettingsNS}:rdv_link`),
              iconName: "link",
              route: "RdvUrl",
            }
          : null,
      ]),
    [t, userData?.address, userData?.kineSpecialties, userData?.rdvLink],
  )

  return (
    <TwoPaneScreen>
      <SettingsList listKey="oncoSettingList" listItems={oncoItems} />
    </TwoPaneScreen>
  )
}

export default KineDataScreen
