import firebase from "firebase"

import { JsonTimestamp } from "../../common/types"

export type Timestamp = firebase.firestore.Timestamp
export type FieldValue = firebase.firestore.FieldValue

export function getFirestoreTimestamp(timestamp: Timestamp | FieldValue | JsonTimestamp) {
  if ("toDate" in timestamp || "delete" in timestamp) {
    return timestamp
  }
  return new firebase.firestore.Timestamp(
    // eslint-disable-next-line no-underscore-dangle
    (timestamp as JsonTimestamp)._seconds,
    // eslint-disable-next-line no-underscore-dangle
    (timestamp as JsonTimestamp)._nanoseconds,
  )
}

export function getFirestoreDate(timestamp: Date | JsonTimestamp | Timestamp) {
  if ("getDate" in timestamp) {
    return timestamp
  }
  return (getFirestoreTimestamp(timestamp) as Timestamp)?.toDate()
}
