import { Button, Subtitle1 } from "capsule"
import { Formik } from "formik"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, TextInput, View } from "react-native"
import * as Yup from "yup"

import OppositeRadioButton from "../../../components/OppositeRadioButton"
import { INPUT_HEIGHT } from "../../../features/config/Constants"
import { PatientNS } from "../../../features/i18n/constants"
import { updatePatientData } from "../../../features/models/UserDataFunctions"
import useTheme from "../../features/Theme/ThemeProvider"
import { FormikTextInput } from "../FormBis"

export interface AdditionalFieldsForm {
  surgeryDuration: string
  surgeryAdditionalInfo: string
  bipolar: string
}

const additionalFieldsValidationSchema = () =>
  Yup.object({
    surgeryDuration: Yup.string(),
    surgeryAdditionalInfo: Yup.string(),
    bipolar: Yup.string(),
  })

export const AdditionalFieldsEditScreen = ({
  surgeryDuration,
  surgeryAdditionalInfo,
  bipolar,
  patientId,
  setEditable,
  isSmithNephew,
}) => {
  const defaultAdditionalFieldsValues: AdditionalFieldsForm = {
    surgeryDuration: surgeryDuration ?? "",
    surgeryAdditionalInfo: surgeryAdditionalInfo ?? "",
    bipolar: bipolar ?? "",
  }
  const {
    dimensions: { spacing },
    colors: {
      primary,
      white: { highEmphasis: white },
    },
  } = useTheme()
  const s = useMemo(
    () => ({
      row: [
        styles.row,
        {
          marginBottom: spacing,
        },
      ],
      label: {
        paddingBottom: spacing,
      },
      buttonContainer: [styles.buttonContainer, { padding: spacing }],
      container: [
        {
          marginTop: spacing,
          paddingHorizontal: spacing,
          backgroundColor: white,
        },
      ],
      view: { paddingRight: spacing / 2 },
    }),
    [spacing, white],
  )
  const { t } = useTranslation()

  const firstInputRef = useRef<TextInput>(null)

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus()
    }
  }, [])

  const onSubmit = useCallback(
    (val: AdditionalFieldsForm) => {
      updatePatientData(patientId, val)
    },
    [patientId],
  )

  return (
    <Formik
      initialValues={defaultAdditionalFieldsValues}
      validationSchema={additionalFieldsValidationSchema}
      onSubmit={values => {
        setEditable(false)
        onSubmit(values)
      }}
    >
      {({ handleSubmit, values, isValid }) => (
        <View style={s.container}>
          <FormikTextInput
            forwardRef={firstInputRef}
            style={s.row}
            viewStyle={s.view}
            name="surgeryDuration"
            label={t(`${PatientNS}:surgeryDuration`)}
            value={values.surgeryDuration}
          />
          <FormikTextInput
            style={s.row}
            viewStyle={s.view}
            name="surgeryAdditionalInfo"
            label={t(`${PatientNS}:surgeryAdditionalInfo`)}
            value={values.surgeryAdditionalInfo}
            multiline={true}
            customHeight={100}
          />
          {isSmithNephew ? (
            <View>
              <Subtitle1 style={s.label}>{t(`${PatientNS}:bipolar`)}</Subtitle1>
              <OppositeRadioButton
                name="bipolar"
                left={{ value: "yes", label: t(`${PatientNS}:label.yes`) }}
                right={{ value: "no", label: t(`${PatientNS}:label.no`) }}
              />
            </View>
          ) : null}

          <View style={s.buttonContainer}>
            <View>
              <Button
                onPress={handleSubmit}
                disabled={!isValid}
                mode="text"
                labelStyle={{ color: primary }}
              >
                {t("common:button.save")}
              </Button>
            </View>
          </View>
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    height: INPUT_HEIGHT,
  },
})
