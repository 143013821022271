import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  IRowItemProps,
  ISectionHeader,
  ParametersScreen,
  Subtitle1,
  useTheme,
  VectorIcon,
  VectorIconProps,
} from "capsule"
import _ from "lodash"
import React, { FC, Fragment, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, SectionListData, StyleSheet } from "react-native"
import FastImage from "react-native-fast-image"
import { TouchableOpacity } from "react-native-gesture-handler"

import { ID, SpecialtyName } from "../common/CommonUserData"
import { Exercise } from "../common/Phase"
import { ICON_SIZE_MEDIUM } from "../features/config/Constants"
import { itemField } from "../features/hooks/useItemI18n"
import { ExercisesListNS, HomeNS } from "../features/i18n/constants"
import { SessionDetails } from "../features/models/Session"
import { RootParamList } from "../features/Navigation/types"
import { useProgram } from "../features/Providers/ProgramProvider"
import { convertSecondsToIsoTime } from "../utils/conversion"

interface IProps {
  specialty?: SpecialtyName
  isScreen?: boolean
  exercises?: Exercise[]
  patientId?: ID
  equipmentsText?: string
  currentSession?: SessionDetails | Record<string, never>
}

const ExercicesList: FC<IProps> = ({
  exercises,
  patientId,
  specialty,
  currentSession,
  equipmentsText,
  isScreen = false,
}) => {
  const navigation = useNavigation<StackNavigationProp<RootParamList, "ExerciseNavigator">>()

  const { setPrefTabVideo } = useProgram()
  const {
    dimensions: { spacing },
    typography: { h6, caption },
    colors: {
      primary,
      surface: { background },
      black: { highEmphasis: black },
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { t } = useTranslation()
  const s = useMemo(
    () => ({
      caption: { backgroundColor: background },
      equipments: { marginBottom: spacing },
      icon: [{ top: spacing / 2 }, styles.icon],
      playBlue: { color: primary },
      sectionTitle: [h6, { color: black, padding: 0 }],
      sectionHeader: {
        paddingVertical: 0,
        backgroundColor: background,
        paddingBottom: spacing,
        paddingTop: spacing,
      },
      row: [
        styles.row,
        {
          paddingVertical: spacing,
        },
      ],
    }),
    [black, background, h6, spacing, primary],
  )

  const icon = useMemo<VectorIconProps>(
    () => ({
      name: "play-circle-fill",
      category: "MaterialIcons",
      size: ICON_SIZE_MEDIUM,
      color: white,
    }),
    [white],
  )

  const onDisplayExercisesVideo = useCallback(
    ex => {
      navigation.navigate("ExerciseNavigator", {
        screen: "Exercise",
        params: { selectedExercise: ex, patientId },
      })
    },
    [navigation, patientId],
  )

  const data = useMemo<IRowItemProps[]>(
    () =>
      _.map(exercises, ex => ({
        rowStyle: s.row,
        name: itemField(ex, "title", t),
        leftChild: isScreen ? (
          <FastImage
            source={{
              uri: ex.image,
              ...(Platform.OS !== "web" && {
                priority: FastImage.priority.high,
                cache: FastImage.cacheControl.immutable,
              }),
            }}
            style={styles.image}
            {...(Platform.OS !== "web" && { resizeMode: FastImage.resizeMode.cover })}
          />
        ) : (
          <>
            <TouchableOpacity
              onPress={() => {
                setPrefTabVideo(false)
                onDisplayExercisesVideo(ex)
              }}
            >
              <FastImage source={{ uri: ex.image }} style={styles.image} />
              <VectorIcon {...icon} style={ex.image ? s.icon : [s.icon, s.playBlue]} />
            </TouchableOpacity>
          </>
        ),
        rightChild:
          specialty === "onco" ? undefined : (
            <Subtitle1 emphasis="high">{convertSecondsToIsoTime(ex.duration)}</Subtitle1>
          ),
      })),
    [
      exercises,
      s.row,
      t,
      isScreen,
      icon,
      specialty,
      onDisplayExercisesVideo,
      s.icon,
      s.playBlue,
      setPrefTabVideo,
    ],
  )

  const titleProps = useMemo(
    () =>
      isScreen
        ? {
            shadows: 1,
            headerStyle: s.sectionHeader,
            title: t(`${ExercisesListNS}:sectionTitle`),
            headerTextStyle: s.sectionTitle,
          }
        : undefined,
    [isScreen, s.sectionHeader, s.sectionTitle, t],
  )

  const sections: Array<SectionListData<IRowItemProps, ISectionHeader>> = useMemo(
    () => [
      {
        data: currentSession
          ? [
              {
                textStyle: caption,
                name: t(`${HomeNS}:description`, {
                  ns: HomeNS,
                  count: currentSession?.exercises,
                  time: currentSession?.duration,
                  equipment: "",
                }),
                rowStyle: s.caption,
              },
              ...(equipmentsText
                ? [
                    {
                      textStyle: caption,
                      rowStyle: [s.caption, s.equipments],
                      name: equipmentsText,
                    },
                  ]
                : []),
            ]
          : [],
        title: "",
      },
      {
        title: "",
        data,
        ...titleProps,
      },
    ],
    [caption, currentSession, data, equipmentsText, s.caption, s.equipments, t, titleProps],
  )
  const containerProps = useMemo(
    () => ({
      leftIcon: "list",
      title: t(`${ExercisesListNS}:sectionTitle`),
    }),
    [t],
  )

  return _.isEmpty(data) ? null : (
    <Fragment {...containerProps}>
      <ParametersScreen
        listKey="exercisesList"
        color={black}
        showSeparator
        sections={sections}
        paddingStyle={s.caption}
        optionalFooterStyle={styles.footer}
      />
    </Fragment>
  )
}

const styles = StyleSheet.create({
  row: {
    height: "100%",
  },
  footer: {
    marginTop: 0,
  },
  icon: {
    alignSelf: "center",
    position: "absolute",
  },
  image: {
    width: 50,
    height: 50,
  },
})

export default ExercicesList
