import { ID } from "./CommonUserData"
import { BaseData } from "./Pathology"
import { QuestionType } from "./Proms"

export const NO_PARTNER = "PAR001"

export interface Instruction extends BaseData<"title"> {
  id: ID
  partner?: ID
  specialty?: ID
  organ?: ID
  pathology?: ID
}

export interface QCUParameters extends BaseData<"title"> {
  id: ID
  qcuId: ID
}

export interface QCU extends Instruction {
  type: QuestionType
  options: QCUParameters[]
}
