import { VectorIconNames } from "capsule"
import _ from "lodash"
import React, { FC } from "react"

import { IProps } from "./loadFonts"

const LoadFonts: FC<IProps> = ({ fonts }) => (
  <>
    <style type="text/css">
      {_.map(VectorIconNames, name =>
        _.isNumber(name) || name === "FontAwesome5"
          ? ""
          : `
        @font-face {
          font-family: '${name}';
          src: url(${
            require(`react-native-vector-icons/Fonts/${name}.ttf`).default
          }) format('truetype');
        }`,
      )}
      {_(fonts)
        .map((weights, name) =>
          _.map(
            weights,
            ({ file, weight }) => `
      @font-face {
        font-family: '${name}';
        font-weight: ${weight};
        src: url(${require(`../assets/fonts/${file}.ttf`).default}) format('truetype');
      }`,
          ),
        )
        .flatten()
        .value()}
    </style>
    <link href={require("react-aspect-ratio/aspect-ratio.css")} rel="stylesheet" />
  </>
)

export default LoadFonts
