import React, { FC } from "react"

import useGalleryPicker from "./useGalleryPicker"

interface IRenderFunctionProps {
  error?: string
  source?: any
  openCamera: (options?: any) => void
  openGallery: (options?: any) => void
}

export interface GalleryPickerProps {
  children: (props: IRenderFunctionProps) => React.ReactElement<any>
}

const GalleryPicker: FC<GalleryPickerProps> = ({ children }) => {
  const galleryPicker = useGalleryPicker()

  return children(galleryPicker)
}

export default GalleryPicker
