import { useNavigation } from "@react-navigation/native"
import {
  ButtonType,
  DialogResponse,
  IUserContext,
  useAlert,
  useAppState,
  userContext,
  useStyles,
} from "capsule"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import { HomeNS } from "../i18n/constants"
import { createSessions, deleteSessionDocument } from "../models/PhaseAndSessionFunctions"
import { AppUserData } from "../models/UserData"
import { updateUserData, useHomeForm } from "../models/UserDataFunctions"
import { programContext } from "../Providers/ProgramProvider"
import { promsContext } from "../Providers/PromsProvider"
import { roleContext } from "../Providers/RoleProvider"
import useSpecialtySystem from "./useSpecialtySystem"

const useHomeActions = () => {
  const { t } = useTranslation(HomeNS)
  const navigation = useNavigation<any>()
  const { userData, userDocRef, user, logout } = useContext<IUserContext<AppUserData>>(userContext)
  // Create session when app is opened (once) => applied Alert last modification
  const firstSessionCalculRef = useRef(false)
  const checkPhaseOnce = useRef(false)
  const resumedOnce = useRef(false)
  const { showDialog, setIsValid } = useAlert()
  const { isSaved, setIsSaved, researchAccepted, role } = useContext(roleContext)
  const { currentPhase, resumedSession, setCurrentSession } = useContext(programContext)
  const { loadForm, isFromLink, showHomeProms } = useContext(promsContext)
  const [initialLoading, setInitialLoading] = useState(false)

  // AppState for changePhase
  const { appState } = useAppState({
    onChange: async nextAppState => {
      if (appState.match(/inactive|background/) && nextAppState === "active") {
        // change current phase
        if (currentPhase && userData) {
          await changeCurrentPhase()
        }
      }
    },
  })

  const initialHomeForm = useHomeForm()
  const { changeCurrentPhase, initDailyPhase } = useSpecialtySystem()

  const s = useStyles(
    ({ dimensions: { spacing } }) => ({
      alert: {
        padding: spacing,
      },
      contentContainer: {
        width: "100%",
      },
      dialogTitle: {
        padding: spacing,
      },
    }),
    [],
  )

  /** Call one time when App is open. Init reeducation values */
  useEffect(() => {
    ;(async () => {
      if (firstSessionCalculRef.current || userData?.role !== "patient" || !currentPhase) {
        return
      }
      try {
        firstSessionCalculRef.current = true
        if (userData && userData.phase) {
          setInitialLoading(true)
          const dailySessions = await createSessions(userData, user)
          if (dailySessions) {
            await initDailyPhase({
              program: userData.program,
              phase: userData.phase as string,
              exercises: dailySessions.exercises,
              sessionDetails: dailySessions.details,
            })
          }
          setInitialLoading(false)
        }
      } catch (e) {
        setInitialLoading(false)
      }
    })()
  }, [currentPhase, firstSessionCalculRef, initDailyPhase, user, userData, userDocRef])

  // Event form to show later when opening app
  useEffect(() => {
    if (initialHomeForm && !showHomeProms && !isFromLink) {
      loadForm?.({
        id: initialHomeForm.id,
        docId: initialHomeForm.docId,
        isTodo: true,
        triggerDay: initialHomeForm.triggerDay,
        isShowAgain: true,
      }).then(formPresent => {
        if (formPresent) {
          navigation.navigate("PromsNavigator")
        }
      })
    }
  }, [navigation, loadForm, isFromLink, initialHomeForm, showHomeProms])

  useEffect(() => {
    ;(async () => {
      if (!checkPhaseOnce.current && currentPhase) {
        // noinspection JSIgnoredPromiseFromCall
        await changeCurrentPhase()
        checkPhaseOnce.current = true
      }
    })()
  }, [changeCurrentPhase, currentPhase, userData])

  useEffect(() => {
    if (!isSaved) {
      updateUserData(userDocRef, { researchAccepted })
      setIsSaved?.(true)
    }
  }, [isSaved, researchAccepted, role, setIsSaved, userData, userDocRef])

  // Web wrong user profile
  useEffect(() => {
    setIsValid?.(true)
    if (Platform.OS === "web" && userData?.role === "patient") {
      showDialog({
        type: "button",
        dismissable: false,
        titleStyle: s.dialogTitle,
        title: t("dialog.message"),
        positive: {
          mode: "text",
          onPress: () => logout(),
          type: ButtonType.POSITIVE,
          label: t("dialog.label"),
          labelType: "confirm",
        },
      })
    }
  }, [logout, showDialog, t, userData, setIsValid, s.dialogTitle])

  useEffect(() => {
    if (resumedSession && !resumedOnce.current) {
      ;(async () => {
        setIsValid?.(true)
        const result: void | DialogResponse = await showDialog({
          type: "button",
          contentContainerStyle: s.contentContainer,
          style: s.alert,
          message: t("alert.message"),
          title: t("alert.title"),
          positive: {
            type: ButtonType.POSITIVE,
            label: t("alert.yes"),
          },
          negative: {
            type: ButtonType.NEGATIVE,
            label: t("common:button.noThanks"),
          },
        })
        if ((result as DialogResponse).button === ButtonType.POSITIVE) {
          setCurrentSession(resumedSession)
          navigation.navigate("ExerciseNavigator", { screen: "Exercise" })
        }
        if ((result as DialogResponse).button === ButtonType.NEGATIVE) {
          deleteSessionDocument(userDocRef, resumedSession?.docId)
          firstSessionCalculRef.current = false
        }
        resumedOnce.current = true
      })()
    }
  }, [
    navigation,
    resumedSession,
    s.alert,
    s.contentContainer,
    setCurrentSession,
    setIsValid,
    showDialog,
    t,
    userDocRef,
  ])

  return { initialLoading }
}

export default useHomeActions
