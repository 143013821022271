import {
  firestore,
  IRowItemProps,
  ISectionHeader,
  ParametersScreen,
  RowItem,
  SectionSeparator,
  Subtitle1,
  useTheme,
} from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { ScrollView, SectionListData, StyleSheet } from "react-native"

import { Score } from "../../common/Proms"
import { collections } from "../../common/types"
import AccordionSection from "../../components/AccordionSection"
import {
  ICON_SIZE,
  INPUT_HEIGHT,
  PATIENT_SURVEY_FORM,
  RATING_SCORE,
} from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { PatientNS } from "../../features/i18n/constants"
import { PatientPromsResult } from "../../features/models/Proms"
import { PatientProps } from "./PatientScreen"

// eslint-disable-next-line no-shadow
enum Section {
  PREOPERATIVE,
  SIX_POSTOPERATIVE,
  TWELVE_POSTOPERATIVE,
  ONE_YEAR_POSTOPERATIVE,
}

type SectionPromsResult = PatientPromsResult & { section: Section }

const PatientPromsView: FC<PatientProps> = ({ patient }) => {
  const {
    dimensions: { spacing },
    colors: {
      surface: { background },
      white: { highEmphasis: white },
      black: { highEmphasis: black },
    },
  } = useTheme()
  const { t } = useTranslation(PatientNS)
  const [patientProms] = useCollectionData<PatientPromsResult>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient.id)
      .collection(collections.PROMS)
      .orderBy("timestamp", "asc") as unknown) as firebase.firestore.Query<PatientPromsResult>,
  )
  const [scores] = useCollectionData<Score>(
    (firestore()
      .collection(collections.SCORES)
      .where("disabled", "==", false) as unknown) as firebase.firestore.Query<Score>,
  )

  const s = useMemo(
    () => ({
      sectionHeader: [
        styles.sectionHeader,
        {
          paddingTop: spacing,
          backgroundColor: background,
          paddingBottom: spacing,
        },
      ],
      sectionHeaderText: { paddingLeft: spacing },
      parameters: {
        backgroundColor: white,
      },
    }),
    [spacing, white, background],
  )

  // @ts-ignore
  const promsWithSection: Record<Section, SectionPromsResult[]> = useMemo(
    () =>
      _(patientProms)
        .filter(proms => proms.id !== PATIENT_SURVEY_FORM)
        .map(proms => {
          // pre op
          if (dayjs(proms.timestamp.toDate()).isBefore(patient.surgeryDate)) {
            return { ...proms, section: Section.PREOPERATIVE } as SectionPromsResult
          } else if (
            // 6 month post
            dayjs(proms.timestamp.toDate()).isBefore(dayjs(patient.surgeryDate).add(6, "month"))
          ) {
            return { ...proms, section: Section.SIX_POSTOPERATIVE } as SectionPromsResult
          } else if (
            // 12 month post
            dayjs(proms.timestamp.toDate()).isBefore(dayjs(patient.surgeryDate).add(12, "month"))
          ) {
            return { ...proms, section: Section.TWELVE_POSTOPERATIVE } as SectionPromsResult
          } else {
            return { ...proms, section: Section.ONE_YEAR_POSTOPERATIVE } as SectionPromsResult
          }
        })
        .groupBy("section")
        .value(),
    [patient.surgeryDate, patientProms],
  )
  const ratingScore = useMemo(() => {
    if (patientProms) {
      const allRating = _(patientProms)
        .filter(prom => _.has(prom, `scores.${RATING_SCORE}`))
        .orderBy("timestamp", "desc")
        .value()
      return allRating?.[0]?.scores?.[RATING_SCORE]
    }
    return undefined
  }, [patientProms])

  const renderRightChild = useMemo(
    () => (value: number | string) => {
      const title = typeof value == "number" ? Math.floor(value) : `${parseInt(value)} %`
      return <Subtitle1 color={black}>{title}</Subtitle1>
    },
    [black],
  )
  /** Retrieve the score to display of each proms otherwise return undefined  */
  const getSelectedScores = useCallback(
    (proms?: PatientPromsResult[]) => {
      if (_.isEmpty(proms) || _.isEmpty(scores)) {
        return []
      }
      return _(scores)
        .flatMap(sc => {
          if (sc.name === "rating") {
            return []
          }
          const forms = _.filter(proms, form => _.has(form, `scores.${sc.name}`))
          return forms.map(form => {
            const triggerDay = form.triggerDay
              ? form.triggerDay > 30
                ? `+${(form.triggerDay / 30).toFixed(0)} mois`
                : `J${form.triggerDay}`
              : ""
            return {
              name: itemField(sc, "title", t)
                ? `${itemField(sc, "title", t)} ${triggerDay}`
                : `${sc.name} ${triggerDay}`,
              rowStyle: styles.row,
              rightChild: renderRightChild(form.scores[sc.name]),
            }
          })
        })
        .compact()
        .value()
    },
    [renderRightChild, scores, t],
  )

  const titleProps = useMemo<(title: string, visible: boolean) => ISectionHeader>(
    () => (title: string, visible: boolean) => ({
      headerStyle: s.sectionHeader,
      headerIcon: {
        name: "calendar-today",
        category: "MaterialIcons",
        color: black,
        size: ICON_SIZE,
      },
      headerTextStyle: s.sectionHeaderText,
      title: visible ? t(title) : "",
    }),
    [black, s.sectionHeader, s.sectionHeaderText, t],
  )

  const sections: Array<SectionListData<IRowItemProps, ISectionHeader>> = useMemo(
    () =>
      _.map(promsWithSection, (p, index) => ({
        data: getSelectedScores(p),
        ...titleProps(Section[index], !_.isEmpty(getSelectedScores(p))),
      })),
    [getSelectedScores, promsWithSection, titleProps],
  )

  return (
    <>
      {!_.isEmpty(promsWithSection) ? (
        <AccordionSection
          leftIcon="star"
          title={t("proms")}
          showBottomSeparator={false}
          expandList={true}
        >
          <ScrollView>
            {ratingScore ? (
              <RowItem
                name={t("rating")}
                rowStyle={styles.row}
                rightChild={<Subtitle1>{t("score", { value: ratingScore })}</Subtitle1>}
              />
            ) : null}
            <SectionSeparator />
            <ParametersScreen
              listKey="promsList"
              color={black}
              showSeparator
              {...{ sections }}
              showHeaderSeparator
              paddingStyle={s.parameters}
              optionalFooterStyle={styles.footer}
            />
          </ScrollView>
        </AccordionSection>
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  sectionHeader: {
    paddingVertical: 0,
    alignSelf: "flex-start",
    flexDirection: "row-reverse",
  },
  footer: { marginTop: 0 },
  row: {
    height: INPUT_HEIGHT,
  },
})

export default PatientPromsView
