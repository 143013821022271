import { Caption, Subtitle1, useTheme } from "capsule"
import React, { FC, PropsWithChildren, useMemo } from "react"
import { StyleSheet, View } from "react-native"
interface IProps {
  label: string
  value: string
}

const EvaluationItem: FC<PropsWithChildren<IProps>> = ({ children, label, value }) => {
  const {
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      item: [
        styles.item,
        {
          marginHorizontal: spacing,
        },
      ],
      image: {
        marginVertical: spacing / 2,
      },
    }),
    [spacing],
  )

  return (
    <View style={s.item}>
      <Subtitle1 emphasis="high">{label}</Subtitle1>
      <View style={s.image}>{children}</View>
      <Caption color="primary">{value}</Caption>
    </View>
  )
}

const styles = StyleSheet.create({
  item: {
    alignItems: "center",
  },
})

export default EvaluationItem
