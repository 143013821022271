import { Caption, FormikRadioButton, useTheme } from "capsule"
import React, { FC, ReactNode, useCallback, useMemo } from "react"
import { FlatList, ListRenderItem, StyleSheet } from "react-native"

export interface RadioItem {
  label: string
  value: number
  checked: ReactNode
  unchecked: ReactNode
}

interface IProps {
  name: string
  data: RadioItem[]
}

const keyExtractor = (item: RadioItem, index: number) => `${index}-${item.value}`

const Rating: FC<IProps> = ({ data, name }) => {
  const {
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      radio: [
        styles.radio,
        {
          paddingVertical: spacing / 4,
        },
      ],
      caption: {
        paddingTop: spacing / 4,
      },
    }),
    [spacing],
  )

  const renderLabel = useCallback(
    (label: string) => isChecked => (
      <Caption emphasis="high" style={s.caption} color={isChecked ? "primary" : undefined}>
        {label}
      </Caption>
    ),
    [s.caption],
  )

  const renderItem: ListRenderItem<RadioItem> = useCallback(
    ({ item: { label, value, checked, unchecked } }) => (
      <FormikRadioButton
        {...{ name }}
        borderless
        value={`${value}`}
        touchableStyle={s.radio}
        // @ts-ignore
        icons={{ checked, unchecked }}
        renderLabel={renderLabel(label)}
      />
    ),
    [name, renderLabel, s.radio],
  )

  return (
    <FlatList
      horizontal
      style={styles.view}
      scrollEnabled={false}
      {...{ data, renderItem, keyExtractor }}
      contentContainerStyle={styles.content}
    />
  )
}

const styles = StyleSheet.create({
  content: {
    width: "100%",
    justifyContent: "space-between",
  },
  view: {
    width: "100%",
  },
  radio: {
    maxWidth: 75,
    height: "100%",
    alignItems: "center",
    flexDirection: "column-reverse",
  },
})

export default Rating
