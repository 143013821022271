import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, StyleSheet, TextStyle, View } from "react-native"
import { Dialog as PaperDialog } from "react-native-paper"

/** Do not shorten to avoid `cycles warning` */
import { AlertButton, ButtonType } from "../../features/Providers/AlertInterfaces"
import useTheme from "../../features/Theme/ThemeProvider"
import { Button } from "../Button"
import { VectorIcon } from "../VectorIcon"

interface IProps {
  isValid?: boolean
  positive: AlertButton
  neutral?: AlertButton
  negative?: AlertButton
}

const AlertDialogButtons: FC<IProps> = ({ positive, neutral, negative, isValid }) => {
  const {
    fontMaker,
    dimensions: { spacing },
    colors: {
      primary,
      danger,
      white: { highEmphasis: white },
      black: { highEmphasis: black, disabled },
    },
    button,
    typography,
  } = useTheme()

  const s = useMemo(
    () => ({
      button: {
        margin: spacing / 2,
      },
      textStyle: [
        typography.button,
        {
          color: black,
        },
      ],
    }),
    [black, spacing, typography.button],
  )

  const getStyle = useCallback(
    (labelType?: AlertButton["labelType"], style?: StyleProp<TextStyle>) => [
      style,
      labelType === "cancel" || labelType === "confirm"
        ? {
            color: primary,
            ...fontMaker({ weight: "Bold" }),
          }
        : undefined,
      labelType === "destructive"
        ? {
            color: danger,
          }
        : undefined,
    ],
    [danger, fontMaker, primary],
  )
  const { t } = useTranslation()
  const hasThreeButtons = positive && neutral && negative
  const renderButton = useCallback(
    ({
      onPress,
      icon,
      labelType,
      textStyle,
      mode = button?.alertMode ?? "text",
      buttonStyle,
      iconStyle,
      type,
      label = t(`common:button.${type}`),
      loading,
    }: AlertButton) => {
      const isDestructive = labelType === "destructive"
      const isConfirm = labelType === "confirm"
      const isPositive = type === ButtonType.POSITIVE
      const labelStyle =
        isPositive && !isValid
          ? { color: disabled }
          : getStyle(labelType, [s.textStyle, { paddingLeft: icon ? spacing / 2 : 0 }, textStyle])
      const style = [s.button, buttonStyle, isDestructive ? { backgroundColor: white } : undefined]

      return (
        <Button
          icon={
            icon
              ? () => (
                  <View style={iconStyle}>
                    <VectorIcon
                      {...icon}
                      color={isPositive ? (isValid ? icon.color : disabled) : icon.color}
                    />
                  </View>
                )
              : undefined
          }
          {...{ onPress, labelStyle, style }}
          disabled={isPositive ? !isValid : false}
          mode={isDestructive || isConfirm ? "outlined" : mode}
          loading={loading && isPositive ? !isValid : false}
        >
          {label}
        </Button>
      )
    },
    [button?.alertMode, t, isValid, disabled, getStyle, s.textStyle, s.button, spacing, white],
  )

  return (
    <PaperDialog.Actions style={hasThreeButtons ? styles.actionsContainer : null}>
      {neutral ? renderButton(neutral) : null}
      {negative ? renderButton(negative) : null}
      {renderButton(positive)}
    </PaperDialog.Actions>
  )
}

const styles = StyleSheet.create({
  actionsContainer: {
    flexDirection: "row",
  },
})

export default AlertDialogButtons
