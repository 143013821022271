import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import _ from "lodash"
import * as Yup from "yup"

import { SpecialtyName } from "../../common/CommonUserData"
import currentDateMs from "../../features/hooks/useDateMock"
import { AppUserData, UserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { FormikParams } from "../../features/Navigation/FormikScreen"
import { ISO8601_TIME } from "../../utils/conversion"

export interface AlertFormValues {
  endTime: Date | undefined
  startTime: Date
  alertDays: Record<string, boolean>
}

export const defaultAlertFormValues: AlertFormValues = {
  endTime: dayjs(currentDateMs()).set("h", 18).set("m", 0).toDate(),
  startTime: dayjs(currentDateMs()).set("h", 9).set("m", 0).toDate(),
  alertDays: {
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
  },
}

export const alertValidationSchema = (t: TFunction, ns: string, isOrtho: boolean) =>
  isOrtho
    ? Yup.object({
        endTime: Yup.date().min(Yup.ref("startTime"), t("errors.min", { ns })),
        startTime: Yup.date().max(Yup.ref("endTime"), t("errors.max", { ns })),
      })
    : Yup.object({
        startTime: Yup.date(),
      })

export const onSubmitAlert = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
) => async (values: AlertFormValues): Promise<any> => {
  const updatedValues: Partial<UserData> = {
    ..._.omit(values, ["startTime", "endTime"]),
    alertEndTime: values.endTime ? dayjs(values.endTime).format(ISO8601_TIME) : "",
    alertStartTime: dayjs(values.startTime).format(ISO8601_TIME),
  }
  await updateUserData(userDocRef, updatedValues)
}

export const alertFormikParams = (
  t: TFunction,
  ns: string,
  specialty?: SpecialtyName,
  userData?: Partial<AppUserData>,
) =>
  ({
    onSubmit: onSubmitAlert,
    initialValues: userData?.alertDays
      ? {
          alertDays: userData?.alertDays,
          endTime: userData?.alertEndTime,
          startTime: userData?.alertStartTime,
        }
      : defaultAlertFormValues,
    validationSchema: alertValidationSchema(t, ns, specialty === "ortho"),
  } as FormikParams<AlertFormValues>)
