import {
  ActivationCodeNS,
  AlertNS,
  CguNS,
  CongratsNS,
  DeleteAccountNS,
  EvaluationNS,
  ExerciseNS,
  ExercisesListNS,
  FaqNS,
  HomeNS,
  JourneyNS,
  LanguageNS,
  MainNS,
  NavigationNS,
  OperationNS,
  PatientNS,
  PatientsListNS,
  PractitionerHomeNS,
  PraticienNS,
  ProfileNS,
  PromsNS,
  ProtocolNS,
  SettingsNS,
  SMSValidationNS,
  VideoNS,
} from "./constants"

const onboarding = "Saisissez le code $t(common:appName) à 8 caractères."
const logoutPractitioner = "Vous n'êtes pas praticien ? "

export default {
  common: {
    activityCalendar: {
      title: "Suivi d'activité",
      programInfo:
        "💡 Votre programme de rééducation est composé de différentes sessions d'exercices adaptées à chacune des étapes pré et post-opératoires.\nIl va vous accompagner tout au long de votre chemin vers la récupération fonctionnelle.",
      oneActivityDay: "Bravo 🥳\nVous avez réalisé votre séance du jour !",
      activityDay: "Bravo 🥳\nVous avez réalisé toutes les séances du jour !",
      inactivityDay: "Pas d'activité renseignée",
      inactivityDayToday: "Vous n’avez pas encore réalisé de séance d'exercices aujourd'hui 💪",
      oneSessionLeft:
        "Bravo 🥳\nVous avez accompli {{sessionNumber}} séance(s) d'exercices aujourd'hui !",
      oneSessionsDone:
        "Vous avez réalisé {{sessionNumber}}/{{totalSessions}} séance aujourd'hui.\nContinuez comme ça 🔥",
      sessionsDone:
        "Vous avez réalisé {{sessionNumber}}/{{totalSessions}} séances aujourd'hui.\nVous êtes sur la bonne voie 🔥",
      noExercicesToday: "💡 Vous n'avez pas d'exercices prévus aujourd'hui.",
      programToday: "Votre activité aujourd'hui 🗓️ ",
      programStart: "Début de votre programme 💪",
      surgeryDay: "💡 Jour de votre opération",
      programEnd: "Fin de votre programme 🥇 🎉",
      currentPhase: "Session en cours ⏳",
      startSession: "Démarrer la séance",
      startNextSession: "Démarrer la prochaine séance",
    },
    alert: {
      dismiss: "Sortir de la modale",
      photo: {
        camera: "Prendre une photo",
        title: "Ajouter un compte-rendu",
        documents: "Sélectionner un fichier",
      },
    },
    appName: "DOCT'UP",
    accountScreen: {
      termsOfUse:
        "<p>En vous inscrivant vous acceptez les <a href='https://www.doctup.fr/gcv-cgu'>conditions générales d’utilisation</a> et la <a href='https://www.doctup.fr/mentions-l%C3%A9gales'>politique de vie privée</a>.</p>",
    },
    addMail:
      "Afin d’améliorer votre expérience avec Doct’UP et faciliter la distribution des codes patients, nous vous remercions de partager quelques informations comme votre adresse e-mail et, optionnellement, celle de votre secrétariat. De plus, nous aimerions mieux comprendre votre utilisation de Doct’UP au quotidien.",
    button: {
      /** common */
      stop: "Arrêter",
      cancel: "Annuler",
      continue: "Continuer",
      dateCheckbox: "Je ne connais pas la date",
      delete: "Supprimer le compte",
      ended: "Terminer",
      logout: "Se déconnecter",
      modify: "Modifier",
      operation_type_unknown: "Je ne connais pas le type de chirurgie",
      negative: "Annuler",
      no: "non",
      noThanks: "Non, merci",
      ok: "OK",
      positive: "OK",
      save: "Enregistrer",
      start: "Commencer",
      validate: "Valider",
      yes: "oui",
      pathology_list: "Modifier mes informations",
      surgery_list: "Ajouter une opération",
      skip: "Passer cette étape",

      /** Alert */
      neutral: "Modifier",
      stopSession: "Retour à la séance",
      accept: "J'accepte",
    },
    fields: {
      label: {
        lastName: "Nom",
        firstName: "Prénom",
        email: "Email",
        otherEmail: "Email secrétariat (optionnel)",
        street_number: "Numéro de voie",
        route: "Voie",
        additional_address: "Complément d'adresse (optionnel)",
        postal_code: "Code postal",
        locality: "Ville",
        country: "Pays",
        France: "France",
        Suisse: "Suisse",
      },
      inputInfo: {
        route: "Exemple : rue du Temple",
        additional_address: "Exemple : Batiment, code, étage ...",
      },
    },
    landing: {
      signUp: "Connexion patient",
      signIn: "Connexion praticien",
    },
    list: {
      empty: {
        title: "Liste vide",
        subtitle: "Aucun élément trouvé",
        comingSoon: "Bientôt disponible",
      },
      error: {
        title: "Erreur",
        subtitle: "Une erreur s'est produite lors du chargement des données.",
      },
    },
    parameters: {
      account: "Compte",
      help: "Aide",
      privacy: "Politique de vie privée",
      termsOfUse: "Conditions d'utilisation",
    },
    permission: {
      message: "Nous avons besoin de votre autorisation afin de pouvoir prendre des photos",
    },
    PhoneNumberScreen: {
      placeholder: "Nous avons besoin de votre autorisation afin de pouvoir prendre des photos",
      label: "Ajouter un numéro",
      caption: "Nous utilisons votre numéro de téléphone pour vous envoyer votre code sms.",
    },
    signUp: {
      termsOfUse:
        "<p>En continuant vous indiquez que vous acceptez nos <a href='https://www.doctup.fr/gcv-cgu'>conditions générales</a> et notre <a href='https://www.doctup.fr/mentions-l%C3%A9gales'>politique de vie privée</a>.</p>",
    },
    SMSInputScreen: {
      caption:
        "Pour valider votre compte, veuillez saisir le code que vous allez recevoir par SMS au\n<1>{{phone}}</1>",
      subtitle: "Vous n’avez rien reçu ? <1>Renvoyer le code</1>",
    },
    AddressEdition: "Adresse professionnelle",
    AddressInfo: "💡 Facilitez votre identification par les patients.",
    KineSpecialtiesEdition: "Spécialités",
    KineDataScreen: "Référencement Doct'UP",
    RdvUrl: "Lien de rendez-vous",
    ShareContactEdition: "Autorisation de partage",
    unknown: {
      name: "Nom inconnu",
    },
    upgrade: {
      title: "Mise à jour disponible  🎉",
      button: "Mettre à jour",
      error: "Erreur lors de la vérification de la version : ",
    },
    version: "Version {{version}}",
  },
  [ActivationCodeNS]: {
    title: "Connecter le compte",
    label: {
      surgeon: onboarding,
      kine: onboarding,
      patient:
        "<p>Un professionnel de santé vous a transmis un code d'activation à 8 caractères." +
        " \n" +
        "<b>Ce code permet de lier votre compte au sien.</b>" +
        " \n" +
        "Il ne vous sera demandé qu'une seule fois.</p>",
      gp: onboarding,
      addPatient: "Saisissez le code fourni par votre patient.",
      addPatientInfo:
        "💡 S’il s’agit d’un patient à qui vous avez fourni un code, inutile de l'ajouter :\nil s'affichera automatiquement dans votre liste dès qu'il aura créé son compte sur Doct'UP.",
      practitioner: onboarding,
    },
    logout: {
      kine: logoutPractitioner,
      gp: logoutPractitioner,
      logoutPractitioner,
      patient: "Vous n'êtes pas patient ?",
      surgeon: "Vous n'êtes pas chirurgien ?",
      button: "Choisissez un autre profil",
    },
    errors: {
      code_invalid:
        "Le code renseigné n'est pas valide, veuillez réessayer (attention aux majuscules et minuscules)",
      code_surgeon:
        "Ce code n’a pas été activé. Contactez votre chirurgien pour qu’il puisse l’activer.",
      code_kine:
        "Ce code n’a pas été activé. Contactez votre kinésithérapeute pour qu’il puisse l’activer.",
      code_gp: "Ce code n’a pas été activé. Contactez votre médecin pour qu’il puisse l’activer.",
      code_patient: "Votre patient n’a pas encore créé de compte Doct’up. Veuillez le contacter.",
      activate_pack:
        "Pack activé. Vos patients s’afficheront dans votre liste une fois leur compte créé.",
      code_already_activated: "Le code renseigné a déjà été activé.",
      patient_activation_code_surgeon:
        "Vous avez renseigné un code chirurgien. Contactez votre chirurgien pour obtenir un code patient valide.",
      patient_activation_code_kine:
        "Vous avez renseigné un code kinésithérapeute. Contactez votre kinésithérapeute pour obtenir un code patient valide.",
      patient_activation_code_gp:
        "Vous avez renseigné un code médecin. Contactez votre médecin pour obtenir un code patient valide.",
      surgeon_activation_code:
        "Code patient inactif. Veuillez saisir le code chirurgien fourni dans votre pack pour l'activer.",
      kine_activation_code:
        "Code patient inactif. Veuillez saisir le code kinésithérapeute fourni dans votre pack pour l'activer.",
      gp_activation_code:
        "Code patient inactif. Veuillez saisir le code médecin fourni dans votre pack pour l'activer.",
      input: "Votre code comporte des caractères non alphanumériques",
      code_pack: "Une erreur est survenue pendant la création des codes",
      unknown: "Une erreur est survenue lors de la vérification du code",
      code_blocked: "Nombre d’essais autorisés dépassé. Veuillez réessayer ultérieurement",
    },
  },
  [AlertNS]: {
    dialog: {
      title: "Activer les notifications ?",
      message: "$t(common:appName) souhaite vous envoyer des notifications Push.",
    },
    label: {
      start: "Heure de début",
      startTraining: "Heure d'entraînement",
      end: "Heure de fin",
      Monday: "Lu",
      Tuesday: "Ma",
      Wednesday: "Me",
      Thursday: "Je",
      Friday: "Ve",
      Saturday: "Sa",
      Sunday: "Di",
    },
    explanation:
      "Votre entraînement consiste en une séance par jour, tous les jours pendant 21 jours.\nÀ quelle heure souhaitez-vous vous entraîner ?",
    errors: {
      min: "L'heure de fin doit être supérieure à l'heure de début",
      max: "L'heure de début doit être inférieure à l'heure de fin",
    },
    helper:
      "Vous pouvez ajuster ces heures et notifications à tout moment pour mieux s'adapter à votre routine quotidienne.",
    subtitle: "Pour améliorer votre assiduité, choisissez quand recevoir vos alertes",
    subtitle2:
      "💡 Durant cette période, vous pourrez avoir plusieurs séances d'exercices basées sur le protocole établi. \n \n",
    channel: {
      phase: "Progression",
      session: "Séance d'exercices",
    },
    notification: {
      nextPhase: "Un nouveau programme a commencé",
      title: "Préparez-vous pour votre séance de rééducation",
      body: "L'assiduité est la clé du succès 👍",
    },
  },
  [CguNS]: {
    accept: "Accepter tout",
    refuse: "Refuser tout",
    more: "En savoir plus",
    title: {
      patient: "La sécurité de vos données est pour nous une priorité absolue",
      practitioner: "La sécurité des données de vos patients est pour nous une priorité absolue",
    },
    address1: "💡 Soyez au cœur du suivi patient sur Doct’UP !",
    address2: "\nRéférencez-vous et accédez directement au suivi de tous vos patients !",
    address3:
      "Optimisez votre visibilité et assurez un accès rapide à des soins de qualité pour les patients.\n\nTout simplement, renseignez votre adresse professionnelle, vos spécialités et votre lien pour une prise de RDV en ligne.",
    addressConfirm:
      "J’accepte que mes informations professionnelles (adresse, spécialité(s) et lien de RDV) soient partagées aux patients utilisant Doct’UP",
    disableTitle: "💡 Important",
    disableMessage:
      "L'enregistrement sur Doct’UP simplifie le suivi de vos patients et optimise votre visibilité.\n\nVous pouvez à tout moment modifier votre choix en vous rendant dans l’espace Compte, sous l'onglet Référencement Doct'UP, de l'application.",
    understand: "J'ai compris",
    researchLabel: "J’accepte que mes données soient utilisées dans le cadre de la recherche",
    research:
      "<p>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par <b>Google Cloud</b> pour le bon fonctionnement de l’application OU à des fins statistiques. La base légale du traitement est le consentement.\n</p>" +
      " \n" +
      "Les données collectées seront communiquées aux seuls destinataires suivants :\n" +
      "<b>l’équipe Doct’Up, votre praticien, les sous-traitants auxquels Doct’Up peut avoir recours pour toute opération technique portant sur vos données personnelles, notre hébergeur de données de santé Google Cloud.</b>\n" +
      " \n" +
      "Les données sont conservées pendant <b>24 mois</b>.\n" +
      " \n" +
      "Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. <b>Vous pouvez également exercer votre droit à la portabilité de vos données.\n" +
      "Vous pouvez à tout moment retirer votre consentement au traitement de vos données, ainsi que de vous y opposer</b>.\n" +
      " \n" +
      "Consultez le site cnil.fr pour plus d’informations sur vos droits.\n" +
      " \n" +
      "Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter : contact@doctup.fr\n" +
      " \n" +
      "Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.\n</p>",
    resume: {
      patient:
        "Dans le cadre de l’utilisation de l’Application, l’Utilisateur Professionnel de santé ou vous-même avez transmis à la Société certaines de vos données personnelles et notamment :\n" +
        "Les données que vous renseignez lors de l’accès à votre compte en remplissant le formulaire dédié :\n" +
        "- identifiant, code patient, date de l’opération, articulation opérée, type de greffe, lésion méniscale, âge, taille, poids, niveau sportif.\n" +
        " \n" +
        "Les données relatives à votre parcours d’auto-rééducation : il s’agit des données que vous publiez pour informer l’Utilisateur Professionnel de santé de votre avancée dans votre parcours d’auto-rééducation et votre ressenti par rapport à la rééducation.\n" +
        "En cliquant sur la case appropriée de recueil du consentement, vous donnez votre consentement exprès à la collecte et au traitement de ces données personnelles, par la Société qui agit en qualité de sous-traitant de l’Utilisateur Professionnel de Santé. Ce dernier est responsable de traitement au sens et dans les conditions prévues par la loi n°78-17 du 6 janvier 1978 modifiée et par le règlement UE 2016/179 à compter de son entrée en vigueur (ci-après ensemble « la Réglementation »).\n" +
        "Ces données personnelles sont collectées et traitées aux fins de fonctionnement de l’Application, de gestion du Service, de bonne exécution des présentes CGU et de statistiques.\n" +
        "Les données personnelles précitées seront conservées pendant la durée de la relation contractuelle et 24 mois à compter de la fin de cette relation, ou à compter de la demande de clôture de votre compte.\n" +
        "La Société s’interdit toute utilisation commerciale de vos données de santé à caractère personnel collectées dans le cadre de l’Application.\n" +
        "Votre attention a été spécifiquement attirée sur la sensibilité des données de santé que vous renseignez dans le cadre de l’Application, couvertes également par le secret professionnel médical.\n" +
        "Vos données à caractère personnel sont strictement destinées :\n" +
        "   • Aux membres de la Société spécifiquement habilités ;\n" +
        "   • À l’Utilisateur Professionnel de santé qui vous a invité à utiliser l’Application ; en aucun cas, la Société ne pourra être tenue responsable de la communication à des tiers de vos données par l’Utilisateur Professionnel de santé.\n" +
        "   • Aux sous-traitants auxquels la Société fait appel pour toute opération technique portant sur vos données dans le cadre de garanties suffisantes au titre de la mise en œuvre de mesures techniques et organisationnelles appropriées, en particulier en termes de confidentialité et de sécurité ;\n" +
        "   • Aux administrateurs strictement habilités de l’hébergeur agréé de données de santé, au sens de l’article L.1111-8 du Code de la santé publique.\n" +
        "La Société, ou l’Utilisateur Professionnel de santé, ne peuvent en aucun cas publier des données vous concernant sans votre consentement.\n" +
        "Conformément à la réglementation applicable, vous êtes informé que vous bénéficiez :\n" +
        "   • d’un droit d’accès aux informations qui vous concernent, ainsi que d’un droit à la portabilité de vos données, qui vous permet de récupérer une partie de ces données pour les transmettre à un autre responsable de traitement ;\n" +
        "   • d’un droit de modification ou rectification de vos données à caractère personnel ;\n" +
        "   • du droit de définir les directives relatives à la conservation, l’effacement et la communication de vos données à caractère personnel post-mortem ;\n" +
        "   • d’un droit de limitation, d’effacement et d’opposition au traitement des informations qui vous concernent.\n" +
        "Lorsque le traitement est basé sur votre consentement, vous avez la possibilité de retirer votre consentement au traitement de vos données personnelles à tout moment.\n" +
        "Pour exercer vos droits, vous pouvez adresser une demande en écrivant à l’adresse suivante : contact@doctup.fr\n" +
        "Vous avez la possibilité d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés.\n",
      practitioner:
        "Dans le cadre de l'utilisation de l'Application, vous ou votre patient avez transmis à la Société certaines de ses données personnelles et notamment :\n" +
        "Les données que votre patient renseigne lors de l’accès à son compte en remplissant le formulaire dédié :\n" +
        "- identifiant, code patient, date de l'opération, articulation opérée, type de greffe, lésion méniscale, âge, taille, poids, niveau sportif.\n" +
        "\n" +
        "Les données relatives au parcours d’auto-rééducation de votre patient : il s'agit des données que votre patient publiera pour vous informer de son avancée dans son parcours d’auto-rééducation et son ressenti par rapport à la rééducation. En cliquant sur la case appropriée de recueil du consentement, votre patient a donné son consentement exprès à la collecte et au traitement de ses données personnelles, par la Société qui agit en qualité de sous-traitant de vous, le praticien (ci-après « l'Utilisateur Professionnel de santé »). Ce dernier est responsable de traitement au sens et dans les conditions prévues par la loi n° 78-17 du 6 janvier 1978 modifier et par le règlement UE 2016/179 à compter de son entrée en vigueur (ci-après ensemble « la Réglementation »).\n" +
        "Ces données personnelles sont collectées et traitées aux fins de fonctionnement de l'Application, de gestion du service, de bonne exécution des présentes CGU et de statistiques.\n" +
        "Les données personnelles précitées seront conservées pendant la durée de la relation contractuelle et 24 mois à compter de la fin de cette relation, ou à compter de la demande de clôture de votre compte. La Société s'interdit toute utilisation commerciale de vos données de santé à caractère personnel collectées dans le cadre de l'Application.\n" +
        "Votre attention a été spécifiquement attirée sur la sensibilité des données de santé que votre patient renseigne dans le cadre de l'Application, couverte également votre secret professionnel médical.\n" +
        "Les données à caractère personnel de votre patient sont strictement destinées :\n" +
        "   • Aux membres de la Société spécifiquement habilités ;\n" +
        "   • À vous, l'Utilisateur Professionnel de santé, qui l’avez invité à utiliser l'Application ; en aucun cas, la Société ne pourra être tenu responsable de la communication à des tiers des données du patient par l'Utilisateur Professionnel de santé ;\n" +
        "   • Aux sous-traitants auxquels la Société fait appel pour toute opération technique portant sur les données de votre patient dans le cadre de garanties suffisantes au titre de la mise en œuvre de mesures techniques et organisationnelles appropriées en particulier au terme de confidentialité et de sécurité ;\n" +
        "   • Aux administrateurs strictement habilités de l'hébergeur agréé de données de santé, au sens de l'article L. 1111-8 du Code de la Santé Publique. La Société, ou l'Utilisateur Professionnel de santé, ne peuvent en aucun cas publier des données concernant le patient sans son consentement.\n" +
        "Conformément à la réglementation applicable, vous et votre patient êtes informé que vous bénéficiez :\n" +
        "   • D’un droit d'accès aux informations qui vous concernent, ainsi que d'un droit à la portabilité de vos données, qui vous permet de récupérer une partie de ces données pour les transmettre à un autre responsable de traitement ;\n" +
        "   • D’un droit de modification ou rectification de vos données à caractère personnel ;\n" +
        "   • D’un droit de définir les directives relatives à la conservation, l'effacement et la communication de vos données à caractère personnel post-mortem ;\n" +
        "   • D'un droit de limitation, d'effacement et d'opposition au traitement des informations qui vous concernent.\n" +
        "Lorsque le traitement est basé sur votre consentement, vous avez la possibilité de retirer votre consentement au traitement de vos données personnelles à tout moment.\n" +
        "Pour exercer ce droit, vous pouvez adresser une demande en écrivant à l'adresse suivante : contact@doctup.fr\n" +
        "Vous avez la possibilité d'introduire une réclamation auprès de la commission nationale de l'informatique et des libertés.\n",
    },
  },
  [CongratsNS]: {
    session: "Vous avez terminé votre séance",
    misession: "Bravo votre séance est presque terminée !",
    phase: "Vous passez à la nouvelle phase de rééducation",
    end: "Vous avez terminé votre programme de rééducation",
    program: "Vous avez terminé votre programme d'aujourd’hui",
  },
  [DeleteAccountNS]: {
    title:
      "Vous avez la possibilité de supprimer votre compte. Toutes les données liées à votre compte seront supprimées sous un délai de 15 jours.",
    warningParagraph:
      "<p><b>Attention, la suppression de votre compte sera définitive</b>, vous ne pourrez plus récupérer les données de votre compte.</p>",
    validateDeletion:
      "<p>Si vous souhaitez tout de même supprimer votre compte, cliquez sur <b>Supprimer votre compte.</b>\n</p>",
  },
  [EvaluationNS]: {
    exercise: "Évaluer votre exercice",
    evening: "Évaluer votre séance",
    increaseDifficulty:
      "Félicitations pour vos efforts ! Seriez-vous d'accord pour passer à un niveau plus avancé de cet exercice à partir de demain ?",
    morning: "Comment vous sentez-vous aujourd'hui ?",
    difficulty: "Quelle a été la difficulté des exercices ?",
    morningPain: "Quel est votre niveau de douleur ?",
    eveningPain: "Quel a été votre niveau de douleur ?",
    fatigue: "Quel est votre niveau de fatigue ?",
    stiffness: "Sentez-vous votre articulation raide ?",
    information: "💡 Votre évaluation est partagée à votre chirurgien pour consultation.",

    medium: "Moyenne",
    efforts: {
      easy: "Facile",
      important: "Importante",
      difficult: "Difficile",
      maximal: "Maximale",
    },
    pains: {
      none: "Aucune",
      weak: "Faible",
      strong: "Forte",
      intolerable: "Intolérable",
    },
  },
  [ExerciseNS]: {
    abandon: "Oui, abandonner",
    next: "Suivant",
    previous: "Précédent",
    sessionOfTheDay: "Session du jour",
    exercise: "Exercice",
    cannot: "Je ne peux pas faire cet exercice",
    play: "Jouer",
    pause: "Pause",
    goals: "Objectifs",
    instructions: "Consignes",
    errors: "Erreurs à éviter",
    progression: "Progressions",
    empty: "Une erreur est survenue lors du chargement de la vidéo",
    alert: {
      title: "Abandonner la séance ?",
      message: "Abandonner mettra fin à la séance d’exercice.",
    },
    oncoAlert: {
      title: "Arrêter la séance",
      message: "Si vous arrêtez maintenant, vous devrez reprendre la séance depuis le début.",
    },
  },
  [ExercisesListNS]: {
    sectionTitle: "Exercices",
  },
  [FaqNS]: {
    tabs: {
      before: "Avant l'opération",
      after: "Après l'opération",
    },
    chips: {
      tutorial: "Tutoriel",
      information: "Information",
      document: "Document",
    },
  },
  [HomeNS]: {
    todo: "À faire",
    faq: "FAQ",
    fillSatisfaction: "Donnez votre avis",
    start: "Démarrer",
    startSession: "Démarrer la séance",
    sessionToCome: "Début de votre programme le:",
    sessionToComeButton: "Programme à venir",
    seeMore: "Voir plus",
    inquire: "S'informer",
    session: "Séance de {{time}}",
    seeExercises: "Voir les exercices",
    endedSession: "Séance terminée",
    comeBack: "Revenez à {{slot}} pour la séance",
    title: "👋 Bienvenue {{firstName}} {{lastName}}",
    titleSurgeon: "👋 Bienvenue Dr {{lastName}}",
    subtitle: "Votre programme d'aujourd'hui",
    nextTime: "Prochaine séance d'exercices",
    todoEnd: "Vous avez fini vos tâches pour le moment ! 👍",
    todoToCome: "💡 Vous retrouverez ici la liste de vos tâches à effectuer.",
    sessionEnd: "Vous avez fini vos exercices pour aujourd’hui ✨",
    noExo: "Vous n'avez pas d'exercices prévus aujourd'hui✨",
    description: "{{count}} exercice • {{time}} min",
    description_plural: "{{count}} exercices • {{time}} min",
    descriptionWithoutEquipments: "$t(description)",
    descriptionWithEquipments: "$t(description) • {{equipment}}",
    descriptionWithEquipments_plural: "$t(description_plural) • {{equipment}}",
    takeTime: "Prenez le temps de faire les exercices à votre rythme",
    dialog: {
      label: "J'ai compris",
      message: "Merci de vous connecter à $t(common:appName) en utilisant l'application mobile.",
    },
    alert: {
      title: "Séance d'exercices en cours",
      message: "Une séance a été interrompue, souhaitez-vous la continuer ? ",
      yes: "Oui, continuer",
    },
    protocolEnd: {
      title: "✔️ Programme complété",
      ortho: {
        button: "Commencer un nouveau programme",
        text: "Félicitations !\nVous avez terminé votre protocole de rééducation  🎉",
      },
      onco: {
        button: "Renseigner une date",
        text:
          "Félicitations !\nVous avez terminé votre protocole de rééducation  🎉\nSi vous avez d'autres date de chirurgie planifiées, n'oubliez pas de les renseigner afin que Doct'Up vous propose un programme adapté à la chirurgie.",
      },
    },
    enterDate: {
      title: "Renseigner une date",
      text:
        "Veuillez renseigner une date de chirurgie afin de débuter votre programme de rééducation",
    },
  },
  [JourneyNS]: {
    header: {
      hello: "Bonjour",
      congrats: "Bravo",
      preop: "💡 Votre opération est prévue dans {{numberOfDays}} jours",
      tomorrow: "💡 Votre opération est prévue demain !",
      surgeryDay: "Votre opération est prévue aujourd’hui 💪",
      activityDays: {
        title: "{{numberOfDays}} jours d’activité consécutive 🔥",
        recovery: "Félicitations pour votre engagement !\nChaque effort compte 🌟",
        lessThan3: "Rappelez-vous, la constance est la voie du succès !",
        lessThan6: "Continuez sur votre lancée, la régularité est clé !",
        lessThan10: "La régularité vous mène vers le succès !",
        moreThan10: "Votre engagement est vraiment remarquable !",
      },
      inactivityDays: "Reprenez vos exercices aujourd'hui et ressentez la différence 💪",
      ended: "Votre programme est terminé 🥳",
    },
    activityTracking: {
      title: "Suivi d'activité",
      calendarButton: "Voir le calendrier",
    },
    exercicesView: {
      addAdditionalDays: "Prolonger la phase en cours",
      addedDaysInfoKine: "📅 Des jours supplémentaires ont été ajoutés à cette phase.",
      addedDaysInfoPatient:
        "💡 Votre kiné a ajouté {{addedDaysNumber}} jours à cette étape de votre programme pour renforcer votre récupération.",
      additionalDays: "Nombre de jours à ajouter: ",
      advanceToNextPhaseKine: "🚀  Vous avez avancé le patient à cette nouvelle phase.",
      advanceToNextPhase: "🚀  Le kiné a avancé le patient à cette nouvelle phase.",
      buttonCurrentPhase: "Prolonger la phase en cours",
      buttonEditCurrentPhase: "Modifier la prolongation",
      buttonGoBackPhase: "Revenir à cette phase",
      buttonNextPhase: "Avancer à cette phase",
      confirmNextPhase: "Êtes-vous sur de faire passer votre patient à cette nouvelle phase ? ",
      confirmationMessage: "Modification effectuée",
      confirmationMessageNextPhase:
        "Vous avez avancé le patient à une nouvelle phase de son protocole de rééducation.",
      confirmationMessagePreviousPhase:
        "Vous avez fait revenir le patient à la phase précédente de son protocole de rééducation.",
      confirmationMessageAdditionalDays:
        "Vous avez ajouté {{safeAdditionalDays}} jours à la phase de ce patient, portant le total de {{initialDays}} à {{totalDays}} jours.",
      currentExos: "Exercices en cours",
      currentPhase: "Phase en cours",
      disabledKine: "💡 Vous avez décidé de raccourcir cette phase pour passer à la suivante.",
      disabled: "💡 Le kiné a décidé de raccourcir cette phase pour passer à la suivante.",
      exosTocome: "Exercices à venir",
      goBackToPhaseMessageKine:
        "💡 Vous avez décidé de faire revenir votre patient sur la phase précédente.",
      goBackToPhaseMessage:
        "💡 Le kiné a décidé de faire revenir votre patient sur la phase précédente.",
      infoPatientAdvancePhase:
        "🚀 Votre kinésithérapeute vous a fait progresser à cette nouvelle séance d'exercices. \n\n Bravo, continuez sur cette voie!",
      infoPatientGoBackToPhaseMessage:
        "💡 Votre kinésithérapeute vous a fait revenir à la séance d'exercices précédente. \n\nNe vous découragez pas, chaque étape est un pas vers votre rétablissement !",
      kinePhase: "Phase",
      maxAdditionalDaysMessage:
        "Vous ne pouvez pas ajouter plus de {{maxAdditionalDays}} jours à la phase.",
      modifyAdditionalDays: "Modifier la prolongation",
      modifyAddedDaysInfoKine:
        "💡 La phase en cours a été prolongée de {{addedDaysNumber}} jour(s), passant de {{initialDays}} à {{totalDaysNumber}} jours.",
      modifyDays: "Modifier le nombre de jours:",
      nextSession: "La prochaine étape de mon programme débutera le\n",
      noExos: "Il n'y a pas d’exercices prévus à cette étape du programme.",
      noNextSession: "Mon programme se terminera le\n",
      phaseBefore: "Phase précédente",
      phaseToCome: "Phase suivante",
      programEnded: "Mon programme s'est terminé le\n",
      programToCome: "Mon programme commencera le\n",
      removeDaysInfoKine: "💡 Vous avez retiré {{addedDaysNumber}} jours à la phase de ce patient.",
      totalDay: "jour",
      totalDays: "jours",
      validationRequired: "Validation Requise",
      warningError: "Une erreur s'est produite.\nVeuillez réessayer.",
      warningMessage:
        "Votre patient a déjà effectué {{minAdditionalDays}} jour(s) additionnel(s). Veuillez entrer un nombre de jours supérieur ou égal à {{minAdditionalDays}}.",
    },
    protocolInfo: {
      surgeryDate: "Ma chirurgie",
      endDate: "💡 Mon programme\nse terminera le",
      endedDate: "💡 Mon programme\ns'est terminé le",
      button: "Voir mon parcours",
      seeMore: "Voir plus",
      seeLess: "Voir moins",
      step: "Étape",
      programStart: "Début du programme",
      noProtocols: "Le protocole n'est pas disponible",
      inProgress: "(en cours)",
    },
    eval: {
      title: "Dernière évaluation",
      navigation: "Mon évolution",
      pain: "Douleur",
      effort: "Difficulté",
      stiffness: "Raideur",
      fatigue: "Fatigue",
      noEval: "Aucune donnée enregistrée pour le moment",
    },
  },
  [MainNS]: {
    tabs: {
      faq: "FAQ",
      codes: "Codes",
      home: "Accueil",
      account: "Compte",
      patients: "Patients",
      protocols: "Protocoles",
      journey: "Parcours",
      patientJourney: "New",
    },
    header: {
      home: "Accueil",
      faq: "Foire aux questions",
      placeholder: "...",
      searchByName: "Rechercher par nom ou prénom",
      codes: "Codes",
      organs: "Sélectionner une Articulation",
      patients: "Patients",
      patient: "Patient",
      pathologies: "Sélectionner un Protocole",
      phaseResume: "Information sur la phase",
      protocols: "Détails du protocole",
      specialties: "Sélectionner une Spécialité",
      journey: "Mon parcours",
      timelineProtocol: "Programme",
      calendar: "Suivi d'activité",
      graph: "Évaluations de la semaine",
    },
  },
  [NavigationNS]: {
    Phone: {
      title: "Téléphone",
    },
    Address: {
      title: "Adresse professionnelle",
    },
    ShareContact: {
      title: "Autorisation de partage",
    },
    KineSpecialties: {
      title: "Spécialités",
      info:
        "💡 Communiquez vos spécialités pour faciliter la mise en relation avec des patients ayant des besoins spécifiques correspondant à votre expertise.",
    },
    KineDataScreen: {
      title: "Référencement Doct'UP",
    },
    SMSValidation: {
      title: "Code SMS",
    },
    Welcome: {
      welcome: [
        {
          title: "DOCT'UP",
          description:
            "Un outil numérique pour mieux se préparer et s’impliquer davantage dans le parcours de rééducation.",
        },
        {
          title: "Remplir ses tâches administratives",
          description: "Un outil simple et efficace\npour ne rien oublier avant l’opération.",
        },
        {
          title: "Un suivi\n post-opératoire\n au quotidien",
          description:
            "Suivre chaque jour son programme d’autorééducation avec des vidéos adaptées à son niveau et sa progression.",
        },
        {
          title: "Une plateforme de télésuivi par des professionnels de santé",
          description:
            "Suivre chaque jour son programme\n" +
            "d’autorééducation avec des vidéos adaptées à son niveau et sa progression.",
        },
      ],
    },
    Cgu: {
      title: "CGU | $t(common:appName)",
    },
    Profile: {
      title: "Profil",
    },
    Operation: {
      title: "Informations médicales",
      surgeries_title: "Opérations",
      other_pathologies: "Autres pathologies",
      heart_disease: "Maladie cardiovasculaire",
      respiratory_disease: "Maladie des voies respiratoires",
      therapies: "Thérapies",
    },
    Alert: {
      title: "Alertes",
    },
    Specialty: {
      title: "De quel service avez-vous besoin ?",
    },
    Organ: {
      title: "Articulation",
    },
    OrganOnco: {
      title: "Localisation",
    },
    Pathology: {
      title: "Pathologie",
    },
    Parameters: {
      title: "Personnalisation",
      info:
        "Ici, vous pouvez choisir d'afficher ou de masquer certaines informations pour vos patients.",
      loading: "Nous actualisons vos paramètres, veuillez patienter.",
      promsActivation: "Activer l'affichage des scores\nfonctionnels",
      promsInfo:
        "💡 Les scores fonctionnels sont des questionnaires envoyés à différents moments du traitement pour mieux suivre l'évolution de vos patients.",
      todosActivation: "Activer l'affichage des Todos",
      parametersTitle: "📝 Scores fonctionnels et Todos",
      todosInfo:
        "💡 Les Todos sont des listes de tâches assignées à différents moments du traitement pour aider vos patients à se préparer et à suivre leur protocole.",
    },
    Applications: {
      dialog: {
        label: "J'ai compris",
        title: "💡 Information",
        proms:
          "Cette application propose aux patients des scores fonctionnels.\nSi vous le souhaitez, vous pouvez désactiver ceux proposés par Doct'UP dans cet onglet.",
        all:
          "Cette application propose aux patients des scores fonctionnels et des Todos.\nSi vous le souhaitez, vous pouvez désactiver ceux proposés par Doct'UP dans cet onglet.",
      },
      title: "📱 Applications partenaires",
      enable: "Activer",
      disable: "Désactiver",
      infoMobile:
        "Vous utilisez une de ces applications ?\nVous pouvez à tout moment activer des liens de redirection vous permettant d'accéder directement à ces plateformes sur le web.",
      infoWeb:
        "Vous utilisez une de ces applications ?\nVous pouvez à tout moment activer des liens de redirection vous permettant d'accéder directement à ces plateformes.",
      linkInfo:
        "💡 Une fois activés, vous retrouverez les liens de redirection uniquement sur l'interface web de notre application.",
    },
    Practitioner: {
      title: "Équipe médicale",
    },
    Research: {
      title: "Données pour la recherche",
    },
    Language: {
      title: "Langage",
    },
    DeleteAccount: {
      title: "Supprimer le compte",
      cancel: "Annuler",
      confirmationDeletion:
        "Vous avez demandé la suppression de votre compte.\n Cette suppression sera effective dans les jours à venir, un email vous sera envoyé dès que la suppression sera effective.",
      definitiveDeletionTitle: "La suppression de votre compte est définitive.",
      definitiveDeletionParagraph:
        "Toutes les données liées à votre compte seront supprimées et ne pourront être récupérées.\n Souhaitez-vous continuer ?",
    },
    ReviewUrl: {
      title: "Avis automatiques",
    },
    RdvUrl: {
      title: "Lien de rendez-vous",
    },
    SurgeonPractice: {
      title: "Informations sur ma pratique",
      question: "À combien de patients envisagez-vous de distribuer DoctUp chaque mois ?",
      info:
        "Cette information a pour but d'améliorer votre expérience en personnalisant le nombre de codes à vous envoyer.",
      junior: "Entre 0 et 29",
      intermediate: "Entre 30 et 49",
      senior: "Plus de 50+",
      unknown: "Je ne souhaite pas répondre",
    },
  },
  [OperationNS]: {
    header:
      "Renseignez vos informations médicales pour permettre à l’équipe médicale de suivre votre progression.",
    informationWithoutCode:
      "💡 Partagez votre code avec votre kinésithérapeute pour un meilleur suivi et une vision plus claire de vos progrès.\n\n🔑 MON CODE : {{code}}",
    informatioSurgeonWithoutCode:
      "💡 Partagez votre code avec votre chirurgien pour un meilleur suivi et une vision plus claire de vos progrès.\n\n🔑 MON CODE : {{code}}",
    informationWithCode:
      "💡 Votre kinésithérapeute a accès à vos données de suivi et peut ainsi mieux suivre votre progression.",
    pathologyInformation:
      "💡 Vos données de santé sont essentielles pour établir un protocole de rééducation sur mesure. En cas de doute, contactez votre chirurgien.",
    pathologyEditInformation:
      "💡  Pour mettre à jour les informations relatives à votre pathologie, contactez notre support client:",
    missing: "Pas enregistré",
    supportButton: "contact@doctup.fr",
    contactTitle: "[Doct'up] - Erreur de pathologie",
    contactText: "Utilisateur(rice) : {{firstName}}·{{lastName}}, ID : {{id}}",
    label: {
      surgeon: "Chirurgien",
      surgeryDate: "Date de l'opération",
      pathology: "Pathologie",
      kine: "Kinésithérapeute",
    },
    noProgram: "Il n'y a pas de programme possible, merci de nous contacter",
    required: {
      kine: "Seules les lettres sont acceptées",
      pathology: "Une pathologie doit être sélectionnée",
      surgeryDate: "La date de l'opération est obligatoire",
    },
  },
  [PatientsListNS]: {
    all: "Tous",
    pathology: "{{name}}",
    add: "Ajouter un patient",
    name: "{{firstName}} {{lastName}}",
    daySinceSurgery: "Jour {{current}}/{{lastDay}}",
    daySinceSurgery_positive: "J+{{nbDays}}",
    daySinceSurgery_negative: "J{{nbDays}}",
    ended: "Terminé",
    inactivity: "Plus de 5 jours d'inactivité",
    allPatients: "Tous les patients",
    therapy_data: "{{therapy_data}}",
    surgery_data: "{{surgery_data}} opérations",
    other_path_data: "{{other_path_data}} pathologies",
    date_unknown: "Date à renseigner",
    no_onco_data: "Non",
  },
  [PatientNS]: {
    additionalInformation: "Informations chirurgicales",
    additionalDays: "Nombre de jours supplémentaires: ",
    bipolar: "Sonde bipolaire",
    empty: "Pas de messages",
    errors: {
      load_patient: "Sélectionnez un patient",
      send_message: "Une erreur est survenue lors de l'envoi du message",
    },
    subtitle_negative: "J{{nbDays}} •",
    subtitle_positive: "J+{{nbDays}} •",
    effort: "Effort",
    pain: "Douleur",
    difficulty: "Difficulté",
    stiffness: "Raideur",
    diligence: "Assiduité",
    information: "Informations",
    handlePhases: "Adapter le protocole",
    viewPhases: "Phases du protocole",
    proms: "Scores fonctionnels",
    PREOPERATIVE: "Pré-opératoire",
    SIX_POSTOPERATIVE: "De 0 à 6 mois post-opératoire",
    TWELVE_POSTOPERATIVE: "De 6 à 12 mois post-opératoire",
    ONE_YEAR_POSTOPERATIVE: "Supérieur à 1 an post-opératoire",
    rating: "Satisfaction",
    score: "{{value}}%",
    surgeon: "Chirurgien",
    surgeryDate: "Date de chirurgie",
    surgeryDuration: "Temps de chirurgie (minutes)",
    surgeryAdditionalInfo: "Conditions particulières de chirurgie",
    toFill: "À compléter",
    kine: "Kinésithérapeute",
    gp: "Médecin",
    addCr: "Ajouter un CR opératoire",
    radio: {
      support: "Appuis",
      complication: "Complication",
      splint: "Attelle",
      meniscal: "Suture méniscale",
    },
    label: {
      yes: "Oui",
      no: "Non",
    },
    evaluation: "Dernière évaluation",
    progression: "Progression",
    patientProgression: "Mon protocole",
    phase: "Phase {{index}}",
    report: "CR opératoire envoyé",
    weekEvaluation: "Évaluations de la semaine",
    messages: "Messages",
    message: "Message",
    addMessage: "Ajouter un message",
    send: "Envoyer",
    journeyCongratulation: "Bravo 👏",
    postOpDaySinceBegining: "C'est votre {{nbDays}}ème jour de rééducation !",
    postOpFirstDaySinceBegining: "C'est votre {{nbDays}}er jour de rééducation !",
    preOpDaySinceBegining: "💡 Il reste {{nbDays}} jour(s) avant votre opération.",
    surgeryDay: "C'est le jour de votre opération 💪",
    numberOfSessions: "Vous avez accompli\n",
    numberOfSessionsEnd: "\njours d'activité",
    programEnd: "💡 Votre programme se terminera le\n{{endDate}}",
    newPhaseStart: "Nouvelle phase\nd’auto-réeducation\nprévue le\n{{newPhase}}",
    noNewPhase: "Vous n'avez pas de session d'exercices à venir.",
    noEvaluationYet: "Aucune évaluation remplie pour le moment.",
    usefulInformation: "Créer onglet : Informations utiles",
    alerts: [
      { key: 0, title: "Ajouter une alerte - optionnel" },
      { key: 1, title: "Rééducation" },
      { key: 2, title: "Cicatrice" },
      { key: 3, title: "Douleur" },
      { key: 4, title: "Température" },
      { key: 5, title: "Raideur" },
    ],
    painChartTitle: "Douleur",
    effortChartTitle: "Difficulté",
    noData: "Aucune donnée enregistrée pour le moment",
    loadingData: "En cours de chargement",
    errorData: "Une erreur s'est produite au cours du chargement des données",
    lastSession: "Dernière connexion",
    noActivityRecorded: "Aucune connexion détectée",
  },
  [PractitionerHomeNS]: {
    codes: "Codes",
    patients: "Patients",
    button: {
      activate: "Activer des codes",
      order: "Commander des codes",
    },
    empty: "Aucun patient trouvé",
    subtitle: {
      order: "J'ai épuisé mon pack de codes et je souhaite en commander un nouveau",
      activate: "J’ai reçu un pack de code et je souhaite l’activer.",
      review:
        "Améliorez votre visibilité en ligne en demandant automatiquement un avis à vos patients.",
      rdvUrl:
        "💡 Facilitez les démarches pour les patients en partageant le lien vers votre site de rendez-vous en ligne.\n\nVous pouvez copier ce lien depuis votre plateforme de réservation. Assurez-vous qu’il redirige correctement vers la page de prise de RDV.",
    },
    activate: "Codes activés. Vous pouvez dès maintenant les distribuer à vos patients.",
    patientAdded: "Patient ajouté avec succès",
    activatedPack: "Codes activés. Vous pouvez dès maintenant les distribuer à vos patients.",
    order: {
      subject: "Passer une commande de codes",
      body: "{{firstName}}·{{lastName}}·{{phone}}\nNombre de codes désiré (10, 50, 100):",
    },
    review: {
      title: "Avis automatiques",
      label: "Lien formulaire d'avis",
      button: "Où trouver ce lien ?",
      switch: "Activer les avis automatiques",
    },
    rdvUrl: {
      label: "Lien site de rendez-vous (optionnel)",
      error: "Format d'url non valide",
      info: "Exemple : https://www.exemple.com",
    },
  },
  [PraticienNS]: {
    surgeon: "Chirurgien",
    kine: "Kinésithérapeute",
    gp: "Médecin",
  },
  [ProfileNS]: {
    label: {
      cm: "cm",
      kg: "kg",
      size: "Taille",
      weight: "Poids",
      lastName: "Nom",
      firstName: "Prénom",
      male: "homme",
      gender: "Sexe",
      female: "femme",
      birthdate: "Date de naissance",
    },
    required: {
      lastName: "Le champ nom est obligatoire",
      firstName: "Le champ prénom est obligatoire",
      size: "Le champ taille est obligatoire",
      weight: "Le champ poids est obligatoire",
      birthdate: "La date doit être inférieure ou égale à la date actuelle",
      numeric: "La saisie doit être un nombre",
      email: "L'email est obligatoire",
      surgeonPractice: "Le champ est obligatoire",
      street_number: "Le numéro de voie est obligatoire",
      route: "La voie est obligatoire",
      postal_code: "Le code postal est obligatoire",
      locality: "La ville est obligatoire",
      country: "Le pays est obligatoire",
    },
    wrongFormat: {
      email: "Le format de l'email est invalide.",
    },
    address: {
      errorTitle: "⚠️ Erreur",
      addressError:
        "Une erreur s'est produite, nous n’avons pas pu trouver votre adresse.\nVeuillez vérifier les informations renseignées et réessayer.\nSi vous décidez de remplir ce formulaire plus tard, vos informations ne seront pas enregistrées.",
      addressModal: {
        title: "Confirmer l'adresse",
        message:
          "Votre adresse est elle :\n\n{{street_number}} {{route}},\n{{locality}} {{postal_code}},\n{{country}}",
        positive: "Oui, je confirme",
        negative: "Modifier l'adresse",
        later: "Remplir plus tard",
      },
    },
  },
  [ProtocolNS]: {
    faqpreop: "FAQ avant l'opération",
    faqpostop: "FAQ après l'opération",
    phasesList: "Phases du protocole",
  },
  [SettingsNS]: {
    address: "Adresse professionnelle",
    kineSpecialties: "Spécialités",
    KineDataScreen: "Référencement Doct'UP",
    automatic_review: "Avis automatiques",
    rdv_link: "Lien de rendez-vous",
    profile: "Profil",
    medicalInfo: "Informations médicales",
    alert: "Alertes",
    code: "Code d'activation",
    phoneNumber: "Numéro",
    parameters: "Personnalisation",
    applications: "Mes applications",
    support: "Support client",
    surgeonPractice: "Ma pratique",
    logout: "Se déconnecter ?",
    disconnect: "Êtes-vous sûr de vouloir vous déconnecter de votre compte ?",
    confirm: "Oui, se déconnecter",
    research: "Données pour la recherche",
    deleteAccount: "Supprimer le compte",
    operations: "Opérations",
    therapies: "Thérapies",
    radiotherapy: "Radiothérapie",
    chemotherapy: "Chimiothérapie",
    hormoneTherapy: "Hormonothérapie",
    immuno: "Thérapie ciblée",
    othersPathology: "Autres pathologies",
    heart_disease: "Maladie cardiovasculaire",
    respiratory_disease: "Maladie des voies respiratoires",
    endocrine_disease: "Maladie endocrinienne",
    mastectomy: "Mastectomie",
    tumorectomy: "Tumorectomie",
    sentinel_node: "Chirurgie du ganglion sentinelle",
    ax_curing: "Curage axillaire",
    reconstructive: "Reconstruction",
    hypertension: "Hypertension artérielle",
    heart_rhythm: "Troubles du rythme cardiaque",
    infarction: "Infarctus du myocarde",
    other: "Autre",
    cerebral: "Accident vasculaire cérébral (AVC)",
    bronchitis: "Bronchite chronique",
    asthma: "Asthme",
    lung_cancer: "Cancer du poumon",
    language: "Langage",
    shareContact: "Autorisation de partage",
  },
  [SMSValidationNS]: {
    sent: "Code envoyé",
    networkError: "Erreur réseau",
    codeError: "Code de validation erroné",
    "auth/too-many-requests":
      "Nous avons bloqué toutes les demandes de cet appareil en raison d'une activité inhabituelle. Réessayez plus tard.",
    "auth/invalid-verification-code": "Le code de vérification est invalide.",
    "auth/invalid-credential": "Votre numéro de téléphone n'a pas été correctement validé",
    "auth/credential-already-in-use":
      "Ce numéro de téléphone est déjà associé à un autre compte utilisateur.",
    "auth/cancelled-popup-request": "erreur",
    unknown: "Une erreur est survenue lors de la vérification du code",
  },
  [PromsNS]: {
    satisfaction: "Satisfaction",
    score: "Score final",
    notShow: "Ne pas me redemander",
    later: "Le faire plus tard",
    notShare: "Ne pas partager",
    share: "Partager votre avis",
    doneTitle: "{{title}}\nVous avez déjà rempli ce formulaire",
    endTitle: "Score {{name}} : {{score}}",
    endSubtitle: "Merci pour votre réponse !",
    shareTitle: "Merci beaucoup !",
    shareSubtitle:
      "Souhaitez-vous aider votre équipe médicale à gagner en visibilité en partageant cet avis en ligne ?",
    notShareSubtitle: "Votre avis nous aidera à améliorer la prise en charge des patients.",
    placeholder: "Ajouter un texte",
    step: "Étape {{current}}/{{total}}",
    error: "Connexion impossible",
    "auth/invalid-id-token": "Ce formulaire est destiné à un autre patient",
    close: "Vous pouvez fermer cet onglet dans votre navigateur",
    oncoTitle: "Informations santé",
    oncoSubtitle1:
      "Nous allons tout d'abord vous poser quelques questions pour mieux connaître votre situation de vie et votre santé.\n" +
      " \n" +
      "Chaque page du questionnaire sera enregistrée dès que vous passerez à la page suivante.\n- Vous pouvez compléter le questionnaire en plusieurs fois.\n- Vous pouvez apporter des modifications à n'importe quel moment.",
    noChirProgram:
      "Aucun programme de rééducation n'a été trouvé pour la chirurgie: {{surgery_type}} à la date : {{date}}",
  },
  [LanguageNS]: {
    title: "Sélectionnez votre langue",
    name: {
      fr: "Français",
      en: "Anglais",
    },
  },
  [VideoNS]: {
    title: "Démarrons avec DoctUp!",
    button: "Commencer l'expérience",
    subtitle:
      "Explorez les fonctionnalités de DoctUp dans notre vidéo introductive. Découvrez comment la rééducation devient simple et personnalisée.",
    encourageText:
      " \n💡 Plongez dans notre vidéo de présentation de DoctUp dès maintenant ! \n \n Si vous manquez de temps, rendez-vous dans notre section FAQ pour la regarder plus tard.",
  },
}
