import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from "react"
import { GestureResponderEvent, StyleSheet } from "react-native"

import { hexToRGB } from "../../features/Theme/colors"
import useTheme from "../../features/Theme/ThemeProvider"
import delay from "../../utils/delay"
import { openHtmlViewUrl } from "../../utils/openUrl"
import { Text } from "./common"

interface LinkProps {
  href: string
}

export const Link: FC<PropsWithChildren<LinkProps>> = ({ href, children }) => {
  const theme = useTheme()
  const {
    colors: { primary },
    typography: { caption },
  } = theme
  const [opacity, setOpacity] = useState(1)
  const { type } = useMemo(
    () => ({
      type: [styles.text, caption, { color: hexToRGB(primary, opacity) }],
    }),
    [caption, opacity, primary],
  )

  const onPress = useCallback(
    (e: GestureResponderEvent) => {
      setOpacity(0.5)
      delay(20).then(() => setOpacity(1))
      // noinspection JSIgnoredPromiseFromCall
      openHtmlViewUrl(e, href)
    },
    [href],
  )

  return (
    <Text
      {...{ theme, type, onPress }}
      onLongPress={() => {}}
      // @ts-ignore exist but not find
      onPressIn={() => setOpacity(0.5)}
      onPressOut={() => setOpacity(1)}
    >
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  text: {
    textDecorationLine: "underline",
  },
})
