import { RouteProp } from "@react-navigation/core"
import { useNavigation } from "@react-navigation/native"
import { Button, EmptyView, generateShadow, useTheme } from "capsule"
import React, { FC, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { congratulationImages } from "../../assets/images"
import { CongratsNS } from "../../features/i18n/constants"
import { CongratType, RootParamList } from "../../features/Navigation/types"

type CongratulationScreenRouteProp = RouteProp<RootParamList, "Congratulation">

interface IProps {
  route: CongratulationScreenRouteProp
}

const CongratulationScreen: FC<IProps> = ({ route }) => {
  const {
    params: { type },
  } = route
  const { t } = useTranslation(CongratsNS)
  const navigation = useNavigation<any>()
  const {
    typography: { h6 },
    dimensions: { spacing },
    colors: {
      primary,
      secondary,
      black: { highEmphasis: black },
      white: { highEmphasis: white },
    },
  } = useTheme()
  const isMiSession = type === CongratType.misession
  const { titleStyle, viewStyle, contentStyle, labelStyle } = useMemo(
    () => ({
      titleStyle: [
        h6,
        {
          color: white,
          paddingVertical: spacing,
        },
      ],
      viewStyle: {
        backgroundColor: secondary,
        paddingHorizontal: spacing * 2,
      },
      contentStyle: [
        styles.button,
        {
          backgroundColor: white,
        },
      ],
      labelStyle: {
        color: black,
      },
    }),
    [secondary, black, h6, spacing, white],
  )

  const onPress = useCallback(() => navigation.navigate(isMiSession ? "Exercise" : "Home"), [
    isMiSession,
    navigation,
  ])

  useEffect(() => {
    if (isMiSession) {
      setTimeout(() => navigation.navigate("Exercise"), 5000)
    }
  }, [isMiSession, navigation])

  return (
    <EmptyView
      headerColor={primary}
      {...{ titleStyle, viewStyle }}
      title={t(CongratType[type])}
      image={congratulationImages[type] as number}
    >
      {type !== CongratType.misession ? (
        <Button {...{ onPress, contentStyle, labelStyle }} style={generateShadow(5)} mode="text">
          {t("common:button.continue")}
        </Button>
      ) : null}
    </EmptyView>
  )
}

const styles = StyleSheet.create({
  button: {
    width: "100%",
  },
})

export default CongratulationScreen
