import { RouteProp } from "@react-navigation/core"
import { NavigationProp } from "@react-navigation/core/lib/typescript/src/types"
import { useNavigation, useRoute } from "@react-navigation/native"
import { ParamListBase } from "@react-navigation/routers"
import { useContext } from "react"

// TODO : move context create cycles with twoPaneScreen and useTwoPaneNavigation
import { twoPaneContext } from "../components/TwoPaneScreen"

const useTwoPaneNavigation = <N extends NavigationProp<ParamListBase>>(): N => {
  const navigation = useNavigation<N>()

  const twoPane = useContext(twoPaneContext)

  return { ...navigation, ...twoPane?.navigation }
}

export const useTwoPaneRoute = <R extends RouteProp<ParamListBase, string>>(): R => {
  const route = useRoute<R>()

  const twoPane = useContext(twoPaneContext)

  return { ...route, ...twoPane?.route }
}

export default useTwoPaneNavigation
