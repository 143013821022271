import { Linking, Platform } from "react-native"

import { logger } from "./logger"

export const openHtmlViewUrl = async (_: any, url: string) => {
  try {
    if (Platform.OS === "web") {
      // @ts-ignore
      window.open(url, "_blank")
    } else {
      await Linking.openURL(url)
    }
  } catch (e) {
    logger("linking url", e)
  }
}

export const openUrl = async (url: string) => {
  try {
    if (url) {
      if (Platform.OS === "web") {
        // @ts-ignore
        window.open(url, "_blank")
      } else {
        await Linking.openURL(url)
      }
    }
  } catch (e) {
    logger(e)
  }
}
