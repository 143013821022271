import _ from "lodash"
import React, { FC, ReactNode, useState } from "react"
import { Namespace, useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"
import FastImage, { FastImageProps } from "react-native-fast-image"
import { List, Searchbar } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import useTheme from "../../features/Theme/ThemeProvider"
import { PresentationListView } from "../PresentationListView"

export interface IListItem {
  id: string
  title: string
  onPress?: () => void
  description?: string
  right?: () => ReactNode
  imageSource?: FastImageProps["source"]
}

interface IProps {
  ns?: Namespace
  data: IListItem[]
}

const keyExtractor = (elem: IListItem) => elem.id

export const ListScreen: FC<IProps> = ({ data, ns }) => {
  const {
    dimensions: { spacing },
    colors: {
      surface: { input },
      white: { highEmphasis: white },
      black: { transparent, highEmphasis: black },
    },
  } = useTheme()
  const { t } = useTranslation(ns)
  const [value, setValue] = useState("")
  const [filteredData, setFilteredData] = useState(data)

  const onChangeText = (text: string) => {
    setFilteredData(
      _.filter(data, item => _.includes(item.title.toLowerCase(), text.toLowerCase())),
    )
    setValue(text)
  }

  const searchbarStyle = {
    backgroundColor: input,
    shadowColor: transparent,
    marginHorizontal: spacing,
  }
  const listStyle = { marginTop: spacing }
  const itemStyle = [s.item, { paddingVertical: spacing / 2, paddingHorizontal: spacing }]
  const imageStyle = [s.image, { marginRight: spacing * 2 }]
  const viewStyle = [s.view, { paddingTop: spacing, backgroundColor: white, paddingBottom: 0 }]

  const renderItem: ListRenderItem<IListItem> = ({
    item: { imageSource, description, title, right, onPress },
  }) => {
    const left = () =>
      imageSource && (
        <View style={{ justifyContent: "center" }}>
          <FastImage source={imageSource} style={imageStyle} />
        </View>
      )

    return (
      <List.Item
        {...{ title, description, left, right, onPress }}
        style={itemStyle}
        titleStyle={{ alignSelf: "flex-start" }}
      />
    )
  }

  return (
    <SafeAreaView edges={["bottom", "left", "right"]} style={viewStyle}>
      <Searchbar
        value={value}
        iconColor={black}
        style={searchbarStyle}
        onChangeText={onChangeText}
        placeholder={t("placeholder")}
      />
      <PresentationListView
        indicator={{ color: black, size: "small" }}
        isEmpty={_.isEmpty(value.length === 0 ? data : filteredData)}
      >
        <FlatList
          style={listStyle}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          data={value.length === 0 ? data : filteredData}
        />
      </PresentationListView>
    </SafeAreaView>
  )
}

const s = StyleSheet.create({
  view: {
    flex: 1,
  },
  image: {
    width: 50,
    height: 50,
  },
  item: {
    padding: 0,
  },
})
