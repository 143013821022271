import { useField, useFormikContext } from "formik"
import React, { FC, ReactNode, useCallback, useMemo } from "react"

import useTheme from "../../features/Theme/ThemeProvider"
import { RadioElement, RadioElementProps } from "../RadioElement"
import { Subtitle1 } from "../Texts"
import { Touchable } from "../Touchable"

interface IProps {
  name: string
  textOnly?: boolean
  borderless?: boolean
  backgroundColor?: string
  onPress?: () => void
  specificLabel?: ReactNode
  isQcmValue?: boolean
}

export type FormikRadioProps = IProps & Omit<RadioElementProps, "onPress" | "isChecked" | "id">

export const FormikRadioButton: FC<FormikRadioProps> = ({
  value,
  name,
  textOnly,
  onPress,
  backgroundColor,
  specificLabel,
  isQcmValue,
  ...props
}) => {
  const {
    typography: { button },
    colors: {
      primary,
      overrides,
      white: { highEmphasis: white },
      black: { disabled, highEmphasis: black },
    },
  } = useTheme()
  const [field] = useField(name)
  const { setFieldValue } = useFormikContext()
  const isChecked = field.value === value

  const onRadioButtonPress = useCallback(() => {
    if (!isQcmValue) {
      setFieldValue(name, value)
    }
    onPress?.()
  }, [name, onPress, setFieldValue, value, isQcmValue])

  const s = useMemo(
    () => ({
      touchable: [
        props.touchableStyle,
        {
          backgroundColor: isChecked
            ? overrides?.radioButton?.checked || primary
            : backgroundColor ?? (overrides?.radioButton?.unchecked || disabled),
        },
      ],
      color: isChecked ? white : black,
    }),
    [
      backgroundColor,
      black,
      disabled,
      isChecked,
      overrides?.radioButton?.checked,
      overrides?.radioButton?.unchecked,
      primary,
      props.touchableStyle,
      white,
    ],
  )

  return textOnly ? (
    <Touchable
      borderless
      style={s.touchable}
      onPress={onRadioButtonPress}
      rippleColor={overrides?.ripple || primary}
    >
      {specificLabel ?? (
        <Subtitle1 color={s.color} style={button}>
          {props.label}
        </Subtitle1>
      )}
    </Touchable>
  ) : (
    <RadioElement id={value} {...props} onPress={onRadioButtonPress} {...{ value, isChecked }} />
  )
}
