import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { firestore, userContext } from "capsule/features"
import { logger } from "capsule/utils"
import dayjs from "dayjs"
import firebase from "firebase"
import { useContext, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { ID, ProgramId } from "../../common/CommonUserData"
import { FormResult, ReviewUrl } from "../../common/Proms"
import { collections } from "../../common/types"
import { ISO8601_TIME } from "../../utils/conversion"
import { FirestoreSession } from "./Session"
import { getFirestoreTimestamp, Timestamp } from "./Types"
import { Patient, UserData } from "./UserData"

export const transform = (userData: UserData) => ({
  ...userData,
  ...(userData.role === "patient"
    ? {
        programEnd: userData.programEnd
          ? getFirestoreTimestamp(userData.programEnd).toDate()
          : undefined,
        programStart: userData.programStart
          ? getFirestoreTimestamp(userData.programStart)?.toDate()
          : undefined,
        birthdate: new Date(userData.birthdate),
        surgeryDate: new Date(userData.surgeryDate as string),
        alertEndTime: dayjs(userData.alertEndTime as string, ISO8601_TIME).toDate(),
        alertStartTime: dayjs(userData.alertStartTime as string, ISO8601_TIME).toDate(),
        lastMessage: userData.lastMessage
          ? {
              ...userData.lastMessage,
              timestamp: getFirestoreTimestamp(userData.lastMessage.timestamp),
            }
          : undefined,
        lastSession: userData.lastSession
          ? getFirestoreTimestamp(userData.lastSession)?.toDate()
          : undefined,
      }
    : null),
})

export const updateUserData = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  values: Partial<UserData>,
) => {
  userDocRef?.set(values, { merge: true }).catch(e => logger("update user error", e))
}

export const editUserData = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  values: Partial<UserData>,
) => {
  userDocRef?.update(values).catch(e => logger("update user error", e))
}

export const updatePatientData = (id: string, values: Partial<Patient>) => {
  firestore()
    .collection(collections.LOGIN)
    .doc(id)
    .set(values, { merge: true })
    .catch(e => logger("update patient error", e))
}

export type UpdatePromsValues = {
  id: ID
  triggerDay?: number | undefined
  showAgain?: boolean
  scores?: FormResult
  timestamp: Timestamp
  responses?: FormResult
}

export const createUserPromsData = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  values: UpdatePromsValues,
) => {
  userDocRef
    ?.collection(collections.PROMS)
    .add(values)
    .catch(e => logger("create user proms error", e))
}

export const updateUserPromsData = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  id: ID,
  values: UpdatePromsValues,
) => {
  userDocRef
    ?.collection(collections.PROMS)
    .doc(id)
    .set(values, { merge: true })
    .catch(e => logger("update user proms error", e))
}

// retrieve last proms where user clicked "do it later"
export const useHomeForm = () => {
  const { userDocRef } = useContext(userContext)
  const [proms] = useCollectionData(
    (userDocRef
      ?.collection(collections.PROMS)
      .where("showAgain", "==", true)
      .orderBy("timestamp", "desc")
      .limit(1) as unknown) as firebase.firestore.Query,
    { idField: "docId" },
  )
  return useMemo(
    () =>
      proms?.[0]
        ? { id: proms?.[0].id, docId: proms?.[0].docId, triggerDay: proms?.[0].triggerDay }
        : undefined,
    [proms],
  )
}

export const updateSurgeonReviewUrl = (id: ID, review: ReviewUrl) => {
  firestore()
    .collection(collections.REVIEW_URLS)
    .doc(id)
    .set(review, { merge: true })
    .catch(e => logger("review url error", e))
}

export const getLastSession = async (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  phase?: ID,
  program?: ProgramId,
): Promise<{ docId?: string; data: FirestoreSession | undefined }> => {
  const query = userDocRef
    ?.collection(collections.SESSIONS)
    .orderBy("daySinceSurgery", "desc")
    .orderBy("seqInDay", "desc")
    .where("phase", "==", phase)
  const oldSessionDocs = (program
    ? await query?.where("program", "==", program).limit(1).get()
    : await query?.limit(1).get()
  )?.docs
  return {
    docId: oldSessionDocs?.[0]?.id,
    data: oldSessionDocs?.[0]?.data() as FirestoreSession | undefined,
  }
}

export const getLastOncoSession = async (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  program?: string,
  phase?: string,
): Promise<{ docId?: string; data?: FirestoreSession }> => {
  const oldSessionsDocs = (
    await userDocRef
      ?.collection(collections.SESSIONS)
      .where("program", "==", program)
      .where("phase", "==", phase)
      .orderBy("daySinceSurgery", "desc")
      .limit(1)
      .get()
  )?.docs

  return {
    docId: oldSessionsDocs?.[0]?.id,
    data: oldSessionsDocs?.[0]?.data() as FirestoreSession | undefined,
  }
}
