import { ImageSourcePropType } from "react-native"

import { CongratType } from "../../features/Navigation/types"

export const images: { [key: string]: ImageSourcePropType } = {
  custom_surgery: require("./custom_surgery.png"),
  kine: require("./kine.png"),
  equipment: require("./equipment.png"),
  evaluation: require("./evaluation.png"),
  congrat_misession: require("./congrat.png"),
  congrat_session: require("./congrat_session.png"),
  congrat_program: require("./congrat_program.png"),
  congrat_phase: require("./congrat_phase.png"),
  congrat_end: require("./congrat_end.png"),
  surgeon: require("./surgeon.png"),
  oncologist: require("./oncologist.png"),
  kine_patient: require("./kine_patient.png"),
  last_session: require("./last_session.png"),
  gp: require("./gp.png"),
  review: require("./review.png"),
  right_placeholder: require("./right_placeholder.jpg"),
  proms_welcome: require("./proms_welcome.png"),
  proms_end: require("./proms_end.png"),
  orthense: require("./orthense.png"),
  renacot: require("./renacot.png"),
  oneday: require("./oneday.png"),
  follow: require("./follow.png"),
  yooli: require("./yooli.png"),
}

export const welcomeImages = [
  require("./welcome1.png"),
  require("./welcome2.png"),
  require("./welcome3.png"),
  require("./welcome4.png"),
]

type CongratulationImage = { [K in CongratType]: ImageSourcePropType | ImageSourcePropType[] }
export const congratulationImages: CongratulationImage = {
  [CongratType.session]: images.congrat_session,
  [CongratType.misession]: images.congrat_misession,
  [CongratType.program]: images.congrat_program,
  [CongratType.phase]: images.congrat_phase,
  [CongratType.end]: images.congrat_end,
}
