import React, { FC, useCallback, useState } from "react"
import { Platform } from "react-native"
import { Switch as PaperSwitch } from "react-native-paper"

import delay from "../../utils/delay"
import { logger } from "../../utils/logger"

export type SwitchProps = React.ComponentProps<typeof PaperSwitch> & { ms?: number }

const retNbrForFn = async (fn: (...args: any) => Promise<unknown>, nbr: number) =>
  fn().then(() => nbr)

const DEFAULT_TIMEOUT = 50

const OptimisticSwitch: FC<SwitchProps> = ({
  value,
  onValueChange,
  ms = DEFAULT_TIMEOUT,
  ...rest
}) => {
  const [optimisticValue, setOptimisticValue] = useState<boolean>()

  const onOptimisticValueChange = useCallback(
    async (val: boolean) => {
      setOptimisticValue(val)
      try {
        const res = await Promise.race([
          retNbrForFn(async () => onValueChange?.(val), 0),
          retNbrForFn(async () => delay(ms), 1),
        ])
        res === 0 && Platform.OS === "android" && (await delay(DEFAULT_TIMEOUT))
      } catch (e) {
        logger("switch error", e)
      } finally {
        setOptimisticValue(undefined)
      }
    },
    [ms, onValueChange],
  )

  return (
    <PaperSwitch
      {...rest}
      value={optimisticValue ?? value}
      onValueChange={onOptimisticValueChange}
    />
  )
}

export default OptimisticSwitch
