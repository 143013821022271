import { Dimensions } from "react-native"

import { IDimensionsTheme } from "./types"

const { height, width } = Dimensions.get("window")

export const maxWidth = 420
export const tabSizeLand = 64
export const minWidthTabLand = 480
export const minWidthTwoPane = maxWidth * 2

const dimensions: IDimensionsTheme = {
  width,
  height,
  columns: 2,
  gutter: 16,
  margin: 16,
  marginSides: 16,
  spacing: 16,
  stackHeight: 56,
  isTabLand: ({ width: w, height: h }) => w > h && w > minWidthTabLand,
  isTabTwoPane: ({ width: w }) => w > minWidthTwoPane,
  textInputStyle: {},
}

export default dimensions
