import { useHeaderHeight } from "@react-navigation/elements"
import { useIsFocused } from "@react-navigation/native"
import { useAppState } from "capsule/utils"
import React, {
  Dispatch,
  FC,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  NativeModules,
  Platform,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native"
import Orientation from "react-native-orientation-locker"
import { ActivityIndicator } from "react-native-paper"
import Icon from "react-native-vector-icons/MaterialIcons"
import Video, {
  OnLoadData,
  OnProgressData,
  OnVideoErrorData,
  ResizeMode,
  VideoRef,
} from "react-native-video"

import useTheme from "../../features/Theme/ThemeProvider"
import useLayout from "../../utils/useLayout"
import { ProgressBar } from "../ProgressBar"
import { Touchable } from "../Touchable"
import { VectorIcon, VectorIconProps } from "../VectorIcon"
const { OrientationManager } = NativeModules

interface VideoControl {
  paused: boolean
  onEnd?: () => void
  onError?: (error: OnVideoErrorData) => void
}

interface IProps {
  uri: string
  icon?: VectorIconProps
  control?: VideoControl
  showProgress?: boolean
  hasExtraHeader?: boolean
  onVideoPress?: () => void
  progressBarHeight?: number
  alternativeProgress?: number
  forwardRef?: RefObject<VideoRef>
  renderDuration?: (time?: number, duration?: number) => ReactNode
  style?: StyleProp<ViewStyle>
  videoRatio?: number
  startPosition?: number // in seconds
  controlledPause?: boolean
  isFullScreenPrincipal?: boolean
  setIsFullScreenPrincipal?: Dispatch<SetStateAction<boolean>>
  isControls?: boolean
  enableFullScreen?: boolean
}

export const VIDEO_RATIO = 16 / 9

const VideoPlayer: FC<IProps> = ({
  uri,
  icon,
  control,
  isControls,
  enableFullScreen = false,
  showProgress = true,
  hasExtraHeader = true,
  onVideoPress,
  progressBarHeight = 8,
  alternativeProgress,
  forwardRef,
  renderDuration,
  style,
  videoRatio = VIDEO_RATIO,
  startPosition = 0,
  controlledPause,
  isFullScreenPrincipal,
  setIsFullScreenPrincipal,
}) => {
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()
  const [handledControls, setHandledControls] = useState(isControls)
  const [elapsedTime, setElapsedTime] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [paused, setPaused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const { width, height, onLayout } = useLayout()
  const headerHeight = useHeaderHeight()

  const { appState } = useAppState()
  const isFocused = useIsFocused()

  useEffect(() => {
    if (appState !== "active" || !isFocused) {
      if (Platform.OS === "ios" && isControls) {
        setHandledControls(false)
      }
      if (!isControls) {
        setPaused(true)
      }
    } else {
      if (Platform.OS === "ios" && isControls) {
        setHandledControls(true)
      }
      if (!isControls) {
        setPaused(control?.paused || false)
      }
    }
  }, [appState, isFocused, control?.paused, isControls])

  const s = useMemo(
    () => ({
      video: {
        width,
        height: width / videoRatio,
        maxHeight:
          Platform.OS !== "web"
            ? height - (hasExtraHeader ? headerHeight + progressBarHeight : 0)
            : undefined,
      },
      loading: [
        styles.loading,
        {
          padding: spacing,
          height: width / VIDEO_RATIO,
        },
      ],
      indicator: {
        marginTop: spacing * 2,
      },
      fullScreenPrincipal: {
        left: isFullScreenPrincipal ? 40 : 10,
        bottom: isFullScreenPrincipal ? 20 : 10,
      },
    }),
    [
      hasExtraHeader,
      headerHeight,
      height,
      progressBarHeight,
      videoRatio,
      width,
      spacing,
      isFullScreenPrincipal,
    ],
  )

  const onLoad = useCallback(
    (data: OnLoadData) => {
      setDuration(data.duration)
      forwardRef?.current?.seek(startPosition)
    },
    [forwardRef, startPosition],
  )

  const onProgress = useCallback(({ playableDuration, currentTime }: OnProgressData) => {
    setElapsedTime(currentTime)
    setProgress((currentTime / playableDuration) * 100)
  }, [])

  useEffect(() => {
    if (Platform.OS === "web") {
      if (control) {
        setPaused(control.paused)
      }
    }
  }, [control])

  const onReadyForDisplay = useCallback(() => {
    setIsLoading(false)
  }, [setIsLoading])

  const onLoadStart = useCallback(() => {
    setIsLoading(true)
  }, [setIsLoading])

  const onTouchStart = useCallback(() => {
    if (!isControls) {
      setPaused(p => !p)
    }
  }, [setPaused, isControls])

  const lockOrientationAndroid = isFullScreen => {
    if (isFullScreen) {
      Orientation.lockToLandscape()
    } else {
      Orientation.lockToPortrait()
    }
  }

  const lockOrientationiOS = isFullScreen => {
    if (isFullScreen) {
      OrientationManager.lockToLandscape()
    } else {
      OrientationManager.lockToPortrait()
    }
  }

  const toggleFullScreenPrincipal = () => {
    if (!enableFullScreen || !setIsFullScreenPrincipal) {
      return
    }

    const newIsFullScreenPrincipal = !isFullScreenPrincipal
    setIsFullScreenPrincipal(newIsFullScreenPrincipal)

    if (Platform.OS === "android") {
      lockOrientationAndroid(newIsFullScreenPrincipal)
    } else if (Platform.OS === "ios") {
      lockOrientationiOS(newIsFullScreenPrincipal)
    }
  }

  return (
    <>
      <View {...{ style, onLayout }}>
        {Platform.OS !== "web" ? (
          <>
            <View onTouchStart={onVideoPress || (isControls ? undefined : onTouchStart)}>
              <Video
                {...{ onLoad, onLoadStart, onReadyForDisplay }}
                style={isFullScreenPrincipal ? styles.fullScreenVideo : styles.video}
                ref={forwardRef}
                controls={handledControls ? true : false}
                fullscreen={true}
                source={{ uri }}
                resizeMode={ResizeMode.CONTAIN}
                onEnd={control?.onEnd}
                onError={control?.onError}
                paused={control?.paused || paused}
                onProgress={alternativeProgress ? undefined : onProgress}
              />
            </View>
            {enableFullScreen && (
              <TouchableOpacity
                onPress={toggleFullScreenPrincipal}
                style={[styles.fullScreenButton, s.fullScreenPrincipal]}
              >
                <Icon name="fullscreen" size={30} color={primary} />
              </TouchableOpacity>
            )}
            {!isControls && icon && controlledPause === true && (control?.paused || paused) ? (
              <Touchable onPress={onVideoPress || onTouchStart} style={styles.icon}>
                <VectorIcon {...icon} />
              </Touchable>
            ) : null}
            {renderDuration?.(elapsedTime, duration)}
          </>
        ) : (
          <TouchableOpacity activeOpacity={1} onPress={onTouchStart}>
            <View onTouchStart={onTouchStart}>
              <Video
                {...{ onLoadStart, onReadyForDisplay }}
                resizeMode="cover"
                controls={true}
                source={{ uri }}
                paused={paused}
              />
            </View>
          </TouchableOpacity>
        )}
      </View>
      {isLoading ? (
        <View style={s.loading}>
          <ActivityIndicator style={s.indicator} size="large" />
        </View>
      ) : null}
      {showProgress ? (
        <View style={styles.innerView}>
          <View>
            <ProgressBar
              height={8}
              trackColor={primary}
              backgroundColor={white}
              paused={control?.paused || paused}
              progress={alternativeProgress ?? progress}
            />
          </View>
        </View>
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  video: {
    alignSelf: "stretch",
    width: "100%",
    aspectRatio: 16 / 9,
  },
  fullScreenVideo: {
    width: "100%",
    height: "100%",
    alignSelf: "stretch",
  },
  innerView: {
    width: "100%",
  },
  icon: {
    top: "40%",
    alignSelf: "center",
    position: "absolute",
  },
  loading: {
    width: "100%",
    justifyContent: "center",
    alignSelf: "center",
    position: "absolute",
  },
  fullScreenButton: {
    position: "absolute",
  },
})

export default VideoPlayer
