import React, { CSSProperties, PropsWithChildren } from "react"

import { logger } from "../../utils/logger"

const styles = {
  container: {
    display: "flex",
    cursor: "pointer",
  },
  input: {
    border: 0,
    width: 0,
    height: 0,
    visibility: "hidden",
    display: "none",
  } as CSSProperties,
}

export interface DataRes {
  data: string | ArrayBuffer
  name?: string
  err?: any
}

interface IProps {
  multiple?: boolean
  style?: CSSProperties
  onFail?: (ret: any) => void
  onComplete: (img: DataRes[]) => void
  accept?: string // can be `image/*` or more complex type https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#unique_file_type_specifiers
}

const ACCEPT_IMAGE = "image/*"

const readAsDataURL = (file: Blob, accept: string): Promise<DataRes> =>
  new Promise((resolve, reject) => {
    if (accept === ACCEPT_IMAGE && !/^image\//.test(file.type)) {
      return reject({ data: null, err: "Not an image." })
    }
    const fileReader = new FileReader()
    // @ts-ignore name is not a value but an accessor, the actual object is a File
    const result = { name: file.name }
    fileReader.onload = () => resolve({ ...result, data: fileReader.result })
    fileReader.onerror = err => reject({ ...result, data: null, err })
    fileReader.onabort = err => reject({ ...result, data: null, err })
    fileReader.readAsDataURL(file)
  })

const handleUpload = (
  accept: string,
  onComplete: (img: DataRes[]) => void,
  onFail?: (ret: any) => void,
) => async (event: Event) => {
  // @ts-ignore
  const files: Blob[] = [...event.target.files]

  try {
    const images = await Promise.all(files.map(f => readAsDataURL(f, accept)))
    onComplete(images)
  } catch (e) {
    logger("Couldn't load the images", e)
    onFail?.(e)
  }
}

const WebGalleryPicker = ({
  style,
  onComplete,
  onFail,
  children,
  multiple = false,
  accept = ACCEPT_IMAGE,
}: PropsWithChildren<IProps>) => {
  const containerStyle = { ...styles.container, ...style }

  return (
    // @ts-ignore
    <label onChange={handleUpload(accept, onComplete, onFail)} style={containerStyle}>
      <input
        style={styles.input}
        type="file"
        accept={accept}
        multiple={multiple}
        // prevent click-through to other components
        onClick={event => event.stopPropagation()}
      />
      {children}
    </label>
  )
}

export default WebGalleryPicker
