import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { StackActions } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { firestore, logger } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import { PromsParamList } from "src/screens/PromsScreen/PromsNavigator"

import { FormResult, Interstitial } from "../../common/Proms"
import { collections } from "../../common/types"
import { getUtcDate } from "../../utils/conversion"
import { AppUserData } from "./UserData"

const calculateSumAddedDays = (userData: AppUserData) =>
  userData.addedDays ? Object.values(userData.addedDays).reduce((a, b) => a + b, 0) : 0

export const calculateLimitStartDate = userData => {
  const sumAddedDays = calculateSumAddedDays(userData)
  return dayjs()
    .endOf("day")
    .subtract(21 + sumAddedDays, "day")
    .toDate()
}

export const setOptionalScreen = (item: string) => {
  switch (item) {
    case "mastectomy":
      return 1
    case "tumorectomy":
      return 2
    case "sentinel_node":
      return 3
    case "ax_curing":
      return 4
    case "reconstructive":
      return 5
    default:
      return 6
  }
}

export const getSurgeryType = (item: number) => {
  switch (item) {
    case 1:
      return "mastectomy"
    case 2:
      return "tumorectomy"
    case 3:
      return "sentinel_node"
    case 4:
      return "ax_curing"
    case 5:
      return "reconstructive"
    default:
      return ""
  }
}

export const handleUpdateProgram = async (
  updateType: string,
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
  userData: AppUserData,
  paramId: number,
  values: FormResult,
  onCreateProgram: any,
) => {
  const limitStartDate = calculateLimitStartDate(userData)
  const programsDocs = await userDocRef?.collection(collections.PROGRAMS).get()
  const currentProgram = (
    await userDocRef?.collection(collections.PROGRAMS).doc(userData?.program).get()
  )?.data()
  const validSurgeryDates =
    updateType === "updateSingleSurgery"
      ? validateSurgeryDate(
          values,
          userData,
          values?.operation_type as string[],
          paramId,
          programsDocs?.docs,
        )
      : validateSurgeryDate(values, userData, values?.operation_type as string[])
  if (validSurgeryDates) {
    const newSurgeryDate =
      updateType !== "createSurgeryData"
        ? await findCurrentProgram(
            validSurgeryDates,
            userData,
            userData?.programStart,
            currentProgram,
            updateType,
          )
        : await findCurrentProgram(validSurgeryDates, userData)
    if (updateType === "updateAllSurgeries" || updateType === "updateSingleSurgery") {
      const surgeryTypesArray = validSurgeryDates.map(objet => objet.type)
      if (programsDocs?.docs) {
        programsDocs.docs.map(async item => {
          const validDateType = validSurgeryDates.find(
            validDate => validDate.type === item.data().surgeryType,
          )
          if (
            !_.includes(surgeryTypesArray, item.data().surgeryType) ||
            (validDateType && validDateType.date < limitStartDate)
          ) {
            await userDocRef?.collection(collections.PROGRAMS).doc(item.id).delete()
            await userDocRef?.set(
              {
                addedDays: firestore.FieldValue.delete(),
              },
              { merge: true },
            )
            const isProgToCome = await userDocRef?.collection(collections.PROGRAMS).get()
            if (!isProgToCome?.docs || isProgToCome?.docs?.length === 0) {
              userDocRef?.set(
                {
                  phase: firestore.FieldValue.delete(),
                  programEnd: firestore.FieldValue.delete(),
                  programStart: firestore.FieldValue.delete(),
                  program: firestore.FieldValue.delete(),
                  surgeryDate: firestore.FieldValue.delete(),
                },
                { merge: true },
              )
            }
          }
        })
      }
    }
    const createProgramsSequentially = async () => {
      for (const item of validSurgeryDates) {
        await onCreateProgram(
          item.date,
          item.type,
          newSurgeryDate,
          updateType === "updateSingleSurgery" ? true : undefined,
        )
      }
    }
    createProgramsSequentially()
  } else {
    logger("TODO : Handle no valid surgery date")
  }
}

export const navigateToWelcome = async (
  screenType: Interstitial,
  navigation: StackNavigationProp<PromsParamList, "Proms_Form">,
) => {
  const pushAction = StackActions.push("Proms_Welcome", { type: screenType })
  navigation.dispatch(pushAction)
}

export const navigateToQuestion = async (
  id: number,
  navigation: StackNavigationProp<PromsParamList, "Proms_Form">,
) => {
  const pushAction = StackActions.push("Proms_Form", { id })
  navigation.dispatch(pushAction)
}

export const validateSurgeryDate = (
  surgeryData: FormResult,
  userData: AppUserData,
  operationType: string[],
  isEditId?: number,
  programs?:
    | Array<FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>>
    | undefined,
) => {
  const limitStartDate = calculateLimitStartDate(userData)
  if (!isEditId) {
    const upcomingDates = operationType
      .map((opType: string) => {
        const typeDateProp = `op_${opType}_date`
        const propValue = surgeryData[typeDateProp] as string | number | undefined
        if (propValue !== undefined) {
          const date = new Date(propValue)
          return date >= limitStartDate ? { type: opType, date } : null
        }
        return null
      })
      .filter(upDate => upDate !== null)
    if (upcomingDates) {
      return upcomingDates as Array<{ date: Date; type: string }>
    }
  } else if (isEditId && programs) {
    const surgeryType = getSurgeryType(isEditId)
    const typeDateProp = `op_${surgeryType}_date`
    const propValue = surgeryData[typeDateProp] as string | number | undefined
    if (propValue !== undefined) {
      const date = new Date(propValue)
      const filteredArray = programs.map(program => {
        const programTypeProp = `op_${program.data().surgeryType}_date`
        const progDataValue = userData.medicalInfo?.surgery?.[programTypeProp]
        const programDate = getUtcDate(progDataValue, true)
        if (program.data().surgeryType !== surgeryType) {
          return {
            type: program.data().surgeryType,
            date: programDate,
          }
        } else {
          return {
            type: surgeryType,
            date,
          }
        }
      })
      const isExistingProgram = filteredArray.find(prog => prog.type === surgeryType)
      if (!isExistingProgram) {
        filteredArray.push({
          type: surgeryType,
          date,
        })
      }
      return filteredArray
    }
  }
  return
}

export const findCurrentProgram = async (
  upcomingDates: Array<{ date: Date; type: string }>,
  userData: AppUserData,
  currentProgramStart?: Date | undefined,
  currentProgram?: FirebaseFirestoreTypes.DocumentData | undefined,
  updateSurgeryType?: string | undefined,
) => {
  const limitStartDate = calculateLimitStartDate(userData)
  const datesArray = upcomingDates.map(objet => objet.date)
  const updateSingleSurgery = updateSurgeryType === "updateSingleSurgery"
  const updateAllSurgeries = updateSurgeryType === "updateAllSurgeries"
  const currentProgramType = currentProgram?.surgeryType
  const isCurrentProgram = upcomingDates.find(item => item.type === currentProgramType)
  const updatedProgramDate = isCurrentProgram?.date
  const validDatesArray = updateSingleSurgery
    ? datesArray.filter((date: Date) => date >= limitStartDate)
    : datesArray

  const existingUpcomingDate =
    updatedProgramDate && currentProgramStart
      ? upcomingDates.find(item => dayjs(item.date).isSame(dayjs(currentProgramStart)))
      : undefined

  validDatesArray.sort((a, b) => a.getTime() - b.getTime())

  let closestDate = validDatesArray.length > 0 ? validDatesArray[0] : null

  if (closestDate) {
    const closestDateWithoutTime = new Date(
      closestDate.getFullYear(),
      closestDate.getMonth(),
      closestDate.getDate(),
    )
    const limitStartDateWithoutTime = new Date(
      limitStartDate.getFullYear(),
      limitStartDate.getMonth(),
      limitStartDate.getDate(),
    )
    if (closestDateWithoutTime.getTime() === limitStartDateWithoutTime.getTime()) {
      closestDate = limitStartDate
    }
  }
  if (
    closestDate !== null &&
    (!currentProgramStart ||
      currentProgramStart > closestDate ||
      (currentProgramStart < closestDate && limitStartDate >= currentProgramStart) ||
      (updateAllSurgeries && !existingUpcomingDate) ||
      updateSingleSurgery ||
      (updatedProgramDate && updatedProgramDate === closestDate))
  ) {
    return closestDate
  } else {
    return
  }
}
