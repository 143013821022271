import { RouteProp } from "@react-navigation/core"
import {
  Button,
  EmptyView,
  firestore,
  generateShadow,
  IUserContext,
  openUrl,
  useAlert,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import { useFormikContext } from "formik"
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { ScrollView, StyleSheet } from "react-native"
import { ActivityIndicator } from "react-native-paper"
import { Edge } from "react-native-safe-area-context"

import { images } from "../../../assets/images"
import { FormResult, Interstitial, ReviewUrl } from "../../../common/Proms"
import { collections } from "../../../common/types"
import { RATING_SCORE } from "../../../features/config/Constants"
import { itemField } from "../../../features/hooks/useItemI18n"
import { PromsNS } from "../../../features/i18n/constants"
import { AppUserData } from "../../../features/models/UserData"
import { promsContext } from "../../../features/Providers/PromsProvider"
import { PromsParamList } from "../PromsNavigator"

interface IProps {
  navigation: any
  route: RouteProp<PromsParamList, "Proms_Welcome">
}

const InterstitialScreen: FC<IProps> = ({ navigation, route }) => {
  const {
    typography: { h6 },
    button,
    dimensions: { spacing },
    colors: {
      primary,
      secondary,
      black: { separator, highEmphasis },
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const [review] = useDocumentData<ReviewUrl>(
    userData?.surgeon
      ? ((firestore()
          .collection(collections.REVIEW_URLS)
          .doc(userData.surgeon as string) as unknown) as firebase.firestore.DocumentReference)
      : null,
  )
  const { showSnack } = useAlert()
  const { t } = useTranslation(PromsNS)
  const { type } = route.params
  const isPromsEnd = type === Interstitial.END
  const isPromsDone = type === Interstitial.DONE
  const isPromsWelcome = type === Interstitial.WELCOME
  const isSigninProms = type === Interstitial.SIGNIN
  const { form, loading, error, scores, saveForm, cleanForm } = useContext(promsContext)
  const { resetForm } = useFormikContext<FormResult>()
  const thanks = form?.thanks ?? ""
  const noThanksScore = !scores?.[thanks]
  const isPositive = scores?.[thanks] ? scores[thanks] > 0 : false
  const isPositiveRating =
    type === Interstitial.RATING && (scores?.[RATING_SCORE] ? scores[RATING_SCORE] > 80 : false)

  const continueLabel = isSigninProms
    ? t("common:button.start")
    : isPromsWelcome
    ? t("common:button.continue")
    : t(isPositiveRating && review?.visible ? "share" : "common:button.ended")

  const laterLabel = t(isPromsWelcome ? "later" : "notShare")
  const title = isSigninProms
    ? t("oncoTitle")
    : isPromsWelcome
    ? error || loading
      ? undefined
      : form?.title
    : isPromsEnd
    ? t(
        error || noThanksScore ? "endSubtitle" : "endTitle",
        error || noThanksScore ? undefined : { name: form?.thanks, score: scores?.[thanks] },
      )
    : isPromsDone
    ? t("doneTitle", { title: form?.title ?? "" })
    : t("shareTitle")

  const subtitle = isSigninProms
    ? t("oncoSubtitle1")
    : isPromsDone
    ? undefined
    : isPromsWelcome
    ? error || loading
      ? undefined
      : itemField(form, "description", t)
    : isPromsEnd
    ? error || noThanksScore
      ? undefined
      : t("endSubtitle")
    : itemField(
        form,
        type === Interstitial.RATING
          ? isPositiveRating && review?.visible
            ? "positive"
            : "negative"
          : isPositive
          ? "positive"
          : "negative",
        t,
      )

  useEffect(() => {
    navigation.setOptions({
      title: isPromsWelcome ? form?.title : isPromsEnd ? t("score") : t("satisfaction"),
    })
  }, [form?.title, isPromsEnd, isPromsWelcome, navigation, t])

  useEffect(() => {
    if (error && isPromsWelcome) {
      showSnack({ message: t("error") })
    }
  }, [error, isPromsWelcome, showSnack, t, type])

  const s = useMemo(
    () => ({
      scrollView: {
        backgroundColor: secondary,
      },
      container: [
        {
          backgroundColor: secondary,
          paddingVertical: spacing * 2,
          paddingHorizontal: spacing * 2,
        },
      ],
      title: [
        h6,
        {
          color: white,
          paddingTop: spacing,
        },
      ],
      subtitle: {
        color: white,
        paddingTop: spacing,
      },
      innerContinueStyle: {
        backgroundColor: white,
      },
      continueLabelStyle: {
        color: highEmphasis,
      },
      continueStyle: [
        styles.continue,
        {
          marginVertical: spacing,
        },
        generateShadow(4),
      ],
      innerLaterStyle: [styles.later, { borderColor: separator, borderRadius: button?.roundness }],
      showStyle: {
        marginTop: spacing,
      },
    }),
    [secondary, spacing, h6, white, highEmphasis, separator, button?.roundness],
  )

  const emptyViewProps = useMemo(
    () => ({
      title,
      subtitle,
      titleStyle: s.title,
      viewStyle: s.container,
      subtitleStyle: s.subtitle,
      edges: ["left", "right"] as Edge[],
      image: !loading
        ? isPromsEnd || isPromsDone
          ? (images.proms_end as number)
          : (images.proms_welcome as number)
        : undefined,
    }),
    [isPromsDone, isPromsEnd, loading, s.container, s.subtitle, s.title, subtitle, title],
  )

  const onContinuePress = useCallback(() => {
    if (isPromsWelcome || isSigninProms) {
      resetForm()
      navigation.navigate("Proms_Form", { id: 0 })
      return
    } else if (isPositiveRating && review?.visible && review?.url) {
      // noinspection JSIgnoredPromiseFromCall
      openUrl(review?.url)
    }
    cleanForm()
  }, [
    cleanForm,
    isPositiveRating,
    isPromsWelcome,
    isSigninProms,
    navigation,
    resetForm,
    review?.url,
    review?.visible,
  ])

  const onLaterPress = useCallback(
    (showAgain: boolean) => () => {
      if (isPromsWelcome) {
        saveForm(showAgain)
      }
      cleanForm()
    },
    [cleanForm, isPromsWelcome, saveForm],
  )
  const renderButtons = useMemo(
    () =>
      isPromsWelcome && error ? null : (
        <>
          <Button
            mode="outlined"
            style={s.continueStyle}
            onPress={onContinuePress}
            labelStyle={s.continueLabelStyle}
            contentStyle={s.innerContinueStyle}
          >
            {continueLabel}
          </Button>
          {isPromsEnd || (!isPromsWelcome && (!isPositiveRating || !review?.visible)) ? null : (
            <Button
              mode="text"
              color={white}
              contentStyle={s.innerLaterStyle}
              onPress={onLaterPress(true)}
            >
              {laterLabel}
            </Button>
          )}
          {!isPromsWelcome ? null : (
            <Button
              mode="text"
              color={white}
              style={s.showStyle}
              contentStyle={s.innerLaterStyle}
              onPress={onLaterPress(false)}
            >
              {t("notShow")}
            </Button>
          )}
        </>
      ),
    [
      continueLabel,
      error,
      isPositiveRating,
      isPromsEnd,
      isPromsWelcome,
      laterLabel,
      onContinuePress,
      onLaterPress,
      review?.visible,
      s.continueLabelStyle,
      s.continueStyle,
      s.innerContinueStyle,
      s.innerLaterStyle,
      s.showStyle,
      t,
      white,
    ],
  )

  return form ? (
    <ScrollView style={s.scrollView} contentContainerStyle={styles.contentContainer}>
      <EmptyView {...emptyViewProps}>
        {loading ? <ActivityIndicator size="large" color={primary} /> : renderButtons}
      </EmptyView>
    </ScrollView>
  ) : null
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    justifyContent: "center",
  },
  continue: {
    borderWidth: 0,
  },
  later: { borderWidth: 1 },
})

export default InterstitialScreen
