import { IUserContext, OptimisticSwitch, Subtitle1, userContext, useTheme } from "capsule"
import _ from "lodash"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"

import { Organ } from "../../common/Pathology"
import { INPUT_HEIGHT } from "../../features/config/Constants"
import { itemName } from "../../features/hooks/useItemI18n"
import { NavigationNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"

const SpecialtiesList = ({ isSwitchOn, onToggleSwitch, organsData }) => {
  const { t } = useTranslation(NavigationNS)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)

  const {
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      container: {
        marginBottom: spacing * 2,
      },
      switchContainer: [
        styles.switchContainer,
        {
          marginHorizontal: spacing,
        },
      ],
      spacing: {
        margin: spacing,
      },
      image: [
        styles.image,
        {
          margin: spacing,
          marginRight: spacing,
        },
      ],
    }),
    [spacing],
  )

  return (
    <View style={s.container}>
      {organsData.map((item: Organ) => (
        <View key={item.id} style={s.switchContainer}>
          <View style={styles.organContainer}>
            <FastImage style={s.image} source={{ uri: item.image }} />
            <Subtitle1 style={s.spacing}>{itemName(item, t)}</Subtitle1>
          </View>
          <OptimisticSwitch
            style={s.spacing}
            value={
              userData && _.includes(userData.kineSpecialties, item.id)
                ? true
                : isSwitchOn
                ? isSwitchOn[item.id]
                : null
            }
            onValueChange={val => onToggleSwitch(item.id, val)}
          />
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  switchContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    width: INPUT_HEIGHT,
    height: INPUT_HEIGHT,
  },
  organContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
})

export default SpecialtiesList
