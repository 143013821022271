import React, { FC, useCallback, useContext, useMemo } from "react"
import { FlatList, ListRenderItem, StyleSheet } from "react-native"

import { tabContext, TabType } from "../../features/Providers"
import useTheme from "../../features/Theme/ThemeProvider"
import useLayout from "../../utils/useLayout"
import Tab from "./Tab"

interface IProps {
  tabs: TabType[]
  display?: number // number of tabs to display in the screen
  maxHeight?: number
}

const keyExtractor = (item: TabType) => `${item.index} - ${item.title}`

const TabBar: FC<IProps> = ({ tabs, display, maxHeight = 40 }) => {
  const {
    colors: {
      overrides,
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { tabRef } = useContext(tabContext)
  const { width, onLayout } = useLayout()
  const { style, contentContainerStyle } = useMemo(
    () => ({
      style: {
        maxHeight,
      },
      contentContainerStyle: [
        styles.view,
        {
          backgroundColor: overrides?.tabBar ?? white,
        },
      ],
    }),
    [maxHeight, overrides?.tabBar, white],
  )

  const renderItem: ListRenderItem<TabType> = useCallback(
    ({ item: tab, index }) => (
      <Tab
        key={tab.index}
        {...{ tab, index, width: width && display ? width / display : undefined }}
      />
    ),
    [display, width],
  )

  return (
    <FlatList<TabType>
      data={tabs}
      ref={tabRef}
      horizontal
      showsHorizontalScrollIndicator={false}
      {...{ contentContainerStyle, renderItem, style, keyExtractor, onLayout }}
    />
  )
}

const styles = StyleSheet.create({
  view: {
    justifyContent: "space-between",
  },
})

export default TabBar
