import "dayjs/locale/fr"

import { defaultWeights, ITypographyTheme, logger, Theme, ThemeProvider } from "capsule"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isBetween from "dayjs/plugin/isBetween"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import localeData from "dayjs/plugin/localeData"
import localizedFormat from "dayjs/plugin/localizedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import weekDay from "dayjs/plugin/weekday"
import _ from "lodash"
import numeral from "numeral"
import React, { FC, PropsWithChildren, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { StatusBar, useWindowDimensions } from "react-native"

require("numeral/locales")

export const maxWidth = 480
export const tabSizeLand = 104

const ThemeApp: FC<PropsWithChildren<ReactNode>> = ({ children }) => {
  const { width: windowWidth } = useWindowDimensions()
  const { i18n } = useTranslation()
  const dayjsLocale = _.split(_.toLower(i18n.language), "-")[0]
  logger("dayjsLocale", dayjsLocale)
  dayjs.locale(dayjsLocale)
  dayjs.extend(utc)
  dayjs.extend(localizedFormat)
  dayjs.extend(isBetween)
  dayjs.extend(customParseFormat)
  dayjs.extend(localeData)
  dayjs.extend(weekDay)
  dayjs.extend(timezone)
  dayjs.extend(isSameOrAfter)
  let numeralLocale: string = _.toLower(i18n.language)
  if (!numeral.locales[numeralLocale]) {
    numeralLocale = _.split(numeralLocale, "-")[0]
  }
  logger("numeralLocale", numeralLocale)
  numeral.locale(numeralLocale)

  return (
    <ThemeProvider
      customTheme={{
        button: { mode: "contained", roundness: 8, uppercase: false, height: 36 },
        roundness: 0,
        colors: {
          primary: "#169A9F",
          secondary: "#00AEB5",
          selection: "#C8ECED",
          accent: "#B2E7E9",
          error: "#B00020",
          danger: "#E04242",
          grey: "#EFEFEF",
          transparent: "transparent",
          white: { mediumEmphasis: "rgba(255, 255, 255, 0.74)" },
          black: {
            highEmphasis: "rgba(44, 81, 100, 0.87)",
            mediumEmphasis: "rgba(44, 81, 100, 0.6)",
            disabledButton: "rgba(0, 0, 0, 0.12)",
            disabled: "rgba(33, 33, 33, 0.38)",
            inactive: "#2C516461",
            disabledImages: "rgba(200, 200, 200, 0.4)",
            strong: "#000000",
          },
          surface: {
            appUi: "#FBFBFB",
            background: "#E7F7FE",
            input: "#C0E8FE",
            textInput: "rgba(33, 33, 33, 0.05)",
            shadows: "#BBBBBB",
            card: "#F3F8FE",
            disabled: "#E0E0E0",
            progress: {
              border: "#169A9F",
              background: "#E6E6E6",
            },
          },
          overrides: {
            switch: {
              lineActive: "#169A9F",
              lineInactive: "rgba(44, 81, 100, 0.6)",
            },
            radioButton: {
              checked: "#169A9F",
              unchecked: "rgba(33, 33, 33, 0.08)",
            },
          },
        },
        fonts: {
          default: {
            family: "Montserrat",
            iosFamily: "Montserrat",
            weights: defaultWeights,
          },
        },
        dimensions: {
          marginSides: windowWidth > maxWidth ? 48 : 16,
          isTabLand: ({ width }) => width > maxWidth + tabSizeLand,
          isTabTwoPane: ({ width }) => width > maxWidth * 2 + tabSizeLand,
        },
        /** I have no idea how to resolve this ts issue :
         * TS2322: Type '{ button: { mode: "contained"; roundness: number; uppercase: false; height: number; };
         * roundness: number;
         * colors: { primary: string; secondary: string; selection: string; accent: string; accent: string;
         * ... 6 more ...; surface:
         * { ...; }; }; fonts: { ...; };
         * dimensions: { ...; }; typoGen:
         * ({ fontMaker, }: Omit<...>...'
         * is not assignable to type 'PartialPartialPartial<Theme>'.
         * Object literal may only specify known properties,
         * and 'typoGen' does not exist in type 'PartialPartialPartial<Theme>'. ' +
         *   ' ThemeProvider.d.ts(19, 5): The expected type comes from property 'customTheme'
         *   which is declared here on type 'IntrinsicAttributes &
         *   { children: ReactNode; customTheme: PartialPartialPartial<Theme>; }' */
        // @ts-ignore
        typoGen: ({
          fontMaker,
        }: Omit<Omit<Theme, "typography">, "typoGen">): Partial<ITypographyTheme> => ({
          h6: {
            ...fontMaker({ weight: "Medium" }),
            // @ts-ignore
            lineHeight: null,
            color: "rgba(44, 81, 100, 0.87)",
          },
          button: {
            ...fontMaker({ weight: "Medium" }),
          },
        }),
        screenStyle: {
          maxWidth,
          width: "100%",
          alignSelf: "center",
        },
      }}
    >
      <StatusBar barStyle="dark-content" backgroundColor="#E7F7FE" />
      {children}
    </ThemeProvider>
  )
}

export default ThemeApp
