import { LogBox } from "react-native"

export const ignoreWarnings = () =>
  LogBox.ignoreLogs([
    "Warning: componentWillReceiveProps",
    "Warning: componentWillMount",
    "VirtualizedLists should never be nested",
    "Warning: DatePickerIOS",
    "RCTBridge required dispatch_sync",
    "Required dispatch_sync",
    "VirtualizedLists should never be nested inside plain ScrollViews",
    "Can't perform a React state update on an unmounted component",
    "FlatList: Calling `getNode()`",
    "i18next: init: i18next is already initialized.",
    "Cannot update",
    "Invalid prop `leftIcon` supplied to",
    "Require cycle: node_modules/victory",
  ])
