import { logger } from "capsule"
import { AppRegistry, Platform } from "react-native"

import App from "./App"
import firebaseInit from "./firebaseInit"

logger(firebaseInit)

// the component name doesn't need to change, and it must be the same
// in JS and native.
AppRegistry.registerComponent("app", () => App)
if (Platform.OS === "web") {
  AppRegistry.runApplication("app", {
    // @ts-ignore
    rootTag: document.getElementById("root"),
  })
}
