import React, { FC, useMemo } from "react"
import { StyleSheet, View } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import { Subtitle1 } from "../Texts"

export interface ProgressItem {
  key: string
  label?: string
  percent: number
  hideSeparator?: boolean
}

export interface SeparatorPosition {
  left: number
  top: number
}

interface IProps {
  size: number
  width?: number
  item: ProgressItem
  borderWidth: number
  separator?: SeparatorPosition
  isPatient: boolean
}

const BASE_DEGREE = 135
const HALF_PERCENT = 50

const progressLayerCircle = (percent: number) => {
  const rotatedBy = BASE_DEGREE + percent * 3.6
  return {
    transform: [{ rotateZ: `${rotatedBy}deg` }],
  }
}

/** https://medium.com/@0saurabhgour/react-native-percentage-based-progress-circle-no-external-library-e25b43e83888 */
const ProgressionItem: FC<IProps> = ({
  item: { label, percent, hideSeparator = false },
  size,
  width,
  borderWidth,
  separator,
  isPatient,
}) => {
  const {
    dimensions: { spacing },
    colors: {
      surface: { progress },
      transparent,
    },
  } = useTheme()
  const localPercent = percent > 100 ? 100 : percent < 0 ? 0 : percent
  const isCompleted = localPercent === 100
  const global = useMemo(
    () => ({
      dimensions: {
        borderWidth,
        width: size,
        height: size,
        borderRadius: size / 2,
      },
      style: [styles.circle, styles.layerCircle],
      borderColor: {
        borderLeftColor: transparent,
        borderBottomColor: transparent,
      },
    }),
    [borderWidth, size, transparent],
  )
  // noinspection JSSuspiciousNameCombination
  const s = useMemo(
    () => ({
      item: [
        styles.item,
        {
          width,
          marginHorizontal: spacing / 2,
        },
      ],
      circle: [
        global.dimensions,
        styles.circle,
        {
          borderColor: progress.background,
          backgroundColor: isCompleted ? progress.border : progress.background,
        },
      ],
      firstProgressLayer: [
        global.style,
        global.dimensions,
        global.borderColor,
        {
          borderRightColor: progress.border,
          borderTopColor: progress.border,
        },
        progressLayerCircle(localPercent > HALF_PERCENT ? HALF_PERCENT : localPercent),
      ],
      secondLayer: [
        global.style,
        global.dimensions,
        global.borderColor,
        styles.secondLayer,
        {
          borderRightColor: progress.background,
          borderTopColor: progress.background,
        },
      ],
      subtitle: {
        marginTop: spacing,
      },
      separator: [
        styles.separator,
        {
          height: borderWidth,
          left: separator?.left ?? size + spacing / 2, // add spacing created for the circle view
          top: separator?.top ?? size / 2 - borderWidth, // half of the circle reduce with the borderWidth (bottom separator is the middle)
          backgroundColor: isCompleted
            ? !isPatient
              ? progress.border
              : progress.background
            : progress.background,
        },
      ],
    }),
    [
      width,
      spacing,
      global,
      progress,
      isCompleted,
      localPercent,
      borderWidth,
      separator,
      size,
      isPatient,
    ],
  )

  const SecondProgressLayer = useMemo(
    () => (
      <View
        style={
          localPercent > HALF_PERCENT
            ? [s.firstProgressLayer, progressLayerCircle(localPercent)]
            : s.secondLayer
        }
      />
    ),
    [localPercent, s.secondLayer, s.firstProgressLayer],
  )

  return (
    <View style={s.item}>
      <View style={s.circle}>
        <View style={s.firstProgressLayer} />
        {SecondProgressLayer}
      </View>
      {hideSeparator ? null : <View style={s.separator} />}
      <Subtitle1 style={s.subtitle}>{label}</Subtitle1>
    </View>
  )
}

const styles = StyleSheet.create({
  item: {
    alignItems: "center",
  },
  circle: {
    alignItems: "center",
    justifyContent: "center",
  },
  layerCircle: {
    position: "absolute",
  },
  secondLayer: {
    transform: [{ rotateZ: `${BASE_DEGREE}deg` }],
  },
  separator: {
    zIndex: -1,
    width: "100%",
    position: "absolute",
  },
})

export default ProgressionItem
