import { StackNavigationProp } from "@react-navigation/stack"
import * as React from "react"

import { RootParamList } from "./types"

export const navigationRef = React.createRef<StackNavigationProp<RootParamList>>()

export const navigate = (name, params: any) => navigationRef?.current?.navigate?.(name, params)

export const hideHeader = { headerShown: false }
