import { FormikConfig, FormikValues } from "formik"
import _ from "lodash"
import React, { ReactElement, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, TextStyle, ViewStyle } from "react-native"
import * as Yup from "yup"

import useTheme from "../../features/Theme/ThemeProvider"
import { logger } from "../../utils/logger"
import { FormikTextInput } from "../FormBis"
import { IPaperTextInputProps, TextInput, TextInputProps } from "../TextInput"
import { InputScreen } from "./InputScreen"

interface InputVal {
  name: string
  label: string
  placeholder?: string
}

interface IProps<Values> {
  inputs: Array<InputVal & TextInputProps>
  buttonLabel?: string
  viewStyle?: StyleProp<ViewStyle>
  middleComp?: ReactElement[]
  inputProps?: IPaperTextInputProps
  inputsStyle?: StyleProp<TextStyle>
  formikConfig?: FormikConfig<Values>
}

interface MultipleTextInputFormikConfigParams<Values extends FormikValues>
  extends Omit<FormikConfig<Values>, "initialValues"> {
  values: Values
  errorMessage?: string
}

export const MultipleTextInputFormikConfig = <Values extends FormikValues>({
  values,
  onSubmit,
  errorMessage,
  validationSchema,
  ...rest
}: MultipleTextInputFormikConfigParams<Values>) => ({
  initialValues: values,
  validateOnMount: true,
  validationSchema:
    validationSchema ??
    Yup.object<Values>(
      (_.mapValues(values, () =>
        Yup.string().required(errorMessage),
      ) as unknown) as Yup.ObjectSchemaDefinition<Values>,
    ),
  onSubmit: onSubmit ?? logger,
  ...rest,
})

export const MultipleTextInputScreen = <Values extends FormikValues>({
  inputs,
  inputProps,
  middleComp,
  buttonLabel,
  inputsStyle,
  viewStyle,
  formikConfig,
}: IProps<Values>) => {
  const {
    colors: {
      black: { transparent },
    },
  } = useTheme()
  const { t } = useTranslation()
  const style = useMemo(() => inputsStyle ?? { backgroundColor: transparent }, [
    inputsStyle,
    transparent,
  ])

  const InputElem = !!formikConfig ? FormikTextInput : TextInput

  const Inputs = useMemo(
    () =>
      inputs.map((item, idx) => (
        <InputElem
          key={idx}
          autoFocus={idx === 0}
          changeUnderline={false}
          {...{ style, ...inputProps, ...item }}
        />
      )),
    [InputElem, inputProps, inputs, style],
  )

  return (
    <InputScreen<Values>
      {...{ Inputs, middleComp, formikConfig, viewStyle }}
      buttonLabel={buttonLabel ?? t("button.save")}
    />
  )
}
