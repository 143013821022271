import React, { FC, ReactNode, RefObject } from "react"
import { StyleProp, StyleSheet, ViewStyle } from "react-native"
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from "react-native-keyboard-aware-scroll-view"
import { SafeAreaView } from "react-native-safe-area-context"
import { NativeSafeAreaViewProps } from "react-native-safe-area-context/src/SafeArea.types"

import { KeyboardAvoidView } from "../KeyboardAvoidingView"

export interface IKeyboardProps extends KeyboardAwareScrollViewProps {
  hasOffset?: boolean
  hasNavBar?: boolean
  upperElem?: ReactNode
  innerElem?: ReactNode
  viewStyle?: StyleProp<ViewStyle>
  safeAreaStyle?: StyleProp<ViewStyle>
  edges?: NativeSafeAreaViewProps["edges"]
  forwardRef?: RefObject<KeyboardAwareScrollView>
}

interface IProps extends IKeyboardProps {
  children: ReactNode
}

const KeyboardSafeAreaScrollView: FC<IProps & KeyboardAwareScrollViewProps> = ({
  children,
  hasOffset,
  innerElem,
  upperElem,
  viewStyle,
  forwardRef,
  safeAreaStyle,
  hasNavBar,
  edges,
  extraScrollHeight = 0,
  enableOnAndroid = false,
  ...props
}) => {
  const mainViewStyle = [styles.view, viewStyle]
  return (
    <KeyboardAvoidView hasOffset={hasOffset} hasNavBar={hasNavBar} style={styles.view}>
      <KeyboardAwareScrollView
        style={mainViewStyle}
        ref={forwardRef}
        keyboardOpeningTime={0}
        enableOnAndroid={enableOnAndroid}
        contentInset={styles.keyboardView}
        keyboardShouldPersistTaps="handled"
        extraScrollHeight={extraScrollHeight}
        {...props}
      >
        {upperElem}
        <SafeAreaView edges={edges} style={safeAreaStyle}>
          {children}
        </SafeAreaView>
      </KeyboardAwareScrollView>
      {innerElem}
    </KeyboardAvoidView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  keyboardView: {
    bottom: 0,
  },
})

export default KeyboardSafeAreaScrollView
