import { useTheme, VectorIconProps } from "capsule"
import { useMemo } from "react"

import { ICON_SIZE } from "../config/Constants"

const useRightChild = (color?: string) => {
  const {
    colors: { black },
  } = useTheme()
  return useMemo<VectorIconProps>(
    () => ({
      size: ICON_SIZE,
      name: "chevron-right",
      color: color ?? black.mediumEmphasis,
      category: "MaterialIcons",
    }),
    [black.mediumEmphasis, color],
  )
}

export default useRightChild
