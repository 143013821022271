import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { ISO_8601_DATE } from "capsule"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import _ from "lodash"
import * as Yup from "yup"

import { ID } from "../../common/CommonUserData"
import { AppUserData, UserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { FormikParams } from "../../features/Navigation/FormikScreen"

export interface OperationFormValues {
  surgeryDate?: Date
  pathology: ID
  organ: ID
}

export const defaultOperationFormValues: OperationFormValues = {
  surgeryDate: undefined,
  pathology: "",
  organ: "",
}

export const operationValidationSchema = (t: TFunction, ns: string) =>
  Yup.object({
    surgeryDate: Yup.date().required(t("required.surgeryDate", { ns })),
    pathology: Yup.string().required(t("required.pathology", { ns })),
  })

export const onSubmitOperation = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
) => async (values: OperationFormValues): Promise<any> => {
  const updatedValues: Partial<UserData> = {
    ..._.omit(values, ["surgeryDate"]),
    surgeryDate: dayjs(values.surgeryDate).utc().format(ISO_8601_DATE),
  }
  await updateUserData(userDocRef, updatedValues)
}

export const operationFormikParams = (t: TFunction, ns: string, userData?: Partial<AppUserData>) =>
  ({
    onSubmit: onSubmitOperation,
    initialValues: userData?.pathology
      ? {
          organ: userData?.organ,
          pathology: userData?.pathology,
          surgeryDate: userData?.surgeryDate,
        }
      : defaultOperationFormValues,
    validationSchema: operationValidationSchema(t, ns),
  } as FormikParams<OperationFormValues>)
