import { Body2, Highlighter, Subtitle1, useTheme } from "capsule"
import React, { FC, useContext, useMemo } from "react"
import { StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"

import { faqContext, FaqNor } from "../features/Providers/FaqProvider"

interface IProps {
  faq: {
    item: FaqNor
  }
}

const FaqItem: FC<IProps> = article => {
  const {
    colors: {
      surface: { appUi: surface },
      black: { highEmphasis },
    },
    dimensions: { spacing },
  } = useTheme()

  const { norSearchInput } = useContext(faqContext)

  const s = useMemo(
    () => ({
      section: {
        backgroundColor: surface,
      },
      image: [
        styles.image,
        {
          marginRight: spacing,
          marginTop: spacing / 2,
        },
      ],
      container: [
        styles.container,
        {
          margin: spacing,
        },
      ],
      articleTitle: {
        color: highEmphasis,
      },
      articleDescription: {
        color: highEmphasis,
      },
    }),
    [surface, spacing, highEmphasis],
  )

  return (
    <View style={s.container}>
      {article?.faq?.item?.thumbnail ? (
        <FastImage style={s.image} source={{ uri: article?.faq?.item?.thumbnail }} />
      ) : null}
      <View style={styles.row}>
        <Subtitle1 style={s.articleTitle}>
          <Highlighter text={article?.faq?.item?.title} searchWords={norSearchInput} />
        </Subtitle1>
        <Body2 style={s.articleDescription}>
          <Highlighter text={article?.faq?.item?.bodyTruncated} searchWords={norSearchInput} />
        </Body2>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  image: {
    width: 50,
    height: 50,
  },
  container: {
    flexDirection: "row",
    flexGrow: 0,
  },
  row: {
    flexShrink: 1,
  },
})

export default FaqItem
