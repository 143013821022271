import {
  Body2,
  collections,
  firestore,
  generateShadow,
  Indicator,
  IUserContext,
  PresentationListView,
  SectionSeparator,
  userContext,
  useTheme,
} from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"
import { Searchbar } from "react-native-paper"

import currentDateMs from "../features/hooks/useDateMock"
import { MainNS, PractitionerHomeNS } from "../features/i18n/constants"
import { getFirestoreDate } from "../features/models/Types"
import { AppUserData, Patient } from "../features/models/UserData"
import PatientsListItem from "./PatientsListItem"

const keyExtractor = (item: AppUserData, index: number) => `${index}`
const normalizedText = (text: string) => _.deburr(_.toLower(text))

const filterPatients = (patients: Patient[] | undefined, searchInput: string): Patient[] => {
  const normalizedSearchInput = normalizedText(searchInput)
  const currentDate = dayjs(currentDateMs()).startOf("day")
  const activePatients = patients?.filter(
    item =>
      item.programEnd &&
      (currentDate.isBefore(getFirestoreDate(item.programEnd)) ||
        currentDate.isSame(getFirestoreDate(item.programEnd))) &&
      !item.archived,
  )
  return !normalizedSearchInput.trim() || !activePatients
    ? activePatients ?? []
    : activePatients.filter(
        item =>
          normalizedText(item.firstName).includes(normalizedSearchInput) ||
          normalizedText(item.lastName).includes(normalizedSearchInput),
      )
}

interface IProps {
  organ?: string
}

const PatientsList: FC<IProps> = ({ organ }) => {
  const [searchInput, setSearchInput] = useState("")
  const {
    colors: {
      primary,
      surface: { card },
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
    typography: { caption },
  } = useTheme()
  const { t } = useTranslation()
  const { user } = useContext<IUserContext<AppUserData>>(userContext)
  const baseQuery = firestore()
    .collection(collections.LOGIN)
    .where("medicalTeam", "array-contains", user?.uid ?? [])
    .orderBy("surgeryDate", "desc")
  const [firestorePatients, loading] = useCollectionData<Patient>(
    ((organ
      ? baseQuery.where("organ", "==", organ)
      : baseQuery) as unknown) as firebase.firestore.Query<AppUserData>,
    { idField: "id" },
  )

  const s = useMemo(
    () => ({
      icon: [styles.icon, { backgroundColor: white }],
      indicator: { paddingTop: spacing * 2 },
      list: [
        styles.shadow,
        {
          backgroundColor: card,
        },
      ],
      searchBar: [styles.searchBar, { marginHorizontal: spacing }],
      input: {
        fontSize: caption.fontSize,
      },
      view: [
        styles.shadow,
        {
          marginBottom: spacing,
        },
      ],
      empty: [
        styles.empty,
        {
          padding: spacing,
          backgroundColor: white,
        },
      ],
    }),
    [card, spacing, white, caption],
  )

  const filteredPatients = useMemo(
    () => (!firestorePatients ? undefined : filterPatients(firestorePatients, searchInput)),
    [firestorePatients, searchInput],
  )

  const renderItem: ListRenderItem<Patient> = useCallback(
    ({ item }) => <PatientsListItem patient={item} />,
    [],
  )

  const childInfo = useMemo(
    () => (
      <Body2 emphasis="high" style={s.empty}>
        {t(`${PractitionerHomeNS}:empty`)}
      </Body2>
    ),
    [s.empty, t],
  )
  const indicator = useMemo<Indicator>(() => ({ size: "large", color: primary }), [primary])

  return (
    <View style={styles.listContainer}>
      <View style={s.icon}>
        <Searchbar
          inputStyle={s.input}
          style={s.searchBar}
          onChangeText={setSearchInput}
          value={searchInput}
          placeholder={t(`${MainNS}:header.searchByName`)}
        />
      </View>
      <PresentationListView
        isEmpty={_.isEmpty(filteredPatients)}
        {...{ childInfo, indicator, loading }}
      >
        <SectionSeparator />
        <View style={s.view}>
          <SectionSeparator />
          <FlatList
            style={s.list}
            data={filteredPatients}
            scrollEnabled={true}
            {...{ renderItem, keyExtractor }}
            ItemSeparatorComponent={SectionSeparator}
          />
        </View>
      </PresentationListView>
    </View>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    flex: 1,
    overflow: "scroll",
  },
  clearIconContainer: {
    justifyContent: "center",
  },
  empty: {
    textAlign: "center",
  },
  icon: {
    display: "flex",
    alignItems: "flex-start",
  },
  searchBar: {
    elevation: 0,
    width: "90%",
    height: 44,
    borderRadius: 8,
  },
  shadow: generateShadow(1),
})

export default PatientsList
