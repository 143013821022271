import React, { FC } from "react"
import {
  Image,
  ImageStyle,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"

interface IProps {
  onPress: () => void
  image: any
  text: string
  buttonStyle?: StyleProp<ViewStyle>
  imageStyle?: StyleProp<ImageStyle>
  textStyle?: StyleProp<TextStyle>
}

const TouchableElem: FC<IProps> = ({
  onPress,
  image,
  text,
  buttonStyle,
  imageStyle,
  textStyle,
}) => {
  const {
    dimensions: { spacing },
    typography: { subtitle1 },
    colors: {
      black: { highEmphasis: black },
    },
  } = useTheme()
  const touchableOpacityStyle = [styles.buttonContent, { marginVertical: spacing }, buttonStyle]
  const csImageStyle = [
    {
      marginLeft: spacing,
      marginRight: spacing * 2,
    },
    imageStyle,
  ]
  const csTextStyle = [subtitle1, { color: black }, textStyle]

  return (
    <TouchableOpacity style={touchableOpacityStyle} onPress={onPress}>
      <Image source={image} style={csImageStyle} />
      <Text style={csTextStyle}>{text}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  buttonContent: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
  },
})

export default TouchableElem
