import { ID } from "../../common/CommonUserData"
import { Timestamp } from "./Types"

// eslint-disable-next-line no-shadow
export enum EvalType {
  EVENING = "evening",
  MORNING = "morning",
  EXERCISE = "exercise",
}

export interface EvalFormValues {
  phase?: ID
  pain?: string
  start?: Timestamp
  fatigue?: string
  stiffness?: "yes" | "no"
  effort?: string
}

export interface ExerciseEvalFormValues {
  validation: "yes" | "no"
}
