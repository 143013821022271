import { FirebaseAuthTypes } from "@react-native-firebase/auth"
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import _ from "lodash"

import { ExerciseId } from "../common/CommonUserData"
import { Eel, Exercise, Phase } from "../common/Phase"
import { FirestoreSession } from "../features/models/Session"
import { AppUserData } from "../features/models/UserData"

const collections = {
  LOGIN: "login",
  PHASES: "phases",
  EELS: "eels",
  EXERCISES: "exercises",
  SESSIONS: "sessions",
}

/** Retrieve current phase of the patient */
const getCurrentUserPhase = (database: FirebaseFirestoreTypes.Module) => async (
  userData: Pick<AppUserData, "phase">,
): Promise<Phase | undefined> => {
  const { phase } = _.pick(userData, "phase")
  return phase
    ? ((await database.collection(collections.PHASES).doc(phase).get()).data() as Phase)
    : undefined
}

/** Retrieve all previous sessions based on the current program and specialty or not: callbackToGetProgramSessions  */
const getProgramSessions = (database: FirebaseFirestoreTypes.Module) => async (
  userData: Pick<AppUserData, "program" | "specialty">,
  firestoreUser: FirebaseAuthTypes.User | null,
): Promise<FirestoreSession[]> => {
  const { uid } = _.pick(firestoreUser, "uid")
  const { program, specialty } = _.pick(userData, ["program", "specialty"])
  let sessionDocuments
  if (specialty === "onco") {
    sessionDocuments = (
      await database
        ?.collection(collections.LOGIN)
        .doc(uid)
        .collection(collections.SESSIONS)
        .where("program", "==", program)
        .orderBy("start", "desc")
        .get()
    )?.docs
  } else {
    sessionDocuments = (
      await database
        ?.collection(collections.LOGIN)
        .doc(uid)
        .collection(collections.SESSIONS)
        .orderBy("start", "desc")
        .get()
    )?.docs
  }
  return _.map(sessionDocuments, doc => doc.data() as FirestoreSession)
}

/** Retrieve all phase static exercises based on the phase inside each exercise (old version)
 * or based on the Array<ExerciseIds> of the phase (new version)
 * : callbackToGetPhaseExercises*/
const getPhaseStaticExercises = (database: FirebaseFirestoreTypes.Module) => async (
  userData: Pick<AppUserData, "phase">,
): Promise<Record<ExerciseId, Exercise>> => {
  const { phase } = _.pick(userData, "phase")
  const currentPhase = await getCurrentUserPhase(database)?.(userData)
  if (!currentPhase?.exercises) {
    const exerciseDocuments = (
      await database
        .collection(collections.EXERCISES)
        .where("intensity", "==", 2)
        .where("disabled", "==", false)
        .where("phase", "==", phase)
        .orderBy("order", "asc")
        .get()
    ).docs
    return _.reduce(
      exerciseDocuments,
      (acc, doc) => ({ ...acc, [doc.id]: doc.data() as Exercise }),
      {},
    )
  }
  const exercises = await Promise.all(
    _.map(
      currentPhase.exercises,
      async id =>
        (await database.collection(collections.EXERCISES).doc(id).get()).data() as Exercise,
    ),
  )
  return _.keyBy(exercises, "id")
}

/** Retrieve all exercises from eel document link to the user's pathology : callbackToGetEelByIds*/
const getEelByIds = (database: FirebaseFirestoreTypes.Module) => async (
  userData: Pick<AppUserData, "pathology">,
): Promise<Record<ExerciseId, Exercise>> => {
  const { pathology } = _.pick(userData, "pathology")
  const pathologyEelDocuments = (
    await database.collection(collections.EELS).where("pathology", "==", pathology).get()
  ).docs
  if (pathologyEelDocuments.length === 0) {
    return {}
  }
  const pathologyEelDoc = pathologyEelDocuments?.[0].data() as Eel
  const exercises = await Promise.all(
    _.map(
      pathologyEelDoc.exercises,
      async (exId: ExerciseId) =>
        (await database.collection(collections.EXERCISES).doc(exId).get()).data() as Exercise,
    ),
  )
  return _.keyBy(exercises, "id")
}

export { getCurrentUserPhase, getProgramSessions, getPhaseStaticExercises, getEelByIds }
