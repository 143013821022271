import _ from "lodash"
import React, { FC, ReactElement } from "react"
import { Platform, TextInput as RNTextInput, View, ViewProps } from "react-native"

import { FormikTextInput, IFormikTextInputProps, InputContainerStyle } from "../FormBis"
import { PaperPasswordInput } from "../PaperPasswordInput"
import { PhoneNumberInput } from "../PhoneNumberInput"
import { IPaperTextInputProps, TextInput, TextInputProps } from "../TextInput"

interface IProps {
  blurOnSubmit?: boolean
  styles?: InputContainerStyle
  children: Array<ReactElement | null>
}

const focusableElements = {
  TextInput,
  PhoneNumberInput,
  FormikTextInput,
  PaperPasswordInput,
}

type Children = IPaperTextInputProps & TextInputProps & IFormikTextInputProps

/**
 * Purpose:
 * - Encapsulate A form to provide a focus for each focusableElements.
 * - Create and add input ref on every focusableElements,
 * - Apply auto scroll by focus the next input ref when submit value of the previous one
 *
 * Update Form to pass child styles and form style to its input children
 * If there are inline children inside the view, the create inputRef will not work, because we must create
 * a second Form to control the style. The solution should be to go deeper inside children if there are
 * Views or other components to check if they contain input children
 * */
const InputFocusProvider: FC<IProps & ViewProps> = ({
  children: formChildren,
  styles,
  blurOnSubmit,
  ...props
}) => {
  const renderChildren = (children: Array<ReactElement<Children> | null>): any => {
    const inputRefs = _.map(
      // @ts-expect-error
      _.filter(children, elem => _.has(focusableElements, _.get(elem?.type, "name"))),
      () => React.createRef<RNTextInput>(),
    )
    let inputIndex = 0
    return React.Children.map(children, child => {
      if (child !== null && _.has(focusableElements, `${_.get(child?.type, "name")}`)) {
        const realIndex = inputIndex + 1
        return React.cloneElement(child, {
          ...child?.props,
          styles,
          forwardRef: inputRefs[inputIndex],
          returnKeyType: "next",
          autoCorrect: false,
          blurOnSubmit: Platform.OS === "ios" ? inputIndex === inputRefs.length - 1 : blurOnSubmit,
          onSubmitEditing: () => {
            // @ts-ignore
            child?.props.onSubmitEditing?.()
            inputRefs[realIndex]?.current?.focus()
          },
          key: inputIndex++,
        })
      }
      return child
    })
  }

  return <View {...props}>{renderChildren(formChildren)}</View>
}

export default InputFocusProvider
