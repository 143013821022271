import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  Body2,
  BottomButtonContainer,
  Button,
  generateShadow,
  H6,
  openUrl,
  OptimisticSwitch,
  Subtitle1,
  userContext,
  useTheme,
} from "capsule"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Platform, ScrollView, StyleSheet, View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { Edge } from "react-native-safe-area-context/lib/typescript/src/SafeArea.types"
import { OnboardingParamList } from "src/features/Navigation/types"

import { urls } from "../../features/config/Constants"
import { CguNS } from "../../features/i18n/constants"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { roleContext } from "../../features/Providers/RoleProvider"

interface IProps {
  isCgu?: boolean
  isAccount?: boolean
}

const CguScreen: FC<IProps> = ({ isCgu = false, isAccount = false }) => {
  const {
    fontMaker,
    colors: {
      primary,
      overrides,
      accent,
      surface: { appUi, disabled, background },
      black: { highEmphasis: black },
    },
    dimensions: { spacing },
  } = useTheme()
  const navigation = useNavigation<StackNavigationProp<OnboardingParamList, "Cgu">>()
  const { role, researchAccepted, setResearchAccepted } = useContext(roleContext)
  const { userDocRef, userData } = useContext(userContext)
  const { t } = useTranslation(CguNS)
  const i18nField = role === "patient" ? "patient" : "practitioner"
  const edges: Edge[] = isCgu ? ["left", "right", "top"] : ["left", "right"]

  const s = useMemo(
    () => ({
      safe: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      upperView: [styles.view, { flexDirection: isAccount ? "column-reverse" : "column" }],
      scrollView: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      contentContainerStyle: {
        padding: spacing,
      },
      buttonContainer: [
        styles.buttonContainer,
        {
          paddingTop: spacing / 2,
          paddingHorizontal: spacing / 2,
          height: isCgu ? 120 : undefined,
        },
        generateShadow(2),
      ],
      button: [styles.view, { margin: spacing / 2 }],
      acceptButton: [styles.view, { margin: spacing / 2, paddingRight: spacing }],
      label: { color: primary },
      title: {
        paddingBottom: spacing / 2,
      },
      bold: fontMaker({ weight: "Bold" }),
      switch: {
        style: {
          marginLeft: spacing,
        },
        trackColor: {
          true: accent,
          false: overrides?.switch?.lineInactive ?? black,
        },
        thumbColor: userData?.researchAccepted ?? researchAccepted ? primary : black,
      },
      switchContainer: [
        styles.switchContainer,
        {
          padding: spacing,
          backgroundColor: appUi,
        },
      ],
      switchLabel: [
        styles.view,
        {
          paddingRight: spacing,
        },
      ],
      separator: [
        styles.separator,
        {
          backgroundColor: disabled,
        },
      ],
    }),
    [
      userData,
      appUi,
      spacing,
      isAccount,
      isCgu,
      primary,
      fontMaker,
      overrides?.switch?.lineInactive,
      black,
      researchAccepted,
      disabled,
      background,
      accent,
    ],
  )

  const onRefuse = useCallback(() => navigation.goBack(), [navigation])

  const onAccept = useCallback(() => {
    if (isAccount && !isCgu) {
      navigation.goBack()
      return
    }
    navigation.navigate(role === "patient" ? (isCgu ? "ResearchData" : "Phone") : "Practitioner")
  }, [isAccount, isCgu, navigation, role])

  const onPress = useCallback(() => openUrl(urls.TERMS_OF_USE), [])

  const onValueChange = useCallback(
    async val => {
      if (isAccount) {
        updateUserData(userDocRef, { researchAccepted: val })
        return
      }
      setResearchAccepted?.(val)
    },
    [isAccount, setResearchAccepted, userDocRef],
  )

  const buttonProps = useMemo(
    () => ({
      labelStyle: s.label,
      style: s.button,
    }),
    [s],
  )

  return (
    <SafeAreaView style={s.safe} {...{ edges }}>
      {/* @ts-ignore */}
      <View style={s.upperView}>
        <ScrollView style={s.scrollView} contentContainerStyle={s.contentContainerStyle}>
          {isCgu ? <H6 style={s.title}>{t(`title.${i18nField}`)}</H6> : null}
          <Body2>
            <Trans
              i18nKey={`${CguNS}:${isCgu ? `resume.${i18nField}` : "research"}`}
              // @ts-ignore
              components={{ p: <Body2 />, b: <Body2 style={s.bold} /> }}
            />
          </Body2>
        </ScrollView>
        {isCgu ? null : (
          <>
            {isAccount ? <View style={s.separator} /> : null}
            <View style={s.switchContainer}>
              <Subtitle1 style={s.switchLabel}>{t("researchLabel")}</Subtitle1>
              <OptimisticSwitch
                ms={250}
                {...s.switch}
                {...{ onValueChange }}
                value={userData?.researchAccepted ?? researchAccepted}
              />
            </View>
          </>
        )}
      </View>
      <BottomButtonContainer edges={["bottom"]} style={s.buttonContainer}>
        <View style={styles.doubleButton}>
          {isCgu ? (
            <Button mode="outlined" {...buttonProps} onPress={onRefuse}>
              {t("refuse")}
            </Button>
          ) : null}
          <Button
            style={Platform.OS === "web" ? s.acceptButton : s.button}
            contentStyle={Platform.OS === "web" ? styles.buttonWidth : undefined}
            onPress={onAccept}
          >
            {t(isCgu ? "accept" : "common:button.continue")}
          </Button>
        </View>
        {isCgu ? (
          <View style={styles.view}>
            <Button mode="text" {...buttonProps} {...{ onPress }}>
              {t("more")}
            </Button>
          </View>
        ) : null}
      </BottomButtonContainer>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  doubleButton: {
    flexDirection: "row",
  },
  buttonContainer: {
    width: "100%",
    flexDirection: "column",
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  separator: {
    height: 1,
  },
  buttonWidth: {
    width: 160,
  },
})

export default CguScreen
