import _ from "lodash"
import { Alert } from "react-native"

import { logger } from "./logger"

export type RESULTS = "unavailable" | "denied" | "blocked" | "granted" | "limited"

export const askNativePermissions = async (
  permissions: string[],
  title: string,
  message: string,
  accept: string,
  cancel: string,
): Promise<RESULTS | undefined> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Permissions = require("react-native-permissions")
    if (Permissions) {
      const checkResults = await Permissions.requestMultiple(permissions)
      logger("permissions check results", checkResults)
      const rejectedPermission = _.find(
        checkResults,
        result => result !== "granted" && result !== "limited",
      )
      if (!rejectedPermission) {
        return "granted"
      } else {
        Alert.alert(
          title,
          message,
          [
            {
              text: cancel,
              style: "cancel",
            },
            { text: accept, onPress: Permissions.openSettings },
          ],
          { cancelable: false },
        )
      }
    }
  } catch (e) {
    logger("ask native permission error", e)
  }
  return undefined
}

export const askNativePermission = async (
  permission: string,
  title: string,
  message: string,
  accept: string,
  cancel: string,
): Promise<RESULTS | undefined> =>
  askNativePermissions([permission], title, message, accept, cancel)
