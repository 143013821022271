import { useTheme } from "capsule"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Dimensions, Platform, StyleSheet, View } from "react-native"
import Svg from "react-native-svg"
import { VictoryLabel, VictoryPie } from "victory-native"

import { JourneyNS } from "../features/i18n/constants"

const CircleProgressComponent = ({ totalDays, daysDone, isPatient, isPreviousPhase }) => {
  const {
    colors: {
      accent,
      secondary,
      primary,
      black: { disabledButton },
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { t } = useTranslation(JourneyNS)
  const isWeb = Platform.OS === "web"
  const screenWidth = Dimensions.get("window").width
  const isSmallScreen = screenWidth < 380
  const size = isWeb ? 170 : isSmallScreen ? screenWidth * 0.42 : screenWidth * 0.4
  const radius = size / 2
  const innerRadius = isWeb ? 30 : isSmallScreen ? radius * 0.42 : radius * 0.45
  const data = [{ y: daysDone }, { y: totalDays - daysDone }]
  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          top: isPatient || !isPreviousPhase ? 10 : 50,
          left: 10,
        },
      ],
      webContainer: [
        styles.webContainer,
        {
          top: 10,
          left: 50,
        },
      ],
    }),
    [isPatient, isPreviousPhase],
  )

  return (
    <View style={isWeb ? s.webContainer : s.container}>
      <Svg width={size} height={size}>
        <VictoryPie
          standalone={false}
          width={size}
          height={size}
          data={data}
          innerRadius={innerRadius}
          startAngle={0}
          endAngle={360}
          cornerRadius={radius * 0.1}
          colorScale={
            isPatient ? [`${primary}`, `${disabledButton}`] : [`${accent}`, `${secondary}`]
          }
          labels={() => null}
        />
        <VictoryLabel
          text={[
            `${daysDone}/${totalDays}`,
            `${totalDays === 1 ? t("exercicesView.totalDay") : t("exercicesView.totalDays")}`,
          ]}
          x={radius}
          y={radius}
          textAnchor="middle"
          verticalAnchor="middle"
          style={[
            {
              fontSize: isSmallScreen ? radius * 0.19 : radius * 0.2,
              fill: !isPatient ? white : undefined,
            },
            {
              fontSize: isSmallScreen ? radius * 0.19 : radius * 0.2,
              fill: !isPatient ? white : undefined,
            },
          ]}
        />
      </Svg>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: 75,
    height: 75,
    justifyContent: "center",
    alignItems: "center",
  },
  webContainer: {
    position: "absolute",
    width: 75,
    height: 150,
    justifyContent: "center",
    alignItems: "center",
  },
})

export default CircleProgressComponent
