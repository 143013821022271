import { Body2, HeaderBackButton, SectionSeparator, useTheme, VectorIconProps } from "capsule"
import React, { FC, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { ProgressBar as PaperProgressBar } from "react-native-paper"

import { PromsNS } from "../features/i18n/constants"
import { promsContext } from "../features/Providers/PromsProvider"

interface IProps {
  index: number
  isRating?: boolean
  onPress: () => void
  signInForm?: boolean
  isEditForm?: boolean
}

const PromsProgressionHeader: FC<IProps> = ({
  index,
  signInForm,
  isEditForm,
  onPress,
  isRating = false,
}) => {
  const { form } = useContext(promsContext)
  const { t } = useTranslation(PromsNS)

  const {
    colors: {
      primary,
      black: { highEmphasis: black },
      surface: { background },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      headerRight: {
        paddingHorizontal: spacing,
      },
      mainContainer: [
        styles.mainContainer,
        {
          backgroundColor: background,
        },
      ],
      progressContainer: [styles.progressBarContainer, { paddingLeft: spacing }],
    }),
    [spacing, background],
  )
  const current = index + 1
  const total = form?.questions?.length
  const progress = useMemo(() => current / (total ?? 1), [current, total])
  const vectorIcon = useMemo<VectorIconProps>(
    () => ({ name: isRating ? "close" : "arrow-back", category: "MaterialIcons", color: black }),
    [black, isRating],
  )

  return (
    <>
      <View style={s.mainContainer}>
        {!isEditForm && signInForm === true && index === 0 ? (
          <></>
        ) : (
          <HeaderBackButton
            labelVisible={false}
            style={styles.headerLeft}
            {...{ onPress, vectorIcon }}
          />
        )}
        {isRating || signInForm === true ? null : (
          <>
            <View style={s.progressContainer}>
              <PaperProgressBar color={primary} {...{ progress }} style={styles.progressBar} />
            </View>
            <Body2 style={s.headerRight}>
              {t("step", { current: `${current}`, total: `${total}` })}
            </Body2>
          </>
        )}
      </View>
      {isRating ? null : <SectionSeparator />}
    </>
  )
}
const styles = StyleSheet.create({
  mainContainer: {
    height: 53,
    flexDirection: "row",
    alignItems: "center",
  },
  progressBarContainer: {
    width: "100%",
    flexShrink: 1,
  },
  progressBar: {
    height: 8,
    borderRadius: 8,
  },
  headerLeft: {
    justifyContent: "center",
  },
})

export default PromsProgressionHeader
