import { SectionSeparator, useTheme } from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"

import { Faq } from "../../common/Faq"
import { ItemList } from "../../common/List"
import AccordionSection from "../../components/AccordionSection"
import ListItem from "../../components/ListItem"

export const renderSeparator = () => <SectionSeparator />

interface IProps<T> {
  listIds?: T[]
  typeItem: string
}

const PhaseListView: FC<IProps<ItemList | Faq>> = ({ listIds, typeItem }) => {
  const containerProps = useMemo(
    () => ({
      leftIcon: typeItem === "todo" ? "check-circle" : "question-answer",
      title: typeItem,
    }),
    [typeItem],
  )
  const {
    dimensions: { spacing },
    colors: {
      black: { separator },
      white: { highEmphasis: white },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      view: [
        {
          backgroundColor: white,
        },
      ],
      item: {
        marginVertical: spacing,
      },
      separator: [
        styles.separator,
        {
          backgroundColor: separator,
        },
      ],
      body: [styles.body, { paddingVertical: spacing }],
    }),
    [separator, spacing, white],
  )

  const keyExtractor = (item: ItemList | Faq) => item.id

  const renderItem: ListRenderItem<ItemList | Faq> = useCallback(
    ({ item }) => (_.isUndefined(item) ? null : <ListItem item={item as ItemList} />),
    [],
  )

  const displayList = (data: Array<ItemList | Faq>) => (
    <AccordionSection {...containerProps} expandList={false}>
      <FlatList
        style={s.view}
        {...{ keyExtractor, renderItem }}
        data={data}
        ListFooterComponent={renderSeparator}
        ListHeaderComponent={renderSeparator}
        ItemSeparatorComponent={renderSeparator}
      />
    </AccordionSection>
  )

  return <View>{listIds && listIds.length > 0 ? displayList(listIds) : null}</View>
}

const styles = StyleSheet.create({
  separator: {
    height: 1,
  },
  body: {
    alignSelf: "center",
  },
})

export default PhaseListView
