import _ from "lodash"
import React, { FC, useEffect, useMemo } from "react"
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"

interface IProps {
  height?: number
  paused: boolean
  progress?: number // Percentage
  viewWidth?: number
  trackColor?: string
  backgroundColor?: string
  progressDuration?: number // ms
  onCompletion?: () => void
  progressStyle?: StyleProp<ViewStyle>
}

export const DEFAULT_TIME = 250

const ProgressBar: FC<IProps> = ({
  paused,
  height = 2,
  trackColor,
  progress = 0,
  progressStyle,
  backgroundColor,
  viewWidth = undefined,
  progressDuration = DEFAULT_TIME,
}) => {
  const {
    colors: {
      white: { highEmphasis },
      black: { inactive },
    },
  } = useTheme()
  const { width } = useWindowDimensions()
  const progressWidth = viewWidth ?? width
  const scale = useMemo(() => new Animated.Value(0), [])
  const calculatedVal = useMemo(() => progressWidth * (_.round(progress, 2) / 100), [
    progress,
    progressWidth,
  ])
  const animation = useMemo(
    () =>
      Animated.timing(scale, {
        duration: progressDuration,
        toValue: calculatedVal > progressWidth ? progressWidth : calculatedVal,
        useNativeDriver: true,
        easing: Easing.linear,
      }),
    [calculatedVal, progressDuration, progressWidth, scale],
  )

  useEffect(() => {
    scale.setValue(calculatedVal)
    // The following dependencies are voluntarily filled with width only
    // eslint-disable-next-line
  }, [progressWidth])

  useEffect(() => {
    paused ? scale.stopAnimation() : animation.start()
  }, [animation, paused, scale])

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          height,
          backgroundColor: trackColor ?? inactive,
        },
        progressStyle,
      ],
      progressBar: [
        styles.progressBar,
        {
          transform: [{ translateX: scale }],
          backgroundColor: backgroundColor ?? highEmphasis,
        },
      ],
    }),
    [height, trackColor, inactive, progressStyle, backgroundColor, highEmphasis, scale],
  )

  return (
    <View style={s.container}>
      <Animated.View style={s.progressBar} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    overflow: "hidden",
  },
  progressBar: {
    flex: 1,
    width: "100%",
  },
})

export default ProgressBar
