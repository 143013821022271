import { firestore } from "capsule"
import firebase from "firebase"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { CollectionNames, collections } from "../../common/types"

export const useFirstCollectionItem = <Type>(collection: CollectionNames, patient?: string) => {
  const [data] = useCollectionData<Type>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient)
      .collection(collection)
      .orderBy("start", "desc") as unknown) as firebase.firestore.Query<Type>,
  )
  return data?.[0] as Type | undefined
}
