import { useTheme } from "capsule"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"

import CodeView from "../../components/CodeView"
import { PractitionerHomeNS } from "../../features/i18n/constants"

const CodesScreen: FC = () => {
  const {
    colors: {
      surface: { background },
    },
    dimensions: { spacing },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation(PractitionerHomeNS)
  const s = useMemo(
    () => ({
      scrollView: [
        styles.scrollView,
        {
          backgroundColor: background,
        },
      ],
      contentScrollView: {
        paddingTop: spacing,
        paddingBottom: spacing * 4,
      },
      separator: {
        paddingVertical: spacing,
      },
    }),
    [background, spacing],
  )
  return (
    <View style={s.scrollView}>
      <KeyboardAwareScrollView
        style={screenStyle}
        enableOnAndroid
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={s.contentScrollView}
      >
        <CodeView subtitle={t("subtitle.activate")} name="new_code" mode="activate_pack" />
        <View style={s.separator} />
        <CodeView subtitle={t("subtitle.order")} name="order_code" order mode="order" />
      </KeyboardAwareScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    height: "100%",
  },
})

export default CodesScreen
