import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, StyleSheet, TextStyle } from "react-native"

import { Subtitle1 } from "../Texts"

interface IProps {
  version: string
  style?: StyleProp<TextStyle>
}

const RowVersionItem: FC<IProps> = ({ version, style }) => {
  const { t } = useTranslation()
  return <Subtitle1 style={[styles.footer, style]}>{t("version", { version })}</Subtitle1>
}

const styles = StyleSheet.create({
  footer: {
    textAlign: "center",
  },
})

export default RowVersionItem
