import { H4, Subtitle1, useTheme } from "capsule"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { JourneyNS } from "../../features/i18n/constants"
import { Patient } from "../../features/models/UserData"
import { daySinceSurgery } from "../../utils/conversion"

interface ProtocolViewProps {
  patient: Patient
  isProgramEnded: boolean | undefined
}

const JourneyHeader: FC<ProtocolViewProps> = ({ patient, isProgramEnded }) => {
  const { t } = useTranslation(JourneyNS)

  const {
    fontMaker,
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      container: {
        margin: spacing,
      },
      titleStyle: [fontMaker({ weight: "Bold" }), { marginBottom: spacing }],
    }),
    [spacing, fontMaker],
  )

  const daysSinceSurgery = daySinceSurgery(patient.surgeryDate)
  const activityDays = patient.activityDays ?? 0

  const getHeaderSubtitle = useCallback(() => {
    if (isProgramEnded) {
      return t("header.ended")
    } else if (daysSinceSurgery < -1) {
      return t("header.preop", { numberOfDays: Math.abs(daysSinceSurgery) })
    } else if (daysSinceSurgery === -1) {
      return t("header.tomorrow")
    } else if (daysSinceSurgery === 0) {
      return t("header.surgeryDay")
    } else {
      if (activityDays > 0) {
        if (activityDays === 1) {
          return t("header.activityDays.recovery")
        } else {
          return t("header.activityDays.title", {
            numberOfDays: activityDays,
          })
        }
      } else {
        return t("header.inactivityDays")
      }
    }
  }, [t, daysSinceSurgery, activityDays, isProgramEnded])

  const getActivityDaySubtitle = useCallback(() => {
    if (daysSinceSurgery > 0) {
      if (activityDays > 1 && activityDays <= 3) {
        return t("header.activityDays.lessThan3")
      } else if (activityDays > 3 && activityDays <= 6) {
        return t("header.activityDays.lessThan6")
      } else if (activityDays > 6 && activityDays <= 10) {
        return t("header.activityDays.lessThan10")
      } else if (activityDays > 10) {
        return t("header.activityDays.moreThan10")
      }
    }
    return null
  }, [t, daysSinceSurgery, activityDays])

  return (
    <View style={s.container}>
      <H4 style={s.titleStyle}>
        {daysSinceSurgery <= 0 ? t("header.hello") : t("header.congrats")} {patient.firstName}
      </H4>
      <Subtitle1>{getHeaderSubtitle()}</Subtitle1>
      {getActivityDaySubtitle() ? <Subtitle1>{getActivityDaySubtitle()}</Subtitle1> : null}
    </View>
  )
}

export default JourneyHeader
