import React, { FC, ReactNode, useMemo } from "react"
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
} from "react-native"
import { Edge, SafeAreaView } from "react-native-safe-area-context"

import { useTheme } from "../../features/Theme"
import { H5, Subtitle1 } from "../Texts"
import { isVectorIcon, VectorIcon, VectorIconProps } from "../VectorIcon"

interface IProps {
  title?: string
  subtitle?: string
  headerColor?: string
  children?: ReactNode
  viewStyle?: StyleProp<ViewStyle> // style of the container
  titleStyle?: StyleProp<TextStyle>
  subtitleStyle?: StyleProp<TextStyle>
  image?: ImageSourcePropType | VectorIconProps
  style?: StyleProp<ViewStyle> // style of the SafeAreaView
  edges?: Edge[]
}

const EmptyView: FC<IProps & ViewProps> = ({
  image,
  title,
  children,
  subtitle,
  viewStyle,
  titleStyle,
  headerColor,
  subtitleStyle,
  style,
  edges,
}) => {
  const {
    colors: {
      surface: { background },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      title: [styles.text, titleStyle],
      subtitle: [styles.text, subtitleStyle],
      view: [
        styles.view,
        {
          backgroundColor: headerColor ?? background,
        },
        style,
      ],
      container: [
        styles.container,
        {
          backgroundColor: background,
        },
        viewStyle,
      ],
    }),
    [titleStyle, subtitleStyle, headerColor, background, style, viewStyle],
  )

  return (
    <SafeAreaView style={s.view} edges={edges ?? ["top"]}>
      <View style={s.container}>
        {isVectorIcon(image) ? (
          <VectorIcon {...image} />
        ) : image ? (
          <Image style={styles.image} source={image} />
        ) : null}
        {title ? <H5 style={s.title}>{title}</H5> : null}
        {subtitle ? <Subtitle1 style={s.subtitle}>{subtitle}</Subtitle1> : null}
        {children}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
  },
  image: {
    alignSelf: "center",
  },
  text: {
    textAlign: "center",
  },
})

export default EmptyView
