import { FormikRadioButton, Subtitle1, useTheme, VectorIcon } from "capsule"
import React, { useMemo } from "react"
import { StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"

import { itemName } from "../../common/dataFunctions"
import { INPUT_HEIGHT } from "../../features/config/Constants"

const PathologyRow = ({ item, onPress, userLanguage }) => {
  const name = itemName(item, userLanguage)

  const {
    colors: {
      black: { highEmphasis: black },
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      button: [
        styles.radioButton,
        {
          backgroundColor: white,
          paddingHorizontal: spacing,
        },
      ],
      label: [
        styles.label,
        {
          paddingLeft: spacing / 2,
        },
      ],
      image: [
        styles.image,
        {
          margin: spacing,
          marginRight: spacing * 2,
        },
      ],
      text: {
        color: black,
        flex: 1,
      },
    }),
    [black, spacing, white],
  )
  return (
    <FormikRadioButton
      name="pathology"
      onPress={onPress}
      textOnly={false}
      renderLabel={() => (
        <View style={s.label}>
          {item.image ? (
            <FastImage style={[s.image]} source={{ uri: item.image }} />
          ) : (
            <View style={s.image}>
              <VectorIcon name="photo" category="MaterialIcons" size={50} />
            </View>
          )}
          <Subtitle1
            style={s.text}
            maxFontSizeMultiplier={1}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {name}
          </Subtitle1>
        </View>
      )}
      flexDirection="row"
      touchableStyle={s.button}
      value={item.id}
    />
  )
}

const styles = StyleSheet.create({
  image: {
    height: INPUT_HEIGHT,
    width: INPUT_HEIGHT,
  },
  label: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  radioButton: {
    height: 80,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
  },
})

export default PathologyRow
