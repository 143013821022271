import { Subtitle1, useTheme, useUser, VectorIcon } from "capsule"
import _ from "lodash"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import SettingsList from "../../components/SettingsList"
import { ICON_SIZE, OTHER_PATH_FORM } from "../../features/config/Constants"
import { SettingsNS } from "../../features/i18n/constants"

const OtherPathologiesScreen = () => {
  const {
    colors: { black },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const { userData } = useUser()
  const userPathologies = userData?.medicalInfo?.otherPathologies
  const s = useMemo(
    () => ({
      rightChildText: {
        marginRight: spacing / 2,
        color: black.mediumEmphasis,
      },
    }),
    [black.mediumEmphasis, spacing],
  )

  const renderRightChild = useCallback(
    text => (
      <View style={styles.rightChildContainer}>
        <Subtitle1 style={s.rightChildText}>{text}</Subtitle1>
        <VectorIcon
          name="chevron-right"
          category="MaterialIcons"
          color={black.mediumEmphasis}
          size={ICON_SIZE}
        />
      </View>
    ),
    [s.rightChildText, black.mediumEmphasis],
  )

  const pathologyItem = useCallback(
    (name, route, rightChild?) => ({
      name: t(`${SettingsNS}:${name}`),
      route,
      rightChild,
    }),
    [t],
  )

  const oncoItems = useMemo(
    () =>
      _.filter([
        pathologyItem(
          "heart_disease",
          "HeartDisease",
          renderRightChild(t(`common:button.${userPathologies?.heart_disease}`)),
        ),
        pathologyItem(
          "respiratory_disease",
          "RespiratoryDisease",
          renderRightChild(t(`common:button.${userPathologies?.respiratory_disease}`)),
        ),
        {
          name: t(`${SettingsNS}:endocrine_disease`),
          form: OTHER_PATH_FORM,
          formId: 0,
          rightChild: renderRightChild(t(`common:button.${userPathologies?.endocrine_disease}`)),
        },
      ]),
    [
      t,
      pathologyItem,
      renderRightChild,
      userPathologies?.endocrine_disease,
      userPathologies?.heart_disease,
      userPathologies?.respiratory_disease,
    ],
  )

  return <SettingsList listKey="pathology_list" listItems={oncoItems} />
}

const styles = StyleSheet.create({
  rightChildContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
})

export default OtherPathologiesScreen
