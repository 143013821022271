import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { SpecialtyName } from "src/common/CommonUserData"
import * as Yup from "yup"

import { collections } from "../../common/types"
import { EvalFormValues, EvalType, ExerciseEvalFormValues } from "../../features/models/Evaluation"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { ScreenParams } from "../../features/Navigation/FormikScreen"

const filterUndefinedValues = obj =>
  Object.entries(obj).reduce((a, [k, v]) => (v === undefined ? a : { ...a, [k]: v }), {})

const defaultEvalFormValues: EvalFormValues = {
  pain: undefined,
  start: undefined,
  phase: undefined,
  fatigue: undefined,
  stiffness: undefined,
  effort: undefined,
}

export const eveningOrthoEvalValidationSchema = () =>
  Yup.object({
    pain: Yup.number().required(),
    effort: Yup.number().required(),
  })

export const eveningOncoEvalValidationSchema = () =>
  Yup.object({
    fatigue: Yup.number().required(),
    effort: Yup.number().required(),
  })

export const morningOrthoEvalValidationSchema = () =>
  Yup.object({
    pain: Yup.string().required(),
    stiffness: Yup.string().required(),
  })

export const morningOncoEvalValidationSchema = () =>
  Yup.object({
    fatigue: Yup.number().required(),
    pain: Yup.string().required(),
  })

export const onSubmitEvaluation = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  screenParams?: ScreenParams,
) => (values: EvalFormValues | ExerciseEvalFormValues) => {
  const { sessionId, evalType, exerciseId } = screenParams ?? {}
  // Exercise Evaluation => Session collection
  if (evalType === EvalType.EXERCISE && exerciseId) {
    userDocRef
      ?.collection(collections.SESSIONS)
      .doc(sessionId)
      .set(
        {
          exercises: {
            [exerciseId]: {
              validation: (values as ExerciseEvalFormValues).validation === "yes",
            },
          },
        },
        { merge: true },
      )
    return
  }
  // Evening and Morning Evaluation => Evaluation collection
  userDocRef
    ?.collection(
      screenParams?.evalType === EvalType.EVENING
        ? collections.EVAL_EVENING
        : collections.EVAL_MORNING,
    )
    .add(filterUndefinedValues(values))
  if (evalType === EvalType.EVENING && !(values as EvalFormValues).pain) {
    return
  }
  if (evalType === EvalType.MORNING && (values as EvalFormValues).fatigue) {
    updateUserData(userDocRef, {
      lastEval: {
        phase: (values as EvalFormValues).phase,
        pain: (values as EvalFormValues).pain,
        fatigue: (values as EvalFormValues).fatigue,
      },
    })
  } else {
    updateUserData(userDocRef, {
      lastEval: { phase: (values as EvalFormValues).phase, pain: (values as EvalFormValues).pain },
    })
  }
}

export const evaluationFormikParams = (evalType: EvalType, specialty?: SpecialtyName) => ({
  onSubmit: onSubmitEvaluation,
  initialValues:
    evalType === EvalType.EVENING
      ? defaultEvalFormValues
      : evalType === EvalType.MORNING
      ? defaultEvalFormValues
      : { validation: undefined },
  validationSchema:
    evalType === EvalType.EVENING
      ? specialty === "ortho" || specialty === "implant"
        ? eveningOrthoEvalValidationSchema()
        : eveningOncoEvalValidationSchema()
      : evalType === EvalType.MORNING
      ? specialty === "ortho" || specialty === "implant"
        ? morningOrthoEvalValidationSchema()
        : morningOncoEvalValidationSchema()
      : Yup.object({ validation: Yup.string().required() }),
})
