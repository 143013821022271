import { H1, useTheme } from "capsule"
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { StyleSheet, View } from "react-native"
import { CountdownCircleTimer } from "react-native-countdown-circle-timer"

interface IProps {
  restart: number
  isPlaying: boolean
  onFinish?: () => void
}

const CountdownOverlay: FC<IProps> = ({ onFinish, restart, isPlaying }) => {
  const {
    colors: {
      primary,
      black: { mediumEmphasis: black },
    },
  } = useTheme()
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (restart) {
      setVisible(true)
    }
  }, [restart])

  const onComplete = useCallback(() => {
    setVisible(false)
    onFinish?.()
  }, [onFinish])

  const renderTime = useCallback(({ remainingTime }) => <H1 color={primary}>{remainingTime}</H1>, [
    primary,
  ])

  const { style } = useMemo(
    () => ({
      style: [
        styles.view,
        {
          backgroundColor: black,
        },
      ],
    }),
    [black],
  )

  return visible ? (
    <View {...{ style }}>
      <CountdownCircleTimer
        duration={5}
        key={restart}
        colors={primary}
        {...{ onComplete, isPlaying }}
      >
        {renderTime}
      </CountdownCircleTimer>
    </View>
  ) : null
}

const styles = StyleSheet.create({
  view: {
    zIndex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
  },
})

export default CountdownOverlay
