import { AppUserData } from "../features/models/UserData"
import { CommonUserData } from "./CommonUserData"
import { NO_PARTNER } from "./Instruction"

export const itemName = (item, userLanguage) => item.i18n?.[userLanguage]?.name ?? item.i18n.fr.name

export const checkValidity = (
  userData: AppUserData | CommonUserData,
  specialty?: string,
  organs?: string[],
  pathologies?: string[],
  partner?: string,
) => {
  if (partner) {
    if (
      (partner === NO_PARTNER && userData.partner) ||
      (partner !== NO_PARTNER && partner !== userData.partner)
    ) {
      return false
    }
  }
  if (pathologies && pathologies.length > 0) {
    return pathologies.includes(userData?.pathology)
  }
  if (organs && organs.length > 0) {
    return organs.includes(userData?.organ)
  }
  if (specialty) {
    return specialty === userData?.specialty
  }
  return true
}
