import {
  File,
  Fileish,
  firebase,
  firestore,
  IUserContext,
  logger,
  PermissionCameraWrapper,
  RowItem,
  useAlert,
  userContext,
  useTheme,
  VectorIcon,
  VectorIconProps,
} from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useFilePicker } from "react-filepicker-hook"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { Image, Options } from "react-native-image-crop-picker"
import { ActivityIndicator } from "react-native-paper"
import ShortUniqueId from "short-unique-id"

import { collections } from "../../common/types"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import { PatientNS, PatientsListNS } from "../../features/i18n/constants"
import { UserData } from "../../features/models/UserData"
import { messageContext } from "../../features/Providers/MessageProvider"
import { getFileName } from "../../utils/getFileName"
import { IChildrenProps } from "./PatientInformationsView"

export const imageOptions: Options = {
  mediaType: "photo",
}

function isImage(source: Fileish): source is Image {
  return (source as Image).path !== undefined
}

function isWeb(source: Fileish): source is File {
  return Platform.OS === "web"
}

const PatientReportView: FC<IChildrenProps> = ({ patient, isSurgeon }) => {
  const {
    dimensions: { spacing },
    colors: {
      primary,
      surface: { textInput },
      black: { mediumEmphasis },
    },
  } = useTheme()
  const { showSnack } = useAlert()
  const { userData } = useContext<IUserContext<UserData>>(userContext)
  const { sendMessage } = useContext(messageContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const s = useMemo(
    () => ({
      row: { padding: spacing },
    }),
    [spacing],
  )
  const { files, errors, FileInput, showFilePicker } = useFilePicker({
    maxSize: 1000000,
  })

  const saveReport = useCallback(
    async (source: Fileish | undefined) => {
      if (!source) {
        return
      }
      try {
        setLoading(true)
        const uuid = new ShortUniqueId({ length: 28 })
        const imageRef = firebase
          .storage()
          .ref(`${collections.LOGIN}/${patient.id}/${uuid.randomUUID()}`)
        let attachmentName: string
        if (isWeb(source)) {
          await imageRef.put(source)
          attachmentName = source.name
        } else {
          const sourcePath = isImage(source)
            ? source.path
            : Platform.OS === "ios"
            ? source.uri
            : source.fileCopyUri
          attachmentName = isImage(source) ? getFileName(sourcePath, source.filename) : source.name
          await imageRef.putFile(sourcePath)
        }
        const attachmentUrl = await imageRef.getDownloadURL()
        const message = {
          attachmentUrl,
          attachmentName,
          timestamp: firestore.Timestamp.now(),
          authorName: t(`${PatientsListNS}:name`, {
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            ns: PatientsListNS,
            interpolation: { escapeValue: false },
          }),
        }
        await sendMessage(patient.id, message)
        showSnack({ message: t(`${PatientNS}:report`) })
      } catch (e) {
        showSnack({ message: t(`${PatientsListNS}:errors.send_message`) })
        logger("send report error", e)
      } finally {
        setLoading(false)
      }
    },
    [patient.id, sendMessage, setLoading, showSnack, t, userData?.firstName, userData?.lastName],
  )

  const commonIconProps = useMemo<Omit<VectorIconProps, "name">>(
    () => ({
      size: ICON_SIZE,
      color: mediumEmphasis,
      category: "MaterialIcons",
    }),
    [mediumEmphasis],
  )

  const rowReportProps = useMemo(
    () => ({
      color: mediumEmphasis,
      leftChild: <VectorIcon name="file-upload" {...commonIconProps} />,
      rightChild: loading ? <ActivityIndicator color={primary} size="small" /> : null,
      rowStyle: {
        height: INPUT_HEIGHT,
        backgroundColor: textInput,
      },
    }),
    [commonIconProps, loading, mediumEmphasis, primary, textInput],
  )

  useEffect(() => {
    logger("files", files)
    if (!_.isEmpty(files)) {
      // noinspection JSIgnoredPromiseFromCall
      saveReport(files[0])
    }
  }, [files, saveReport])

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      // eslint-disable-next-line no-console
      console.error(errors)
      showSnack({
        message: _(errors)
          .map(e => e.message)
          .join(),
      })
    }
  }, [errors, showSnack])

  const rowItem = useMemo(
    () => (
      <RowItem
        {...rowReportProps}
        name={t(`${PatientNS}:addCr`)}
        color={mediumEmphasis}
        onPress={Platform.OS === "web" ? showFilePicker : undefined}
      />
    ),
    [mediumEmphasis, rowReportProps, showFilePicker, t],
  )

  return (
    <View style={s.row}>
      {Platform.OS === "web" ? (
        <>
          {rowItem}
          <FileInput accept="*/*" />
        </>
      ) : (
        <PermissionCameraWrapper
          downloadFile
          style={styles.cover}
          disabled={!isSurgeon}
          setSource={saveReport}
          downloadGallery={false}
          imageOptions={imageOptions}
          icons={{
            camera: {
              name: "photo-camera",
              ...commonIconProps,
            },
            documents: {
              name: "file-copy",
              ...commonIconProps,
            },
          }}
        >
          {rowItem}
        </PermissionCameraWrapper>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  cover: {
    height: INPUT_HEIGHT,
    justifyContent: "center",
  },
  clear: {
    justifyContent: "center",
  },
})

export default PatientReportView
