import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { TFunction } from "i18next"
import * as Yup from "yup"

import { updateUserData } from "../../features/models/UserDataFunctions"
import { FormikParams } from "../../features/Navigation/FormikScreen"

export interface SurgeonPracticeFormValues {
  surgeonPractice: string
}

export const defaultSurgeonPracticeValues: SurgeonPracticeFormValues = {
  surgeonPractice: "",
}

export const surgeonPracticeValidationSchema = (t: TFunction, ns: string) =>
  Yup.object({
    surgeonPractice: Yup.string().required(t("required.surgeonPractice", { ns })),
  })

export const onSubmitSurgeonPractice = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
) => async (values: SurgeonPracticeFormValues): Promise<any> => {
  await updateUserData(userDocRef, { surgeonPractice: values.surgeonPractice })
}

export const surgeonPracticeFormikParams = (
  t: TFunction,
  ns: string,
  userData?: { surgeonPractice: string },
) =>
  ({
    onSubmit: onSubmitSurgeonPractice,
    initialValues: userData?.surgeonPractice
      ? {
          surgeonPractice: userData?.surgeonPractice,
        }
      : defaultSurgeonPracticeValues,
    validationSchema: surgeonPracticeValidationSchema(t, ns),
  } as FormikParams<SurgeonPracticeFormValues>)
