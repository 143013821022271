import { RouteProp } from "@react-navigation/core"
import { NavigationProp } from "@react-navigation/core/lib/typescript/src/types"
import { ParamListBase } from "@react-navigation/routers"
import { useTheme } from "capsule/features"
import React, { Children, createContext, ReactNode, useCallback, useMemo, useState } from "react"
import { StyleSheet, View } from "react-native"

import { maxWidth } from "../../features/Theme/dimensions"
import generateShadow from "../../utils/generateShadow"
import { useTabTwoPane } from "../../utils/useTabLand"

export interface ITwoPaneContext<
  N extends NavigationProp<ParamListBase>,
  R extends RouteProp<ParamListBase, string>
> {
  navigation: Pick<N, "navigate">
  route: R
}

export const twoPaneContext = createContext<
  ITwoPaneContext<NavigationProp<ParamListBase>, RouteProp<ParamListBase, string>> | undefined
>(undefined)

const TwoPaneScreen = <
  N extends NavigationProp<ParamListBase>,
  R extends RouteProp<ParamListBase, string>
>({
  children,
  customWidth = maxWidth,
}: {
  children: ReactNode
  customWidth?: number
}): JSX.Element => {
  const isTabTwoPane = useTabTwoPane()
  const [params, setParams] = useState<R["params"]>({})

  const childrenArray = Children.toArray(children)

  const navigate = useCallback((p1, p2) => {
    setParams(prev => ({ ...prev, ...p2 }))
  }, [])

  const context: ITwoPaneContext<N, R> = useMemo(
    () => ({
      navigation: {
        navigate,
      } as N,
      route: {
        params,
      } as R,
    }),
    [navigate, params],
  )

  const {
    colors: {
      surface: { background },
    },
  } = useTheme()
  const s = useMemo(
    () => ({
      rightView: [
        styles.right,
        {
          backgroundColor: background,
        },
      ],
    }),
    [background],
  )

  if (!isTabTwoPane) {
    return <>{childrenArray[0]}</>
  }

  return (
    <twoPaneContext.Provider value={context}>
      <View style={styles.container}>
        <View style={{ width: customWidth }}>{childrenArray[0]}</View>
        <View style={s.rightView}>{childrenArray.slice(1)}</View>
      </View>
    </twoPaneContext.Provider>
  )
}

const styles = StyleSheet.create({
  header: {
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 0,
  },
  container: {
    flexDirection: "row",
    height: "100%",
  },
  right: {
    flex: 1,
    height: "100%",
    ...generateShadow(4),
  },
})

export default TwoPaneScreen
