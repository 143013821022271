import React, { FC } from "react"
import {
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
} from "react-native"

import { getHeaderSafeAreaHeight } from "../../utils/getHeaderHeight"

interface IProps {
  hasOffset?: boolean
  hasNavBar?: boolean
}

const KeyboardAvoidingView: FC<IProps & KeyboardAvoidingViewProps> = ({
  hasOffset,
  hasNavBar,
  ...props
}) => (
  <RNKeyboardAvoidingView
    {...props}
    behavior={(Platform.OS === "ios" && "padding") || undefined}
    keyboardVerticalOffset={
      (hasOffset && Platform.OS === "ios" && getHeaderSafeAreaHeight() + (hasNavBar ? 56 : 0)) ||
      undefined
    }
  />
)

export default KeyboardAvoidingView
