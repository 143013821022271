import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  DateTimePicker,
  generateShadow,
  IUserContext,
  Subtitle1,
  Subtitle2,
  userContext,
  useStyles,
  useTheme,
  VectorIconProps,
} from "capsule"
import dayjs from "dayjs"
import { useFormikContext } from "formik"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, View } from "react-native"

import Calendar from "../../components/Calendar"
import SubmitButton from "../../components/SubmitButton"
import { ICON_SIZE } from "../../features/config/Constants"
import usePermissions from "../../features/hooks/usePermissions"
import useSpecialtySystem from "../../features/hooks/useSpecialtySystem"
import { AlertNS } from "../../features/i18n/constants"
import { createDailyPhase } from "../../features/models/PhaseAndSessionFunctions"
import { AppUserData } from "../../features/models/UserData"
import { programContext } from "../../features/Providers/ProgramProvider"
import { AlertFormValues, defaultAlertFormValues } from "./Constants"

interface IProps {
  isEdit: boolean
}

export const AlertScreen: FC<IProps> = ({ isEdit = false }) => {
  usePermissions(true)
  const {
    colors: { black },
  } = useTheme()
  const { t } = useTranslation(AlertNS)
  const navigation = useNavigation()
  const { user, userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { handleSubmit, values, setFieldValue } = useFormikContext<AlertFormValues>()
  const { currentPhase } = useContext(programContext)
  const { initDailyPhase, createLocalNotifications } = useSpecialtySystem()
  const [submitLoading, setSubmitLoading] = useState(false)
  const isOnco = useMemo(() => userData?.specialty === "onco", [userData?.specialty])
  const total = useMemo(() => dayjs(userData?.programEnd).diff(userData?.programStart, "d"), [
    userData?.programEnd,
    userData?.programStart,
  ])
  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        surface: { textInput, background },
      },
    }) => ({
      view: {
        flex: 1,
        backgroundColor: background,
      },
      explanation: { paddingBottom: 16 },
      container: {
        backgroundColor: background,
        paddingBottom: spacing * 2,
      },
      innerContainer: {
        padding: spacing,
        marginBottom: spacing,
        backgroundColor: background,
      },
      child: {
        paddingBottom: spacing,
      },
      subtitle: {
        padding: spacing,
        backgroundColor: background,
      },
      separator: { height: 1, ...generateShadow(2) },
      touchable: { borderRadius: 0, backgroundColor: textInput },
    }),
    [],
  )
  useEffect(() => {
    if (userData?.specialty === "onco") {
      setFieldValue("endTime", undefined)
    }
  }, [setFieldValue, userData?.specialty])

  const pickerProps = useMemo(
    () => ({
      icons: {
        left: {
          name: "access-time",
          category: "MaterialIcons",
          color: black.mediumEmphasis,
          size: ICON_SIZE,
        } as VectorIconProps,
        right: {
          name: "arrow-drop-down",
          category: "MaterialIcons",
          color: black.mediumEmphasis,
          size: ICON_SIZE,
        } as VectorIconProps,
      },
      styles: { child: s.child },
      touchableStyle: s.touchable,
    }),
    [black, s.child, s.touchable],
  )

  const onSubmit = useCallback(async () => {
    setSubmitLoading(true)
    handleSubmit?.()
    // Update current journey and session notifications (edit or creation)
    // Avoid creating notifs each day with checking if a change was done here (edit)
    if (userData && currentPhase && values) {
      await createDailyPhase({
        phase: currentPhase,
        userData,
        user,
        initDailyPhase,
        isEdit,
        createLocalNotifications,
      })
    }
    setSubmitLoading(false)
    if (isEdit) {
      navigation.goBack()
    }
  }, [
    handleSubmit,
    user,
    userData,
    currentPhase,
    values,
    isEdit,
    initDailyPhase,
    createLocalNotifications,
    navigation,
  ])

  return (
    <View style={s.view}>
      <ScrollView contentContainerStyle={s.container}>
        <Subtitle1 emphasis="high" style={s.subtitle}>
          {t("subtitle")}
        </Subtitle1>
        <View style={s.separator} />
        <View style={s.innerContainer}>
          {isOnco ? (
            total ? (
              <Body2 style={s.explanation} color={black.highEmphasis}>
                {t("explanation", { total })}
              </Body2>
            ) : null
          ) : (
            <Calendar list={defaultAlertFormValues.alertDays} />
          )}
          <DateTimePicker
            {...pickerProps}
            mode="time"
            name="startTime"
            label={t(`label.start${isOnco ? "Training" : ""}`)}
          />
          {isOnco ? null : (
            <DateTimePicker {...pickerProps} mode="time" name="endTime" label={t("label.end")} />
          )}
          <Subtitle2 emphasis="high">{t("subtitle2")}</Subtitle2>
          <Body2 emphasis="high">{t("helper")}</Body2>
        </View>
        <SubmitButton<AlertFormValues> {...{ onSubmit }} syncLoading={submitLoading} />
      </ScrollView>
    </View>
  )
}

export default AlertScreen
