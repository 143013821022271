import { logger } from "capsule"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export const useTranslate = () => {
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)

  const setLanguage = async (languageCode: string) => {
    try {
      setCurrentLanguage(languageCode)
      i18n.changeLanguage(languageCode)
    } catch (error) {
      logger("Error when saving the langage", error)
    }
  }

  return {
    setLanguage,
    currentLanguage,
  }
}
