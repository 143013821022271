import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  Button,
  functions,
  H6,
  IUserContext,
  logger,
  Subtitle1,
  userContext,
  useTheme,
} from "capsule"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { RootParamList } from "src/features/Navigation/types"

import { OnArchivePatientParams } from "../../common/types"
import { HomeNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { programContext } from "../../features/Providers/ProgramProvider"
import crashlytics from "../../services/Analytics/crashlytics"

interface IProps {
  isProgramEnded: boolean
}

const HomeNoProgramView: FC<IProps> = ({ isProgramEnded }) => {
  const {
    colors: {
      white: { highEmphasis: white },
    },
    dimensions: { spacing, margin },
  } = useTheme()
  const { t } = useTranslation(HomeNS)
  const { userData, userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const navigation = useNavigation<StackNavigationProp<RootParamList, "OncoSurgeries">>()
  // PHASES
  const { cleanPhase } = useContext(programContext)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const isOnco = userData?.specialty === "onco"
  const isOrtho = userData?.specialty === "ortho"

  const s = useMemo(
    () => ({
      button: {
        margin: spacing,
      },
      congratsView: {
        backgroundColor: white,
        paddingTop: spacing,
        marginTop: margin,
        marginHorizontal: margin / 2,
        borderRadius: 10,
      },
      textView: {
        marginHorizontal: margin * 2,
      },
      titleStyle: [
        styles.textStyle,
        {
          marginBottom: margin,
        },
      ],
    }),
    [spacing, white, margin],
  )

  const onPress = useCallback(async () => {
    if (isOrtho) {
      try {
        if (userDocRef) {
          setArchiveLoading(true)
          const params: OnArchivePatientParams = {
            patientId: userDocRef.id,
            dev: true,
          }
          crashlytics.reportError(crashlytics.errorCodes.archive, "Archive triggered")
          await functions().httpsCallable("createArchive")(params)
          cleanPhase()
        }
      } catch (e) {
        logger("archive error", e)
      } finally {
        setArchiveLoading(false)
      }
    } else if (isOnco) {
      navigation.navigate("OncoSurgeries")
    }
  }, [cleanPhase, userDocRef, isOnco, isOrtho, navigation])

  return isOnco || isOrtho ? (
    <View style={s.congratsView}>
      <View style={s.textView}>
        <H6 style={s.titleStyle}>
          {isProgramEnded ? t("protocolEnd.title") : t("enterDate.title")}
        </H6>
        {isOnco ? (
          isProgramEnded ? (
            <Subtitle1 style={styles.textStyle}>{t("protocolEnd.onco.text")}</Subtitle1>
          ) : (
            <Subtitle1 style={styles.textStyle}>{t("enterDate.text")}</Subtitle1>
          )
        ) : (
          <Subtitle1 style={styles.textStyle}>{t("protocolEnd.ortho.text")}</Subtitle1>
        )}
      </View>
      <Button {...{ onPress }} style={s.button} loading={archiveLoading} disabled={archiveLoading}>
        {isOnco ? t("protocolEnd.onco.button") : t("protocolEnd.ortho.button")}
      </Button>
    </View>
  ) : null
}

const styles = StyleSheet.create({
  textStyle: {
    textAlign: "center",
  },
})

export default HomeNoProgramView
