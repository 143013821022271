import { logger } from "capsule"
import firebase from "firebase/app"

const googleService =
  process.env.REACT_APP_ENV === "dev"
    ? require("./preprod/google-services.json")
    : require("./prod/google-services.json")

logger("after import")

const app = firebase.initializeApp(googleService)

logger("after init", app)

const firebaseInit = "Init (web)"

export default firebaseInit
