import React, { FC } from "react"
import { StyleProp, StyleSheet, ViewStyle } from "react-native"
import { IconButton } from "react-native-paper"

import useTheme from "../../features/Theme/ThemeProvider"

interface IProps {
  isChecked?: boolean
  iconStyle?: StyleProp<ViewStyle>
  onPress?: () => void
  color?: string
}

const RadioButton: FC<IProps> = ({ isChecked, onPress, color, ...props }) => {
  const {
    colors: { primary, black, overrides },
  } = useTheme()
  const iconStyle: StyleProp<ViewStyle> = [styles.iconStyle, props.iconStyle]
  const checkedColor = color || overrides?.radioButton?.checked || primary

  return (
    <IconButton
      icon={`radio-button-${isChecked ? "checked" : "unchecked"}`}
      iconColor={isChecked ? checkedColor : black.inactive}
      onPress={onPress}
      style={iconStyle}
    />
  )
}

export const UseRadioButton = (props: IProps) => () => RadioButton({ ...props })

const styles = StyleSheet.create({
  iconStyle: { height: 24, width: 24, alignSelf: "center" },
})

export default RadioButton
