import { firestore, useTheme } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { collections } from "../../common/types"
import AccordionSection from "../../components/AccordionSection"
import { PatientNS } from "../../features/i18n/constants"
import { EvalFormValues } from "../../features/models/Evaluation"
import { getDatesFrom } from "../../utils/conversion"
import { useItems } from "../EvaluationScreen/Items"
import { PatientProps } from "./PatientScreen"
import PatientStatistics from "./PatientStatistics"

const PatientStatisticView: FC<PatientProps> = ({ patient }) => {
  const { t } = useTranslation(PatientNS)

  const {
    colors: {
      white: { highEmphasis: white },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      chartsContainer: [
        styles.chartsContainer,
        {
          backgroundColor: white,
        },
      ],
    }),
    [white],
  )

  const { efforts, pains } = useItems()

  const currentDate = new Date()
  const sevenDaysAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7,
  )

  const [morning, loadingMorning] = useCollectionData<EvalFormValues>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient.id)
      .collection(collections.EVAL_MORNING)
      .where("start", ">=", sevenDaysAgo)
      .orderBy("start", "asc") as unknown) as firebase.firestore.Query<EvalFormValues>,
  )
  const [evening, loadingEvening] = useCollectionData<EvalFormValues>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient.id)
      .collection(collections.EVAL_EVENING)
      .where("start", ">=", sevenDaysAgo)
      .orderBy("start", "asc") as unknown) as firebase.firestore.Query<EvalFormValues>,
  )

  const painData = useMemo(() => {
    const weekArray = getDatesFrom(7)
    return _.map(weekArray, el => {
      let pain: string | undefined
      pain = evening?.find(eve => dayjs(el).isSame(eve.start?.toDate(), "day"))?.pain
      if (pain === undefined) {
        pain = morning?.find(mo => dayjs(el).isSame(mo.start?.toDate(), "day"))?.pain
      }
      return {
        day: el,
        statData: pain ? parseInt(pain) : 0,
      }
    })
  }, [evening, morning])

  const effortData = useMemo(() => {
    const weekArray = getDatesFrom(7)
    return _.map(weekArray, el => {
      const effort: string | undefined = evening?.find(eve =>
        dayjs(el).isSame(eve.start?.toDate(), "day"),
      )?.effort
      return {
        day: el,
        statData: effort ? parseInt(effort) : 0,
      }
    })
  }, [evening])

  return (
    <AccordionSection leftIcon="analytics" title={t("weekEvaluation")} expandList={true}>
      <View style={s.chartsContainer}>
        <PatientStatistics
          chartTitle={t("painChartTitle")}
          icons={pains}
          datesRange={painData}
          {...{ loadingEvening, loadingMorning }}
        />
        <PatientStatistics
          chartTitle={t("effortChartTitle")}
          icons={efforts}
          datesRange={effortData}
          loadingEvening={loadingEvening}
        />
      </View>
    </AccordionSection>
  )
}

const styles = StyleSheet.create({
  chartsContainer: {
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
  },
})

export default PatientStatisticView
