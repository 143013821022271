import { FormikCheckbox, useTheme, VectorIconProps } from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet } from "react-native"

import { ICON_SIZE } from "../features/config/Constants"
import { AlertNS } from "../features/i18n/constants"

interface IData {
  day: string
  value: boolean
}

const keyExtractor = (item: IData, index: number) => `${index}-${item.day}`

interface IProps {
  list: Record<string, boolean>
}

const Calendar: FC<IProps> = ({ list }) => {
  const {
    colors: { primary },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation(AlertNS)

  const data: IData[] = _.map(list, (value: boolean, key: string) => ({
    value,
    day: key,
  }))

  const s = useMemo(
    () => ({
      checkbox: [
        styles.checkbox,
        {
          paddingHorizontal: spacing / 2,
        },
      ],
      content: [
        styles.content,
        {
          paddingBottom: spacing,
        },
      ],
    }),
    [spacing],
  )

  const icons = useMemo<{ checked: VectorIconProps; unchecked: VectorIconProps }>(
    () => ({
      checked: {
        size: ICON_SIZE,
        name: "checkbox-marked-circle",
        category: "MaterialCommunityIcons",
        color: primary,
      },
      unchecked: {
        size: ICON_SIZE,
        category: "MaterialCommunityIcons",
        name: "checkbox-blank-circle-outline",
      },
    }),
    [primary],
  )

  const renderItem: ListRenderItem<IData> = useCallback(
    ({ item: { day } }) => (
      <FormikCheckbox
        {...{ icons }}
        borderless
        showDefault
        contentStyle={s.checkbox}
        name={`alertDays.${day}`}
        label={t(`label.${day}`)}
      />
    ),
    [icons, s.checkbox, t],
  )

  return list ? (
    <FlatList
      horizontal
      style={styles.view}
      scrollEnabled={false}
      contentContainerStyle={s.content}
      {...{ data, renderItem, keyExtractor }}
    />
  ) : null
}

const styles = StyleSheet.create({
  checkbox: {
    height: 56,
    alignItems: "center",
  },
  content: {
    width: "100%",
    justifyContent: "space-between",
  },
  view: {
    width: "100%",
  },
})

export default Calendar
