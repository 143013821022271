import { firestore, IUserContext, userContext } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"

import { Faq } from "../../common/Faq"
import { collections } from "../../common/types"
import { TEXT_LIMIT } from "../config/Constants"
import { itemField } from "../hooks/useItemI18n"
import { FaqNS } from "../i18n/constants"
import { AppUserData } from "../models/UserData"

interface IFaqContext {
  searchInput?: string
  norSearchInput: string[]
  setSearchInput: Dispatch<SetStateAction<string | undefined>>
  searchBarVisible: boolean
  setSearchBarVisible: Dispatch<SetStateAction<boolean>>
  filteredDataBySearch?: FaqNor[]
  faqs?: FaqNor[]
  error?: firebase.FirebaseError
  loading: boolean
}

export type FaqNor = Faq & {
  title: string
  urlTitle: string
  description: string
  titleNor: string
  descriptionNor: string
  bodyTruncated: string
}

interface IProps {
  children: ReactNode
}

const defaultValues: Omit<IFaqContext, "setSearchInput" | "setSearchBarVisible"> = {
  error: undefined,
  faqs: [],
  loading: false,
  searchInput: "",
  norSearchInput: [],
  filteredDataBySearch: [],
  searchBarVisible: false,
}

export const faqContext = createContext<IFaqContext>(defaultValues as IFaqContext)

export const normalizedText = text => _.deburr(_.toLower(text))

const FaqProvider: FC<IProps> = ({ children }) => {
  const { t } = useTranslation(FaqNS)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const [searchInput, setSearchInput] = useState(defaultValues.searchInput)
  const [searchBarVisible, setSearchBarVisible] = useState(defaultValues.searchBarVisible)
  const [norSearchInput, setNorSearchInput] = useState(defaultValues.norSearchInput)
  const [faqs, loading, error] = useCollectionData<FaqNor>(
    (firestore()
      .collection(collections.FAQ)
      .where("disabled", "==", false) as unknown) as firebase.firestore.Query<Faq>,
    {
      transform: useCallback(
        faq => ({
          ...faq,
          title: itemField(faq, "title", () => t("common:lang")),
          description: itemField(faq, "description", () => t("common:lang")),
          titleNor: normalizedText(itemField(faq, "title", () => t("common:lang"))),
          descriptionNor: normalizedText(itemField(faq, "description", () => t("common:lang"))),
          bodyTruncated: _.truncate(
            itemField(faq, "description", () => t("common:lang")),
            {
              length: TEXT_LIMIT,
            },
          ),
        }),
        [t],
      ),
    },
  )

  useEffect(() => {
    setNorSearchInput(_.words(normalizedText(searchInput)))
  }, [searchInput])

  const [filteredDataByUser, setFilteredDataByUser] = useState<FaqNor[]>()
  const [filteredDataBySearch, setFilteredDataBySearch] = useState<FaqNor[]>()
  useEffect(() => {
    setFilteredDataByUser(
      _(faqs)
        .filter(
          f =>
            (_.isEmpty(f.specialty) || f.specialty === userData?.specialty) &&
            (_.isEmpty(f.organs) || _.includes(f.organs, userData?.organ)) &&
            (_.isEmpty(f.pathologies) || _.includes(f.pathologies, userData?.pathology)),
        )
        .value(),
    )
  }, [faqs, userData?.organ, userData?.pathology, userData?.specialty])

  useEffect(
    () =>
      searchInput?.length ?? 0 > 1
        ? setFilteredDataBySearch(
            _.filter(filteredDataByUser, f =>
              _.some(
                norSearchInput,
                w => f.titleNor.indexOf(w) !== -1 || f.descriptionNor.indexOf(w) !== -1,
              ),
            ),
          )
        : setFilteredDataBySearch(filteredDataByUser),
    [filteredDataByUser, norSearchInput, searchInput?.length, t],
  )

  const contextValue: IFaqContext = {
    norSearchInput,
    searchInput,
    setSearchInput,
    filteredDataBySearch,
    faqs,
    loading,
    error,
    searchBarVisible,
    setSearchBarVisible,
  }

  return <faqContext.Provider value={contextValue}>{children}</faqContext.Provider>
}

export default FaqProvider
