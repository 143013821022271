import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { RouteProp } from "@react-navigation/core"
import { userContext } from "capsule"
import { Formik } from "formik"
import React, { createElement, FC, useContext } from "react"

import { ExerciseId, SessionId } from "../../common/CommonUserData"
import { ExerciseParamList } from "../../screens/ExerciseScreen/ExerciseNavigator"
import { EvalType } from "../models/Evaluation"
import { RootParamList } from "./types"

type RootKey = keyof RootParamList
type ExerciseNavKey = keyof ExerciseParamList

export interface FormikParams<Values> {
  initialValues: Values
  validateOnMount?: boolean
  onSubmit: (
    userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
    props?: any,
  ) => (values: Values) => Promise<any>
  validationSchema: any | (() => any)
}

export interface ScreenParams {
  evalType?: EvalType
  sessionId?: SessionId
  exerciseId?: ExerciseId
}

interface IProps<Values> {
  navigation: any
  route: RouteProp<RootParamList, RootKey> | RouteProp<ExerciseParamList, ExerciseNavKey>
  component: FC
  isEdit?: boolean
  screenParams?: ScreenParams
  formikParams?: FormikParams<Values>
}

export const FormikScreen = <Values,>({ component, formikParams, ...props }: IProps<Values>) => {
  const { userDocRef } = useContext(userContext)
  return (
    // @ts-ignore
    <Formik {...formikParams} onSubmit={formikParams.onSubmit(userDocRef, props.screenParams)}>
      {
        // @ts-ignore
        createElement<Values>(
          component,
          props.screenParams ? { ...props, ...props.screenParams } : props,
        )
      }
    </Formik>
  )
}
