import _ from "lodash"

const diacritics = "ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž"
const diacriticsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz"

function removeDiacritics(str: string): string {
  if (_.isEmpty(str) || !_.isString(str)) {
    return ""
  }

  const split = str.split("")
  split.forEach((letter, index) => {
    const i = diacritics.indexOf(letter)
    if (i !== -1) {
      split[index] = diacriticsOut[i]
    }
  })
  return split.join("").toLowerCase()
}

export const removeDiacriticsFast: (str: string) => string = _.memoize(removeDiacritics)

export default removeDiacritics
