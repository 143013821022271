import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { Subtitle2, useTheme, VectorIcon } from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native"
import FastImage from "react-native-fast-image"

import { ID } from "../common/CommonUserData"
import { Exercise } from "../common/Phase"
import { ICON_SIZE } from "../features/config/Constants"
import { itemField } from "../features/hooks/useItemI18n"
import { JourneyNS } from "../features/i18n/constants"
import { RootParamList } from "../features/Navigation/types"
import { useProgram } from "../features/Providers/ProgramProvider"

interface ExercisePicture {
  uri: string
  id: string
  title: string
  url: string | undefined
}

interface ExercisesCarouselProps {
  isCurrentSession: boolean
  isPatient: boolean
  patientId: ID | undefined
  showedExercises: Exercise[]
}

const keyExtractor = (item: ExercisePicture) => item.id

const ExercisesCarousel: FC<ExercisesCarouselProps> = ({
  isCurrentSession,
  isPatient,
  patientId,
  showedExercises,
}) => {
  const isEmptyExo = _.isEmpty(showedExercises)
  const navigation = useNavigation<StackNavigationProp<RootParamList, "ExerciseNavigator">>()
  const { setPrefTabVideo } = useProgram()
  const { t } = useTranslation(JourneyNS)

  const {
    colors: {
      white: { highEmphasis: white },
      black: { disabledImages },
    },
    dimensions: { spacing },
    typography: { caption },
  } = useTheme()

  const s = useMemo(
    () => ({
      scrollViewSubtitle: [
        styles.scrollViewSubtitle,
        {
          width: "90%",
          marginTop: spacing,
          paddingHorizontal: spacing,
        },
      ],

      scrollView: {
        width: "100%",
        marginTop: spacing,
        paddingHorizontal: spacing,
      },
      imageView: [
        styles.imageView,
        {
          backgroundColor: white,
          marginRight: spacing,
          marginVertical: spacing / 2,
        },
      ],
      overlay: [
        styles.overlay,
        {
          backgroundColor: disabledImages,
        },
      ],
      textImage: [
        styles.textImage,
        {
          fontSize: caption.fontSize,
          paddingVertical: spacing / 4,
          paddingHorizontal: spacing / 2,
        },
      ],
    }),
    [spacing, white, disabledImages, caption.fontSize],
  )

  const onDisplayExercisesVideo = useCallback(
    (ex: Exercise) => {
      navigation.navigate("ExerciseNavigator", {
        screen: "Exercise",
        params: { selectedExercise: ex, patientId, watchOnly: true },
      })
    },
    [navigation, patientId],
  )

  const renderItem = useCallback(
    ({ item }) =>
      _.isUndefined(item) ? null : (
        <View style={s.imageView}>
          {!isPatient || isCurrentSession ? (
            <TouchableOpacity
              onPress={() => {
                setPrefTabVideo(false)
                onDisplayExercisesVideo(item)
              }}
            >
              <FastImage source={{ uri: item.image }} style={styles.image} key={item.id} />
            </TouchableOpacity>
          ) : (
            <>
              <View style={styles.iconContainer}>
                <VectorIcon color={white} size={ICON_SIZE} name="lock" category="MaterialIcons" />
              </View>
              <View>
                <View style={s.overlay} />
                <FastImage source={{ uri: item.image }} style={styles.image} key={item.id} />
              </View>
            </>
          )}
          <Subtitle2 style={s.textImage}>{itemField(item, "title", t)}</Subtitle2>
        </View>
      ),
    [
      s.imageView,
      s.textImage,
      isCurrentSession,
      onDisplayExercisesVideo,
      setPrefTabVideo,
      t,
      isPatient,
      s.overlay,
      white,
    ],
  )

  return (
    <>
      {!isEmptyExo ? (
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={false}
          data={showedExercises}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          style={s.scrollView}
        />
      ) : (
        <Subtitle2
          style={[s.scrollViewSubtitle, isCurrentSession && !isPatient && { color: white }]}
        >
          {t("exercicesView.noExos")}
        </Subtitle2>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  scrollViewSubtitle: {
    textAlign: "center",
  },
  imageView: {
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 8,
  },
  image: {
    width: 195,
    height: 127,
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
  },
  textImage: {
    width: 195,
    textAlign: "center",
    lineHeight: 16,
  },
  overlay: {
    zIndex: 1,
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8,
  },
  iconContainer: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 1,
  },
})

export default ExercisesCarousel
