import React, { FC, ReactElement, useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native"
import { RadioButton } from "react-native-paper"

import useTheme from "../../features/Theme/ThemeProvider"
import { Subtitle1 } from "../Texts"
import { Touchable } from "../Touchable"

interface IRadioElement {
  id: string
  label?: string
  textOnly?: boolean
  isChecked: boolean
  onPress: () => void
  borderless?: boolean
  selectedColor?: string
  touchableStyle?: StyleProp<ViewStyle>
  flexDirection?: "row" | "row-reverse"
  renderLabel?: (label?: string, isChecked?: boolean) => ReactElement
}
export type RadioElementProps = React.ComponentProps<typeof RadioButton> & IRadioElement

const RadioElement: FC<RadioElementProps> = ({
  label,
  onPress,
  isChecked,
  borderless,
  renderLabel,
  selectedColor,
  flexDirection,
  touchableStyle,
  textOnly = false,
  ...props
}) => {
  const {
    dimensions: { spacing },
    colors: { overrides, primary },
  } = useTheme()
  const s = useMemo(
    () => ({
      touchable: {
        flexDirection,
        paddingVertical: spacing / 2,
        backgroundColor: isChecked && selectedColor ? selectedColor : undefined,
      },
      subtitle: [
        styles.subtitle,
        { marginHorizontal: spacing / 2, textAlign: flexDirection === "row" ? "left" : "right" },
      ] as StyleProp<TextStyle>,
    }),
    [flexDirection, spacing, isChecked, selectedColor],
  )

  return (
    <Touchable
      {...{ onPress, borderless }}
      style={[s.touchable, touchableStyle]}
      rippleColor={overrides?.ripple || primary}
    >
      <>
        {renderLabel ? (
          renderLabel(label, isChecked)
        ) : label ? (
          <Subtitle1 style={s.subtitle}>{label}</Subtitle1>
        ) : null}
        {textOnly ? null : (
          <RadioButton.Android
            {...props}
            onPress={onPress}
            theme={undefined}
            status={isChecked ? "checked" : "unchecked"}
            color={overrides?.radioButton?.checked ?? primary}
          />
        )}
      </>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  subtitle: {
    flex: 1,
    alignSelf: "center",
  },
})

export default RadioElement
