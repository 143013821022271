import { Button, logger, useStyles, useTheme, VectorIcon } from "capsule"
import React, { FC, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { ICON_SIZE } from "../../features/config/Constants"
import { ExerciseNS } from "../../features/i18n/constants"
import { useProgram } from "../../features/Providers/ProgramProvider"
import { useVideo } from "../../features/Providers/VideoProvider"

interface IProps {
  readOnly?: boolean
  isPreviousDisabled?: boolean
  goBack?: () => Promise<boolean | undefined>
  onVideoPress?: () => void
  onEndExercise?: (afterEval?: boolean, skip?: boolean) => Promise<void>
  restartExercise?: () => void
  goToPreviousExercise?: () => void
  specialty?: string
  isFullScreenPrincipal?: boolean
}

const ExerciseVideoButtons: FC<IProps> = ({
  isPreviousDisabled,
  specialty,
  onEndExercise,
  goToPreviousExercise,
  isFullScreenPrincipal,
}) => {
  const { t } = useTranslation(ExerciseNS)
  const {
    colors: { black, white },
  } = useTheme()
  const { videoState, dispatch, actions } = useVideo()
  const { setPrefTabVideo } = useProgram()
  const s = useStyles(
    ({ dimensions: { spacing }, colors: { surface } }) => ({
      fullWidth: {
        flex: 1,
      },
      button: {
        borderRadius: 100,
        margin: spacing / 2,
      },
      contentButton: {
        width: 140,
        height: 48,
      },
      row: {
        paddingBottom: spacing / 2,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
      oncoButton: {
        height: 48,
        borderRadius: 8,
      },
      oncoLeftButton: {
        marginRight: spacing,
      },
      oncoRightButton: {
        flexDirection: "row-reverse",
      },
      oncoButtonContainer: {
        backgroundColor: surface.appUi,
        paddingBottom: spacing,
        paddingHorizontal: spacing,
      },
      skipButton: {
        borderWidth: 1,
        marginBottom: spacing,
      },
      skipButtonContent: {
        height: 36,
      },
      skipButtonLabel: {
        color: black.mediumEmphasis,
      },
    }),
    [],
  )

  const isOnco = useMemo(() => specialty === "onco", [specialty])

  const renderIcon = useMemo(
    () => (name: string, color?: string) => () => (
      <VectorIcon {...{ name, color }} size={ICON_SIZE} category="MaterialIcons" />
    ),
    [],
  )

  // Next Exercise Button
  const nextExercise = useCallback(
    (skip?: boolean) => {
      actions.pause()
      dispatch({ type: "next" })
      setPrefTabVideo(false)
      // noinspection JSIgnoredPromiseFromCall
      onEndExercise?.(undefined, skip)
    },
    [actions, dispatch, onEndExercise, setPrefTabVideo],
  )
  useEffect(() => {
    logger({ videoState })
  }, [videoState])

  return (
    <View style={s.oncoButtonContainer}>
      {isOnco ? (
        <Button
          disabled={videoState.disabledButtons || isFullScreenPrincipal}
          mode="outlined"
          style={[s.skipButton, { opacity: isFullScreenPrincipal ? 0 : 1 }]}
          labelStyle={s.skipButtonLabel}
          contentStyle={s.skipButtonContent}
          onPress={() => nextExercise(true)}
          icon={renderIcon("not-interested", black.mediumEmphasis)}
        >
          {t("cannot")}
        </Button>
      ) : null}
      {!isFullScreenPrincipal && (
        <View style={s.row}>
          <Button
            disabled={isPreviousDisabled ?? videoState.disabledButtons}
            style={[s.fullWidth, s.oncoLeftButton]}
            contentStyle={s.oncoButton}
            onPress={goToPreviousExercise}
            icon={renderIcon("skip-previous", white.highEmphasis)}
          >
            {t("previous")}
          </Button>
          <Button
            disabled={videoState.disabledButtons}
            style={s.fullWidth}
            contentStyle={[s.oncoButton, { flexDirection: "row-reverse" }]}
            onPress={() => nextExercise(false)}
            icon={renderIcon("skip-next", white.highEmphasis)}
          >
            {t("next")}
          </Button>
        </View>
      )}
    </View>
  )
}
export default ExerciseVideoButtons
