export interface VideoState {
  paused: boolean
  restart: number
  disabled: boolean
  firstPress: boolean
  inProgress: boolean
  disabledButtons: boolean
}

export type ActionName = "next" | "restart" | "start" | "paused" | "play" | "ready"
export interface VideoAction {
  type: ActionName
}

export const defaultValues: VideoState = {
  restart: 0,
  paused: true,
  disabled: true,
  firstPress: true,
  inProgress: false,
  disabledButtons: true,
}

export function videoStateReducer(state: VideoState, action: VideoAction): VideoState {
  switch (action.type) {
    case "next": {
      return {
        ...state,
        restart: 0,
        paused: true,
        disabled: true,
        firstPress: true,
        inProgress: false,
        disabledButtons: true,
      }
    }
    case "restart":
      return {
        ...state,
        paused: true,
        disabled: true,
        firstPress: true,
        inProgress: false,
        restart: state.restart + 1,
      }
    case "start":
      return {
        ...state,
        paused: false,
        disabled: false,
        inProgress: true,
        firstPress: false,
        disabledButtons: false,
      }
    case "paused":
      return {
        ...state,
        paused: true,
        inProgress: false,
      }
    case "play":
      return {
        ...state,
        paused: !state.paused,
      }
    case "ready":
      return {
        ...state,
        disabled: false,
        firstPress: true,
      }
    default:
      return state
  }
}
