import _ from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import SettingsList from "../../components/SettingsList"
import { SettingsNS } from "../../features/i18n/constants"

const OncoSettingsScreen = () => {
  const { t } = useTranslation()

  const oncoItems = useMemo(
    () =>
      _.filter([
        {
          name: t(`${SettingsNS}:operations`),
          route: "OncoSurgeries",
        },
        {
          name: t(`${SettingsNS}:therapies`),
          route: "Therapies",
        },
        {
          name: t(`${SettingsNS}:othersPathology`),
          route: "OtherPathologies",
        },
      ]),
    [t],
  )

  return <SettingsList listKey="oncoSettingList" listItems={oncoItems} />
}

export default OncoSettingsScreen
