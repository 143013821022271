import { firestore, IUserContext, userContext } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { ID } from "../../common/CommonUserData"
import { collections } from "../../common/types"
import currentDateMs from "../hooks/useDateMock"
import { Message } from "../models/Message"
import { getFirestoreDate, getFirestoreTimestamp } from "../models/Types"
import { AppUserData, Patient } from "../models/UserData"
import { transform } from "../models/UserDataFunctions"

interface MessageContext {
  unreadMessages: Record<ID, boolean>
  sendMessage: (patient: ID, message: Message) => Promise<void>
  isMessageModalVisible: boolean
  setIsMessageModalVisible: Dispatch<SetStateAction<boolean>>
}

interface IProps {
  children: ReactNode
}

export const promiseNoop = async () => _.noop()

const defaultValues: Omit<MessageContext, "setIsMessageModalVisible"> = {
  unreadMessages: {},
  sendMessage: promiseNoop,
  isMessageModalVisible: false,
}

export const messageContext = createContext<MessageContext>(defaultValues as MessageContext)

const MessageProvider: FC<IProps> = ({ children }) => {
  const { userData, userDocRef, user } = useContext<IUserContext<AppUserData>>(userContext)
  const [unreadMessages, setUnreadMessages] = useState(defaultValues.unreadMessages)
  const [isMessageModalVisible, setIsMessageModalVisible] = useState(
    defaultValues.isMessageModalVisible,
  )
  const [patients] = useCollectionData<Patient>(
    user
      ? ((firestore()
          .collection(collections.LOGIN)
          .where(
            "medicalTeam",
            "array-contains",
            user?.uid,
          ) as unknown) as firebase.firestore.Query<Patient>)
      : null,
    { idField: "id", transform },
  )

  const sendMessage = useCallback(
    async (patient: ID, message: Message) => {
      await firestore()
        .collection(collections.LOGIN)
        .doc(patient)
        .collection(collections.MESSAGES)
        .add(message)
      await firestore()
        .collection(collections.LOGIN)
        .doc(patient)
        .set({ lastMessage: message }, { merge: true })
      await userDocRef?.update({ [`patientLastRead.${patient}`]: message.timestamp })
    },
    [userDocRef],
  )

  useEffect(() => {
    if (!patients) {
      return
    }
    const activePatients = patients?.filter(
      item =>
        item.programEnd &&
        dayjs(currentDateMs()).isBefore(getFirestoreDate(item.programEnd)) &&
        !item.archived,
    )
    const data = _.map(activePatients, (patient: Patient) => {
      const patientLastRead = userData?.patientLastRead?.[patient.id]
        ? getFirestoreTimestamp(userData?.patientLastRead?.[patient.id])
        : undefined
      if (!patient.lastMessage) {
        return { [patient.id]: false }
      }
      if (!patientLastRead && patient.lastMessage) {
        return { [patient.id]: true }
      }
      if (patientLastRead) {
        const date = getFirestoreTimestamp((patient.lastMessage as Message)?.timestamp).toDate()
        return {
          [patient.id]: dayjs(date).isAfter(patientLastRead.toDate()),
        }
      }
      return { [patient.id]: false }
    })
    setUnreadMessages(_.reduce(data, (result, value) => ({ ...result, ...value }), {}))
  }, [patients, userData?.patientLastRead, userData?.patients])

  const contextValue: MessageContext = {
    unreadMessages,
    sendMessage,
    isMessageModalVisible,
    setIsMessageModalVisible,
  }

  return <messageContext.Provider value={contextValue}>{children}</messageContext.Provider>
}

export default MessageProvider
