import React, { FC, PropsWithChildren, useMemo } from "react"
import { Platform } from "react-native"
import { Button as PaperButton } from "react-native-paper"

import useTheme from "../../features/Theme/ThemeProvider"

export type ButtonProps = React.ComponentProps<typeof PaperButton>

const Button: FC<PropsWithChildren<ButtonProps>> = ({ mode, theme, labelStyle, ...props }) => {
  const {
    button,
    typography,
    colors: { primary, overrides },
  } = useTheme()
  const buttonMode = mode ?? button?.mode
  const color = props.buttonColor
    ? props.buttonColor
    : Platform.OS === "android" && buttonMode !== "contained"
    ? overrides?.ripple
    : overrides?.ripple || primary // affect ripple and contained background color on Android
  const { style, contentStyle } = useMemo(
    () => ({
      style: [
        Platform.OS === "ios" && !props.disabled
          ? {
              backgroundColor: undefined,
            }
          : undefined,
        props.style,
      ],
      contentStyle: [
        Platform.OS === "ios" && buttonMode === "contained" && !props.disabled
          ? {
              backgroundColor: overrides?.button || primary,
            }
          : undefined,
        {
          height: button?.height,
          // width: 150,
        },
        props.contentStyle,
      ],
    }),
    [props, buttonMode, overrides?.button, primary, button?.height],
  )

  return (
    <PaperButton
      {...props}
      mode={buttonMode}
      uppercase={button?.uppercase}
      {...{ color, style, contentStyle }}
      labelStyle={[typography.button, labelStyle]}
      {...(theme?.roundness || button?.roundness
        ? { theme: { roundness: theme?.roundness || button?.roundness } }
        : undefined)}
    />
  )
}

export default Button
