import { useField, useFormikContext } from "formik"
import React, { FC, useCallback, useMemo } from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import { TextInput, TextInputProps } from "../TextInput"
import { Caption } from "../Texts"
import { InputContainerStyle } from "./InputContainer"

export interface IFormikTextInputProps {
  name: string
  styles?: InputContainerStyle
  viewStyle?: StyleProp<ViewStyle>
  prevTextChange?: (e: string) => string
  numberOfLines?: number
  customHeight?: number
  multiline?: boolean
  autoCorrect?: boolean
  autoComplete?: string
  autoCapitalize?: string
  inputInfo?: string
}

export const FormikTextInput: FC<IFormikTextInputProps & TextInputProps> = ({
  name,
  styles,
  viewStyle,
  prevTextChange,
  changeUnderline = true,
  numberOfLines = 1,
  multiline = false,
  autoComplete,
  autoCorrect,
  customHeight,
  autoCapitalize,
  inputInfo,
  ...props
}) => {
  const {
    colors: { primary, overrides, error },
  } = useTheme()
  const { handleChange, handleBlur, setTouched, isSubmitting } = useFormikContext()
  const [field, meta] = useField(name)

  const onChangeText = useCallback(
    (e: string | React.ChangeEvent<any>) => {
      const res = prevTextChange?.(e as string) ?? e
      handleChange(name)(res)
      props.onChangeText?.(res as string)
    },
    [prevTextChange, handleChange, name, props],
  )

  const onTouchStart = useCallback(() => setTouched({ [name]: false }), [name, setTouched])
  const onSubmitEditing = useCallback(
    e => {
      setTouched({ [name]: true })
      props.onSubmitEditing?.(e)
    },
    [name, props, setTouched],
  )

  const s = useMemo(
    () => ({
      viewStyle: [styles?.child, viewStyle],
      caption: [styles?.error ?? { color: error }],
    }),
    [error, styles, viewStyle],
  )

  const inputStyle = useMemo(
    () => [
      StyleSheetStyles.input,
      props.style,
      multiline ? {} : { maxHeight: 50 },
      customHeight ? { height: customHeight } : {},
    ],
    [props.style, customHeight, multiline],
  )

  return (
    <View style={s.viewStyle}>
      <TextInput
        {...props}
        value={field.value}
        multiline={multiline}
        onBlur={props.onBlur ?? handleBlur(name)}
        numberOfLines={numberOfLines}
        textAlignVertical="bottom"
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        autoCapitalize={autoCapitalize}
        style={inputStyle}
        error={meta.touched && !isSubmitting && !!meta.error}
        theme={{ colors: { primary: overrides?.textInput || primary } }}
        {...{ onTouchStart, onSubmitEditing, onChangeText, changeUnderline }}
      />
      {inputInfo ? <Caption>{inputInfo}</Caption> : null}
      {meta.touched && meta.error ? <Caption style={s.caption}>{meta?.error}</Caption> : null}
    </View>
  )
}

const StyleSheetStyles = StyleSheet.create({
  input: {
    justifyContent: "center",
  },
})
