import { useNavigation } from "@react-navigation/native"
import { NamesForm, NamesFormikConfig, NamesScreen, userContext, useTheme } from "capsule"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import * as Yup from "yup"

import { ProfileNS } from "../../features/i18n/constants"
import { updateUserData } from "../../features/models/UserDataFunctions"

interface IProps {
  isEdit: boolean
}

const PractitionerProfileScreen: FC<IProps> = ({ isEdit = false }) => {
  const {
    colors: {
      surface: { textInput, background },
    },
    dimensions: { spacing },
  } = useTheme()

  const { userDocRef, userData } = useContext(userContext)
  const navigation = useNavigation<any>()
  const { t } = useTranslation(ProfileNS)

  const s = useMemo(
    () => ({
      container: [styles.container, { backgroundColor: background }],
      inputStyle: { backgroundColor: textInput, marginBottom: spacing },
      viewStyle: [styles.viewStyle, { backgroundColor: background, paddingHorizontal: spacing }],
      childStyle: {
        child: { marginBottom: spacing },
      },
    }),
    [spacing, textInput, background],
  )

  const onSubmit = useCallback(
    (val: NamesForm) => {
      // noinspection JSIgnoredPromiseFromCall
      updateUserData(userDocRef, val)
      if (isEdit) {
        navigation.navigate("Account_Main")
      }
    },
    [isEdit, navigation, userDocRef],
  )

  return (
    <View style={s.container}>
      <NamesScreen
        inputStyle={s.inputStyle}
        viewStyle={s.viewStyle}
        isEmail={userData.email}
        isLastName={userData.lastName}
        formikConfig={NamesFormikConfig({
          onSubmit,
          initialValues: {
            firstName: userData?.firstName || "",
            lastName: userData?.lastName || "",
            email: userData?.email || "",
            otherEmail: userData?.otherEmail || "",
          },
          validationSchema: Yup.object({
            lastName: Yup.string().required(t("required.lastName", { ProfileNS })),
            firstName: Yup.string().required(t("required.firstName", { ProfileNS })),
            email: Yup.string()
              .email(t("required.email", { ProfileNS }))
              .required(t("wrongFormat.email", { ProfileNS })),
            otherEmail: Yup.string().email(t("wrongFormat.email", { ProfileNS })),
          }),
        })}
        buttonStyle={styles.buttonStyle}
        styles={s.childStyle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  buttonStyle: { marginTop: 0 },
  viewStyle: { marginVertical: 0 },
  container: { flex: 1 },
})

export default PractitionerProfileScreen
