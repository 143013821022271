import React, { createContext, PropsWithChildren, useCallback, useState } from "react"

import delay from "../../utils/delay"
import { logger } from "../../utils/logger"

const retNbrForFn = async (fn: (...args: any) => Promise<unknown>, nbr: number) =>
  fn().then(() => nbr)

const DEFAULT_TIMEOUT = 50

interface IProps {
  defaultTimeout: number
}

interface OptimisticValueProps<T> {
  value?: T
  ms?: number
  onNext?: () => void | Promise<void>
  handler?: (value?: T) => void | Promise<void>
}

export interface IOptimisticContext<T> {
  value?: T
  setValue: (props: OptimisticValueProps<T>) => void | Promise<void>
}

export const optimisticContext = createContext<IOptimisticContext<any>>(
  {} as IOptimisticContext<any>,
)

const OptimisticProvider = <T,>({
  children,
  defaultTimeout = DEFAULT_TIMEOUT,
}: PropsWithChildren<IProps>) => {
  const [optimisticValue, setOptimisticValue] = useState<T | undefined>()

  const setValue = useCallback(
    async ({ value, ms = 1500, handler, onNext }: OptimisticValueProps<T>) => {
      setOptimisticValue(value)
      try {
        const res = await Promise.race([
          retNbrForFn(async () => handler?.(), 0),
          retNbrForFn(async () => delay(ms), 1),
        ])
        // eslint-disable-next-line no-unused-expressions
        res === 0 && (await delay(defaultTimeout))
      } catch (e) {
        logger("optimistic error", e)
      } finally {
        setOptimisticValue(undefined)
        onNext?.()
      }
    },
    [defaultTimeout],
  )

  const contextValue: IOptimisticContext<T> = {
    setValue,
    value: optimisticValue,
  }

  return <optimisticContext.Provider value={contextValue}>{children}</optimisticContext.Provider>
}

export default OptimisticProvider
