import { ID, ProgramId, SpecialtyName } from "../../common/CommonUserData"
import { Exercise, Total } from "../../common/Phase"
import { Timestamp } from "./Types"

export interface Program {
  start: Timestamp
  end: Timestamp
  pathology: ID
  specialty: SpecialtyName
}

export type ExDuration = {
  skip?: boolean
  validation?: boolean
  start?: Timestamp
  end?: Timestamp
}

export interface FirestoreSession {
  phase: ID
  program?: ProgramId
  start: Timestamp
  end: Timestamp
  daySinceSurgery: number
  seqInDay: number
  abandoned?: boolean
  exercises: Record<ID, ExDuration>
}

export interface SessionDetails {
  id: ID
  start?: Timestamp
  end?: Timestamp
  docId?: ID // only when a session is unfinished
  unfinishedExIndex?: number // exercise id where the resumed session
  slot: string // hour
  duration: Total // minutes
  exercises: Total
  equipments: Total
}

export interface Daily {
  phase: ID
  program?: ID
  exercises: Exercise[]
  sessionDetails?: SessionDetails[]
}

// eslint-disable-next-line no-shadow
export enum NextSessionAction {
  CLOSE,
  NEXT,
  EVALUATION,
  EVALUATION_AND_FINISH,
}
