import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  Body2,
  Button,
  Caption,
  generateShadow,
  IUserContext,
  SectionSeparator,
  Subtitle1,
  userContext,
  useTheme,
  VectorIcon,
} from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, Image, ImageSourcePropType, StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"

import { images } from "../../assets/images"
import { ICON_SIZE } from "../../features/config/Constants"
import currentDateMs from "../../features/hooks/useDateMock"
import { HomeNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { programContext } from "../../features/Providers/ProgramProvider"
import { IHomeParamList } from "../MainScreen/MainTabs"
interface ExercisePicture {
  uri: string
  id: string
}

const keyExtractor = (item: ExercisePicture) => item.id

const HomeOrthoView: FC = () => {
  const { t, i18n } = useTranslation(HomeNS)
  const {
    dimensions: { spacing },
    colors: {
      white: { highEmphasis: white },
      black: { highEmphasis: black },
    },
  } = useTheme()
  const navigation = useNavigation<StackNavigationProp<IHomeParamList, "Home_Main">>()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { isSessionReady, currentSession, phaseExercises } = useContext(programContext)
  const programStartDate = dayjs(userData?.programStart).toDate()
  const currentDate = dayjs(currentDateMs()).endOf("day").toDate()
  const formatedStartDate = dayjs(programStartDate)
    .locale(i18n.language)
    .format("dddd DD MMMM YYYY")
  const isProgToCome = programStartDate && currentDate <= programStartDate
  const isEmptyExo = _.isEmpty(phaseExercises)
  const exercisePictures: ExercisePicture[] = useMemo(
    () =>
      currentSession
        ? _(phaseExercises)
            .map(ex => ({ id: ex.id, uri: ex.image }))
            .value()
        : [],
    [currentSession, phaseExercises],
  )

  const s = useMemo(
    () => ({
      view: [
        styles.sessionContainer,
        {
          backgroundColor: white,
          marginHorizontal: spacing,
          paddingVertical: spacing,
        },
        generateShadow(4),
      ],
      body: [
        styles.body,
        {
          padding: spacing / 2,
        },
      ],
      title: {
        color: black,
        paddingTop: spacing * 2,
        paddingBottom: spacing,
        paddingHorizontal: spacing,
      },
      imageView: [
        {
          backgroundColor: white,
          paddingVertical: spacing,
        },
      ],
      scrollView: {
        paddingHorizontal: spacing / 2,
      },
      content: { paddingTop: spacing, marginHorizontal: spacing },
      contentAdd: { marginHorizontal: spacing, paddingVertical: spacing / 2 },
      image: [
        styles.image,
        {
          marginHorizontal: spacing / 2,
        },
      ],
      caption: [
        styles.caption,
        {
          paddingVertical: spacing / 2,
        },
      ],
      iconText: { marginLeft: spacing / 2 },
      icon: {
        marginLeft: spacing / 4,
      },
      iconView: [
        styles.iconView,
        {
          marginBottom: spacing,
        },
      ],
    }),
    [white, black, spacing],
  )

  const renderItem = useCallback(
    ({ item }) =>
      _.isUndefined(item) ? null : (
        <FastImage source={{ uri: item.uri }} style={s.image} key={item.id} />
      ),
    [s.image],
  )

  const onPress = useCallback(() => navigation.navigate("ExerciseNavigator"), [navigation])
  const formatTime = useCallback(
    (time: string) => {
      if (i18n.language.startsWith("en")) {
        const [hours, minutes] = time.split(":").map(Number)
        const ampm = hours >= 12 ? "PM" : "AM"
        const formattedHours = hours % 12 || 12
        return `${formattedHours}${`:${minutes.toString().padStart(2, "0")} `}${ampm}`
      }
      return time
    },
    [i18n.language],
  )

  return (
    <>
      <SectionSeparator />
      <View style={s.imageView}>
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={false}
          style={s.scrollView}
          data={exercisePictures}
          {...{ keyExtractor, renderItem }}
        />
        <View style={s.content}>
          {currentSession && !isEmptyExo ? (
            <View>
              <View>
                <Subtitle1 emphasis="high">
                  {t("session", {
                    time: formatTime(currentSession.slot),
                  })}
                </Subtitle1>
                <View style={s.caption}>
                  <Caption emphasis="high">
                    {t("descriptionWithEquipments", {
                      count: currentSession.exercises,
                      time: currentSession.duration,
                      equipment: currentSession.equipments,
                    })}
                  </Caption>
                  <Image style={s.icon} source={images.equipment as ImageSourcePropType} />
                </View>
              </View>
              <Button {...{ onPress }}>{t(isSessionReady ? "start" : "seeExercises")}</Button>
            </View>
          ) : isProgToCome ? (
            <View style={s.view}>
              <View style={s.contentAdd}>
                <View style={s.iconView}>
                  <VectorIcon
                    {...{ name: "calendar-today" }}
                    category="MaterialIcons"
                    size={ICON_SIZE}
                  />
                  <View style={styles.startProgView}>
                    <Subtitle1 style={s.iconText} emphasis="high">
                      {t("sessionToCome")}
                    </Subtitle1>
                    <Subtitle1 style={s.iconText} emphasis="high">
                      {formatedStartDate}
                    </Subtitle1>
                  </View>
                </View>
                <Button {...{ onPress }} disabled>
                  {t("sessionToComeButton")}
                </Button>
              </View>
            </View>
          ) : isEmptyExo ? (
            <View style={s.contentAdd}>
              <Body2 emphasis="high" style={s.body}>
                {t("noExo")}
              </Body2>
            </View>
          ) : (
            <View style={s.contentAdd}>
              <Body2 emphasis="high" style={s.body}>
                {t("sessionEnd")}
              </Body2>
            </View>
          )}
        </View>
      </View>
      <SectionSeparator />
    </>
  )
}

const styles = StyleSheet.create({
  image: {
    width: 176,
    height: 176,
    borderRadius: 8,
  },
  caption: {
    alignItems: "center",
    flexDirection: "row",
  },
  body: {
    alignSelf: "center",
  },
  startProgView: {
    flex: 1,
    flexDirection: "column",
  },
  iconView: {
    flex: 1,
    flexDirection: "row",
  },
  sessionContainer: {
    flex: 1,
    width: "90%",
    borderRadius: 8,
    alignSelf: "center",
  },
})

export default HomeOrthoView
