import { ExerciseId, ID } from "./CommonUserData"
import { BaseData } from "./Pathology"

export type Total = number

export const tabKeys = ["instructions", "goals", "errors", "progression"] as const
export const videoKeys = [
  "instructionsVideo",
  "goalsVideo",
  "errorsVideo",
  "progressionVideo",
  "video",
] as const

export type Tabs = typeof tabKeys[number]
export type VideoKeys = typeof videoKeys[number]

export interface Exercise extends BaseData<"title" | VideoKeys | Tabs> {
  phase: ID
  intensity: number
  image: string
  recurrence: number
  reps: number
  series: number
  duration: number
  order: number
  disabled: boolean
  video?: string
  instructionsVideo?: string
  goalsVideo?: string
  errorsVideo?: string
  progressionVideo?: string
  equipment: ID[]
  eels?: ExerciseId[] // use for ED and formatted exercise after onco algo
  dynamicId?: ExerciseId // use for formatted exercise after onco algo
}

export interface Phase
  extends BaseData<
    | "objective"
    | "title"
    | "description"
    | "descriptionPatient"
    | "descriptionPhysician"
    | "titlePatient"
    | "titlePhysician"
  > {
  pathology: ID
  order: number
  startDay: number
  endDay: number
  image?: string
  faq?: ID[]
  todo?: ID[]
  showSatisfaction: boolean
  showPatientSurvey?: boolean
  showProtocoleTab: boolean
  exercises?: ExerciseId[]
  isDisabled?: boolean
  blockDates?: {
    startDay: number
    endDay: number
  }
}

export interface Eel {
  pathology: ID
  id: ID
  exercises?: ExerciseId[]
}

export interface AdditionalDays {
  [phaseId: string]: number
}

export interface Equipment extends BaseData<"title"> {
  id: ID
}
