import React, { FC, ReactNode, useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import { Caption, Subtitle1 } from "../Texts"
import { VectorIcon, VectorIconProps } from "../VectorIcon"

interface IProps {
  title: string
  titleStyle?: StyleProp<TextStyle>
  titleContainerStyle?: StyleProp<ViewStyle>
  alertIcon?: VectorIconProps
  alertLabel?: string
  alertLabelStyle?: StyleProp<ViewStyle>
  alertContainerStyle?: StyleProp<ViewStyle>
  date: string
  dateStyle?: StyleProp<TextStyle>
  message?: string
  messageStyle?: StyleProp<TextStyle>
  containerStyle?: StyleProp<ViewStyle>
  renderMessage?: ReactNode
}

export const MessageItem: FC<IProps> = ({
  title,
  titleStyle,
  titleContainerStyle,
  alertLabel,
  alertIcon,
  alertLabelStyle,
  alertContainerStyle,
  containerStyle,
  date,
  dateStyle,
  message,
  messageStyle,
  renderMessage,
}) => {
  const {
    colors: {
      danger,
      black: { highEmphasis: black },
      white: { highEmphasis: white },
      primary,
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      alertLabel: {
        marginHorizontal: spacing / 2,
        color: danger,
      },
      message: {
        color: black,
      },
      mainContainer: {
        backgroundColor: white,
        padding: spacing,
      },
      titleContainer: [
        styles.title,
        {
          marginBottom: spacing / 2,
          color: primary,
        },
      ],
      alertContainer: [
        styles.alertContainer,
        {
          marginHorizontal: spacing / 2,
        },
      ],
      title: {
        color: primary,
      },
      date: [
        styles.date,
        {
          color: black,
        },
      ],
    }),
    [black, danger, primary, spacing, white],
  )

  return (
    <View style={[s.mainContainer, containerStyle]}>
      <View style={[s.titleContainer, titleContainerStyle]}>
        <Subtitle1 style={[s.title, titleStyle]}>{title}</Subtitle1>
        <View style={[s.alertContainer, alertContainerStyle]}>
          {alertIcon ? (
            <VectorIcon
              size={alertIcon.size ?? 16}
              name={alertIcon.name ?? "warning"}
              color={alertIcon.color ?? danger}
              category={alertIcon.category ?? "MaterialIcons"}
            />
          ) : null}
          {alertLabel ? (
            <Caption style={[s.alertLabel, alertLabelStyle]}>{alertLabel}</Caption>
          ) : null}
        </View>
        <Subtitle1 style={[s.date, dateStyle]}>{date}</Subtitle1>
      </View>
      {renderMessage ?? <Caption style={[s.message, messageStyle]}>{message}</Caption>}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    flexDirection: "row",
  },
  alertContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  date: {
    position: "absolute",
    right: 0,
  },
})
