import { Body2, IUserContext, SectionSeparator, userContext, useTheme } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"

import { ItemList } from "../../common/List"
import { Todo } from "../../common/Todo"
import ListItem from "../../components/ListItem"
import currentDateMs from "../../features/hooks/useDateMock"
import { HomeNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { programContext } from "../../features/Providers/ProgramProvider"
import HomeSectionHeader from "./HomeSectionHeader"

export const renderSeparator = () => <SectionSeparator />

const keyExtractor = (item: Todo) => item.id

const HomeTodoView = () => {
  const { t } = useTranslation(HomeNS)
  const {
    dimensions: { spacing },
    colors: {
      black: { separator },
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { phaseTodos } = useContext(programContext)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const currentDate = dayjs(currentDateMs()).endOf("day").toDate()
  const programStartDate = dayjs(userData?.programStart).toDate()
  const isProgToCome = programStartDate && currentDate <= programStartDate
  const isDone = _.every(phaseTodos, todo => userData?.doneTasks?.[todo.id])
  const isOrthoOrImplant = useMemo(
    () => userData?.specialty === "ortho" || userData?.specialty === "implant",
    [userData?.specialty],
  )
  const isEmptyTodo = _.isEmpty(phaseTodos)

  const todosWithoutProms = phaseTodos?.filter(
    todo => !todo.url?.startsWith("https://doctup.page.link"),
  )

  const s = useMemo(
    () => ({
      view: [
        {
          backgroundColor: white,
        },
      ],
      item: {
        marginVertical: spacing,
      },
      separator: [
        styles.separator,
        {
          backgroundColor: separator,
        },
      ],
      body: [styles.body, { padding: spacing }],
    }),
    [separator, spacing, white],
  )

  const renderItem: ListRenderItem<ItemList> = useCallback(
    ({ item }) => (_.isUndefined(item) ? null : <ListItem {...{ item }} />),
    [],
  )

  return (
    <View>
      {isEmptyTodo && !isProgToCome ? null : <HomeSectionHeader title={t("todo")} />}
      {isProgToCome && isOrthoOrImplant ? (
        <Body2 emphasis="high" style={s.body}>
          {t("todoToCome")}
        </Body2>
      ) : isEmptyTodo ? null : isDone ? (
        <Body2 emphasis="high" style={s.body}>
          {t("todoEnd")}
        </Body2>
      ) : (
        <FlatList
          style={s.view}
          {...{ keyExtractor, renderItem }}
          data={userData?.displayProms !== false ? phaseTodos : todosWithoutProms}
          ListFooterComponent={renderSeparator}
          ListHeaderComponent={renderSeparator}
          ItemSeparatorComponent={renderSeparator}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  separator: {
    height: 1,
  },
  body: {
    alignSelf: "center",
  },
})

export default HomeTodoView
