import React, { FC, ReactNode, RefObject, useMemo } from "react"
import { TextInput as NativeInput } from "react-native"
import { TextInput as PaperTextInput } from "react-native-paper"

import useTheme from "../../features/Theme/ThemeProvider"

export type IPaperTextInputProps = React.ComponentProps<typeof PaperTextInput>

export interface ITextInputProps {
  hasValue?: boolean
  inputColor?: string
  children?: ReactNode
  changeUnderline?: boolean
  forwardRef?: RefObject<NativeInput>
  autoCorrect?: boolean
  autoComplete?: string
  autoCapitalize?: string
}

export type TextInputProps = ITextInputProps & IPaperTextInputProps

const TextInput: FC<TextInputProps> = ({
  inputColor,
  forwardRef,
  error,
  changeUnderline = true,
  autoComplete,
  autoCorrect,
  autoCapitalize,
  ...props
}) => {
  const {
    colors: {
      primary,
      overrides,
      surface,
      black: { transparent },
    },
    dimensions: { textInputStyle },
  } = useTheme()
  const textInputColor = inputColor || overrides?.textInput || primary
  const hasValue = props.hasValue || (props.value && props.value.length > 0)
  const { style } = useMemo(
    () => ({
      style: [
        textInputStyle,
        {
          backgroundColor: surface.textInput,
        },
        props.style,
      ],
    }),
    [props.style, surface.textInput, textInputStyle],
  )

  return (
    <PaperTextInput
      {...props}
      {...{ style }}
      error={error}
      ref={forwardRef}
      theme={{ colors: { primary: textInputColor, background: transparent } }}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      autoCapitalize={autoCapitalize}
      underlineColor={
        changeUnderline ? (hasValue ? textInputColor : transparent) : props.underlineColor
      }
    />
  )
}

export default TextInput
