import { useTheme } from "capsule/features/Theme"
import { FormikHelpers } from "formik"
import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import SelectDropdown from "react-native-select-dropdown"
import FontAwesome from "react-native-vector-icons/FontAwesome"

import { IFormikTextInputProps } from "../FormBis"
import { CountriesList } from "../Screens"
import { TextInput, TextInputProps } from "../TextInput"

interface IProps {
  countries: CountriesList[]
  setIfValid?: (text: string) => void
  inputStyle?: StyleProp<ViewStyle>
  langageBtnStyle?: StyleProp<ViewStyle>
  dropdownBtnStyle?: StyleProp<ViewStyle>
  phoneInputContainer?: StyleProp<ViewStyle>
  setFieldValue?: FormikHelpers<any>["setFieldValue"]
}

const PhoneNumberInput: FC<IProps & TextInputProps & Partial<IFormikTextInputProps>> = ({
  onChangeText,
  forwardRef,
  countries,
  setIfValid,
  setFieldValue,
  inputStyle,
  phoneInputContainer,
  langageBtnStyle,
  dropdownBtnStyle,
  ...props
}) => {
  const [value, setValue] = useState<string>(countries[0].callingCode)
  const [selectedCountry, setSelectedCountry] = useState<CountriesList>(countries[0])
  const {
    colors: { surface },
    typography: { caption },
  } = useTheme()
  const { t } = useTranslation()

  const WebSelectDropdown = ({ data, defaultValue, onSelect }) => (
    <select
      value={defaultValue.title}
      onChange={e => {
        // @ts-ignore
        const selectedWebCountry = e.target.value
        const selectedItem = data.find(item => item.title === selectedWebCountry)
        onSelect(selectedItem)
      }}
      style={{
        width: "100%",
        padding: 8,
        backgroundColor: surface.textInput,
        marginRight: 8,
        border: "none",
        marginBottom: 0,
        fontFamily: "Montserrat",
        fontSize: caption.fontSize,
      }}
    >
      {data.map(item => (
        <option key={item.code} value={item.title}>
          {`${item.flag} ${t(`fields.label.${item.title}`)}`}
        </option>
      ))}
    </select>
  )
  const onFormatPhoneNumber = (val: string) => {
    try {
      const libPhoneNumber = require("libphonenumber-js")

      if (libPhoneNumber) {
        const { parsePhoneNumberFromString, formatIncompletePhoneNumber } = libPhoneNumber
        const phoneNumber = parsePhoneNumberFromString(val, selectedCountry.code)

        if (phoneNumber && phoneNumber.nationalNumber.length > 9) {
          return
        }
        const newVal = formatIncompletePhoneNumber(val, selectedCountry.code)
        if (phoneNumber) {
          const isValid = phoneNumber.isValid()
          setIfValid?.(isValid ? formatIncompletePhoneNumber(val, selectedCountry.code) : "")
          if (props.name) {
            setFieldValue?.(
              props.name,
              phoneNumber.isPossible() ? phoneNumber.formatInternational() : "",
            )
          }
        }
        setValue(newVal)
        onChangeText?.(newVal)
      }
    } catch (e) {
      const newVal = props.value && props.value.length > 2 ? props.value : ""
      setValue(newVal)
      onChangeText?.(newVal)
      if (props.name) {
        setFieldValue?.(props.name, newVal)
      }
    }
  }
  const style = [inputStyle, { backgroundColor: surface.textInput }]

  return (
    <View style={phoneInputContainer}>
      {Platform.OS === "web" ? (
        <WebSelectDropdown
          data={countries}
          defaultValue={selectedCountry}
          onSelect={selectedItem => {
            setSelectedCountry(selectedItem)
            setValue(selectedItem.callingCode)
          }}
        />
      ) : (
        <SelectDropdown
          data={countries}
          defaultValue={selectedCountry}
          buttonStyle={langageBtnStyle}
          buttonTextAfterSelection={selectedItem => selectedItem.flag}
          onSelect={selectedItem => {
            setSelectedCountry(selectedItem)
            setValue(selectedItem.callingCode)
          }}
          rowTextForSelection={item => `${item.flag} ${item.title}`}
          renderDropdownIcon={isOpened => (
            <FontAwesome name={isOpened ? "chevron-up" : "chevron-down"} color="#444" size={18} />
          )}
          dropdownIconPosition="right"
          dropdownStyle={dropdownBtnStyle}
          buttonTextStyle={styles.textStyle}
          rowTextStyle={styles.textStyle}
        />
      )}
      <TextInput
        {...props}
        keyboardType="phone-pad"
        onChangeText={onFormatPhoneNumber}
        {...{ style, value, forwardRef }}
      />
    </View>
  )
}

export default PhoneNumberInput

const styles = StyleSheet.create({
  textStyle: {
    fontFamily: "Montserrat",
  },
})
