import { IUserContext, OptimisticSwitch, Subtitle2, userContext, useTheme } from "capsule"
import _ from "lodash"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { App } from "../../common/App"
import { NavigationNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"

const AppsList = ({ isAppSwitchOn, onToggleAppSwitch, appsData }) => {
  const { t } = useTranslation(NavigationNS)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)

  const {
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      button: {
        margin: spacing,
      },
    }),
    [spacing],
  )

  return (
    <View>
      {appsData.map((item: App) => (
        <View key={item.id} style={styles.switchContainer}>
          <OptimisticSwitch
            style={s.button}
            value={
              userData && _.includes(userData.apps, item.id)
                ? true
                : isAppSwitchOn
                ? isAppSwitchOn[item.id]
                : null
            }
            onValueChange={val => onToggleAppSwitch(item.id, val)}
          />
          <Subtitle2>
            {_.includes(userData?.apps, item.id)
              ? t("Applications.disable")
              : t("Applications.enable")}{" "}
            {item.name}
          </Subtitle2>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  switchContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})

export default AppsList
