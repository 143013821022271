import React, { FC, useMemo } from "react"
import { StyleSheet } from "react-native"
import { Snackbar as PaperSnackbar } from "react-native-paper"
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context"

import { ISnackInternal } from "../../features/Providers"
import { maxWidth } from "../../features/Theme/dimensions"
import useTheme from "../../features/Theme/ThemeProvider"
import getFooterHeight from "../../utils/getFooterHeight"
import { Body2 } from "../Texts"

interface IProps {
  snack: ISnackInternal
  bottomHeight?: number
  hasBottomNav?: boolean
}

const Snackbar: FC<IProps> = ({
  snack: { message, style, ...rest },
  hasBottomNav,
  bottomHeight,
}) => {
  const insets = useSafeAreaInsets()
  const {
    roundness,
    colors: {
      white: { highEmphasis: white },
      black: { highEmphasis: black },
      overrides,
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      safeStyle: {
        marginBottom:
          hasBottomNav && bottomHeight ? bottomHeight + getFooterHeight() : insets.bottom,
      },
      snackbar: [
        styles.snackbar,
        {
          borderRadius: !roundness || roundness === 0 ? 4 : roundness,
          backgroundColor: overrides?.snack?.background ?? black,
        },
        style,
      ],
    }),
    [
      black,
      bottomHeight,
      hasBottomNav,
      insets.bottom,
      overrides?.snack?.background,
      roundness,
      style,
    ],
  )

  return (
    <SafeAreaView style={s.safeStyle}>
      <PaperSnackbar {...{ ...rest }} style={s.snackbar}>
        <Body2 color={overrides?.snack?.text ?? white}>{message}</Body2>
      </PaperSnackbar>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  snackbar: {
    maxWidth,
    bottom: 0,
    alignSelf: "center",
    position: "absolute",
  },
})

export default Snackbar
