import { useFormikContext } from "formik"
import React, { FC } from "react"
import { Button } from "react-native-paper"

interface IProps {
  title: string
}

export const ResetButton: FC<IProps> = ({ title }) => {
  const { handleReset } = useFormikContext()

  return <Button onPress={handleReset}>{title}</Button>
}
