import { useTheme } from "capsule"
import _ from "lodash"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { ICON_SIZE_MEDIUM, ICON_SIZE_SMALL } from "../features/config/Constants"
import { JourneyNS } from "../features/i18n/constants"
import ColorCircle from "./ColorCircle"
import LastEvalItem from "./LastEvalItem"
import { RadioItem } from "./Rating"

interface EvalItemsListProps {
  effort: RadioItem | undefined
  pain: RadioItem | undefined
  fatigue: RadioItem | undefined
  morning: any
  isOnco: boolean
}

const EvalItemsList: FC<EvalItemsListProps> = ({ effort, pain, fatigue, morning, isOnco }) => {
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation(JourneyNS)
  const s = useMemo(
    () => ({
      evalContainer: [
        styles.evaluation,
        {
          backgroundColor: white,
          marginTop: spacing,
          paddingHorizontal: spacing / 4,
        },
      ],
      colorCircle: [
        styles.colorCircle,
        {
          height: ICON_SIZE_MEDIUM,
        },
      ],
    }),
    [spacing, white],
  )

  const stiffnessColor = morning?.[0]?.stiffness === "yes" ? "#FFAFA4" : "#B0FFA4"
  const stiffnessIcon = morning?.[0]?.stiffness === "yes" ? "check" : "close"
  return (
    <View style={s.evalContainer}>
      {pain ? (
        <LastEvalItem label={t("eval.pain")} value={pain.label}>
          <View style={styles.smileyCircle}>{pain.checked}</View>
        </LastEvalItem>
      ) : null}
      {effort ? (
        <LastEvalItem label={t("eval.effort")} value={effort.label}>
          <View style={s.colorCircle}>{effort.checked}</View>
        </LastEvalItem>
      ) : null}
      {fatigue && isOnco ? (
        <LastEvalItem label={t("eval.fatigue")} value={fatigue.label}>
          <View style={styles.smileyCircle}>{fatigue.checked}</View>
        </LastEvalItem>
      ) : null}
      {morning?.[0]?.stiffness && !isOnco ? (
        <LastEvalItem
          label={t("eval.stiffness")}
          value={_.capitalize(t(`common:button.${morning?.[0]?.stiffness}`))}
        >
          <View style={s.colorCircle}>
            <ColorCircle
              color={primary}
              backgroundColor={stiffnessColor}
              size={ICON_SIZE_MEDIUM}
              icon={{
                name: stiffnessIcon,
                color: primary,
                size: ICON_SIZE_SMALL,
                category: "MaterialCommunityIcons",
              }}
            />
          </View>
        </LastEvalItem>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  evaluation: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  colorCircle: {
    top: 2.5,
  },
  smileyCircle: {
    top: 1.2,
  },
})

export default EvalItemsList
