import { DependencyList, useMemo } from "react"
import { StyleSheet } from "react-native"

import { Theme, useTheme } from "../features/Theme"

export const useStyles = <
  AppTheme extends Theme,
  T extends StyleSheet.NamedStyles<T> | StyleSheet.NamedStyles<any>
>(
  // eslint-disable-next-line no-shadow
  create: (theme: Theme & AppTheme) => T,
  deps: DependencyList = [],
): T => {
  const theme = useTheme<Theme & AppTheme>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => StyleSheet.create(create(theme)), [...deps, theme])
}
