import { colors } from "capsule"

import { ICON_SIZE } from "../features/config/Constants"

export const renderIcon = {
  size: ICON_SIZE,
  name: "chevron-right",
  color: colors.black.mediumEmphasis,
  category: "MaterialIcons",
}
