import { FormikConfig, FormikValues } from "formik"
import React, { ReactNode, useMemo } from "react"
import { StyleProp, StyleSheet, ViewStyle } from "react-native"
import * as Yup from "yup"

import useTheme from "../../features/Theme/ThemeProvider"
import { logger } from "../../utils/logger"
import { SMSValidationCodeInput } from "../SMSValidationCodeInput"
import { StylizedTranslatedText } from "../StylizedTranslatedText"
import { Caption } from "../Texts"
import { InputScreen } from "./InputScreen"

interface SMSForm {
  sms: string
}

interface IProps<Values> {
  name?: string
  length: number
  onValidate: (text?: string) => void
  formikConfig?: FormikConfig<Values>
  extraComponent?: ReactNode
  phone: string
  onResend?: () => void
  inputStyle?: StyleProp<ViewStyle>
  editable?: boolean
}

export const SMSFormikConfig = <Values extends FormikValues>(onSubmit?: (args: Values) => void) =>
  ({
    initialValues: { sms: "" },
    validateOnMount: true,
    validationSchema: Yup.object({
      sms: Yup.string().required(),
    }),
    onSubmit: onSubmit || logger,
  } as FormikConfig<SMSForm>)

export const SMSInputScreen = <Values extends FormikValues>({
  formikConfig,
  name,
  onValidate,
  length,
  extraComponent,
  phone,
  onResend,
  inputStyle,
  editable = true,
}: IProps<Values>) => {
  const {
    dimensions: { spacing },
    colors: {
      black: { highEmphasis },
      primary,
    },
  } = useTheme()

  const commonStyleProps = useMemo(
    () => ({
      marginTop: spacing,
    }),
    [spacing],
  )

  const s = useMemo(
    () => ({
      caption: [
        commonStyleProps,
        styles.text,
        {
          color: highEmphasis,
        },
      ],
      bottom: [commonStyleProps, styles.bottom],
      text: [
        styles.text,
        {
          color: primary,
        },
      ],
    }),
    [commonStyleProps, highEmphasis, primary],
  )

  return (
    <InputScreen
      showButton={false}
      formikConfig={formikConfig}
      Inputs={
        <SMSValidationCodeInput
          {...{ name, length, onValidate, inputStyle, editable }}
          focus={editable}
        />
      }
      captionComp={
        <StylizedTranslatedText
          highlightedTextStyle={s.text}
          TextComp={Caption}
          i18nKey="SMSInputScreen.caption"
          textStyle={s.caption}
          params={{ phone }}
        />
      }
      bottomComp={
        <>
          <StylizedTranslatedText
            highlightedTextStyle={s.text}
            TextComp={Caption}
            i18nKey="SMSInputScreen.subtitle"
            textStyle={s.bottom}
            onPress={onResend}
          />
          {extraComponent}
        </>
      }
    />
  )
}

const styles = StyleSheet.create({
  bottom: {
    alignSelf: "center",
  },
  text: {
    textAlign: "center",
  },
})
