import {
  Body2,
  ButtonType,
  firestore,
  H6,
  IUserContext,
  logger,
  OptimisticSwitch,
  SectionSeparator,
  Subtitle1,
  Subtitle2,
  useAlert,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { ActivityIndicator, Platform, ScrollView, StyleSheet, View } from "react-native"

import { App } from "../../common/App"
import { collections } from "../../common/types"
import WebModal from "../../components/WebModal"
import { NavigationNS } from "../../features/i18n/constants"
import { AppUserData, Patient } from "../../features/models/UserData"
import { editUserData, updateUserData } from "../../features/models/UserDataFunctions"
import { maxWidth } from "../../ThemeApp"
import AppsList from "./AppsList"

const ParametersEditionScreen: FC = () => {
  const { userDocRef, user, userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { t } = useTranslation(NavigationNS)
  const { showDialog } = useAlert()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [modalValues, setModalValues] = useState({ type: "", val: false })
  const {
    dimensions: { spacing },
    colors: {
      primary,
      accent,
      surface,
      white: { highEmphasis: white },
    },
  } = useTheme()

  const surgeonPatientsQuery = firestore()
    .collection(collections.LOGIN)
    .where("medicalTeam", "array-contains", user?.uid ?? [])
    .orderBy("surgeryDate", "desc")

  const [apps] = useCollectionData<App>(
    (firestore()
      .collection(collections.APPS)
      .where("disabled", "==", false) as unknown) as firebase.firestore.Query<AppUserData>,
  )

  const [firestorePatients] = useCollectionData<Patient>(
    (surgeonPatientsQuery as unknown) as firebase.firestore.Query<AppUserData>,
    { idField: "id" },
  )

  const initialState = apps
    ? apps.reduce((acc, app) => {
        const appId = app.id
        acc[appId] = false
        return acc
      }, {})
    : null

  const [isSwitchOn, setIsSwitchOn] = useState({ proms: true, todos: true })
  const [isAppSwitchOn, setIsAppSwitchOn] = useState(initialState)
  const [isUpdating, setIsUpdating] = useState(false)

  const s = useMemo(
    () => ({
      scrollView: [
        styles.scrollview,
        {
          backgroundColor: surface.background,
        },
      ],
      button: {
        margin: spacing,
      },
      inputContainer: [
        {
          paddingHorizontal: spacing,
          backgroundColor: white,
        },
      ],
      indicator: [
        styles.indicator,
        {
          padding: spacing,
          backgroundColor: surface.background,
        },
      ],
      label: {
        paddingTop: spacing,
        paddingHorizontal: spacing,
      },
      body: [
        styles.body,
        {
          padding: spacing,
          margin: spacing,
          backgroundColor: accent,
        },
      ],
      centerText: [
        styles.centerText,
        {
          margin: spacing,
        },
      ],
      titleView: [
        styles.centerText,
        {
          paddingTop: spacing,
        },
      ],
      dialog: [
        styles.dialog,
        {
          paddingHorizontal: spacing,
        },
      ],
      dialogTitle: {
        padding: spacing,
      },
      appView: {
        paddingBottom: spacing,
      },
      screenStyle: [Platform.OS === "web" ? { width: maxWidth } : null],
    }),
    [spacing, white, accent, surface.background],
  )

  const onToggleSwitch = useCallback(
    async (type, val) => {
      setIsUpdating(true)
      const updateField = `display${type}`
      // Mise à jour de la fiche du chirurgien
      updateUserData(userDocRef, { [updateField]: val })
      setIsSwitchOn(prev => ({ ...prev, [type.toLowerCase()]: val }))

      // Mise à jour des fiches patients dans Firestore
      if (firestorePatients) {
        const updates = firestorePatients.map(patient => {
          const patientDocRef = firestore().collection(collections.LOGIN).doc(patient.id)
          return patientDocRef.update({ [updateField]: val })
        })

        try {
          await Promise.all(updates)
          setIsUpdating(false)
        } catch (error) {
          logger(`Error updating ${type}:`, error)
          setIsUpdating(false)
        }
      }
    },
    [userDocRef, firestorePatients],
  )

  const onToggleAppSwitch = useCallback(
    async (type: string, val: boolean) => {
      setIsUpdating(true)
      if (Platform.OS === "web") {
        setModalVisible(false)
      }
      if (userData && _.includes(userData.apps, type)) {
        editUserData(userDocRef, {
          apps: firestore.FieldValue.arrayRemove(type) as any,
        })
      } else {
        editUserData(userDocRef, {
          apps: firestore.FieldValue.arrayUnion(type) as any,
        })
      }
      setIsAppSwitchOn(prev => ({ ...prev, [type]: val }))
      if (firestorePatients) {
        const updates = firestorePatients.map(patient => {
          const patientDocRef = firestore().collection(collections.LOGIN).doc(patient.id)
          if (userData && _.includes(userData.apps, type)) {
            return patientDocRef.update({ apps: firestore.FieldValue.arrayRemove(type) as any })
          } else {
            return patientDocRef.update({ apps: firestore.FieldValue.arrayUnion(type) as any })
          }
        })
        try {
          await Promise.all(updates)
          setIsUpdating(false)
        } catch (error) {
          logger(`Error updating ${type}:`, error)
          setIsUpdating(false)
        }
      }
    },
    [userData, userDocRef, firestorePatients],
  )

  const onPress = useCallback(
    async (type: string, val: boolean) => {
      const selectedApp = apps?.find(app => type === app.id)
      if (selectedApp?.disableOptions && val === true) {
        setModalMessage(t(`Applications.dialog.${selectedApp.disableOptions}`))
        if (Platform.OS === "web") {
          setModalVisible(true)
          setModalValues({ type, val })
        } else {
          showDialog({
            type: "button",
            style: s.dialog,
            dismissable: false,
            titleStyle: s.dialogTitle,
            contentContainerStyle: styles.dialogContent,
            title: t("Applications.dialog.title"),
            message: t(`Applications.dialog.${selectedApp.disableOptions}`),
            positive: {
              mode: "text",
              onPress: () => onToggleAppSwitch(type, val),
              type: ButtonType.POSITIVE,
              label: t("Applications.dialog.label"),
              labelType: "confirm",
            },
          })
        }
      } else {
        onToggleAppSwitch(type, val)
      }
    },
    [apps, onToggleAppSwitch, s.dialogTitle, t, showDialog, s.dialog],
  )

  return isUpdating ? (
    <View style={s.indicator}>
      <Subtitle1 style={s.centerText}>{t("Parameters.loading")}</Subtitle1>
      <ActivityIndicator color={primary} />
    </View>
  ) : (
    <ScrollView style={s.scrollView}>
      <View style={styles.containerScreen}>
        <View style={s.screenStyle}>
          <View style={s.appView}>
            <H6 style={s.titleView}>{t("Applications.title")}</H6>
            {Platform.OS === "web" ? (
              <Subtitle1 style={s.label}>{t("Applications.infoWeb")}</Subtitle1>
            ) : (
              <Subtitle1 style={s.label}>{t("Applications.infoMobile")}</Subtitle1>
            )}

            {apps ? (
              <>
                <AppsList
                  isAppSwitchOn={isAppSwitchOn}
                  onToggleAppSwitch={onPress}
                  appsData={apps}
                />
                <Body2 style={s.body}>{t("Applications.linkInfo")}</Body2>
              </>
            ) : null}
          </View>
          <SectionSeparator />
        </View>
        <View style={s.screenStyle}>
          <H6 style={s.titleView}>{t("Parameters.parametersTitle")}</H6>
          <Subtitle1 style={s.label}>{t("Parameters.info")}</Subtitle1>
          <View style={styles.switchContainer}>
            <OptimisticSwitch
              style={s.button}
              value={userData?.displayProms ?? isSwitchOn.proms}
              onValueChange={val => onToggleSwitch("Proms", val)}
            />
            <Subtitle2>{t("Parameters.promsActivation")}</Subtitle2>
          </View>
          <Body2 style={s.body}>{t("Parameters.promsInfo")}</Body2>
          <View style={styles.switchContainer}>
            <OptimisticSwitch
              style={s.button}
              value={userData?.displayTodos ?? isSwitchOn.todos}
              onValueChange={val => onToggleSwitch("Todos", val)}
            />
            <Subtitle2>{t("Parameters.todosActivation")}</Subtitle2>
          </View>
          <Body2 style={s.body}>{t("Parameters.todosInfo")}</Body2>
        </View>
      </View>
      <WebModal
        isVisible={modalVisible}
        onClose={() => onToggleAppSwitch(modalValues.type, modalValues.val)}
        title={t("Applications.dialog.title")}
        message={modalMessage}
        buttonLabel={t("Applications.dialog.label")}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  containerScreen: {
    alignItems: "center",
  },
  switchContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  indicator: {
    alignItems: "center",
    flex: 1,
  },
  centerText: {
    textAlign: "center",
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
  scrollview: {
    flex: 1,
  },
  dialog: {
    alignItems: "center",
  },
  dialogContent: {
    maxWidth,
    width: "100%",
  },
})

export default ParametersEditionScreen
