import { Body2, H6, useTheme } from "capsule"
import React, { useMemo } from "react"
import { Modal, StyleSheet, View } from "react-native"

import { Button } from "../capsule/components/Button"

const WebModal = ({ isVisible, onClose, title, message, buttonLabel }) => {
  const {
    dimensions: { spacing },
    colors: {
      primary,
      white: { highEmphasis: white },
      black: { disabled },
    },
  } = useTheme()
  const s = useMemo(
    () => ({
      button: [
        styles.button,
        {
          margin: spacing / 2,
          color: primary,
        },
      ],
      modalView: [
        styles.modalView,
        {
          backgroundColor: disabled,
        },
      ],
      dialogBox: [
        styles.dialogBox,
        {
          padding: spacing,
          backgroundColor: white,
        },
      ],
      labelStyle: { color: primary },
      textView: {
        marginVertical: spacing,
      },
    }),
    [spacing, primary, disabled, white],
  )
  return (
    <Modal transparent={true} visible={isVisible} onRequestClose={onClose}>
      <View style={s.modalView}>
        <View style={s.dialogBox}>
          <H6>{title}</H6>
          <View style={s.textView}>
            <Body2>{message}</Body2>
          </View>
          <View style={styles.buttonView}>
            <Button
              style={s.button}
              labelStyle={s.labelStyle}
              onPress={() => onClose()}
              mode="outlined"
            >
              {buttonLabel}
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  dialogBox: {
    width: "50%",
    borderRadius: 8,
  },
  button: {
    width: "50%",
  },
  buttonView: {
    alignItems: "center",
  },
})

export default WebModal
