import colors from "./colors"
import dimensions from "./dimensions"
import fonts from "./fonts"
import { Theme } from "./ThemeProvider"

const theme: Omit<Theme, "typography" | "typeGen" | "fontMaker"> = {
  fonts,
  colors,
  mode: "exact",
  animation: { scale: 1 },
  dimensions,
  darkMode: false,
  button: {
    roundness: 16,
    mode: "outlined",
    uppercase: false,
    alertMode: "text",
  },
}

export default theme
