import React, { FC } from "react"
import { StyleSheet, View } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"

// Need to call export at the top to avoid conflict with export default separator inside the same folder
export const SectionSeparator: FC = () => {
  const {
    colors: { black },
  } = useTheme()
  const separatorStyle = [styles.separator, { backgroundColor: black.separator }]

  return <View style={separatorStyle} />
}

const styles = StyleSheet.create({
  separator: {
    height: 1,
  },
})
