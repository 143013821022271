import "firebase/analytics"
import "firebase/auth"
import "firebase/firestore"
import "firebase/messaging"
import "firebase/functions"
import "firebase/storage"

import firebase from "firebase/app"

export const analytics = () => {
  const analytics1 = firebase.analytics()
  // @ts-ignore
  analytics1.logScreenView = (currentScreen: string) => analytics1.setCurrentScreen(currentScreen)
  return analytics1
}
export const auth = () => {
  const auth1 = firebase.auth()
  // @ts-ignore
  auth1.onUserChanged = auth1.onIdTokenChanged
  // @ts-ignore
  auth1.RecaptchaVerifier = (...params) => new firebase.auth.RecaptchaVerifier(...params)
  return auth1
}
export const firestore = firebase.firestore
export const messaging = firebase.messaging
export const crashlytics = () => ({
  // eslint-disable-next-line no-console
  recordError: console.log,
})
export const dynamicLinks = () => ({
  // eslint-disable-next-line no-console
  onLink: console.log,
  // eslint-disable-next-line no-console
  getInitialLink: console.log,
})
export const functions = firebase.functions
export const storage = firebase.storage

export default firebase
