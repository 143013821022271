import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  Button,
  Caption,
  functions,
  generateShadow,
  IUserContext,
  logger,
  openUrl,
  SMSValidationCodeInput,
  Subtitle1,
  Touchable,
  useAlert,
  userContext,
  useTheme,
  VectorIcon,
} from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import * as Localization from "react-native-localize"

import { OnCheckActivationCodeParams } from "../common/types"
import { INPUT_HEIGHT } from "../features/config/Constants"
import { ActivationCodeNS, PractitionerHomeNS } from "../features/i18n/constants"
import { AppUserData } from "../features/models/UserData"
import { useRole } from "../features/Providers/RoleProvider"

type Mode = "onboarding" | "activate_pack" | "add_patient" | "order"

interface IProps {
  name: string
  order?: boolean
  subtitle: string
  caption?: string
  /** true when this is a fullscreen dialog (onboarding), rather than shared (practitioner) */
  isScreen?: boolean
  mode: Mode
  addInfo?: string
}

export const length = 8

const CodeView: FC<IProps> = ({ name, isScreen = false, subtitle, mode, caption, addInfo }) => {
  const {
    fontMaker,
    colors: {
      primary,
      accent,
      surface: { background },
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const { showSnack } = useAlert()
  const navigation = useNavigation()
  const { user, userData, logout } = useContext<IUserContext<AppUserData>>(userContext)
  const { role } = useRole()
  const userTimezone = Localization.getTimeZone()
  const [code, setCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const s = useMemo(
    () => ({
      view: [
        styles.view,
        isScreen
          ? {
              backgroundColor: background,
            }
          : {
              padding: spacing,
              backgroundColor: white,
              ...generateShadow(1),
            },
      ],
      bold: fontMaker({ weight: "Bold" }),
      subtitle: [
        styles.subtitle,
        {
          paddingVertical: spacing,
        },
      ],
      button: [
        styles.button,
        mode === "order"
          ? undefined
          : {
              marginTop: spacing,
            },
      ],
      input: [
        styles.input,
        {
          paddingLeft: Platform.OS === "android" && code.length === 0 ? spacing * 1.6 : spacing,
        },
      ],
      logout: [
        styles.logout,
        {
          paddingTop: spacing,
        },
      ],
      caption: {
        color: primary,
      },
      body: [
        styles.body,
        {
          padding: spacing,
          marginTop: spacing * 2,
          backgroundColor: accent,
        },
      ],
    }),
    [background, code.length, isScreen, mode, primary, spacing, white, fontMaker, accent],
  )

  const activationCode = useCallback(
    async (val: string) => {
      setLoading(true)
      try {
        if (role === "practitioner" || role === undefined) {
          // noinspection ExceptionCaughtLocallyJS
          throw Error(`Incorrect role for activation ${role}`)
        }
        const checkCodeParams: OnCheckActivationCodeParams = {
          code: val,
          dev: __DEV__,
          phone: user?.phoneNumber ?? "",
          role,
          timezone: userTimezone,
          ...(role !== "patient" && userData?.specialties && { specialties: userData.specialties }),
        }
        const result = await functions().httpsCallable("isActivationCodeValid")(checkCodeParams)
        const validationCode = result.data
        if (validationCode && mode !== "onboarding") {
          showSnack({ message: t(`${PractitionerHomeNS}:${validationCode}`) })
        }
      } catch (e) {
        logger("Activation code error: ", e.message)
        showSnack({
          message: t([
            `${ActivationCodeNS}:errors.${e.message}`,
            `${ActivationCodeNS}:errors.unknown`,
          ]),
        })
      } finally {
        if (mode === "add_patient") {
          navigation.goBack()
        } else {
          setLoading(false)
        }
      }
    },
    [mode, navigation, role, showSnack, t, user?.phoneNumber, userTimezone, userData?.specialties],
  )

  const onValidate = useCallback(
    async (val: string) => {
      const isValid = !_.isEmpty(val) ? val.match("^[0-9a-zA-Z]+$") : true
      if (_.isNil(isValid)) {
        setDisabled(true)
        showSnack({ message: t(`${ActivationCodeNS}:errors.input`) })
        return
      }
      setCode(val)
      setDisabled(val.length !== length)
      if (isScreen && val.length === length) {
        await activationCode(val)
      }
    },
    [activationCode, isScreen, showSnack, t],
  )

  const icon = useMemo(
    () => () => (
      <VectorIcon
        color={white}
        category="MaterialIcons"
        name={mode === "order" ? "shopping-basket" : "code"}
      />
    ),
    [mode, white],
  )

  const iconTitle = useMemo(
    () => () => (
      <VectorIcon
        size={50}
        color={primary}
        category="MaterialIcons"
        name={
          name === "activation_code"
            ? "connect-without-contact"
            : name === "new_code"
            ? "dialpad"
            : "shopping-basket"
        }
      />
    ),
    [name, primary],
  )

  const onPress = useCallback(async () => {
    if (mode === "order") {
      const subject = t(`${PractitionerHomeNS}:order.subject`)
      const body = t(`${PractitionerHomeNS}:order.body`, {
        phone: user?.phoneNumber,
        lastName: userData?.lastName,
        firstName: userData?.firstName,
        interpolation: { escapeValue: false },
      })
      // noinspection ES6MissingAwait
      openUrl(
        `mailto:commande@doctup.fr?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
          body,
        )}`,
      )
      return
    }
    await activationCode(code)
    setCode("")
    setDisabled(true)
  }, [activationCode, code, mode, t, user?.phoneNumber, userData?.firstName, userData?.lastName])
  return (
    <View style={s.view}>
      <View>{iconTitle()}</View>
      <Subtitle1 emphasis="high" style={s.subtitle}>
        <Trans
          i18nKey={subtitle}
          // @ts-ignore
          components={{ p: <Subtitle1 />, b: <Subtitle1 style={s.bold} /> }}
        />
      </Subtitle1>
      {mode === "order" ? null : (
        <SMSValidationCodeInput
          focus={isScreen}
          keyboardType="default"
          clear={_.isEmpty(code)}
          inputStyle={s.input}
          editable={!loading}
          {...{ length, onValidate, name }}
        />
      )}
      {addInfo ? <Body2 style={s.body}>{addInfo}</Body2> : null}
      {mode === "onboarding" ? (
        <View style={styles.logout}>
          <Caption>{caption}</Caption>
          <Touchable style={styles.touchable} rippleColor={primary} onPress={logout}>
            <Caption style={s.caption}>{t(`${ActivationCodeNS}:logout.button`)}</Caption>
          </Touchable>
        </View>
      ) : null}
      {isScreen ? null : (
        <Button
          {...{ onPress, icon, loading }}
          disabled={(mode !== "order" && disabled) || loading}
          style={s.button}
        >
          {t(`${PractitionerHomeNS}:button.${mode === "order" ? "order" : "activate"}`)}
        </Button>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    alignItems: "center",
  },
  image: {
    width: 130,
    height: 130,
  },
  input: {
    height: INPUT_HEIGHT,
    textAlign: Platform.select({
      ios: "center",
      android: undefined,
      web: "center",
    }),
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    width: "100%",
  },
  touchable: {
    justifyContent: "center",
  },
  logout: {
    flexDirection: "row",
    alignItems: "center",
  },
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
})

export default CodeView
