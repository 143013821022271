export * from "./ArgumentTypes"
export { default as focusOnRef } from "./focusOnRef"
export {
  default as getHeaderHeight,
  getHeaderSafeAreaHeight,
  statusBarHeight,
} from "./getHeaderHeight"
export { default as getOrStoreFile } from "./getOrStoreFile"
export { default as hmsToMillis } from "./hmsToMillis"
export { default as isIphoneX } from "./isIphoneX"
export { default as isPromise } from "./isPromise"
export { default as isValidEmail } from "./isValidEmail"
export * from "./isValidEmail"
export { default as isPad } from "./isPad"
export { default as isUnderSDK24 } from "./isUnderSDK24"
export * from "./MaybePromise"
export { default as msToTime, timeVals } from "./msToTime"
export * from "./StrictOmit"
export * from "./logger"
export { default as getFooterHeight } from "./getFooterHeight"
export { default as removeDiacritics, removeDiacriticsFast } from "./removeDiacritics"
export { default as useDebounce } from "./useDebounce"
export { default as getSafeBottomSize } from "./getSafeBottomSize"
export { default as useBackButton } from "./useBackButton"
export { openUrl, openHtmlViewUrl } from "./openUrl"
export * from "./askNativePermission"
export { default as isValidDate } from "./isValidDate"
export { default as hitSlop } from "./hitSlop"
export { default as generateShadow } from "./generateShadow"
export { default as delay } from "./delay"
export { default as useTabLand, useTabTwoPane } from "./useTabLand"
export { default as useTwoPaneNavigation, useTwoPaneRoute } from "./useTwoPaneNavigation"
export { default as useGridCalculation } from "./useGridCalculation"
export * from "./dateFormat"
export { default as useDelay } from "./useDelay"
export { default as Delayed } from "./Delayed"
export { default as useComponentDimensions } from "./useComponentDimensions"
export * from "./useKeyboard"
export { default as useLayout } from "./useLayout"
export * from "./useStyles"
export { useAppState } from "./useAppState"
