import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Image, ImageSourcePropType, StyleSheet, TouchableOpacity } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

import useTheme from "../../features/Theme/ThemeProvider"
import { Body2, H6, Subtitle1 } from "../Texts"

interface IProps {
  title?: string
  color?: string
  subtitle?: string
  namespace?: string
  buttonLabel?: string
  onPress?: () => void
  image?: ImageSourcePropType
}

export const ErrorScreen: FC<IProps> = ({
  color,
  image,
  title,
  onPress,
  subtitle,
  namespace,
  buttonLabel,
}) => {
  const { t } = useTranslation(namespace)
  const {
    dimensions: { spacing },
    colors,
  } = useTheme()

  const titleStyle = [
    styles.text,
    {
      paddingBottom: spacing,
    },
  ]
  const subtitleStyle = [
    styles.text,
    {
      paddingBottom: spacing,
      marginHorizontal: spacing * 4.5,
    },
  ]
  const imageStyle = { marginBottom: spacing }

  return (
    <SafeAreaView style={styles.container}>
      {image && <Image source={image} style={imageStyle} />}
      {title ? (
        <H6 style={titleStyle} color={color} emphasis="high">
          {t(title ?? "list.error.title")}
        </H6>
      ) : null}
      {subtitle ? (
        <Subtitle1 style={subtitleStyle} color={color} emphasis="medium">
          {t(subtitle ?? "list.error.subtitle")}
        </Subtitle1>
      ) : null}
      {onPress ? (
        <TouchableOpacity onPress={onPress}>
          <Body2 style={{ color: colors.accent }}>{t(buttonLabel ?? "button.retry")}</Body2>
        </TouchableOpacity>
      ) : null}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
  },
})
