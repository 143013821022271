import { firebase, firestore, functions, logger, useAlert, userContext } from "capsule"
import firebaseApp from "firebase"
import { Formik } from "formik"
import _ from "lodash"
import * as math from "mathjs"
import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import { ID } from "../../common/CommonUserData"
import { Form, FormResult } from "../../common/Proms"
import { collections } from "../../common/types"
import { setMetadata } from "../../services/Analytics/shake"
import { RATING_SCORE } from "../config/Constants"
import { PromsNS } from "../i18n/constants"
import { PatientPromsResult } from "../models/Proms"
import {
  createUserPromsData,
  UpdatePromsValues,
  updateUserPromsData,
} from "../models/UserDataFunctions"

export type PromsIds = { id: ID; docId?: ID; triggerDay?: number } | undefined

export interface LoadFormParams {
  id: ID
  token?: ID
  docId?: ID
  isTodo?: boolean
  triggerDay?: number
  isShowAgain?: boolean
}

export interface LoadSigninFormParams {
  id: ID
  isEdit?: boolean
  questionId?: number
}

interface IPromsContext {
  form?: Form
  error?: string
  loading: boolean
  scores?: FormResult
  isFromLink: boolean
  isOpened: boolean
  showHomeProms: boolean
  triggerDay?: number
  setShowHomeProms: Dispatch<SetStateAction<boolean>>
  getFormById: (formId: ID) => Promise<Form | undefined>
  getScore: (values: FormResult) => void
  saveForm: (showAgain?: boolean) => void
  setForm: Dispatch<SetStateAction<Form | undefined>>
  setIsFromLink: Dispatch<SetStateAction<boolean>>
  setIsOpened: Dispatch<SetStateAction<boolean>>
  loadForm?: (params?: LoadFormParams) => Promise<boolean>
  loadSigninForm?: (params?: LoadSigninFormParams) => Promise<boolean>
  setLoading: Dispatch<SetStateAction<boolean>>
  cleanForm: () => void
}

/** Props use when the proms is open from SMS */

const defaultValues: Omit<
  IPromsContext,
  | "getFormById"
  | "getScore"
  | "saveForm"
  | "loadForm"
  | "setIsFromLink"
  | "setIsOpened"
  | "setForm"
  | "setShowHomeProms"
  | "cleanForm"
  | "setLoading"
> = {
  form: undefined,
  loading: false,
  error: undefined,
  isFromLink: false,
  isOpened: false,
  showHomeProms: false,
}

export const promsContext = createContext<IPromsContext>(defaultValues as IPromsContext)

/**
 * Provider use to :
 *  - retrieve one Proms form for the patient
 * */
const PromsProvider: FC<PropsWithChildren<ReactNode>> = ({ children }) => {
  const { userDocRef, loginWithCustomToken, logout } = useContext(userContext)
  const { t } = useTranslation(PromsNS)
  const { showSnack } = useAlert()
  const [showHomeProms, setShowHomeProms] = useState(false)
  const [currentIds, setCurrentIds] = useState<PromsIds>(undefined)
  const [isShowAgain, setIsShowAgain] = useState<boolean | undefined>(undefined)
  const [currentForm, setCurrentForm] = useState<Form | undefined>(defaultValues.form)
  const [scores, setScores] = useState<FormResult | undefined>(undefined)
  const [loading, setLoading] = useState(defaultValues.loading)
  const [error, setError] = useState(defaultValues.error)
  const [isFromLink, setIsFromLink] = useState(defaultValues.isFromLink)
  /** check if form is already opened to avoid a second opening of the same form
   * It is necessary to display form when app is running with a connected user */
  const [isOpened, setIsOpened] = useState(defaultValues.isOpened)
  const [isFormId, setIsFormId] = useState<string | undefined>(undefined)
  const [isSMSForm, setIsSMSForm] = useState<boolean | undefined>(undefined)

  /**
   * Compute score with math js
   * retrieve each score from the formula and should create fields inside firestore
   * Thanks is used to select the one to display
   * */

  const getFormById = useCallback(async (formId: ID) => {
    try {
      const firestoreForm = (
        await firestore().collection(collections.FORMS).doc(formId).get()
      ).data() as Form
      return firestoreForm
    } catch (e) {
      logger("get form by id error", e)
      return undefined
    }
  }, [])

  const [userProms] = useCollectionData<PatientPromsResult & { docId: string }>(
    userDocRef && isFormId
      ? ((userDocRef
          .collection(collections.PROMS)
          .where(
            "id",
            "==",
            isFormId,
          ) as unknown) as firebaseApp.firestore.Query<PatientPromsResult>)
      : null,
    { idField: "docId" },
  )

  useEffect(() => {
    if (isFormId && userProms && currentIds?.id && isSMSForm) {
      const filteredArray = userProms?.find(
        proms => proms.triggerDay !== undefined && proms.triggerDay === currentIds.triggerDay,
      )
      setCurrentIds({
        id: currentIds?.id,
        docId: filteredArray?.docId,
        triggerDay: currentIds.triggerDay,
      })
      setIsShowAgain(filteredArray?.showAgain)
    }
  }, [userProms, isFormId, currentIds?.docId, currentIds?.id, currentIds?.triggerDay, isSMSForm])
  const getScore = useCallback(
    (values: FormResult) => {
      if (!currentForm?.formulas) {
        setScores(undefined)
        setError("")
        logger("calcul score, no formulas")
        return
      }
      try {
        const scope = _.clone(values)
        math.evaluate(currentForm?.formulas, scope)
        const result = _.has(values, RATING_SCORE) ? scope : _.omit(scope, [..._.keys(values)])
        const promsValues: UpdatePromsValues = {
          id: currentForm?.id,
          scores: result,
          showAgain: false,
          responses: values,
          timestamp: firestore.Timestamp.now(),
        }
        if (currentIds?.triggerDay !== undefined) {
          promsValues.triggerDay = currentIds?.triggerDay
        }
        if (currentIds?.docId && isShowAgain === true) {
          updateUserPromsData(userDocRef, currentIds?.docId, promsValues)
        } else {
          createUserPromsData(userDocRef, promsValues)
        }
        setScores(result)
      } catch (e) {
        setScores(undefined)
        setIsFormId(undefined)
        setIsSMSForm(undefined)
        setError(e)
        logger("calcul score error", e)
      } finally {
        setIsFromLink(false)
        setIsOpened(false)
        setIsFormId(undefined)
        setIsSMSForm(undefined)
      }
    },
    [
      currentForm?.formulas,
      currentForm?.id,
      currentIds?.docId,
      currentIds?.triggerDay,
      isShowAgain,
      userDocRef,
    ],
  )

  const loadForm = useCallback(
    async (params?: LoadFormParams): Promise<boolean> => {
      try {
        setIsFormId(params?.id)
        setLoading(true)
        setCurrentForm(undefined)
        if (params) {
          if (!params.isTodo) {
            setIsSMSForm(true)
            const isAuth = !_.isEmpty(firebase.auth().currentUser)
            const customToken = (
              await functions().httpsCallable("getCustomTokenFromRefreshToken")({
                isAuth,
                refreshToken: params.token,
              })
            ).data
            if (!isAuth && params.token) {
              await loginWithCustomToken(customToken)
            }
          }
          const firestoreForm = (
            await firestore().collection(collections.FORMS).doc(params.id).get()
          ).data() as Form
          setCurrentIds({ id: params.id, docId: params.docId, triggerDay: params.triggerDay })
          setIsShowAgain(params.isShowAgain)
          setCurrentForm(firestoreForm)
          setMetadata("form", (firestoreForm.id as ID) ?? "unset")
        }
        return true
      } catch (e) {
        showSnack({ message: t(e.message) })
        logger("load form error", e)
        setIsFromLink(false)
        return false
      } finally {
        setLoading(false)
      }
    },
    [loginWithCustomToken, showSnack, t],
  )

  const loadSigninForm = useCallback(
    async (params: LoadSigninFormParams): Promise<boolean> => {
      try {
        setLoading(true)
        if (params) {
          const firestoreForm = (
            await firestore().collection(collections.SIGNIN_FORMS).doc(params.id).get()
          ).data() as Form
          const questionsRef = await firestore().collection(collections.SIGNIN_QUESTIONS)
          const parametersRef = await firestore().collection(collections.SIGNIN_PARAMETERS)
          const formQuestions = firestoreForm.questions ? firestoreForm.questions : []
          const firestoreQuestions = await Promise.all(
            // @ts-ignore
            formQuestions.map(async fq => (await questionsRef.doc(fq).get()).data()),
          )
          const norQuestions = await Promise.all(
            firestoreQuestions.map(async q => {
              const firestoreOptions = q?.options ? q.options : []
              const firestoreLinked = q?.linkedQuestions ? q.linkedQuestions : []
              const options = await Promise.all(
                firestoreOptions.map(async p => (await parametersRef.doc(p).get()).data()),
              )
              const linkedQuestions = await Promise.all(
                firestoreLinked.map(async lq => {
                  const linkedQuestionData = (await questionsRef.doc(lq).get()).data()
                  const firestoreLinkedOptions =
                    linkedQuestionData && linkedQuestionData.options
                      ? linkedQuestionData.options
                      : []
                  const linkedOptions = await Promise.all(
                    firestoreLinkedOptions.map(async lp =>
                      (await parametersRef.doc(lp).get()).data(),
                    ),
                  )
                  return {
                    ...linkedQuestionData,
                    options: linkedOptions,
                  }
                }),
              )
              return {
                ...q,
                options,
                linkedQuestions: linkedQuestions ? linkedQuestions : [],
              }
            }),
          )
          const norForm = Object.assign(firestoreForm, {
            signInForm: true,
            isEdit: params.isEdit ? true : undefined,
            questionId: params.questionId ?? undefined,
            questions: norQuestions,
          })
          setCurrentIds({ id: params.id })
          setCurrentForm(norForm)
          setMetadata("signinForm", (firestoreForm.id as ID) ?? "unset")
        }
        return true
      } catch (e) {
        showSnack({ message: t(e.message) })
        logger("load signin form error", e)
        setIsFromLink(false)
        return false
      } finally {
        setLoading(false)
      }
    },
    [showSnack, t],
  )

  const saveForm = useCallback(
    (showAgain?: boolean) => {
      setIsOpened(false)
      if (!currentForm?.id) {
        return
      }
      const values: UpdatePromsValues = {
        showAgain,
        id: currentForm?.id,
        timestamp: firestore.Timestamp.now(),
      }
      if (currentIds?.triggerDay !== undefined) {
        values.triggerDay = currentIds?.triggerDay
      }
      if (currentIds?.docId && isShowAgain === true) {
        updateUserPromsData(userDocRef, currentIds?.docId, values)
      } else {
        createUserPromsData(userDocRef, values)
      }
    },
    [currentForm?.id, currentIds?.docId, userDocRef, currentIds?.triggerDay, isShowAgain],
  )

  const cleanForm = useCallback(() => {
    if (Platform.OS === "web") {
      // @ts-ignore
      window.close()
      // noinspection JSIgnoredPromiseFromCall
      logout()
      showSnack({ message: t("close") })
    }
    setError(undefined)
    setIsFromLink(false)
    setCurrentForm(undefined)
    setIsFormId(undefined)
    setIsSMSForm(undefined)
  }, [logout, showSnack, t])

  const contextValue: IPromsContext = {
    form: currentForm,
    error,
    scores,
    loading,
    loadForm,
    loadSigninForm,
    isFromLink,
    getFormById,
    getScore,
    saveForm,
    isOpened,
    cleanForm,
    showHomeProms,
    setShowHomeProms,
    setIsOpened,
    setIsFromLink,
    setLoading,
    setForm: setCurrentForm,
    triggerDay: currentIds?.triggerDay,
  }

  /** Simple test to recognize and accept question name as Formik Field */
  return (
    <promsContext.Provider value={contextValue}>
      <Formik onSubmit={() => logger("submit")} initialValues={{}}>
        {children}
      </Formik>
    </promsContext.Provider>
  )
}

export default PromsProvider
