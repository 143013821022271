import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"

import { useTheme } from "../../features/Theme"
import { FormikCheckbox, FormikRadioButton } from "../FormBis"
import { VectorIconProps } from "../VectorIcon"

export type FormikField = Record<
  string,
  Array<string | number> | Record<string, { checked: VectorIconProps; unchecked: VectorIconProps }>
>

type FormComponent = "radio" | "checkbox"

const isArray = (
  fields:
    | Array<string | number>
    | Record<string, { checked: VectorIconProps; unchecked: VectorIconProps }>,
): fields is Array<string | number> => _.isArray(fields)

interface IProps {
  interstice?: number
  vertical?: boolean
  components: FormComponent[]
  fields?: FormikField[] // Each component couple share the same name
  style?: StyleProp<ViewStyle> // Style of the encapsulate view
  childStyle?: StyleProp<ViewStyle> // Global style of children
}

// Use Formik system to save value. Use FormikRadioButton or FormikCheckbox.
const SegmentedControlView: FC<IProps> = ({
  interstice = 0,
  vertical = false,
  fields,
  style,
  childStyle,
  components,
}) => {
  const { button } = useTheme()
  const s = useMemo(() => {
    const horizontalInterstice = vertical ? 0 : interstice / 2
    const verticalInterstice = vertical ? interstice / 2 : 0
    const horizontalRadius = vertical ? 0 : button?.roundness
    const verticalRadius = vertical ? button?.roundness : 0
    return {
      left: [
        styles.radius,
        {
          marginRight: horizontalInterstice,
          marginBottom: verticalInterstice,
          borderTopStartRadius: button?.roundness,
          borderTopEndRadius: verticalRadius,
          borderBottomStartRadius: horizontalRadius,
        },
        childStyle,
      ],
      middle: [
        styles.radius,
        {
          marginHorizontal: horizontalInterstice,
          marginVertical: verticalInterstice,
        },
        childStyle,
      ],
      right: [
        styles.radius,
        {
          marginLeft: horizontalInterstice,
          marginTop: verticalInterstice,
          borderTopEndRadius: horizontalRadius,
          borderBottomEndRadius: button?.roundness,
          borderBottomStartRadius: verticalRadius,
        },
        childStyle,
      ],
    }
  }, [button?.roundness, childStyle, interstice, vertical])

  /** At the moment Radio Button has no props to show icon. Maybe in the next update of FormikRadioButton  */
  const singleChoice = useCallback(
    (name: string, values: string[]) => {
      const head = _.head(values) as string
      const last = _.last(values) as string
      const middleValues = _.slice(values, 1, -1)
      return (
        <>
          <FormikRadioButton
            {...{ name }}
            label={head}
            value={head}
            textOnly
            key={`${name}-${head}`}
            touchableStyle={s.left}
          />
          {_.isEmpty(middleValues)
            ? null
            : _.map(middleValues, val => (
                <FormikRadioButton
                  {...{ name }}
                  label={val}
                  value={val}
                  textOnly
                  key={`${name}-${val}`}
                  touchableStyle={s.middle}
                />
              ))}
          <FormikRadioButton
            {...{ name }}
            label={last}
            value={last}
            textOnly
            key={`${name}-${last}`}
            touchableStyle={s.right}
          />
        </>
      )
    },
    [s.left, s.middle, s.right],
  )

  /** At the moment, label cannot be hidden. Only the custom icons. */
  const multipleChoices = useCallback(
    (
      name: string,
      values:
        | Record<string, { checked: VectorIconProps; unchecked: VectorIconProps }>
        | Array<string | number>,
    ) => {
      const keys = isArray(values) ? values : _.keys(values)
      const head = _.head(keys) as string
      const last = _.last(keys) as string
      const middleValues = isArray(values) ? _.slice(values, 1, -1) : _.omit(values, [head, last])

      return (
        <>
          <FormikCheckbox
            key={head}
            name={head}
            label={head}
            showDefault={false}
            contentStyle={s.left}
            icons={isArray(values) ? undefined : values[head]}
          />
          {_.map(middleValues, (value, key) => {
            const label = isArray(values) ? value : key
            return (
              <FormikCheckbox
                name={label}
                {...{ key, label }}
                showDefault={false}
                contentStyle={s.middle}
                icons={isArray(values) ? undefined : values[key]}
              />
            )
          })}
          <FormikCheckbox
            key={last}
            name={last}
            label={last}
            showDefault={false}
            contentStyle={s.right}
            icons={isArray(values) ? undefined : values[last]}
          />
        </>
      )
    },
    [s.left, s.middle, s.right],
  )

  const renderChildren = (field: FormikField, index: number): any => {
    const element = components?.[index]
    const name = _.keys(field)?.[0]
    if (element === "radio") {
      return singleChoice(name, field?.[name] as any[])
    } else {
      return multipleChoices(name, field?.[name] as Record<string, any>)
    }
  }

  return (
    <>
      {fields?.map((field, index) => (
        <View {...{ style }} key={index}>
          {renderChildren(field, index)}
        </View>
      ))}
    </>
  )
}

const styles = StyleSheet.create({
  radius: {
    borderRadius: 0,
  },
})

export default SegmentedControlView
