import React, { forwardRef } from "react"
import { ScrollView, ScrollViewProps, ViewStyle } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"

const ScrollViewContent = forwardRef<ScrollView, ScrollViewProps>(function RefForwarded(
  props,
  ref,
) {
  const {
    dimensions: { spacing },
  } = useTheme()

  const contentContainerStyle: ViewStyle = {
    padding: spacing,
  }

  return <ScrollView {...props} {...{ contentContainerStyle, ref }} />
})

export default ScrollViewContent
