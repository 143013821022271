import { firestore, logger } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"

import { ID } from "../../common/CommonUserData"
import { Exercise, Phase } from "../../common/Phase"
import { collections } from "../../common/types"
import currentDateMs from "../hooks/useDateMock"
import { ExDuration } from "./Session"

export const getUnfinishedExIndex = (exercises?: Record<ID, ExDuration>) =>
  _(exercises)
    .map()
    .sortBy(["start"])
    .takeWhile(ex => !_.isUndefined(ex.start) && !_.isUndefined(ex.end))
    .value().length

interface ValidationParams {
  surgeryDate?: Date
  currentPhase?: Phase
  exercise?: Exercise
  phases?: Phase[]
}
export const checkExerciseValidation = async ({
  currentPhase,
  surgeryDate,
  exercise,
  phases,
}: ValidationParams) => {
  const lastDayOfCurrentPhase = currentPhase?.endDay
    ? dayjs(surgeryDate).add(currentPhase.endDay, "day")
    : undefined
  // check validation if last day of phase and ED is inside next phase
  if (lastDayOfCurrentPhase && dayjs(currentDateMs()).isSame(lastDayOfCurrentPhase, "day")) {
    const currentPhaseIndex = _.findIndex(phases, ["id", currentPhase?.id])
    const nextPhase = phases?.[currentPhaseIndex + 1]
    if (!nextPhase?.id) {
      return false
    }

    // Diviser les exercices en groupes de 10
    const exerciseGroups = _.chunk(nextPhase?.exercises, 10)

    // Effectuer une requête pour chaque groupe et combiner les résultats
    const docsPromises = exerciseGroups.map(group =>
      firestore().collection(collections.EXERCISES).where("id", "in", group).get(),
    )

    const snapshots = await Promise.all(docsPromises)
    const docs = snapshots.flatMap(snapshot => snapshot.docs)

    const dynamics = _.filter(docs, doc => _.has(doc.data(), "eels"))
    if (_.isEmpty(dynamics)) {
      return false
    }
    return _.some(dynamics, doc => _.includes(doc.data()?.eels, exercise?.id))
  }
  const index = _.findIndex(exercise?.eels, val => val === exercise?.id)
  return exercise?.eels?.length ? index < exercise?.eels?.length - 1 : false
}

export const getExosFromPhase = async (phaId: string) => {
  try {
    const phaseDoc = await firestore().collection(collections.PHASES).doc(phaId).get()
    const phase = phaseDoc.data() as Phase

    if (!phase || !phase.exercises) {
      return null
    }

    const exosData = await Promise.all(
      phase.exercises.map(async exoId => {
        const exerciseDoc = (
          await firestore().collection(collections.EXERCISES).doc(exoId).get()
        ).data()
        if (!exerciseDoc?.eels) {
          return exerciseDoc as Exercise
        } else {
          const eelExercises = await Promise.all(
            exerciseDoc.eels.map(async eelId => {
              const eelExerciseDoc = await firestore()
                .collection(collections.EXERCISES)
                .doc(eelId)
                .get()
              return eelExerciseDoc.data() as Exercise
            }),
          )
          return eelExercises[0]
        }
      }),
    )
    return exosData.filter(data => data !== null)
  } catch (error) {
    logger("error in getting exercises from phase", error)
    throw error
  }
}
