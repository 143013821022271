import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useReducer,
  useRef,
  useState,
} from "react"
import useCountDown from "react-countdown-hook"
import { OnVideoErrorData, VideoRef } from "react-native-video"

import {
  defaultValues,
  VideoAction,
  VideoState,
  videoStateReducer,
} from "../../screens/ExerciseScreen/Constants"
interface VideoContext {
  videoRef: MutableRefObject<VideoRef | null>
  videoState: VideoState
  dispatch: Dispatch<VideoAction>
  actions: { start(ttc?: number): void; pause(): void; resume(): void; reset(): void }
  videoError?: OnVideoErrorData
  setVideoError: Dispatch<SetStateAction<OnVideoErrorData | undefined>>
  duration?: number
  timeLeft: number
}

const interval = 1000 // interval to change remaining time amount, defaults to 1000

export const videoContext = createContext<VideoContext>({} as VideoContext)

interface IProps {
  exDuration?: number
}
const VideoProvider: FC<PropsWithChildren<IProps>> = ({ children, exDuration }) => {
  const videoRef = useRef<VideoRef>(null)
  const [videoState, dispatch] = useReducer(videoStateReducer, defaultValues)
  const [videoError, setVideoError] = useState<OnVideoErrorData | undefined>()
  const duration = exDuration ? exDuration * 1000 : undefined
  const [timeLeft, actions] = useCountDown(duration ?? 0, interval)

  const contextValue: VideoContext = {
    videoRef,
    videoState,
    dispatch,
    duration,
    timeLeft,
    actions,
    videoError,
    setVideoError,
  }

  return <videoContext.Provider value={contextValue}>{children}</videoContext.Provider>
}

export default VideoProvider

export const useVideo = (): VideoContext => {
  const context = useContext<VideoContext>(videoContext)
  if (_.isEmpty(context)) {
    throw new Error("useVideo must be used within a VideoProvider")
  }
  return context
}
