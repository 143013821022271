import notifee, {
  EventDetail,
  EventType,
  TimestampTrigger,
  TriggerType,
} from "@notifee/react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { logger } from "capsule"
import dayjs from "dayjs"

import currentDateMs from "../hooks/useDateMock"

export const createTrigger = (date: Date): TimestampTrigger | undefined => {
  if (dayjs(date).isBefore(dayjs(currentDateMs()).toDate())) {
    return undefined
  }
  return {
    timestamp: dayjs(date).local().valueOf(),
    type: TriggerType.TIMESTAMP,
  }
}

export const removePreviousNotification = async (type: EventType, detail: EventDetail) => {
  if (type === EventType.DELIVERED) {
    try {
      const previousNotif = await AsyncStorage.getItem("previous")
      if (previousNotif) {
        await notifee.cancelDisplayedNotification(previousNotif)
      }
      await AsyncStorage.setItem("previous", detail?.notification?.id ?? "")
    } catch (e) {
      logger("remove notification error", e)
    }
  }
}
