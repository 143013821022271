import { ISO_8601_DATE, useTheme } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CalendarList, LocaleConfig } from "react-native-calendars"

import { EN_CALENDAR_CONFIG, FR_CALENDAR_CONFIG } from "../features/config/Constants"
import { PatientProps } from "../screens/PatientScreen/PatientScreen"

const PatientCalendar: FC<PatientProps> = ({ patient }) => {
  const { i18n } = useTranslation()
  const [mappedDates, setMappedDates] = useState({})

  const {
    fontMaker,
    colors: {
      primary,
      white: { highEmphasis: white },
      surface: { background },
    },
  } = useTheme()

  useEffect(() => {
    const initializeCalendar = () => {
      LocaleConfig.locales.fr = FR_CALENDAR_CONFIG
      LocaleConfig.locales.en = EN_CALENDAR_CONFIG
      LocaleConfig.defaultLocale = i18n.language
      const todayDate = dayjs().format(ISO_8601_DATE)
      const activityDates = patient.activityTracking ?? []
      const isTodayActivity = _.includes(activityDates, todayDate)
      const newMappedDates = {}
      activityDates.forEach(date => {
        newMappedDates[date] = {
          customStyles: {
            container: {
              backgroundColor: primary,
            },
            text: [
              {
                color: white,
              },
            ],
          },
        }
      })
      newMappedDates[todayDate] = {
        customStyles: {
          container: {
            backgroundColor: isTodayActivity ? primary : white,
            borderWidth: 1,
            borderColor: primary,
          },
          text: [
            fontMaker({ weight: "Bold" }),
            {
              color: isTodayActivity ? white : primary,
            },
          ],
        },
      }
      setMappedDates(newMappedDates)
    }

    initializeCalendar()
  }, [i18n.language, patient.activityTracking, fontMaker, primary, white])

  return (
    <CalendarList
      key={LocaleConfig.defaultLocale}
      markingType="custom"
      markedDates={mappedDates}
      horizontal={false}
      showScrollIndicator={true}
      scrollEnabled={true}
      theme={{
        calendarBackground: background,
      }}
    />
  )
}

export default PatientCalendar
