import { useNavigation } from "@react-navigation/native"
import { Body2, Button, firestore, IUserContext, userContext, useTheme } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { SafeAreaView } from "react-native-safe-area-context"

import { Organ } from "../../common/Pathology"
import { collections } from "../../common/types"
import { SCROLL_HEIGHT } from "../../features/config/Constants"
import { NavigationNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { editUserData, updateUserData } from "../../features/models/UserDataFunctions"
import { maxWidth } from "../../ThemeApp"
import SpecialtiesList from "./SpecialtiesList"

interface IProps {
  isEdit?: boolean
}

const KineSpecialtiesScreen: FC<IProps> = ({ isEdit }) => {
  const { userDocRef, userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { t } = useTranslation(NavigationNS)
  const navigation = useNavigation()
  const {
    colors: { accent, primary, surface },
    dimensions: { spacing },
  } = useTheme()

  const [organs] = useCollectionData<Organ>(
    (firestore().collection(
      collections.ORGANS,
    ) as unknown) as firebase.firestore.Query<AppUserData>,
  )

  const initialState = organs
    ? organs.reduce((acc, app) => {
        const appId = app.id
        acc[appId] = false
        return acc
      }, {})
    : {}

  const [isSwitchOn, setIsSwitchOn] = useState(initialState)

  const onToggleSwitch = useCallback(
    (type: string, val: boolean) => {
      if (isEdit) {
        if (userData && _.includes(userData.kineSpecialties, type)) {
          editUserData(userDocRef, {
            kineSpecialties: firestore.FieldValue.arrayRemove(type) as any,
          })
        } else {
          editUserData(userDocRef, {
            kineSpecialties: firestore.FieldValue.arrayUnion(type) as any,
          })
        }
      }
      setIsSwitchOn(prev => ({ ...prev, [type]: val }))
    },
    [userDocRef, userData, isEdit],
  )

  const onSubmit = useCallback(() => {
    if (isEdit) {
      navigation.goBack()
    } else {
      const specialtiesArray = Object.keys(isSwitchOn).filter(key => isSwitchOn[key])
      updateUserData(userDocRef, {
        kineSpecialties: specialtiesArray,
      })
    }
  }, [userDocRef, isSwitchOn, isEdit, navigation])

  const s = useMemo(
    () => ({
      view: [
        styles.view,
        {
          backgroundColor: surface.background,
        },
      ],
      screenStyle: [
        Platform.OS === "web"
          ? { width: maxWidth, backgroundColor: surface.background }
          : { backgroundColor: surface.background },
      ],
      buttonView: [
        styles.buttonView,
        {
          paddingHorizontal: spacing * 2,
          paddingVertical: spacing,
        },
      ],
      body: [
        styles.body,
        {
          padding: spacing,
          margin: spacing,
          backgroundColor: accent,
        },
      ],
      label: [styles.buttonLabel, { right: spacing / 2 }],
    }),
    [spacing, accent, surface.background],
  )

  return (
    <SafeAreaView style={s.view} edges={["bottom"]}>
      <KeyboardAwareScrollView
        enableOnAndroid
        keyboardShouldPersistTaps="handled"
        extraScrollHeight={Platform.OS === "android" ? SCROLL_HEIGHT : undefined}
      >
        <View style={s.screenStyle}>
          <Body2 style={s.body}>{t("KineSpecialties.info")}</Body2>
          {organs ? (
            <SpecialtiesList
              isSwitchOn={isSwitchOn}
              onToggleSwitch={onToggleSwitch}
              organsData={organs}
            />
          ) : null}
        </View>
      </KeyboardAwareScrollView>
      <View style={s.screenStyle}>
        <View style={s.buttonView}>
          <Button style={styles.button} onPress={onSubmit}>
            {t("common:button.validate")}
          </Button>
        </View>
        {!isEdit ? (
          <Button
            mode="text"
            labelStyle={s.label}
            onPress={onSubmit}
            contentStyle={styles.buttonContent}
          >
            <Body2 color={primary}>{t("common:button.skip")}</Body2>
          </Button>
        ) : null}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  buttonView: {
    flexDirection: "row",
    width: "100%",
  },
  button: {
    flex: 1,
  },
  view: {
    flex: 1,
    alignItems: "center",
  },
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
})

export default KineSpecialtiesScreen
