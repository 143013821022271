import React, { FC, Ref } from "react"
import {
  StyleProp,
  StyleSheet,
  TextInput as NativeInput,
  TextStyle,
  TouchableOpacity,
  View,
} from "react-native"
import { List } from "react-native-paper"

import useTheme from "../../features/Theme/ThemeProvider"
import { IPaperTextInputProps, ITextInputProps } from "../TextInput"
import TextInput from "../TextInput/TextInput"
import { Caption } from "../Texts"
import useToggle from "../Toggle/useToggle"

interface IProps {
  printSizeInfo?: boolean
  info?: string
  color?: "primary" | "secondary" | "default"
  forwardRef?: Ref<NativeInput>
}

const PaperPasswordInput: FC<IProps & IPaperTextInputProps & ITextInputProps> = ({
  info,
  color,
  printSizeInfo,
  forwardRef,
  ...props
}) => {
  const {
    colors,
    dimensions: { spacing },
    typography,
  } = useTheme()
  const { value, toggle } = useToggle(false)
  const infoStyles: StyleProp<TextStyle> = [
    typography.caption,
    styles.info,
    {
      paddingTop: spacing / 4,
      paddingLeft: spacing,
      color: colors.black.disabled,
    },
  ]
  const listColor = colors.overrides?.listIcon || colors.black.highEmphasis

  return (
    <React.Fragment>
      <View>
        <TextInput {...props} forwardRef={forwardRef} secureTextEntry={!value} />
        <TouchableOpacity style={styles.iconButton} onPress={toggle}>
          <List.Icon color={listColor} icon={`eye-${value ? "off" : "outline"}`} />
        </TouchableOpacity>
      </View>
      {!!info && printSizeInfo && (
        <Caption color={color} style={infoStyles}>
          {info}
        </Caption>
      )}
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  info: {
    alignSelf: "flex-start",
  },
  iconButton: {
    position: "absolute",
    right: 10,
    bottom: 13,
    margin: 0,
  },
})

export default PaperPasswordInput
