import { RouteProp } from "@react-navigation/core"
import {
  firestore,
  IRowItemProps,
  IUserContext,
  ParametersScreen,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { FlatList, SectionListData, StyleSheet, View } from "react-native"

import { Faq } from "../../common/Faq"
import { Phase } from "../../common/Phase"
import { collections } from "../../common/types"
import AccordionSection from "../../components/AccordionSection"
import { ICON_SIZE } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { ProtocolNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { OperationParamList } from "../OperationScreen/OperationNavigator"
import PhaseListView from "../PhaseResumeScreen/PhaseListView"

interface IProps {
  route: RouteProp<OperationParamList, "Protocol">
}

const ProtocolScreen: FC<IProps> = ({ route }) => {
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { t } = useTranslation()
  const {
    colors: {
      surface: { background: surface },
      white: { highEmphasis: white },
      primary,
    },
    dimensions: { spacing },
    typography: { subtitle1 },
  } = useTheme()

  const [filteredPreoperativeFaqs, setFilteredPreoperativeFaqs] = useState<Faq[] | undefined>(
    undefined,
  )
  const [filteredPostoperativeFaqs, setFilteredPostoperativeFaqs] = useState<Faq[] | undefined>(
    undefined,
  )

  const s = useMemo(
    () => ({
      indicator: { color: primary, size: ICON_SIZE },
      section: {
        backgroundColor: surface,
      },
      row: [
        styles.row,
        {
          backgroundColor: white,
          padding: spacing / 2,
        },
      ],
      scrollview: [
        styles.scrollview,
        {
          backgroundColor: surface,
        },
      ],
    }),
    [primary, white, spacing, surface],
  )

  const [blockPhases] = useCollectionData<Phase>(
    (firestore()
      .collection(collections.PHASES)
      .where("pathology", "==", route?.params?.pathology)
      .orderBy("startDay", "asc") as unknown) as firebase.firestore.Query<Phase>,
  )

  const blocksData = _(blockPhases)
    .filter(blockPhase => blockPhase.showProtocoleTab)
    .map(
      blockPhase =>
        ({
          name: itemField(blockPhase, "titlePhysician", t),
          rowStyle: s.row,
          textStyle: subtitle1,
          route: "PhaseResume",
          params: { blockPhase },
        } as IRowItemProps<OperationParamList>),
    )
    .value()

  const phaseSectionData: Array<SectionListData<IRowItemProps<OperationParamList>, any>> = [
    {
      shadows: 1,
      data: blocksData,
      title: "",
    },
  ]

  const [phaseFaqs] = useCollectionData<Faq>(
    (firestore()
      .collection(collections.FAQ)
      .where("disabled", "==", false) as unknown) as firebase.firestore.Query<Faq>,
  )

  const filterFaqs = useCallback(
    (faqs: Faq[], when: string) =>
      _.filter(
        faqs,
        f =>
          f.when === when &&
          (_.isEmpty(f.specialty) || _.includes(userData?.specialties, f.specialty)) &&
          (_.isEmpty(f.organs) || _.includes(f.organs, route?.params?.organId)) &&
          (_.isEmpty(f.pathologies) || _.includes(f.pathologies, route?.params?.pathology)),
      ),
    [route, userData?.specialties],
  )

  useEffect(() => {
    if (phaseFaqs) {
      const preoperativeFaqs = filterFaqs(phaseFaqs, "before")
      const postoperativeFaqs = filterFaqs(phaseFaqs, "after")
      setFilteredPreoperativeFaqs(preoperativeFaqs)
      setFilteredPostoperativeFaqs(postoperativeFaqs)
    }
  }, [phaseFaqs, filterFaqs])

  const FlatListComponents = [
    phaseSectionData && phaseSectionData[0].data.length > 0 && (
      <AccordionSection
        key="phaseResume"
        leftIcon="view-list"
        title={t(`${ProtocolNS}:phasesList`)}
        expandList={false}
      >
        <ParametersScreen
          listKey="phaseList"
          optionalSectionListStyle={s.section}
          sections={phaseSectionData}
        />
      </AccordionSection>
    ),
    <PhaseListView
      key="PhaseFaqsPreoperative"
      typeItem={t(`${ProtocolNS}:faqpreop`)}
      listIds={filteredPreoperativeFaqs}
    />,
    <PhaseListView
      key="PhaseFaqsPostoperative"
      typeItem={t(`${ProtocolNS}:faqpostop`)}
      listIds={filteredPostoperativeFaqs}
    />,
  ]

  return (
    <View style={s.scrollview}>
      <FlatList
        data={FlatListComponents}
        renderItem={({ item }) => <React.Fragment>{item}</React.Fragment>}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    height: 80,
  },
  scrollview: {
    flex: 1,
    overflow: "scroll",
  },
})

export default ProtocolScreen
