import { H6, Subtitle1, useTheme } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { VictoryAxis, VictoryBar, VictoryChart } from "victory-native"

import { DatesRange } from "../../common/Progression"
import { RadioItem } from "../../components/Rating"
import { PatientNS } from "../../features/i18n/constants"

interface IProps {
  datesRange?: DatesRange[]
  chartTitle: string
  icons: RadioItem[]
  loadingEvening: boolean
  loadingMorning?: boolean
}

const PatientStatistics: FC<IProps> = ({
  datesRange,
  chartTitle,
  icons,
  loadingEvening,
  loadingMorning,
}) => {
  const { t } = useTranslation(PatientNS)

  const RenderIcons = useMemo(
    () => () => (
      <View style={styles.iconsContainer}>
        {_(icons)
          .clone()
          .reverse()
          .map(icon => (
            <View key={icon?.value} style={styles.icons}>
              {icon?.checked}
            </View>
          ))}
      </View>
    ),
    [icons],
  )

  const {
    dimensions: { spacing },
    colors: { primary },
  } = useTheme()

  const s = useMemo(
    () => ({
      chartContainer: {
        padding: spacing,
      },
      chartTitle: {
        color: primary,
      },
      dataContainer: {
        paddingLeft: spacing * 2,
      },
      dataStatusContainer: [
        styles.dataStatusContainer,
        {
          padding: spacing * 4,
          marginLeft: spacing * 2,
          marginRight: spacing,
        },
      ],
    }),
    [spacing, primary],
  )

  return (
    <View style={s.chartContainer}>
      <View style={styles.titleContainer}>
        <H6 style={s.chartTitle}>{chartTitle}</H6>
      </View>
      <View style={s.dataContainer}>
        <View style={s.dataStatusContainer}>
          <Subtitle1 style={styles.dataStatus}>
            {!datesRange
              ? t("errorData")
              : loadingEvening || loadingMorning
              ? t("loadingData")
              : datesRange?.every(value => value.statData === 0)
              ? t("noData")
              : null}
          </Subtitle1>
        </View>
        <VictoryChart domainPadding={20}>
          <VictoryAxis
            dependentAxis
            domain={[0, 5]}
            style={{
              grid: { stroke: "#7378FD3D", outline: "24%" },
              axis: { stroke: "none" },
            }}
            standalone={false}
            tickLabelComponent={<RenderIcons />}
          />
          <VictoryAxis
            tickValues={datesRange?.map(d => d.day)}
            tickFormat={x => dayjs(x).format("ddd")}
            style={{
              axis: { stroke: "#7378FD3D" },
            }}
          />
          {datesRange ? (
            <VictoryBar
              x="day"
              y="statData"
              alignment="middle"
              barWidth={30}
              style={{ data: { fill: primary } }}
              data={datesRange}
            />
          ) : null}
        </VictoryChart>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: "center",
  },
  iconsContainer: {
    position: "absolute",
    top: 35,
  },
  icons: {
    width: 60,
    height: 40,
  },
  dataStatusContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  dataStatus: {
    textAlign: "center",
  },
})

export default PatientStatistics
