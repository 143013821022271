import { Body2, Button, H6, useTheme } from "capsule"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Dimensions, Modal, Platform, StyleSheet, View } from "react-native"

import { JourneyNS } from "../features/i18n/constants"

interface ConfirmationModalProps {
  visible: boolean
  onRequestClose: () => void
  confirmationMessage: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  visible,
  onRequestClose,
  confirmationMessage,
}) => {
  const { t } = useTranslation(JourneyNS)

  const { width } = Dimensions.get("window")

  const {
    colors: {
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      modalView: [
        styles.modalView,
        {
          backgroundColor: white,
          padding: spacing,
          width: Platform.OS === "web" ? width / 4 : "85%",
          marginLeft: Platform.OS === "web" ? width / 3 : 0,
        },
      ],
      text: [
        {
          paddingVertical: spacing,
        },
      ],
    }),
    [spacing, white, width],
  )

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <View style={styles.centeredView}>
        <View style={s.modalView}>
          <H6>{t("exercicesView.confirmationMessage")}</H6>
          <Body2 style={s.text}>{confirmationMessage}</Body2>
          <Button style={styles.modalButton} onPress={onRequestClose}>
            {t("common:button.ok")}
          </Button>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    borderRadius: 10,
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    padding: 20,
  },
  text: {
    textAlign: "center",
    marginBottom: 15,
  },
  modalButton: {
    width: "45%",
    borderRadius: 5,
    marginHorizontal: "1%",
  },
})

export default ConfirmationModal
