import { useState } from "react"
import DocumentPicker, {
  DocumentPickerOptions,
  Platform as DocPicPlatform,
} from "react-native-document-picker"
import ImageCropPicker, { Image, Options } from "react-native-image-crop-picker"

import { logger } from "../../utils/logger"

export interface DocumentPickerResponse {
  uri: string
  fileCopyUri: string
  copyError?: string
  type: string
  name: string
  size: number
}

const useGalleryPicker = () => {
  const defaultOptions: Options = {
    cropping: true,
    mediaType: "any",
  }

  const [source, setSource] = useState<Image | DocumentPickerResponse | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  const clearCamera = () => {
    setSource(undefined)
  }

  const openCamera = async (options: Options = defaultOptions) => {
    try {
      const image = await ImageCropPicker.openCamera(options)
      setSource(image)
    } catch (e) {
      setError(e)
      logger("camera error", e)
    }
  }
  const openGallery = async (options: Options = defaultOptions) => {
    try {
      const image = await ImageCropPicker.openPicker(options)
      setSource(image)
    } catch (e) {
      setError(e)
      logger("gallery error", e)
    }
  }

  const openDocumentGallery = async (
    type: DocumentPickerOptions<DocPicPlatform>["type"] = [DocumentPicker.types.allFiles],
  ) => {
    try {
      const document = await DocumentPicker.pick({
        type,
        copyTo: "cachesDirectory",
      })
      setSource(document)
    } catch (e) {
      setError(e)
      logger("gallery error", e)
    }
  }

  return {
    openCamera,
    openGallery,
    openDocumentGallery,
    source,
    error,
    clearCamera,
  }
}

export default useGalleryPicker
