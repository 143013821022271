import * as React from "react"
import { ScrollView, TextInput } from "react-native"

const focusOnRef = (
  ref?: React.RefObject<TextInput>,
  scrollViewRef?: React.RefObject<ScrollView>,
  top = false,
) => () => {
  if (scrollViewRef && scrollViewRef.current) {
    top ? scrollViewRef.current.scrollTo({ x: 0, y: 0 }) : scrollViewRef.current.scrollToEnd()
  }
  if (ref && ref.current) {
    ref.current.focus()
  }
}

export default focusOnRef
