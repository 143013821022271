import { Button, useTheme } from "capsule"
import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { ICON_SIZE, INPUT_HEIGHT } from "../../../features/config/Constants"
import { PatientNS } from "../../../features/i18n/constants"
import { RowItem } from "../RowItem"
import { VectorIcon } from "../VectorIcon"

interface IProps {
  surgeryDuration?: string
  surgeryAdditionalInfo?: string
  bipolar?: string
  setEditable: Dispatch<SetStateAction<boolean>>
  isSmithNephew: boolean
}

export const AdditionalFieldsScreen: FC<IProps> = ({
  surgeryDuration,
  surgeryAdditionalInfo,
  bipolar,
  setEditable,
  isSmithNephew,
}) => {
  const {
    dimensions: { spacing },
    colors: {
      primary,
      black: { highEmphasis, transparent },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      label: {
        paddingBottom: spacing / 6,
      },
      row: [
        styles.row,
        {
          backgroundColor: transparent,
          marginBottom: spacing,
        },
      ],
      padding: {
        paddingVertical: spacing,
      },
    }),
    [spacing, transparent],
  )

  const { t } = useTranslation()

  const renderLeftChild = useCallback(
    (name: string) => (
      <VectorIcon size={ICON_SIZE} name={name} color={highEmphasis} category="MaterialIcons" />
    ),
    [highEmphasis],
  )

  const rowProps = useMemo(
    () => ({
      rowStyle: s.row,
    }),
    [s.row],
  )

  return (
    <>
      <RowItem
        {...rowProps}
        labelStyle={s.label}
        name={surgeryDuration}
        placeholder={t(`${PatientNS}:toFill`)}
        label={t(`${PatientNS}:surgeryDuration`)}
        leftChild={renderLeftChild("access-time")}
      />
      <RowItem
        {...rowProps}
        labelStyle={s.label}
        name={surgeryAdditionalInfo}
        placeholder={t(`${PatientNS}:toFill`)}
        label={t(`${PatientNS}:surgeryAdditionalInfo`)}
        leftChild={renderLeftChild("event-note")}
      />
      {isSmithNephew ? (
        <RowItem
          {...rowProps}
          labelStyle={s.label}
          name={bipolar ? t(`${PatientNS}:label.${bipolar}`) : undefined}
          placeholder={t(`${PatientNS}:toFill`)}
          label={t(`${PatientNS}:bipolar`)}
          leftChild={renderLeftChild("build")}
        />
      ) : null}
      <Button
        onPress={() => setEditable(true)}
        style={s.padding}
        mode="text"
        labelStyle={{ color: primary }}
      >
        {t("common:button.modify")}
      </Button>
    </>
  )
}

const styles = StyleSheet.create({
  row: {
    height: INPUT_HEIGHT,
  },
})
