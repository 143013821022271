import { firestore, SectionSeparator, Subtitle1, Touchable, useTheme } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { ActivityIndicator, Checkbox } from "react-native-paper"

import { checkValidity } from "../../common/dataFunctions"
import { Instruction } from "../../common/Instruction"
import { collections } from "../../common/types"
import { INPUT_HEIGHT } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { PatientNS } from "../../features/i18n/constants"
import { updatePatientData } from "../../features/models/UserDataFunctions"
import { IChildrenProps } from "./PatientInformationsView"

const PostOpInstructionsView: FC<IChildrenProps> = ({ patient, isSurgeon }) => {
  const {
    colors: { primary },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation(PatientNS)
  const [instructions, loading, error] = useCollectionData<Instruction>(
    (firestore()
      .collection(collections.INSTRUCTIONS)
      .where("disabled", "==", false) as unknown) as firebase.firestore.Query<Instruction>,
  )
  const filteredInstructions = _.filter(instructions, i =>
    checkValidity(patient, i.specialty, i.organ, i.pathology, i.partner),
  )
  const arrays = filteredInstructions
    ? _.map(filteredInstructions, value => ({
        [itemField(value, "title", t)]:
          patient?.postSurgery?.[itemField(value, "title", t)] ?? false,
      }))
    : []
  const radios = _.reduce(arrays, (result, value) => ({ ...result, ...value }), {})

  const s = useMemo(
    () => ({
      touchable: [
        styles.touchable,
        {
          paddingHorizontal: spacing,
        },
      ],
      indicator: {
        paddingTop: spacing,
      },
    }),
    [spacing],
  )

  const updateCheckbox = useCallback(
    (key: string, value: boolean) => () => {
      updatePatientData(patient.id, { postSurgery: { [key]: !value } })
    },
    [patient.id],
  )

  return loading ? (
    <ActivityIndicator style={s.indicator} />
  ) : error ? null : (
    <View>
      {_.map(radios, (value, key) => (
        <View {...{ key }}>
          <Touchable
            disabled={!isSurgeon}
            rippleColor={primary}
            style={s.touchable}
            onPress={updateCheckbox(key, value)}
          >
            <>
              <Checkbox.Android
                color={primary}
                disabled={!isSurgeon}
                onPress={updateCheckbox(key, value)}
                status={value ? "checked" : "unchecked"}
              />
              <Subtitle1 emphasis="high">{key}</Subtitle1>
            </>
          </Touchable>
          <SectionSeparator />
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  touchable: {
    flexDirection: "row",
    alignItems: "center",
    height: INPUT_HEIGHT,
  },
})

export default PostOpInstructionsView
