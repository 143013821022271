import { useField, useFormikContext } from "formik"
import React, { FC, useCallback, useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native"
import { Checkbox, TouchableRipple } from "react-native-paper"

import useTheme from "../../features/Theme/ThemeProvider"
import { Subtitle1 } from "../Texts"
import { ITouchableRippleProps, Touchable } from "../Touchable"
import { VectorIcon, VectorIconProps } from "../VectorIcon"

interface IProps {
  name: string
  label?: string
  borderless?: boolean
  showDefault?: boolean // use default paper check
  contentStyle?: StyleProp<ViewStyle>
  touchableProps?: ITouchableRippleProps
  styles?: Partial<{ label: StyleProp<TextStyle> }>
  icons?: { checked: VectorIconProps; unchecked: VectorIconProps }
  isCheckbox?: boolean
}
/** Formik checkbox can take vectorIcons or use default Paper system */
export const FormikCheckbox: FC<IProps> = ({
  name,
  label,
  styles,
  icons,
  showDefault,
  borderless, // inside touchableProps, not working.
  contentStyle,
  touchableProps,
  isCheckbox,
}) => {
  const [field] = useField(name)
  const { setFieldValue } = useFormikContext()
  const {
    colors: { primary, overrides, white, black, accent },
  } = useTheme()

  const onPress = useCallback(() => {
    setFieldValue(name, !field.value)
  }, [field.value, name, setFieldValue])

  const s = useMemo(
    () => ({
      checkbox: [
        defaultStyles.checkbox,
        contentStyle,
        isCheckbox ? { minHeight: 40 } : {},
        !showDefault
          ? {
              backgroundColor:
                isCheckbox && field.value ? accent : field.value ? primary : white.highEmphasis,
            }
          : undefined,
      ],
      color: !showDefault
        ? isCheckbox
          ? black.highEmphasis
          : field.value
          ? white.highEmphasis
          : black.highEmphasis
        : undefined,
    }),
    [
      black,
      contentStyle,
      field.value,
      primary,
      showDefault,
      white.highEmphasis,
      accent,
      isCheckbox,
    ],
  )
  return (
    <Touchable
      style={s.checkbox}
      {...touchableProps}
      {...{ onPress, borderless }}
      rippleColor={overrides?.ripple || primary}
    >
      <>
        {icons ? (
          <TouchableRipple // keep same effect provided by paper on Checkbox
            {...{ onPress }}
            borderless
            style={defaultStyles.icon}
            rippleColor={overrides?.ripple || primary}
          >
            {field.value ? <VectorIcon {...icons.checked} /> : <VectorIcon {...icons.unchecked} />}
          </TouchableRipple>
        ) : showDefault ? (
          <Checkbox.Android
            {...{ onPress }}
            color={overrides?.checkbox || primary}
            status={field.value ? "checked" : "unchecked"}
          />
        ) : null}
        {label ? (
          <Subtitle1 color={s.color} style={styles?.label}>
            {label}
          </Subtitle1>
        ) : null}
      </>
    </Touchable>
  )
}

const defaultStyles = StyleSheet.create({
  checkbox: { flexDirection: "column-reverse", alignSelf: "flex-start" },
  ripple: {
    alignItems: "center",
  },
  icon: {
    borderRadius: 100,
  },
})
