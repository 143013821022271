import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import { Button } from "../Button"
import { VectorIcon, VectorIconProps } from "../VectorIcon"

interface IProps {
  openGallery?: () => void
  openCamera?: () => void
  openDocuments?: () => void
  icons?: { camera?: VectorIconProps; gallery?: VectorIconProps; documents?: VectorIconProps }
}

const PermissionDialogContent: FC<IProps> = ({ openGallery, openCamera, openDocuments, icons }) => {
  const {
    dimensions: { spacing },
    colors: {
      black: { highEmphasis },
    },
  } = useTheme()
  const { t } = useTranslation()

  const s = useMemo(
    () => ({
      label: { color: highEmphasis },
      button: { paddingBottom: spacing },
      dialog: [
        styles.dialog,
        {
          marginLeft: spacing,
        },
      ],
    }),
    [highEmphasis, spacing],
  )

  return (
    <>
      {openCamera ? (
        <Button
          mode="text"
          style={s.button}
          labelStyle={s.label}
          onPress={openCamera}
          contentStyle={s.dialog}
          icon={() => (icons?.camera ? <VectorIcon {...icons?.camera} /> : null)}
        >
          {t("alert.photo.camera")}
        </Button>
      ) : undefined}
      {openGallery ? (
        <Button
          mode="text"
          labelStyle={s.label}
          style={s.button}
          onPress={openGallery}
          contentStyle={s.dialog}
          icon={() => (icons?.gallery ? <VectorIcon {...icons?.gallery} /> : null)}
        >
          {t("alert.photo.gallery")}
        </Button>
      ) : undefined}
      {openDocuments ? (
        <Button
          mode="text"
          labelStyle={s.label}
          style={s.button}
          onPress={openDocuments}
          contentStyle={s.dialog}
          icon={() => (icons?.documents ? <VectorIcon {...icons?.documents} /> : null)}
        >
          {t("alert.photo.documents")}
        </Button>
      ) : undefined}
    </>
  )
}

const styles = StyleSheet.create({
  dialog: {
    alignSelf: "flex-start",
  },
})

export default PermissionDialogContent
