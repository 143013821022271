import { useNavigation } from "@react-navigation/native"
import {
  BottomButtonContainer,
  Button,
  ButtonType,
  OptimisticSwitch,
  Subtitle1,
  useAlert,
  userContext,
  useTheme,
} from "capsule"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, ScrollView, StyleSheet, View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { Edge } from "react-native-safe-area-context/lib/typescript/src/SafeArea.types"

import { CguNS } from "../../features/i18n/constants"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { maxWidth } from "../../ThemeApp"

interface IProps {
  isEdit?: boolean
}

const ShareContactScreen: FC<IProps> = ({ isEdit = false }) => {
  const {
    fontMaker,
    colors: {
      secondary,
      black: { highEmphasis: black },
      surface: { disabled, background },
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()
  const navigation = useNavigation()
  const { showDialog } = useAlert()
  const { userDocRef, userData } = useContext(userContext)
  const { t } = useTranslation(CguNS)
  const edges: Edge[] = ["left", "right"]
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(userData?.shareContact ?? false)
  const s = useMemo(
    () => ({
      screenView: [
        styles.screenView,
        {
          padding: spacing,
          backgroundColor: background,
        },
      ],
      screenStyle: [Platform.OS === "web" ? { width: maxWidth } : null],
      upperView: [styles.view, { flexDirection: "column" }],
      textView: [
        styles.view,
        {
          padding: spacing,
          backgroundColor: background,
        },
      ],
      button: [styles.view, { margin: spacing / 2 }],
      separator: [
        styles.separator,
        {
          backgroundColor: disabled,
        },
      ],
      switch: {
        style: {
          marginLeft: spacing,
        },
        trackColor: {
          true: secondary,
          false: black,
        },
        thumbColor: white,
      },
      switchContainer: [
        styles.switchContainer,
        {
          padding: spacing,
          backgroundColor: background,
        },
      ],
      switchLabel: [
        styles.view,
        {
          paddingRight: spacing,
        },
      ],
      dialogTitle: {
        padding: spacing,
      },
      buttonContainer: [
        styles.buttonContainer,
        {
          backgroundColor: background,
        },
      ],
      boldText: [fontMaker({ weight: "Bold" })],
    }),
    [white, spacing, disabled, black, secondary, fontMaker, background],
  )

  const onPress = useCallback(() => {
    if (!isEdit && !isSwitchOn) {
      showDialog({
        type: "button",
        dismissable: false,
        titleStyle: s.dialogTitle,
        title: t("disableTitle", { CguNS }),
        message: t("disableMessage", { CguNS }),
        positive: {
          mode: "text",
          onPress: () => updateUserData(userDocRef, { shareContact: isSwitchOn }),
          type: ButtonType.POSITIVE,
          label: t("understand", { CguNS }),
          labelType: "confirm",
        },
      })
    } else {
      updateUserData(userDocRef, { shareContact: isSwitchOn })
    }
    if (isEdit && (!isSwitchOn || userData.address)) {
      navigation.goBack()
    }
  }, [navigation, userDocRef, isSwitchOn, isEdit, userData.address, s.dialogTitle, showDialog, t])

  const onValueChange = useCallback(async (val: boolean) => {
    setIsSwitchOn(val)
  }, [])

  return (
    <SafeAreaView style={styles.view} {...{ edges }}>
      <View style={s.screenView}>
        <View style={s.screenStyle}>
          {/* @ts-ignore */}
          <ScrollView style={s.upperView}>
            <View style={s.textView}>
              <Subtitle1>{t("address1", { CguNS })}</Subtitle1>
              <Subtitle1 style={s.boldText}>{t("address2", { CguNS })}</Subtitle1>
              <Subtitle1>{t("address3", { CguNS })}</Subtitle1>
            </View>
          </ScrollView>
          <View style={s.separator} />
          <View style={s.switchContainer}>
            <Subtitle1 style={s.switchLabel}>{t("addressConfirm")}</Subtitle1>
            <OptimisticSwitch ms={250} {...s.switch} {...{ onValueChange }} value={isSwitchOn} />
          </View>
          <View style={s.separator} />
          <BottomButtonContainer edges={["bottom"]} style={s.buttonContainer}>
            <Button style={s.button} onPress={onPress}>
              {t("common:button.validate")}
            </Button>
          </BottomButtonContainer>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  buttonContainer: {
    width: "100%",
  },
  separator: {
    height: 1,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  screenView: {
    height: "100%",
    alignItems: "center",
  },
})

export default ShareContactScreen
