import { Caption, FormikRadioButton, generateShadow, useTheme, VectorIcon } from "capsule"
import { useFormikContext } from "formik"
import _ from "lodash"
import React, { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, StyleSheet, View } from "react-native"

import { Option, ScaleType } from "../common/Proms"
import { itemField } from "../features/hooks/useItemI18n"
import VisualAnalogScale from "./VisualAnalogScale"

interface IProps {
  data: Option[]
  name: string
  scaleType?: ScaleType
  isRating?: boolean
  onSubmit: (name?: string, val?: any) => void
  scaleValue?: string | number | boolean | string[]
}

const keyExtractor = item => item?.id

const smileys = [
  "happy",
  "dead",
  "dead",
  "frown",
  "frown",
  "neutral",
  "neutral",
  undefined,
  undefined,
  "excited",
  "excited",
]

const ScaleForm: FC<IProps> = ({
  data,
  name,
  onSubmit,
  scaleType,
  isRating = false,
  scaleValue,
}) => {
  const {
    colors: {
      black: { highEmphasis: black, mediumEmphasis },
      white: { highEmphasis: white },
      surface: { background },
      primary,
    },
    dimensions: { spacing },
  } = useTheme()
  const [optionValue, setOptionValue] = useState<number>(-1)
  const { setFieldValue } = useFormikContext()

  const handleValueChange = (value: number) => {
    setOptionValue(value)
    setFieldValue(name, value)
  }

  const isChecked = useCallback(
    (value: number) => (scaleValue ? scaleValue === value : optionValue === value),
    [optionValue, scaleValue],
  )
  const onOptionPress = useCallback(
    (value: number) => () => {
      setOptionValue(value)
      // formikValues is not updated after the selection of the scale value
      if (!isRating) {
        setTimeout(() => onSubmit(name, value), 500)
      }
    },
    [isRating, name, onSubmit],
  )
  const { t } = useTranslation()
  const smileyName = _.isUndefined(smileys[optionValue])
    ? "emoticon-outline"
    : `emoticon-${_.isUndefined(optionValue) ? "happy" : smileys[optionValue]}-outline`

  const dataList = useMemo(
    () =>
      _.map(_.range(data?.[0]?.value, data?.[1]?.value + 1), o => ({
        value: o,
        id: `${o}`,
      })),
    [data],
  )
  const s = useMemo(
    () => ({
      formContainer: [
        {
          backgroundColor: background,
        },
        styles.formContainer,
      ],
      optionsContainer: [
        styles.optionsContainer,
        {
          paddingBottom: spacing,
        },
      ],
      buttonLabel: [
        styles.buttonLabel,
        {
          paddingVertical: spacing / 2,
        },
        generateShadow(4),
      ],
      selectedColor: {
        color: white,
        backgroundColor: primary,
      },
      unselectedColor: {
        backgroundColor: white,
        color: black,
      },
      buttonContainer: [
        styles.buttonContainer,
        {
          margin: spacing / 2,
        },
        generateShadow(4),
      ],
      iconView: [
        styles.icon,
        {
          marginBottom: spacing,
        },
      ],
      labelContent: [
        styles.labelContent,
        {
          marginHorizontal: spacing * 2.5,
        },
      ],
    }),
    [black, primary, spacing, white, background],
  )

  const specificLabel = useCallback(
    item => (
      <Caption
        style={[s.buttonLabel, isChecked(item?.value) ? s.selectedColor : s.unselectedColor]}
      >
        {item?.value}
      </Caption>
    ),
    [isChecked, s.buttonLabel, s.selectedColor, s.unselectedColor],
  )

  const renderItem = useCallback(
    ({ item }) => (
      <FormikRadioButton
        {...{ name }}
        value={item?.value}
        textOnly
        touchableStyle={s.buttonContainer}
        onPress={onOptionPress(item?.value)}
        specificLabel={specificLabel(item)}
      />
    ),
    [name, onOptionPress, s.buttonContainer, specificLabel],
  )

  const renderSmiley = useMemo(
    () => (
      <VectorIcon
        size={60}
        name={smileyName}
        category="MaterialCommunityIcons"
        color={_.isUndefined(optionValue) ? mediumEmphasis : primary}
      />
    ),
    [mediumEmphasis, optionValue, primary, smileyName],
  )

  return (
    <>
      {scaleType === "gauge" ? (
        <VisualAnalogScale data={data} setValue={handleValueChange} value={optionValue} />
      ) : (
        <View style={s.formContainer}>
          {scaleType === "smiley" ? <View style={s.iconView}>{renderSmiley}</View> : null}
          <View style={s.labelContent}>
            <View style={styles.view}>
              <Caption emphasis="high">{itemField(data?.[0], "title", t)}</Caption>
            </View>
            <View style={styles.view}>
              <Caption style={styles.right} emphasis="high">
                {itemField(data?.[1], "title", t)}
              </Caption>
            </View>
          </View>
          <FlatList
            numColumns={5}
            data={dataList}
            scrollEnabled={false}
            style={s.optionsContainer}
            {...{ renderItem, keyExtractor }}
            contentContainerStyle={styles.contentContainer}
          />
        </View>
      )}
    </>
  )
}
const styles = StyleSheet.create({
  buttonContainer: {
    minWidth: 48,
  },
  optionsContainer: {
    flexGrow: 0,
  },
  contentContainer: {
    width: "100%",
    alignItems: "center",
  },
  buttonLabel: {
    textAlign: "center",
    flex: 1,
    overflow: "hidden",
    borderRadius: 8,
  },
  formContainer: {
    justifyContent: "center",
    flex: 1,
  },
  labelContent: {
    flexDirection: "row",
  },
  view: {
    flex: 1,
  },
  right: {
    textAlign: "right",
  },
  icon: {
    alignItems: "center",
  },
})

export default ScaleForm
