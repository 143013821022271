/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FirebaseAuthTypes } from "@react-native-firebase/auth"
import _ from "lodash"

import { ExerciseId } from "../common/CommonUserData"
import { Exercise } from "../common/Phase"
import { FirestoreSession } from "../features/models/Session"
import { AppUserData } from "../features/models/UserData"
/**
 * Eel = Exercise
 *
 * exercise with Array<eel> = dynamicExercise
 *    - dynamicId = exerciseId
 *    - id = eelId => this is the one use to create dailyPhase
 *
 * all callback are method to get firestore collection or document based on ids, pathology, phase or program
 *
 * */

/**
 * Check exercises from sessions to find validation
 * Use to return the first exercise if no validation
 *
 * return boolean
 * */
const checkIfAnyExerciseHasValidation = (sessions: FirestoreSession[]) =>
  _.some(sessions, session => {
    const exerciseIds = Object.keys(session.exercises)
    return _.some(exerciseIds, id => _.has(session.exercises[id], "validation"))
  })

/**
 * Returns the current exercise (eel) to be used in the dynamic based on session information
 * - If no sessions or no exercises validated, returns the first eel
 * - Otherwise, returns the first non-validated exercise found
 * - If all exercises are validated, returns the last exercise
 */
const getDynamicExercise = (
  eelByIds: Record<ExerciseId, Exercise>,
  eels: ExerciseId[],
  sessions: FirestoreSession[],
): Exercise | undefined => {
  const noExerciseHasValidation = !checkIfAnyExerciseHasValidation(sessions || [])

  if (_.isEmpty(sessions) || noExerciseHasValidation) {
    const firstExerciseId = _.head(eels)
    if (firstExerciseId !== undefined) {
      return eelByIds[firstExerciseId]
    }
    return undefined
  }

  let lastNonValidatedExercise: Exercise | undefined

  for (const id of eels) {
    const session = _.find(sessions, `exercises.${id}`)

    if (session) {
      if (!session.exercises[id].validation) {
        return eelByIds[id]
      }
      lastNonValidatedExercise = eelByIds[id]
    } else {
      return eelByIds[id]
    }
  }
  return lastNonValidatedExercise
}

/**
 * Return the formattedExercises arrau of the current session
 * Method to regroup fields
 * Integrate eel inside the formattedExercises array based on previous validation
 * Otherwise return the basic list of exercises of there is no dynamic
 * */

interface FormattedExercisesParams {
  userData: Pick<AppUserData, "pathology" | "phase" | "program" | "specialty">
  user: Pick<FirebaseAuthTypes.User, "uid"> | null
  callbackToGetProgramSessions: (
    userData: Pick<AppUserData, "program" | "specialty">,
    user: Pick<FirebaseAuthTypes.User, "uid"> | null,
  ) => Promise<FirestoreSession[]>
  callbackToGetPhaseExercises: (
    userData: Pick<AppUserData, "phase">,
  ) => Promise<Record<ExerciseId, Exercise>>
  callbackToGetEelByIds: (
    userData: Pick<AppUserData, "pathology">,
  ) => Promise<Record<ExerciseId, Exercise>>
}

const getFormattedExercises = async ({
  userData,
  user,
  callbackToGetProgramSessions,
  callbackToGetPhaseExercises,
  callbackToGetEelByIds,
}: FormattedExercisesParams): Promise<Exercise[]> => {
  try {
    if (!userData.program && userData.specialty === "onco") {
      return []
    }
    const previousSessions = await callbackToGetProgramSessions?.(userData, user)
    const exerciseByIds = await callbackToGetPhaseExercises?.(userData)
    const exerciseIds = _.map(exerciseByIds, (ex, id) => id)
    const eelByIds = await callbackToGetEelByIds?.(userData)
    return _.map(exerciseIds, id => {
      const ex = exerciseByIds?.[id]
      if (_.has(ex, "eels") && ex.eels) {
        const eel = getDynamicExercise(eelByIds, _.clone(ex.eels), previousSessions)
        return {
          ...eel,
          id: eel?.id,
          eels: ex?.eels, // keep it to check order for validation
          dynamicId: ex?.id,
        }
      }
      return ex
    }) as Exercise[]
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("getFormattedExercise error", e)
    throw new Error(e)
  }
}

export { getDynamicExercise, getFormattedExercises }
