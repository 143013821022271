import { useIsFocused } from "@react-navigation/native"
import { Formik, FormikConfig, FormikProps, FormikValues } from "formik"
import _ from "lodash"
import React, {
  cloneElement,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import { Button } from "../Button"
import { InputFocusProvider } from "../Form"
import { KeyboardSafeAreaScrollView } from "../KeyboardSafeAreaScrollView"
import { Caption } from "../Texts"

export interface InputScreenProps<Values> {
  caption?: string
  buttonLabel?: string
  showButton?: boolean
  Inputs: ReactElement | ReactElement[]
  bottomComp?: ReactNode
  middleComp?: ReactElement[]
  captionComp?: ReactNode
  buttonStyle?: {
    style?: StyleProp<ViewStyle>
    contentStyle?: StyleProp<ViewStyle>
    labelStyle?: StyleProp<TextStyle>
  }
  formikConfig?: FormikConfig<Values>
  viewStyle?: StyleProp<ViewStyle>
  captionStyle?: StyleProp<TextStyle>
  isTextStyle?: boolean | undefined
  formLoading?: boolean
  disableButtonMargin?: boolean
}

type childType<Values> = Omit<InputScreenProps<Values>, "formikConfig"> &
  Partial<FormikProps<Values>>

export const InputScreenChild = <Values extends FormikValues>({
  viewStyle,
  Inputs,
  caption,
  isValid,
  bottomComp,
  buttonLabel,
  middleComp,
  captionComp,
  handleSubmit,
  setFieldValue,
  isTextStyle,
  showButton = true,
  formLoading,
  disableButtonMargin,
  ...props
}: childType<Values>) => {
  const {
    dimensions: { spacing },
    colors: {
      black: { disabledButton, disabled: disabledText },
      surface: { background },
    },
    screenStyle,
  } = useTheme()

  const isFocused = useIsFocused()
  const [disabled, setDisabled] = useState(!isValid)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (isFocused) {
      setLoading(false)
      setDisabled(false)
    }
  }, [isFocused])

  useEffect(() => {
    if (formLoading === true) {
      setLoading(false)
      setDisabled(false)
    }
  }, [formLoading])

  const s = useMemo(
    () => ({
      caption: [{ marginTop: spacing }, props.captionStyle],
      button: [
        styles.button,
        !disableButtonMargin ? { marginTop: spacing } : null,
        props.buttonStyle?.style,
        disabled ? { backgroundColor: disabledButton } : null,
      ],
      buttonContent: !disabled
        ? props.buttonStyle?.contentStyle
        : { backgroundColor: disabledButton },
      buttonLabel: !disabled ? props.buttonStyle?.labelStyle : { color: disabledText },
      view: {
        paddingHorizontal: spacing * 2,
        paddingVertical: isTextStyle ? 0 : spacing,
        backgroundColor: background,
      },
    }),
    [
      spacing,
      disableButtonMargin,
      props.captionStyle,
      props.buttonStyle,
      disabled,
      disabledButton,
      disabledText,
      background,
      isTextStyle,
    ],
  )

  const onPress = useCallback(() => {
    setLoading(true)
    setDisabled(true)
    handleSubmit?.()
  }, [handleSubmit])

  useEffect(() => {
    setDisabled(!_.isEmpty(props.errors))
  }, [props.errors])

  return (
    <KeyboardSafeAreaScrollView
      style={[s.view, viewStyle, screenStyle]}
      keyboardShouldPersistTaps="handled"
    >
      {_.isArray(Inputs) ? (
        <InputFocusProvider>
          {_.map(_.flatten(_.map(Inputs, (elem, idx) => [elem, middleComp?.[idx]])), (elem, key) =>
            elem ? cloneElement(elem, { setFieldValue, key }) : null,
          )}
        </InputFocusProvider>
      ) : (
        cloneElement(Inputs, { setFieldValue })
      )}
      {!_.isEmpty(captionComp) ? (
        captionComp
      ) : caption ? (
        <Caption style={s.caption}>{caption}</Caption>
      ) : null}
      {showButton && (
        <Button
          style={s.button}
          {...{ onPress, disabled, loading }}
          labelStyle={s.buttonLabel}
          contentStyle={s.buttonContent}
        >
          {buttonLabel || t("save")}
        </Button>
      )}
      {bottomComp}
    </KeyboardSafeAreaScrollView>
  )
}

export const InputScreen = <Values extends FormikValues>({
  formikConfig,
  viewStyle,
  ...props
}: InputScreenProps<Values>) => (
  <>
    {!!formikConfig ? (
      <Formik {...formikConfig}>
        {formikProps => <InputScreenChild {...{ ...props, ...formikProps, viewStyle }} />}
      </Formik>
    ) : (
      <InputScreenChild {...{ ...props, viewStyle }} />
    )}
  </>
)

const styles = StyleSheet.create({
  button: {
    width: "100%",
  },
})
