import { Body2, Button, H6, TextInput, useTheme } from "capsule"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Dimensions, Modal, Platform, StyleSheet, Text, View } from "react-native"

import { Phase } from "../common/Phase"
import { JourneyNS } from "../features/i18n/constants"
import { Patient } from "../features/models/UserData"

interface AdditionalDaysModalProps {
  visible: boolean
  onRequestClose: () => void
  onChangeText: (text: string) => void
  onSubmit: () => void
  additionalDays?: number
  initialDays: number | null
  totalPhaseDays: number | undefined
  warningMessage: string | null
  patient: Patient
  currentPhase?: Phase
  selectedPhase?: Phase
}

const AdditionalDaysModal: React.FC<AdditionalDaysModalProps> = ({
  visible,
  onRequestClose,
  onChangeText,
  onSubmit,
  additionalDays,
  initialDays,
  totalPhaseDays,
  warningMessage,
  patient,
  currentPhase,
  selectedPhase,
}) => {
  const { t } = useTranslation(JourneyNS)

  const { width } = Dimensions.get("window")

  const {
    colors: {
      accent,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      modalView: [
        styles.modalView,
        {
          backgroundColor: white,
          padding: spacing,
          width: Platform.OS === "web" ? width / 4 : "85%",
          marginLeft: Platform.OS === "web" ? width / 3 : 0,
        },
      ],
      modalText: [
        styles.text,
        {
          marginHorizontal: spacing,
          marginBottom: spacing * 2,
        },
      ],
      popupInfoContainer: [
        styles.popupInfoContainer,
        {
          padding: spacing,
          backgroundColor: accent,
        },
      ],
      text: [
        {
          paddingVertical: spacing,
        },
      ],
      textInput: [
        styles.textInput,
        {
          marginBottom: spacing * 2,
        },
      ],
    }),
    [spacing, white, accent, width],
  )
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <View style={styles.centeredView}>
        <View style={s.modalView}>
          <H6 style={s.text}>
            {!patient.addedDays ||
            (currentPhase?.id && !patient.addedDays[currentPhase.id]) ||
            (currentPhase?.id && patient?.addedDays?.[currentPhase?.id] === 0)
              ? t("exercicesView.addAdditionalDays")
              : t("exercicesView.modifyAdditionalDays")}
          </H6>
          {patient.addedDays && selectedPhase?.id && patient.addedDays[selectedPhase.id] ? (
            <Body2 style={s.popupInfoContainer}>
              {t("exercicesView.modifyAddedDaysInfoKine", {
                addedDaysNumber: patient.addedDays[selectedPhase.id],
                initialDays,
                totalDaysNumber: totalPhaseDays,
              })}
            </Body2>
          ) : null}
          <View style={styles.row}>
            <Text style={s.modalText}>
              {patient.addedDays
                ? t("exercicesView.modifyDays")
                : t("exercicesView.additionalDays")}
            </Text>
            <TextInput
              style={s.textInput}
              blurOnSubmit
              autoComplete="off"
              autoCorrect={false}
              autoCapitalize="none"
              keyboardType="numeric"
              {...{ onChangeText }}
              value={additionalDays?.toString() || "0"}
            />
          </View>
          {warningMessage && <Text style={styles.warningText}>{warningMessage}</Text>}
          <Button style={styles.modalButton} onPress={onSubmit} disabled={!!warningMessage}>
            {t("common:button.validate")}
          </Button>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    borderRadius: 10,
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    padding: 20,
  },
  text: {
    textAlign: "center",
  },
  popupInfoContainer: {
    borderRadius: 8,
    overflow: "hidden",
    padding: 10,
    backgroundColor: "#f0f0f0",
    marginVertical: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 10,
  },
  modalText: {
    marginHorizontal: 10,
  },
  textInput: {
    height: 40,
    width: "15%",
    marginVertical: 10,
  },
  warningText: {
    textAlign: "center",
    marginVertical: 10,
    color: "red",
  },
  modalButton: {
    width: "45%",
    borderRadius: 5,
    marginHorizontal: "1%",
    marginTop: 10,
  },
})

export default AdditionalDaysModal
