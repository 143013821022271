import { FormikHelpers } from "formik"
import _ from "lodash"
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  KeyboardTypeOptions,
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { ICON_SIZE_SMALL } from "../../../features/config/Constants"
import useTheme from "../../features/Theme/ThemeProvider"

interface IProps {
  name?: string
  length: number
  focus?: boolean
  clear?: boolean
  editable?: boolean
  onValidate: (text?: string) => void
  setFieldValue?: FormikHelpers<any>["setFieldValue"]
  keyboardType?: KeyboardTypeOptions
  inputStyle?: StyleProp<ViewStyle>
}

/** Android and iOS have different behavior with the text style of the input. Waiting for correction of this ticket
 * https://github.com/facebook/react-native/issues/27658 */
const SMSValidationCodeInput: FC<IProps> = ({
  setFieldValue,
  name,
  clear,
  length,
  editable = true,
  onValidate,
  keyboardType = "phone-pad",
  inputStyle,
  focus = true,
}) => {
  const [editing, setEditing] = useState<boolean>()
  const [value, setValue] = useState<string>()

  const inputRef = useRef<TextInput>(null)

  const {
    dimensions: { spacing },
    colors: {
      primary,
      surface,
      black: { highEmphasis },
    },
    typography: { h6, subtitle1, body1 },
  } = useTheme()

  useEffect(() => {
    if (focus) {
      setTimeout(() => inputRef.current?.focus(), 100)
    }
  }, [focus])

  useEffect(() => {
    if (clear) {
      setValue("")
    }
  }, [clear])

  const onChangeText = useCallback(
    (val: string) => {
      setValue(val)
      onValidate(val)
      if (val.length === length) {
        inputRef.current?.blur()
        if (name) {
          setFieldValue?.(name, val)
        }
      }
    },
    [length, name, onValidate, setFieldValue],
  )

  const onSubmitEditing = useCallback(() => {
    onValidate(value)
  }, [onValidate, value])
  const onFocusChange = (val: boolean) => () => setEditing(val)

  const style: StyleProp<TextStyle> = useMemo(
    () => ({
      ...h6,
      paddingLeft: value && Platform.OS === "android" ? spacing / 2 : spacing * 1.2,
      fontSize: value && length === 8 ? subtitle1.fontSize : body1.fontSize,
      letterSpacing: value && length === 8 ? spacing : spacing * 1.4,
      color: highEmphasis,
      paddingVertical: spacing,
      backgroundColor: surface.textInput,
      textAlign: Platform.select({
        ios: "center",
        web: value ? "center" : undefined,
        android: undefined,
      }),
    }),
    [h6, highEmphasis, spacing, surface.textInput, value, subtitle1, body1, length],
  )
  const viewStyle: StyleProp<ViewStyle> = useMemo(
    () => [styles.view, { borderColor: editing ? primary : highEmphasis, width: length * 38 }],
    [editing, length, highEmphasis, primary],
  )

  const indicatorProps = useMemo(
    () => ({
      size: ICON_SIZE_SMALL,
      color: primary,
      style: [styles.indicator, { left: spacing / 4, bottom: spacing }],
    }),
    [primary, spacing],
  )

  return (
    <View style={styles.container}>
      <View style={viewStyle}>
        <TextInput
          ref={inputRef}
          selectionColor={primary}
          maxLength={length}
          style={[style, inputStyle]}
          onFocus={onFocusChange(true)}
          onBlur={onFocusChange(false)}
          placeholderTextColor={highEmphasis}
          placeholder={_.repeat("_", length)}
          {...{ value, onChangeText, onSubmitEditing, keyboardType, editable }}
        />
        {!editable ? <ActivityIndicator {...indicatorProps} /> : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  view: {
    borderBottomWidth: 1,
  },
  indicator: {
    position: "absolute",
  },
})

export default SMSValidationCodeInput
