import { H6, SectionSeparator, Subtitle1, useTheme } from "capsule"
import React, { FC, useMemo } from "react"
import { View } from "react-native"

interface IProps {
  title: string
  description?: string
  showSeparator?: boolean
}

const PromsFormHeader: FC<IProps> = ({ title, description, showSeparator = true }) => {
  const {
    colors: {
      black: { highEmphasis },
      surface: { background },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      container: {
        padding: spacing,
        backgroundColor: background,
      },
      buttonContent: showSeparator
        ? {
            marginBottom: spacing,
          }
        : undefined,
      subtitle: {
        marginTop: spacing,
        color: highEmphasis,
      },
    }),
    [highEmphasis, showSeparator, spacing, background],
  )

  return (
    <View style={s.container}>
      <H6 style={s.buttonContent}>{title}</H6>
      {showSeparator ? <SectionSeparator /> : null}
      {description ? <Subtitle1 style={s.subtitle}>{description}</Subtitle1> : null}
    </View>
  )
}

export default PromsFormHeader
