import { FormikRadioButton, useTheme } from "capsule"
import React, { FC, useMemo } from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"

interface Option {
  label: string
  value: string
}

interface IProps {
  name: string
  viewStyle?: StyleProp<ViewStyle>
  options: Option[]
}

const RadioButton: FC<IProps> = ({ viewStyle, name, options }) => {
  const {
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      radio: [
        styles.radio,
        {
          padding: spacing / 2,
          marginHorizontal: spacing * 3,
          marginVertical: spacing,
        },
      ],
    }),
    [spacing],
  )

  return (
    <View style={[styles.view, viewStyle]}>
      {options.map(option => (
        <FormikRadioButton
          key={option.value}
          {...{ name }}
          textOnly
          label={option.label}
          value={option.value}
          touchableStyle={s.radio}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    flexDirection: "column",
  },
  radio: {
    height: 40,
    alignItems: "center",
    marginBottom: 10,
    justifyContent: "center",
  },
})

export default RadioButton
