import { useRoute } from "@react-navigation/native"
import { EmptyView, firestore, useTheme } from "capsule"
import firebase from "firebase"
import React, { useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StatusBar, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { ID } from "../../common/CommonUserData"
import { collections } from "../../common/types"
import PatientCalendar from "../../components/PatientCalendar"
import { JourneyNS } from "../../features/i18n/constants"
import { Patient } from "../../features/models/UserData"

type RouteParams = {
  id: ID
}

const PatientCalendarScreen = () => {
  const { t } = useTranslation(JourneyNS)
  const route = useRoute()
  const params = route.params as RouteParams
  const [patient, loading] = useDocumentData<Patient>(
    params.id
      ? ((firestore()
          .collection(collections.LOGIN)
          .doc(params.id) as unknown) as firebase.firestore.DocumentReference<Patient>)
      : undefined,
    { idField: "id" },
  )
  const {
    fontMaker,
    dimensions: { spacing },
    typography: { h6 },
    colors: {
      surface: { background },
      primary,
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      indicator: { paddingTop: spacing },
      empty: [h6, { paddingHorizontal: spacing }],
      emptyContainer: { backgroundColor: background },
      titleStyle: [fontMaker({ weight: "Bold" })],
    }),
    [spacing, fontMaker, background, h6],
  )

  return (
    <View>
      <StatusBar barStyle="dark-content" backgroundColor={primary} />
      {loading ? (
        <ActivityIndicator style={s.indicator} size="large" color={primary} />
      ) : patient ? (
        <PatientCalendar patient={patient} />
      ) : (
        <EmptyView
          titleStyle={s.empty}
          title={t("errors.load_patient")}
          viewStyle={s.emptyContainer}
        />
      )}
    </View>
  )
}

export default PatientCalendarScreen
