import { BottomTabNavigationOptions, createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { CommonActions, ParamListBase, PathConfigMap } from "@react-navigation/core"
import { useNavigation } from "@react-navigation/native"
import { NavigationState } from "@react-navigation/routers"
import {
  firestore,
  generateShadow,
  IUserContext,
  logger,
  userContext,
  useTabLand,
  useTheme,
  VectorIcon,
} from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Image, Linking, Platform, StatusBar, StyleSheet, TouchableOpacity } from "react-native"
import { getTimeZone } from "react-native-localize"

import { images } from "../../assets/images"
import { App } from "../../common/App"
import { collections } from "../../common/types"
import { ICON_SIZE } from "../../features/config/Constants"
import { MainNS } from "../../features/i18n/constants"
import { AppUserData, UserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import FaqProvider from "../../features/Providers/FaqProvider"
import { messageContext } from "../../features/Providers/MessageProvider"
import { tabSizeLand } from "../../ThemeApp"
import {
  accountScreens,
  codesScreens,
  faqScreens,
  homeScreens,
  MainAccountTab,
  MainCodesTab,
  MainFaqTab,
  MainHomeTab,
  MainPatientJourneyTab,
  MainPatientsTab,
  MainProtocolsTab,
  patientJourneyScreens,
  patientsScreens,
  protocolsScreens,
} from "./MainTabs"

export interface IMainParamList extends ParamListBase {
  Home: undefined
  Faq: undefined | { screen: string; initial?: boolean; params?: { id: string } }
  Account: undefined
  Codes: undefined
  Protocols: undefined
  Patients: undefined
  PatientJourney: undefined
}

export const screens: PathConfigMap<any> = {
  Home: { path: "home", screens: homeScreens },
  Faq: { path: "faq", screens: faqScreens },
  Account: { path: "account", screens: accountScreens },
  Codes: { path: "codes", screens: codesScreens },
  Protocols: { path: "protocols", screens: protocolsScreens },
  Patients: { path: "patients", screens: patientsScreens },
  PatientJourney: { path: "patientjourney", screens: patientJourneyScreens },
}

const mainNavigator = createBottomTabNavigator<IMainParamList>()

const MainNavigator = () => {
  const navigation = useNavigation()
  const {
    colors: {
      primary,
      white,
      surface: { background },
    },
    dimensions: { spacing },
    typography: { caption },
  } = useTheme()
  const { t } = useTranslation(MainNS)
  const { userData, userDocRef } = useContext<IUserContext<UserData>>(userContext)
  const { unreadMessages } = useContext(messageContext)
  const numberOfUnreadMessages = _.filter(unreadMessages, val => val).length
  const isPatient = userData?.role === "patient"
  const displayPatientJourney = user => !(user?.specialty === "onco" && !user?.program)
  const [apps] = useCollectionData<App>(
    (firestore()
      .collection(collections.APPS)
      .where("disabled", "==", false) as unknown) as firebase.firestore.Query<AppUserData>,
  )
  const getActiveRoute = (innerState: NavigationState | undefined) => {
    const route = innerState?.routes[innerState?.index || 0]
    if (route?.state) {
      return getActiveRoute(route.state as NavigationState)
    }
    return route
  }
  const activeRoute = getActiveRoute(navigation.getState())
  useEffect(() => {
    const userTimezone = getTimeZone()
    if (userData && (!userData.timezone || userData.timezone !== userTimezone)) {
      updateUserData(userDocRef, {
        timezone: userTimezone,
      })
    }
  }, [userData, activeRoute, userDocRef])
  const statusBarBackground =
    activeRoute?.name === "PatientJourney" || activeRoute?.name === "Patient_Journey_Main"
      ? background
      : primary

  const userApps =
    userData && userData.apps ? userData?.apps.map(app => apps?.find(a => a.id === app)) : null
  const s = useMemo(
    () => ({
      tabBarLabel: {
        marginBottom: spacing / 4,
        fontSize: caption.fontSize,
      },
      image: [
        styles.image,
        {
          marginRight: spacing,
        },
      ],
    }),
    [caption.fontSize, spacing],
  )
  const tabBarIcon = useCallback(
    (name: string) => ({ color }: { color: string }) => (
      <VectorIcon {...{ name }} category="MaterialIcons" size={ICON_SIZE} color={color} />
    ),
    [],
  )

  const patientsBadge = useMemo(
    () => ({
      tabBarBadgeStyle: { backgroundColor: primary },
      tabBarBadge: numberOfUnreadMessages === 0 ? undefined : numberOfUnreadMessages,
    }),
    [numberOfUnreadMessages, primary],
  )

  const isTabLand = useTabLand()

  const AppsComponent = useMemo(
    () =>
      userApps
        ? userApps.map((item, index) =>
            item ? (
              <mainNavigator.Screen
                name={item.name}
                key={index}
                component={MainHomeTab}
                options={{
                  tabBarLabel: item.name,
                  tabBarIcon: () => (
                    <Image
                      style={{ width: 22, height: 22 }}
                      // @ts-ignore
                      source={{ uri: images[item.name.toLowerCase()].data.uri }}
                    />
                  ),
                  tabBarButton: props => (
                    <TouchableOpacity {...props} onPress={() => Linking.openURL(item.link)} />
                  ),
                }}
              />
            ) : null,
          )
        : null,
    [userApps],
  )

  logger("isTabLand", isTabLand)

  const screenOptions: BottomTabNavigationOptions = useMemo(
    () => ({
      headerShown: false,
      tabBarStyle:
        isTabLand && Platform.OS === "web"
          ? [styles.tabBarLand, { backgroundColor: primary }]
          : undefined,
      tabBarItemStyle: isTabLand && Platform.OS === "web" ? styles.tabLand : styles.tab,
      tabBarLabelPosition: "below-icon",
      tabBarLabelStyle: s.tabBarLabel,
      tabBarActiveTintColor: isTabLand && Platform.OS === "web" ? white.highEmphasis : primary,
      tabBarInactiveTintColor:
        isTabLand && Platform.OS === "web" ? white.mediumEmphasis : undefined,
      tabBarIconStyle: styles.icon,
    }),
    [white.highEmphasis, white.mediumEmphasis, primary, isTabLand, s.tabBarLabel],
  )

  return (
    <>
      <StatusBar barStyle="dark-content" backgroundColor={statusBarBackground} />
      <mainNavigator.Navigator
        screenOptions={screenOptions}
        sceneContainerStyle={isTabLand && Platform.OS === "web" ? styles.screenLand : undefined}
      >
        {isPatient ? (
          <>
            <mainNavigator.Screen
              name="Home"
              component={MainHomeTab}
              options={{
                tabBarIcon: tabBarIcon("calendar-today"),
                tabBarLabel: t(`tabs.home`),
              }}
            />
            <mainNavigator.Screen
              name="Faq"
              options={{
                tabBarIcon: tabBarIcon("question-answer"),
                tabBarLabel: t(`tabs.faq`),
              }}
            >
              {() => (
                <FaqProvider>
                  <MainFaqTab />
                </FaqProvider>
              )}
            </mainNavigator.Screen>
            {displayPatientJourney(userData) && (
              <mainNavigator.Screen
                name="PatientJourney"
                component={MainPatientJourneyTab}
                options={{
                  tabBarIcon: tabBarIcon("trending-up"),
                  tabBarLabel: t(`tabs.journey`),
                }}
              />
            )}
          </>
        ) : (
          <>
            <mainNavigator.Screen
              name="Patients"
              component={MainPatientsTab}
              options={{
                ...patientsBadge,
                tabBarIcon: tabBarIcon("message"),
                tabBarLabel: t(`tabs.patients`),
              }}
            />
            <mainNavigator.Screen
              name="Codes"
              component={MainCodesTab}
              options={{
                tabBarIcon: tabBarIcon("vpn-key"),
                tabBarLabel: t(`tabs.codes`),
              }}
            />
            <mainNavigator.Screen
              name="Protocols"
              listeners={{
                tabPress: e => {
                  navigation.dispatch(
                    CommonActions.reset({
                      index: 0,
                      routes: [{ name: "Protocols" }],
                    }),
                  )
                  e.preventDefault()
                },
              }}
              component={MainProtocolsTab}
              options={{
                tabBarIcon: tabBarIcon("menu-book"),
                tabBarLabel: t(`tabs.protocols`),
              }}
            />
          </>
        )}
        <>
          <mainNavigator.Screen
            name="Account"
            component={MainAccountTab}
            options={{
              tabBarIcon: tabBarIcon("account-circle"),
              tabBarLabel: t(`tabs.account`),
            }}
          />
          {userData?.role === "surgeon" && Platform.OS === "web" && userApps && userApps !== null
            ? AppsComponent
            : null}
        </>
      </mainNavigator.Navigator>
    </>
  )
}

const styles = StyleSheet.create({
  tabBarLand: {
    position: "absolute",
    left: 0,
    height: "100%",
    bottom: 0,
    width: tabSizeLand,
    borderTopWidth: 0,
    ...generateShadow(2),
  },
  tabLand: {
    maxHeight: 56,
    paddingHorizontal: 0,
  },
  tab: {
    maxWidth: 600,
  },
  screenLand: {
    marginLeft: tabSizeLand,
  },
  icon: {
    maxHeight: 24,
  },
  image: {
    width: 20,
    height: 20,
  },
})

export default MainNavigator
