import _ from "lodash"
import { useWindowDimensions } from "react-native"

import { ICustomCardOptions } from "../features/Models"
import useTheme from "../features/Theme/ThemeProvider"

const useGridCalculation = (options: ICustomCardOptions) => {
  const {
    dimensions: { gutter: themeGutter, margin: themeMargin, columns: themeNbColumns },
  } = useTheme()

  const hookWindowWidth = useWindowDimensions().width // must always be called to keep the same order of hooks
  const windowWidth = options.windowWidth || hookWindowWidth

  let result

  if (options.type === "deprecatedGridScreen") {
    result = {
      gutter: options.gutter ?? themeGutter,
      margin: themeMargin,
      columns: options.columns || themeNbColumns,
    }
  } else {
    // default options for gutter, margin and columns
    const opts = {
      ...{ gutter: themeGutter, margin: themeMargin, columns: themeNbColumns },
      ...options,
    }
    let { gutter, margin, columns } = opts

    let cardWidth: number =
      options.type === "fixedGutterNoColumns" ||
      options.type === "fixedMarginNoColumns" ||
      options.type === "fixedColumnsAndGutter" ||
      options.type === "fixedColumnsAndMargin"
        ? options.cardWidth
        : 0 // not sure about initializing to 0 but I wanted to be able to see immediately if there was an error
    let cardHeight: number | "auto" = "auto"

    const neverReached = (param: never) => {
      // eslint-disable-next-line
      console.warn(param)
    }

    switch (options.type) {
      case "fixedGutterNoColumns":
        {
          const { minMargin: customMinMargin, cardWidth: customCardWidth } = options
          columns = _.floor((windowWidth - 2 * customMinMargin) / (customCardWidth + gutter))
          margin = (windowWidth - columns * (cardWidth + gutter)) / 2
        }
        break

      case "fixedMarginNoColumns":
        {
          const {
            /* margin: customMargin,  */ cardWidth: customCardWidth,
            minGutter: customMinGutter,
          } = options
          // margin = customMargin || themeMargin
          columns = _.floor((windowWidth - 2 * margin) / (customCardWidth + customMinGutter))
          gutter = (windowWidth - 2 * margin) / columns - customCardWidth
        }
        break

      case "fixedColumnsAndGutter":
        {
          const { cardWidth: customCardWidth, minMargin: customMinMargin } = options
          margin =
            _.max([(windowWidth - columns * (customCardWidth + gutter)) / 2, customMinMargin]) ??
            customMinMargin // to respect minMargin, not sure it is the right approach
        }
        break

      case "fixedColumnsAndMargin":
        {
          const { cardWidth: customCardWidth, minGutter: customMinGutter } = options
          gutter =
            _.max([(windowWidth - 2 * margin) / columns - customCardWidth, customMinGutter]) ??
            customMinGutter // to respect minGutter, not sure it is the right approach
        }
        break

      case "flexibleCardWithFixedHeight":
      case "flexibleCardWithRatio":
        {
          const { minCardWidth: customMinCardWidth } = options
          columns = _.floor((windowWidth - 2 * margin) / (customMinCardWidth + gutter))
          cardWidth = (windowWidth - 2 * margin) / columns - gutter
          switch (options.type) {
            case "flexibleCardWithFixedHeight":
              {
                const { cardHeight: customCardHeight } = options
                cardHeight = customCardHeight
              }
              break
            case "flexibleCardWithRatio":
              {
                const { cardRatio: customCardRatio } = options
                cardHeight = cardWidth / customCardRatio
              }
              break
          }
        }
        break

      default:
        neverReached(options)
    }

    result = { margin, gutter, columns, cardWidth, cardHeight }
  }
  return result
}

export default useGridCalculation
