import { firestore, Subtitle1, useTheme } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { collections } from "../../common/types"
import AccordionSection from "../../components/AccordionSection"
import ColorCircle from "../../components/ColorCircle"
import EvaluationItem from "../../components/EvaluationItem"
import { RadioItem } from "../../components/Rating"
import { ICON_SIZE_MEDIUM, ICON_SIZE_SMALL } from "../../features/config/Constants"
import { PatientNS } from "../../features/i18n/constants"
import { EvalFormValues } from "../../features/models/Evaluation"
import { getFirestoreTimestamp } from "../../features/models/Types"
import { useItems } from "../EvaluationScreen/Items"
import { IChildrenProps } from "./PatientInformationsView"

const PatientEvaluationView: FC<IChildrenProps> = ({ patient }) => {
  const {
    dimensions: { spacing },
    colors: {
      primary,
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { t } = useTranslation(PatientNS)
  const { efforts, pains } = useItems(undefined, ICON_SIZE_MEDIUM)
  const [morning] = useCollectionData<EvalFormValues>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient.id)
      .collection(collections.EVAL_MORNING)
      .orderBy("start", "desc")
      .limit(1) as unknown) as firebase.firestore.Query<EvalFormValues>,
  )
  const [evening] = useCollectionData<EvalFormValues>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient.id)
      .collection(collections.EVAL_EVENING)
      .orderBy("start", "desc")
      .limit(1) as unknown) as firebase.firestore.Query<EvalFormValues>,
  )
  // @ts-ignore
  const painEval = _([morning?.[0], evening?.[0]])
    .filter(o => o?.pain)
    .orderBy(["start"], "desc")
    .head()

  const effort: RadioItem | undefined = evening
    ? _.find(efforts, e => e.value === Number(evening[0]?.effort))
    : undefined
  const pain: RadioItem | undefined = painEval
    ? _.find(pains, e => e.value === Number(painEval?.pain))
    : undefined
  const { style, colorCircle, noEvaluationContainer } = useMemo(
    () => ({
      style: [
        styles.evaluation,
        {
          backgroundColor: white,
          paddingVertical: spacing,
        },
      ],
      colorCircle: [
        styles.colorCircle,
        {
          height: ICON_SIZE_MEDIUM,
        },
      ],
      noEvaluationContainer: [
        {
          padding: spacing,
          backgroundColor: white,
        },
      ],
    }),
    [white, spacing],
  )
  const stiffnessColor = morning?.[0]?.stiffness === "yes" ? "#FFAFA4" : "#B0FFA4"
  const stiffnessIcon = morning?.[0]?.stiffness === "yes" ? "check" : "close"

  const hasEvaluation = pain || effort || morning?.[0]?.stiffness

  return (
    <AccordionSection
      expandList={true}
      leftIcon="clipboard-text"
      title={t("evaluation")}
      description={
        patient?.lastSession
          ? `${dayjs(getFirestoreTimestamp(patient.lastSession).toDate()).format(
              "DD/MM/YYYY [à] HH:mm",
            )}`
          : undefined
      }
      category="MaterialCommunityIcons"
    >
      {hasEvaluation ? (
        <View {...{ style }}>
          {pain ? (
            <EvaluationItem label={t("pain")} value={pain.label}>
              <View style={styles.smileyCircle}>{pain.checked}</View>
            </EvaluationItem>
          ) : null}
          {effort ? (
            <EvaluationItem label={t("difficulty")} value={effort.label}>
              <View style={colorCircle}>{effort.checked}</View>
            </EvaluationItem>
          ) : null}
          {morning?.[0]?.stiffness ? (
            <EvaluationItem
              label={t("stiffness")}
              value={_.capitalize(t(`common:button.${morning?.[0]?.stiffness}`))}
            >
              <View style={colorCircle}>
                <ColorCircle
                  color={primary}
                  backgroundColor={stiffnessColor}
                  size={ICON_SIZE_MEDIUM}
                  icon={{
                    name: stiffnessIcon,
                    color: primary,
                    size: ICON_SIZE_SMALL,
                    category: "MaterialCommunityIcons",
                  }}
                />
              </View>
            </EvaluationItem>
          ) : null}
        </View>
      ) : (
        <View style={noEvaluationContainer}>
          <Subtitle1>{t("noEvaluationYet")}</Subtitle1>
        </View>
      )}
    </AccordionSection>
  )
}

const styles = StyleSheet.create({
  evaluation: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  colorCircle: {
    top: 2.5,
  },
  smileyCircle: {
    top: 1.2,
  },
})

export default PatientEvaluationView
