import { colors, VectorIconProps } from "capsule"

import { ICON_SIZE } from "../../features/config/Constants"

export const rightChild = {
  size: ICON_SIZE,
  name: "chevron-right",
  color: colors.black.mediumEmphasis,
  category: "MaterialIcons",
}

export const leftChild: Partial<VectorIconProps> = {
  size: ICON_SIZE,
  category: "MaterialIcons",
}
