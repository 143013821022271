import { RowItem, useTheme, VectorIcon } from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { LayoutChangeEvent, StyleSheet, View } from "react-native"
import { Menu } from "react-native-paper"

import { QCU, QCUParameters } from "../../common/Instruction"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { PatientNS } from "../../features/i18n/constants"
import { updatePatientData } from "../../features/models/UserDataFunctions"
import { IChildrenProps } from "./PatientInformationsView"

interface IProps extends IChildrenProps {
  qcu: QCU
}

const PatientQcuView: FC<IProps> = ({ patient, isSurgeon, qcu }) => {
  const {
    dimensions: { spacing },
    colors: {
      surface: { textInput },
      black: { mediumEmphasis },
    },
  } = useTheme()
  // Contexts
  const { t } = useTranslation(PatientNS)
  // Constants & states
  const [menuVisible, setMenuVisible] = useState(false)
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)
  const firstRender = useRef<boolean>(false)
  const patientAnswer = _.find(qcu.options, option => option.id === patient.qcuAnswers?.[qcu.id])
  const name = patientAnswer
    ? itemField(patientAnswer, "title", t)
    : itemField<QCU>(qcu, "title", t)

  const s = useMemo(
    () => ({
      menu: {
        width: menuWidth,
        maxWidth: menuWidth,
      },
      row: { paddingHorizontal: spacing, paddingVertical: spacing / 2 },
    }),
    [menuWidth, spacing],
  )

  const rowCRProps = useMemo(
    () => ({
      color: mediumEmphasis,
      rowStyle: [styles.row, { height: INPUT_HEIGHT, backgroundColor: textInput }],
      rightChild: <VectorIcon size={ICON_SIZE} name="arrow-drop-down" category="MaterialIcons" />,
    }),
    [mediumEmphasis, textInput],
  )

  const openMenu = useCallback(() => setMenuVisible(true), [setMenuVisible])
  const closeMenu = useCallback(() => setMenuVisible(false), [setMenuVisible])

  const updateQcu = useCallback(
    (value: string) => () => {
      closeMenu()
      updatePatientData(patient.id, { qcuAnswers: { [qcu.id]: value } })
    },
    [closeMenu, patient.id, qcu.id],
  )

  const onLayout = (event: LayoutChangeEvent) => {
    if (firstRender.current) {
      return
    }
    firstRender.current = true
    const { width } = event.nativeEvent.layout
    setMenuWidth(width - spacing * 2)
  }

  return (
    <View style={s.row} onLayout={onLayout}>
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        anchor={
          <RowItem {...rowCRProps} {...{ name }} onPress={isSurgeon ? openMenu : undefined} />
        }
      >
        {_.map(qcu.options, (p: QCUParameters, key: string) => (
          <Menu.Item
            {...{ key }}
            style={s.menu}
            onPress={updateQcu(p.id)}
            title={itemField<QCUParameters>(p, "title", t)}
          />
        ))}
      </Menu>
    </View>
  )
}

const styles = StyleSheet.create({
  menu: {
    paddingVertical: 0,
  },
  row: { overflow: "hidden", borderRadius: 8 },
})

export default PatientQcuView
