import { IUserContext, logger, userContext } from "capsule"
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react"

import { RoleOrPracticioner } from "../../common/CommonUserData"
import { AppUserData } from "../models/UserData"

interface IRoleContext {
  isSaved?: boolean
  researchAccepted: boolean
  role: RoleOrPracticioner | undefined
  setIsSaved: Dispatch<SetStateAction<boolean>>
  setRole?: Dispatch<SetStateAction<RoleOrPracticioner>>
  setResearchAccepted: Dispatch<SetStateAction<boolean>>
}

interface IProps {
  children: ReactNode
}

const defaultValues: Omit<IRoleContext, "setIsSaved" | "setRole" | "setResearchAccepted"> = {
  isSaved: false,
  role: undefined,
  researchAccepted: true,
}
// @ts-ignore
export const roleContext = createContext<IRoleContext>(defaultValues)

const RoleProvider: FC<IProps> = ({ children }) => {
  const [role, setRole] = useState<RoleOrPracticioner | undefined>(defaultValues.role)
  const [researchAccepted, setResearchAccepted] = useState<boolean>(defaultValues.researchAccepted)
  const [isSaved, setIsSaved] = useState<boolean | undefined>(defaultValues.isSaved)
  const contextValue: IRoleContext = {
    role,
    isSaved,
    setRole,
    setIsSaved,
    researchAccepted,
    setResearchAccepted,
  }

  return <roleContext.Provider value={contextValue}>{children}</roleContext.Provider>
}

export const useRole = () => {
  const context = useContext(roleContext)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  if (userData) {
    return {
      isSaved: false,
      setRole: undefined,
      role: userData.role ?? context?.role,
      researchAccepted: userData?.researchAccepted ?? context?.researchAccepted,
    }
  }

  if (context !== undefined) {
    return context
  }

  logger("useRole must be used within a RoleProvider, or the user must be logged in")
  return {
    role: undefined,
    setRole: undefined,
  }
}

export default RoleProvider
