import { RouteProp } from "@react-navigation/core"
import {
  collections,
  EmptyView,
  firestore,
  IUserContext,
  userContext,
  useTheme,
  useTwoPaneRoute,
} from "capsule"
import firebase from "firebase"
import React, { FC, useContext, useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { FlatList, StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { PatientNS } from "../../features/i18n/constants"
import { AppUserData, Patient } from "../../features/models/UserData"
import ProgramProvider from "../../features/Providers/ProgramProvider"
import { IPatientsParamList } from "../MainScreen/MainTabs"
import AdditionalFieldsView from "./AdditionalFieldsView"
import PatientHeader from "./PatientHeader"
import PatientInformationsView from "./PatientInformationsView"
import PatientMessageView from "./PatientMessageView"
import PatientProgressionView from "./PatientProgressionView"
import PatientPromsView from "./PatientPromsView"

type PatientScreenRouteProp = RouteProp<IPatientsParamList, "Patient_Profile">

export interface PatientProps {
  patient: Patient
  isSurgeon?: boolean
}

const PatientScreen: FC = () => {
  const {
    typography: { h6 },
    dimensions: { spacing },
    colors: {
      primary,
      surface: { background },
    },
  } = useTheme()
  const { t } = useTranslation(PatientNS)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)

  // Constants & states
  const isSurgeon = userData?.role === "surgeon"

  const { params } = useTwoPaneRoute<PatientScreenRouteProp>()
  const [patient, loading] = useDocumentData<Patient>(
    params?.id
      ? ((firestore()
          .collection(collections.LOGIN)
          .doc(params?.id) as unknown) as firebase.firestore.DocumentReference<Patient>)
      : undefined,
    { idField: "id" },
  )

  const s = useMemo(
    () => ({
      viewContainer: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      view: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      innerView: {
        paddingBottom: spacing * 2,
      },
      indicator: [styles.view, { paddingTop: spacing, backgroundColor: background }],
      empty: [h6, { paddingHorizontal: spacing }],
      emptyContainer: { backgroundColor: background },
    }),
    [background, h6, spacing],
  )

  const FlatListComponents = patient
    ? [
        <ProgramProvider key="PatientProgression" patient={patient}>
          <PatientProgressionView {...{ patient }} />
        </ProgramProvider>,
        <PatientPromsView key="PatientProms" {...{ patient }} />,
        <PatientMessageView key="PatientMessage" {...{ patient }} />,
      ].filter(Boolean)
    : []

  return (
    <View style={s.viewContainer}>
      {loading ? (
        <ActivityIndicator style={s.indicator} size="large" color={primary} />
      ) : patient ? (
        <FlatList
          data={FlatListComponents}
          renderItem={({ item }) => <React.Fragment>{item}</React.Fragment>}
          ListHeaderComponent={
            <>
              <PatientHeader {...{ patient }} />
              <PatientInformationsView {...{ patient, isSurgeon }} />
              {isSurgeon ? <AdditionalFieldsView {...{ patient }} /> : null}
            </>
          }
        />
      ) : (
        <EmptyView
          titleStyle={s.empty}
          title={t("errors.load_patient")}
          viewStyle={s.emptyContainer}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
})

export default PatientScreen
