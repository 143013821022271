import React, { FC } from "react"

import useToggle from "./useToggle"

interface IRenderFunctionProps {
  value: boolean
  toggle: () => void
  setTrue: () => void
  setFalse: () => void
}

export interface ToggleProps {
  initialValue?: boolean
  onChange?: (value: boolean) => void
  children: (props: IRenderFunctionProps) => React.ReactElement<any>
}

const Toggle: FC<ToggleProps> = ({ children, onChange, initialValue }) => {
  const toggle = useToggle(initialValue, onChange)

  return children(toggle)
}

export default Toggle
