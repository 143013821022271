export default {
  setInvokeShakeOnScreenshot: () => {},
  setConsoleLogsEnabled: () => {},
  setShowIntroMessage: () => {},
  setAutoVideoRecording: () => {},
  setEnableBlackBox: () => {},
  start: () => {},
  log: () => {},
  setMetadata: () => {},
}

export const LogLevel = {
  INFO: "INFO"
}
