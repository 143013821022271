import { firestore, IUserContext, useAppState, userContext } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore"

import { ID } from "../../common/CommonUserData"
import { Faq } from "../../common/Faq"
import { Equipment, Exercise, Phase } from "../../common/Phase"
import { Todo } from "../../common/Todo"
import { collections } from "../../common/types"
import { setMetadata } from "../../services/Analytics/shake"
import { ISO8601_TIME, MINUTE_MS } from "../../utils/conversion"
import { SessionDetails } from "../models/Session"
import { AppUserData } from "../models/UserData"

export interface ProgramContext {
  isSessionReady: boolean
  setIsSessionReady: Dispatch<SetStateAction<boolean>>
  sessions: SessionDetails[] | undefined
  currentSession?:
    | SessionDetails
    | Record<string, never> /** current session of the day for the current phase */
  resumedSession: SessionDetails | undefined /** previous session the user can continue or avoid */
  lastSession: { daySinceSurgery?: number; seqIndDay?: number } | undefined
  currentPhase?: Phase
  phases?: Phase[]
  allFaqs: Faq[] | undefined
  phaseTodos: Todo[] | undefined
  phaseExercises: Exercise[]
  phaseEquipments?: Record<ID, Equipment>
  prefTabVideo: boolean
  cleanPhase: () => void
  setSessions: Dispatch<SetStateAction<SessionDetails[] | undefined>>
  setCurrentSession: Dispatch<SetStateAction<SessionDetails | Record<string, never>> | undefined>
  setResumedSession: Dispatch<SetStateAction<SessionDetails | undefined>>
  setLastSession: Dispatch<
    SetStateAction<{ daySinceSurgery?: number; seqIndDay?: number } | undefined>
  >
  setPhaseExercises: Dispatch<SetStateAction<Exercise[]>>
  setPrefTabVideo: Dispatch<SetStateAction<boolean>>
}

interface IProps {
  children: ReactNode
  patient?: AppUserData
}

// @ts-ignore
export const programContext = createContext<ProgramContext>({} as ProgramContext)

export const defaultValues: Omit<
  ProgramContext,
  | "setIsSessionReady"
  | "setSessions"
  | "setCurrentSession"
  | "setResumedSession"
  | "setLastSession"
  | "setPhaseExercises"
  | "setCurrentPhase"
  | "setPhases"
  | "setPrefTabVideo"
  | "cleanPhase"
> = {
  isSessionReady: false,
  sessions: undefined,
  currentSession: undefined,
  resumedSession: undefined,
  lastSession: undefined,
  currentPhase: undefined,
  phases: undefined,
  allFaqs: [],
  phaseTodos: [],
  phaseExercises: [],
  prefTabVideo: false,
}

/**
 * Program Provider gives :
 * - current Phase based on user's pathology and user's surgery date (startDay & endDay) => need review
 * - current Phase informations, its todos and faqs
 * - list of all sessions informations of the current phase
 * - the current session based on time slot with current time
 *
 * It doesn't concern the creation of a session when clicked on start button.
 * It will be done in another context dedicate to this (Session context)
 * */
const ProgramProvider = ({ children, patient }: IProps) => {
  // Context states
  const { userData, user } = useContext<IUserContext<AppUserData>>(userContext)
  const userProps = patient ?? userData
  const [isSessionReady, setIsSessionReady] = useState(defaultValues.isSessionReady)
  const [sessions, setSessions] = useState<SessionDetails[] | undefined>(defaultValues.sessions)
  const [currentSession, setCurrentSession] = useState<
    SessionDetails | Record<string, never> | undefined
  >(defaultValues.currentSession)
  const [resumedSession, setResumedSession] = useState(defaultValues.resumedSession)
  const [lastSession, setLastSession] = useState<
    { daySinceSurgery?: number; seqIndDay?: number } | undefined
  >(undefined)
  const [phaseExercises, setPhaseExercises] = useState<Exercise[]>(defaultValues.phaseExercises)
  const [currentPhase, setCurrentPhase] = useState<Phase | undefined>(defaultValues.currentPhase)
  const [phases, setPhases] = useState<Phase[] | undefined>(defaultValues.phases)
  const [prefTabVideo, setPrefTabVideo] = useState(false)
  const [restart, setRestart] = useState(0)
  const [phasesWithAddedDays, setPhasesWithAddedDays] = useState<Phase[]>([])
  const { appState } = useAppState({
    onChange: async nextAppState => {
      if (appState.match(/inactive|background/) && nextAppState === "active") {
        if (!isSessionReady) {
          setIsSessionReady(dayjs().isSameOrAfter(dayjs(currentSession?.slot, ISO8601_TIME)))
        }
      }
    },
  })

  const groupArray = (array: ID[], size: number) => {
    const result: any = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }

  const [originalPhases] = useCollectionData<Phase>(
    userProps?.pathology
      ? ((firestore()
          .collection(collections.PHASES)
          .where("pathology", "==", userProps?.pathology)
          .orderBy("startDay", "asc") as unknown) as firebase.firestore.Query<Phase>)
      : null,
  )

  const [originalCurrentPhase] = useDocumentData<Phase>(
    userProps?.phase
      ? ((firestore()
          .collection(collections.PHASES)
          .doc(userProps?.phase as ID) as unknown) as firebase.firestore.DocumentReference<Phase>)
      : null,
  )

  const updateBlockDates = (allPhases, additionalDays) => {
    const copyAllPhases = allPhases.map(phase => ({
      ...phase,
      blockDates: { ...phase.blockDates },
    }))

    const blockPhases = copyAllPhases
      .filter(phase => phase.showProtocoleTab)
      .sort((a, b) => a.blockDates.startDay - b.blockDates.startDay)

    // Appliquer les modifications de addedDays aux blockDates des phases représentatives
    blockPhases.forEach((phase, i) => {
      if (additionalDays && additionalDays.hasOwnProperty(phase.id)) {
        const addedDays = additionalDays[phase.id]

        phase.blockDates.endDay += addedDays

        // Appliquer les changements aux phases représentatives suivantes
        for (let j = i + 1; j < blockPhases.length; j++) {
          blockPhases[j].blockDates.startDay += addedDays
          blockPhases[j].blockDates.endDay += addedDays
        }
      }
    })

    // Application des addedDays pour les phases non représentatives
    copyAllPhases.forEach(phase => {
      if (additionalDays && additionalDays.hasOwnProperty(phase.id) && !phase.showProtocoleTab) {
        const addedDays = additionalDays[phase.id]

        // Trouver la phase représentative correspondante
        const repPhase = blockPhases.find(
          ph => ph.blockDates.startDay <= phase.startDay && ph.blockDates.endDay >= phase.startDay,
        )

        if (repPhase) {
          repPhase.blockDates.endDay += addedDays

          // Appliquer les changements aux phases représentatives suivantes
          for (let j = blockPhases.indexOf(repPhase) + 1; j < blockPhases.length; j++) {
            blockPhases[j].blockDates.startDay += addedDays
            blockPhases[j].blockDates.endDay += addedDays
          }
        }
      }
    })
    return copyAllPhases
  }

  // Récupérer les phases qui contiennent des addedDays
  useEffect(() => {
    const getPhasesWithAddedDays = async () => {
      if (!userProps?.addedDays) {
        setPhasesWithAddedDays([])
        return
      }

      const addedDays = userProps.addedDays
      const phaseIds: ID[] = Object.keys(addedDays)
      const phaseIdsByGroupOf10 = groupArray(phaseIds, 10)
      const promises = phaseIdsByGroupOf10.map(group =>
        firestore()
          .collection(collections.PHASES)
          .where(firestore.FieldPath.documentId(), "in", group)
          .get(),
      )

      try {
        const results = await Promise.all(promises)
        const phasesAddedDays = results.flatMap(result =>
          result.docs.map(doc => ({ id: doc.id, ...doc.data() })),
        )
        setPhasesWithAddedDays(phasesAddedDays)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Erreur lors de la récupération des phases avec des jours ajoutés :", err)
      }
    }

    getPhasesWithAddedDays()
  }, [userProps?.addedDays])

  useEffect(() => {
    if (!originalPhases) {
      setPhases([])
      return
    }
    if (!userProps?.addedDays) {
      setPhases(originalPhases)
      return
    }

    let accumulatedAddedDays = 0
    const updatedPhases = originalPhases.map(phase => {
      const addedDaysForPhase = userProps?.addedDays?.[phase.id] ?? 0
      accumulatedAddedDays += addedDaysForPhase
      const newStartDay = phase.startDay + accumulatedAddedDays - addedDaysForPhase
      const newEndDay = phase.endDay + accumulatedAddedDays
      const isDisabled = addedDaysForPhase && newEndDay < newStartDay

      return {
        ...phase,
        startDay: newStartDay,
        endDay: newEndDay,
        ...(isDisabled && { isDisabled: true }),
      }
    })
    const updatedPhasesWithBlockDates = updateBlockDates(updatedPhases, userProps.addedDays)

    setPhases(updatedPhasesWithBlockDates)
  }, [originalPhases, userProps])

  useEffect(() => {
    if (!originalCurrentPhase) {
      setCurrentPhase(undefined)
      return
    }
    if (!userProps?.addedDays) {
      setCurrentPhase(originalCurrentPhase)
      return
    }

    const allPhaseIds = phasesWithAddedDays
      ?.filter(phase => phase.endDay >= phase.startDay)
      .map(phase => phase.id)
    if (originalCurrentPhase && !allPhaseIds?.includes(originalCurrentPhase.id)) {
      phasesWithAddedDays?.push(originalCurrentPhase)
    }

    const sortedPhases = phasesWithAddedDays?.sort((a, b) => a.startDay - b.startDay)
    let accumulatedAddedDays = 0
    let adjustedPhase: Phase | null = null

    sortedPhases?.forEach(phase => {
      const addedDaysForPhase = userProps?.addedDays?.[phase.id] ?? 0
      accumulatedAddedDays += addedDaysForPhase

      if (phase.id === originalCurrentPhase.id) {
        const adjustedStartDay = phase.startDay + accumulatedAddedDays - addedDaysForPhase
        const adjustedEndDay = phase.endDay + accumulatedAddedDays

        adjustedPhase = {
          ...phase,
          startDay: adjustedStartDay,
          endDay: adjustedEndDay,
        }
      }
    })

    setCurrentPhase(adjustedPhase ? adjustedPhase : originalCurrentPhase)
  }, [originalCurrentPhase, phasesWithAddedDays, userProps])

  const [allFaqs, setAllFaqs] = useState<Faq[]>([])

  const getAllFaqs = useCallback(async () => {
    try {
      const technicalFaqSnapshot = await firestore()
        .collection(collections.FAQ)
        .where("appInfoFaq", "==", true)
        .where("disabled", "==", false)
        .get()

      const videoFaq = technicalFaqSnapshot.docs.map(doc => doc.data() as Faq)

      let phaseFaqs: Faq[] = []

      if (!_.isEmpty(currentPhase?.faq)) {
        const chunks = _.chunk(currentPhase?.faq, 10)

        for (const chunk of chunks) {
          const phaseFaqsSnapshot = await firestore()
            .collection(collections.FAQ)
            .where("disabled", "==", false)
            .where("id", "in", chunk)
            .get()

          const chunkFaqs = phaseFaqsSnapshot.docs.map(doc => doc.data() as Faq)
          phaseFaqs = [...phaseFaqs, ...chunkFaqs]
        }
      }

      setAllFaqs([...videoFaq, ...phaseFaqs])
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Erreur lors de la récupération des FAQs :", e)
    }
  }, [currentPhase])

  useEffect(() => {
    if (user) {
      getAllFaqs()
    }
  }, [currentPhase, user, getAllFaqs])

  const [phaseTodos, setPhaseTodos] = useState<Todo[]>([])

  const getPhaseTodos = useCallback(async () => {
    if (_.isEmpty(currentPhase?.todo)) {
      setPhaseTodos([])
      return
    }

    try {
      let allTodos: Todo[] = []
      const chunks = _.chunk(currentPhase?.todo, 10)

      for (const chunk of chunks) {
        const todosSnapshot = await firestore()
          .collection(collections.TODO)
          .where("id", "in", chunk)
          .where("disabled", "==", false)
          .get()

        const chunkTodos = todosSnapshot.docs.map(doc => doc.data() as Todo)
        allTodos = [...allTodos, ...chunkTodos]
      }

      setPhaseTodos(allTodos)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Erreur lors de la récupération des TODOs :", e)
    }
  }, [currentPhase])

  useEffect(() => {
    if (user) {
      getPhaseTodos()
    }
  }, [currentPhase, user, getPhaseTodos])

  const [equipments] = useCollectionData<Equipment>(
    user
      ? ((firestore().collection(
          collections.EQUIPMENTS,
        ) as unknown) as firebase.firestore.Query<Equipment>)
      : null,
  )

  const cleanPhase = useCallback(() => {
    setSessions(defaultValues.sessions)
    setResumedSession(defaultValues.resumedSession)
    setLastSession(defaultValues.lastSession)
    setPhaseExercises(defaultValues.phaseExercises)
    setCurrentSession(defaultValues.currentSession)
  }, [])

  useEffect(() => {
    setMetadata("organ", (userData?.organ as ID) ?? "unset")
    setMetadata("pathology", (userData?.pathology as ID) ?? "unset")
    setMetadata("phase", (userData?.phase as ID) ?? "unset")
    setMetadata("userId", user?.uid ?? "unset")
  }, [user?.uid, userData?.organ, userData?.pathology, userData?.phase])

  /** Check if next session is available if a phase exists */
  useEffect(() => {
    if (!userData?.phase || !currentSession || isSessionReady || appState !== "active") {
      return
    }
    const diff = dayjs().diff(dayjs(currentSession?.slot, ISO8601_TIME), "millisecond")
    const delay = Math.abs(diff) > MINUTE_MS ? MINUTE_MS : Math.abs(diff)
    const timeout = setTimeout(() => {
      const result = dayjs().isSameOrAfter(dayjs(currentSession?.slot, ISO8601_TIME))
      setIsSessionReady(result)
      setRestart(previous => (!result ? previous + 1 : 0))
    }, delay)

    return () => clearTimeout(timeout)
  }, [appState, currentSession, isSessionReady, restart, setIsSessionReady, userData?.phase])

  const contextValue: ProgramContext = {
    isSessionReady,
    sessions,
    currentSession,
    resumedSession,
    lastSession,
    currentPhase,
    phases,
    allFaqs,
    phaseTodos,
    phaseExercises,
    phaseEquipments: _.keyBy(equipments, eq => eq.id),
    prefTabVideo,
    cleanPhase,
    setIsSessionReady,
    setSessions,
    setCurrentSession,
    setResumedSession,
    setLastSession,
    setPhaseExercises,
    setPrefTabVideo,
  }

  return <programContext.Provider value={contextValue}>{children}</programContext.Provider>
}

export const useProgram = (): ProgramContext => {
  const context = useContext<ProgramContext>(programContext)
  if (_.isEmpty(context)) {
    throw new Error("useProgram must be used within a ProgramProvider")
  }
  return context
}

export default ProgramProvider
