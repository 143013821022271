import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Keyboard, Platform, StyleProp, StyleSheet, ViewStyle } from "react-native"
import DocumentPicker, {
  DocumentPickerOptions,
  Platform as DocPicPlatform,
} from "react-native-document-picker"
import { Image, Options } from "react-native-image-crop-picker"
import { PERMISSIONS } from "react-native-permissions"

import { ButtonType, useAlert } from "../../features/Providers"
import useTheme from "../../features/Theme/ThemeProvider"
import { askNativePermissions } from "../../utils/askNativePermission"
import generateShadow from "../../utils/generateShadow"
import { DocumentPickerResponse, useGalleryPicker } from "../GalleryPicker"
import { Touchable } from "../Touchable"
import { VectorIconProps } from "../VectorIcon"
import PermissionDialogContent from "./PermissionDialogContent"

export type File = Blob & {
  name: string
  type: string
}

export type Fileish = File | Image | DocumentPickerResponse

export interface IProps {
  disabled?: boolean
  children: ReactNode
  imageOptions?: Options
  downloadFile?: boolean
  downloadGallery?: boolean
  style?: StyleProp<ViewStyle>
  setSource:
    | Dispatch<SetStateAction<Fileish | undefined>>
    | ((file: Fileish | Image[] | DocumentPickerResponse[] | undefined) => void)
  icons?: { camera?: VectorIconProps; gallery?: VectorIconProps; documents?: VectorIconProps }
  documentTypes?: DocumentPickerOptions<DocPicPlatform>["type"]
}

const PermissionCameraWrapper: FC<IProps> = ({
  disabled,
  children,
  style,
  setSource,
  imageOptions,
  icons,
  downloadFile = false,
  downloadGallery = true,
  documentTypes = [DocumentPicker.types.allFiles],
}) => {
  const {
    typography: { h6 },
    dimensions: { spacing },
    colors: { white, primary },
  } = useTheme()
  const { t } = useTranslation()
  const { showDialog, onDismiss } = useAlert()
  const { openCamera, openDocumentGallery, openGallery, source, clearCamera } = useGalleryPicker()

  const s = useMemo(
    () => ({
      view: [
        styles.view,
        {
          paddingHorizontal: spacing,
        },
      ],
      button: [{ backgroundColor: white.highEmphasis }, generateShadow(1)],
    }),
    [spacing, white.highEmphasis],
  )

  useEffect(() => {
    if (source) {
      setSource(source)
      clearCamera()
    }
  }, [source, setSource, clearCamera])

  const onPress = async () => {
    Keyboard.dismiss()
    const isAndroid = Platform.OS === "android"
    const platformVersion =
      typeof Platform.Version === "string" ? parseInt(Platform.Version) : Platform.Version
    const permissionsArray = isAndroid
      ? platformVersion >= 33
        ? [PERMISSIONS?.ANDROID.CAMERA, PERMISSIONS?.ANDROID.READ_MEDIA_IMAGES]
        : [PERMISSIONS?.ANDROID.CAMERA, PERMISSIONS?.ANDROID.READ_EXTERNAL_STORAGE]
      : [PERMISSIONS?.IOS.CAMERA, PERMISSIONS?.IOS.PHOTO_LIBRARY]
    const result = await askNativePermissions(
      Platform.select({
        ios: permissionsArray,
        android: permissionsArray,
        default: [], // TODO handle web uploads
      }),
      "",
      t("permission.message"),
      t("button.ok"),
      t("button.noThanks"),
    )

    if (result !== "granted") {
      return
    }
    showDialog({
      type: "button",
      title: t("alert.photo.title"),
      titleStyle: h6,
      style: s.view,
      contentContainerStyle: styles.modal,
      positive: {
        type: ButtonType.NEGATIVE,
        label: t("button.cancel"),
        buttonStyle: s.button,
      },
      children: (
        <PermissionDialogContent
          {...{ icons }}
          openCamera={async () => {
            await openCamera(imageOptions)
            onDismiss()
          }}
          openGallery={
            downloadGallery
              ? async () => {
                  await openGallery(imageOptions)
                  onDismiss()
                }
              : undefined
          }
          openDocuments={
            downloadFile
              ? async () => {
                  await openDocumentGallery(documentTypes)
                  onDismiss()
                }
              : undefined
          }
        />
      ),
    })
  }

  return (
    <Touchable borderless rippleColor={primary} {...{ style, onPress, disabled }}>
      {children}
    </Touchable>
  )
}

const styles = StyleSheet.create({
  view: {
    alignSelf: "center",
  },
  modal: {
    width: "100%",
    maxWidth: 420,
    alignSelf: "center",
  },
})

export default PermissionCameraWrapper
