import _ from "lodash"
import React, { FC, ReactNode, useMemo } from "react"
import { StyleProp, Text as RNText, TextProps, TextStyle } from "react-native"

import { Theme } from "../../features/Theme"
import useTheme from "../../features/Theme/ThemeProvider"

export interface IBodyProps extends TextProps {
  children: ReactNode
  emphasis?: "high" | "medium"
  color?: "primary" | "secondary" | string
}

export interface ITextProps {
  type: StyleProp<TextStyle>
  theme: Theme
}

export const Text: FC<ITextProps & IBodyProps> = ({ color, emphasis, ...props }) => {
  const { colors } = useTheme()
  const colorStyle = useMemo(() => {
    const colorOverride =
      color && _.indexOf(["primary", "secondary"], color) !== -1
        ? colors?.[color as "primary" | "secondary"]
        : color
    return (
      colorOverride ??
      (emphasis
        ? colors.black?.[`${emphasis}Emphasis` as "highEmphasis" | "mediumEmphasis"]
        : undefined)
    )
  }, [color, colors, emphasis])

  return (
    <RNText {...props} style={[props.type, props.style, colorStyle ? { color: colorStyle } : {}]} />
  )
}
