import { RouteProp } from "@react-navigation/core"
import {
  Caption,
  firestore,
  IUserContext,
  Subtitle1,
  Touchable,
  userContext,
  useStyles,
  useTheme,
  useTwoPaneNavigation,
  useTwoPaneRoute,
} from "capsule"
import VectorIcon from "capsule/components/VectorIcon/VectorIcon"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { EvalFormValues } from "src/features/models/Evaluation"

import { ID } from "../common/CommonUserData"
import { Phase } from "../common/Phase"
import { collections } from "../common/types"
import { ICON_SIZE_SMALL } from "../features/config/Constants"
import currentDateMs from "../features/hooks/useDateMock"
import { useFirstCollectionItem } from "../features/hooks/useFirstCollectionItem"
import useItemI18n from "../features/hooks/useItemI18n"
import { PatientsListNS } from "../features/i18n/constants"
import { Message } from "../features/models/Message"
import { getNextPhase } from "../features/models/PhaseAndSessionFunctions"
import { AppUserData } from "../features/models/UserData"
import { updatePatientData } from "../features/models/UserDataFunctions"
import { messageContext } from "../features/Providers/MessageProvider"
import { useItems } from "../screens/EvaluationScreen/Items"
import { IPatientsParamList } from "../screens/MainScreen/MainTabs"
import { PatientProps } from "../screens/PatientScreen/PatientScreen"
import { daySinceSurgery } from "../utils/conversion"

const PatientsListItem: FC<PatientProps> = ({
  patient: { firstName, id, lastMessage, lastName, pathology, surgeryDate, lastEval, phase },
}) => {
  const { fontMaker, colors } = useTheme()
  // Contexts
  const { t } = useTranslation(PatientsListNS)
  const navigation = useTwoPaneNavigation()
  const { unreadMessages } = useContext(messageContext)
  const { userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const route = useTwoPaneRoute<RouteProp<IPatientsParamList, "Patient_Profile">>()

  // Memoize datas
  const pathologyName = useItemI18n(collections.PATHOLOGIES, pathology as string, "name")
  const pathologyShortName = useItemI18n(collections.PATHOLOGIES, pathology as string, "shortName")
  const eveningEvalItem = useFirstCollectionItem<EvalFormValues>(collections.EVAL_EVENING, id)
  const { pains, efforts } = useItems(
    lastEval && Number(lastEval?.pain) >= 4 ? colors.danger : colors.black.highEmphasis,
    16,
  )

  // Local constants
  const nbDays = daySinceSurgery(surgeryDate)
  const isUnread = unreadMessages?.[id]

  const painIcon = lastEval
    ? _.find(pains, pain => pain.value === Number(lastEval?.pain))
    : undefined
  const effortIcon = eveningEvalItem
    ? _.find(efforts, ef => ef.value === Number(eveningEvalItem?.effort))
    : undefined

  const s = useStyles(
    ({ dimensions: { spacing }, colors: { primary, selection, white, black } }) => ({
      name: {
        color: black.highEmphasis,
      },
      caption: {
        color: black.highEmphasis,
      },
      evaluation: {
        marginTop: spacing / 2,
        alignItems: "center",
        flexDirection: "row",
      },
      message: {
        flexDirection: "row",
      },
      row: {
        backgroundColor:
          route.params?.id && route.params?.id === id ? selection : white.highEmphasis,
        flexDirection: "row",
        height: undefined,
        justifyContent: "space-between",
        padding: spacing,
      },
      subtitle: {
        color: black.highEmphasis,
        paddingBottom: spacing / 2,
      },
      surgeon: {
        color: primary,
        paddingRight: spacing / 4,
      },
    }),
    [fontMaker, route.params?.id, id],
  )

  const getCurrentPatientPhase = useCallback(async (phaseId: ID) => {
    if (phaseId) {
      const phaseDoc = await firestore().collection(collections.PHASES).doc(phaseId).get()
      return phaseDoc.data() as Phase | undefined
    }
    return undefined
  }, [])

  const updateLastRead = useCallback(() => {
    userDocRef?.set(
      {
        patientLastRead: {
          [id]: (lastMessage as Message)?.timestamp ?? firestore.Timestamp.now(),
        },
      },
      { merge: true },
    )
  }, [id, lastMessage, userDocRef])

  const checkAndUpdatePhaseIfNeeded = useCallback(async () => {
    const currentPhase = await getCurrentPatientPhase(phase as ID)
    if (!currentPhase) {
      return
    }

    const startDate = dayjs(surgeryDate).add(currentPhase.startDay, "day")
    const endDate = dayjs(surgeryDate).add(currentPhase.endDay, "day")
    const currentDate = dayjs(currentDateMs())

    if (currentDate.isBefore(startDate) || currentDate.isAfter(endDate)) {
      const nextPhase = await getNextPhase(pathology, surgeryDate)
      if (nextPhase) {
        updatePatientData(id, { phase: nextPhase?.id })
      }
    }
  }, [getCurrentPatientPhase, phase, surgeryDate, pathology, id])

  const openPatientProfile = useCallback(() => {
    navigation.navigate("Patient_Profile", { id })
    setTimeout(() => {
      updateLastRead()
      checkAndUpdatePhaseIfNeeded()
    }, 0)
  }, [id, navigation, updateLastRead, checkAndUpdatePhaseIfNeeded])

  return (
    <Touchable style={s.row} rippleColor={colors.accent} onPress={openPatientProfile}>
      <>
        <View>
          <Subtitle1 style={s.name}>
            {t("name", { firstName, lastName, interpolation: { escapeValue: false } })}
          </Subtitle1>
          <View style={s.evaluation}>
            {painIcon?.checked}
            <>
              {painIcon?.checked && effortIcon?.checked ? <Caption>{" • "}</Caption> : null}
              {effortIcon?.checked}
              {painIcon?.checked || effortIcon?.checked ? <Caption>{" • "}</Caption> : null}
              <Caption style={s.caption}>
                {_.isEmpty(pathologyShortName) ? pathologyName : pathologyShortName}
              </Caption>
            </>
          </View>
        </View>
        <View style={{ alignItems: "flex-end" }}>
          {surgeryDate ? (
            <Subtitle1 style={s.subtitle}>
              {t(`daySinceSurgery_${nbDays && nbDays > 0 ? "positive" : "negative"}`, { nbDays })}
            </Subtitle1>
          ) : null}
          {lastMessage && isUnread ? (
            <View style={s.message}>
              <Caption style={s.surgeon}>{(lastMessage as Message)?.authorName}</Caption>
              <VectorIcon name="message" category="MaterialIcons" size={ICON_SIZE_SMALL} />
            </View>
          ) : null}
        </View>
      </>
    </Touchable>
  )
}

export default PatientsListItem
