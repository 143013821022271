import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  Button,
  Caption,
  generateShadow,
  IUserContext,
  Subtitle1,
  userContext,
  useTheme,
  VectorIcon,
} from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"
import { AppUserData } from "src/features/models/UserData"

import { ICON_SIZE } from "../../features/config/Constants"
import currentDateMs from "../../features/hooks/useDateMock"
import { HomeNS } from "../../features/i18n/constants"
import { calculateLimitStartDate } from "../../features/models/OncoFormFunctions"
import { programContext } from "../../features/Providers/ProgramProvider"
import { IHomeParamList } from "../MainScreen/MainTabs"

interface ExercisePicture {
  uri: string
  id: string
}

const HomeOncoView: FC = () => {
  const { t, i18n } = useTranslation(HomeNS)
  const {
    dimensions: { spacing },
    colors: {
      white: { highEmphasis: white },
    },
  } = useTheme()
  const navigation = useNavigation<StackNavigationProp<IHomeParamList, "Home_Main">>()
  const { currentSession, phaseExercises } = useContext(programContext)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const currentDate = dayjs(currentDateMs()).endOf("day").toDate()
  const currentDateString = dayjs(currentDateMs()).locale(i18n.language).format("dddd DD MMMM")
  const limitStartDate = calculateLimitStartDate(userData)
  const formatedDate = currentDateString.charAt(0).toUpperCase() + currentDateString.slice(1)
  const programStartDate = dayjs(userData?.programStart).toDate()
  const formatedStartDate = dayjs(programStartDate).locale(i18n.language).format("dddd DD MMMM")
  const isCurrentProg =
    programStartDate &&
    currentDate > programStartDate &&
    (programStartDate > limitStartDate ||
      dayjs(programStartDate).isSame(dayjs(limitStartDate), "day"))
  const isProgToCome = programStartDate && currentDate < programStartDate
  const isEmptyExo = _.isEmpty(phaseExercises)

  const exercisePictures: ExercisePicture[] = useMemo(
    () =>
      currentSession
        ? _(phaseExercises)
            .map(ex => ({ id: ex.id, uri: ex.image ?? "" }))
            .value()
        : [],
    [currentSession, phaseExercises],
  )

  const s = useMemo(
    () => ({
      view: [
        styles.sessionContainer,
        {
          backgroundColor: white,
          marginHorizontal: spacing,
          paddingBottom: spacing / 2,
          marginBottom: spacing / 2,
        },
        generateShadow(4),
      ],
      textView: {
        marginBottom: spacing / 2,
      },
      iconText: { marginLeft: spacing / 2 },
      sessionEndText: { margin: spacing / 2 },
      iconView: [
        styles.iconView,
        {
          marginBottom: spacing / 2,
        },
      ],
      content: { marginHorizontal: spacing, paddingVertical: spacing / 2 },
      caption: [
        styles.caption,
        {
          paddingVertical: spacing / 4,
        },
      ],
    }),
    [white, spacing],
  )

  const onPress = useCallback(() => navigation.navigate("ExerciseNavigator"), [navigation])

  return (
    <View style={s.view}>
      {exercisePictures && exercisePictures[0] && exercisePictures[0].uri ? (
        <FastImage
          source={{ uri: exercisePictures[0].uri ?? "" }}
          style={styles.image}
          key={exercisePictures[0].id}
        />
      ) : null}
      <View style={s.content}>
        {isProgToCome ? (
          <View style={styles.startProgView}>
            <Subtitle1 style={s.iconText} emphasis="high">
              {t("sessionToCome")}
            </Subtitle1>
            <Subtitle1 style={s.iconText} emphasis="high">
              {formatedStartDate}
            </Subtitle1>
          </View>
        ) : currentSession && !isEmptyExo ? (
          <View style={s.textView}>
            <View style={s.iconView}>
              <VectorIcon
                {...{ name: "calendar-today" }}
                category="MaterialIcons"
                size={ICON_SIZE}
              />
              {isCurrentProg ? (
                <Subtitle1 style={s.iconText} emphasis="high">
                  {formatedDate}
                </Subtitle1>
              ) : null}
            </View>
            {isCurrentProg && currentSession ? (
              <>
                <View style={s.caption}>
                  <Caption emphasis="high">
                    {t("descriptionWithoutEquipments", {
                      count: currentSession.exercises,
                      time: currentSession.duration,
                    })}
                  </Caption>
                </View>
                <View style={s.caption}>
                  <Caption emphasis="high">{t("takeTime")}</Caption>
                </View>
              </>
            ) : null}
          </View>
        ) : isEmptyExo ? (
          <View style={s.textView}>
            <Subtitle1 style={s.sessionEndText} emphasis="high">
              {t("noExo")}
            </Subtitle1>
          </View>
        ) : (
          <View style={s.textView}>
            <Subtitle1 style={s.sessionEndText} emphasis="high">
              {t("sessionEnd")}
            </Subtitle1>
          </View>
        )}
        {isProgToCome ? (
          <Button {...{ onPress }} disabled>
            {t("sessionToComeButton")}
          </Button>
        ) : currentSession ? (
          isCurrentProg ? (
            <Button {...{ onPress }}>{t("startSession")}</Button>
          ) : null
        ) : !isEmptyExo ? (
          <Button {...{ onPress }} disabled>
            {t("endedSession")}
          </Button>
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  sessionContainer: {
    flex: 1,
    width: "90%",
    borderRadius: 8,
    alignSelf: "center",
  },
  image: {
    width: "100%",
    height: 100,
  },
  caption: {
    alignItems: "center",
    flexDirection: "row",
  },
  iconView: {
    flex: 1,
    flexDirection: "row",
  },
  startProgView: {
    flex: 1,
    flexDirection: "column",
  },
})

export default HomeOncoView
