import {
  Body2,
  Caption,
  generateShadow,
  IRowItemProps,
  ISectionHeader,
  IUserContext,
  ParametersScreen,
  userContext,
  useTheme,
  VectorIcon,
  VectorIconProps,
} from "capsule"
import { useFormikContext } from "formik"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Image,
  ImageSourcePropType,
  SectionListData,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native"

import { images } from "../../assets/images"
import { collections } from "../../common/types"
import SubmitButton from "../../components/SubmitButton"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import useItemI18n from "../../features/hooks/useItemI18n"
import usePermissions from "../../features/hooks/usePermissions"
import { OperationNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { OncoFormValues } from "./Constants"
import { OncoParamList } from "./OncoPathologyNavigator"

interface IProps {
  isEdit?: boolean
}

const OncoPathologyScreen: FC<IProps> = ({ isEdit = false }) => {
  const {
    colors: {
      accent,
      surface,
      white: { highEmphasis: white },
      black: { mediumEmphasis: black },
    },
    dimensions: { spacing },
  } = useTheme()
  usePermissions(isEdit)
  const { t } = useTranslation(OperationNS)
  const { handleSubmit, values, validateForm, setFieldValue } = useFormikContext<OncoFormValues>()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const pathologyName = useItemI18n(
    collections.PATHOLOGIES,
    (userData?.pathology as string) ?? values.pathology,
    "name",
  )
  const code = userData?.code || t("missing")
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    validateForm()
  }, [values, validateForm])

  useEffect(() => {
    if (isEdit && userData?.specialty) {
      setFieldValue("specialty", userData.specialty)
    }
  }, [isEdit, setFieldValue, userData?.specialty])

  const s = useMemo(
    () => ({
      header: {
        backgroundColor: white,
        paddingVertical: spacing,
      },
      rowDate: {
        padding: spacing,
        backgroundColor: white,
      },
      rowSubitem: [
        styles.row,
        styles.shadow,
        {
          marginTop: spacing,
          paddingLeft: spacing * 2,
        },
      ],
      rowKine: [
        styles.shadow,
        {
          padding: spacing,
          backgroundColor: white,
        },
      ],
      body: [
        styles.body,
        {
          padding: spacing,
          marginTop: spacing,
          backgroundColor: accent,
        },
      ],
      icon: {
        tintColor: black,
      },
      rowPatInfo: [
        styles.shadow,
        {
          padding: spacing,
          marginBottom: spacing / 2,
          backgroundColor: white,
        },
      ],
      bodyInfo: [
        styles.body,
        {
          padding: spacing,
          backgroundColor: accent,
        },
      ],
      touchable: [styles.touchable, { backgroundColor: surface.textInput }],
    }),
    [accent, black, spacing, surface.textInput, white],
  )

  const renderIcon = useCallback(
    (name: string) =>
      ({
        name,
        category: "MaterialIcons",
        size: ICON_SIZE,
        color: black,
      } as VectorIconProps),
    [black],
  )

  const renderPathologyRightChild = useCallback(
    () => (
      <View style={styles.pathology}>
        {pathologyName ? (
          <Caption style={styles.caption} emphasis="medium">
            {pathologyName}
          </Caption>
        ) : null}
        <VectorIcon {...renderIcon("chevron-right")} />
      </View>
    ),
    [renderIcon, pathologyName],
  )

  const sections: Array<SectionListData<IRowItemProps<OncoParamList>, ISectionHeader>> = useMemo(
    () => [
      {
        shadows: 1,
        title: t("header"),
        headerStyle: s.header,
        data: [
          {
            rowStyle: s.rowSubitem,
            name: (userData?.surgeonName as string) || t("missing"),
            labelStyle: styles.label,
            label: t("label.surgeon"),
            leftChild: (
              <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
            ),
          },
          {
            render: () => (
              <>
                {!userData?.surgeonName ? (
                  <View style={s.rowKine}>
                    <Body2 style={s.body}>{t("informatioSurgeonWithoutCode", { code })}</Body2>
                  </View>
                ) : null}
              </>
            ),
          },
        ],
      },
      {
        data: [
          {
            rowStyle: s.rowSubitem,
            name: t("label.pathology"),
            route: isEdit ? undefined : "Organ",
            rightChild: renderPathologyRightChild(),
          },
        ],
        title: "",
      },
      {
        data: [
          {
            name: t("label.pathology"),
            render: () => (
              <View style={s.rowPatInfo}>
                <Body2 style={s.bodyInfo}>{t("pathologyInformation")}</Body2>
              </View>
            ),
          },
        ],
        title: "",
      },
      {
        shadows: 1,
        data: [
          {
            rowStyle: s.rowSubitem,
            name: (userData?.kineName as string) || t("missing"),
            labelStyle: styles.label,
            label: t("label.kine"),
            leftChild: (
              <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
            ),
          },
          {
            render: () => (
              <>
                {!userData?.kineName ? (
                  <View style={s.rowKine}>
                    <Body2 style={s.body}>{t("informationWithoutCode", { code })}</Body2>
                  </View>
                ) : null}
              </>
            ),
          },
        ],
        title: "",
      },
    ],
    [isEdit, renderPathologyRightChild, s, t, code, userData?.surgeonName, userData?.kineName],
  )

  const onSubmit = useCallback(async () => {
    setSubmitLoading(true)
    handleSubmit?.()
    setSubmitLoading(false)
  }, [handleSubmit])

  const renderFooterComponent = useCallback(
    () => (
      <SubmitButton<OncoFormValues>
        {...{ onSubmit }}
        syncLoading={submitLoading}
        viewStyle={styles.submit}
      />
    ),
    [onSubmit, submitLoading],
  )

  return (
    <ParametersScreen
      listKey="operationList"
      sections={sections}
      footerComponent={renderFooterComponent()}
    />
  )
}

const styles = StyleSheet.create({
  submit: {
    width: "100%",
  },
  row: {
    height: INPUT_HEIGHT,
  },
  label: {
    paddingBottom: 0,
  },
  touchable: {
    height: INPUT_HEIGHT,
    borderRadius: 0,
  },
  shadow: generateShadow(2) as ViewStyle,
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  pathology: {
    flexDirection: "row",
    alignItems: "center",
  },
  caption: {
    width: 150,
  },
})

export default OncoPathologyScreen
