import { PathConfigMap } from "@react-navigation/core"
import { useIsFocused } from "@react-navigation/native"
import { ParamListBase } from "@react-navigation/routers"
import {
  createStackNavigator,
  HeaderStyleInterpolators,
  TransitionPresets,
} from "@react-navigation/stack"
import { EmptyView, userContext, useTheme } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StatusBar, StyleSheet } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { Interstitial } from "../../common/Proms"
import { collections } from "../../common/types"
import { RATING_SCORE } from "../../features/config/Constants"
import { PromsNS } from "../../features/i18n/constants"
import { PatientPromsResult } from "../../features/models/Proms"
import { promsContext } from "../../features/Providers/PromsProvider"
import InterstitialScreen from "./InterstitialScreen/InterstitialScreen"
import PromsScreen from "./PromsScreen"

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface PromsParamList extends ParamListBase {
  Proms_Welcome: { type?: Interstitial }
  Proms_Form: { id: number }
}

const promsNavigator = createStackNavigator<PromsParamList>()

const options = {
  headerShown: false,
  ...TransitionPresets.SlideFromRightIOS,
  headerStyleInterpolator: HeaderStyleInterpolators.forNoAnimation,
}

export const screens: PathConfigMap<PromsParamList> = {
  Proms_Welcome: "proms_welcome",
  Proms_Form: "quest/:id",
}

const PromsNavigator = () => {
  const {
    colors: {
      primary,
      black: { mediumEmphasis: black },
      surface: { background },
    },
  } = useTheme()
  const isFocused = useIsFocused()
  const { t } = useTranslation(PromsNS)
  const { userDocRef } = useContext(userContext)
  const { form, triggerDay, setIsOpened, setShowHomeProms, loading } = useContext(promsContext)
  const [userProms] = useCollectionData<PatientPromsResult>(
    userDocRef && form?.id
      ? ((userDocRef
          .collection(collections.PROMS)
          .where("id", "==", form.id) as unknown) as firebase.firestore.Query<PatientPromsResult>)
      : null,
  )
  const lastUserProms = useMemo(() => {
    const filteredArray = userProms?.filter(item => item.triggerDay !== undefined)
    if (!filteredArray || filteredArray.length === 0) {
      return userProms?.[0]
    }
    return filteredArray.reduce(
      (maxObj, item) =>
        item.triggerDay &&
        (!maxObj || maxObj.triggerDay === undefined || item.triggerDay > maxObj.triggerDay)
          ? item
          : maxObj,
      null as (PatientPromsResult & { docId: string }) | null,
    )
  }, [userProms])
  const isRating = form?.thanks === RATING_SCORE
  const [initialRouteName, setInitialRouteName] = useState<string>()
  const [initialType, setInitialType] = useState<Interstitial | undefined>(undefined)
  const busy = !initialRouteName && !initialType
  useEffect(() => {
    if ((form && userProms) || form?.signInForm) {
      const promsResult = lastUserProms
      const isExistingProms = promsResult && triggerDay === lastUserProms.triggerDay
      setInitialRouteName(
        (_.includes(form?.formulas, RATING_SCORE) && _.isEmpty(promsResult)) ||
          form.skipInterstitial === true
          ? "Proms_Form"
          : "Proms_Welcome",
      )
      setInitialType(
        form.signInForm
          ? Interstitial.SIGNIN
          : _.isEmpty(promsResult) || !isExistingProms || promsResult?.showAgain
          ? Interstitial.WELCOME
          : Interstitial.DONE,
      )
    }
  }, [form, userProms, triggerDay, lastUserProms])

  useEffect(() => {
    if (isFocused) {
      setShowHomeProms(true)
      setIsOpened(true)
    }
  }, [isFocused, setIsOpened, setShowHomeProms])

  const s = useMemo(
    () => ({
      empty: [
        styles.empty,
        {
          backgroundColor: primary,
        },
      ],
    }),
    [primary],
  )

  return busy || loading ? (
    <>
      <StatusBar barStyle="dark-content" backgroundColor={primary} />
      <EmptyView style={s.empty} viewStyle={s.empty}>
        {busy || loading ? <ActivityIndicator size="large" color={black} /> : null}
      </EmptyView>
    </>
  ) : (
    <promsNavigator.Navigator {...{ initialRouteName }}>
      <promsNavigator.Screen
        name="Proms_Welcome"
        {...{ options }}
        initialParams={{ type: initialType }}
      >
        {props => (
          <>
            <StatusBar barStyle="dark-content" backgroundColor={primary} />
            <InterstitialScreen {...props} />
          </>
        )}
      </promsNavigator.Screen>
      <promsNavigator.Screen
        name="Proms_Form"
        initialParams={{ id: form?.questionId ?? 0 }}
        options={{ ...options, title: isRating ? t("satisfaction") : form?.title }}
      >
        {props => (
          <>
            <StatusBar barStyle="dark-content" backgroundColor={background} />
            <PromsScreen {...props} />
          </>
        )}
      </promsNavigator.Screen>
    </promsNavigator.Navigator>
  )
}

const styles = StyleSheet.create({
  empty: {
    flex: 1,
  },
})

export default PromsNavigator
