import React, { FC, ReactChild, useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import { Body2, Caption, IBodyProps, Subtitle1 } from "../Texts"

interface IProps {
  text?: ReactChild
  placeholder?: string
  label?: string
  color?: string
  isBodyText?: boolean
  viewStyle?: StyleProp<ViewStyle> // manage label and text position
  textStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
  labelProps?: Partial<IBodyProps>
  maxFontSizeMultiplier?: number
}

const RowCenterItem: FC<IProps> = ({
  text,
  placeholder,
  label,
  textStyle,
  isBodyText,
  color,
  viewStyle,
  labelStyle,
  labelProps,
  maxFontSizeMultiplier,
}) => {
  const {
    colors: { black },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      view: [styles.view, viewStyle],
      label: [
        {
          paddingBottom: spacing / 2,
        },
        labelStyle,
      ],
      commonProps: {
        color: color ?? black.highEmphasis,
      },
    }),
    [black.highEmphasis, color, labelStyle, spacing, viewStyle],
  )

  return (
    <View style={s.view}>
      {label ? (
        <Caption {...labelProps} style={s.label}>
          {label}
        </Caption>
      ) : null}
      {text ? (
        isBodyText ? (
          <Body2 {...s.commonProps}>{text}</Body2>
        ) : (
          <Subtitle1
            style={textStyle}
            maxFontSizeMultiplier={maxFontSizeMultiplier}
            {...s.commonProps}
          >
            {text}
          </Subtitle1>
        )
      ) : placeholder ? (
        <Caption {...labelProps} style={s.label}>
          {placeholder}
        </Caption>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
    height: 48,
    justifyContent: "center",
  },
})

export default RowCenterItem
