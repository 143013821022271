import { useEffect, useState } from "react"

const useDelay = (ms = 500) => {
  const [expired, setExpired] = useState(false)

  useEffect(() => {
    const id = setTimeout(() => setExpired(true), ms)
    return () => clearTimeout(id)
  }, [ms])

  return [expired]
}

export default useDelay
