import { useNavigation } from "@react-navigation/native"
import { Button, useTheme } from "capsule"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { Organ } from "../../common/Pathology"
import PatientsList from "../../components/PatientsList"
import { PatientsListNS } from "../../features/i18n/constants"

interface IProps {
  organ?: Organ
}

const PatientsListScreen: FC<IProps> = ({ organ }) => {
  const {
    dimensions: { spacing },
    colors: { surface },
  } = useTheme()
  const { t } = useTranslation(PatientsListNS)
  const navigation = useNavigation<any>()
  const s = useMemo(
    () => ({
      view: [
        styles.view,
        {
          backgroundColor: surface.background,
        },
      ],
    }),
    [surface.background],
  )
  return (
    <View style={s.view}>
      <Button style={{ margin: spacing }} onPress={() => navigation.navigate("AddPatient")}>
        {t("add")}
      </Button>
      <PatientsList organ={organ?.id} />
    </View>
  )
}

export default PatientsListScreen

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
})
