import React, { FC } from "react"
import { TouchableOpacity, TouchableOpacityProps } from "react-native"
import { List } from "react-native-paper"

export type IconProps = React.ComponentProps<typeof List.Icon>

/**
 * @deprecated
 */
const IconButton: FC<IconProps & TouchableOpacityProps> = ({ onPress, icon, style, color }) => (
  <TouchableOpacity {...{ onPress, style }}>
    <List.Icon {...{ icon, color, style }} />
  </TouchableOpacity>
)

export default IconButton
