// TO KEEP EVEN IF NOT USED
import React, { FC } from "react"
import { StyleSheet, View } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"

interface IProps {
  color?: string
  active: boolean
}

const CircleStep: FC<IProps> = ({ active, color }) => {
  const {
    colors: { primary, overrides },
  } = useTheme()

  return (
    <View
      style={[
        styles.view,
        {
          backgroundColor: color || overrides?.circleStep || primary,
          opacity: active ? 1 : 0.2,
        },
      ]}
    />
  )
}

const styles = StyleSheet.create({
  view: {
    borderRadius: 10,
    height: 8,
    marginLeft: 5,
    width: 8,
  },
})

export default CircleStep
