import { RouteProp } from "@react-navigation/core"
import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  H6,
  IUserContext,
  SectionSeparator,
  TabBar,
  tabContext,
  TabType,
  userContext,
  useStyles,
} from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, ScrollView, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { SpecialtyName } from "../../common/CommonUserData"
import { Exercise, tabKeys } from "../../common/Phase"
import { itemField } from "../../features/hooks/useItemI18n"
import useSpecialtySystem from "../../features/hooks/useSpecialtySystem"
import { ExerciseNS } from "../../features/i18n/constants"
import { NextSessionAction } from "../../features/models/Session"
import { AppUserData } from "../../features/models/UserData"
import { CongratType } from "../../features/Navigation/types"
import { programContext } from "../../features/Providers/ProgramProvider"
import { sessionContext } from "../../features/Providers/SessionProvider"
import { useVideo } from "../../features/Providers/VideoProvider"
import ExerciseContentView from "./ExerciseContentView"
import { ExerciseParamList } from "./ExerciseNavigator"
import ExerciseVideoButtons from "./ExerciseVideoButtons"
import ExerciseVideoView from "./ExerciseVideoView"

interface IProps {
  specialty?: SpecialtyName
  route: RouteProp<ExerciseParamList, "Exercise">
}

const ExerciseScreen: FC<IProps> = ({ route, specialty }) => {
  const { t } = useTranslation(ExerciseNS)
  const navigation = useNavigation<any>()
  const [isFullScreenPrincipal, setIsFullScreenPrincipal] = useState(false)
  const { setCurrentTab } = useContext(tabContext)
  const { phaseEquipments } = useContext(programContext)
  const {
    index,
    currentEx,
    endExercise,
    goToPreviousExercise,
    endSession,
    displayMiSessionCongratulation,
    displayProgramCongratulation,
  } = useContext(sessionContext)
  const {
    actions: { reset },
  } = useVideo()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { displayEvaluation } = useSpecialtySystem()
  const exoToDisplay = useMemo(() => route?.params?.selectedExercise ?? currentEx, [
    currentEx,
    route?.params,
  ]) as Exercise

  const title = itemField(exoToDisplay, "title", t)
  const videoUri = itemField(exoToDisplay, "video", t)

  const equipmentsText = _.join(
    _(exoToDisplay?.equipment)
      .map(eq => itemField(phaseEquipments?.[eq], "title", t))
      .value(),
    " • ",
  )

  const tabs = useMemo<TabType[]>(
    () =>
      _(tabKeys)
        .map(n =>
          _.isEmpty(exoToDisplay?.[`${n}Video`]) && _.isEmpty(itemField(exoToDisplay, n, t))
            ? undefined
            : { index: n as string, title: t(n) },
        )
        .compact()
        .value(),
    [exoToDisplay, t],
  )

  useEffect(() => {
    if (tabs) {
      setCurrentTab(tabs[0])
    }
  }, [setCurrentTab, tabs])

  useEffect(() => {
    if (isFullScreenPrincipal) {
      navigation.setOptions({ headerShown: false })
    } else {
      navigation.setOptions({ headerShown: true })
    }
  }, [isFullScreenPrincipal, navigation])

  const s = useStyles(
    ({ dimensions: { spacing }, colors: { primary, white, surface } }) => ({
      body: {
        paddingTop: spacing / 2,
      },
      container: {
        flex: 1,
        backgroundColor: surface.appUi,
        borderColor: Platform.OS === "web" ? surface.card : undefined,
        borderWidth: Platform.OS === "web" ? 2 : undefined,
      },
      content: {
        flexGrow: 1,
      },
      indicator: {
        marginTop: spacing * 2,
      },
      innerContent: {
        padding: spacing,
        backgroundColor: white.highEmphasis,
      },
      tabBar: {
        minHeight: 40,
        backgroundColor: white.highEmphasis,
        paddingVertical: Platform.OS === "web" ? spacing * 2.8 : undefined,
      },
      view: {
        flex: 1,
        backgroundColor: primary,
      },
      webContainer: {
        flex: 1,
        flexDirection: "row",
      },
      webContent: {
        flex: 1,
        maxWidth: "60%",
      },
      videoWeb: {
        borderRadius: 16,
        overflow: "hidden",
        margin: spacing * 1.2,
        maxWidth: "80%",
      },
    }),
    [],
  )

  const onEndExercise = useCallback(
    async (afterEval?: boolean, skip?: boolean) => {
      reset()
      if (route?.params?.selectedExercise) {
        navigation.navigate("Patient_Profile", { id: route?.params?.patientId })
        // TODO: add navigation to ExerciseEvaluation with
        return
      }
      const displayProgramCongrat = displayProgramCongratulation()
      const displayEveningEval = displayEvaluation(true)
      const result = await endExercise(afterEval, skip)
      switch (result) {
        case NextSessionAction.CLOSE: {
          endSession()
          navigation.navigate(
            displayEveningEval ? "EveningEvaluation" : "Congratulation",
            displayEveningEval
              ? { endProgram: displayProgramCongrat }
              : {
                  type: displayProgramCongrat ? CongratType.program : CongratType.session,
                },
          )
          return
        }
        case NextSessionAction.EVALUATION: {
          // Evaluation exercise
          navigation.navigate("ExerciseEvaluation")
          return
        }
        case NextSessionAction.EVALUATION_AND_FINISH: {
          navigation.navigate("ExerciseEvaluation", { validationExIsLast: true })
          return
        }
        // NEXT Exercise
        default: {
          const displayMiSessionCongrat = displayMiSessionCongratulation()
          if (displayMiSessionCongrat) {
            navigation.navigate("Congratulation", { type: CongratType.misession })
          }
          return
        }
      }
    },
    [
      reset,
      route?.params?.selectedExercise,
      route?.params?.patientId,
      displayProgramCongratulation,
      displayEvaluation,
      endExercise,
      navigation,
      endSession,
      displayMiSessionCongratulation,
      // userActivity,
    ],
  )

  const UpperView = specialty === "onco" ? ScrollView : View
  const InnerView = specialty === "onco" ? View : ScrollView
  return (
    <SafeAreaView style={s.view} edges={["left", "right"]}>
      {exoToDisplay ? (
        <>
          <UpperView style={s.container}>
            {Platform.OS === "web" ? (
              <View style={s.webContainer}>
                {route?.params?.selectedExercise && (
                  // Practician view (readOnly)
                  <View style={s.webContent}>
                    <View style={s.innerContent}>
                      <H6 emphasis="high">{title}</H6>
                      <Body2 emphasis="high" style={s.body}>
                        {equipmentsText}
                      </Body2>
                    </View>
                    <View style={s.videoWeb}>
                      <ExerciseVideoView id={exoToDisplay.id} uri={videoUri} readOnly={true} />
                    </View>
                  </View>
                )}
                <InnerView style={s.container} contentContainerStyle={s.content}>
                  <View style={s.tabBar}>
                    <TabBar {...{ tabs }} display={3} />
                    <ExerciseContentView
                      {...{ specialty }}
                      exercise={exoToDisplay}
                      isFullScreenPrincipal={isFullScreenPrincipal}
                    />
                  </View>
                </InnerView>
              </View>
            ) : (
              <>
                {route?.params?.selectedExercise ? (
                  // Practician view (readOnly)
                  <ExerciseVideoView
                    id={exoToDisplay.id}
                    uri={videoUri}
                    readOnly={true}
                    isFullScreenPrincipal={isFullScreenPrincipal}
                    setIsFullScreenPrincipal={setIsFullScreenPrincipal}
                  />
                ) : (
                  // Patient view
                  <ExerciseVideoView
                    {...{ specialty }}
                    id={exoToDisplay.id}
                    isFullScreenPrincipal={isFullScreenPrincipal}
                    setIsFullScreenPrincipal={setIsFullScreenPrincipal}
                    uri={videoUri}
                    reps={exoToDisplay.reps}
                    series={exoToDisplay.series}
                  />
                )}
                <SectionSeparator />

                <InnerView style={s.container} contentContainerStyle={s.content}>
                  <View style={s.innerContent}>
                    <H6 emphasis="high">{title}</H6>
                    <Body2 emphasis="high" style={s.body}>
                      {equipmentsText}
                    </Body2>
                  </View>
                  <View style={s.tabBar}>
                    <TabBar {...{ tabs }} display={3} />
                  </View>
                  <ExerciseContentView
                    {...{ specialty }}
                    exercise={exoToDisplay}
                    isFullScreenPrincipal={isFullScreenPrincipal}
                  />
                </InnerView>
              </>
            )}
          </UpperView>
          {route?.params?.patientId || userData?.role === "surgeon" ? null : (
            <ExerciseVideoButtons
              isFullScreenPrincipal={isFullScreenPrincipal}
              isPreviousDisabled={index === 0}
              {...{ onEndExercise, goToPreviousExercise, specialty }}
              onVideoPress={() => {}}
            />
          )}
        </>
      ) : (
        <ActivityIndicator style={s.indicator} size="large" />
      )}
    </SafeAreaView>
  )
}

export default ExerciseScreen
