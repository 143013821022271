import {
  HeaderBackButton as NHeaderBackButton,
  HeaderBackButtonProps,
} from "@react-navigation/elements"
import React, { FC, useMemo } from "react"
import { Platform } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"
import { VectorIcon, VectorIconProps } from "../VectorIcon"

interface Iprops extends HeaderBackButtonProps {
  vectorIcon?: VectorIconProps
}

export const HeaderBackButton: FC<Iprops> = ({ vectorIcon, labelVisible, backImage, ...props }) => {
  const {
    dimensions: { spacing },
  } = useTheme()
  const s = useMemo(
    () => ({
      button: {
        ...Platform.select({
          ios: {
            paddingLeft: labelVisible ? 0 : spacing,
          },
          // maybe web too
        }),
      },
    }),
    [spacing, labelVisible],
  )
  return (
    <NHeaderBackButton
      {...props}
      {...{ labelVisible }}
      style={s.button}
      backImage={vectorIcon ? () => <VectorIcon {...vectorIcon} /> : backImage}
    />
  )
}
