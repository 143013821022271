import React, { FC, ReactNode, Ref, useEffect } from "react"
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native"
import { Image as RNICPicker } from "react-native-image-crop-picker"

import useTheme from "../../features/Theme/ThemeProvider"
import { useGalleryPicker } from "../GalleryPicker"
import RowSideItem from "../RowItem/RowSideItem"
import { Subtitle1 } from "../Texts"
import { VectorIconProps } from "../VectorIcon"

interface IProps {
  title: string
  subtitle?: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  ref?: Ref<Record<string, unknown>>
  rightChild?: ReactNode | VectorIconProps
  updateImage?: (url: string) => void
  defaultSource?: ImageSourcePropType
}

const RowPhotoPickerItem: FC<IProps> = ({
  title,
  style,
  subtitle,
  textStyle,
  rightChild,
  updateImage,
  defaultSource,
}) => {
  const {
    colors,
    dimensions: { spacing },
  } = useTheme()
  const { openGallery, source } = useGalleryPicker()

  const rowStyle = [
    styles.rowContent,
    {
      paddingHorizontal: spacing,
      backgroundColor: colors.surface.appUi,
    },
    style,
  ]

  const textViewStyle = [
    styles.flexContent,
    {
      marginHorizontal: spacing,
    },
  ]

  const placeholderViewStyle = [
    styles.image,
    {
      backgroundColor: colors.surface.input,
    },
  ]

  useEffect(() => {
    if ((source as RNICPicker)?.path && updateImage) {
      updateImage((source as RNICPicker).path)
    }
  }, [source, updateImage])

  const onPress = () => openGallery()

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={rowStyle}>
        {source ? (
          <Image style={styles.image} source={source && { uri: (source as RNICPicker).path }} />
        ) : defaultSource ? (
          <Image style={styles.image} source={defaultSource} />
        ) : (
          <View style={placeholderViewStyle} />
        )}
        <View style={textViewStyle}>
          <Subtitle1 emphasis="high" style={textStyle}>
            {title}
          </Subtitle1>
          {subtitle && <Subtitle1 emphasis="medium">{subtitle}</Subtitle1>}
        </View>
        <RowSideItem child={rightChild} />
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  flexContent: {
    flex: 1,
  },
  image: {
    width: 56,
    height: 56,
  },
  rowContent: {
    width: "100%",
    minHeight: 72,
    flexDirection: "row",
    alignItems: "center",
  },
})

export default RowPhotoPickerItem
