import NetInfo from "@react-native-community/netinfo"
import { useIsFocused } from "@react-navigation/native"
import { Body2, tabContext, useStyles, useTheme, VectorIconProps, VideoPlayer } from "capsule"
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, View } from "react-native"
import { OnVideoErrorData, VideoRef } from "react-native-video"

import { Exercise, Tabs, VideoKeys } from "../../common/Phase"
import { collections } from "../../common/types"
import EmptyVideo from "../../components/EmptyVideo"
import { ICON_SIZE_MEDIUM } from "../../features/config/Constants"
import useItemI18n, { itemField } from "../../features/hooks/useItemI18n"
import { ExerciseNS } from "../../features/i18n/constants"
import { useProgram } from "../../features/Providers/ProgramProvider"

interface IProps {
  exercise: Exercise
  isFullScreenPrincipal: boolean
}

const ExerciseContentView: FC<IProps> = ({ exercise, isFullScreenPrincipal }) => {
  const {
    colors: {
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { t } = useTranslation(ExerciseNS)
  const [videoError, setVideoError] = useState<OnVideoErrorData>()
  const { prefTabVideo, setPrefTabVideo } = useProgram()
  const { currentTab } = useContext(tabContext)
  const videoRef = useRef<VideoRef>(null)
  const isFocused = useIsFocused()
  const [paused, setPaused] = useState(true)
  const field = currentTab?.index as Tabs
  const videoField = `${field}Video` as VideoKeys
  const description = useItemI18n(collections.EXERCISES, exercise?.id, field)
  const uri = itemField(exercise, videoField, t)

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        surface: { appUi },
      },
    }) => ({
      view: {
        padding: spacing,
        backgroundColor: appUi,
        paddingBottom: spacing * 2,
      },
      body: {
        paddingTop: spacing,
      },
      video: {
        borderRadius: 16,
        overflow: "hidden",
      },
    }),
    [],
  )

  useEffect(() => {
    NetInfo.addEventListener(state => {
      if (state.isConnected) {
        setVideoError(undefined)
      }
    })
  }, [])

  useEffect(() => {
    if (!prefTabVideo) {
      setPaused(true)
    }
  }, [prefTabVideo])

  useEffect(() => {
    if (isFullScreenPrincipal) {
      setPaused(true)
    }
  }, [isFullScreenPrincipal])

  useEffect(() => {
    if (!isFocused) {
      setPaused(true)
    }
  }, [isFocused])

  const onVideoPress = useCallback(() => {
    if (paused) {
      setPrefTabVideo(true)
    }
    setPaused(p => !p)
  }, [paused, setPrefTabVideo])

  const icon = useMemo<VectorIconProps>(
    () => ({
      name: "play-circle-fill",
      category: "MaterialIcons",
      size: ICON_SIZE_MEDIUM,
      color: white,
    }),
    [white],
  )

  const onError = useCallback((error: OnVideoErrorData) => {
    setVideoError(error)
  }, [])

  return (
    <View style={s.view}>
      {!uri ? null : videoError ? (
        <EmptyVideo />
      ) : (
        <VideoPlayer
          hasExtraHeader={false}
          startPosition={1.5}
          showProgress={false}
          isControls
          control={{ paused, onError }}
          style={[s.video, { width: Platform.OS === "web" ? "85%" : "100%", aspectRatio: 16 / 9 }]}
          forwardRef={videoRef}
          key={currentTab?.index}
          controlledPause={true}
          {...{ uri, onVideoPress, icon }}
        />
      )}
      <Body2 style={[s.body, { flex: 1 }]} emphasis="high">
        {description}
      </Body2>
    </View>
  )
}

export default ExerciseContentView
