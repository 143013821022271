import { IColorTheme } from "./types"

const colors: IColorTheme = {
  primary: "#169A9F",
  secondary: "#00AEB5",
  selection: "#C8ECED",
  accent: "#B2E7E9",
  error: "#B00020",
  danger: "#E04242",
  grey: "#EFEFEF",
  transparent: "transparent",
  white: {
    header: "#FFF",
    highEmphasis: "#FFF",
    mediumEmphasis: "#FFF",
    disabled: "rgba(255, 255, 255, 0.5)",
    inactive: "rgba(255, 255, 255, 0.54)",
  },
  black: {
    highEmphasis: "rgba(44, 81, 100, 0.87)",
    mediumEmphasis: "rgba(44, 81, 100, 0.6)",
    disabledButton: "rgba(0, 0, 0, 0.12)",
    disabled: "rgba(33, 33, 33, 0.38)",
    inactive: "#2C516461",
    disabledImages: "rgba(200, 200, 200, 0.4)",
    shadow: "rgba(0, 0, 0, 0.14)",
    transparent: "rgba(0, 0, 0, 0)",
    separator: "rgba(0, 0, 0, 0.12)",
    strong: "#000000",
  },
  surface: {
    appUi: "#FBFBFB",
    background: "#E7F7FE",
    input: "#C0E8FE",
    textInput: "rgba(33, 33, 33, 0.05)",
    shadows: "#BBBBBB",
    card: "#F3F8FE",
    disabled: "#E0E0E0",
    progress: {
      border: "#169A9F",
      background: "#E6E6E6",
    },
    header: "#FFF",
    dialogs: "#FFF",
    drawers: "#FFF",
    chip: "#32352F",
    menu: "#F0F0F0",
    sheets: "#FAFAFA",
    sideSheets: "#FFF",
    snackbar: "#202020",
    textInputDisabled: "rgba(50, 53, 47, 0.38)",
  },
  overrides: {
    menu: undefined,
    switch: undefined,
    button: undefined,
    ripple: undefined,
    checkbox: undefined,
    listIcon: undefined,
    textInput: undefined,
    circleStep: undefined,
    iconButton: undefined,
    activityIndicator: undefined,
    tabBar: undefined,
    tabIndicator: undefined,
    snack: {
      text: undefined,
      background: undefined,
    },
  },
} as const

export const hexToRGB = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")"
  }
}

export default colors
