import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  Button,
  Caption,
  DateTimePicker,
  firestore,
  generateShadow,
  IRowItemProps,
  ISectionHeader,
  IUserContext,
  openUrl,
  ParametersScreen,
  useAlert,
  userContext,
  useTheme,
  VectorIcon,
  VectorIconProps,
} from "capsule"
import dayjs from "dayjs"
import { useFormikContext } from "formik"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Image,
  ImageSourcePropType,
  SectionListData,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native"

import { images } from "../../assets/images"
import { collections } from "../../common/types"
import SubmitButton from "../../components/SubmitButton"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import useItemI18n from "../../features/hooks/useItemI18n"
import usePermissions from "../../features/hooks/usePermissions"
import { OperationNS } from "../../features/i18n/constants"
import { usePhases } from "../../features/models/PhaseAndSessionFunctions"
import { AppUserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { programContext } from "../../features/Providers/ProgramProvider"
import crashlytics from "../../services/Analytics/crashlytics"
import { getUtcDate } from "../../utils/conversion"
import { OperationFormValues } from "./Constants"
import { OperationParamList } from "./OperationNavigator"

interface IProps {
  isEdit?: boolean
}

const OperationScreen: FC<IProps> = ({ isEdit = false }) => {
  const {
    fontMaker,
    colors: {
      accent,
      surface,
      white: { highEmphasis: white },
      black: { mediumEmphasis: black },
      primary,
    },
    dimensions: { spacing },
  } = useTheme()
  usePermissions(isEdit)
  const { t } = useTranslation(OperationNS)
  const navigation = useNavigation()
  const { showSnack } = useAlert()
  const {
    handleSubmit,
    values,
    validateForm,
    setFieldValue,
  } = useFormikContext<OperationFormValues>()
  const { userData, userDocRef } = useContext<IUserContext<AppUserData>>(userContext) // patient data
  const { phases, currentPhase } = useContext(programContext)
  const firstActivation = !userData?.pathology && !!values.pathology
  const startPhase = usePhases(values.surgeryDate, "asc", firstActivation, values.pathology)
  const endPhase = usePhases(values.surgeryDate, "desc", firstActivation, values.pathology)
  const currentDate = useMemo(() => getUtcDate(undefined, true), [])
  const pathologyName = useItemI18n(
    collections.PATHOLOGIES,
    (userData?.pathology as string) ?? values.pathology,
    "name",
  )

  const code = userData?.code || t("missing")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [initialStartPhaseId, setInitialStartPhaseId] = useState(startPhase?.id)

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    validateForm()
  }, [values, validateForm])

  useEffect(() => {
    if (isEdit && userData?.specialty) {
      setFieldValue("specialty", userData.specialty)
    }
  }, [isEdit, setFieldValue, userData?.specialty])

  const s = useMemo(
    () => ({
      header: {
        backgroundColor: surface.background,
        paddingVertical: spacing / 2,
      },
      row: [
        styles.row,
        {
          paddingTop: spacing,
          paddingLeft: spacing * 2,
        },
      ],
      rowDate: {
        paddingHorizontal: spacing,
        paddingVertical: spacing / 2,
        backgroundColor: white,
      },
      rowSubitem: [
        styles.row,
        styles.shadow,
        {
          paddingLeft: spacing * 2,
        },
      ],
      rowKine: [
        styles.shadow,
        {
          paddingHorizontal: spacing,
          paddingVertical: spacing / 2,
          backgroundColor: white,
        },
      ],
      rowPatInfo: [
        styles.shadow,
        {
          paddingHorizontal: spacing,
          paddingVertical: spacing / 2,
          backgroundColor: white,
        },
      ],
      body: [
        styles.body,
        {
          padding: spacing,
          backgroundColor: accent,
        },
      ],
      label: [styles.buttonLabel, { right: spacing / 2 }],
      bodyInfo: [
        styles.body,
        {
          padding: spacing,
          backgroundColor: accent,
        },
      ],
      icon: {
        tintColor: black,
      },
      submit: [
        styles.submit,
        {
          marginVertical: spacing / 2,
        },
      ],
      touchable: [styles.touchable, { backgroundColor: surface.textInput }],
      contactLink: [fontMaker({ weight: "Bold" })],
    }),
    [accent, black, spacing, surface.textInput, white, fontMaker, surface.background],
  )

  const renderIcon = useCallback(
    (name: string) =>
      ({
        name,
        category: "MaterialIcons",
        size: ICON_SIZE,
        color: black,
      } as VectorIconProps),
    [black],
  )

  const renderPathologyRightChild = useCallback(
    () => (
      <View style={styles.pathology}>
        {pathologyName ? (
          <Caption style={styles.caption} emphasis="medium">
            {pathologyName}
          </Caption>
        ) : null}
        {!isEdit ? <VectorIcon {...renderIcon("chevron-right")} /> : null}
      </View>
    ),
    [renderIcon, pathologyName, isEdit],
  )

  const onPress = useCallback(async () => {
    const subject = t(`${OperationNS}:contactTitle`)
    const body = t(`${OperationNS}:contactText`, {
      lastName: userData?.lastName,
      firstName: userData?.firstName,
      id: userDocRef?.id,
    })
    // noinspection ES6MissingAwait
    openUrl(
      `mailto:contact@doctup.fr?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body,
      )}`,
    )
    return
  }, [t, userData?.firstName, userData?.lastName, userDocRef?.id])

  // @ts-ignore
  const sections: Array<
    SectionListData<IRowItemProps<OperationParamList>, ISectionHeader>
  > = useMemo(
    () => [
      {
        shadows: 1,
        title: t("header"),
        headerStyle: s.header,
        data: [
          {
            rowStyle: s.row,
            name: (userData?.surgeonName as string) || t("missing"),
            labelStyle: styles.label,
            label: t("label.surgeon"),
            leftChild: (
              <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
            ),
          },
          {
            render: () => (
              <>
                {!userData?.surgeonName ? (
                  <View style={s.rowKine}>
                    <Body2 style={s.body}>{t("informatioSurgeonWithoutCode", { code })}</Body2>
                  </View>
                ) : null}
              </>
            ),
          },
          {
            name: "date",
            render: () => (
              <View style={s.rowDate}>
                <DateTimePicker
                  mode="date"
                  name="surgeryDate"
                  touchableStyle={s.touchable}
                  label={t("label.surgeryDate")}
                  initialValue={values.surgeryDate ?? currentDate}
                  noAdditionalValue
                  icons={{
                    left: renderIcon("calendar-today"),
                    right: renderIcon("arrow-drop-down"),
                  }}
                />
              </View>
            ),
          },
        ],
      },
      {
        data: [
          {
            rowStyle: s.rowSubitem,
            name: t("label.pathology"),
            route: isEdit ? undefined : "Organ",
            rightChild: renderPathologyRightChild(),
          },
        ],
        title: "",
      },
      {
        data: [
          {
            name: t("label.pathology"),
            render: () => (
              <View style={s.rowPatInfo}>
                {isEdit ? (
                  <View style={s.bodyInfo}>
                    <Body2>{t("pathologyEditInformation")}</Body2>
                    <Button
                      mode="text"
                      labelStyle={s.label}
                      onPress={onPress}
                      contentStyle={styles.buttonContent}
                    >
                      <Body2 style={s.contactLink} color={primary}>
                        {t("supportButton")}
                      </Body2>
                    </Button>
                  </View>
                ) : (
                  <Body2 style={s.bodyInfo}>{t("pathologyInformation")}</Body2>
                )}
              </View>
            ),
          },
        ],
        title: "",
      },
      {
        shadows: 1,
        data: [
          {
            rowStyle: s.rowSubitem,
            name: (userData?.kineName as string) || t("missing"),
            labelStyle: styles.label,
            label: t("label.kine"),
            leftChild: (
              <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
            ),
          },
          {
            render: () => (
              <>
                {!userData?.kineName ? (
                  <View style={s.rowKine}>
                    <Body2 style={s.body}>{t("informationWithoutCode", { code })}</Body2>
                  </View>
                ) : null}
              </>
            ),
          },
        ],
        title: "",
      },
    ],
    [
      isEdit,
      renderIcon,
      renderPathologyRightChild,
      s,
      t,
      userData?.surgeonName,
      userData?.kineName,
      onPress,
      primary,
      code,
      currentDate,
      values.surgeryDate,
    ],
  )
  const onSubmit = useCallback(async () => {
    if (!startPhase || !endPhase) {
      showSnack({ message: t("noProgram") })
      crashlytics.reportError(
        23,
        `Aucune phase trouvée, date d'opération ${values.surgeryDate}, pathologie: ${values.pathology} `,
      )
      return
    }
    setSubmitLoading(true)
    handleSubmit?.()
    const programStart = firestore.Timestamp.fromDate(
      dayjs(values.surgeryDate).add(startPhase.startDay, "day").toDate(),
    )
    const programEnd = firestore.Timestamp.fromDate(
      dayjs(values.surgeryDate).add(endPhase.endDay, "day").toDate(),
    )

    if (!isEdit) {
      const newUserProgramValue = {
        phase: startPhase.id,
        programEnd,
        programStart,
      }
      await updateUserData(userDocRef, newUserProgramValue)
    }

    // If surgeryDate is updated, the phase can be updated too
    // Creation account, the initialization cannot be done without alert values
    if (isEdit && userData) {
      // Réinitialiser les jours additionnels pour les phases après la nouvelle phase de départ si la phase change
      if (initialStartPhaseId !== startPhase.id && !(endPhase?.id === currentPhase?.id)) {
        if (phases) {
          const newAddedDays = { ...userData.addedDays }
          const sortedPhases = [...phases].sort((a, b) => a.startDay - b.startDay)
          let reset = false
          sortedPhases.forEach(phase => {
            if (phase.id === startPhase.id) {
              reset = true
            }
            if (reset && phase.id !== startPhase.id && newAddedDays[phase.id]) {
              newAddedDays[phase.id] = 0
            }
          })
          await updateUserData(userDocRef, { addedDays: newAddedDays })
        }
        setInitialStartPhaseId(startPhase.id)
      }
      await updateUserData(userDocRef, { programEnd, programStart, phase: startPhase.id })
      setSubmitLoading(false)
      navigation.goBack()
    }
  }, [
    endPhase,
    handleSubmit,
    isEdit,
    navigation,
    showSnack,
    startPhase,
    t,
    userData,
    values.pathology,
    values.surgeryDate,
    userDocRef,
    initialStartPhaseId,
    phases,
    currentPhase?.id,
  ])

  const renderFooterComponent = useCallback(
    () => (
      <SubmitButton<OperationFormValues>
        {...{ onSubmit }}
        syncLoading={submitLoading}
        viewStyle={s.submit}
      />
    ),
    [onSubmit, submitLoading, s.submit],
  )

  return (
    <ParametersScreen
      listKey="operationList"
      sections={sections}
      footerComponent={renderFooterComponent()}
    />
  )
}

const styles = StyleSheet.create({
  submit: {
    width: "100%",
  },
  row: {
    height: INPUT_HEIGHT,
  },
  label: {
    paddingBottom: 0,
  },
  touchable: {
    height: INPUT_HEIGHT,
    borderRadius: 0,
  },
  shadow: generateShadow(2) as ViewStyle,
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  pathology: {
    flexDirection: "row",
    alignItems: "center",
  },
  caption: {
    width: 150,
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
})

export default OperationScreen
