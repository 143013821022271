export * from "./firebase"

export const collections = {
  LOGIN: "login",
}

export {
  default as firebase,
  analytics,
  crashlytics,
  firestore,
  auth,
  functions,
  messaging,
} from "./firebase"
