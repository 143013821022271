import notifee from "@notifee/react-native"
import remoteConfig from "@react-native-firebase/remote-config"
import { PathConfigMap } from "@react-navigation/core"
import { createStackNavigator, StackNavigationOptions } from "@react-navigation/stack"
import {
  AlertProvider,
  delay,
  dynamicLinks,
  FirebaseDynamicLinksTypes,
  IUserContext,
  logger,
  useAlert,
  userContext,
  useTheme,
} from "capsule"
import _ from "lodash"
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { Linking, Platform, StyleSheet, Text, View } from "react-native"
import SplashScreen from "react-native-bootsplash"
import FastImage from "react-native-fast-image"
import { getLocales, getTimeZone } from "react-native-localize"

import { version } from "../../../package.json"
import { images } from "../../assets/images"
import { webAppUrl } from "../../common/types"
import RdvUrlView from "../../components/RdvUrl"
import ReviewUrlView from "../../components/ReviewUrlView"
import {
  CANCER_DATA_FORM,
  CHEMO_FORM,
  HORMONE_FORM,
  IMMUNO_FORM,
  METASTATIC_FORM,
  ONCO_SURGERY_FORM,
  OTHER_PATH_FORM,
  RADIO_FORM,
} from "../../features/config/Constants"
import { updateUserData } from "../../features/models/UserDataFunctions"
import ActivationCodeScreen from "../../screens/ActivationCodeScreen/ActivationCodeScreen"
import AddressScreen from "../../screens/AddressScreen/AddressScreen"
import ShareContactScreen from "../../screens/AddressScreen/ShareContact"
import AlertScreen from "../../screens/AlertScreen/AlertScreen"
import { alertFormikParams, AlertFormValues } from "../../screens/AlertScreen/Constants"
import CguScreen from "../../screens/CguScreen/CguScreen"
import CongratulationScreen from "../../screens/CongratulationScreen/CongratulationScreen"
import DeleteAccountScreen from "../../screens/DeleteAccountScreen/DeleteAccountScreen"
import { evaluationFormikParams } from "../../screens/EvaluationScreen/Constants"
import EvaluationScreen from "../../screens/EvaluationScreen/EvaluationScreen"
import ExerciseNavigator from "../../screens/ExerciseScreen/ExerciseNavigator"
import KineSpecialtiesScreen from "../../screens/KineSpecialtiesScreen/KineSpecialtiesScreen"
import { LanguageSelector } from "../../screens/LanguageScreen/Constants"
import LanguageScreen from "../../screens/LanguageScreen/LanguageScreen"
import MainNavigator, { screens as mainScreens } from "../../screens/MainScreen/MainNavigator"
import { oncoFormikParams, OncoFormValues } from "../../screens/OncoPathologyScreen/Constants"
import OncoPathologyNavigator, {
  screens as oncoPathologyScreens,
} from "../../screens/OncoPathologyScreen/OncoPathologyNavigator"
import OncoSettingsScreen from "../../screens/OncoSettingsScreen/OncoSettingsScreen"
import OncoSurgeriesScreen from "../../screens/OncoSurgeriesScreen/OncoSurgeriesScreen"
import { operationFormikParams, OperationFormValues } from "../../screens/OperationScreen/Constants"
import OperationNavigator, {
  screens as operationScreens,
} from "../../screens/OperationScreen/OperationNavigator"
import OperationScreen from "../../screens/OperationScreen/OperationScreen"
import OtherPathologiesScreen from "../../screens/OtherPathologiesScreen/OtherPathologiesScreen"
import ParametersEditionScreen from "../../screens/ParametersEditionScreen/ParametersEditionScreen"
import PathologiesListScreen from "../../screens/PathologiesListScreen/PathologiesListScreen"
import PhoneScreen from "../../screens/PhoneScreen/PhoneScreen"
import PractitionerProfileScreen from "../../screens/PractitionerProfileScreen/PractitionerProfileScreen"
import PractitionerScreen from "../../screens/PractitionerScreen/PractitionerScreen"
import { profileFormikParams, ProfileFormValues } from "../../screens/ProfileScreen/Constants"
import ProfileScreen from "../../screens/ProfileScreen/ProfileScreen"
import PromsNavigator, { screens as promsScreens } from "../../screens/PromsScreen/PromsNavigator"
import SmsValidationScreen from "../../screens/SMSValidationScreen/SMSValidationScreen"
import {
  surgeonPracticeFormikParams,
  SurgeonPracticeFormValues,
} from "../../screens/SurgeonPracticeScreen/Constants"
import SurgeonPracticeScreen from "../../screens/SurgeonPracticeScreen/SurgeonPracticeScreen"
import TherapiesScreen from "../../screens/TherapiesScreen/TherapiesScreen"
import VideoPresentationScreen from "../../screens/VideoPresentationScreen/VideoPresentationScreen"
import { maxWidth } from "../../ThemeApp"
import { BOTTOM_SIZE } from "../config/Constants"
import {
  ActivationCodeNS,
  AlertNS,
  NavigationNS,
  OperationNS,
  ProfileNS,
  VideoNS,
} from "../i18n/constants"
import { removePreviousNotification } from "../models/AlertFunctions"
import { EvalFormValues, EvalType } from "../models/Evaluation"
import { AppUserData } from "../models/UserData"
import { promsContext } from "../Providers/PromsProvider"
import { useRole } from "../Providers/RoleProvider"
import SessionProvider from "../Providers/SessionProvider"
import { FormikParams, FormikScreen } from "./FormikScreen"
import MyWelcomeScreen from "./MyWelcomeScreen"
import { hideHeader } from "./NavigationService"
import { OnboardingParamList, RootParamList } from "./types"

export const screens: PathConfigMap<RootParamList> | PathConfigMap<OnboardingParamList> = {
  MainNavigator: { screens: mainScreens },
  Welcome: "welcome",
  Cgu: "cgu",
  DeleteAccount: "deleteAccount",
  ResearchData: "researchData",
  ActivationCode: "activation",
  Phone: "phone",
  SMSValidation: "sms",
  Specialty: "specialty",
  OperationNavigator: { screens: operationScreens, path: "surgery" },
  OncoPathologyNavigator: { screens: oncoPathologyScreens, path: "OncoPathology" },
  Profile: "profile",
  Alert: "alert",
  Practitioner: "pract",
  PractitionerProfile: "pract_profile",
  AddPatient: "add_patient",
  PractitionerProfileEdition: "profile_edit",
  PromsNavigator: { screens: promsScreens },
  ReviewUrl: "review_url",
  RdvUrl: "rdv_url",
}

export type OnboardingRouteNames = keyof OnboardingParamList
// noinspection JSUnusedGlobalSymbols
export type MainRouteNames = keyof RootParamList

export const onboardingNavigator = createStackNavigator<OnboardingParamList>()
export const rootNavigator = createStackNavigator<RootParamList>()

const oneHourMillis = 60 * 60 * 1000
const minimumFetchInterval = 6 * oneHourMillis

const compareVersions = (currentVersion: string, newVersion: string) => {
  const currentParts = currentVersion.split(".").map(Number)
  const newParts = newVersion.split(".").map(Number)
  for (let i = 0; i < newParts.length; i++) {
    if (currentParts[i] < newParts[i]) {
      return true
    }
    if (currentParts[i] > newParts[i]) {
      return false
    }
  }
  return false
}

const RootNavigator: FC = () => {
  const {
    colors: {
      black: { highEmphasis },
      surface: { background },
    },
    typography: { h6 },
  } = useTheme()

  const { user, userData, userDocRef, initializing, userDataLoading, logout } = useContext<
    IUserContext<AppUserData>
  >(userContext)
  const { showSnack, showDialog } = useAlert()
  const { form, loadForm, setIsFromLink, isFromLink, loadSigninForm, loading } = useContext(
    promsContext,
  )
  const { role } = useRole()
  const isPatient = userData?.role === "patient"
  const isOnco = useMemo(() => userData?.specialty === "onco", [userData?.specialty])
  const isOrthoOrImplant = useMemo(
    () => userData?.specialty === "ortho" || userData?.specialty === "implant",
    [userData?.specialty],
  )
  const supportedLanguages = LanguageSelector.map(lang => lang.languageCode)
  const deviceLanguage = getLocales()[0].languageCode
  const fallbackLanguage = "fr"
  const effectiveLanguage = supportedLanguages.includes(deviceLanguage)
    ? deviceLanguage
    : fallbackLanguage
  const appStoreLink = "https://apps.apple.com/app/id1574145501"
  const playStoreLink = "https://play.google.com/store/apps/details?id=com.siruplab.doctup"
  const storeLink = Platform.OS === "ios" ? appStoreLink : playStoreLink

  const { t } = useTranslation()
  const renderFormattedText = text => {
    const formattedText = text.replace(/\\n/g, "\n")
    const textParts = formattedText.split(/(\[b\]|\[\/b\])/g)
    return textParts.map((textPart, index) => {
      if (textPart === "[b]") {
        return null
      } else if (textPart === "[/b]") {
        return null
      } else {
        const isBold = index > 0 && textParts[index - 1] === "[b]"
        return (
          <Text key={index} style={isBold ? { fontWeight: "bold" } : {}}>
            {textPart}
          </Text>
        )
      }
    })
  }

  const checkForUpdates = useCallback(async () => {
    try {
      await remoteConfig().setDefaults({
        latest_version: version,
        force_update: false,
      })
      await remoteConfig().setConfigSettings({
        minimumFetchIntervalMillis: minimumFetchInterval,
      })

      const activated = await remoteConfig().fetchAndActivate()
      const latestVersion = remoteConfig().getString("latest_version")
      const forceUpdate = remoteConfig().getBoolean("force_update")
      const releaseInformation = remoteConfig().getString(
        userData?.lng
          ? `release_information_${userData?.lng}`
          : `release_information_${effectiveLanguage}`,
      )

      if (
        activated &&
        compareVersions(version, latestVersion) &&
        forceUpdate &&
        userData !== undefined &&
        userData.showUpdateModal !== false
      ) {
        showDialog({
          type: "button",
          title: t("common:upgrade.title"),
          message: renderFormattedText(releaseInformation),
          dismissable: false,
          positive: {
            onPress: async () => {
              Linking.openURL(storeLink)
              updateUserData(userDocRef, {
                showUpdateModal: false,
              })
            },
            label: t("common:upgrade.button"),
          },
        })
      } else if (
        latestVersion &&
        version === latestVersion &&
        userData !== undefined &&
        userData.showUpdateModal === false
      ) {
        updateUserData(userDocRef, {
          showUpdateModal: true,
        })
      }
    } catch (error) {
      logger("Error checking for updates:", error)
    }
  }, [userData, effectiveLanguage, showDialog, t, userDocRef, storeLink])

  useEffect(() => {
    if (user && !userDataLoading && userData !== undefined && Platform.OS !== "web") {
      checkForUpdates()
    }
  }, [user, userDataLoading, userData, checkForUpdates])

  useEffect(() => {
    const updateTimezoneIfNeeded = () => {
      const currentTimezone = getTimeZone()
      if (userData && (!userData.timezone || userData.timezone !== currentTimezone)) {
        updateUserData(userDocRef, {
          timezone: currentTimezone,
        })
      }
    }
    updateTimezoneIfNeeded()
    const intervalId = setInterval(() => {
      updateTimezoneIfNeeded()
    }, 60000)
    return () => clearInterval(intervalId)
  }, [userData, userDocRef])

  const [waitingInitialLink, setWaitingInitialLink] = useState(true)
  const rootOptions = useMemo<StackNavigationOptions>(
    () => ({
      gestureEnabled: false,
      headerBackTitleVisible: false,
      headerTitleStyle: { ...h6, color: highEmphasis },
      headerTintColor: highEmphasis,
      headerStyle: [styles.header, { backgroundColor: background }],
      headerTitleAlign: "left",
    }),
    [h6, highEmphasis, background],
  )
  const handleDynamicLink = useCallback(
    async (link: FirebaseDynamicLinksTypes.DynamicLink | null) => {
      if (_.startsWith(link?.url, webAppUrl)) {
        const data = _.split(link?.url, "/")
        const formId = data[data.length - 2]
        const triggerDay = parseInt(data[data.length - 4])
        const token = _.replace(_.last(data) ?? "", "?auth=", "")
        setIsFromLink(true)
        await loadForm?.({ id: formId, token, triggerDay })
      }
      setWaitingInitialLink(false)
    },
    [loadForm, setIsFromLink],
  )

  // Check if web url include a PROMS id
  const handleWebUrl = useCallback((url: string | null) => {
    if (url && _.includes(url, "FOR")) {
      setIsFromLink(true)
      // noinspection JSIgnoredPromiseFromCall
      handleDynamicLink({ url, minimumAppVersion: null, utmParameters: {} })
    }
    // warning do not add handleDynamicLink as dependencies
    // eslint-disable-next-line
  }, [])

  const launchDynamicLink = async () => {
    const unsubscribe = dynamicLinks().onLink(handleDynamicLink)
    dynamicLinks().getInitialLink().then(handleDynamicLink)
    return unsubscribe
  }

  // Event dynamic link
  useEffect(() => {
    if (Platform.OS !== "web") {
      ;(async () => {
        await Promise.race([
          new Promise(resolve => {
            launchDynamicLink()
            resolve(undefined)
          }),
          new Promise(async resolve => {
            await delay(2000)
            setWaitingInitialLink(false)
            resolve(undefined)
          }),
        ])
      })()
      return
    }
    Linking.getInitialURL()
      .then(handleWebUrl)
      .catch(e => logger("web url error", e))
    return undefined
    // warning do not add handleDynamicLink as dependencies
    // eslint-disable-next-line
  }, [])

  const getSigninForm = useCallback(
    async (id: string) => {
      await loadSigninForm?.({ id })
    },
    [loadSigninForm],
  )

  useEffect(() => {
    if (!initializing && !userDataLoading && !waitingInitialLink) {
      logger("hiding splash screen")
      setTimeout(() => {
        // noinspection JSIgnoredPromiseFromCall
        SplashScreen.hide()
      }, 100)
    }
  }, [initializing, userDataLoading, waitingInitialLink])

  // Local notifications
  useEffect(() =>
    notifee.onBackgroundEvent(async ({ type, detail }) => {
      await removePreviousNotification(type, detail)
    }),
  )
  useEffect(
    () =>
      notifee.onForegroundEvent(async ({ type, detail }) => {
        await removePreviousNotification(type, detail)
      }),
    [],
  )

  const navOnboardingSwitcher = useMemo(() => {
    /** SIGN IN FLOW */
    if (form || isFromLink || loading) {
      return (
        <rootNavigator.Screen
          name="PromsNavigator"
          options={hideHeader}
          component={PromsNavigator}
        />
      )
    }

    if (userData?.disabled) {
      logout()
      showSnack({
        message: t(`${NavigationNS}:DeleteAccount:confirmationDeletion`),
      })
    }

    if (!user || !role || role === "practitioner") {
      return (
        <>
          <onboardingNavigator.Screen
            name="Welcome"
            options={hideHeader}
            component={MyWelcomeScreen}
          />
          <onboardingNavigator.Screen
            name="Cgu"
            options={{
              ...hideHeader,
            }}
          >
            {() => <CguScreen isCgu />}
          </onboardingNavigator.Screen>
          <onboardingNavigator.Screen
            name="ResearchData"
            component={CguScreen}
            options={{ title: "" }}
          />
          <onboardingNavigator.Screen
            name="Phone"
            component={PhoneScreen}
            options={{
              title: t(`${NavigationNS}:Phone:title`),
            }}
          />
          <onboardingNavigator.Screen
            name="SMSValidation"
            component={SmsValidationScreen}
            options={{
              title: t(`${NavigationNS}:SMSValidation:title`),
            }}
          />
          <onboardingNavigator.Screen
            name="Practitioner"
            options={{
              title: t(`${NavigationNS}:Practitioner:title`),
            }}
            component={PractitionerScreen}
          />
        </>
      )
    }

    if ((isPatient && !userData?.code) || (!isPatient && !userData?.firstActivation)) {
      return (
        <onboardingNavigator.Screen
          name="ActivationCode"
          component={ActivationCodeScreen}
          options={{
            title: t(`${ActivationCodeNS}:title`),
          }}
        />
      )
    }

    if (isPatient) {
      if (userData?.specialty === "implant" && !userData?.lng) {
        return (
          <onboardingNavigator.Screen
            name="Language"
            options={hideHeader}
            component={LanguageScreen}
          />
        )
      }
      if (!userData?.pathology) {
        return (
          <>
            {isOnco ? (
              <onboardingNavigator.Screen name="OncoPathologyNavigator" options={hideHeader}>
                {props => (
                  <FormikScreen<OncoFormValues>
                    {...props}
                    component={OncoPathologyNavigator}
                    formikParams={oncoFormikParams(t, OperationNS)}
                  />
                )}
              </onboardingNavigator.Screen>
            ) : (
              <onboardingNavigator.Screen name="OperationNavigator" options={hideHeader}>
                {props => (
                  <FormikScreen<OperationFormValues>
                    {...props}
                    component={OperationNavigator}
                    formikParams={operationFormikParams(t, OperationNS)}
                  />
                )}
              </onboardingNavigator.Screen>
            )}
          </>
        )
      }
      if (!userData?.lastName) {
        return (
          <onboardingNavigator.Screen
            name="Profile"
            options={{
              title: t(`${NavigationNS}:Profile:title`),
            }}
          >
            {props => (
              <FormikScreen<ProfileFormValues>
                {...props}
                component={ProfileScreen}
                formikParams={profileFormikParams(t, ProfileNS)}
              />
            )}
          </onboardingNavigator.Screen>
        )
      }

      const medicalInfo = userData?.medicalInfo
      if (isOnco) {
        if (!medicalInfo?.cancerData) {
          getSigninForm(CANCER_DATA_FORM)
        } else if (!medicalInfo?.surgery) {
          getSigninForm(ONCO_SURGERY_FORM)
        } else if (!medicalInfo?.chemoTh) {
          getSigninForm(CHEMO_FORM)
        } else if (!medicalInfo?.radioTh) {
          getSigninForm(RADIO_FORM)
        } else if (!medicalInfo?.hormoneTh) {
          getSigninForm(HORMONE_FORM)
        } else if (!medicalInfo?.immunoTh) {
          getSigninForm(IMMUNO_FORM)
        } else if (!medicalInfo?.metastatic) {
          getSigninForm(METASTATIC_FORM)
        } else if (!medicalInfo?.otherPathologies) {
          getSigninForm(OTHER_PATH_FORM)
        }
      }

      if (
        (isOrthoOrImplant && !userData?.alertDays) ||
        (isOnco && medicalInfo?.otherPathologies && !userData?.alertDays)
      ) {
        return (
          <onboardingNavigator.Screen
            name="Alert"
            options={{
              title: t(`${NavigationNS}:Alert:title`),
            }}
          >
            {props => (
              <FormikScreen<AlertFormValues>
                {...props}
                component={AlertScreen}
                formikParams={{
                  validateOnMount: true,
                  ...alertFormikParams(t, AlertNS, userData?.specialty),
                }}
              />
            )}
          </onboardingNavigator.Screen>
        )
      }
      if (!userData?.videoWatched) {
        return (
          <onboardingNavigator.Screen
            name="Video"
            component={VideoPresentationScreen}
            options={{
              title: t(`${VideoNS}:title`),
            }}
          />
        )
      }
    }

    if (!isPatient) {
      if (userData?.specialty === "implant" && !userData?.lng) {
        return (
          <onboardingNavigator.Screen
            name="Language"
            options={hideHeader}
            component={LanguageScreen}
          />
        )
      }
      if (!userData?.lastName || !userData?.email) {
        return (
          <onboardingNavigator.Screen
            name="PractitionerProfile"
            options={{
              title: t(`${NavigationNS}:Profile:title`),
            }}
          >
            {props => (
              // @ts-ignore
              <FormikScreen<ProfileFormValues>
                {...props}
                component={PractitionerProfileScreen}
                formikParams={profileFormikParams(t, ProfileNS)}
              />
            )}
          </onboardingNavigator.Screen>
        )
      }

      if (!userData?.surgeonPractice && userData?.role === "surgeon") {
        return (
          <onboardingNavigator.Screen
            name="SurgeonPractice"
            options={{
              title: t(`${NavigationNS}:SurgeonPractice:title`),
            }}
          >
            {props => (
              // @ts-ignore
              <FormikScreen<SurgeonPracticeFormValues>
                {...props}
                component={SurgeonPracticeScreen}
                formikParams={surgeonPracticeFormikParams(t, ProfileNS, userData)}
              />
            )}
          </onboardingNavigator.Screen>
        )
      }
      if (userData?.role === "kine") {
        if (userData?.shareContact === undefined) {
          return (
            <onboardingNavigator.Screen
              name="ShareContact"
              options={{
                title: t(`${NavigationNS}:ShareContact:title`),
              }}
              component={ShareContactScreen}
            />
          )
        }
        if (userData?.shareContact === true && !userData?.address) {
          return (
            <onboardingNavigator.Screen
              name="Address"
              component={AddressScreen}
              options={{
                title: t(`${NavigationNS}:Address:title`),
              }}
            />
          )
        }
        if (userData?.address && !userData.kineSpecialties) {
          return (
            <onboardingNavigator.Screen
              options={{
                title: t(`${NavigationNS}:KineSpecialties.title`),
              }}
              name="KineSpecialties"
              component={KineSpecialtiesScreen}
            />
          )
        }
        if (userData?.address && !userData.rdvLink && userData.rdvLink !== "") {
          return (
            <onboardingNavigator.Screen
              options={{
                title: t(`${NavigationNS}:RdvUrl.title`),
              }}
              name="RdvUrl"
              component={RdvUrlView}
            />
          )
        }
      }
    }

    return null
  }, [
    isFromLink,
    loading,
    form,
    isPatient,
    isOrthoOrImplant,
    isOnco,
    role,
    t,
    user,
    userData,
    logout,
    showSnack,
    getSigninForm,
  ])

  const navSwitcher = useMemo(
    () =>
      !user ||
      (isPatient && (!userData?.code || !userData?.pathology || !userData.lastName)) ? null : (
        <>
          {/** USER FLOW */}
          {Platform.OS === "web" && isFromLink ? (
            <rootNavigator.Screen
              name="PromsNavigator"
              options={hideHeader}
              component={PromsNavigator}
            />
          ) : null}
          <rootNavigator.Screen name="MainNavigator" options={hideHeader}>
            {() => (
              <AlertProvider hasBottomNav bottomHeight={BOTTOM_SIZE}>
                <MainNavigator />
              </AlertProvider>
            )}
          </rootNavigator.Screen>

          <rootNavigator.Screen options={hideHeader} name="ExerciseNavigator">
            {() => (
              <SessionProvider>
                <ExerciseNavigator />
              </SessionProvider>
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen name="EveningEvaluation" options={hideHeader}>
            {props => (
              <FormikScreen<EvalFormValues>
                {...props}
                screenParams={{ evalType: EvalType.EVENING }}
                component={EvaluationScreen}
                formikParams={
                  evaluationFormikParams(
                    EvalType.EVENING,
                    userData?.specialty,
                  ) as FormikParams<EvalFormValues>
                }
              />
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen name="MorningEvaluation" options={hideHeader}>
            {props => (
              <FormikScreen<EvalFormValues>
                {...props}
                screenParams={{ evalType: EvalType.MORNING }}
                component={EvaluationScreen}
                formikParams={
                  evaluationFormikParams(
                    EvalType.MORNING,
                    userData?.specialty,
                  ) as FormikParams<EvalFormValues>
                }
              />
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name="Congratulation"
            options={hideHeader}
            component={CongratulationScreen}
          />
          <rootNavigator.Screen
            name="AddPatient"
            options={{
              title: t(`${ActivationCodeNS}:title`),
            }}
          >
            {() => <ActivationCodeScreen addPatient />}
          </rootNavigator.Screen>
          {/** EDITION FLOW */}
          <rootNavigator.Screen
            name="PhoneEdition"
            options={{
              title: t(`${NavigationNS}:Phone:title`),
            }}
          >
            {() => <PhoneScreen isEdit />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name="LanguageEdition"
            options={{
              title: t(`${NavigationNS}:Language:title`),
            }}
          >
            {() => <LanguageScreen isEdit />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name="SMSValidationEdition"
            options={{
              title: t(`${NavigationNS}:SMSValidation:title`),
            }}
          >
            {props => <SmsValidationScreen isEdit {...props} />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name="OperationEdition"
            options={{
              title: t(`${NavigationNS}:Operation:title`),
            }}
          >
            {props => (
              <FormikScreen<OperationFormValues>
                {...props}
                component={OperationScreen}
                isEdit
                formikParams={operationFormikParams(t, OperationNS, userData)}
              />
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:Operation:title`),
            }}
            name="OncoDataEdition"
          >
            {() => <OncoSettingsScreen />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:Operation:surgeries_title`),
            }}
            name="OncoSurgeries"
          >
            {() => <OncoSurgeriesScreen />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:Operation:therapies`),
            }}
            name="Therapies"
          >
            {() => <TherapiesScreen />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:Operation:other_pathologies`),
            }}
            name="OtherPathologies"
          >
            {() => <OtherPathologiesScreen />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:Operation:heart_disease`),
            }}
            name="HeartDisease"
          >
            {() => <PathologiesListScreen isHeartDisease />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:Operation:respiratory_disease`),
            }}
            name="RespiratoryDisease"
          >
            {() => <PathologiesListScreen />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name="ProfileEdition"
            options={{
              title: t(`${NavigationNS}:Profile:title`),
            }}
          >
            {props => (
              <FormikScreen<ProfileFormValues>
                {...props}
                isEdit
                component={ProfileScreen}
                formikParams={profileFormikParams(t, ProfileNS, userData)}
              />
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name="AlertEdition"
            options={{
              title: t(`${NavigationNS}:Alert:title`),
            }}
          >
            {props => (
              <FormikScreen<AlertFormValues>
                {...props}
                isEdit
                component={AlertScreen}
                formikParams={alertFormikParams(t, AlertNS, userData?.specialty, userData)}
              />
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:ReviewUrl.title`),
            }}
            name="ReviewUrl"
            component={ReviewUrlView}
          />
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:Parameters.title`),
            }}
            name="ParametersEdition"
            component={ParametersEditionScreen}
          />
          <rootNavigator.Screen
            name="SurgeonPracticeEdition"
            options={{
              title: t(`${NavigationNS}:SurgeonPractice.title`),
            }}
          >
            {props => (
              <FormikScreen<SurgeonPracticeFormValues>
                {...props}
                isEdit
                component={SurgeonPracticeScreen}
                formikParams={surgeonPracticeFormikParams(t, ProfileNS, userData)}
              />
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name="PractitionerProfileEdition"
            options={{
              title: t(`${NavigationNS}:Profile.title`),
            }}
          >
            {props => <PractitionerProfileScreen {...props} isEdit />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={hideHeader}
            name="PromsNavigator"
            component={PromsNavigator}
          />
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:Research:title`),
            }}
            name="Research"
          >
            {() => <CguScreen isAccount />}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            options={{
              title: t(`${NavigationNS}:DeleteAccount:title`),
            }}
            name="DeleteAccount"
            component={DeleteAccountScreen}
          />
        </>
      ),
    [isPatient, isFromLink, t, user, userData],
  )

  return initializing || userDataLoading ? null : (
    <rootNavigator.Navigator initialRouteName="Onboarding" screenOptions={rootOptions}>
      {navOnboardingSwitcher ? (
        <rootNavigator.Screen name="Onboarding" options={hideHeader}>
          {() => <Onboarding screenOptions={rootOptions}>{navOnboardingSwitcher}</Onboarding>}
        </rootNavigator.Screen>
      ) : (
        navSwitcher
      )}
    </rootNavigator.Navigator>
  )
}

const Onboarding: FC<PropsWithChildren<{ screenOptions: StackNavigationOptions }>> = ({
  children,
  screenOptions,
}) => (
  <View style={styles.container}>
    {Platform.OS === "web" ? (
      <>
        <View style={{ width: maxWidth }}>
          <onboardingNavigator.Navigator screenOptions={screenOptions}>
            {children}
          </onboardingNavigator.Navigator>
        </View>
        <FastImage
          source={images.right_placeholder as number}
          style={styles.image}
          resizeMode="cover"
        />
      </>
    ) : (
      <onboardingNavigator.Navigator screenOptions={screenOptions}>
        {children}
      </onboardingNavigator.Navigator>
    )}
  </View>
)

const styles = StyleSheet.create({
  header: {
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 0,
  },
  container: { flexDirection: "row", width: "100%", height: "100%" },
  image: { flex: 1, height: "100%" },
})

export default RootNavigator
