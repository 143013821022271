import { ParamListBase } from "@react-navigation/core"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import { useTheme } from "capsule"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { FaqNS } from "../../features/i18n/constants"
import FaqScreen from "./FaqScreen"

export interface TabParamList extends ParamListBase {
  FaqTab_before: { when: string }
  FaqTab_after: { when: string }
}
const faqTopTabNavigator = createMaterialTopTabNavigator()

const FaqNavigator: FC = () => {
  const { t } = useTranslation(FaqNS)
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
    },
  } = useTheme()

  const screenOptions = useMemo(
    () => ({
      tabBarStyle: { backgroundColor: primary },
      tabBarActiveTintColor: white,
      tabBarIndicatorStyle: { backgroundColor: white },
    }),
    [primary, white],
  )

  return (
    <faqTopTabNavigator.Navigator initialRouteName="FaqTab_before" {...{ screenOptions }}>
      <faqTopTabNavigator.Screen
        name="FaqTab_before"
        component={FaqScreen}
        options={{ title: t(`tabs.before`) }}
        initialParams={{ when: "before" }}
      />
      <faqTopTabNavigator.Screen
        name="FaqTab_after"
        component={FaqScreen}
        options={{ title: t(`tabs.after`) }}
        initialParams={{ when: "after" }}
      />
    </faqTopTabNavigator.Navigator>
  )
}

export default FaqNavigator
