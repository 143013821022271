import { logger, setExtraLogger, setLoggerEnabled } from "capsule"

import { config } from "../../../package.json"

let Shake

const start = () => {
  try {
    const pack = require("@shakebugs/react-native-shake")
    Shake = pack.default

    setLoggerEnabled(true)
    if (__DEV__) {
      // because shaking in dev mode invokes the debug UI
      Shake.setInvokedByScreenshot(true)
    }

    Shake.setConsoleLogsEnabled(true)
    Shake.setShowIntroMessage(true)
    Shake.setAutoVideoRecording(false)
    Shake.setEnableBlackBox(false)
    Shake.start(config.shake.clientId, config.shake.clientSecret)

    setExtraLogger((message, ...optionalParams) => {
      let params
      try {
        params = JSON.stringify(optionalParams)
      } catch {
        params = `${optionalParams}`
      }
      Shake.log(pack.LogLevel.INFO, `${message} ${params}`)
    })

    logger("Shake started")
  } catch (e) {
    logger("Shake not installed", e)
  }
}

export const setMetadata = (key: string, value: string) => {
  try {
    Shake?.setMetadata?.(key, value)
  } catch (e) {
    logger("Shake not installed", e)
  }
}

export default start
