import { logger } from "./logger"

const getOrStoreFile = async (filename: string, url: string): Promise<string | null> => {
  try {
    const AsyncStorage = require("@react-native-async-storage/async-storage")

    const prev = await AsyncStorage.getItem(filename)

    if (prev) {
      return prev
    } else {
      await AsyncStorage.setItem(filename, url)
      const res = await AsyncStorage.getItem(filename)
      if (res) {
        return res
      } else {
        return url
      }
    }
  } catch (e) {
    logger("@react-native-async-storage/async-storage was not linked !")
    return null
  }
}

export default getOrStoreFile
