import { FormikConfig, FormikValues } from "formik"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, TextInput as NativeInput, TextStyle, ViewStyle } from "react-native"
import { TextInput } from "react-native-paper"
import * as Yup from "yup"

import useTheme from "../../features/Theme/ThemeProvider"
import { logger } from "../../utils/logger"
import { PhoneNumberInput } from "../PhoneNumberInput"
import { InputScreen } from "./InputScreen"

export interface PhoneNumberForm {
  phoneNumber: string
}

export interface CountriesList {
  code: string
  title: string
  flag: string
  callingCode: string
}

interface IProps<Values> {
  formikConfig?: FormikConfig<Values>
  name?: string
  countries: CountriesList[]
  inputStyle?: StyleProp<ViewStyle>
  langageBtnStyle?: StyleProp<ViewStyle>
  dropdownBtnStyle?: StyleProp<ViewStyle>
  phoneInputContainer?: StyleProp<ViewStyle>
  buttonStyle?: {
    style: StyleProp<ViewStyle>
    contentStyle: StyleProp<ViewStyle>
    labelStyle: StyleProp<TextStyle>
  }
}
export const PhoneNumberFormikConfig = <Values extends FormikValues>(
  onSubmit?: (args: Values) => void,
) =>
  ({
    validateOnMount: true,
    onSubmit: onSubmit || logger,
    initialValues: { phoneNumber: "" },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().required(),
    }),
  } as FormikConfig<PhoneNumberForm>)

export const PhoneNumberScreen = <Values extends FormikValues>({
  formikConfig,
  name,
  countries,
  inputStyle,
  langageBtnStyle,
  dropdownBtnStyle,
  phoneInputContainer,
  buttonStyle,
}: IProps<Values>) => {
  const {
    colors: {
      black: { inactive: black },
    },
  } = useTheme()
  const { t } = useTranslation()
  const forwardRef = useRef<NativeInput>(null)

  useEffect(() => {
    forwardRef?.current?.focus()
  }, [])

  return (
    <InputScreen<Values>
      Inputs={
        <PhoneNumberInput
          changeUnderline={false}
          {...{
            name,
            inputStyle,
            langageBtnStyle,
            dropdownBtnStyle,
            phoneInputContainer,
            forwardRef,
          }}
          countries={countries}
          label={t("PhoneNumberScreen.label")}
          left={<TextInput.Icon icon="phone" color={black} />}
        />
      }
      buttonLabel={t("button.save")}
      caption={t("PhoneNumberScreen.caption")}
      formikConfig={formikConfig}
      {...{ buttonStyle }}
    />
  )
}
