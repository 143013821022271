import useKeyboard from "@rnhooks/keyboard"
import {
  alertContext,
  ButtonType,
  firestore,
  IDialogInternal,
  logger,
  Subtitle1,
  TextInput,
  TextInputDialog,
  Touchable,
  userContext,
  useTabLand,
  useTheme,
  VectorIcon,
} from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, ScrollView, StyleSheet, TextInput as NativeInput, View } from "react-native"
import { Menu } from "react-native-paper"

import { PatientNS, PatientsListNS } from "../features/i18n/constants"
import { messageContext } from "../features/Providers/MessageProvider"

interface IProps {
  patientID: string
}

type Content =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly"

const MessageModal: FC<IProps> = ({ patientID }) => {
  const {
    colors: {
      black: { mediumEmphasis, highEmphasis, disabled },
      surface: { textInput },
      danger,
      primary,
      error,
    },
    dimensions: { spacing },
    typography: { subtitle2 },
    screenStyle,
  } = useTheme()

  const { t } = useTranslation()
  const { showSnack } = useContext(alertContext)
  const { isMessageModalVisible, setIsMessageModalVisible, sendMessage } = useContext(
    messageContext,
  )
  const { userData } = useContext(userContext)
  const [visible, dismiss] = useKeyboard()
  const isTabLand = useTabLand()
  const inputRef = useRef<NativeInput>(null)
  const [textMessage, setTextMessage] = useState("")
  const [alertSelection, setAlertSelection] = useState(0)
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const isValid = !_.isEmpty(textMessage)
  const onChangeText = useCallback(text => setTextMessage(text), [])
  const closeModal = useCallback(() => {
    setIsMessageModalVisible(false)
    setTextMessage("")
    setAlertSelection(0)
  }, [setIsMessageModalVisible])
  const onMenuDismiss = useCallback(() => setIsMenuVisible(false), [])
  const onMenuPress = useCallback(() => {
    dismiss()
    inputRef?.current?.blur()
    setTimeout(() => setIsMenuVisible(true), 50)
  }, [dismiss])
  const onSubmit = useCallback(() => {
    sendMessage(patientID, {
      text: textMessage,
      authorName: t(`${PatientsListNS}:name`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        ns: PatientNS,
        interpolation: { escapeValue: false },
      }),
      timestamp: firestore.Timestamp.now(),
      alert: alertSelection !== 0 ? t(`${PatientNS}:alerts.${alertSelection}.title`) : "",
    })
      .then(closeModal)
      .catch(e => {
        logger("Sending message error", e.message)
        showSnack({ message: t(`${PatientNS}:errors.send_message`) })
      })
  }, [
    alertSelection,
    closeModal,
    patientID,
    sendMessage,
    showSnack,
    t,
    textMessage,
    userData.firstName,
    userData.lastName,
  ])
  const onAlertPress = useCallback(
    item => () => {
      setAlertSelection(item)
      setIsMenuVisible(false)
    },
    [],
  )
  const s = useMemo(
    () => ({
      textInput: [
        styles.textInput,
        {
          borderRadius: spacing / 2,
          borderTopLeftRadius: spacing / 2,
          borderTopRightRadius: spacing / 2,
        },
      ],
      mainContainer: {
        justifyContent: (visible && (!isTabLand || Platform.OS === "web")
          ? "flex-start"
          : "center") as Content,
        padding: spacing,
      },
      modalContainer: [
        styles.modalContainer,
        screenStyle,
        {
          padding: spacing,
        },
      ],
      alertSelection: [
        {
          color: alertSelection !== 0 ? danger : mediumEmphasis,
          marginRight: spacing / 2,
          fontSize: subtitle2.fontSize,
        },
        styles.alertSelection,
      ],
      alertContainer: [
        styles.alertContainer,
        {
          backgroundColor: textInput,
          borderRadius: spacing / 2,
          marginVertical: spacing,
        },
      ],
      alertOptions: {
        color: mediumEmphasis,
      },
      leftIcon: {
        marginRight: spacing / 2,
        color: alertSelection !== 0 ? error : disabled,
      },
      alertBar: [
        styles.alertBar,
        {
          padding: spacing,
        },
      ],
      dialogLeftButtonLabel: {
        color: mediumEmphasis,
      },
      dialogRightButtonLabel: {
        color: isValid ? primary : disabled,
      },
      title: [
        styles.title,
        {
          marginTop: spacing / 2,
        },
      ],
      menu: [
        styles.menu,
        {
          paddingTop: spacing,
        },
      ],
      alertSelectionColor: alertSelection !== 0 ? danger : mediumEmphasis,
    }),
    [
      spacing,
      visible,
      isTabLand,
      screenStyle,
      alertSelection,
      danger,
      mediumEmphasis,
      textInput,
      error,
      disabled,
      isValid,
      primary,
      subtitle2,
    ],
  )

  const menuItems = useMemo(
    () => (
      <ScrollView>
        {_.map(
          t(`${PatientNS}:alerts`, { returnObjects: true }),
          (i: { key: number; title: string }) => (
            <Menu.Item
              title={i.title}
              key={i.key}
              onPress={onAlertPress(i.key)}
              titleStyle={s.alertOptions}
            />
          ),
        )}
      </ScrollView>
    ),
    [onAlertPress, s.alertOptions, t],
  )

  const anchor = useMemo(
    () => (
      <>
        <Touchable style={s.alertContainer} onPress={onMenuPress} rippleColor={primary}>
          <View style={s.alertBar}>
            <VectorIcon
              name="warning"
              category="MaterialIcons"
              color={s.alertSelectionColor}
              style={s.leftIcon}
            />
            <View style={styles.alertSelectionContainer}>
              <Subtitle1 style={s.alertSelection}>
                {t(`${PatientNS}:alerts.${alertSelection}.title`)}
              </Subtitle1>
            </View>
            <VectorIcon
              name="arrow-drop-down"
              category="MaterialIcons"
              color={highEmphasis}
              style={styles.rightIcon}
            />
          </View>
        </Touchable>
      </>
    ),
    [
      alertSelection,
      highEmphasis,
      onMenuPress,
      primary,
      s.alertBar,
      s.alertContainer,
      s.alertSelection,
      s.alertSelectionColor,
      s.leftIcon,
      t,
    ],
  )

  const children = useMemo(
    () => (
      <View>
        <TextInput
          {...{ onChangeText }}
          multiline
          style={s.textInput}
          forwardRef={inputRef}
          placeholder={t(`${PatientNS}:message`)}
          placeholderTextColor={mediumEmphasis}
          inputColor={highEmphasis}
          selectionColor={primary}
        />
        <Menu visible={isMenuVisible} onDismiss={onMenuDismiss} {...{ anchor }} style={s.menu}>
          {menuItems}
        </Menu>
      </View>
    ),
    [
      primary,
      anchor,
      highEmphasis,
      isMenuVisible,
      mediumEmphasis,
      menuItems,
      onChangeText,
      onMenuDismiss,
      s.menu,
      s.textInput,
      t,
    ],
  )

  const dialog: IDialogInternal = useMemo(
    () => ({
      title: t(`${PatientNS}:addMessage`),
      titleStyle: s.title,
      type: "text",
      style: s.mainContainer,
      visible: isMessageModalVisible,
      isValid,
      contentContainerStyle: s.modalContainer,
      children,
      onDismiss: closeModal,
      positive: {
        type: ButtonType.POSITIVE,
        label: t(`${PatientNS}:send`),
        onPress: onSubmit,
        buttonStyle: styles.dialogButton,
        textStyle: s.dialogRightButtonLabel,
      },
      negative: {
        type: ButtonType.NEGATIVE,
        onPress: closeModal,
        buttonStyle: styles.dialogButton,
        textStyle: s.dialogLeftButtonLabel,
      },
    }),
    [
      t,
      s.title,
      s.mainContainer,
      s.modalContainer,
      s.dialogRightButtonLabel,
      s.dialogLeftButtonLabel,
      isMessageModalVisible,
      isValid,
      children,
      closeModal,
      onSubmit,
    ],
  )

  return <TextInputDialog {...{ dialog }} isValid={isValid} />
}

const styles = StyleSheet.create({
  modalContainer: {
    width: "100%",
    paddingVertical: 0,
  },
  alertBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  alertContainer: {
    justifyContent: "center",
    height: 52,
  },
  alertSelection: {
    flexShrink: 1,
    flexGrow: 2,
  },
  alertSelectionContainer: {
    flex: 1,
  },
  rightIcon: {
    alignSelf: "center",
  },
  dialogButton: {
    marginRight: 0,
    right: 0,
    flex: 0,
  },
  title: {
    marginLeft: 0,
  },
  textInput: {
    minHeight: 52,
    maxHeight: 80,
  },
  menu: {
    maxHeight: 256,
  },
})

export default MessageModal
