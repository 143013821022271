import { useEffect, useState } from "react"
import { Keyboard } from "react-native"

export const useKeyboard = () => {
  const [keyboardShown, setKeyboardShown] = useState(false)

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardWillShow", () => {
      setKeyboardShown(true)
    })
    const hideSubscription = Keyboard.addListener("keyboardWillHide", () => {
      setKeyboardShown(false)
    })

    return () => {
      showSubscription.remove()
      hideSubscription.remove()
    }
  }, [])

  return keyboardShown
}
