import { useNavigation } from "@react-navigation/native"
import _ from "lodash"
import React, { FC, ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Image,
  ImageSourcePropType,
  Platform,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from "react-native"
import LinearGradient from "react-native-linear-gradient"

import { useAlert } from "../../features/Providers"
import useTheme from "../../features/Theme/ThemeProvider"
import { openUrl } from "../../utils/openUrl"
import { IRowItemProps, RowItem } from "../RowItem"
import RowVersionItem from "../RowItem/RowVersionItem"
import { SectionSeparator } from "../Separator"
import { Subtitle1 } from "../Texts"
import { isVectorIcon, VectorIcon, VectorIconProps } from "../VectorIcon"

interface IProps {
  listKey?: string
  version?: string
  namespace?: string
  paddingSection?: number
  showSeparator?: boolean
  showHeaderSeparator?: boolean
  headerTextColor?: string
  color?: string | undefined
  footerComponent?: ReactNode
  rowBackgroundColor?: string
  versionStyle?: StyleProp<TextStyle>
  paddingStyle?: StyleProp<ViewStyle>
  optionalViewStyle?: StyleProp<ViewStyle> // for tablet use
  sections: Array<SectionListData<IRowItemProps, ISectionHeader>>
  optionalSectionListStyle?: StyleProp<ViewStyle> // for tablet use
  optionalFooterStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  maxFontSizeMultiplier?: number
}

export interface ISectionHeader {
  title?: string
  shadows?: number
  rightHeaderNode?: ReactNode
  headerIcon?: ImageSourcePropType | VectorIconProps
  headerStyle?: StyleProp<ViewStyle>
  headerTextStyle?: StyleProp<TextStyle>
}

const keyExtractor = (item: IRowItemProps | ISectionHeader, index: number) =>
  `${index}-${(item as IRowItemProps).name}`
const renderSeparator = () => <SectionSeparator />

export const ParametersScreen: FC<IProps> = ({
  listKey,
  color,
  version,
  sections,
  namespace,
  paddingStyle,
  versionStyle,
  showSeparator,
  showHeaderSeparator,
  paddingSection,
  footerComponent,
  headerTextColor,
  optionalViewStyle,
  rowBackgroundColor,
  optionalFooterStyle,
  optionalSectionListStyle,
  textStyle,
  maxFontSizeMultiplier,
}) => {
  const {
    colors: { surface },
    dimensions: { spacing },
    screenStyle,
  } = useTheme()
  const separatorColor = [surface.shadows, surface.appUi]
  const { t } = useTranslation(namespace)
  const navigation = useNavigation<any>()
  const { notImplemented } = useAlert()

  const s = useMemo(
    () => ({
      view: [
        styles.container,
        {
          backgroundColor: surface.background,
        },
        optionalViewStyle,
      ],
      sectionList: [
        styles.container,
        {
          backgroundColor: surface.background,
        },
        optionalSectionListStyle,
      ],
      header: [
        styles.header,
        {
          paddingHorizontal: spacing,
          paddingVertical: spacing / 2,
        },
      ],

      sectionListContent: [
        {
          backgroundColor: surface.background,
        },
        paddingStyle ?? { paddingBottom: spacing * 4 },
        Platform.OS !== "web" ? screenStyle : null,
      ],

      sectionListSeparator: {
        marginBottom: paddingSection,
      },

      footer: [
        styles.footer,
        {
          marginTop: spacing,
          marginHorizontal: spacing,
          backgroundColor: surface.background,
        },
        optionalFooterStyle,
      ],
    }),
    [
      optionalFooterStyle,
      optionalSectionListStyle,
      optionalViewStyle,
      paddingSection,
      paddingStyle,
      screenStyle,
      spacing,
      surface.background,
    ],
  )

  const renderItem: SectionListRenderItem<IRowItemProps, ISectionHeader> = ({ item }) => {
    const {
      name,
      url,
      route,
      params,
      render,
      onPress,
      notImplemented: notImpl = false,
    } = item as IRowItemProps
    return render ? (
      render()
    ) : (
      <RowItem
        {...item}
        backgroundColor={rowBackgroundColor}
        name={namespace ? name && t(name) : name}
        color={(item as IRowItemProps).color ?? color}
        textStyle={textStyle ?? undefined}
        maxFontSizeMultiplier={maxFontSizeMultiplier}
        onPress={
          route
            ? () => navigation.navigate(route, params)
            : url
            ? () => openUrl(url)
            : notImpl
            ? notImplemented
            : onPress
        }
      />
    )
  }

  const renderHeader = ({
    section,
  }: {
    section: SectionListData<IRowItemProps, ISectionHeader>
  }) => {
    const {
      title,
      headerIcon,
      shadows,
      rightHeaderNode,
      headerStyle,
      headerTextStyle,
    } = (section as unknown) as ISectionHeader
    return title ? (
      <>
        <View style={[s.header, headerStyle]}>
          <Subtitle1 style={headerTextStyle} color={headerTextColor ?? color}>
            {namespace ? t(title) : title}
          </Subtitle1>
          {!_.isUndefined(headerIcon) ? (
            isVectorIcon(headerIcon) ? (
              <VectorIcon {...headerIcon} />
            ) : (
              <Image source={headerIcon} />
            )
          ) : null}
          {rightHeaderNode}
        </View>
        {shadows ? <LinearGradient colors={separatorColor} style={{ height: shadows }} /> : null}
      </>
    ) : null
  }

  const renderSectionFooter = ({
    section,
  }: {
    section: SectionListData<IRowItemProps, ISectionHeader>
  }) =>
    section.shadows ? (
      <LinearGradient colors={separatorColor} style={{ height: section.shadows }} />
    ) : null

  const renderSectionListSeparator = () => (
    <>
      {showHeaderSeparator ? renderSeparator() : null}
      {paddingSection ? <View style={s.sectionListSeparator} /> : null}
    </>
  )

  const renderFooter = () => (
    <View style={s.footer}>
      {version ? <RowVersionItem {...{ version }} style={versionStyle} /> : null}
      {footerComponent}
    </View>
  )

  return (
    <View style={s.view}>
      <SectionList<IRowItemProps, ISectionHeader>
        sections={sections}
        renderItem={renderItem}
        style={s.sectionList}
        keyExtractor={keyExtractor}
        listKey={listKey}
        renderSectionHeader={renderHeader}
        stickySectionHeadersEnabled={false}
        renderSectionFooter={renderSectionFooter}
        contentContainerStyle={s.sectionListContent}
        SectionSeparatorComponent={renderSectionListSeparator}
        ItemSeparatorComponent={showSeparator ? renderSeparator : undefined}
        ListFooterComponent={renderFooter}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flex: 1,
    minHeight: 48,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  separator: {
    height: 1,
  },
  section: {
    height: 4,
  },
  footer: {
    alignItems: "center",
  },
})
