import { useUser } from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

import SettingsList from "../../components/SettingsList"
import { OTHER_PATH_FORM } from "../../features/config/Constants"
import { SettingsNS } from "../../features/i18n/constants"

interface IProps {
  isHeartDisease?: boolean
}

const PathologiesListScreen: FC<IProps> = ({ isHeartDisease = false }) => {
  const { t } = useTranslation()
  const { userData } = useUser()
  const userPathologies = userData?.medicalInfo?.otherPathologies
  const heartDiseaseType = userPathologies?.heart_disease_type
  const respiratoryDiseaseType = userPathologies?.respiratory_disease_type
  const pathologyData = useCallback(item => t(`${SettingsNS}:${item}`), [t])

  const oncoItems = useMemo(
    () =>
      _.map(isHeartDisease ? heartDiseaseType : respiratoryDiseaseType, item => ({
        name: pathologyData(item),
        rightChild: () => null,
      })),
    [isHeartDisease, heartDiseaseType, respiratoryDiseaseType, pathologyData],
  )

  return (
    <SettingsList
      listKey="pathology_list"
      listItems={oncoItems}
      settingsForm={OTHER_PATH_FORM}
      questionId={isHeartDisease ? 1 : 2}
    />
  )
}

export default PathologiesListScreen
