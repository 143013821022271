import { useAlert } from "capsule/features/Providers/AlertProvider"
import { userContext } from "capsule/features/Providers/UserProvider"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleProp, StyleSheet, ViewStyle } from "react-native"

import { maxWidth } from "../../features/Theme/dimensions"
import useTheme from "../../features/Theme/ThemeProvider"
import { IRowItemProps, RowItem } from "../RowItem"

type LogoutItemProps = IRowItemProps & { buttonStyle?: StyleProp<ViewStyle> }

const LogoutItem: FC<LogoutItemProps> = ({ buttonStyle, ...props }) => {
  const { t } = useTranslation()
  const {
    dimensions: { spacing },
  } = useTheme()
  const { logout } = useContext(userContext)
  const { showDialog, setIsValid } = useAlert()

  const s = useMemo(
    () => ({
      dialog: [
        styles.dialog,
        {
          paddingHorizontal: spacing,
        },
      ],
    }),
    [spacing],
  )

  const onPress = useCallback(() => {
    setIsValid?.(true)
    showDialog({
      type: "button",
      style: s.dialog,
      contentContainerStyle: styles.dialogContent,
      title: t(`alert.logout.title`),
      message: t(`alert.logout.message`),
      positive: {
        buttonStyle,
        onPress: () => logout(),
        label: t(`button.logout`),
      },
      negative: {
        buttonStyle,
        labelType: "cancel",
        label: t(`button.cancel`),
      },
    })
  }, [logout, buttonStyle, s.dialog, setIsValid, showDialog, t])

  return <RowItem {...props} {...{ onPress }} name={t("button.signOut")} />
}

const styles = StyleSheet.create({
  dialog: {
    alignItems: "center",
  },
  dialogContent: {
    maxWidth,
    width: "100%",
  },
})

export default LogoutItem
