import { Subtitle1, useTheme } from "capsule"
import React, { Dispatch, FC, SetStateAction, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { Question } from "../common/Proms"
import { itemField } from "../features/hooks/useItemI18n"
import AlertForm from "./AlertForm"
import DateAndCheck from "./DateAndCheck"
import QcmForm from "./QcmForm"
import QcuForm from "./QcuForm"
import TextForm from "./TextForm"

interface IProps {
  item: Question
  setIsButtonDisabled: Dispatch<SetStateAction<boolean>>
  isLinkedQuestion?: boolean
  currentValue?: string | number | boolean | string[]
}

const QuestionsType: FC<IProps> = ({
  item,
  setIsButtonDisabled,
  isLinkedQuestion,
  currentValue,
}) => {
  const {
    colors: {
      black,
      surface: { background },
    },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const data = item.options
  const name = item.name
  const type = item.type
  const dateType = type === "date"
  const title = itemField(item, "title", t)
  const signInForm = true
  const addCheckbox = type === "qcm-checkbox"
  const s = useMemo(
    () => ({
      mainView: [
        styles.mainView,
        {
          paddingHorizontal: spacing,
          backgroundColor: background,
        },
      ],
      subtitle: {
        marginBottom: spacing / 2,
        color: black.highEmphasis,
      },
      label: {
        marginVertical: spacing / 2.5,
      },
    }),
    [black.highEmphasis, spacing, background],
  )

  const renderQuestionType = useMemo(() => {
    switch (type) {
      case "qcu":
        return (
          <QcuForm
            {...{ data, name, signInForm }}
            setIsButtonDisabled={isLinkedQuestion ? setIsButtonDisabled : undefined}
          />
        )
      case "qcm":
      case "qcm-checkbox":
        return (
          <QcmForm {...{ data, name, addCheckbox }} setIsButtonDisabled={setIsButtonDisabled} />
        )
      case "date":
        return (
          <DateAndCheck
            item={item}
            currentValue={currentValue}
            setIsButtonDisabled={setIsButtonDisabled}
          />
        )
      case "alert":
        return item && <AlertForm data={item} />
      case "text":
        return <TextForm {...{ title, name }} />
      default:
        return null
    }
  }, [
    name,
    addCheckbox,
    data,
    isLinkedQuestion,
    item,
    setIsButtonDisabled,
    signInForm,
    title,
    type,
    currentValue,
  ])

  return (
    <View style={dateType || isLinkedQuestion ? s.mainView : null}>
      {isLinkedQuestion ? (
        <Subtitle1 style={s.subtitle}>{itemField(item, "title", t)}</Subtitle1>
      ) : null}
      {renderQuestionType}
    </View>
  )
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
  },
  checkbox: {
    alignSelf: "flex-start",
    flexDirection: "row",
  },
})

export default QuestionsType
