import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { PhoneNumberForm, PhoneNumberFormikConfig, PhoneNumberScreen, useTheme } from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { StyleSheet, View } from "react-native"

import { DoctupSupportedCountries, INPUT_HEIGHT } from "../../features/config/Constants"
import { OnboardingParamList, RootParamList } from "../../features/Navigation/types"
import { getFlagEmoji } from "../../utils/conversion"

interface IProps {
  isEdit?: boolean
}

const PhoneScreen: FC<IProps> = ({ isEdit = false }) => {
  const {
    dimensions: { spacing },
    colors: {
      surface: { background },
    },
  } = useTheme()
  const navigation = useNavigation<
    | StackNavigationProp<OnboardingParamList, "Phone">
    | StackNavigationProp<RootParamList, "PhoneEdition">
  >()

  const countriesAndFlagsArray = useMemo(
    () =>
      _.map(DoctupSupportedCountries, country => ({
        code: country.countryCode,
        title: country.countryName,
        flag: getFlagEmoji(country.countryCode),
        callingCode: country.countryCallingCode,
      })),
    [],
  )

  const onSubmit = useCallback(
    (form: PhoneNumberForm) => {
      if (isEdit) {
        ;(navigation as StackNavigationProp<RootParamList, "PhoneEdition">).navigate(
          "SMSValidationEdition",
          {
            phoneNumber: form.phoneNumber,
          },
        )
      } else {
        ;(navigation as StackNavigationProp<OnboardingParamList, "Phone">).navigate(
          "SMSValidation",
          {
            phoneNumber: form.phoneNumber,
          },
        )
      }
    },
    [isEdit, navigation],
  )

  const { inputStyle, langageBtnStyle, dropdownBtnStyle, phoneInputContainer, view } = useMemo(
    () => ({
      inputStyle: styles.input,
      langageBtnStyle: styles.langageButton,
      dropdownBtnStyle: styles.dropdownButton,
      phoneInputContainer: [styles.phoneInputContainer, { marginTop: spacing * 2 }],
      view: [styles.view, { backgroundColor: background }],
    }),
    [spacing, background],
  )

  return (
    <View style={view}>
      <PhoneNumberScreen<PhoneNumberForm>
        countries={countriesAndFlagsArray}
        name="phoneNumber"
        {...{ inputStyle, langageBtnStyle, dropdownBtnStyle, phoneInputContainer }}
        formikConfig={PhoneNumberFormikConfig<PhoneNumberForm>(onSubmit)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  phoneInputContainer: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  input: {
    height: INPUT_HEIGHT,
    flexBasis: "72%",
  },
  langageButton: {
    flexBasis: "25%",
    backgroundColor: "rgba(33, 33, 33, 0.05)",
  },
  dropdownButton: {
    width: "50%",
  },
  view: {
    flex: 1,
  },
})

export default PhoneScreen
