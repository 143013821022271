import React, { FC, ReactNode } from "react"
import { ImageSourcePropType } from "react-native"
import AntDesignI from "react-native-vector-icons/AntDesign"
import EntypoI from "react-native-vector-icons/Entypo"
import EvilIconsI from "react-native-vector-icons/EvilIcons"
import FeatherI from "react-native-vector-icons/Feather"
import FontAwesomeI from "react-native-vector-icons/FontAwesome"
import FontAwesome5I from "react-native-vector-icons/FontAwesome5"
import FoundationI from "react-native-vector-icons/Foundation"
import { IconProps } from "react-native-vector-icons/Icon"
import IoniconsI from "react-native-vector-icons/Ionicons"
import MaterialCommunityIconsI from "react-native-vector-icons/MaterialCommunityIcons"
import MaterialIconsI from "react-native-vector-icons/MaterialIcons"
import OcticonsI from "react-native-vector-icons/Octicons"
import SimpleLineIconsI from "react-native-vector-icons/SimpleLineIcons"
import ZocialI from "react-native-vector-icons/Zocial"

import useTheme from "../../features/Theme/ThemeProvider"

const ICON_SIZE = 24

export const MaterialCommunityIcons: FC<IconProps> = props => <MaterialCommunityIconsI {...props} />
export const SimpleLineIcons: FC<IconProps> = props => <SimpleLineIconsI {...props} />
export const MaterialIcons: FC<IconProps> = props => <MaterialIconsI {...props} />
export const FontAwesome5: FC<IconProps> = props => <FontAwesome5I {...props} />
export const FontAwesome: FC<IconProps> = props => <FontAwesomeI {...props} />
export const Foundation: FC<IconProps> = props => <FoundationI {...props} />
export const EvilIcons: FC<IconProps> = props => <EvilIconsI {...props} />
export const AntDesign: FC<IconProps> = props => <AntDesignI {...props} />
export const Ionicons: FC<IconProps> = props => <IoniconsI {...props} />
export const Octicons: FC<IconProps> = props => <OcticonsI {...props} />
export const Feather: FC<IconProps> = props => <FeatherI {...props} />
export const Entypo: FC<IconProps> = props => <EntypoI {...props} />
export const Zocial: FC<IconProps> = props => <ZocialI {...props} />

export interface VectorIconProps {
  name: string
  size?: number
  color?: string
  category: VectorIconCategory
}

// eslint-disable-next-line no-shadow
export enum VectorIconNames {
  "MaterialCommunityIcons",
  "SimpleLineIcons",
  "MaterialIcons",
  "FontAwesome5",
  "FontAwesome",
  "Foundation",
  "AntDesign",
  "EvilIcons",
  "Ionicons",
  "Octicons",
  "Feather",
  "Entypo",
  "Zocial",
}

export type VectorIconCategory = keyof typeof VectorIconNames

export function isVectorIcon(
  icon: undefined | ImageSourcePropType | ReactNode | VectorIconProps,
): icon is VectorIconProps {
  return (icon as VectorIconProps)?.category !== undefined
}

export const VectorIcon: FC<VectorIconProps & IconProps> = ({
  category,
  name,
  size = ICON_SIZE,
  color,
  ...rest
}) => {
  const {
    colors: { black },
  } = useTheme()
  const props: IconProps = {
    color: color ?? black.highEmphasis,
    name,
    size,
    ...rest,
  }

  switch (category) {
    case "MaterialCommunityIcons":
      return <MaterialCommunityIcons {...props} />
    case "SimpleLineIcons":
      return <SimpleLineIcons {...props} />
    case "MaterialIcons":
      return <MaterialIcons {...props} />
    case "FontAwesome5":
      return <FontAwesome5 {...props} />
    case "FontAwesome":
      return <FontAwesome {...props} />
    case "Foundation":
      return <Foundation {...props} />
    case "EvilIcons":
      return <EvilIcons {...props} />
    case "AntDesign":
      return <AntDesign {...props} />
    case "Ionicons":
      return <Ionicons {...props} />
    case "Octicons":
      return <Octicons {...props} />
    case "Feather":
      return <Feather {...props} />
    case "Zocial":
      return <Zocial {...props} />
    case "Entypo":
      return <Entypo {...props} />
    default:
      return null
  }
}

export default VectorIcon
