import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { IRowItemProps, ISectionHeader, ParametersScreen, useTheme, VectorIconProps } from "capsule"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Image, ImageSourcePropType, SectionListData, StyleSheet } from "react-native"

import { images } from "../../assets/images"
import { Role } from "../../common/CommonUserData"
import { ICON_SIZE } from "../../features/config/Constants"
import { PraticienNS } from "../../features/i18n/constants"
import { OnboardingParamList, RootParamList } from "../../features/Navigation/types"
import { useRole } from "../../features/Providers/RoleProvider"
const PractitionerScreen = () => {
  const {
    colors: {
      surface: { background },
      black: { mediumEmphasis: black },
    },
    dimensions: { spacing },
    typography: { subtitle1 },
  } = useTheme()
  const { setRole } = useRole()

  const { t } = useTranslation(PraticienNS)
  const navigation = useNavigation<StackNavigationProp<OnboardingParamList, "Phone">>()

  const s = useMemo(
    () => ({
      image: [
        styles.image,
        {
          marginRight: spacing,
        },
      ],
      background: {
        backgroundColor: background,
      },
      row: [
        styles.row,
        {
          paddingVertical: spacing,
        },
      ],
    }),
    [spacing, background],
  )

  const rightChild = useMemo<VectorIconProps>(
    () => ({
      size: ICON_SIZE,
      name: "chevron-right",
      color: black,
      category: "MaterialIcons",
    }),
    [black],
  )

  const onRolePress = useCallback(
    role => () => {
      setRole?.(role)
      navigation.navigate("Phone")
    },
    [navigation, setRole],
  )

  const common = useMemo(() => ({ rowStyle: s.row, textStyle: subtitle1, rightChild }), [
    rightChild,
    s.row,
    subtitle1,
  ])

  const getRow = useCallback(
    (role: Role) => ({
      leftChild: <Image style={s.image} source={images[role] as ImageSourcePropType} />,
      name: t(role),
      onPress: onRolePress(role),
      ...common,
    }),
    [common, onRolePress, s.image, t],
  )
  const practitionerData = useMemo(() => [getRow("surgeon"), getRow("kine"), getRow("gp")], [
    getRow,
  ])

  const practitionerSectionData: Array<
    SectionListData<IRowItemProps<RootParamList>, ISectionHeader>
  > = [
    {
      data: practitionerData,
      title: "",
    },
  ]

  return (
    <ParametersScreen
      listKey="practitioner"
      showSeparator
      sections={practitionerSectionData}
      optionalSectionListStyle={s.background}
      paddingStyle={s.background}
    />
  )
}

const styles = StyleSheet.create({
  image: {
    width: 50,
    height: 50,
  },
  row: {
    height: "100%",
  },
})

export default PractitionerScreen
