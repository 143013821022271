import { StyleProp, TextStyle, ViewStyle } from "react-native"

type TypographyVariant =
  | "body1"
  | "body2"
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "overline"
  | "subtitle1"
  | "subtitle2"
  | "dateNumber"

export type WeightName =
  | "Thin"
  | "Light"
  | "Regular"
  | "Medium"
  | "Semi-Bold"
  | "SemiBold"
  | "Bold"
  | "Black"

export const defaultWeights: WeightNames = {
  Thin: "100",
  Light: "300",
  Regular: "400",
  Medium: "500",
  "Semi-Bold": "600",
  SemiBold: "600",
  Bold: "700",
  Black: "900",
}

export const defaultStyles: StyleNames = {
  italic: "italic",
  normal: "normal",
}

export type StyleName = "normal" | "italic"

export interface IColorTheme {
  primary: string
  secondary: string
  selection: string
  accent: string
  error: string
  danger: string
  grey: string
  transparent: string
  white: {
    header: string
    disabled: string
    inactive: string
    highEmphasis: string
    mediumEmphasis: string
  }
  black: {
    shadow: string
    disabled: string
    inactive: string
    separator: string
    transparent: string
    highEmphasis: string
    mediumEmphasis: string
    disabledButton: string
    disabledImages: string
    strong: string
  }
  surface: {
    appUi: string
    background: string
    input: string
    textInput: string
    shadows: string
    card: string
    disabled: string
    progress: {
      border: string
      background: string
    }
    menu: string
    chip: string
    sheets: string
    drawers: string
    dialogs: string
    snackbar: string
    sideSheets: string
    textInputDisabled: string
    header: string
  }
  overrides?: {
    menu?: string
    switch?: {
      lineActive?: string
      thumbActive?: string
      lineInactive?: string
      thumbInactive?: string
    }
    button?: string
    ripple?: string
    checkbox?: string
    listIcon?: string
    textInput?: string
    circleStep?: string
    iconButton?: string
    radioButton?: { checked: string; unchecked: string }
    activityIndicator?: string
    tabBar?: string
    tabIndicator?: string
    snack?: {
      text?: string
      background?: string
    }
  }
}

export interface IDimensionsTheme {
  width: number
  gutter: number
  margin: number
  marginSides: number
  height: number
  spacing: number
  columns: number
  stackHeight: number
  isTabLand: ({ width, height }: { width: number; height: number }) => boolean
  isTabTwoPane: ({ width, height }: { width: number; height: number }) => boolean
  textInputStyle: StyleProp<ViewStyle>
}

export type ITypographyTheme = {
  [K in TypographyVariant]: TextStyle
}

export type IFontsTheme = {
  [key: string]: IFontTheme
} & { default: IFontTheme; title?: IFontTheme }

export type PartialPartial<T> = {
  [P in keyof T]?: Partial<T[P]>
}

export type PartialPartialPartial<T> = {
  [P in keyof T]?: PartialPartial<T[P]>
}

type WeightNames = {
  [K in WeightName]?:
    | "normal"
    | "bold"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"
}

type StyleNames = {
  [K in StyleName]?: StyleName
}

export interface IFontTheme {
  weights: WeightNames
  styles?: StyleNames
  defaultWeight?: WeightName
  iosFamily?: string
  family?: string
}

export interface FmOptions {
  family?: string
  weight?: WeightName
  style?: StyleName
}

export type FmFunc = (options?: FmOptions) => TextStyle

type Mode = "adaptive" | "exact"

export interface ICSTheme {
  colors: IColorTheme
  dimensions: IDimensionsTheme
  typography: ITypographyTheme
  typoGen: (theme: ICSTheme) => ITypographyTheme
  fonts: IFontsTheme
  darkMode: boolean
  button?: {
    height?: number
    roundness?: number
    uppercase?: boolean
    mode?: "text" | "outlined" | "contained"
    alertMode?: "text" | "outlined" | "contained"
  }
  roundness?: number
  mode: Mode
  animation: {
    scale: number
  }
  screenStyle?: ViewStyle
}
