import React, { cloneElement, FC, ReactNode, useCallback, useMemo, useState } from "react"
import { StyleProp, StyleSheet, TextStyle, View } from "react-native"
import { IconButton, Menu } from "react-native-paper"
import { IconSource } from "react-native-paper/lib/typescript/src/components/Icon"
import { Icon } from "react-native-vector-icons/Icon"

import { ICON_SIZE } from "../../../features/config/Constants"
import useTheme from "../../features/Theme/ThemeProvider"
import hitSlop from "../../utils/hitSlop"
import { Body2 } from "../Texts"
import { Touchable } from "../Touchable"

interface IconType {
  icon: string | Icon | JSX.Element
}

interface IconCompType {
  iconComp: JSX.Element
}

export interface IItem {
  id: number
  disabled?: boolean
  onPress?: (item: IItem) => void
}

export type IIconItem = IItem & {
  color?: string
} & (IconType | IconCompType)

export interface IMenuItem extends IItem {
  label: string | JSX.Element
  labelStyle?: StyleProp<TextStyle>
}

interface IProps {
  /** The icon is ignored if a label is present */
  icons?: Array<IIconItem | IMenuItem>
  menu?: IMenuItem[]
  menuColor?: string
  onPress?: (item: IItem) => void
}

export function isMenuItem(item: IIconItem | IMenuItem): item is IMenuItem {
  return !!(item as IMenuItem).label
}

const ActionBar: FC<IProps> = ({ icons, menu, menuColor: propMenuColor, onPress }) => {
  const {
    colors: { white, overrides },
    dimensions: { spacing },
  } = useTheme()

  const [menuVisible, setMenuVisible] = useState(false)
  const menuOverrideColor = overrides?.menu
  const iconOverrideColor = overrides?.iconButton

  const onDismiss = () => {
    setMenuVisible(false)
  }

  const onMenuPress = () => setMenuVisible(true)

  const s = useMemo(
    () => ({
      icon: [styles.icon, { marginHorizontal: spacing / 4 }],
      container: [styles.container, { paddingRight: spacing / 2 }],
      item: { paddingHorizontal: spacing, paddingVertical: spacing },
      label: { margin: spacing / 2 },
    }),
    [spacing],
  )
  const menuColor = propMenuColor || menuOverrideColor || white.inactive

  const createOnLinePress = useCallback(
    (line: IMenuItem) => () => {
      ;(line.onPress ?? onPress)?.(line)
      onDismiss()
    },
    [onPress],
  )

  const menuItems = useMemo(
    () =>
      menu?.map(line => (
        <Touchable
          key={line.id}
          style={s.item}
          hitSlop={hitSlop(spacing)}
          onPress={createOnLinePress(line)}
        >
          {typeof line.label === "string" ? <Body2>{line.label}</Body2> : <>{line.label}</>}
        </Touchable>
      )),
    [createOnLinePress, menu, s.item, spacing],
  )

  const renderIconItems = useMemo(
    () => (item: IIconItem | IMenuItem) => {
      const iconOnPress = item.onPress ? item.onPress : onPress ? onPress : null
      const iconButtonColor =
        (isMenuItem(item) ? undefined : item.color) || iconOverrideColor || white.highEmphasis
      const commonProps = {
        disabled: item.disabled,
        rippleColor: "rgba(0, 0, 0, .32)",
        hitSlop: hitSlop(spacing / 2),
        onPress: () => iconOnPress?.(item) ?? undefined,
        accessibilityLabel: ((item as IconType)?.icon as Icon)?.props?.accessibilityLabel,
      }

      const icon = (item as IconCompType).iconComp
        ? () =>
            cloneElement((item as IconCompType).iconComp, {
              color: iconButtonColor,
              size: ICON_SIZE,
            }) as ReactNode
        : (item as IconType).icon

      return (
        <View key={item.id}>
          {isMenuItem(item) ? (
            <Touchable {...commonProps}>
              <Body2 style={[s.label, item.labelStyle]}>{item.label}</Body2>
            </Touchable>
          ) : typeof (item as IconType).icon === "string" || (item as IconCompType).iconComp ? (
            <IconButton
              size={24}
              style={s.icon}
              {...commonProps}
              iconColor={iconButtonColor}
              icon={icon as IconSource}
            />
          ) : (
            (item as IconType).icon
          )}
        </View>
      )
    },
    [iconOverrideColor, onPress, s.icon, s.label, spacing, white.highEmphasis],
  )

  const anchor = useMemo(
    () => (
      <IconButton
        size={24}
        iconColor={menuColor}
        style={styles.icon}
        icon="dots-vertical"
        onPress={onMenuPress}
        hitSlop={hitSlop(spacing)}
        rippleColor="rgba(0, 0, 0, .32)"
      />
    ),
    [menuColor, spacing],
  )

  return (
    <View style={s.container}>
      {icons?.map(icon => renderIconItems(icon))}
      {menu && (
        <Menu visible={menuVisible} {...{ onDismiss, anchor }} contentStyle={styles.menu}>
          {menuItems}
        </Menu>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    margin: 0,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  menu: {
    paddingVertical: 0,
  },
})

export default ActionBar
