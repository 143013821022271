import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  RefObject,
  SetStateAction,
  useRef,
  useState,
} from "react"
import { FlatList } from "react-native"

export interface TabType {
  index: string
  title: string
}

export interface ITabContext {
  currentTab?: TabType
  tabRef?: RefObject<FlatList<TabType>>
  setCurrentTab: Dispatch<SetStateAction<TabType | undefined>>
}

interface IProps {
  children: ReactNode
  initialTab?: TabType
}

const defaultValues: ITabContext = {
  currentTab: undefined,
  setCurrentTab: _.noop,
}

export const tabContext = createContext<ITabContext>(defaultValues)

const TabProvider: FC<IProps> = ({ children, initialTab }: IProps) => {
  const tabRef = useRef<FlatList<TabType>>(null)
  const [currentTab, setCurrentTab] = useState<TabType | undefined>(
    initialTab ?? defaultValues.currentTab,
  )
  const contextValue: ITabContext = {
    tabRef,
    currentTab,
    setCurrentTab,
  }

  return <tabContext.Provider value={contextValue}>{children}</tabContext.Provider>
}

export default TabProvider

// I think that this provider could be used with more component than a tabbar (radioButton,) It could be more generic in the future
