// TO KEEP EVEN IF NOT USED
import React, { FC } from "react"
import { StyleSheet, View } from "react-native"

import CircleStep from "./CircleStep"

interface IProps {
  steps: number
  color?: string
  currentStep: number
}

const CircleStepProgress: FC<IProps> = ({ steps, currentStep, color }) => (
  <View style={styles.view}>
    {Array(steps)
      .fill(null)
      .map((_, index) => (
        <CircleStep key={index} active={currentStep === index} color={color} />
      ))}
  </View>
)

const styles = StyleSheet.create({
  view: {
    flexDirection: "row",
  },
})

export default CircleStepProgress
