import {
  Body2,
  FormikRadioButton,
  generateShadow,
  IUserContext,
  Subtitle1,
  userContext,
  useTheme,
} from "capsule"
import { useFormikContext } from "formik"
import _ from "lodash"
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"

import { Option } from "../common/Proms"
import { itemField } from "../features/hooks/useItemI18n"
import { AppUserData } from "../features/models/UserData"
import { promsContext } from "../features/Providers/PromsProvider"

interface IProps {
  data: Option[]
  name: string
  signInForm?: boolean
  setIsButtonDisabled?: Dispatch<SetStateAction<boolean>>
}

const keyExtractor = item => item?.id

const QcuForm: FC<IProps> = ({ data, name, signInForm, setIsButtonDisabled }) => {
  const {
    colors: { black, white, accent },
    dimensions: { spacing },
  } = useTheme()

  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<number | string>()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { form } = useContext(promsContext)
  const isEditForm = form?.isEdit
  const [optionValue, setOptionValue] = useState<number | string | undefined>(
    typeof values[name] === "string" ? values[name] : parseInt(values[name]),
  )
  const isChecked = useCallback((value: number | string) => optionValue === value, [optionValue])
  const onPress = useCallback((value: number | string) => () => setOptionValue(value), [])
  useEffect(() => {
    if (setIsButtonDisabled) {
      setIsButtonDisabled(_.isEmpty(values[name]))
    }
  }, [values, name, setIsButtonDisabled])

  useEffect(() => {
    if (isEditForm && form && form.dataGroupName && userData?.medicalInfo) {
      setFieldValue(name, userData.medicalInfo[form.dataGroupName][name])
      setOptionValue(
        typeof userData.medicalInfo[form.dataGroupName][name] === "string"
          ? userData.medicalInfo[form.dataGroupName][name]
          : parseInt(userData.medicalInfo[form.dataGroupName][name]),
      )
    }
  }, [form, isEditForm, name, setFieldValue, userData])

  useEffect(() => {
    if (setIsButtonDisabled) {
      setIsButtonDisabled(_.isEmpty(values[name]))
    }
  }, [values, name, setIsButtonDisabled])

  const s = useMemo(
    () => ({
      optionsContainer: {
        marginBottom: spacing,
        marginHorizontal: spacing / 2,
      },
      buttonContent: [
        styles.buttonContent,
        {
          backgroundColor: white.highEmphasis,
          minHeight: signInForm ? 20 : 75,
        },
      ],
      optionSelected: {
        backgroundColor: accent,
      },
      buttonSubtitleLabel: [
        styles.buttonLabel,
        {
          color: black.mediumEmphasis,
          marginTop: signInForm ? 0 : spacing / 2,
          marginBottom: spacing / 2,
        },
      ],
      buttonContainer: [
        styles.buttonContainer,
        {
          margin: spacing / 2,
          marginHorizontal: spacing,
        },
        generateShadow(4),
      ],
      buttonLabel: [
        styles.buttonLabel,
        {
          margin: spacing / 2,
          color: black.highEmphasis,
        },
      ],
    }),
    [black.highEmphasis, accent, black.mediumEmphasis, spacing, white.highEmphasis, signInForm],
  )

  const specificLabel = useCallback(
    item => (
      <View style={[s.buttonContent, isChecked(item?.value) ? s.optionSelected : null]}>
        <Subtitle1 style={s.buttonLabel}>{itemField(item, "title", t)}</Subtitle1>
        {itemField(item, "description", t) ? (
          <Body2 style={s.buttonSubtitleLabel}>{itemField(item, "description", t)}</Body2>
        ) : null}
      </View>
    ),
    [isChecked, s.buttonContent, s.buttonLabel, s.buttonSubtitleLabel, s.optionSelected, t],
  )

  const renderItem: ListRenderItem<Option> = useCallback(
    ({ item }) => (
      <FormikRadioButton
        {...{ name }}
        textOnly
        value={`${item?.value}`}
        onPress={onPress(item?.value)}
        touchableStyle={s.buttonContainer}
        specificLabel={specificLabel(item)}
      />
    ),
    [name, onPress, s.buttonContainer, specificLabel],
  )

  return <FlatList style={s.optionsContainer} {...{ data, renderItem, keyExtractor }} />
}

const styles = StyleSheet.create({
  buttonContainer: {
    justifyContent: "center",
    height: "auto",
  },
  buttonContent: {
    width: "100%",
    alignSelf: "center",
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: 4,
  },
  buttonLabel: {
    textAlign: "center",
  },
})

export default QcuForm
