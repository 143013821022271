import {
  Body2,
  FormikCheckbox,
  FormikRadioButton,
  generateShadow,
  IUserContext,
  Subtitle1,
  userContext,
  useTheme,
} from "capsule"
import { useFormikContext } from "formik"
import _ from "lodash"
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"

import { Option } from "../common/Proms"
import { itemField } from "../features/hooks/useItemI18n"
import { AppUserData } from "../features/models/UserData"
import { promsContext } from "../features/Providers/PromsProvider"

interface IProps {
  data: Option[]
  name: string
  setIsButtonDisabled?: Dispatch<SetStateAction<boolean>>
  addCheckbox?: boolean
}

const keyExtractor = (item: Option) => item.id

const QcmForm: FC<IProps> = ({ data, name, setIsButtonDisabled, addCheckbox }) => {
  const {
    colors: {
      black,
      white,
      accent,
      surface: { background },
    },
    dimensions: { spacing },
  } = useTheme()

  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const { values } = useFormikContext<string[]>()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { form } = useContext(promsContext)
  const isEditForm = form?.isEdit
  const [selectedOptions, setSelectedOptions] = useState<string[]>(values[name] ?? [])
  const [isOptionChecked, setIsOptionChecked] = useState<string | undefined>()
  useEffect(() => {
    if (isEditForm && form && form.dataGroupName && userData?.medicalInfo) {
      setFieldValue(name, userData.medicalInfo[form.dataGroupName][name])
      setFieldValue(
        `${name}_unknown`,
        userData.medicalInfo[form.dataGroupName][[`${name}_unknown`]],
      )
      setSelectedOptions(userData.medicalInfo[form.dataGroupName][name])
    }
  }, [form, isEditForm, name, setFieldValue, userData?.medicalInfo])
  useEffect(() => {
    if (setIsButtonDisabled) {
      if (addCheckbox === true) {
        setIsButtonDisabled(_.isEmpty(values[name]) && !values[`${name}_unknown`])
      } else {
        setIsButtonDisabled(_.isEmpty(values[name]))
      }
    }
  }, [values, name, setIsButtonDisabled, addCheckbox])

  useEffect(() => {
    if (values[`${name}_unknown`]) {
      setIsOptionChecked(values[`${name}_unknown`].toString())
      setSelectedOptions([])
      setFieldValue(name, undefined)
    } else {
      setIsOptionChecked(undefined)
    }
  }, [values, name, setFieldValue])
  const handleOptionSelect = useCallback(
    (optionValue: number | string) => () => {
      const stringValue = typeof optionValue === "string" ? optionValue : optionValue.toString()
      if (isOptionChecked) {
        setIsOptionChecked(undefined)
        setFieldValue(`${name}_unknown`, undefined)
      }
      if (_.includes(selectedOptions, stringValue)) {
        const optionsArray = selectedOptions.filter(value => value !== stringValue)
        setSelectedOptions(optionsArray)
        setFieldValue(name, optionsArray)
      } else {
        const optionsArray = selectedOptions ? [...selectedOptions, stringValue] : [stringValue]
        setSelectedOptions(optionsArray)
        setFieldValue(name, optionsArray)
      }
    },
    [selectedOptions, name, setFieldValue, isOptionChecked],
  )
  const isChecked = useCallback(
    (value: number | string) =>
      !isOptionChecked &&
      _.includes(selectedOptions, typeof value === "string" ? value : value.toString()),
    [selectedOptions, isOptionChecked],
  )

  const s = useMemo(
    () => ({
      optionsContainer: {
        marginBottom: spacing,
        marginHorizontal: spacing / 2,
        backgroundColor: background,
      },
      buttonContent: [
        styles.buttonContent,
        {
          backgroundColor: white.highEmphasis,
          paddingHorizontal: spacing / 2,
        },
      ],
      optionSelected: {
        backgroundColor: accent,
      },
      buttonSubtitleLabel: [
        styles.label,
        {
          color: black.mediumEmphasis,
          marginBottom: spacing / 4,
        },
      ],
      buttonContainer: [
        styles.buttonContainer,
        {
          margin: spacing / 2,
        },
        generateShadow(4),
      ],
      label: [
        styles.label,
        {
          margin: spacing / 2,
          color: black.highEmphasis,
        },
      ],
    }),
    [black.highEmphasis, accent, black.mediumEmphasis, spacing, white.highEmphasis, background],
  )

  const specificLabel = useCallback(
    item => (
      <View style={[s.buttonContent, isChecked(item?.value) ? s.optionSelected : null]}>
        <Subtitle1 style={s.label}>{itemField(item, "title", t)}</Subtitle1>
        {itemField(item, "description", t) ? (
          <Body2 style={s.buttonSubtitleLabel}>{itemField(item, "description", t)}</Body2>
        ) : null}
      </View>
    ),
    [isChecked, s.buttonContent, s.label, s.buttonSubtitleLabel, s.optionSelected, t],
  )

  const renderItem: ListRenderItem<Option> = useCallback(
    ({ item }) => (
      <FormikRadioButton
        {...{ name }}
        textOnly={true}
        value={`${item?.value}`}
        onPress={handleOptionSelect(item?.value)}
        touchableStyle={s.buttonContainer}
        specificLabel={specificLabel(item)}
        key={item.id}
        isQcmValue={true}
      />
    ),
    [name, handleOptionSelect, s.buttonContainer, specificLabel],
  )

  return (
    <View style={s.optionsContainer}>
      <FlatList {...{ data, renderItem, keyExtractor }} />
      {addCheckbox === true ? (
        <View style={s.buttonContainer}>
          <FormikCheckbox
            key={`${name}_unknown`}
            name={`${name}_unknown`}
            label={t(`common:button.${name}_unknown`)}
            showDefault={false}
            contentStyle={s.buttonContent}
            isCheckbox
          />
        </View>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    justifyContent: "center",
    height: "auto",
  },
  buttonContent: {
    width: "100%",
    alignSelf: "center",
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: 4,
    minHeight: 20,
  },
  label: {
    textAlign: "center",
  },
})

export default QcmForm
