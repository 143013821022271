import {
  firestore,
  Indicator,
  PresentationListView,
  SectionSeparator,
  Subtitle1,
  Touchable,
  useTheme,
  VectorIcon,
} from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"

import { collections } from "../../common/types"
import AccordionSection from "../../components/AccordionSection"
import { MessageList } from "../../components/MessageList"
import MessageModal from "../../components/MessageModal"
import { PatientNS } from "../../features/i18n/constants"
import { Message } from "../../features/models/Message"
import { messageContext } from "../../features/Providers/MessageProvider"
import { PatientProps } from "./PatientScreen"

const PatientMessageView: FC<PatientProps> = ({ patient }) => {
  const {
    dimensions: { spacing },
    colors: {
      primary,
      black: { mediumEmphasis },
      surface: { textInput },
      white: { highEmphasis: white },
    },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation(PatientNS)
  const { setIsMessageModalVisible } = useContext(messageContext)
  const openModal = useCallback(() => setIsMessageModalVisible(true), [setIsMessageModalVisible])
  const [patientMessages, loading, error] = useCollectionData<Message>(
    (firestore()
      .collection(collections.LOGIN)
      .doc(patient.id)
      .collection(collections.MESSAGES)
      .orderBy("timestamp", "desc") as unknown) as firebase.firestore.Query<Message>,
    { idField: "id" },
  )
  const { style, s } = useMemo(
    () => ({
      style: [
        {
          backgroundColor: white,
        },
      ],
      s: {
        sendContainer: [
          styles.sendContainer,
          {
            backgroundColor: white,
            padding: spacing,
          },
        ],
        sendBar: [
          styles.sendBar,
          {
            backgroundColor: textInput,
            padding: spacing,
          },
        ],
        empty: {
          paddingVertical: spacing,
        },
      },
    }),
    [white, spacing, textInput],
  )

  const indicator = useMemo<Indicator>(() => ({ size: "large", color: primary }), [primary])

  return (
    <AccordionSection leftIcon="message" title={t("messages")} expandList={true}>
      <View style={Platform.OS !== "web" ? screenStyle : null}>
        <View {...{ style }}>
          <View style={s.sendContainer}>
            <Touchable onPress={openModal} style={s.sendBar} rippleColor={primary}>
              <View style={styles.alertBar}>
                <Subtitle1>{t("addMessage")}</Subtitle1>
                <VectorIcon
                  name="send"
                  category="MaterialIcons"
                  color={mediumEmphasis}
                  style={styles.sendIcon}
                />
              </View>
            </Touchable>
          </View>
          <MessageModal patientID={patient.id} />
          <SectionSeparator />
          <PresentationListView
            style={s.empty}
            edges={["left", "right"]}
            {...{ loading, error, indicator }}
            info={{ subtitle: t("empty") }}
            isEmpty={_.isEmpty(patientMessages)}
          >
            <MessageList {...{ patientMessages }} />
          </PresentationListView>
        </View>
      </View>
    </AccordionSection>
  )
}

const styles = StyleSheet.create({
  alertBar: {
    flexDirection: "row",
    alignItems: "center",
  },
  sendBar: {
    flex: 1,
    justifyContent: "center",
    height: 52,
  },
  sendContainer: {
    flex: 1,
    maxHeight: Platform.OS !== "web" ? undefined : 100,
  },
  sendIcon: {
    position: "absolute",
    right: 0,
  },
})

export default PatientMessageView
