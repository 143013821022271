import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { ISO_8601_DATE } from "capsule"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import _ from "lodash"
import * as Yup from "yup"

import { Gender } from "../../common/CommonUserData"
import currentDateMs from "../../features/hooks/useDateMock"
import { AppUserData, UserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { FormikParams } from "../../features/Navigation/FormikScreen"

export interface ProfileFormValues {
  lastName: string
  firstName: string
  gender: Gender | undefined
  birthdate: Date | undefined
  size: string
  weight: string
}

export const defaultProfileFormValues: ProfileFormValues = {
  lastName: "",
  firstName: "",
  gender: undefined,
  birthdate: undefined,
  size: "",
  weight: "",
}

function transform(value) {
  return value
}

export const profileValidationSchema = (t: TFunction, ns: string) =>
  Yup.object({
    lastName: Yup.string().required(t("required.lastName", { ns })),
    firstName: Yup.string().required(t("required.firstName", { ns })),
    gender: Yup.string().required(),
    birthdate: Yup.date()
      .required()
      .max(dayjs(currentDateMs()).toDate(), t("required.birthdate", { ns })),
    size: Yup.number()
      .required(t("required.size", { ns }))
      .transform(transform)
      .typeError(t("required.numeric", { ns })),
    weight: Yup.number()
      .required(t("required.weight", { ns }))
      .transform(transform)
      .typeError(t("required.numeric", { ns })),
  })

export const onSubmitProfile = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
) => async (values: ProfileFormValues): Promise<any> => {
  const updatedValues: Partial<UserData> = {
    ..._.omit(values, ["birthdate"]),
    birthdate: dayjs(values.birthdate).utc().format(ISO_8601_DATE),
  }
  await updateUserData(userDocRef, updatedValues)
}

export const profileFormikParams = (t: TFunction, ns: string, userData?: Partial<AppUserData>) =>
  ({
    onSubmit: onSubmitProfile,
    initialValues: userData?.lastName
      ? {
          size: userData?.size,
          weight: userData?.weight,
          gender: userData?.gender,
          lastName: userData?.lastName,
          firstName: userData?.firstName,
          birthdate: userData?.birthdate,
        }
      : defaultProfileFormValues,
    validationSchema: profileValidationSchema(t, ns),
  } as FormikParams<ProfileFormValues>)
