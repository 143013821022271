import { IntlProvider, UserProvider } from "capsule"
// eslint-disable-next-line no-duplicate-imports
import * as React from "react"
import { SafeAreaProvider } from "react-native-safe-area-context"

import { transform } from "../models/UserDataFunctions"
import MessageProvider from "./MessageProvider"
import ProgramProvider from "./ProgramProvider"
import RoleProvider from "./RoleProvider"

interface IProps {
  children: React.ReactNode
}

const RootProvider: React.FC<IProps> = props => (
  <SafeAreaProvider>
    <UserProvider options={{ transform }} disableToken>
      <IntlProvider>
        <ProgramProvider>
          <RoleProvider>
            <MessageProvider>{props.children}</MessageProvider>
          </RoleProvider>
        </ProgramProvider>
      </IntlProvider>
    </UserProvider>
  </SafeAreaProvider>
)

export default RootProvider
