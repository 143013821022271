// eslint-disable-next-line prefer-const
let loggerEnabled = __DEV__

export const setLoggerEnabled = (enabled: boolean) => {
  loggerEnabled = enabled
}

let extraLogger: LoggerFn | undefined

// eslint-disable-next-line no-shadow
export const setExtraLogger = (logger: LoggerFn) => {
  extraLogger = logger
}

export type LoggerFn = (message?: any, ...optionalParams: any[]) => void

export const logger: LoggerFn = (message?: unknown, ...optionalParams: any[]) => {
  if (loggerEnabled) {
    // This following console.log is the only one that should be allowed in this project
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams)
    extraLogger?.(message, ...optionalParams)
  }
}

export let logI18n = false

export const setLogI18n = (enabled: boolean) => {
  logI18n = enabled
}

export let logUser = false

export const setLogUser = (enabled: boolean) => {
  logUser = enabled
}
