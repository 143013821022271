// TO KEEP EVEN IF NOT USED
import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { StyleSheet, TextProps } from "react-native"

import { Clock } from "../Clock"

interface IProps {
  start: boolean
  reset?: boolean
  maxTime?: number // milliseconds
  setProgress?: (arg1?: number) => void
}

interface IState {
  elapsed?: number // milliseconds
  startTime?: number
}

const defaultStates: IState = {
  elapsed: undefined,
  startTime: undefined,
}

const Chronometer: FC<IProps & TextProps> = ({ reset, start, setProgress, maxTime, style }) => {
  const [isFinished, setIsFinished] = useState(false)
  const startTime = useRef<number | undefined>(0)
  const [elapsed, setElapsed] = useState<number>()
  const interval = useRef<NodeJS.Timeout>()

  const clearIntervalTimer = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current)
      interval.current = undefined
    }
  }, [])

  const resetTimer = useCallback(() => {
    setIsFinished(false)
    setElapsed(defaultStates.elapsed)
    startTime.current = defaultStates.startTime
    clearIntervalTimer()
  }, [clearIntervalTimer])

  const stopTimer = clearIntervalTimer

  const startTimer = useCallback(() => {
    startTime.current = elapsed ? Date.now() - elapsed : Date.now()
    if (!interval.current && !isFinished) {
      interval.current = setInterval(() => {
        const spent = Date.now() - (startTime.current || 0)
        setElapsed(spent)
        if (maxTime) {
          setProgress?.(spent)
          if (maxTime < spent) {
            if (reset) {
              setProgress?.()
              resetTimer()
            } else {
              stopTimer()
              setIsFinished(true)
            }
          }
        }
      }, 1000)
    }
  }, [elapsed, isFinished, maxTime, reset, resetTimer, setProgress, stopTimer])

  useEffect(() => {
    if (start) {
      startTimer()
    } else {
      stopTimer()
    }
    if (reset) {
      resetTimer()
    }
    return () => {
      clearIntervalTimer()
    }
  }, [clearIntervalTimer, reset, resetTimer, start, startTimer, stopTimer])

  return <Clock style={[styles.text, style]} time={elapsed || 0} />
}

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
  },
})

export default Chronometer
