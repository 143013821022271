import { useNavigation } from "@react-navigation/native"
import {
  Button,
  IUserContext,
  Subtitle1,
  Subtitle2,
  userContext,
  useTheme,
  VectorIcon,
} from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { ICON_SIZE_SMALL } from "../features/config/Constants"
import { JourneyNS } from "../features/i18n/constants"
import { useProtocolPhases } from "../features/models/PhaseAndSessionFunctions"
import { AppUserData, Patient } from "../features/models/UserData"

interface ProtocolInfoViewrops {
  patient: Patient
  isProgramEnded: boolean | undefined
}

const ProtocolInfoView: FC<ProtocolInfoViewrops> = ({ patient, isProgramEnded }) => {
  const {
    fontMaker,
    colors: {
      accent,
      primary,
      selection,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()
  const navigation = useNavigation<any>()
  const { t } = useTranslation(JourneyNS)
  const { userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const protocolPhases = useProtocolPhases(true)
  const surgeryDate = dayjs(patient.surgeryDate).format("DD MMM YYYY")
  const endDateTimestamp = new Date(patient.programEnd?.seconds * 1000)
  const endDate = dayjs(endDateTimestamp).format("DD MMM YYYY")
  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          backgroundColor: accent,
          margin: spacing,
          padding: spacing / 1.5,
        },
      ],
      card: [
        styles.card,
        {
          paddingVertical: spacing,
        },
      ],
      primaryCard: [
        styles.primaryCard,
        {
          backgroundColor: primary,
          marginBottom: spacing / 2,
          padding: spacing / 2,
        },
      ],
      lineHeight: {
        lineHeight: spacing * 1.2,
      },
      boldText: [fontMaker({ weight: "Bold" })],
      dateText: {
        marginTop: spacing / 4,
      },
      iconView: [
        styles.iconView,
        {
          marginRight: spacing / 2,
          padding: spacing / 2,
          backgroundColor: selection,
        },
      ],
      primaryCardText: [
        styles.centerText,
        {
          color: white,
        },
      ],
      lightView: {
        paddingVertical: spacing,
        marginRight: spacing / 4,
      },
      button: [
        styles.alignContainer,
        {
          backgroundColor: primary,
        },
      ],
    }),
    [spacing, accent, fontMaker, primary, white, selection],
  )
  return (
    <View style={s.container}>
      <View style={styles.cardContainer}>
        <View style={s.card}>
          <View style={s.iconView}>
            <VectorIcon size={ICON_SIZE_SMALL} name="calendar-today" category="MaterialIcons" />
          </View>
          <View>
            <Subtitle2 style={[s.lineHeight]}>{t("protocolInfo.surgeryDate")}</Subtitle2>
            <Subtitle1 style={[s.boldText, s.lineHeight]}>{surgeryDate}</Subtitle1>
          </View>
        </View>
        <View style={s.primaryCard}>
          <Subtitle2 style={[s.primaryCardText, s.lineHeight]}>
            {isProgramEnded ? t("protocolInfo.endedDate") : t("protocolInfo.endDate")}
          </Subtitle2>
          <Subtitle2 style={[s.boldText, s.primaryCardText, s.lineHeight, s.dateText]}>
            {endDate}
          </Subtitle2>
        </View>
      </View>
      {!_.isEmpty(protocolPhases) ? (
        <Button
          style={s.button}
          labelStyle={s.boldText}
          onPress={() => navigation.navigate("Patient_Protocol_Timeline", { id: userDocRef?.id })}
        >
          {t("protocolInfo.button")}
        </Button>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    overflow: "hidden",
  },
  cardContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  alignContainer: {
    alignItems: "center",
  },
  centerText: {
    textAlign: "center",
  },
  primaryCard: {
    justifyContent: "center",
    width: "50%",
    borderRadius: 8,
  },
  icon: {
    alignSelf: "flex-start",
  },
  iconView: {
    borderRadius: 50,
  },
})

export default ProtocolInfoView
