import { Subtitle1, useTheme, VIDEO_RATIO } from "capsule"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, useWindowDimensions, View } from "react-native"

import { ExerciseNS } from "../features/i18n/constants"

const EmptyVideo = () => {
  const {
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation(ExerciseNS)
  const { width } = useWindowDimensions()
  const s = useMemo(
    () => ({
      empty: [
        styles.empty,
        {
          padding: spacing,
          height: width / VIDEO_RATIO,
        },
      ],
    }),
    [spacing, width],
  )

  return (
    <View style={s.empty}>
      <Subtitle1 emphasis="high" style={styles.subtitle}>
        {t("empty")}
      </Subtitle1>
    </View>
  )
}

const styles = StyleSheet.create({
  empty: {
    width: "100%",
    justifyContent: "center",
  },
  subtitle: {
    textAlign: "center",
  },
})

export default EmptyVideo
