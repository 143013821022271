import { H5, Subtitle1 } from "capsule"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { Question } from "../common/Proms"
import { itemField } from "../features/hooks/useItemI18n"

interface IProps {
  data: Question
}

const AlertForm: FC<IProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <H5 style={styles.text}>{itemField(data, "title", t)}</H5>
      <Subtitle1 style={styles.text}>{itemField(data, "description", t)}</Subtitle1>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
  },
})

export default AlertForm
