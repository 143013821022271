import { FC, ReactElement } from "react"

import useDelay from "./useDelay"

const Delayed: FC<{ children: ReactElement }> = ({ children }) => {
  const [showChildren] = useDelay()

  return showChildren ? children : null
}

export default Delayed
