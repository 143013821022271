import { SectionSeparator, Subtitle2, useTheme, VectorIcon, VectorIconCategory } from "capsule"
import _ from "lodash"
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from "react"
import { ImageSourcePropType, ImageURISource, StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"
import { List } from "react-native-paper"

import { ICON_SIZE } from "../features/config/Constants"

interface IProps {
  title: string
  description?: string
  showBottomSeparator?: boolean
  category?: VectorIconCategory
  leftIcon: string | ImageSourcePropType | ImageSourcePropType[]
  expandList: boolean
}

export function isImage(
  icon: string | ImageSourcePropType | ImageSourcePropType[],
): icon is ImageSourcePropType | ImageSourcePropType[] {
  return _.isNumber(icon as ImageURISource)
}

const AccordionSection: FC<PropsWithChildren<IProps>> = ({
  leftIcon,
  category,
  title,
  description,
  children,
  expandList,
  showBottomSeparator = true,
}) => {
  const {
    typography: { h6 },
    dimensions: { spacing },
    colors: {
      surface: { card },
      black: { highEmphasis: black },
    },
  } = useTheme()
  const [expanded, setExpanded] = useState(expandList)
  const onPress = useCallback(() => setExpanded(old => !old), [])

  const left = useMemo(
    () => () =>
      isImage(leftIcon) ? (
        <FastImage source={leftIcon as number} style={styles.icon} />
      ) : (
        <VectorIcon
          name={leftIcon}
          category={category ?? "MaterialIcons"}
          size={ICON_SIZE}
          color={black}
        />
      ),
    [black, category, leftIcon],
  )

  const right = useMemo(
    () => (props: { expandList: boolean }) => (
      <VectorIcon
        name={`chevron-${props.expandList ? "up" : "down"}`}
        category="MaterialCommunityIcons"
        size={ICON_SIZE}
        color={black}
      />
    ),
    [black],
  )

  const { titleStyle, descriptionStyle, style } = useMemo(
    () => ({
      titleStyle: [h6, { paddingLeft: spacing / 2 }],
      descriptionStyle: [Subtitle2, { paddingLeft: spacing / 2 }],
      style: { paddingHorizontal: spacing, backgroundColor: card },
    }),
    [card, h6, spacing],
  )

  return (
    <>
      {/* @ts-ignore*/}
      <List.Accordion
        {...{
          onPress,
          expanded,
          title,
          description,
          left,
          right,
          titleStyle,
          descriptionStyle,
          style,
        }}
      >
        <SectionSeparator />
        <View style={styles.view}>{children}</View>
      </List.Accordion>
      {showBottomSeparator || !expanded ? <SectionSeparator /> : null}
    </>
  )
}

const styles = StyleSheet.create({
  view: {
    paddingLeft: 0,
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
})

export default AccordionSection
