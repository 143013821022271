import React, { FC, useMemo } from "react"
import { Platform, Pressable } from "react-native"
import { TouchableRipple } from "react-native-paper"

import useTheme from "../../features/Theme/ThemeProvider"

export type ITouchableRippleProps = React.ComponentProps<typeof TouchableRipple>

const Touchable: FC<ITouchableRippleProps> = props => {
  const { button } = useTheme()
  const TouchableElem = Platform.OS === "android" ? TouchableRipple : Pressable

  const { style } = useMemo(
    () => ({
      style: [
        {
          height: button?.height,
          borderRadius: button?.roundness,
        },
        props.style,
      ],
    }),
    [button?.height, button?.roundness, props.style],
  )

  return <TouchableElem {...props} {...{ style }} />
}

export default Touchable
