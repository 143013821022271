import { useWindowDimensions } from "react-native"

import useTheme from "../features/Theme/ThemeProvider"

const useTabLand = () => {
  const dimensions = useWindowDimensions()
  const {
    dimensions: { isTabLand },
  } = useTheme()

  return isTabLand(dimensions)
}

export const useTabTwoPane = () => {
  const dimensions = useWindowDimensions()
  const {
    dimensions: { isTabTwoPane },
  } = useTheme()

  return isTabTwoPane(dimensions)
}

export default useTabLand
