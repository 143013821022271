import { FormikTextInput, useTheme } from "capsule"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { PromsNS } from "../features/i18n/constants"

interface IProps {
  name: string
}

const TextForm: FC<IProps> = ({ name }) => {
  const {
    colors: {
      black: { mediumEmphasis },
    },
    dimensions: { spacing },
  } = useTheme()

  const { t } = useTranslation(PromsNS)

  const s = useMemo(
    () => ({
      container: {
        marginBottom: spacing / 2,
      },
      textInput: [
        styles.textInput,
        {
          margin: spacing,
        },
      ],
    }),
    [spacing],
  )

  return (
    <FormikTextInput
      style={s.textInput}
      viewStyle={s.container}
      underlineColor={mediumEmphasis}
      numberOfLines={3}
      multiline
      label={t("placeholder")}
      changeUnderline={false}
      {...{ name }}
    />
  )
}
const styles = StyleSheet.create({
  textInput: {
    maxHeight: 96,
    marginTop: 0,
    alignContent: "center",
    flexShrink: 1,
  },
})

export default TextForm
