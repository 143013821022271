import Slider from "@react-native-community/slider"
import { Caption, useTheme } from "capsule"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Dimensions, Platform, StyleSheet, View } from "react-native"
import Svg, { Polygon } from "react-native-svg"

import { itemField } from "../features/hooks/useItemI18n"

const { width } = Dimensions.get("window")
const sliderWidth = width - 40

const StepMarker = ({ stepMarked }) => (
  <View style={[styles.stepMarker, stepMarked && styles.stepMarkerActive]} />
)

const VisualAnalogScale = ({ data, setValue, value }) => {
  const { t } = useTranslation()
  const handleValueChange = useCallback(
    newValue => {
      setValue(newValue)
    },
    [setValue],
  )

  const {
    colors: { primary, secondary },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      container: {
        margin: spacing,
      },
      labelsContainer: [
        styles.labelsContainer,
        {
          marginVertical: spacing,
        },
      ],
    }),
    [spacing],
  )

  return (
    <View style={s.container}>
      <View style={s.labelsContainer}>
        <Caption style={styles.labelLeft}>{itemField(data?.[0], "title", t)}</Caption>
        <Caption style={styles.labelRight}>{itemField(data?.[1], "title", t)}</Caption>
      </View>
      <View style={styles.triangleContainer}>
        <Svg height="60" width={sliderWidth} style={styles.triangleSvg}>
          <Polygon
            points={`0,60 ${sliderWidth},60 ${sliderWidth},0`}
            fill="rgba(22, 154, 159, 0.2)"
          />
        </Svg>
        <Svg height="60" width={sliderWidth} style={styles.triangleSvg2}>
          <Polygon
            points={`0,0 ${sliderWidth},0 ${sliderWidth},60`}
            fill="rgba(22, 154, 159, 0.2)"
          />
        </Svg>
        <View style={styles.sliderContainer}>
          <Slider
            style={styles.slider}
            minimumValue={data[0]?.value}
            maximumValue={data[1]?.value}
            step={1}
            value={value}
            onSlidingComplete={handleValueChange}
            minimumTrackTintColor={primary}
            maximumTrackTintColor={secondary}
            thumbTintColor={primary}
            renderStepNumber
            StepMarker={StepMarker}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  labelsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  triangleContainer: {
    position: "relative",
    width: "100%",
    alignItems: "center",
    marginTop: 10,
  },
  triangleSvg: {
    position: "absolute",
    top: Platform.OS === "android" ? -30 : -20,
    left: 0,
  },
  triangleSvg2: {
    position: "absolute",
    top: Platform.OS === "android" ? 30 : 40,
    left: 0,
  },
  sliderContainer: {
    width: "100%",
    paddingHorizontal: 15,
  },
  slider: {
    width: "100%",
    height: 20,
    marginTop: 20,
  },
  stepMarker: {
    width: 1,
    height: 10,
    backgroundColor: "grey",
  },
  stepMarkerActive: {
    backgroundColor: "white",
  },
  labelLeft: {
    textAlign: "left",
    marginVertical: 10,
    flex: 1,
  },
  labelRight: {
    textAlign: "right",
    marginVertical: 10,
    flex: 1,
  },
})

export default VisualAnalogScale
