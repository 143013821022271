import { useTheme, VectorIcon } from "capsule"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import ColorCircle from "../../components/ColorCircle"
import { RadioItem } from "../../components/Rating"
import { CIRCLE_DEFAULT_SIZE } from "../../features/config/Constants"
import { EvaluationNS } from "../../features/i18n/constants"

export const useItems = (overrideColor?: string, size?: number) => {
  const {
    colors: {
      primary,
      black: { mediumEmphasis: black },
    },
  } = useTheme()
  const { t } = useTranslation(EvaluationNS)
  const renderPainIcon = useMemo(
    () => (name: string, color: string) => (
      <VectorIcon
        {...{ name }}
        size={size || CIRCLE_DEFAULT_SIZE}
        color={overrideColor || color}
        category="MaterialCommunityIcons"
      />
    ),
    [overrideColor, size],
  )

  const renderEffortIcon = useMemo(
    () => (backgroundColor: string, color: string) => (
      <ColorCircle {...{ backgroundColor, color, size }} />
    ),
    [size],
  )

  return useMemo<{ efforts: RadioItem[]; pains: RadioItem[] }>(
    () => ({
      pains: [
        {
          label: t("pains.none"),
          value: 1,
          checked: renderPainIcon("emoticon-excited-outline", primary),
          unchecked: renderPainIcon("emoticon-excited-outline", black),
        },
        {
          label: t("pains.weak"),
          value: 2,
          checked: renderPainIcon("emoticon-happy-outline", primary),
          unchecked: renderPainIcon("emoticon-happy-outline", black),
        },
        {
          label: t("medium"),
          value: 3,
          checked: renderPainIcon("emoticon-neutral-outline", primary),
          unchecked: renderPainIcon("emoticon-neutral-outline", black),
        },
        {
          label: t("pains.strong"),
          value: 4,
          checked: renderPainIcon("emoticon-sad-outline", primary),
          unchecked: renderPainIcon("emoticon-sad-outline", black),
        },
        {
          label: t("pains.intolerable"),
          value: 5,
          checked: renderPainIcon("emoticon-dead-outline", primary),
          unchecked: renderPainIcon("emoticon-dead-outline", black),
        },
      ],
      efforts: [
        {
          label: t("efforts.easy"),
          value: 1,
          checked: renderEffortIcon("#B0FFA4", primary),
          unchecked: renderEffortIcon("#B0FFA4", black),
        },
        {
          label: t("medium"),
          value: 2,
          checked: renderEffortIcon("#D7FFA4", primary),
          unchecked: renderEffortIcon("#D7FFA4", black),
        },
        {
          label: t("efforts.important"),
          value: 3,
          checked: renderEffortIcon("#F8FFA4", primary),
          unchecked: renderEffortIcon("#F8FFA4", black),
        },
        {
          label: t("efforts.difficult"),
          value: 4,
          checked: renderEffortIcon("#FFE5A4", primary),
          unchecked: renderEffortIcon("#FFE5A4", black),
        },
        {
          label: t("efforts.maximal"),
          value: 5,
          checked: renderEffortIcon("#FFAFA4", primary),
          unchecked: renderEffortIcon("#FFAFA4", black),
        },
      ],
    }),
    [black, primary, renderEffortIcon, renderPainIcon, t],
  )
}
