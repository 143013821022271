import { useTheme } from "capsule"
import { AdditionalFieldsEditScreen } from "capsule/components/Screens/AdditionalFieldsEditScreen"
import { AdditionalFieldsScreen } from "capsule/components/Screens/AdditionalFieldsScreen"
import React, { FC, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import AccordionSection from "../../components/AccordionSection"
import { KNEE_PROSTHESIS_PROTOCOLS, SMITH_NEPHEW_PARTNER } from "../../features/config/Constants"
import { PatientNS } from "../../features/i18n/constants"
import { PatientProps } from "./PatientScreen"

const AdditionalFieldsView: FC<PatientProps> = ({ patient }) => {
  const { surgeryAdditionalInfo, surgeryDuration, bipolar, id, pathology, partner } = patient
  const [editable, setEditable] = useState(false)

  const isSmithNephew = useMemo(
    () => partner === SMITH_NEPHEW_PARTNER && KNEE_PROSTHESIS_PROTOCOLS.includes(pathology),
    [partner, pathology],
  )

  const emptySurgeryFields =
    !surgeryDuration && !surgeryAdditionalInfo && (!bipolar || !isSmithNephew)

  const {
    dimensions: { spacing },
    colors: {
      white: { highEmphasis: white },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      container: [
        {
          backgroundColor: white,
          paddingVertical: spacing,
        },
      ],
    }),
    [white, spacing],
  )
  const { t } = useTranslation()

  const RenderScreen =
    editable || emptySurgeryFields ? AdditionalFieldsEditScreen : AdditionalFieldsScreen

  return (
    <AccordionSection
      title={t(`${PatientNS}:additionalInformation`)}
      description={t(`${PatientNS}:toFill`)}
      leftIcon="build-circle"
      expandList={false}
    >
      <View style={s.container}>
        <RenderScreen
          surgeryDuration={surgeryDuration}
          surgeryAdditionalInfo={surgeryAdditionalInfo}
          bipolar={bipolar}
          patientId={id}
          setEditable={setEditable}
          isSmithNephew={isSmithNephew}
        />
      </View>
    </AccordionSection>
  )
}

export default AdditionalFieldsView
