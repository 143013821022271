import AsyncStorage from "@react-native-async-storage/async-storage"
import { logger, logUser } from "capsule/utils/logger"
import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { DataOptions } from "react-firebase-hooks/firestore/dist/firestore/types"
import { Platform } from "react-native"
import RNRestart from "react-native-restart"

import { AppUserData } from "../../../features/models/UserData"
import delay from "../../utils/delay"
import isValidEmail from "../../utils/isValidEmail"
import {
  auth,
  collections,
  dynamicLinks,
  FirebaseAuthTypes,
  FirebaseDynamicLinksTypes,
  FirebaseFirestoreTypes,
  firestore,
  messaging,
} from "../Firebase"

interface IProps {
  children: ReactNode
  confirmationMail?: boolean
  options?: DataOptions<any>
  /** disableToken: true to avoid getting a push token and triggering push permission requests */
  disableToken?: boolean
}

export interface IUserContext<IUserData> {
  /** the Firebase user object, once authentication is complete */
  user: FirebaseAuthTypes.User | null
  /** the contents of `login/{uid}` in Firestore with transform method for inner fields */
  userData?: IUserData
  /** reference to `login/{uid}` */
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null
  /** during login, any error reported by Firebase */
  error?: string
  /** return the admin user claims if there is one, otherwise returns false */
  isAdmin: boolean
  /** set to true during authentication */
  loading: boolean
  /** set to false once the user is loaded (or null) at app startup */
  initializing: boolean
  /** set to true until userData is loaded (or determined to be undefined), switches back to true whenever userData is being updated */
  userDataLoading: boolean
  /** can be used by custom in-app login to set error states */
  setError: Dispatch<SetStateAction<string | undefined>>
  logout: () => Promise<void>
  login: (email: string, password: string) => void
  loginWithCustomToken: (customToken: string) => Promise<void>
  register: (email: string, password: string) => void
  /** password-less sign in by email **/
  signInWithEmail: (email: string, actionCodeSettings: FirebaseAuthTypes.ActionCodeSettings) => void
  handleDynamicLink: (link: FirebaseDynamicLinksTypes.DynamicLink) => void
  reauthenticate: (password: string) => Promise<FirebaseAuthTypes.UserCredential | undefined>
  /** Run an authenticate method (ex: cloud function) with its parameters inside UserProvider and retrieve its customToken or errors  */
  customLogin<LoginParams extends any[]>(
    authenticate: (...authParams: LoginParams) => Promise<string>,
    ...params: LoginParams
  ): Promise<void>
}

export const userContext = createContext<IUserContext<any>>({} as IUserContext<any>)

function UserProvider<IUserData extends Record<string, unknown>>({
  children,
  confirmationMail,
  options,
  disableToken,
}: IProps) {
  // loading state for each user steps
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initializing, setInitializing] = useState<boolean>(true)

  const [error, setError] = useState<string | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(undefined)

  // firestore user, doc and data
  const [user, setUser] = useState<FirebaseAuthTypes.User | null>(auth().currentUser)
  const userDocRef = useMemo(
    () => user && firestore().collection(collections.LOGIN).doc(user?.uid),
    [user],
  )
  // @ts-ignore
  const [userData, userDataLoading] = useDocumentData<IUserData>(userDocRef, options)
  if (logUser) {
    logger("\nUserProvider", JSON.stringify(user?.toJSON(), null, 2))
  }

  // Save user pushTokens
  useEffect(() => {
    if (!userDocRef || !token) {
      logger("No push token", { userDocRef, token })
      return
    }
    logger("Push token", { token, userDocRef: userDocRef.path })
    delay(1000).then(() =>
      userDocRef
        .set({ pushTokens: { [token]: true } }, { merge: true })
        .catch(e => logger("push token error", e)),
    )
  }, [token, user, userDocRef])

  /* Detect when user status is changed
   * !_isObject useful : user has only properties with getter
   * Necessary to detect when user becomes null and to warn the app
   *  */
  useEffect(
    () =>
      auth().onUserChanged(async (updatedUser: FirebaseAuthTypes.User | null) => {
        if (logUser) {
          logger(
            "\nonAuthStateChanged",
            JSON.stringify(updatedUser?.toJSON(), null, 2),
            JSON.stringify(auth().currentUser?.toJSON(), null, 2),
          )
        }
        if (!_.isObject(updatedUser) || !_.isEmpty(updatedUser?.toJSON())) {
          setUser(updatedUser)
        }
        setInitializing(false)
        if (!disableToken) {
          try {
            const tmpToken = await messaging?.().getToken?.()
            setToken(tmpToken)
          } catch (e) {
            logger("Can't get token", e)
          }
        }
      }),
    [disableToken],
  )

  useEffect(() => {
    if (Platform.OS === "web") {
      return undefined
    }
    const unsubscribe = dynamicLinks().onLink(handleDynamicLink)

    /* When the app is not running and is launched by a magic link the `onLink`
            method won't fire, we can handle the app being launched by a magic link like this */
    dynamicLinks()
      .getInitialLink()
      .then(link => link && handleDynamicLink(link))

    // cleaning listener on unmount
    return () => unsubscribe()
  }, [])

  const handleDynamicLink = async (link: FirebaseDynamicLinksTypes.DynamicLink) => {
    logger("handleDynamicLink", link)
    setError(undefined)
    if (_.isEmpty(link)) {
      setError("emptyField")
      return
    }

    // Check and handle if the link is an email login link
    if (!auth().isSignInWithEmailLink(link.url)) {
      logger("auth/invalid-link")
      setError("auth/invalid-link")
      return
    }
    setLoading(true)

    try {
      const email = await AsyncStorage.getItem("emailForSignIn")
      logger("email", email)
      if (!email) {
        setError("auth/invalid-email")
        return
      }
      await auth().signInWithEmailLink(email, link.url)
      await AsyncStorage.removeItem("emailForSignIn")
    } catch (e) {
      setError(e.code)
      logger("Firebase login", e.code, e.message)
    } finally {
      setLoading(false)
    }
  }

  const reauthenticate = async (password: string) => {
    const userCredential = auth.EmailAuthProvider.credential(user?.email ?? "", password)
    return user?.reauthenticateWithCredential(userCredential)
  }

  const register = async (email: string, password: string) => {
    setError(undefined)
    setInitializing(true)
    if (_.isEmpty(email) || _.isEmpty(password)) {
      setError("emptyField")
      return
    }
    if (!isValidEmail(email)) {
      setError("auth/invalid-email")
      return
    }
    setLoading(true)
    try {
      const { user: userLocal } = await auth().createUserWithEmailAndPassword(email, password)
      confirmationMail && userLocal?.sendEmailVerification()
      await login(email, password)
    } catch (e) {
      setError(e.code)
      logger("Firebase create user", e.code, e.message)
    } finally {
      setLoading(false)
    }
  }

  const signInWithEmail = async (
    email: string,
    actionCodeSettings: FirebaseAuthTypes.ActionCodeSettings,
  ) => {
    setError(undefined)
    if (_.isEmpty(email) || _.isEmpty(actionCodeSettings)) {
      setError("emptyField")
      return
    }
    if (!isValidEmail(email)) {
      setError("auth/invalid-email")
      return
    }
    setLoading(true)
    try {
      await AsyncStorage.setItem("emailForSignIn", email)
      await auth().sendSignInLinkToEmail(email, actionCodeSettings)
    } catch (e) {
      setError(e.code)
      logger("Firebase create user with signIn email", e.code, e.message)
    } finally {
      setLoading(false)
    }
  }

  // todo issue with android, the try catch is "unused"
  const login = async (email: string, password: string) => {
    setError(undefined)
    if (_.isEmpty(email) || _.isEmpty(password)) {
      setError("emptyField")
      return
    }
    if (!isValidEmail(email)) {
      setError("auth/invalid-email")
      return
    }
    setLoading(true)
    try {
      await auth().signInWithEmailAndPassword(email, password)
    } catch (e) {
      setError(e.code)
      logger("Firebase login", e.code, e.message)
    } finally {
      setLoading(false)
    }
  }

  const loginWithCustomToken = async (customToken: string) => {
    if (_.isEmpty(customToken)) {
      setError("emptyField")
      return
    }
    setLoading(true)
    try {
      await auth().signInWithCustomToken(customToken)
    } catch (e) {
      setError(e.code)
      logger("Firebase login", e.code, e.message)
    } finally {
      delay(500).then(() => setLoading(false))
    }
  }

  const customLogin = async <LoginParams extends any[]>(
    authenticate: (...authParams: LoginParams) => Promise<string>,
    ...params: LoginParams
  ) => {
    try {
      setLoading(true)
      setError(undefined)
      const customToken = await authenticate(...params)
      await loginWithCustomToken(customToken)
    } catch (e) {
      logger("Firebase custom login", e.code, e.message)
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const logout = async () => {
    setInitializing(true)
    try {
      await auth()
        .signOut()
        .then(() => {
          RNRestart.Restart()
        })
    } catch (e) {
      logger("Firebase logout", e.code, e.message)
    } finally {
      setError(undefined)
    }
  }

  useEffect(() => {
    ;(async () => {
      const res = await user?.getIdTokenResult()
      setIsAdmin(res?.claims?.admin)
    })()
  }, [user])

  const contextValue: IUserContext<IUserData> = {
    user,
    userData,
    userDocRef,
    error,
    isAdmin,
    loading,
    initializing,
    userDataLoading,
    login,
    handleDynamicLink,
    logout,
    register,
    setError,
    customLogin,
    reauthenticate,
    signInWithEmail,
    loginWithCustomToken,
  }

  return <userContext.Provider value={contextValue}>{children}</userContext.Provider>
}

export const useUser = (): IUserContext<AppUserData> => {
  const context = useContext<IUserContext<AppUserData>>(userContext)
  if (_.isEmpty(context)) {
    throw new Error("useUser must be used within a UserProvider")
  }
  return context
}

export default UserProvider
