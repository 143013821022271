import { Body2, FontAwesome } from "capsule"
import { Field, FormikConfig, FormikValues } from "formik"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Dimensions,
  Keyboard,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native"
import SelectDropdown from "react-native-select-dropdown"
import * as Yup from "yup"

import { FormikTextInput, InputContainerStyle } from "../../capsule/components/FormBis"
import { InputScreen } from "../../capsule/components/Screens/InputScreen"
import useTheme from "../../capsule/features/Theme/ThemeProvider"
import { logger } from "../../capsule/utils/logger"

export interface AddressFormType {
  street_number: string
  route: string
  additional_address: string
  postal_code: string
  locality: string
  country: string
}

const defaultNames: AddressFormType = {
  street_number: "street_number",
  route: "route",
  additional_address: "additional_address",
  postal_code: "postal_code",
  locality: "locality",
  country: "country",
}

interface CountriesList {
  code: string
  title: string
  flag: string
  callingCode: string
}

interface IProps<Values> {
  inputStyle?: StyleProp<ViewStyle>
  viewStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  formikConfig?: FormikConfig<Values>
  address?: {
    street_number: string
    route: string
    additional_address: string
    postal_code: string
    locality: string
    country: string
  }
  styles?: InputContainerStyle
  formLoading: boolean
  countries: CountriesList[]
}

export const AddressFormikConfig = <Values extends FormikValues>(options?: {
  onSubmit?: (args: Values) => void
  isOnMount: boolean
  initialValues?: AddressFormType
  validationSchema?: any
}) =>
  ({
    validateOnMount: options?.isOnMount,
    onSubmit: options?.onSubmit || logger,
    initialValues: options?.initialValues || {
      street_number: "",
      route: "",
      additional_address: "",
      postal_code: "",
      locality: "",
      country: "France",
    },
    validationSchema:
      options?.validationSchema ||
      Yup.object<AddressFormType>({
        street_number: Yup.string().required(),
        route: Yup.string().required(),
        additional_address: Yup.string(),
        postal_code: Yup.string().required(),
        locality: Yup.string().required(),
        country: Yup.string().required(),
      }),
  } as FormikConfig<AddressFormType>)

const screenWidth = Dimensions.get("window").width

export const AddressInputScreen = <Values extends FormikValues>({
  formikConfig,
  viewStyle,
  styles,
  buttonStyle,
  address = defaultNames,
  inputStyle,
  formLoading,
  countries,
}: IProps<Values>) => {
  const {
    colors: {
      black: { transparent },
      accent,
      surface,
    },
    dimensions: { spacing },
  } = useTheme()
  const s = useMemo(
    () => ({
      body: [
        bodyStyle.body,
        {
          padding: spacing,
          marginBottom: spacing,
          backgroundColor: accent,
        },
      ],
    }),
    [spacing, accent],
  )
  const { t } = useTranslation()
  const style = { backgroundColor: transparent }

  const InputElem = FormikTextInput

  const formikStreetNumberProps = {
    name: address.street_number,
  }
  const formikRouteProps = {
    name: address.route,
  }
  const formikAddAddressProps = {
    name: address.additional_address,
  }
  const formikPostalCodeProps = {
    name: address.postal_code,
  }
  const formikLocalityProps = {
    name: address.locality,
  }

  const WebSelectDropdown = ({ data, defaultValue, onSelect }) => (
    <select
      value={defaultValue.title}
      onChange={e => {
        // @ts-ignore
        const selectedCountry = e.target.value
        const selectedItem = data.find(item => item.title === selectedCountry)
        onSelect(selectedItem)
      }}
      style={{
        width: "100%",
        padding: 8,
        backgroundColor: surface.textInput,
        border: "none",
        marginBottom: Platform.OS === "web" ? 20 : 0,
        fontFamily: "Montserrat",
      }}
    >
      {data.map(item => (
        <option key={item.code} value={item.title}>
          {`${item.flag} ${t(`fields.label.${item.title}`)}`}
        </option>
      ))}
    </select>
  )

  return (
    <InputScreen<Values>
      Inputs={
        <View>
          <Body2 style={s.body}>{t("AddressInfo")}</Body2>
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikStreetNumberProps, styles }}
            label={t("fields.label.street_number")}
            style={inputStyle}
            autoComplete="off"
            autoCorrect={false}
            autoCapitalize="none"
            onSubmitEditing={() => {
              Keyboard.dismiss()
            }}
          />
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikRouteProps, styles }}
            label={t("fields.label.route")}
            style={inputStyle}
            autoComplete="off"
            autoCorrect={false}
            autoCapitalize="none"
            inputInfo={t("fields.inputInfo.route")}
            onSubmitEditing={() => {
              Keyboard.dismiss()
            }}
          />
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikAddAddressProps, styles }}
            label={t("fields.label.additional_address")}
            style={inputStyle}
            autoComplete="off"
            autoCorrect={false}
            autoCapitalize="none"
            inputInfo={t("fields.inputInfo.additional_address")}
            onSubmitEditing={() => {
              Keyboard.dismiss()
            }}
          />
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikPostalCodeProps, styles }}
            label={t("fields.label.postal_code")}
            style={inputStyle}
            autoComplete="off"
            autoCorrect={false}
            autoCapitalize="none"
            onSubmitEditing={() => {
              Keyboard.dismiss()
            }}
          />
          <InputElem
            changeUnderline={false}
            {...{ style, ...formikLocalityProps, styles }}
            label={t("fields.label.locality")}
            style={inputStyle}
            autoComplete="off"
            autoCorrect={false}
            autoCapitalize="none"
            returnKeyType="done"
            onSubmitEditing={() => {
              Keyboard.dismiss()
            }}
          />
          <Field name="country">
            {({ field, form }) => {
              const selectedCountry = countries.find(c => c.title === field.value)
              return (
                <>
                  {Platform.OS === "web" ? (
                    <WebSelectDropdown
                      data={countries}
                      defaultValue={selectedCountry}
                      onSelect={selectedItem => {
                        form.setFieldValue(field.name, selectedItem.title)
                      }}
                    />
                  ) : (
                    <SelectDropdown
                      data={countries}
                      onSelect={selectedItem => {
                        form.setFieldValue(field.name, selectedItem.title)
                      }}
                      onFocus={() => Keyboard.dismiss()}
                      defaultValue={selectedCountry}
                      buttonTextAfterSelection={selectedItem =>
                        `${selectedItem.flag} ${t(`fields.label.${selectedItem.title}`)}`
                      }
                      rowTextForSelection={item =>
                        `${item.flag} ${t(`fields.label.${item.title}`)}`
                      }
                      buttonStyle={{
                        width: screenWidth - 30,
                        marginBottom: 30,
                        backgroundColor: surface.textInput,
                      }}
                      renderDropdownIcon={isOpened => (
                        <FontAwesome
                          name={isOpened ? "chevron-up" : "chevron-down"}
                          color="#444"
                          size={18}
                        />
                      )}
                      dropdownStyle={{
                        width: screenWidth - 30,
                      }}
                      buttonTextStyle={bodyStyle.textStyle}
                      rowTextStyle={bodyStyle.textStyle}
                    />
                  )}
                </>
              )
            }}
          </Field>
        </View>
      }
      formLoading={formLoading}
      buttonLabel={t("button.save")}
      disableButtonMargin
      {...{ viewStyle, formikConfig }}
      buttonStyle={{ style: buttonStyle }}
    />
  )
}

const bodyStyle = StyleSheet.create({
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  textStyle: {
    fontFamily: "Montserrat",
  },
})
