import { useNavigation } from "@react-navigation/native"
import {
  Button,
  firestore,
  generateShadow,
  IUserContext,
  openUrl,
  OptimisticSwitch,
  Subtitle1,
  TextInput,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"
import { Text } from "react-native-paper"

import { images } from "../assets/images"
import { ReviewUrl } from "../common/Proms"
import { collections } from "../common/types"
import { urls } from "../features/config/Constants"
import { PractitionerHomeNS } from "../features/i18n/constants"
import { AppUserData } from "../features/models/UserData"
import { updateSurgeonReviewUrl } from "../features/models/UserDataFunctions"
import { maxWidth } from "../ThemeApp"

const ReviewUrlView = () => {
  const {
    colors: {
      overrides,
      primary,
      error,
      black: { highEmphasis: black },
      surface,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()
  const navigation = useNavigation()
  const { user } = useContext<IUserContext<AppUserData>>(userContext)
  const [review] = useDocumentData<ReviewUrl>(
    user
      ? ((firestore()
          .collection(collections.REVIEW_URLS)
          .doc(user?.uid) as unknown) as firebase.firestore.DocumentReference)
      : undefined,
  )
  const [value, setValue] = useState<string>()
  const [urlError, setUrlError] = useState<string | null>(null)
  const [disabled, setDisabled] = useState<boolean>(false)
  const isChecked = _.isUndefined(review?.visible) ? true : review?.visible
  const { t } = useTranslation(PractitionerHomeNS)
  const s = useMemo(
    () => ({
      view: [
        styles.view,
        {
          padding: spacing,
          backgroundColor: surface.background,
        },
        generateShadow(1),
      ],
      screenStyle: [Platform.OS === "web" ? { width: maxWidth } : null],
      switch: {
        style: {
          marginLeft: spacing,
        },
        trackColor: {
          true: overrides?.switch?.lineActive ?? primary,
          false: overrides?.switch?.lineInactive ?? black,
        },
        thumbColor: isChecked ? white : black,
      },
      subtitle: {
        color: black,
      },
      padding: {
        paddingVertical: spacing,
      },
      infoStyle: {
        marginVertical: spacing / 2,
      },
      errorStyle: {
        color: error,
      },
      button: { marginTop: spacing * 4, marginBottom: spacing, marginHorizontal: spacing / 2 },
    }),
    [
      black,
      isChecked,
      overrides?.switch?.lineActive,
      overrides?.switch?.lineInactive,
      primary,
      spacing,
      error,
      surface.background,
      white,
    ],
  )

  const buttonProps = useMemo(
    () => ({
      style: s.button,
    }),
    [s],
  )

  const isValidUrl = (url: string) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i",
    )
    return pattern.test(url)
  }

  const onChangeText = (text: string) => {
    setValue(text)
    setDisabled(false)
    setUrlError(null)
  }

  useEffect(() => {
    if (review && review?.url) {
      onChangeText(review?.url)
    }
  }, [review])

  const onValueChange = useCallback(
    async val => {
      if (user) {
        updateSurgeonReviewUrl(user.uid, { visible: val })
      }
    },
    [user],
  )

  const onSubmit = useCallback(() => {
    if (value && !isValidUrl(value) && value !== "") {
      setUrlError(t("rdvUrl.error"))
      setDisabled(true)
    } else {
      setUrlError(null)
      if (!user) {
        return
      }
      updateSurgeonReviewUrl(user.uid, {
        url: value,
        visible: _.isUndefined(review?.visible) ? true : review?.visible,
      })
      navigation.goBack()
    }
  }, [value, t, navigation, review?.visible, user])

  const onPress = useCallback(() => openUrl(urls.REVIEW), [])

  return (
    <View style={s.view}>
      <View style={s.screenStyle}>
        <FastImage style={styles.image} source={images.review as number} />
        <Subtitle1 style={[s.subtitle, s.padding]}>{t("subtitle.review")}</Subtitle1>
        <TextInput
          blurOnSubmit
          style={styles.textInput}
          label={t("review.label")}
          autoComplete="off"
          autoCorrect={false}
          autoCapitalize="none"
          {...{ onChangeText, value }}
        />
        <Text style={s.infoStyle}>{t("rdvUrl.info")}</Text>
        {error && <Text style={s.errorStyle}>{urlError}</Text>}
        <Button {...{ onPress }} style={s.padding} mode="text" labelStyle={{ color: primary }}>
          {t("review.button")}
        </Button>
        <View style={styles.switchContainer}>
          <Subtitle1 style={s.subtitle}>{t("review.switch")}</Subtitle1>
          {/** Issue : trackColor is not applied. props from react-native. Update RN should resolve it  */}
          <OptimisticSwitch ms={250} {...s.switch} value={isChecked} {...{ onValueChange }} />
        </View>
        <Button {...buttonProps} disabled={disabled} onPress={onSubmit}>
          {t("common:button.validate")}
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    alignItems: "center",
    height: "100%",
  },
  image: {
    width: 130,
    height: 130,
  },
  textInput: {
    maxHeight: 69,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
})

export default ReviewUrlView
