export const timeVals = {
  Seconds: 1000,
  Minutes: 60000,
  Hours: 3600000,
  SecInMin: 60,
  MinInHours: 60,
  HoursMod: 24,
  timeMin: 10,
}

interface ITime {
  millis: number
  modulo: number
}

const Times = {
  seconds: {
    millis: timeVals.Seconds,
    modulo: timeVals.SecInMin,
  },
  minutes: {
    millis: timeVals.Minutes,
    modulo: timeVals.MinInHours,
  },
  hours: {
    millis: timeVals.Hours,
    modulo: timeVals.HoursMod,
  },
}

const dots = ":"

const getCorrectTime = (duration: number, divider: ITime): string => {
  const timeStr: number = Math.floor((duration / divider.millis) % divider.modulo)

  return timeStr < timeVals.timeMin ? "0" + timeStr : String(timeStr)
}

const msToTime = (duration: number, needHours = true): string =>
  (needHours ? getCorrectTime(duration, Times.hours) + dots : "") +
  getCorrectTime(duration, Times.minutes) +
  dots +
  getCorrectTime(duration, Times.seconds)

export default msToTime
