import { ParamListBase } from "@react-navigation/routers"
import React, { FC, ReactChild, ReactElement, ReactNode, useCallback, useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native"

import { FmOptions } from "../../features/Theme"
import useTheme from "../../features/Theme/ThemeProvider"
import { IBodyProps } from "../Texts"
import { Touchable } from "../Touchable"
import { VectorIconProps } from "../VectorIcon"
import RowCenterItem from "./RowCenterItem"
import RowSideItem from "./RowSideItem"

export interface IRowItemProps<
  ParamList extends ParamListBase = ParamListBase,
  RouteNames extends keyof ParamList = keyof ParamList
> {
  url?: string
  name?: string
  placeholder?: string
  label?: string
  route?: RouteNames
  color?: string
  text?: () => ReactChild
  onPress?: () => void
  isBodyText?: boolean
  fmOptions?: FmOptions
  notImplemented?: boolean
  backgroundColor?: string
  params?: ParamList[RouteNames]
  onAccessibilityActivate?: () => void
  textStyle?: StyleProp<TextStyle>
  textViewStyle?: StyleProp<ViewStyle>
  rowStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  labelProps?: Partial<IBodyProps>
  accessibilityHint?: string
  leftChild?: ReactNode | VectorIconProps
  rightChild?: ReactNode | VectorIconProps
  render?: (props?: IRowItemProps<ParamList, RouteNames>) => ReactElement | null
  maxFontSizeMultiplier?: number
}

export const accessibilityAction = "activate"

const RowItem: FC<IRowItemProps> = ({
  name,
  text,
  placeholder,
  label,
  color,
  onPress,
  fmOptions,
  rowStyle,
  textStyle,
  textViewStyle,
  labelStyle,
  labelProps,
  leftChild,
  rightChild,
  isBodyText,
  backgroundColor,
  accessibilityHint,
  onAccessibilityActivate,
  maxFontSizeMultiplier,
}) => {
  const {
    colors: {
      accent,
      overrides,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
    fontMaker,
  } = useTheme()

  const s = useMemo(
    () => ({
      row: [
        styles.rowContent,
        {
          paddingLeft: spacing,
          paddingRight: spacing * 1.5,
          backgroundColor: backgroundColor ?? white,
        },
        rowStyle,
      ],
      textView: [
        {
          marginLeft: leftChild ? spacing : 0,
          marginRight: rightChild ? spacing : 0,
          width: "100%",
        },
        textViewStyle,
      ],
      text: [textStyle, fmOptions ? fontMaker(fmOptions) : null],
    }),
    [
      spacing,
      backgroundColor,
      white,
      rowStyle,
      leftChild,
      rightChild,
      textViewStyle,
      textStyle,
      fmOptions,
      fontMaker,
    ],
  )

  const onAccessibilityAction = useCallback(
    event => {
      switch (event.nativeEvent.actionName) {
        case accessibilityAction:
          onAccessibilityActivate?.()
          onPress?.()
          break
        default:
          onPress?.()
      }
    },
    [onAccessibilityActivate, onPress],
  )

  return (
    <Touchable
      style={s.row}
      onPress={onPress}
      disabled={!onPress}
      rippleColor={overrides?.ripple || accent}
      {...{ onAccessibilityAction, accessibilityHint }}
      accessibilityActions={[{ name: accessibilityAction, label: accessibilityAction }]}
    >
      <>
        <RowSideItem child={leftChild} />
        <RowCenterItem
          textStyle={s.text}
          viewStyle={s.textView}
          labelProps={labelProps}
          labelStyle={labelStyle}
          text={text ? text() : name}
          placeholder={placeholder}
          maxFontSizeMultiplier={maxFontSizeMultiplier}
          {...{ label, color, isBodyText }}
        />
        <RowSideItem child={rightChild} />
      </>
    </Touchable>
  )
}
const styles = StyleSheet.create({
  rowContent: {
    flex: 1,
    minHeight: 48,
    borderRadius: 0,
    flexDirection: "row",
    alignItems: "center",
  },
})

export default RowItem
