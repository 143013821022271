import { IUserContext, userContext } from "capsule"
import React, { FC, useContext } from "react"
import { useTranslation } from "react-i18next"

import { HomeNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import HomeOncoView from "./HomeOncoView"
import HomeOrthoView from "./HomeOrthoView"
import HomeSectionHeader from "./HomeSectionHeader"

const HomeSessionView: FC = () => {
  const { t } = useTranslation(HomeNS)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const isOnco = userData?.specialty === "onco"

  return (
    <>
      <HomeSectionHeader title={t("nextTime")} />
      {isOnco ? <HomeOncoView /> : <HomeOrthoView />}
    </>
  )
}

export default HomeSessionView
