import { VectorIcon, VectorIconProps } from "capsule"
import React, { FC, useMemo } from "react"
import { StyleSheet, View } from "react-native"

import { CIRCLE_DEFAULT_SIZE } from "../features/config/Constants"

interface IProps {
  size?: number
  color: string
  backgroundColor: string
  icon?: VectorIconProps
}

const ColorCircle: FC<IProps> = ({ size, backgroundColor, color, icon }) => {
  const iconSize = size ? size - 4 : 36 // -4 to respect same size of VectorIcon

  const { style } = useMemo(
    () => ({
      style: [
        styles.icon,
        {
          backgroundColor,
          borderColor: color,
          width: iconSize ?? CIRCLE_DEFAULT_SIZE,
          height: iconSize ?? CIRCLE_DEFAULT_SIZE,
          borderWidth: (size ?? CIRCLE_DEFAULT_SIZE) / 9,
        },
      ],
    }),
    [backgroundColor, color, iconSize, size],
  )

  return <View {...{ style }}>{icon ? <VectorIcon {...icon} /> : null}</View>
}

const styles = StyleSheet.create({
  icon: {
    borderWidth: 4,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
  },
})

export default ColorCircle
