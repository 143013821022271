import { RouteProp } from "@react-navigation/core"
import {
  firestore,
  IRowItemProps,
  ISectionHeader,
  IUserContext,
  ParametersScreen,
  PresentationListView,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useContext, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Platform, SectionListData, StyleSheet } from "react-native"
import FastImage from "react-native-fast-image"

import { Organ } from "../../common/Pathology"
import { collections } from "../../common/types"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import { itemName } from "../../features/hooks/useItemI18n"
import useRightChild from "../../features/hooks/useRightChild"
import { AppUserData } from "../../features/models/UserData"
import { OperationParamList } from "../OperationScreen/OperationNavigator"

interface IProps {
  route: RouteProp<OperationParamList, "Organ">
}

const OrganScreen: FC<IProps> = ({ route }) => {
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const {
    colors: {
      white: { highEmphasis: white },
      primary,
    },
    dimensions: { spacing },
    typography: { subtitle1 },
  } = useTheme()
  const [organs, loading, error] = useCollectionData<Organ>(
    ((userData?.specialty && userData?.role === "patient"
      ? firestore()
          .collection(collections.ORGANS)
          .where("specialty", "==", userData?.specialty)
          .where("disabled", "==", false)
      : !_.isEmpty(route?.params?.specialtyId)
      ? firestore()
          .collection(collections.ORGANS)
          .where("specialty", "==", route?.params?.specialtyId)
          .where("disabled", "==", false)
      : firestore()
          .collection(collections.ORGANS)
          .where("disabled", "==", false)) as unknown) as firebase.firestore.Query<AppUserData>,
  )

  const { t } = useTranslation()

  const s = useMemo(
    () => ({
      image: [
        styles.image,
        {
          margin: spacing,
          marginRight: spacing,
        },
      ],
      row: [
        styles.row,
        {
          backgroundColor: white,
        },
      ],
      indicator: { color: primary, size: ICON_SIZE },
    }),
    [spacing, white, primary],
  )
  const rightChild = useRightChild()
  const organsData = _(organs)
    .map(
      organ =>
        ({
          rightChild,
          leftChild: (
            <FastImage
              style={s.image}
              source={{
                uri: organ.image,
                ...(Platform.OS !== "web" && {
                  priority: FastImage.priority.high,
                  cache: FastImage.cacheControl.immutable,
                }),
              }}
              {...(Platform.OS !== "web" && { resizeMode: FastImage.resizeMode.cover })}
            />
          ),
          name: itemName(organ, t),
          rowStyle: s.row,
          textStyle: subtitle1,
          route: "Pathology",
          params: { organId: organ.id, specialty: organ.specialty },
        } as IRowItemProps<OperationParamList>),
    )
    .value()

  const organSectionData: Array<
    SectionListData<IRowItemProps<OperationParamList>, ISectionHeader>
  > = [
    {
      shadows: 1,
      data: organsData,
      title: "",
    },
  ]

  return (
    <PresentationListView
      isEmpty={_.isEmpty(organs)}
      indicator={s.indicator}
      loading={loading}
      error={error}
    >
      <ParametersScreen listKey="organList" sections={organSectionData} />
    </PresentationListView>
  )
}

const styles = StyleSheet.create({
  image: {
    width: INPUT_HEIGHT,
    height: INPUT_HEIGHT,
  },
  row: {
    height: 80,
  },
})

export default OrganScreen
