import Bugsnag from "@bugsnag/react-native"
import { crashlytics, logger } from "capsule"
import _ from "lodash"
import { Platform } from "react-native"

import { config } from "../../../package.json"

const bugsnag =
  // @ts-ignore
  Platform.OS === "web" ? Bugsnag.start(config.bugsnagKey) : Bugsnag.start()

const reportError = (errorCode: number, errorMessage: string, err?: Error): void => {
  const error =
    err ??
    new Error(
      errorCode + " - " + !_.isString(errorMessage) ? JSON.stringify(errorMessage) : errorMessage,
    )
  bugsnag.leaveBreadcrumb("reportError", { errorCode, errorMessage }, "error")
  crashlytics().recordError(error)
  bugsnag.notify(error)
  logger("reportError", errorCode, errorMessage)
}

const errorCodes = {
  smsLogin: 20,
  archive: 21,
  notifee: 22,
  phase: 23,
}

export default { reportError, errorCodes, bugsnag }
