import { getDefaultHeaderHeight } from "@react-navigation/elements"
import { Dimensions, Platform } from "react-native"

import theme from "../features/Theme/theme"
import isIphoneX from "./isIphoneX"
import isPad from "./isPad"

export const statusBarHeight = isIphoneX && !isPad ? 44 : 20

// eslint-disable-next-line no-shadow
enum Orientation {
  Landscape = "landscape",
  Portrait = "portrait",
}

// https://github.com/react-navigation/react-navigation/issues/2411#issuecomment-382434542
// plus a little bit of modifications
const getOrientation = (): Orientation => {
  const { width, height } = Dimensions.get("window")
  return width > height ? Orientation.Landscape : Orientation.Portrait
}

// This does not include the new bar area in the iPhone X, so I use this when I
// need a custom headerTitle component
export const getHeaderSafeAreaHeight = () => {
  const orientation = getOrientation()
  if (Platform.OS === "ios" && orientation === Orientation.Landscape && !isPad) {
    return 32
  }
  return getDefaultHeaderHeight(
    {
      width: Dimensions.get("window").width,
      height: Dimensions.get("window").height,
    },
    false,
    statusBarHeight,
  )
}

const getHeaderHeight = (): number =>
  getHeaderSafeAreaHeight() + (isIphoneX ? theme.dimensions.spacing * 1.5 : 0)

export default getHeaderHeight
