import { Body2, Button, useTheme, VectorIcon } from "capsule"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { ICON_SIZE } from "../features/config/Constants"
import { HomeNS } from "../features/i18n/constants"

interface IProps {
  onPress: () => void
  padding?: boolean
}

const SeeMoreButton: FC<IProps> = ({ onPress, padding = false }) => {
  const {
    colors: { primary },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation(HomeNS)

  const s = useMemo(
    () => ({
      icon: {
        left: spacing / 2,
        paddingLeft: spacing / 2,
      },
      button: padding ? { paddingRight: spacing } : { left: spacing },
      label: [styles.buttonLabel, { right: spacing / 2 }],
    }),
    [padding, spacing],
  )

  const icon = useCallback(
    () => (
      <VectorIcon
        style={s.icon}
        color={primary}
        size={ICON_SIZE}
        name="chevron-right"
        category="MaterialIcons"
      />
    ),
    [primary, s.icon],
  )

  return (
    <Button
      mode="text"
      icon={icon}
      style={s.button}
      labelStyle={s.label}
      onPress={onPress}
      contentStyle={styles.buttonContent}
    >
      <Body2 color={primary}>{t("seeMore")}</Body2>
    </Button>
  )
}

const styles = StyleSheet.create({
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
})

export default SeeMoreButton
