import { Chunk, findAll } from "highlight-words-core"
import _ from "lodash"
import React, { FC, useMemo } from "react"
import { Text, TextStyle } from "react-native"

import useTheme from "../../features/Theme/ThemeProvider"

interface IProps {
  text: string
  searchWords: string[]
  highlightStyle?: TextStyle
}
// <Highlighter> component must be wrapped in a <Text> component
// @ts-ignore
export const Highlighter: FC<IProps> = ({ text, searchWords, highlightStyle }) => {
  const {
    colors: { primary },
  } = useTheme()

  const s = useMemo(
    () => ({
      highlightText: {
        color: primary,
      },
    }),
    [primary],
  )

  if (_.isEmpty(searchWords)) {
    return text
  }

  const textToHighlight = text + "           "

  const chunks = findAll({
    caseSensitive: false,
    searchWords,
    textToHighlight,
    sanitize: _.deburr,
  })

  // this function syntax allows us to use "this" keyword
  function mapper(this: any, chunk: Chunk) {
    const { end, highlight, start } = chunk

    let chunkText = textToHighlight.substr(start + this.diff, end - start)

    const diff = chunkText.length - _.deburr(chunkText).length

    if (diff !== 0) {
      chunkText = textToHighlight.substr(start + this.diff, end - start + diff)
      this.diff += diff
    }

    if (highlight) {
      return (
        <Text key={`${start}-${end}`} style={highlightStyle ?? s.highlightText}>
          {chunkText}
        </Text>
      )
    } else {
      return chunkText
    }
  }
  return chunks.map(mapper, { diff: 0 })
}
